import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useAxiosCall from "../../../../hooks/useAxiosCall";
import { ADMIN_PRODUUCTS_URL } from "../../../../common/constants";
import styled from "styled-components";
import { KeyValueObj } from "../../../../common/types";
import { DataWrapper } from "../../../../common/components/dataWrapper";
import { Product } from "../../../../common/components/product-card/Product";
import { Pagination } from "src/common/components/pagination";
import { PageTitleNavs } from "src/common/components/page-title-navs";

const ComapanyWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (max-width: ${({theme}) => theme.screen.mobile}) {
    padding: 2rem 1.5rem;
  }
`;

export function AdminProducts() {
  const [retry, setRetry] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [pageType, setPageType] = useState("")

  const location = useLocation();
  const [
    fetchProducts,
    fetchedProducts,
    productsError,
    productsLoading,
    productMeta,
  ] = useAxiosCall();

  useEffect(() => {
    const locationArr = location.pathname.split("/");
    const status = locationArr[locationArr.length - 1];
    setPageType(status)

    fetchProducts({
      url:
        ADMIN_PRODUUCTS_URL +
        "?status=" +
        status +
        `&per_page=${perPage}&page=${page}`,
      method: "GET",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, retry, perPage, page]);

  return (
    <ComapanyWrapper>
       <PageTitleNavs
        title={pageType + " Products"}
        backLink="#"
        bcrumbs={[{ title: "products", link: "#" }]}
      />
      <DataWrapper
        data={fetchedProducts}
        noDataMessage={"No product at this time"}
        loading={productsLoading}
        error={productsError}
        loadingMessage="Loading products data..."
        failedMessage="Could not fetch products data. Check your network and click the button below to retry"
        onFailAction={() => {
          setRetry((prev) => prev + 1);
        }}
      >
        <>
          {fetchedProducts &&
            fetchedProducts.map((product: KeyValueObj) => (
              <Product
                key={product?.id}
                imageUrl={product?.images?.[0]?.url}
                brand={product?.brand_name}
                name={product?.atc_code?.code_title}
                id={product?.id}
                strengthUnit={product?.strength_unit}
                strength={product?.strength}
                form={product?.form}
                size={""}
                onApprove
                baseUrl={"/dashboard/admin/products/details/"+pageType}
              />
            ))}
        </>
      </DataWrapper>
      <Pagination
        pageCount={productMeta?.total_pages}
        onNextPage={({ selected }) => setPage(selected)}
        onPerPageChange={(event: any) => setPerPage(event?.target?.value)}
      />
    </ComapanyWrapper>
  );
}

import styled from "styled-components";
import { PageContainr } from "../../common/elements/containers";

import loginBg from "../../assets/images/onboard4.webp";

export const LoginWrapper = styled(PageContainr)`
  z-index: 4;
  align-items: flex-start;
  margin-bottom: 10rem;

  .container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding: 32px;
    gap: 32px;

    @media screen and (max-width: 850px) {
      flex-direction: column;
    }

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 0;
    }
  }

  .info-sec {
    width: 50%;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    background-image: url(${loginBg});
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 36px;
    padding: 1.5rem;
    min-height: 50vh;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 100%;
    }

    .text-wrapper {
      background-color: rgba(69, 0, 1, 0.3);
      width: 100%;
      padding: 1rem;
    }

    h2 {
      font-size: 28.8px;
      color: ${({ theme }) => theme.text.prim_500};
      font-family: "Vidaloka", serif;
      z-index: 2;
    }

    p {
      font-size: 14.4px;
      color: ${({ theme }) => theme.text.prim_500};
      text-align: left;
      line-height: 1.5rem;
      z-index: 2;
    }

    .dots-image {
      position: absolute;

      left: 0;
      top: 115%;
    }
  }

  .input-sec {
    width: 50%;
    min-width: 30rem;
    max-width: 45rem;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    padding: 5rem 3rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 4rem 0;
      width: 100%;
      min-width: 100%;
    }
  }

  .top {
    width: 100%;

    .welcome-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .sign-up {
        font-weight: 700;
        font-size: 48px;
        letter-spacing: 0.0144px;
        color: ${({ theme }) => theme.text.primary};

        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          font-size: 35.2px;
        }
      }

      .other-route {
        margin-top: 3.2px;
        font-weight: 300;
        font-size: 14.4px;
        letter-spacing: 0.0144px;
        color: ${({ theme }) => theme.text.prim_100};

        .route-link {
          font-weight: 700;
          text-decoration: none;
          color: ${({ theme }) => theme.text.secondary};
        }
      }
    }
  }

  .form {
    width: 100%;
    margin-top: 64px;
    padding: 1rem;
  }

  .controls {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2.25rem;
    gap: 2.25rem;

    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 1.44px;
        color: ${({ theme }) => theme.text.input};
      }
    }

    .or:before,
    .or:after {
      content: "";
      width: 45%;
      height: 0.0313rem;
      position: absolute;
      background-color: ${({ theme }) => theme.border.input};
      top: 55%;
      right: 0;
      z-index: -1;
    }

    .or:after {
      left: 0;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.0144px;
      background: ${({ theme }) => theme.bg.prim_500};
      border: 1px solid ${({ theme }) => theme.border.input};
      border-radius: 3.2px;
      height: 48px;
      width: 100%;
      cursor: pointer;

      img {
        width: 20px;
        margin-right: 18px;
      }
    }
  }
`;

import styled from "styled-components";

interface TableStyleProps {
  full?: boolean;
  checked?: boolean;
  lined?: boolean;
  headerBg?: string;
  allSelected?: boolean;
}

interface TRProps {
  full?: boolean;
  selected?: boolean;
  lined?: boolean;
  shouldHover?: boolean;
  rowLineColor?: string;
  spaced?: boolean;
  indexValue?: number;
}

export const TableWrapper = styled.div`
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;

    .title {
      font-size: 1.2rem;
      font-weight: 700;
      color: ${({ theme }) => theme.text.prim_100};
      text-transform: capitalize;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 1rem;
        font-size: 600;
      }
    }
  }
`;

export const TableContainer = styled.div<TableStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.35rem;
  background-color: ${({ theme }) => theme.bg.prim_500};

  .search-wrapper {
    justify-content: flex-start;
    align-items: stretch;
    gap: 2rem;
    width: 100%;
    padding: 0.5rem 0.4rem 0.5rem 0.2rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      gap: 1rem 0;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .table-controls {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0;
    margin-left: -0.2rem;
    width: 100%;
  }

  .search-input {
    padding: 0.65rem;
    border: 1px solid ${({ theme }) => theme.border.light};
    border-radius: 0.5rem;
    outline: none;

    :active,
    :focus {
      box-shadow: ${({ theme }) => theme.shadow.drag_over};
    }

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 48%;
    }
  }

  .search-input::-webkit-input-placeholder {
    font-family: FontAwesome;
    font-weight: 300;
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    font-size: 1.1rem;
    color: ${({ theme }) => theme.border.input};
  }

  .search-input::-moz-placeholder {
    font-family: FontAwesome;
    font-weight: 300;
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    color: ${({ theme }) => theme.border.input};
  }

  .search-input:-ms-input-placeholder {
    font-family: FontAwesome;
    font-weight: 300;
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    color: ${({ theme }) => theme.border.input};
  }

  .search-icon {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.text.prim_200};
  }

  .search-filter {
    border: 1px solid ${({ theme }) => theme.border.light};
    padding: 0.5rem 2rem 0.5rem 1rem;
    gap: 1.1rem;
    color: ${({ theme }) => theme.text.prim_100};
    border-radius: 0.5rem;
    cursor: pointer;
    width: 15rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 48%;
      gap: 0.5rem;
      padding: 0.5rem 0.75rem;
    }

    h3 {
      font-size: 0.875rem;
      font-weight: 400;
    }

    .filter-icon {
      font-size: 1.3rem;
      transform: rotate(90deg);
    }

    :hover {
      box-shadow: ${({ theme }) => theme.shadow.drag_over};
    }
  }

  .header-action-wrapper {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 100%;
    }
  }

  .regular-button {
    background-color: #6047f520;
    outline: none;
    color: #6047f5;
    border-radius: 0.2rem;
    border: none;
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    cursor: pointer;
    min-width: 4rem;

    :hover {
      background-color: #6047f540;
    }
  }

  .danger-button {
    background-color: #ffdbe0;
    outline: none;
    color: #fc2d4a;
    border-radius: 0.2rem;
    border: none;
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    cursor: pointer;

    :hover {
      background-color: #f7abb6;
    }
  }

  div {
    overflow-x: auto;
    width: 100%;
    display: flex;

    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
      justify-content: center;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    min-width: 550px;
  }

  thead {
    tr {
      ${({ headerBg, theme }) =>
        headerBg && "background-color: " + theme.bg[headerBg]};
      border-bottom: 1px solid transparent;
    }
  }

  th {
    color: ${({ theme }) => theme.text.prim_100};
    font-size: ${({ full }) => (full ? ".85rem" : ".75rem")};
    font-weight: 700;
    padding: ${({ full }) => (full ? "1.4rem .5rem" : ".7rem .4rem")};
    text-align: left;
  }

  .check-icon {
    font-size: 1.2rem;
    margin-right: 0.7rem;
    margin-bottom: -0.2rem;
    cursor: pointer;
    color: ${({ allSelected, theme }) =>
      allSelected ? theme.bg.primary : theme.bg.tert_300};
  }

  .click-icon {
    font-size: 1.3rem;
    margin-right: 0.7rem;
    margin-top: 0.3rem;

    color: ${({ allSelected, theme }) =>
      allSelected ? theme.bg.primary : theme.bg.tert_300};
  }
`;

export const TR = styled.tr<TRProps>`
  background-color: ${({ selected, theme }) => selected && theme.bg.prim_400};
  border-top: 1px solid ${({ theme }) => theme.bg.primary};
  border-bottom: 1px solid
    ${({ rowLineColor, theme }) =>
      rowLineColor ? theme.border[rowLineColor] : "transparent"};
  z-index: ${({ indexValue }) => indexValue};
  position: relative;

  :nth-child(2n) {
    background-color: ${({ lined, selected, theme }) =>
      selected ? theme.bg.prim_400 : lined ? "rgba(0,0,0,.03)" : "none"};
  }

  :hover {
    background-color: ${({ theme, shouldHover }) =>
      shouldHover && theme.bg.secondary}30;
    cursor: pointer;
  }

  .check-icon {
    font-size: 1.2rem;
    margin-right: 0.7rem;
    margin-bottom: -0.2rem;
    color: ${({ selected, theme }) =>
      selected ? theme.bg.primary : theme.bg.tert_300};
  }

  td {
    color: ${({ theme }) => theme.text.prim_100};
    font-size: ${({ full }) => (full ? ".85rem" : ".75rem")};
    font-weight: 500;
    padding: ${({ spaced }) =>
      spaced ? "1.8rem 0.4rem 1.3rem" : "1.2rem 0.5rem"};
    vertical-align: top;
    word-break: break-all;

    ::first-letter {
      text-transform: capitalize;
    }

    .table-image {
      height: 3rem;
      margin-bottom: -0.4rem;
    }

    .dotted-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      height: 3rem;
      cursor: pointer;
      overflow: auto;
      margin-top: -0.5rem;
      background-color: transparent;

      :disabled {
        background: none;
        cursor: not-allowed;
      }

      .button-icon {
        background-color: #cfcfcf4f;
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 50%;
        padding: 0.5rem;
        color: ${({ theme }) => theme.text.muted_200};
      }
    }

    .light-green {
      padding: 0.5rem 0.7rem;
      color: ${({ theme }) => theme.text.life};
      border-radius: 0rem;
      background-color: ${({ theme }) => theme.text.life}15;
    }

    .status {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .status-inner {
        /* border: 1px solid green; */
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.6rem 0.6rem;
        border-radius: 0.2rem;
        min-width: 5.9rem;

        .status-dot {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
        }
        h3 {
          text-transform: capitalize;
          font-weight: 500;
          font-size: 0.8rem;
        }
      }

      .pending {
        /* border: 1px solid orange; */
        background-color: #ffa6001b;
        .status-dot {
          background-color: orange;
        }
        h3 {
          color: orange;
        }
      }

      .rejected,
      .declined {
        /* border: 1px solid #ff5100; */
        background-color: #ff00001d;
        .status-dot {
          background-color: #ff5100;
        }
        h3 {
          color: #ff5100;
        }
      }
      .accepted {
        /* border: 1px solid #336cfb; */
        background-color: #336cfb22;
        .status-dot {
          background-color: #336cfb;
        }
        h3 {
          color: #336cfb;
        }
      }
      .shipped {
        /* border: 1px solid #006d80; */
        background-color: #006d8020;
        .status-dot {
          background-color: #006d80;
        }
        h3 {
          color: #006d80;
        }
      }

      .delivered {
        /* border: 1px solid #07d907; */
        background-color: #07d90720;
        .status-dot {
          background-color: #07d907;
        }
        h3 {
          color: #07d907;
        }
      }
    }

    .input-wrapper {
      /* width: 9rem; */
      height: 4rem;
      position: relative;

      .error-message {
        color: red;
        position: absolute;
        top: 2.2rem;
        font-size: 0.7rem;
        font-weight: 400;
      }

      input {
        width: 5rem;
        padding: 0.5rem;
        outline: none;
        border: 1px solid ${({ theme }) => theme.border.input};
        color: ${({ theme }) => theme.text.prim_100};
        max-height: 3rem;
        border-radius: 0.25rem;
      }

      input[type="date"] {
        width: 7.5rem;
      }

      label {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        gap: 0.4rem;
        align-items: flex-start;
        font-size: 0.85rem;
        width: 10rem;

        span {
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
          }

          .selected-icon {
            color: ${({ theme }) => theme.bg.secondary};
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .options-wrapper {
    position: absolute;
    top: 3.5rem;
    right: 0.2rem;
    width: 10rem;
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    margin-top: 0.3rem;
    opacity: 0;
    animation: optionsFadeIn 0.35s forwards;
    z-index: 201;
  }

  @keyframes optionsFadeIn {
    to {
      margin-top: 0;
      opacity: 1;
    }
  }
`;

import styled from "styled-components";

export const RespondRfqWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }
`;

export const RVRfqWrapper = styled.div`
  width: 100%;
  max-width: 60rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 2rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .rvrf-controls {
    width: 100%;
    display: flex;
    padding: 4rem;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 3rem 0.5rem;
  }
  }

  .center {
    justify-content: center;
  }

  .text {
    width: 100%;
    text-align: left;
  }

  .quote-title {
    color: ${({ theme }) => theme.text.primary};
    font-weight: 700;
    font-size: 1.2rem;
  }

  .request-info {
    background-color: ${({ theme }) => theme.bg.prim_500};
    width: 100%;
    padding: 2rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
`;

export const PrintWrapper = styled.div`
  width: 100%;
`;

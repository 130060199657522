import { KeyValueObj } from "src/common/types";
import { formatToNaira } from "src/common/utils/general";
import styled from "styled-components";

const CTableWrapper = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.bg.prim_500};
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    align-items: flex-start;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    min-width: 550px;
  }

  thead {
    tr {
      background-color: ${({ theme }) => theme.bg.secondary};
    }
  }

  th {
    color: ${({ theme }) => theme.text.prim_500};
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.75rem;
    text-align: left;
  }

  tbody {
    tr {
      border-bottom: 1px solid ${({ theme }) => theme.border.light};
    }

    td {
      color: ${({ theme }) => theme.text.prim_100};
      font-size: 0.85rem;
      font-weight: 500;
      padding: 1rem 0.75rem;
      vertical-align: top;
      word-break: break-all;

      .summary-wrapper {
        display: inline-block;
        padding: 0;
        margin: 0;
        padding-bottom: 0.5rem;
      }

      .summary-title {
        font-weight: 400;
        font-size: 0.8rem;
      }

      button {
        background-color: #f6e6fe;
        outline: none;
        border: none;
        padding: 0.5rem 1.2rem;
        font-size: 0.8rem;
        font-weight: 500;
        color: #c758ff;
        border-radius: 0.25rem;

        :hover {
          cursor: pointer;
          background-color: #eccdfa;
        }
      }
    }
  }
`;

function getSubTotal(products: KeyValueObj[]) {
  let total = 0;
  products?.forEach(
    (product: KeyValueObj) =>
      (total += product?.quantity * parseFloat(product?.price_per_unit))
  );
  return total;
}
export interface ICompareTableProps {
  rfqQuotations: KeyValueObj[];
  handleViewClicked: (row: KeyValueObj) => void;
}

export function CompareTable({
  rfqQuotations,
  handleViewClicked,
}: ICompareTableProps) {
  return (
    <CTableWrapper>
      <table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Product Info</th>
            <th>Package Type</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Amount</th>
            <th style={{ minWidth: "10rem" }}>Total Summary</th>
            <th style={{ minWidth: "7rem" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {rfqQuotations?.map((quotation: KeyValueObj) =>
            quotation?.quotation_products?.map(
              (qProduct: KeyValueObj, i: number) => (
                <tr>
                  {i === 0 ? (
                    <td rowSpan={quotation?.quotation_products?.length}>
                      {quotation?.distributor_info?.name}
                    </td>
                  ) : null}
                  <td>
                    {`
                      ${qProduct.brand_name} - ${qProduct.product_strength}${qProduct.product_strength_unit}(${qProduct?.product_form})
                    `}
                  </td>
                  <td>{qProduct?.package_type}</td>
                  <td>{qProduct?.quantity}</td>
                  <td>{formatToNaira(qProduct?.price_per_unit)}</td>
                  <td>
                    {formatToNaira(
                      qProduct?.quantity * parseFloat(qProduct?.price_per_unit)
                    )}
                  </td>
                  {i === 0 ? (
                    <td rowSpan={quotation?.quotation_products?.length}>
                      <span className="summary-wrapper">
                        <span className="summary-title">Sub-total:</span>{" "}
                        {getSubTotal(quotation?.quotation_products)}
                      </span>{" "}
                      <br />
                      <span className="summary-wrapper">
                        <span className="summary-title">Discount: </span>
                        {quotation?.discount}
                      </span>{" "}
                      <br />
                      <span className="summary-wrapper">
                        <span className="summary-title">Tax: </span>
                        {quotation?.tax}
                      </span>{" "}
                      <br />
                      <span className="summary-wrapper">
                        <span className="summary-title">Delivery Charge: </span>
                        {quotation?.delivery_charges}
                      </span>
                      <br />
                      <span className="summary-wrapper">
                        <span className="summary-title">Total: </span>
                        {formatToNaira(quotation.total_amount)}
                      </span>
                      <br />
                      <span className="summary-wrapper">
                        <span className="summary-title">Payment Term: </span>
                        {quotation?.payment_term}
                      </span>
                    </td>
                  ) : null}

                  {i === 0 ? (
                    <td rowSpan={quotation?.quotation_products?.length}>
                      <button
                        onClick={() => handleViewClicked({ id: quotation?.id })}
                      >
                        View
                      </button>
                    </td>
                  ) : null}
                </tr>
              )
            )
          )}
        </tbody>
      </table>
    </CTableWrapper>
  );
}

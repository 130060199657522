import styled from "styled-components";

interface ProductProps {
  bgColor: string;
}

const ProductWrapper = styled.div<ProductProps>`
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  background-color: ${({ bgColor }) => bgColor};
  gap: 0.3125rem;
  margin: 0;


  .text-color{
    color: ${({theme}) => theme.text.prim_500};
  }

  .amount {
    font-weight: 700;
    font-size: 1.2rem;
    
  }

  .title {
    font-weight: 500;
    font-size: .9rem;

    text-align: center;
  }

  .increase {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.text.muted_200};
  }
`;

export interface IproductProps {
  bgColor: string;
  amount: number;
  title: string;
  increase: string;
}

export function Product({ bgColor, amount, title, increase }: IproductProps) {
  return (
    <ProductWrapper bgColor={bgColor}>
      <h2 className="amount text-color">{amount}</h2>
      <h3 className="title text-color">{title}</h3>
      <h4 className="increase text-color">{increase}</h4>
    </ProductWrapper>
  );
}

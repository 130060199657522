import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuthDetails } from "../../../hooks/useAuthDetails";
import useAuth from "../../../hooks/useContexts";
import { useNotification } from "../Notifications/NotificationProvider";

interface RequireAuthProps {
  allowedRoles?: string[];
  allowWithoutComplete?: boolean;
}

export const RequireAuth = ({
  allowedRoles,
  allowWithoutComplete,
}: RequireAuthProps) => {
  const { auth, setGoBack } = useAuth();
  const location = useLocation();
  const { companyType } = useAuthDetails();

  let isAllowed = true;
  const firstUrl = location.pathname.split("/")[1];
  const notify = useNotification();

  //if the user is logged in and is trying to access a dashboard link then if
  // the role is not allowed it should redirect to the dashboard stats page

  let redirectTo =
    firstUrl === "dashboard" && auth?.user?.registration_completed
      ? "/dashboard/stats"
      : "/login";

  //this helps to keep track of where to redirect to.
  if (!auth.user) {
    setGoBack(true);
  }

  //If we did not pass allowedRoles as props then isAllowed should remain true
  if (allowedRoles) {
    isAllowed =
      allowedRoles.findIndex(
        (innerRole) => innerRole === companyType?.toLowerCase()
      ) !== -1;
  }

  if (!isAllowed) {
    notify({
      message: "You are not allowed to view this page for this role",
      type: "ERROR",
    });
  }

  //This is for allowing a user without complete registeration access
  if (allowWithoutComplete) {
    return auth.user ? (
      <Outlet />
    ) : (
      <Navigate to={redirectTo} state={{ from: location }} replace />
    );
  }

  //if it is a super admin, we dont need to check if the registeration is complete
  if (auth?.user?.role?.designation === "super-admin") {
    return <Outlet />;
  }

  return (auth?.user?.registration_completed && isAllowed) || true ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} replace />
  );
};

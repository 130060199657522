import { states } from "src/common/data";
import { KeyValueObj } from "src/common/types";

export const getProductInfoForm = (onEveryChange: (...props: any) => void) => [
  {
    inputType: "selectInput",
    prompt: "Product Type",
    name: "item_type",
    placeholder: "Select the product type",
    fullWidth: true,
    shortWidth: false,
    options: [
      { id: "drug", title: "Drug" },
      { id: "medical_item", title: "Medical Item" },
    ],
    onEveryChange: onEveryChange,
  },
];

export const getDrugForm = (onEveryChange: (...props: any) => void) => [
  {
    inputType: "text",
    prompt: "ATC code",
    options_name: "atc_code",
    name: "atc_code_id",
    placeholder: "Loading ATC data ...",
    fullWidth: false,
    shortWidth: true,
    readOnly: true,
    loadingState: true,
    options: [],
    valueType: "ATC",
    onEveryChange: onEveryChange,
  },
  {
    inputType: "text",
    prompt: "Brand Name",
    name: "brand_name",
    placeholder: "Select the brand name",
    fullWidth: false,
    shortWidth: false,
    options: [],
    readOnly: true,
    onEveryChange: onEveryChange,
  },
  {
    inputType: "text",
    prompt: "Drug Strength",
    name: "product_strength",
    placeholder: "Select the product strength",
    fullWidth: false,
    shortWidth: false,
    readOnly: true,
    options: [],
    onEveryChange: onEveryChange,
  },
  {
    inputType: "text",
    prompt: "Packaging Type",
    name: "package_type",
    placeholder: "Select packaging type",
    fullWidth: false,
    shortWidth: false,
    readOnly: true,
    options: [
      {
        id: "blister",
        title: "Blister",
      },
      {
        id: "pack",
        title: "Pack",
      },
      {
        id: "carton",
        title: "Carton",
      },
    ],
    onEveryChange: onEveryChange,
  },
  {
    inputType: "number",
    prompt: "Quantity",
    name: "quantity",
    placeholder: "Enter quantity",
    fullWidth: false,
    shortWidth: false,
    readOnly: true,
    rules: {
      minValue: {
        expectedValue: 1,
        errorMessage: "Quantity should not be less than 1",
      },
    },
    onEveryChange: onEveryChange,
  },
];

export const packagingOptions = [
  {
    id: "pack",
    title: "Pack",
  },
  {
    id: "carton",
    title: "Carton",
  },
];

export const productTableHeaders = [
  "Product Type",
  "Brand",
  "Weight",
  "Product Form",
  "Packaging Type",
  "Quantity",
];

export const productTableKeys = [
  "productType",
  "brandName",
  "productStrength",
  "productForm",
  "packagingType",
  "quantity",
];

export const getProductTableData = (values: KeyValueObj[]) => {
  const data = values?.map((value: KeyValueObj) => ({
    id: value?.id,
    productType: {
      type: "string",
      value: value?.item_type,
    },
    brandName: {
      type: "string",
      value: value?.brand_name,
    },
    productStrength: {
      type: "string",
      value: `${value?.product_strength}${value?.product_strength_unit}`,
    },
    productForm: {
      type: "string",
      value: value?.product_form,
    },
    packagingType: {
      type: "string",
      value: value?.package_type,
    },
    quantity: {
      type: "string",
      value: value?.quantity,
    },
  }));
  return data;
};

export const distributorTableHeaders = [
  "Company Name",
  "Status",
  "Phone Number",
  "Email",
  "Corporate Address",
];

export const distributorTableKeys = [
  "companyName",
  "status",
  "phoneNumber",
  "email",
  "corporateAddress",
];

export const getDistributorsTableData = (values: KeyValueObj[]) => {
  const data = values.map((value: KeyValueObj) => ({
    id: value?.id,
    companyName: {
      type: "string",
      value: value?.name,
    },
    establishmentDate: {
      type: "string",
      value: value?.date_of_establishment,
    },
    status: {
      type: "string",
      value: value?.company_status,
    },
    phoneNumber: {
      type: "string",
      value: value?.phone_number,
    },
    email: {
      type: "string",
      value: value?.email,
    },
    corporateAddress: {
      type: "string",
      value: value?.corporate_address,
    },
  }));
  return data;
};

export const distributorFilterOptions = [
  {
    label: "Favorites",
    name: "favorites",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },

  {
    label: "State",
    name: "state",
    type: "select",
    options: states.map((state: KeyValueObj) => ({
      label: state?.state?.name,
      value: state?.state?.name?.split(" ")?.shift(),
    })),
  },
];

export const paymentTerms = [
  {
    title: "Advance Payment",
    id: "advance",
  },
  {
    title: "Net-30 days",
    id: "net-30",
  },
  {
    title: "Net-60 days",
    id: "net-60",
  },
];

export const paymentPlans = [
  {
    title: "One off",
    id: "one-off",
  },
  {
    title: "Pre-Paid",
    id: "pre-paid",
  },
  {
    title: "Post-Paid",
    id: "post-paid",
  },
];

export const getDeliveryFormInputs = () => [

  {
    inputType: "date",
    prompt: "Expected Delivery Date",
    name: "expected_delivery_date",
    placeholder: "Enter expected delivery date",
    fullWidth: false,
    shortWidth: false,
    rules: {
      future: {
        expectedValue: 1,
        errorMessage: "Quantity should not be less than 1",
      },
    },
  },

  {
    inputType: "date",
    prompt: "RFQ Expiry Date",
    name: "expiration_date",
    placeholder: "Enter RFQ expiry date",
    fullWidth: false,
    shortWidth: false,
    rules: {
      future: {
        expectedValue: 1,
        errorMessage: "Quantity should not be less than 1",
      },
    },
  },
];

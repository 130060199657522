import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

const BarchatWrapper = styled.div`
width: 100%;
`;

export interface IBarChartProps {
  chartData: any,
  options: any,
}

export function Barchart({chartData, options}: IBarChartProps) {
  return (
    <BarchatWrapper>
      <Bar data={chartData} options={options} />
    </BarchatWrapper>
  );
}

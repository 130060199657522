import { useEffect, useState } from "react";
import { KeyValueObj } from "src/common/types";
import styled from "styled-components";
import { Select } from "../selectInput";
import Input from "../Input/Input";
import { formatToNaira, getAddress } from "src/common/utils/general";
import useQuery from "src/hooks/useQuery";
import { OTHER_BASE_SETTINGS_URL } from "src/common/constants";
import { useAuthDetails } from "src/hooks/useAuthDetails";

const WrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .top-liner {
    width: 100%;
    background-color: ${({ theme }) => theme.bg.secondary};
    border-radius: 0.35rem;
    height: 0.9rem;
  }
  .top-content {
    width: 100%;
    background-color: ${({ theme }) => theme.bg.prim_400};
    display: flex;
    justify-content: center;
    align-items: center;

    .info {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        padding: 2rem 0.2rem;
        min-width: 100%;
      }
    }

    img {
      width: 12rem;
    }
  }
`;

export interface ICompanyTopViewProps {
  entity: KeyValueObj;
}

export function CompanyTopView({ entity }: ICompanyTopViewProps) {
  return (
    <WrapperStyle>
      <div className="top-liner"></div>
      <div className="top-content">
        <div className="info">
          <h2 className="dash-title-lg">{entity?.name}</h2>
          <h4 className="dash-text">{entity?.corporate_address}</h4>
          <h4 className="dash-text">{entity?.email}</h4>
          <h4 className="dash-text">{entity?.phone_number}</h4>
        </div>
        <img src={entity?.company_logo?.url} alt="" />
      </div>
    </WrapperStyle>
  );
}

const AddressWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .address-top-title {
    font-size: 1.3rem;
    color: ${({ theme }) => theme.text.primary};
    font-weight: 700;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      font-size: 1.1rem;
    }
  }

  .addresses {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 30%;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      max-width: 47%;
    }
  }

  .address-title {
    color: ${({ theme }) => theme.text.prim_100};
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 2px solid ${({ theme }) => theme.border.input};
    padding-bottom: 0.25rem;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      font-size: 1rem;
    }
  }

  .address-details {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .detail {
    font-size: 0.85rem;
    font-weight: 700;
    color: ${({ theme }) => theme.text.prim_100};
    word-break: break-all;

    span {
      font-weight: 500;
    }
  }
`;

export interface IAddressDetailsProps {
  rfq: KeyValueObj;
  isInvoice?: boolean;
  invoiceDetails?: KeyValueObj;
  title?: string;
  isInspecting?: boolean;
}

export function AddressDetails({
  rfq,
  invoiceDetails,
  isInvoice,
  title,
  isInspecting,
}: IAddressDetailsProps) {
  const { companyType } = useAuthDetails();
  const owner = rfq?.owner;

  function getTitle(title: string | undefined | null) {
    if (title) return title;
    if (!isInspecting && companyType?.toLowerCase() === "distributor")
      return "Quotation";
    return "Request for Quotation";
  }

  return (
    <AddressWrapper>
      <h3 className="address-top-title">{getTitle(title)}</h3>
      <div className="addresses">
        <div className="address">
          <h3 className="address-title">BILL TO</h3>
          <div className="address-details">
            <h4 className="detail">
              Company: <span>{owner?.name}</span>
            </h4>
            <h4 className="detail">
              Address: <span>{owner?.corporate_address}</span>
            </h4>
            <h4 className="detail">
              Lga: <span>{owner?.local_govt}</span>
            </h4>
            <h4 className="detail">
              State: <span>{owner?.state}</span>
            </h4>
            <h4 className="detail">
              Country: <span>Nigeria</span>
            </h4>
            <h4 className="detail">
              Phone No: <span>{owner?.phone_number}</span>
            </h4>
            <h4 className="detail">
              Email: <span>{owner?.email}</span>
            </h4>
          </div>
        </div>
        {!isInvoice ? (
          <div className="address">
            <h3 className="address-title">SHIP TO</h3>
            <div className="address-details">
              <h4 className="detail">
                Company: <span>{owner?.name}</span>
              </h4>
              <h4 className="detail">
                Shipping Address: <span>{getAddress(rfq?.address)}</span>
              </h4>
              <h4 className="detail">
                Shipping City: <span>{rfq?.address?.city}</span>
              </h4>
              <h4 className="detail">
                State: <span>{rfq?.address?.state}</span>
              </h4>
              <h4 className="detail">
                Country: <span>Nigeria</span>
              </h4>
              <h4 className="detail">
                Phone No: <span>{owner?.phone_number}</span>
              </h4>
              <h4 className="detail">
                Email: <span>{owner?.email}</span>
              </h4>
            </div>
          </div>
        ) : null}

        <div className="address">
          {isInvoice ? (
            <>
              <h3 className="address-title">INVOICE DETAILS</h3>
              <div className="address-details">
                <h4 className="detail">
                  Invoice ID: <span>{invoiceDetails?.invoice_number}</span>
                </h4>
                <h4 className="detail">
                  Invoice Issue Date:{" "}
                  <span>{invoiceDetails?.invoice_issue_date}</span>
                </h4>
                <h4 className="detail">
                  Invoice Due Date:{" "}
                  <span>{invoiceDetails?.invoice_due_date}</span>
                </h4>
                <h4 className="detail">
                  Order ID: <span>{invoiceDetails?.order_number}</span>
                </h4>
              </div>
            </>
          ) : null}
          {!isInvoice ? (
            <>
              <h4 className="detail">
                Date: <span>{new Date().toDateString()}</span>
              </h4>
            </>
          ) : null}
        </div>
      </div>
    </AddressWrapper>
  );
}

const RequestTableWrapper = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    align-items: flex-start;
    padding: 2rem 0.5rem;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    min-width: 50px;
    max-width: 100%;
  }

  thead {
    tr {
      background-color: ${({ theme }) => theme.bg.secondary};
    }
  }

  th {
    color: ${({ theme }) => theme.text.prim_500};
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.75rem;
    text-align: left;
  }

  tbody {
    tr {
      border-bottom: 1px solid ${({ theme }) => theme.border.light};
    }

    td {
      color: ${({ theme }) => theme.text.prim_100};
      font-size: 0.85rem;
      font-weight: 500;
      padding: 1rem 0rem;
      vertical-align: top;
      word-break: break-all;
    }
  }

  input,
  textarea {
    color: ${({ theme }) => theme.text.prim_100};
    padding: 0.5rem;
    width: 6.5rem;
    border: transparent;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    border-radius: 0.2rem;
    resize: none;

    :focus {
      outline: none;
      border: ${({ theme }) => theme.bg.secondary};
      box-shadow: ${({ theme }) => theme.shadow.drag_over};
    }
  }

  .other-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .account-container {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid ${({ theme }) => theme.border.light};
    flex-wrap: wrap;
  }

  .counter-options {
    width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .account-details {
    width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin-top: 0.5rem;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 100%;
    }

    .account-detail {
      display: flex;
      flex-direction: column;
      gap: 0.35rem;
      min-height: 3.25rem;

      h4 {
        font-size: 0.85rem;
        color: ${({ theme }) => theme.text.primary};
        font-weight: 600;
        width: 6.5rem;
      }
    }
  }

  .viewing {
    margin-top: 1rem;
    gap: 1.2rem;
  }

  .other-details {
    width: 17rem;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    margin-top: 1rem;
    margin-left: auto;

    .quote-detail {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h4 {
        font-size: 0.85rem;
        color: ${({ theme }) => theme.text.primary};
        font-weight: 600;
        border-bottom: 1px solid ${({ theme }) => theme.border.input};
        width: 6.5rem;
        padding: 0 0.5rem;
      }
    }
  }

  h3 {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.text.prim_100};
    font-weight: 600;
  }
`;

export interface IRequestTableProps {
  rfq: KeyValueObj;
  prices?: KeyValueObj;
  subTotal?: number | string;
  logisticsCost: number | string;
  tax: number | string;
  discount: number | string;
  rowCosts?: KeyValueObj;
  rowQuantities?: KeyValueObj;
  rowStockQuantities?: KeyValueObj;
  total: number | string;
  rowTotals: KeyValueObj;
  handleInputChange?: (value: string, id: string, unitPrice: string) => void;
  handleQuantityChange?: (value: string, id: string, unitPrice: string) => void;
  handleOtherInputChange?: (type: string, value: string) => void;
  handleAccountChange?: (type: string, value: string) => void;
  terms: string;
  updateCounterPaymentTerm?: (name: string, value: any) => void;
  isViewing?: boolean;
  quotation?: KeyValueObj;
  inspecting?: boolean;
  quotationExpiry?: string;
  orderType?: string;
}

export function RequestTable({
  subTotal,
  tax,
  discount,
  logisticsCost,
  total,
  handleInputChange,
  handleOtherInputChange,
  rowTotals,
  handleAccountChange,
  terms,
  rfq,
  quotation,
  isViewing,
  inspecting,
  quotationExpiry,
  updateCounterPaymentTerm,
  rowQuantities,
  rowStockQuantities,
  rowCosts,
  handleQuantityChange,
  orderType,
}: IRequestTableProps) {
  const [formValues, setFormValues] = useState<KeyValueObj>({});
  const [paymentTermsList, setPaymentTermsList] = useState<KeyValueObj[]>([]);
  function updateData(name: string, value: any) {
    updateCounterPaymentTerm?.(name, value);
  }
  const products = isViewing ? quotation : rfq?.quotation_request_products;
  const { companyType } = useAuthDetails();
  const showNA =
    companyType?.toLowerCase() === "distributor" && orderType === "sent";

  const { data: baseSetting } = useQuery(OTHER_BASE_SETTINGS_URL);

  useEffect(() => {
    const paymentTerms = baseSetting?.[0]?.values?.payment_terms?.map(
      (paymentTerm: string) => ({
        title: paymentTerm,
        id: paymentTerm,
      })
    );

    setPaymentTermsList(paymentTerms);
  }, [baseSetting]);

  const inspectingHeaders = (
    <tr>
      <th>DESCRIPTION</th>
      <th>STRENGTH/UNIT</th>
      <th>PACKAGE TYPE</th>
      <th>QUANTITY</th>
    </tr>
  );

  const mainHeaders = (
    <tr>
      <th>DESCRIPTION</th>
      <th>STRENGTH/UNIT</th>
      <th>PACKAGE TYPE</th>
      {companyType?.toLowerCase() === "distributor" && !isViewing ? (
        <th style={{ maxWidth: "5rem" }}>STOCK QUANTITY</th>
      ) : (
        ""
      )}
      <th>QUANTITY</th>
      <th>UNIT PRICE(₦)</th>
      <th>TOTAL(₦)</th>
    </tr>
  );

  const getInspectingData = (product: KeyValueObj) => (
    <tr>
      <td>{`${product?.brand_name}(${product?.product_form || ""})`}</td>
      <td>{`${product?.product_strength || ""}${
        product?.product_strength_unit || ""
      }`}</td>
      <td>{product?.package_type || ""}</td>
      <td>{product?.quantity}</td>
    </tr>
  );

  const getMainData = (product: KeyValueObj) => (
    <tr>
      <td>{`${product?.brand_name}(${product?.product_form || ""})`}</td>
      <td>{`${product?.product_strength || ""}${
        product?.product_strength_unit || ""
      }`}</td>
      <td>{product?.package_type || ""}</td>
      {companyType?.toLowerCase() === "distributor" && !isViewing ? (
        <td>{rowStockQuantities?.[product?.id]}</td>
      ) : (
        ""
      )}
      <td>
        {isViewing ? (
          product?.quantity
        ) : (
          <input
            onChange={(event) =>
              handleQuantityChange?.(
                event.target.value,
                product?.id,
                rowCosts?.[product?.id]
              )
            }
            type="number"
            value={rowQuantities?.[product?.id] ?? 0}
            max={product?.quantity}
          />
        )}
      </td>
      <td>
        {isViewing ? (
          showNA ? (
            "N/A"
          ) : (
            formatToNaira(product?.price_per_unit)
          )
        ) : (
          <input
            onChange={(event) =>
              handleInputChange?.(
                event.target.value,
                product?.id,
                rowQuantities?.[product?.id]
              )
            }
            value={rowCosts?.[product?.id] ?? 0}
            type="number"
          />
        )}
      </td>
      <td>
        {showNA
          ? "N/A"
          : formatToNaira(
              isViewing
                ? product?.price_per_unit * product?.quantity
                : isNaN(rowTotals?.[product?.id])
                ? 0
                : rowTotals?.[product?.id]
            )}
      </td>
    </tr>
  );

  return (
    <RequestTableWrapper>
      <table>
        <thead>
          {!isViewing && inspecting ? inspectingHeaders : mainHeaders}
        </thead>
        <tbody>
          {products?.map((product: KeyValueObj) => {
            return !isViewing && inspecting
              ? getInspectingData(product)
              : getMainData(product);
          })}
        </tbody>
      </table>
      {inspecting ? null : (
        <div className="other-container">
          {isViewing ? (
            <div className="account-details viewing">
              <div className="account-detail">
                <h3 className="account-title">Payment Term</h3>
                <h4>{rfq?.payment_term}</h4>
              </div>

              <div className="account-detail">
                <h3 className="account-title">Remark</h3>
                {isViewing ? (
                  <h4 style={{ width: "100%" }}>{terms}</h4>
                ) : (
                  <textarea
                    value={terms}
                    onChange={(event) =>
                      handleAccountChange?.("terms", event.target.value)
                    }
                    style={{ width: "100%" }}
                  ></textarea>
                )}
              </div>

              <div className="account-detail">
                <h3 className="account-title">Expiry Date</h3>
                <h4>{quotationExpiry}</h4>
              </div>
            </div>
          ) : null}

          <div className="other-details">
            <div className="quote-detail">
              <h3>Sub Total</h3>
              <h4>{showNA ? "N/A" : formatToNaira(subTotal)}</h4>
            </div>
            <div className="quote-detail">
              <h3>Discount(%)</h3>
              {isViewing ? (
                <h4>{showNA ? "N/A" : discount}</h4>
              ) : (
                <input
                  type="number"
                  value={discount}
                  onChange={(event) =>
                    handleOtherInputChange?.("discount", event.target.value)
                  }
                />
              )}
            </div>
            <div className="quote-detail">
              <h3>Tax(%)</h3>
              {isViewing ? (
                <h4>{showNA ? "N/A" : tax}</h4>
              ) : (
                <input
                  type="tax"
                  value={tax}
                  onChange={(event) =>
                    handleOtherInputChange?.("tax", event.target.value)
                  }
                />
              )}
            </div>
            <div className="quote-detail">
              <h3>Logistics</h3>

              {isViewing ? (
                <h4>{showNA ? "N/A" : formatToNaira(logisticsCost)}</h4>
              ) : (
                <input
                  type="number"
                  value={logisticsCost}
                  onChange={(event) =>
                    handleOtherInputChange?.(
                      "logisticsCost",
                      event.target.value
                    )
                  }
                />
              )}
            </div>
            <div className="quote-detail">
              <h3>Total</h3>
              {isViewing ? (
                <h4>{showNA ? "N/A" : formatToNaira(total)}</h4>
              ) : (
                <h4>{formatToNaira(isNaN(total as number) ? 0 : total)}</h4>
              )}
            </div>
          </div>
        </div>
      )}

      {inspecting || isViewing ? null : (
        <div className="account-container">
          <div className="account-details">
            <div className="account-detail">
              <h3 className="account-title">Payment Term</h3>
              <h4>{rfq?.payment_term}</h4>
            </div>

            <div className="account-detail">
              <h3 className="account-title">Remark</h3>

              <textarea
                value={terms}
                onChange={(event) =>
                  handleAccountChange?.("terms", event.target.value)
                }
                style={{ width: "100%" }}
              ></textarea>
            </div>
          </div>
          {isViewing ? null : (
            <div className="counter-options">
              <Select
                options={paymentTermsList}
                placeholder={"Select payment term"}
                prompt={"Counter Payment Term"}
                fullWidth={true}
                shortWidth={false}
                name={"payment_term"}
                indexLevel={0}
                formValues={formValues}
                setFormValues={setFormValues}
                updateData={updateData}
                returnId
              />
              <Input
                prompt="Quotation Expiry Date"
                placeholder="Select quotation expiry date"
                fullWidth
                name={"expiration_date"}
                inputType="date"
                updateData={updateData}
                updateValidity={() => {}}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </div>
          )}
        </div>
      )}
    </RequestTableWrapper>
  );
}

import instockImage from "src/assets/images/in-stock.png";
import expiredImage from "src/assets/images/export.png";
import manufacturedImage from "src/assets/images/manufactured.png";
import productValImage from "src/assets/images/product-value.png";

export const tableHeaders = [
  "Name",
  "Unit",
  "Strength",
  "Storage",
  "Qty",
  "Min Level",
  "Supply",
  "Selling Price",
];
export const tableKeys = [
  "name",
  "unit",
  "strength",
  "storage",
  "quantity",
  "minLevel",
  "supply",
  "selling_price",
];
export const tableData = [
  {
    name: "Adderal 5mg tabs",
    itemId: "MEDEX11122",
    unit: "TAB",
    storage: "A1.21",
    quantity: 300,
    minLevel: 20,
    supply: "In 5 days",
  },
  {
    name: "Chloroquin 5mg tabs",
    itemId: "MEDEX13422",
    unit: "CAP",
    storage: "A1.21",
    quantity: 300,
    minLevel: 20,
    supply: "In 19 days",
  },
  {
    name: "Paracetamol",
    itemId: "MEDEX55522",
    unit: "TAB",
    storage: "BC.21",
    quantity: 300,
    minLevel: 100,
    supply: "In 2 days",
  },
  {
    name: "Lemenfathrine 200mg",
    itemId: "MEDEX11122",
    unit: "PATCH",
    storage: "A1.21",
    quantity: 300,
    minLevel: 30,
    supply: "In 11 days",
  },
];

export const inventoryForm = [
  {
    inputType: "selectInput",
    prompt: "Package type",
    options_name: "package_type",
    name: "package_type",
    placeholder: "Select the package type you wish to add",
    fullWidth: false,
    options: [],
  },
  {
    inputType: "number",
    prompt: "Quantity",
    name: "quantity_received",
    placeholder: "Type the quantity to be added",
    fullWidth: false,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be greater than 0",
      },
    },
  },

  {
    inputType: "text",
    prompt: "Batch Number",
    name: "batch_number",
    placeholder: "Type the batch number",
    fullWidth: false,
    rules: {
      minLength: {
        errorMessage: "should be at least 2 characters",
        expectedValue: 2,
      },
    },
  },

  {
    inputType: "number",
    prompt: "Cost price per unit (NGN)",
    name: "cost_price_per_unit",
    placeholder: "Type the cost price per unit",
    fullWidth: false,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be greater than 0",
      },
    },
  },
  {
    inputType: "number",
    prompt: "Selling price per unit (NGN)",
    name: "selling_price_per_unit",
    placeholder: "Type the selling price per unit",
    fullWidth: false,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be greater than 0",
      },
    },
  },
  {
    inputType: "date",
    prompt: "Manufacture Date",
    name: "manufacture_date",
    placeholder: "Select manufacture date",
    fullWidth: false,
    rules: {
      notInTheFuture: {
        expectedValue: true,
        errorMessage: "Manufacture date cannot be in the future",
      },
    },
  },
  {
    inputType: "date",
    prompt: "Expiry Date",
    name: "expiry_date",
    placeholder: "Select expiry date",
    fullWidth: false,
    rules: {
      notInTheFuture: {
        expectedValue: false,
        errorMessage: "Product has expired",
      },
    },
  },
];

export interface ICardDetails {
  title: string;
  value: string;
  imageUrl: string;
  bg: string;
}

export function getCardDetails(data: any) {
  return [
    {
      title: "Total Products Value",
      value: "100,000",
      imageUrl: productValImage,
      bg: "stat_1",
    },
    {
      title: "Total Products in Stock",
      value: "20,000",
      imageUrl: instockImage,
      bg: "stat_2",
    },
    {
      title: "Manufactured Products",
      value: "55,900",
      imageUrl: manufacturedImage,
      bg: "stat_3",
    },
    {
      title: "Total Expired Products",
      value: "11,000",
      imageUrl: expiredImage,
      bg: "stat_4",
    },
  ];
}

export const getBarchartData = (barUserData: any) => ({
  labels: barUserData?.map((data: any) => data?.year),
  datasets: [
    {
      label: "Stock Value (in carton)",
      data: barUserData?.map((data: any) => data?.achieved),
      backgroundColor: "#ECAF57",
      barThickness: 8,
    },
  ],
});

export const tableHeadersShort = [
  "Name",
  "Item ID",
  "Unit",
  "Storage",
  "Qty",
  "Min Level",
  "Supply",
];
export const tableKeysShort = [
  "name",
  "itemId",
  "unit",
  "storage",
  "quantity",
  "minLevel",
  "supply",
];
export const tableDataShort = [
  {
    name: { value: "Adderal 5mg tabs", type: "string" },
    itemId: { value: "MEDEX11122", type: "string" },
    unit: { value: "TAB", type: "string" },
    storage: { value: "A1.21", type: "string" },
    quantity: { value: 300, type: "string" },
    minLevel: { value: 20, type: "string" },
    supply: { value: "In 5 days", type: "string" },
    id: "1",
  },
  {
    name: { value: "Paracetamol", type: "string" },
    itemId: { value: "MEDEX22122", type: "string" },
    unit: { value: "CAP", type: "string" },
    storage: { value: "C.21", type: "string" },
    quantity: { value: 700, type: "string" },
    minLevel: { value: 200, type: "string" },
    supply: { value: "In 10 days", type: "string" },
    id: "1",
  },
  {
    name: { value: "Omeprazole", type: "string" },
    itemId: { value: "MEDEX22122", type: "string" },
    unit: { value: "CAP", type: "string" },
    storage: { value: "C.21", type: "string" },
    quantity: { value: 700, type: "string" },
    minLevel: { value: 200, type: "string" },
    supply: { value: "In 5 days", type: "string" },
    id: "1",
  },
  {
    name: { value: "Lumenfanthrine", type: "string" },
    itemId: { value: "MEDEX65622", type: "string" },
    unit: { value: "PAP", type: "string" },
    storage: { value: "B.91", type: "string" },
    quantity: { value: 50, type: "string" },
    minLevel: { value: 190, type: "string" },
    supply: { value: "In a day", type: "string" },
    id: "1",
  },
];

import { KeyValue } from "src/common/components/key-value";
import { KeyValueObj } from "src/common/types";
import styled from "styled-components";
import { CompanyTopView } from "src/common/components/quotation-views";

const CompanyWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  border-radius: 0.45rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 1rem 0.2rem;
  }

  .top-container {
  }

  .other-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 2rem;

    .info {
      display: flex;
      gap: 1.5rem 1rem;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .key-wrapper {
      width: 30%;
    }

    .controls {
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
    }

    .centered-controls {
      justify-content: center;
      margin-top: 3rem;
      display: flex;
    }
  }
`;

export interface IViewCompanyProps {
  data: KeyValueObj;
}

export function ViewCompanyDetails({ data }: IViewCompanyProps) {
  return (
    <CompanyWrapper>
      <div className="container">
        <CompanyTopView entity={data} />
        <div className="other-info">
          <h3 className="info-title">Other Information</h3>
          <div className="info">
            <div className="key-wrapper">
              <KeyValue title={"RC Number"} value={data?.rc_number} />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"TIN"} value={data?.tin} />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"Classification"} value={data?.classification} />
            </div>
            <div className="key-wrapper">
              <KeyValue
                title={"Date of Establishment"}
                value={data?.date_of_establishment}
              />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"Medex Code"} value={data?.medex_code} />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"Company Status"} value={data?.company_status} />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"Company type"} value={data?.type} />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"Company State"} value={data?.state} />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"Company Lga"} value={data?.local_govt} />
            </div>
            <div className="key-wrapper">
              <KeyValue title={"Request Status"} value={data?.status} />
            </div>
          </div>
        </div>
      </div>
    </CompanyWrapper>
  );
}

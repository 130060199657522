import { KeyValueObj } from "../types";

export const sleep = (ms: any) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function toParamsFormat(values: KeyValueObj) {
  let respString = "?";
  Object.keys(values).forEach((valKey: string) => {
    respString = respString + `${valKey}=${values[valKey]}&`;
  });

  return respString;
}

export function formatToNaira(value: number | string | undefined) {
  return `₦${new Intl.NumberFormat().format(value as unknown as bigint)}`;
}

export function formatKey(value?: string): string {
  if (!value) return "";
  return value.split("_").join(" ");
}

export function getAddress(address?: KeyValueObj) {
  if (!address) return "";
  let returnAddress = address?.address_line_one ?? "";
  if (returnAddress && address?.address_line_two) {
    if (returnAddress.substring(returnAddress.length - 1) !== ",") {
      returnAddress = returnAddress + ", " + address?.address_line_two;
    } else {
      returnAddress = returnAddress + " " + address?.address_line_two;
    }
  }

  return returnAddress;
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (this: unknown, ...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: unknown, ...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}


export async function fitImageInSquareBox(file: File, boxSize: number): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      img.src = event.target?.result as string;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Canvas context could not be created"));
        return;
      }

      // Set up a temporary canvas to calculate the average color
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");

      if (!tempCtx) {
        reject(new Error("Temporary canvas context could not be created"));
        return;
      }

      tempCanvas.width = img.width;
      tempCanvas.height = img.height;
      tempCtx.drawImage(img, 0, 0, img.width, img.height);

      // Get image data and calculate the average color
      const imageData = tempCtx.getImageData(0, 0, img.width, img.height);
      const { data } = imageData;
      let r = 0, g = 0, b = 0, pixelCount = 0;

      for (let i = 0; i < data.length; i += 4) {
        r += data[i];
        g += data[i + 1];
        b += data[i + 2];
        pixelCount++;
      }

      r = Math.floor(r / pixelCount);
      g = Math.floor(g / pixelCount);
      b = Math.floor(b / pixelCount);

      // Set the square canvas size and fill with the average color
      canvas.width = boxSize;
      canvas.height = boxSize;
      ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
      ctx.fillRect(0, 0, boxSize, boxSize);

      // Determine the scaling factor
      const scale = Math.min(boxSize / img.width, boxSize / img.height);

      // Calculate the new image dimensions
      const newWidth = img.width * scale;
      const newHeight = img.height * scale;

      // Calculate padding to center the image within the square
      const xPadding = (boxSize - newWidth) / 2;
      const yPadding = (boxSize - newHeight) / 2;

      // Draw the image in the center with padding
      ctx.drawImage(img, xPadding, yPadding, newWidth, newHeight);

      // Convert canvas to blob and create a new File object
      canvas.toBlob((blob) => {
        if (blob) {
          const newFile = new File([blob], file.name, { type: "image/png" });
          resolve(newFile);
        } else {
          reject(new Error("Failed to convert canvas to Blob"));
        }
      }, "image/png");
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };

    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(file);
  });
}


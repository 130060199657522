import styled from "styled-components";
import {
  ChangeEvent,
  DragEvent,
  useState,
  SetStateAction,
  Dispatch,
} from "react";

import { FaFilePdf } from "react-icons/fa";
import { BsCheck2Square, BsFileImage } from "react-icons/bs";
import { FilesObject } from "../../../common/types";
import { AiOutlineClose } from "react-icons/ai";

interface FileProps {
  dragEntered?: boolean;
  error?: string;
  fileName?: string;
}

export const FileWrapper = styled.div<FileProps>`
  width: 100%;
  min-height: 5rem;
  border: ${({ theme, dragEntered }) =>
    dragEntered
      ? `1px solid ${theme.bg.progress}`
      : `1px dashed ${theme.text.muted_200}`};
  ${({ theme, dragEntered }) =>
    dragEntered && `box-shadow: ${theme.shadow.drag_over}`};
  background-color: ${({ theme }) => theme.bg.tert_400};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .close-icon-wrapper {
    position: absolute;
    top: 0.2rem;
    right: 1rem;
    cursor: pointer;
    z-index: 10;

    .close-icon {
      font-size: 1.1rem;
      :hover {
        color: red;
      }
    }
  }

  .file-name {
    justify-content: flex-start;
    flex: 4;
    margin-left: 1.5rem;

    .file-icon {
      color: ${({ theme }) => theme.bg.icon};
      font-size: 1.5rem;
    }

    .required {
      color: red;
      padding: 0.1rem;
    }

    h3 {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1.2rem;
      margin-left: 0.875rem;
      text-transform: capitalize;

      @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
        font-size: 0.85rem;
      }
    }
  }

  .controls {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2rem;

    .input {
      cursor: pointer;
    }

    .error {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.text.error};
      margin-top: 0.5rem;
      width: 55%;
    }

    .success {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.85rem;
      color: ${({ theme }) => theme.text.success};
      gap: 0.3rem;
      width: 55%;
    }

    h4 {
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 0.9375rem;

      @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
        display: none;
      }
    }

    label {
      color: ${({ theme }) => theme.bg.secondary};
      text-decoration: underline;
      font-weight: 500;
      font-size: 1rem;
      line-height: 0.9375rem;
      margin-left: 0.3125rem;
      position: absolute;
      z-index: 2;
      right: 2.5rem;
    }
  }

  .drag-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export interface IFileInputProps {
  name: string;
  setFiles: Dispatch<SetStateAction<FilesObject>>;
  title: string;
  cancelable?: boolean;
  onClose?: () => void;
  files: FilesObject;
  isRequired?: boolean;
  imageOnly?: boolean;
}

export function FileInput({
  name,
  title,
  setFiles,
  cancelable,
  files,
  onClose,
  isRequired,
  imageOnly,
}: IFileInputProps) {
  const [dragEntered, setDragEntered] = useState(false);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");
  const MAX_SIZE = 2000000;

  function handleFileChanged(e: ChangeEvent<HTMLInputElement>) {
    const chosenFile = e.target.files && e.target.files[0];
    chosenFile && handleFile(chosenFile as File);
  }

  function handleFile(chosenFile: File) {
    const fileType = chosenFile.type;
    const mainType = fileType.split("/")[0];
    const fileSize = chosenFile.size;
    const fileName = chosenFile.name;


    if (imageOnly) {
      if (mainType !== "image" && fileSize > MAX_SIZE) {
        setError("File must be of image format and size not more than 2MB");
        setFileName("");
        return;
      }
      if (mainType !== "image") {
        setError("File must be of image type");
        setFileName("");

        return;
      }
      if (fileSize > MAX_SIZE) {
        setError("File size must not be more than 2MB");
        setFileName("");
        return;
      }
    } else {
      if (
        fileType !== "application/pdf" &&
        mainType !== "image" &&
        fileSize > MAX_SIZE
      ) {
        setError(
          "File must be either pdf or image format and size not more than 2MB"
        );
        setFileName("");
        return;
      }
      if (fileType !== "application/pdf" && mainType !== "image") {
        setError("File must be a pdf or image type");
        setFileName("");

        return;
      }
      if (fileSize > MAX_SIZE) {
        setError("File size must not be more than 2MB");
        setFileName("");
        return;
      }
    }

    let alreadyExist = false;
    Object.values(files).forEach((file: File) => {
      if (file.name === fileName) {

        alreadyExist = true;
        return;
      }
    });

    if (alreadyExist) {
      setError("File with same name already selected, choose another file.");
      return;
    }

    //If it gets here then are conditions are fulfilled
    setError("");
    setFileName(fileName);
    setFiles((prev: FilesObject) => {
      return {
        ...prev,
        [name]: chosenFile,
      };
    });
  }

  function handleDragEnter(e: DragEvent) {
    e.preventDefault();
    setDragEntered(true);
  }

  function handleDragLeave(e: DragEvent) {
    e.preventDefault();
    setDragEntered(false);
  }

  function handleDrop(e: DragEvent) {
    e.preventDefault();
    setDragEntered(false);
    const chosenFile = e.dataTransfer.files && e.dataTransfer.files[0];
    chosenFile && handleFile(chosenFile as File);
  }

  function handleDragOver(e: DragEvent) {
    e.preventDefault();
  }

  return (
    <FileWrapper dragEntered={dragEntered} error={error} fileName={fileName}>
      {onClose && (
        <div className="close-icon-wrapper">
          <AiOutlineClose className="close-icon" onClick={onClose} />
        </div>
      )}
      <div className="file-name flex-row">
        {imageOnly ? (
          <BsFileImage className="file-icon" />
        ) : (
          <FaFilePdf className="file-icon" />
        )}

        <h3>
          {title} {isRequired ? <span className="required">*</span> : null}
        </h3>
      </div>
      <div className="controls flex-row">
        <h4>Drag and drop file here or</h4>
        {error && <p className="error">{error}</p>}
        {fileName && (
          <p className="success">
            <BsCheck2Square className="sucess-icon" />
            {fileName}
          </p>
        )}
        <label className="input">
          browse file
          <input
            onChange={handleFileChanged}
            type="file"
            name="image"
            style={{ display: "none" }}
          />
        </label>
      </div>
      <div
        className="drag-overlay"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      ></div>
    </FileWrapper>
  );
}

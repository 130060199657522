import {
  useEffect,
  useState,
  MouseEvent,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import {
  BsCaretDownFill,
  BsFillCheckSquareFill,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { PiCaretRightBold } from "react-icons/pi";
import { usePositioning } from "../../../hooks/usePositioning";
import { ButtonWrapper } from "../../../pages/register/style";
import { IFilterInput, KeyValueObj } from "../../types";
import { validateData } from "../../utils";
import { ClipSpinner } from "../spinner";
import { TableContainer, TableWrapper, TR } from "./subs/style";
import { Button } from "../button";
import { GiSettingsKnobs } from "react-icons/gi";
import { useStore } from "src/hooks/useContexts";
import { TableModals } from "./subs/modals";
import { TableLoader } from "./subs/TableLoader";
import { Options } from "../action-popup";
import { EmptyTable } from "./subs/EmptyTable";
import { ErrorLoading } from "./subs/ErrorLoading";

let timer: any;
interface IDropDownOptions {
  label: string;
  action: (row: KeyValueObj) => void;
}

interface TableProps {
  tableHeaders: (string | JSX.Element)[];
  tableData: KeyValueObj[];
  actionsData?: any[];
  tableKeys?: string[];
  full?: boolean;
  spaced?: boolean;
  headerBg?: string;
  lined?: boolean;
  rowLineColor?: string;
  withCheck?: boolean;
  isRadio?: boolean;
  shouldHover?: boolean;
  isForm?: boolean;
  submitLabel?: string;
  submitLoading?: boolean;
  clickable?: boolean;
  onTDClick?: (rowId: string | number, columnId: string | number) => void;
  onTRClick?: (id: string | number) => void;
  selectedList?: KeyValueObj[];
  setSelectedList?: Dispatch<SetStateAction<KeyValueObj[]>>;
  onFormSubmit?: (formValues: KeyValueObj) => void;
  onMouseEnter?: (e: MouseEvent<HTMLElement>, rowId: string) => void;
  onMouseLeave?: (e: MouseEvent<HTMLElement>, rowId: string) => void;
  onMouseInnerEnter?: (e: MouseEvent<HTMLElement>, rowId: string) => void;
  onMouseInnerLeave?: (e: MouseEvent<HTMLElement>, rowId: string) => void;
  filterInputs?: IFilterInput[];
  handleFilter?: (values: KeyValueObj) => void;
  tableTitle?: string;
  tableSubtitle?: string;
  handleSearch?: (searchString: string) => void;
  loading?: boolean;
  error?: boolean;
  handleRetryLoading?: () => void;
  dropDownOptions?: IDropDownOptions[];
  showDropDown?: boolean;
  handleDropDownClicked?: (row: KeyValueObj) => void;
  removeFilters?: boolean;
  errorLoading?: boolean;
  isSearching?: boolean;
  onRetry?: () => void;
  headerAction?: { label: string; action: () => void };
}

const Table = ({
  filterInputs,
  tableHeaders,
  tableData,
  tableKeys,
  full,
  spaced,
  headerBg,
  withCheck,
  isRadio,
  onTDClick,
  onTRClick,
  selectedList,
  setSelectedList,
  lined,
  rowLineColor,
  shouldHover,
  isForm,
  submitLabel,
  submitLoading,
  onFormSubmit,
  clickable,
  onMouseInnerLeave,
  onMouseInnerEnter,
  handleFilter,
  tableTitle,
  tableSubtitle,
  loading,
  error,
  handleRetryLoading,
  handleSearch,
  dropDownOptions,
  showDropDown,
  handleDropDownClicked,
  removeFilters,
  errorLoading,
  isSearching,
  onRetry,
  headerAction,
}: TableProps) => {
  function onTDButtonClicked(e: any, callBack: any, rowId: string) {
    e.stopPropagation();
    callBack(e, rowId);
  }

  const [formValues, setFormValues] = useState<KeyValueObj>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [validations, setValidations] = useState<KeyValueObj>({});
  const [, setSelectOptions] = useState<KeyValueObj[]>([]);
  const [, setShowOptions] = useState(false);
  const [, setSelectType] = useState("");

  const [loadingStates, setLoadingStates] = useState<any>({});
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [, setCurrentId] = useState("");
  const [, setCurrentName] = useState("");

  const [currentActionId, setCurrentActionId] = useState("");

  //Hookes

  const [setPosition] = usePositioning();
  const { setCurrentLevel2Modal, setLevel2ModalOptions } = useStore();

  const tableBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //set up all validations and initial loading states of the checkbox
    const tempValidations: KeyValueObj = {};
    const tempLoadingStates: KeyValueObj = {};
    const tempFormValues: KeyValueObj = {};
    const tempErrorMessages: KeyValueObj = {};

    tableData?.forEach((input) => {
      const currentIdInner = input?.id;
      Object.keys(input).forEach((objKey: string) => {
        if (input[objKey]?.type === "input") {
          tempValidations[input[objKey]?.name + "_" + currentIdInner] = false;
          tempLoadingStates[currentIdInner] = {
            ...tempLoadingStates[currentIdInner],
            [input[objKey]?.name]: false,
          };
          tempFormValues[currentIdInner] = {
            ...tempFormValues[currentIdInner],
            [input[objKey].name]:
              input[objKey].inputType === "checkBox" ? [] : "",
          };
          tempErrorMessages[currentIdInner] = {
            ...tempErrorMessages[currentIdInner],
            [input[objKey].name]: [],
          };
        }
      });

      //tempValidations[input.name] = false;
    });
    setValidations(tempValidations);
    setLoadingStates(tempLoadingStates);
    setFormValues(tempFormValues);
    setErrorMessages(tempErrorMessages);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  //setting the validity of the form
  useEffect(() => {
    setIsFormValid(true);
    //go through all validations and set isFormValid to false if any of the
    //validation is false
    Object.values(validations).forEach((validation: boolean) => {
      if (validation === false) {
        setIsFormValid(false);
        return;
      }
    });
  }, [validations]);

  useEffect(() => {
    clearTimeout(timer);
  }, []);

  async function onSelectClicked(
    e: any,
    id: string,
    name: string,
    options: any,
    selectType: string,
    fetchOptions?: any
  ) {
    e.stopPropagation();
    setPosition(e);
    setCurrentId(id);
    setCurrentName(name);
    setShowOptions(true);
    setSelectType(selectType);

    //set the options
    if (fetchOptions) {
      //set loading state
      setLoadingStates({
        ...loadingStates,
        [id]: {
          ...loadingStates[id],
          [name]: true,
        },
      });

      //fetch the data with the fetch function
      const fetchedOptions = await fetchOptions(id);
      setSelectOptions(fetchedOptions);

      //clear loading state
      setLoadingStates({
        ...loadingStates,
        [id]: {
          ...loadingStates[id],
          [name]: false,
        },
      });
    } else {
      setSelectOptions(options);
    }
  }

  function onFilter(values: KeyValueObj) {
    setCurrentLevel2Modal("");
    handleFilter?.(values);
  }

  function handleFilterClick() {
    setLevel2ModalOptions({
      filterInputs,
      onFilter,
    });
    filterInputs && setCurrentLevel2Modal("table-filters");
  }

  function onActionClick(data: KeyValueObj) {
    setCurrentActionId((prev: string) => (prev === data?.id ? "" : data?.id));
    handleDropDownClicked && handleDropDownClicked(data);
  }

  function onInputChange(e: any, id: string, name: string, rules: any) {
    e.stopPropagation();
    const value = e.target.value;
    //check for validation
    const { isValid, errorMessages: obtainedError } = validateData(
      value,
      rules,
      formValues[id]
    );

    //update validations object
    setValidations({
      ...validations,
      [name + "_" + id]: isValid,
    });

    //set errorMessage
    setErrorMessages({
      ...errorMessages,
      [id]: {
        ...errorMessages[id],
        [name]: obtainedError,
      },
    });

    //set value if it's valid
    if (isValid) {
      setFormValues({
        ...formValues,
        [id]: {
          ...formValues[id],
          [name]: value,
        },
      });
    }
  }

  function onSearch(e: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleSearch?.(e.target.value);
    }, 500);
  }
  function onCheckClicked(row: KeyValueObj) {
    //the table row id will be added to the list if it does not already exist
    const exists = selectedList?.find(
      (distributor: KeyValueObj) => distributor.id === row.id
    );
    if (!exists) {
      isRadio
        ? setSelectedList?.([row])
        : setSelectedList?.((prev: KeyValueObj[]) => [...prev, row]);
    } else {
      setSelectedList?.((prev: KeyValueObj[]) => {
        prev = prev.filter(
          (distributor: KeyValueObj) => distributor.id !== row.id
        );
        return prev.slice();
      });
    }
  }

  function onCheckAllClicked(tableData: KeyValueObj[]) {
    if (isRadio) return;
    if (tableData?.length === selectedList?.length) {
      setSelectedList?.([]);
    } else {
      setSelectedList?.(tableData);
    }
  }

  let totalCols = tableKeys?.length || 0;
  if (withCheck || isRadio) {
    totalCols += 1;
  }
  if ((dropDownOptions?.length as number) > 0) {
    totalCols += 1;
  }

  function scrollBodyBy(value: number) {
    tableBodyRef?.current?.scrollBy({ top: value, behavior: "smooth" });
  }

  return (
    <>
      <TableModals />

      <TableWrapper>
        {(tableTitle?.length as number) > 0 ||
        (tableSubtitle?.length as number) > 0 ? (
          <div className="title-wrapper">
            {(tableTitle?.length as number) > 0 ? (
              <h2 className="title">{tableTitle}</h2>
            ) : null}
            {(tableSubtitle?.length as number) > 0 ? (
              <h3 className="dash-text">{tableSubtitle}</h3>
            ) : null}
          </div>
        ) : null}

        <TableContainer
          headerBg={headerBg}
          full={full}
          allSelected={selectedList?.length === tableData?.length}
        >
          {removeFilters ? null : (
            <div className="table-controls">
              <div className="flex-row search-wrapper">
                <input
                  className="search-input"
                  type="text"
                  placeholder="&#61442;   Search"
                  onChange={onSearch}
                />
                <div
                  onClick={handleFilterClick}
                  className="flex-row search-filter"
                >
                  <GiSettingsKnobs className="filter-icon" />
                  <h3>Filter by</h3>
                </div>
                {headerAction ? (
                  <div className="header-action-wrapper">
                    <Button secondary onClick={headerAction?.action}>
                      {headerAction?.label}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          )}

          <div ref={tableBodyRef}>
            <table>
              <thead>
                <tr>
                  {tableHeaders ? (
                    <>
                      {withCheck || isRadio ? (
                        <th>
                          <BsFillCheckSquareFill
                            onClick={() => onCheckAllClicked?.(tableData)}
                            className="check-icon header-check"
                          />
                        </th>
                      ) : null}

                      {tableHeaders.map(
                        (header: string | JSX.Element, i: number) => (
                          <th key={i}>{header}</th>
                        )
                      )}
                      {dropDownOptions && dropDownOptions?.length > 0 ? (
                        <th>Action</th>
                      ) : null}
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {!loading && tableData?.length === 0 && !errorLoading ? (
                  <EmptyTable
                    isSearching={!!isSearching}
                    colSize={tableHeaders.length}
                  />
                ) : null}
                {!loading && tableData?.length === 0 && errorLoading ? (
                  <ErrorLoading
                    onRetry={() => onRetry?.()}
                    colSize={tableHeaders?.length}
                  />
                ) : null}
                {loading ? (
                  <TableLoader columnSize={totalCols} />
                ) : (
                  tableData?.map((data, i) => {
                    return (
                      <TR
                        full={full}
                        lined={lined}
                        rowLineColor={rowLineColor}
                        shouldHover={shouldHover}
                        spaced={spaced}
                        selected={
                          selectedList &&
                          selectedList.findIndex(
                            (list) => list.id === data.id
                          ) !== -1
                        }
                        key={i}
                        onClick={() => onTRClick && onTRClick(data.id)}
                        indexValue={9999 - i}
                      >
                        {withCheck || isRadio ? (
                          <td onClick={() => onCheckClicked?.(data)}>
                            <BsFillCheckSquareFill className="check-icon" />
                          </td>
                        ) : null}
                        {tableKeys?.map((theKey: string, i: number) => (
                          <td
                            style={data[theKey]?.style ?? {}}
                            key={theKey}
                            onClick={() => onTDClick && onTDClick(data.id, i)}
                            onMouseEnter={(e: MouseEvent<HTMLElement>) =>
                              data[theKey]?.onMouseEnter &&
                              data[theKey]?.onMouseEnter(e, data.id)
                            }
                            onMouseLeave={(e: MouseEvent<HTMLElement>) =>
                              data[theKey]?.onMouseLeave &&
                              data[theKey]?.onMouseLeave(e, data.id)
                            }
                          >
                            {data[theKey]?.type === "string" &&
                              data[theKey]?.value}
                            {data[theKey]?.type === "image" && (
                              <img
                                className="table-image"
                                src={data[theKey]?.value}
                                alt={theKey}
                              />
                            )}
                            {data[theKey]?.type === "button" &&
                              (data[theKey]?.buttonType === "dots" ? (
                                <button
                                  disabled={data[theKey]?.buttonLoading}
                                  onClick={(e: any) =>
                                    onTDButtonClicked(
                                      e,
                                      data[theKey]?.onClick,
                                      data.id
                                    )
                                  }
                                  className="dotted-button"
                                >
                                  {data[theKey]?.buttonLoading ? (
                                    "Processing..."
                                  ) : (
                                    <BsThreeDotsVertical className="button-icon" />
                                  )}
                                </button>
                              ) : data[theKey]?.buttonType === "secondary" ? (
                                <Button
                                  width="6rem"
                                  secondary
                                  onClick={(e: any) =>
                                    onTDButtonClicked(
                                      e,
                                      data[theKey]?.onClick,
                                      data.id
                                    )
                                  }
                                  disabled={data[theKey]?.buttonLoading}
                                >
                                  {data[theKey]?.buttonName}
                                </Button>
                              ) : data[theKey]?.buttonType === "danger" ? (
                                <button
                                className={
                                  data[theKey]?.buttonType + " danger-button"
                                }
                                  onClick={(e: any) =>
                                    onTDButtonClicked(
                                      e,
                                      data[theKey]?.onClick,
                                      data.id
                                    )
                                  }
                                  disabled={data[theKey]?.buttonLoading}
                                >
                                  {data[theKey]?.buttonName}
                                </button>
                              ) : (
                                <button
                                  className={
                                    data[theKey]?.buttonType + " regular-button"
                                  }
                                  onClick={(e: any) =>
                                    onTDButtonClicked(
                                      e,
                                      data[theKey]?.onClick,
                                      data.id
                                    )
                                  }
                                >
                                  {data[theKey]?.buttonName}
                                </button>
                              ))}
                            {data[theKey]?.type === "status" && (
                              <div
                                onMouseEnter={(e: MouseEvent<HTMLElement>) =>
                                  data[theKey]?.onMouseInnerEnter &&
                                  data[theKey]?.onMouseInnerEnter(e, data.id)
                                }
                                onMouseLeave={(e: MouseEvent<HTMLElement>) =>
                                  data[theKey]?.onMouseInnerLeave &&
                                  data[theKey]?.onMouseInnerLeave(e, data.id)
                                }
                                className={`status ${
                                  data[theKey]?.statusType ??
                                  data?.[theKey]?.value
                                }`}
                              >
                                <div
                                  className={`status-inner ${
                                    data[theKey]?.statusType ??
                                    data?.[theKey]?.value
                                  }`}
                                >
                                  <div className="status-dot"></div>
                                  <h3>{data[theKey]?.value}</h3>
                                </div>
                              </div>
                            )}
                            {data[theKey]?.type === "input" && (
                              <div className="input-wrapper">
                                {data[theKey]?.inputType === "checkBox" ? (
                                  <label
                                    onClick={(e: any) =>
                                      onSelectClicked(
                                        e,
                                        data.id,
                                        data[theKey]?.name,
                                        data[theKey]?.options,
                                        "checkBox",
                                        data[theKey]?.fetchOptions
                                      )
                                    }
                                  >
                                    <span>
                                      {formValues[data.id] &&
                                      formValues[data.id][data[theKey]?.name]
                                        .length > 0 ? (
                                        <span>
                                          Selected{" "}
                                          <BsFillCheckSquareFill className="selected-icon" />
                                        </span>
                                      ) : (
                                        data[theKey]?.placeholder
                                      )}
                                    </span>
                                    <span>
                                      <BsCaretDownFill />
                                    </span>
                                  </label>
                                ) : data[theKey]?.inputType === "select" ? (
                                  <label
                                    onClick={(e: any) =>
                                      onSelectClicked(
                                        e,
                                        data.id,
                                        data[theKey]?.name,
                                        data[theKey]?.options,
                                        "select"
                                      )
                                    }
                                  >
                                    <span>
                                      {formValues[data.id] &&
                                      formValues[data.id][data[theKey]?.name]
                                        ? formValues[data.id][
                                            data[theKey]?.name
                                          ]
                                        : data[theKey]?.placeholder}
                                    </span>
                                    <span>
                                      <BsCaretDownFill />
                                    </span>
                                  </label>
                                ) : (
                                  <>
                                    <input
                                      onChange={(e) =>
                                        onInputChange(
                                          e,
                                          data.id,
                                          data[theKey]?.name,
                                          data[theKey]?.rules
                                        )
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                      type={data[theKey]?.inputType}
                                    />
                                    {errorMessages[data.id] && (
                                      <h3 className="error-message">
                                        {
                                          errorMessages[data.id][
                                            data[theKey]?.name
                                          ]
                                        }
                                      </h3>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </td>
                        ))}
                        {dropDownOptions && dropDownOptions?.length > 0 ? (
                          <div className="actions-wrapper">
                            <td>
                              <button
                                onClick={() => onActionClick(data)}
                                className="dotted-button"
                              >
                                <BsThreeDotsVertical className="button-icon" />
                              </button>

                              {currentActionId === data?.id && showDropDown ? (
                                <>
                                  {/* <Backdrop
                                    backdropClicked={() =>
                                      setCurrentActionId("")
                                    }
                                  /> */}
                                  <div className="options-wrapper">
                                    <Options
                                      callActionWith={data}
                                      actions={dropDownOptions}
                                      scrollLastToView
                                      scrollParentBy={scrollBodyBy}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </td>
                          </div>
                        ) : null}

                        {clickable ? (
                          <td>
                            <PiCaretRightBold className="click-icon" />
                          </td>
                        ) : null}
                      </TR>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableContainer>

        {isForm && (
          <ButtonWrapper>
            <Button
              onClick={() => onFormSubmit && onFormSubmit(formValues)}
              disabled={!isFormValid || submitLoading}
            >
              {submitLoading ? (
                <ClipSpinner />
              ) : submitLabel ? (
                submitLabel
              ) : (
                "Continue"
              )}
            </Button>
          </ButtonWrapper>
        )}
      </TableWrapper>
    </>
  );
};

export default Table;

import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DataWrapper } from "../../../../common/components/dataWrapper";
import { KeyValueContainer } from "../../../../common/components/key-value-wrapper";
import Tab from "../../../../common/components/Tab/Tab";
import { COMPANIES } from "../../../../common/constants";
import { KeyValueObj } from "../../../../common/types";
import useAxiosCall from "../../../../hooks/useAxiosCall";
import { AdminActions } from "../components/admin-actions";
import { Carousel } from "src/common/components/carousel";
import { PageTitleNavs } from "src/common/components/page-title-navs";

const CompanyDetailsWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
     padding: 2rem .5rem;
    }

  .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .tabs-views {
    background-color: ${({ theme }) => theme.bg.prim_500};
    padding: 4rem 2rem;
  }

  .carousel-wrapper {
    min-height: 20rem;
    padding: 2rem 2rem;
    width: 100%;
  }
`;

export interface IComapanyDetailsProps {}

export function ComapanyDetails(props: IComapanyDetailsProps) {
  const [companyInfo, setCompanyInfo] = useState<KeyValueObj[]>([]);
  const [contactInfo, setContactInfo] = useState<KeyValueObj[]>([]);
  const [documents, setDocuments] = useState<KeyValueObj[]>([]);
  const [currentTab, setCurrentTab] = useState("Company Information");
  const [status, setStatus] = useState<string>("");
  const [retry, setRetry] = useState(0);

  const { id, type } = useParams();
  const [fetchCompany, fetchedCompany, companyError, companyLoading] =
    useAxiosCall();

  useEffect(() => {
    fetchCompany({
      url: COMPANIES + "/" + id,
      method: "GET",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, retry]);

  useEffect(() => {
    const innerCompanyInfo: KeyValueObj[] = [];
    const innerContactInfo: KeyValueObj[] = [];
    const innerDocuments: KeyValueObj[] = [];

    fetchedCompany &&
      Object.keys(fetchedCompany).forEach((objKey: string) => {
        let url,
          contentType,
          type = "";

        switch (objKey) {
          case "approval_status":
          case "contact":
          case "id":
          case "users":
          case "api_key":
            break;

          case "certificate_of_incorporation":
            contentType = fetchedCompany[objKey]?.content_type;
            url = fetchedCompany[objKey]?.url;
            type = contentType === "application/pdf" ? "pdf" : "image";
            if (fetchedCompany[objKey])
              innerDocuments.push({
                title: "Certificate of Incorporation",
                fileUrl: url,
                type,
              });
            break;

          case "nafdac_registration_certificate":
            contentType = fetchedCompany[objKey]?.content_type;
            url = fetchedCompany[objKey]?.url;
            type = contentType === "application/pdf" ? "pdf" : "image";
            if (fetchedCompany[objKey])
              innerDocuments.push({
                title: "NAFDAC registeration certificate",
                fileUrl: url,
                type,
              });
            break;

          case "tax_clearance":
            contentType = fetchedCompany[objKey]?.content_type;
            url = fetchedCompany[objKey]?.url;
            type = contentType === "application/pdf" ? "pdf" : "image";
            if (fetchedCompany[objKey])
              innerDocuments.push({
                title: "Tax Clearance",
                fileUrl: url,
                type,
              });
            break;

          case "company_logo":
            contentType = fetchedCompany[objKey]?.content_type;
            url = fetchedCompany[objKey]?.url;
            type = contentType === "application/pdf" ? "pdf" : "image";
            if (fetchedCompany[objKey])
              innerDocuments.push({
                title: "Company Logo",
                fileUrl: url,
                type,
              });
            break;

          case "tin":
            innerCompanyInfo.push({
              title: "TIN",
              value: fetchedCompany[objKey],
            });
            break;

          case "rc_number":
            innerCompanyInfo.push({
              title: "RC Number",
              value: fetchedCompany[objKey],
            });
            break;

          default:
            innerCompanyInfo.push({
              title: objKey.split("_").join(" "),
              value: fetchedCompany[objKey],
            });
        }
      });

    const contact = fetchedCompany?.contacts?.[0];
    contact &&
      Object.keys(contact).forEach((objKey: string) => {
        switch (objKey) {
          case "id":
          case "created_at":
          case "company_id":
          case "updated_at":
          case "settings":
            break;
          default:
            innerContactInfo.push({
              title: objKey.split("_").join(" "),
              value: contact[objKey],
            });
        }
      });
    setCompanyInfo(innerCompanyInfo);
    setContactInfo(innerContactInfo);
    setDocuments(innerDocuments);
    setStatus(fetchedCompany.approval_status);
  }, [fetchedCompany]);

  const tabMap = useMemo(
    () => ({
      companyInfo: "Company Information",
      contactInfo: "Contact Person",
      uploadedDocument: "Documents Uploaded",
    }),
    []
  );

  function handleLeftArrow() {
    switch (currentTab) {
      case tabMap.companyInfo:
        setCurrentTab(tabMap.uploadedDocument);
        break;

      case tabMap.contactInfo:
        setCurrentTab(tabMap.companyInfo);
        break;

      case tabMap.uploadedDocument:
        setCurrentTab(tabMap.contactInfo);
        break;

      default:
        break;
    }
  }

  function handleRightArrow() {
    switch (currentTab) {
      case tabMap.companyInfo:
        setCurrentTab(tabMap.contactInfo);
        break;

      case tabMap.contactInfo:
        setCurrentTab(tabMap.uploadedDocument);
        break;

      case tabMap.uploadedDocument:
        setCurrentTab(tabMap.companyInfo);
        break;

      default:
        break;
    }
  }

  return (
    <CompanyDetailsWrapper>
      <PageTitleNavs
        title="View Company"
        backLink={"/dashboard/admin/companies/" + type}
        bcrumbs={[
          { title: "Companies", link: "/dashboard/admin/companies/" + type },
          { title: "View", link: "#" },
        ]}
      />
      <DataWrapper
        data={fetchedCompany}
        noDataMessage={"No company information to show"}
        loading={companyLoading}
        error={companyError}
        loadingMessage="Loading company data..."
        failedMessage="Could not fetch company data. Check your network and click the button below to retry"
        onFailAction={() => {
          setRetry((prev) => prev + 1);
        }}
      >
        <div className="inner">
          <Tab
            tabsTitle={[
              tabMap.companyInfo,
              tabMap.contactInfo,
              tabMap.uploadedDocument,
            ]}
            onTabClick={(title: string) => setCurrentTab(title)}
            currentTab={currentTab}
          />
          <div className="tabs-views">
            {currentTab === tabMap.companyInfo && (
              <KeyValueContainer data={companyInfo} />
            )}

            {currentTab === tabMap.contactInfo && (
              <KeyValueContainer data={contactInfo} />
            )}
            {currentTab === tabMap.uploadedDocument ? (
              <div className="carousel-wrapper">
                <Carousel pdfHeight={"30rem"} files={documents} />
              </div>
            ) : null}
          </div>

          <AdminActions
            postUrl={COMPANIES + "/approval/" + id}
            initialStatus={status}
            showArrows
            onArrowLeftClick={handleLeftArrow}
            onArrowRightClick={handleRightArrow}
          />
        </div>
      </DataWrapper>
    </CompanyDetailsWrapper>
  );
}

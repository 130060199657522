import styled, { CSSProperties } from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as Carous } from "react-responsive-carousel";
import { KeyValueObj } from "../../types";
import { PdfViewer } from "../pdfViewer";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

const CarouselWrapper = styled.div`
  width: 100%;
  .arrow-button {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.35rem;
    border-radius: 50%;
    opacity: 0.4;
    outline: none;
    background-color: transparent;
    border: none;

    :hover {
      opacity: 1;
      border-width: 2px;
    }

    .arrow-icon {
      font-size: 2rem;
      padding: 0.25rem 0;
      background-color: ${({ theme }) => theme.bg.prim_400};
    }
  }

  .carousel-1 {
  }

  .carousel {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
  }

  .image-thumb {
    border: 1px solid red;
    outline: none;

    :active {
      outline: none;
    }

    :focus {
      outline: none;
    }
  }

  .file-wrapper {
    position: relative;
    max-width: 100%;
    width: 100%;
    background-color: white;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.bg.prim_300};

    img {
      width: 100%;
    }
  }

  .file-label {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    color: ${({ theme }) => theme.text.prim_100};
    font-weight: 600;
    font-size: 1.2rem;
    background-color: ${({ theme }) => theme.bg.prim_400};
    padding: 0.5rem 0.5rem;
    border-radius: 0.2rem;
    z-index: 999; 
    background-color: ${({theme}) => theme.bg.prim_500};
  }

  .indicator {
    background: #fff;
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 50%;
    display: inline-block;
    margin: 0 8px;
    border: 1px solid ${({ theme }) => theme.bg.secondary};
  }

  .selected {
    background-color: ${({ theme }) => theme.bg.secondary};
  }
`;

export interface ICarouselProps {
  files: any[];
  pdfHeight?: string;
}

export function Carousel({ files, pdfHeight }: ICarouselProps) {
  const right: CSSProperties = {
    right: -5,
  };

  const left: CSSProperties = {
    left: -7,
  };

  const prevArrow = <PiCaretLeftBold className="arrow-icon" />;
  const nextArrow = <PiCaretRightBold className="arrow-icon" />;

  const arrowPosMap: KeyValueObj = {
    prev: left,
    next: right,
  };

  const arrowMap: KeyValueObj = {
    prev: prevArrow,
    next: nextArrow,
  };

  function carouselArrow(
    onClickHandler: () => void,
    hasNext: boolean,
    label: string,
    type: string
  ) {
    return (
      hasNext && (
        <button
          type="button"
          onClick={onClickHandler}
          title={label}
          style={{ ...arrowPosMap[type] }}
          className="arrow-button"
        >
          {arrowMap[type]}
        </button>
      )
    );
  }

  // const renderIndicator = (
  //   onClickHandler: any,
  //   isSelected: boolean,
  //   index: number,
  //   label: string
  // ) => {
  //   if (isSelected) {
  //     return (
  //       <li
  //         className="indicator selected"
  //         aria-label={`Selected: ${label} ${index + 1}`}
  //         title={`Selected: ${label} ${index + 1}`}
  //       />
  //     );
  //   }
  //   return (
  //     <li
  //       className="indicator"
  //       onClick={onClickHandler}
  //       onKeyDown={onClickHandler}
  //       value={index}
  //       key={index}
  //       role="button"
  //       tabIndex={0}
  //       title={`${label} ${index + 1}`}
  //       aria-label={`${label} ${index + 1}`}
  //     />
  //   );
  // };

  let showThumbs = true;
  files?.forEach((file: KeyValueObj) => {
    if (file?.type === "pdf") showThumbs = false;
  });

  return (
    <CarouselWrapper>
      <Carous
        dynamicHeight={true}
        showThumbs={showThumbs}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          carouselArrow(onClickHandler, hasPrev, label, "prev")
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          carouselArrow(onClickHandler, hasNext, label, "next")
        }
        // renderIndicator={(onClickHandler, isSelected, index, label) =>
        //   renderIndicator(onClickHandler, isSelected, index, label)
        // }
        thumbWidth={60}
        className="carousel-1"
      >
        {files?.map((file: any, i: number) => (
          <div className="file-wrapper" key={i}>
            {file?.type === "image" ? (
              <img src={file?.fileUrl} alt="carousel" />
            ) : null}
            {file?.type === "pdf" ? (
              <PdfViewer pdfHeight={pdfHeight} fileUrl={file?.fileUrl} />
            ) : null}
            {file?.title ? (
              <label className="file-label">{file?.title}</label>
            ) : null}
          </div>
        ))}
      </Carous>
    </CarouselWrapper>
  );
}

import useAuth from "./useContexts";

export function useAuthDetails() {
  const { auth } = useAuth();

  return {
    firstName: auth?.user?.first_name,
    middleName: auth?.user?.middle_name,
    lastName: auth?.user?.last_name,
    email: auth?.user?.email,
    companyAddress: auth?.user?.company?.corporate_address,
    address: auth?.user?.address,
    phone: auth?.user?.phone_number,
    companyName: auth?.user?.company?.name,
    companyId: auth?.user?.company?.id,
    role: auth?.user?.role?.title,
    userId: auth?.user?.id,
    isCompanyApproved: auth?.user?.company?.approval_status === "approved",
    companyType: auth?.user?.user_company_type,
    emailVerified: auth?.user?.email_verified,
    phoneVerified: auth?.user?.phone_verified,
    companyApprovalStatus: auth?.user?.company?.approval_status,
    companyApprovalRemark: auth?.user?.company?.remark,
    apiKey: auth?.user?.company?.api_key,
    pcnRegistrationStatus: auth?.user?.company?.pcn_registration_status,
    goodsReceivedNotificationUrl:
      auth?.user?.company?.settings?.goods_received_notification_url,
  };
}

import * as React from "react";
import { Modal, ModalLevel2 } from ".";
import { useStore } from "src/hooks/useContexts";

export interface IModalCoverProps {
  modalName: string;
  canClose?: boolean;
  children?: JSX.Element;
  title?: string;
  subTitle?: string;
  onClose?: () => void;
}

export function ModalCover({
  modalName,
  canClose,
  children,
  title,
  subTitle,
  onClose,
}: IModalCoverProps) {
  const { currentModal, setCurrentModal } = useStore();

  const closeModal = () => setCurrentModal("");

  return (
    <Modal
      isOpen={currentModal === modalName}
      close={canClose ? (onClose ? onClose : closeModal) : undefined}
      title={title}
      subTitle={subTitle}
    >
      {children}
    </Modal>
  );
}

export function ModalCover2({
  modalName,
  canClose,
  children,
  title,
  subTitle,
  onClose,
}: IModalCoverProps) {
  const { currentLevel2Modal, setCurrentLevel2Modal } = useStore();

  const closeModal = () => setCurrentLevel2Modal("");

  return (
    <ModalLevel2
      isOpen={currentLevel2Modal === modalName}
      close={canClose ? (onClose ? onClose : closeModal) : undefined}
      title={title}
      subTitle={subTitle}
    >
      {children}
    </ModalLevel2>
  );
}

//@ts-ignore

import { FormEvent, useEffect, useRef, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { DataWrapper } from "../../../common/components/dataWrapper";

import { PRODUCT_URL } from "../../../common/constants";
import { Button, MessageText } from "../../../common/elements";
import { KeyValueObj } from "../../../common/types";
import { useApiCallHandling } from "../../../hooks/useApiCallHandling";
import { useAuthDetails } from "../../../hooks/useAuthDetails";
//import { useAxiosAuth } from "../../../hooks/useAxiosAuth";
import useAxiosCall from "../../../hooks/useAxiosCall";
import { useDashboardContext, useStore } from "../../../hooks/useContexts";

import { Product } from "../../../common/components/product-card/Product";
import { ProductsWrapper } from "./subs/styles";

import { Pagination } from "../../../common/components/pagination";
import Tab from "../../../common/components/Tab/Tab";
import { ProductFactory } from "../common/product-factory";
import { ProductModals } from "./subs/modals";
import { ActionPopup } from "src/common/components/action-popup";
import { usePositioning } from "src/hooks/usePositioning";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { ProductLoadingSkeleton } from "./subs/product-loading-skeleton";
import excelIcon from "src/assets/images/excel_icon.svg";
import { UploadProduct } from "./subs/product-upload";
import { Modal } from "src/common/components/modal";

export interface IProductsProps {}

export function Products(props: IProductsProps) {
  const [addMore, setAddMore] = useState(false);

  const [retry, setRetry] = useState(0);

  const [products, setProducts] = useState<any[]>([]);
  const [noProductMessage, setNoProductMessage] = useState(
    "No product added yet, Products added will show here."
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [pageCount, setPagCount] = useState(1);
  const [productStatus, setProductStatus] = useState("approved");
  const [editProduct, setEditProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<KeyValueObj>({});
  const [currentTab, setCurrentTab] = useState("Approved");
  const [showProductOptions, setShowProductOptions] = useState(false);
  const [productType, setProductType] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [scrapDownloadLink, setScrapDowloadLink] = useState("");
  const [currentStage, setCurrentStage] = useState("uploading");
  const [successCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const [uploadTitle, setUploadTitle] = useState("Upload Products");
  //HOOKES
  const [getProducts, productFetched, productError, productLoading, meta] =
    useAxiosCall();
  const { setOnGlobalSearch, setSearching } = useDashboardContext();
  const apiCall = useApiCallHandling();
  const { companyType, isCompanyApproved, companyId } = useAuthDetails();
  const {
    setModalOptions,
    setCurrentModal,
    setCurrentLevel2Modal,
    setLevel2ModalOptions,
  } = useStore();
  const tabRef = useRef<any>(null);
  const [setPosition, position] = usePositioning();

  const onProductSearch = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearching(true);
    apiCall(
      PRODUCT_URL + `?search_term=${value}&page=${page}&per_page=${perPage}`,
      "GET",
      {},
      (data, metaData) => {
        setProducts(data);
        typeof metaData.total_pages === "number" &&
          setPagCount(metaData.total_pages);
        if (data.length === 0)
          setNoProductMessage("The search term you entered returned no data!");
      },
      (err) => {
        console.log(err);
      },
      () => {
        setSearching(false);
      },
      { dontNotifyOnSucess: true, dontNotifyonFail: true }
    );
  };

  //USEEFFECTS
  useEffect(() => {
    //this is where the function that runs on search is asigned
    setOnGlobalSearch(() => onProductSearch);

    return () => {
      setOnGlobalSearch(() => () => {});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOnGlobalSearch]);

  // initial set ups
  useEffect(() => {
    const productsUrl =
      companyType?.toLowerCase() === "importer" ||
      companyType?.toLowerCase() === "manufacturer"
        ? PRODUCT_URL +
          `?page=${page}&per_page=${perPage}&status=${productStatus}`
        : PRODUCT_URL + `?page=${page}&per_page=${perPage}`;

    getProducts({
      method: "GET",
      url: productsUrl,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, page, perPage, productStatus]);

  //handling products changes
  useEffect(() => {
    productFetched && setProducts(productFetched.reverse());
    typeof meta.total_pages === "number" && setPagCount(meta.total_pages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productLoading, productFetched, productError, meta]);

  function handleNextPage(event: { selected: number }) {
    setPage(event.selected + 1);
  }

  function handlePerPageChange(event: FormEvent<HTMLInputElement>) {
    setPerPage(+event.currentTarget.value);
  }

  function onTabClick(title: string) {
    setCurrentTab(title);
    switch (title) {
      case "Pending Approval":
        setProductStatus("pending");
        break;
      case "Approved":
        setProductStatus("approved");
        break;

      case "Queried":
        setProductStatus("queried");
        break;
      case "Declined":
        setProductStatus("declined");
        break;

      default:
        break;
    }
  }

  function returnToPendingTab(data: KeyValueObj, done: boolean) {
    setCurrentTab("Pending Approval");
    setProducts((prev: KeyValueObj[]) => {
      const copy = [...prev];
      copy.unshift(data);
      return copy;
    });
    setAddMore(false);
  }

  function confirmedDeleteProduct(id: string) {

    setLevel2ModalOptions((prev: any) => ({
      ...prev,
      loading: true,
    }));
    apiCall(
      PRODUCT_URL + "/" + id,
      "DELETE",
      {},
      (resp: any) => {
     
        setCurrentLevel2Modal("");
        setCurrentModal("");
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        setLevel2ModalOptions({
          loading: false,
        });
      }
    );
  }

  const cancelDelete = () => setCurrentLevel2Modal("");

  function deleteProduct(id: string) {
    setLevel2ModalOptions({
      id,
      loading: false,
      confirmedDeleteProduct,
      cancelDelete,
    });
    setCurrentLevel2Modal("confirm-delete");
  }

  function handleEditProduct(product: any) {
    setSelectedProduct(product);
    setEditProduct(true);
  }

  function handleView(id: string) {
    const product = products.find((product: any) => product?.id === id);
    setModalOptions({
      deleteLoading: false,
      companyId,
      product,
      deleteProduct,
      handleEditProduct,
    });
    setCurrentModal("view-product-modal");
  }

  function updateProductList(data: KeyValueObj) {
    setProducts((prev: KeyValueObj[]) => {
      const copy = [...prev];
      const index = copy.findIndex(
        (product: KeyValueObj) => product.id === data.id
      );
      copy[index] = data;
      return copy;
    });
  }

  function updateProductInfo(data: KeyValueObj, done: boolean) {
    setSelectedProduct(data);
    updateProductList(data);
    done && setEditProduct(false);
    setCurrentModal("");
  }

  function handleAddProduct(e: any) {
    setPosition(e);
    setShowProductOptions(true);
  }

  function handleProductTypeSelected(type: string) {
    setProductType(type);
    if (type === "drug") {
      setAddMore(true);
    }
    setShowProductOptions(false);
  }

  function handleUploadProduct() {
    setShowUploadModal(true);
  }

  function handleFileUploadSuccess(data: any) {
    setUploadTitle("Uploaded Products");
    setCurrentStage("uploaded");
    setScrapDowloadLink(data?.csv);
    setSuccessCount(data?.stats?.total_ok);
    setFailureCount(data?.stats?.total_faulty);

  }

  function closeUploadModal() {
    setUploadTitle("Upload Products");
    setCurrentStage("uploading");
    setShowUploadModal(false);
  }

  const productTypeActions = [
    {
      label: "Drug",
      action: () => handleProductTypeSelected("drug"),
    },
    {
      label: "Medical Item",
      action: () => handleProductTypeSelected("medical_item"),
    },
  ];

  return (
    <ProductsWrapper>
      <PageTitleNavs
        title="Added Products"
        backLink="#"
        bcrumbs={[{ title: "Products", link: "#" }]}
      />
      <ProductFactory
        onSuccessfullMutation={returnToPendingTab}
        open={addMore}
        close={() => setAddMore(false)}
        productType={productType}
      />
      <ProductFactory
        open={editProduct}
        close={() => setEditProduct(false)}
        editMode
        product={selectedProduct}
        onSuccessfullMutation={updateProductInfo}
        productType={productType}
      />
      <ActionPopup
        position={position}
        open={showProductOptions}
        actions={productTypeActions}
        onClose={() => setShowProductOptions(false)}
      />
      <Modal
        title={uploadTitle}
        isOpen={showUploadModal}
        close={closeUploadModal}
      >
        <UploadProduct
          handleUploadSuccess={handleFileUploadSuccess}
          scrapDownloadLink={scrapDownloadLink}
          currentStage={currentStage}
          successCount={successCount}
          failureCount={failureCount}
        />
      </Modal>
      {isCompanyApproved && (
        <>
          {companyType?.toLowerCase() === "importer" ||
          companyType?.toLowerCase() === "manufacturer" ? (
            <>
              <div className="top">
                <Button secondary onClick={handleUploadProduct}>
                  <img className="excel_icon" alt="csv" src={excelIcon} />
                  Upload Product
                </Button>
                <Button
                  disabled={!isCompanyApproved}
                  onClick={handleAddProduct}
                >
                  <BsPlusLg /> Add Product
                </Button>
              </div>
              <div className="tabs-wrapper">
                <Tab
                  tabsTitle={[
                    "Approved",
                    "Pending Approval",
                    "Queried",
                    "Declined",
                  ]}
                  onTabClick={onTabClick}
                  ref={tabRef}
                  currentTab={currentTab}
                />
              </div>
            </>
          ) : null}
          <DataWrapper
            data={products}
            noDataMessage={noProductMessage}
            loading={productLoading}
            error={productError}
            customLoader={<ProductLoadingSkeleton />}
            loadingMessage="Loading products data..."
            failedMessage="Could not fetch products data. Check your network and click the button below to retry"
            onFailAction={() => {
              setRetry((prev) => prev + 1);
            }}
          >
            <div className=" products">
              <>
                {products?.map((product: KeyValueObj) => (
                  <Product
                    key={product?.id}
                    imageUrl={product?.images?.[0]?.url}
                    brand={product?.brand_name}
                    name={product?.atc_code?.code_title}
                    id={product?.id}
                    strengthUnit={product?.strength_unit}
                    strength={product?.strength}
                    size={""}
                    form={product?.form}
                    baseUrl="/dashboard/products"
                    onView={handleView}
                  />
                ))}
              </>
            </div>
          </DataWrapper>
          <Pagination
            pageCount={pageCount}
            onNextPage={handleNextPage}
            onPerPageChange={handlePerPageChange}
          />
        </>
      )}
      {!isCompanyApproved && (
        <MessageText>
          You cannot view or add products as your company is yet to be approved.
        </MessageText>
      )}
      <ProductModals />
    </ProductsWrapper>
  );
}

import styled from "styled-components";

interface SelectProps {
  fullWidth: boolean;
  shortWidth?: boolean;
  showOptions: boolean;
  indexLevel: number;
  fadeOut?: boolean;
}

export const SelectWrapper = styled.div<SelectProps>`
  text-align: left;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "47%")};
  opacity: 0;
  animation: inputFadeIn 0.5s ease-in forwards;

  /* neccessary to solve z index issue due to stacking context */
  z-index: ${({ indexLevel }) => 1000 - indexLevel};

  @media (max-width: 831px) {
    width: 100%;
  }

  .label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: .5rem;

    .tooltip-icon {
      font-size: 1.1rem;
      color: ${({theme}) => theme.text.prim_100};
      cursor: pointer;
    }

  }

  label {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.text.primary};
    display: inline-block;
  }

  .select-wrapper {
    width: 100%;

    cursor: pointer;
    position: relative;

    .select-input {
      width: 100%;
      height:3rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      border: 0.0313rem solid ${({ theme }) => theme.border.input};
      border-radius: 0.2rem;

      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding: 1rem;
        font-size: .875rem;
        border-radius: 0.2rem;

        :focus {
          outline: none;
          border: 1px solid ${({ theme }) => theme.bg.secondary};
          box-shadow: ${({ theme }) => theme.shadow.drag_over};
        }
      }

      h4 {
        color: #c4c4c4;
        font-size: .875rem;
        font-weight: 400;
        text-transform: capitalize;
        width: 100%;
        height: 100%;
        padding: 1rem;
      }

      h3 {
        font-size: .875rem;;
        line-height: 1.375rem;
        font-weight: 400;
        letter-spacing: 0.0313rem;
        text-transform: capitalize;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 1rem
        
      }

      .icon {
        position: absolute;
        right: 16px;
        top: 40%;
        transition: all ease-in-out 0.3s;
        transform: ${({ showOptions }) =>
          showOptions ? "rotate(90deg)" : "rotate(0)"};

        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          top: 35%;
        }
      }
    }

    .select-input:has(> input:focus) {
      border: transparent;
    }

    .error-message {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }) => theme.text.error};
      margin-top: 0.4rem;
      text-align: left;
      position: absolute;
      top: 95%;
    }

    .select-options {
      width: 100%;
      max-height: 15rem;
      overflow-y: auto;
      list-style: none;
      position: absolute;
      top: 100%;
      margin-top: 0.375rem;
      z-index: 1;
      background: #ffffff;
      border: 0.0313rem solid ${({theme}) => theme.border.light};
      box-shadow: ${({theme}) => theme.shadow.shad_100};
      border-radius: 0.25rem;
 
      /* opacity: 0;
      transform: translateY(16px);
      transition: all 0.35s ease-in-out; */
      animation: ${({ fadeOut }) =>
          fadeOut ? "optionsFadeOut" : "optionsFadeIn"}
        forwards 0.35s ease-in-out;

      @keyframes optionsFadeIn {
        from {
          margin-top: 1.375rem;
          opacity: 0;
        }
        to {
          opacity: 1;
          margin-top: 0.375rem;
        }
      }

      @keyframes optionsFadeOut {
        from {
          opacity: 1;
          transform: translateY(0);
        }
        to {
          transform: translateY(16px);
          opacity: 0;
        }
      }

      li {
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.0625rem;
        padding:1rem 1.5rem;
        cursor: pointer;
        text-transform: capitalize;
      }

      li:hover {
        background-color: ${({theme}) => theme.bg.prim_400};
      }
    }
  }


  @keyframes inputFadeIn {
    to {
      opacity: 1;
    }
  }
`;

import { KeyValueObj } from "src/common/types";

export const tableHeaders = [
  "Medex Code",
  "Brand Name",
  "ATC code - Name",
  "Strength",
  "Form",
  "Classification",
];
export const tableKeys = [
  "code",
  "brand",
  "atc_name",
  "strength",
  "form",
  "classification",
];

export const manuImporterTableHeaders = [
  "Medex Code",
  "Name",
  "Type",
  "RC Number",
  "Phone",
  "Email",
];

export const manuImporterTableKeys = [
  "medexCode",
  "name",
  "type",
  "rcNumber",
  "phone",
  "email",
];

export const getManuImporterTableData = (
  importers: KeyValueObj[],
  manus: KeyValueObj[]
) => {
  const data = [...importers, ...manus];

  return data?.map((datum: KeyValueObj) => ({
    id: datum?.id,
    medexCode: {
      type: "string",
      value: datum?.medex_code,
    },
    name: {
      type: "string",
      value: datum?.name,
    },
    rcNumber: {
      type: "string",
      value: datum?.rc_number,
    },
    type: {
      type: "string",
      value: datum?.type,
    },
    phone: {
      type: "string",
      value: datum?.phone_number,
    },
    email: {
      type: "string",
      value: datum?.email,
    },
  }));
};

export const registerRefferedInput = [
  {
    inputType: "text",
    prompt: "Company Phone number",
    name: "phone",
    placeholder: "Enter the company's phone number",

    fullWidth: true,
    rules: {
      validNigerianPhoneNumber: {
        expectedValue: true,
        errorMessage: "Invalid Nigerian phone number",
      },
    },
  },

  {
    inputType: "password",
    prompt: "Password",
    name: "password",
    placeholder: "Enter password",
    fullWidth: false,
    rules: {
      containSpecialChar: {
        expectedValue: true,
        errorMessage: "Include a special character",
      },
      minLength: {
        expectedValue: 6,
        errorMessage: "Should be atleast 6 characters",
      },
      containUppercase: {
        expectedValue: true,
        errorMessage: "Include uppercase letter",
      },
      containLowercase: {
        expectedValue: true,
        errorMessage: "Include lowercase letter",
      },
    },
  },
  {
    inputType: "password",
    prompt: "Confirm",
    name: "repeatPassword",
    placeholder: "Confirm password",
    fullWidth: false,
    rules: {
      compareWithExisting: {
        expectedValue: "password",
        errorMessage: "Does not match the password",
      },
    },
  },
];

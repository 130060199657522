/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { BsChevronDown, BsQuestionSquare } from "react-icons/bs";
import { KeyValueObj } from "../../types";

import { SelectWrapper } from "./style";
import CustomTooltip from "../Tooltip";

export interface ISelectProps {
  options: any[];
  placeholder: string;
  prompt: string;
  fullWidth: boolean;
  shortWidth: boolean;
  name: string;
  indexLevel: number;
  returnId?: boolean;
  valueType?: string;
  initialValue?: string;
  tooltip?: string;
  updateValidity?: (name: string, validity: boolean) => void;
  updateData?: (
    name: string,
    value: { id: string | number; title: string } | string
  ) => void;
  onEveryChange?: (
    latestInput: string,
    name: string,
    updateValidity?: (name: string, validity: boolean) => void,
    setFormValues?: Dispatch<SetStateAction<KeyValueObj>>,
    fomrValues?: KeyValueObj
  ) => void;
  formValues: KeyValueObj;
  setFormValues: Dispatch<SetStateAction<KeyValueObj>>;
  handleSearch?: (value: string) => void;
}

export function Select({
  options,
  placeholder,
  prompt,
  fullWidth,
  updateValidity,
  updateData,
  name,
  indexLevel,
  returnId,
  shortWidth,
  valueType,
  onEveryChange,
  formValues,
  setFormValues,
  initialValue,
  tooltip,
  handleSearch,
}: ISelectProps) {
  const [selectedValue, setSelectedValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [selectOptions, setSelectOptions] = useState<KeyValueObj[]>([]);
  const [initialFiltered, setinitialFiltered] = useState<KeyValueObj[]>([]);
  const [fadeOut, setFadeOut] = useState(false);

  let timeout: number;
  useEffect(() => {
    if (valueType === "ATC") {
      const filtered = options.slice(0, 200);
      setSelectOptions(filtered);
      setinitialFiltered(filtered);
    } else {
      setSelectOptions(options);
    }

    //Check if there is initial value and update the selection
    if (initialValue) {
      updateValidity?.(name, true);
      setSelectedValue(initialValue);
      updateData?.(name, initialValue);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [initialValue, options]);

  function onSelectClicked() {
    setSelectOptions(options);
    setFadeOut(false);
    setShowOptions(!showOptions);
    setClicked(true);
  }

  const onSelectOption = (item: any) => {
    updateValidity?.(name, true);

    if (valueType === "ATC") {
      setSelectedValue(`${item.code_identifier} - ${item.code_title}`);
    } else {
      setSelectedValue(item.title);
    }
    removeOptions();

    if (returnId || valueType === "ATC") {
      updateData?.(name, item.id);
      onEveryChange &&
        onEveryChange(item.id, name, updateValidity, setFormValues, formValues);
    } else {
      updateData?.(name, item.title);
      onEveryChange &&
        onEveryChange(
          item.title,
          name,
          updateValidity,
          setFormValues,
          formValues
        );
    }

    updateValidity?.(name, true);

    //call the function for controlling from outside.
  };

  function onInputBlur() {
    removeOptions();
  }

  function removeOptions() {
    setFadeOut(true);
    timeout = window.setTimeout(() => {
      setShowOptions(false);
    }, 350);
  }

  function onInputChange(e: FormEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    if (handleSearch) {
      return handleSearch(value);
    }
    if (valueType === "ATC") {
      if (value.length > 2) {
        const filtered = options.filter(
          (option: KeyValueObj) =>
            option.code_title?.toLowerCase()?.includes(value.toLowerCase()) ||
            option.code_identifier?.toLowerCase()?.includes(value.toLowerCase())
        );
        setSelectOptions(filtered);
      } else {
        setSelectOptions(initialFiltered);
      }
    } else {
      const filtered = options.filter(
        (option: KeyValueObj) =>
          option?.title?.toLowerCase().includes(value.toLowerCase()) ||
          option?.id?.toLowerCase().includes(value.toLowerCase())
      );
      setSelectOptions(filtered);
    }
  }

  return (
    <SelectWrapper
      shortWidth={shortWidth}
      indexLevel={indexLevel}
      showOptions={showOptions}
      fullWidth={fullWidth}
      fadeOut={fadeOut}
    >
      <div className="label-wrapper">
        <label>{prompt}</label>
        {tooltip ? (
          <CustomTooltip title={tooltip}>
            <div>
              <BsQuestionSquare className="tooltip-icon" />
            </div>
          </CustomTooltip>
        ) : null}
      </div>
      <div className="select-wrapper">
        <div className="select-input">
          {showOptions ? (
            <input
              onChange={onInputChange}
              onBlur={onInputBlur}
              autoFocus
              type="text"
            />
          ) : selectedValue ? (
            <h3 onClick={onSelectClicked}>{selectedValue}</h3>
          ) : (
            <h4 id={name} onClick={onSelectClicked}>
              {placeholder}
            </h4>
          )}
          <BsChevronDown className="icon" />
        </div>
        {clicked && !showOptions && !selectedValue && (
          <h3 className="error-message">Please select from the options</h3>
        )}
        {showOptions && (
          <ul className={`select-options`}>
            {valueType === "ATC"
              ? selectOptions &&
                selectOptions.map((item, i) => {
                  return (
                    <li
                      onClick={() => onSelectOption(item)}
                      key={i}
                      value={item.title}
                      id={`${name}-${i}`}
                    >
                      {item.code_identifier} - {item.code_title}
                    </li>
                  );
                })
              : selectOptions &&
                selectOptions.map((item, i) => {
                  return (
                    <li
                      onClick={() => onSelectOption(item)}
                      key={i}
                      value={item.title}
                      id={`${name}-${i}`}
                    >
                      {item.title}
                    </li>
                  );
                })}
          </ul>
        )}
      </div>
    </SelectWrapper>
  );
}

import styled from "styled-components";

export const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3.3rem 2rem;
  gap: 2.5rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 1rem;
  }

  .product-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
    }

    @media (max-width: 768px) and (min-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  .top-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: ${({ theme }) => theme.bg.prim_500};
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    border-radius: 0.625rem;
  }
  .dash-title {
    font-size: 1.1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.text.prim_100};
  }

  .underline {
    border-bottom: 1px solid ${({ theme }) => theme.border.input};
    padding-bottom: 0.3rem;
    width: 12rem;
  }

  .dash-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${({ theme }) => theme.text.prim_100};
  }

  .title {
    width: 100%;
    justify-content: space-between;
  }

  .top-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    gap: 1rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.tablet}) {
      grid-template-columns: repeat(1, 1fr);
    }

    .client-chart {
      display: flex;
      padding: 1rem;
      width: 100%;
      align-items: flex-start;
      gap: 1rem;

      .client-lower {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .statuses {
          height: 100%;
          justify-content: space-around;
          align-items: flex-start;
          gap: 1.2rem;

          .status {
            gap: 0.35rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .status-inner {
              gap: 0.3125rem;

              .dot {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #6956e5;
              }

              .green-dot {
                background-color: #7ecd8b;
              }
              .orange-dot {
                background-color: #ebb975;
              }
              .red-dot {
                background-color: #f0786f;
              }
              .blue-dot {
                background-color: #85b4e5;
              }
            }

            .value {
              font-weight: 600;
              font-size: 1rem;
            }
          }
        }
        .image {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 1rem;

          img {
            width: 85%;
          }
        }
      }
    }

    .distributors {
      width: 100%;
      flex-direction: column;
      display: flex;
      gap: 1rem;
      padding: 1rem;

      .distri-table {
        gap: 1rem;
      }

      .distri-tb-row {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
      }

      .table-header {
        font-size: 1rem;
        color: ${({ theme }) => theme.text.prim_100};
        font-weight: 600;
      }

      .table-text {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.text.prim_100};
        font-weight: 400;
      }

      .left {
        width: 35%;
      }

      .right {
        width: 60%;
      }

      .table-header {
      }
    }
  }

  .lower-row {
    width: 100%;
    align-items: stretch;
    gap: 1rem;

    @media (max-width: ${({ theme }) => theme.screen.tablet}) {
      flex-direction: column;
      gap: 2rem;
    }

    .line-chart {
      flex: 3;
    }

    .notifications {
      flex: 2;
      justify-content: flex-start;
    

      .title {
        width: 100%;
        justify-content: space-between;

        h3 {
          color: #7661e2;
          font-weight: 500;
          font-size: 0.875rem;
        }
      }

      .notifications-wrapper {
        width: 100%;
        padding-top: 1rem;
        height: 100%;
        gap: 0.5rem;
      }
    }
  }
`;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DataWrapper } from "../../../../common/components/dataWrapper";
import { ProductDetails } from "../../../../common/components/product-details";
import { ADMIN_PRODUUCTS_URL, PRODUCT_URL } from "../../../../common/constants";
import { KeyValueObj } from "../../../../common/types";
import useAxiosCall from "../../../../hooks/useAxiosCall";

import { AdminActions } from "../components/admin-actions";
import { PageTitleNavs } from "src/common/components/page-title-navs";

const AdminProductDetailsWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media screen and (max-width: ${({theme}) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }
`;

export interface IAdminProductDetailsProps {}

export function AdminProductDetails(props: IAdminProductDetailsProps) {
  const [status, setStatus] = useState("");
  const [product, setProduct] = useState<KeyValueObj>({});
  const [retry, setRetry] = useState(0);

  const [fetchProduct, fetchedProduct, productError, productLoading] =
    useAxiosCall();
  const { id, type } = useParams();

  useEffect(() => {
    fetchProduct({ url: PRODUCT_URL + "/" + id, method: "GET" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry]);

  useEffect(() => {
    fetchedProduct && setProduct(fetchedProduct);
    setStatus(fetchedProduct.approval_status);
  }, [fetchedProduct]);

  return (
    <AdminProductDetailsWrapper>
      <PageTitleNavs
        title="View Product"
        backLink={"/dashboard/admin/products/" + type}
        bcrumbs={[
          { title: "Products", link: "/dashboard/admin/products/" + type },
          { title: "View", link: "#" },
        ]}
      />
      <DataWrapper
        data={fetchedProduct}
        noDataMessage={"No product to show"}
        loading={productLoading}
        error={productError}
        loadingMessage="Loading products data..."
        failedMessage="Could not fetch products data. Check your network and click the button below to retry"
        onFailAction={() => {
          setRetry((prev) => prev + 1);
        }}
      >
        <>
          <ProductDetails isAdmin product={product} />
          <AdminActions
            initialStatus={status}
            postUrl={ADMIN_PRODUUCTS_URL + "/approval/" + id}
          />
        </>
      </DataWrapper>
    </AdminProductDetailsWrapper>
  );
}

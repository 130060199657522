import { useState, useEffect } from "react";
import styled from "styled-components";
import { KeyValueObj } from "../../../../common/types";
import Table from "src/common/components/Table";
import {
  declineTableHeaders,
  declineTableKeys,
  getDeclineTableData,
  getPendingTableData,
  getQuotationTableData,
  pendingTableHeaders,
  pendingTableKeys,
  quotesTableHeaders,
  quotesTableKeys,
} from "./data";
import Tab from "src/common/components/Tab/Tab";
import { usePositioning } from "src/hooks/usePositioning";
import { ActionPopup } from "src/common/components/action-popup";
import { Prompt } from "src/common/components/prompt";
import { Modal } from "src/common/components/modal";
import { KeyValueContainer } from "src/common/components/key-value-wrapper";
import {
  getProductTableData,
  productTableHeaders,
  productTableKeys,
} from "../new-rfq/data";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { QUOTATION_URL } from "src/common/constants";
import { RespondViewRfq } from "../respond-rfq/respond-view-rfq";
import { CompareTable } from "./compare-table";

const RFQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 60rem;
  overflow: hidden;
  max-width: 79vw;
  padding: 2rem;
  background-color: ${({ theme }) => theme.bg.prim_400};

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0rem;
    max-width: 100%;
  }

  .rfq-modal-wrapper {
    min-width: 50rem;
    max-width: 95%;
  }

  .table-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
  }

  .title,
  .value {
    font-weight: 400;
    font-size: 0.85rem;
    color: ${({ theme }) => theme.text.prim_100};
    word-wrap: break-word;
  }

  .value {
    font-weight: 500;
    color: ${({ theme }) => theme.text.primary};
  }

  .company-info {
    width: 100%;
    padding: 2rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
  }

  .company-info,
  .quotations {
    justify-content: space-between;

    .info-wrapper {
      align-items: flex-start;
      width: 40%;
      gap: 0.5rem;

      .c-title {
        color: ${({ theme }) => theme.bg.secondary};
        font-size: 0.85rem;
        font-weight: 400;
        word-wrap: break-word;
      }

      .c-value {
        color: ${({ theme }) => theme.text.input};
        font-size: 0.85rem;
        font-weight: 500;
        width: 100%;
        word-wrap: break-word;
      }
    }
  }

  .text {
    width: 100%;
    text-align: left;
  }

  .quote-title {
    color: ${({ theme }) => theme.text.primary};
    font-weight: 700;
    font-size: 1.2rem;
  }

  .request-info {
    background-color: ${({ theme }) => theme.bg.prim_500};
    width: 100%;
    padding: 2rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 2rem .2rem;
  }

    .quote-details {
      display: grid; /* 1 */
      grid-template-columns: repeat(auto-fill, 100px); /* 2 */
      grid-gap: 1rem 4rem; /* 3 */
      justify-content: space-between; /* 4 */

      .quote-info-wrapper {
        width: 10rem;
      }

      label {
        font-size: 0.875rem;
      }
    }

    .quote-input {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      margin: 1rem 0;

      .quote-input-wrapper {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        select,
        input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid ${({ theme }) => theme.border.input};
          outline: none;
          font-size: 0.85rem;
          border-radius: 0.2rem;
        }

        option {
          padding: 0.7rem;
        }
      }
    }
  }

  .controls {
    width: 100%;
    justify-content: space-around;
  }

  .quotations {
    margin-top: 2rem;
    padding: 2rem;
    background-color: #f6fbf8;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    .quotation-outer {
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.text.muted_100};
      padding-bottom: 2rem;
    }

    .quotations-info-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 2rem 0;
    }

    .quotations-controls {
      justify-content: space-around;
    }
    .quotation-status-wrapper {
      width: 100%;
      justify-content: space-between;
    }
    .quotation-status {
      gap: 1rem;
      align-items: center;
      justify-content: center;

      h3,
      h4 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme.text.input};
      }
      h4 {
        text-transform: uppercase;
      }

      .rejected {
        color: red;
      }

      .approved {
        color: #04d204;
      }
    }
    .m-1 {
      margin-top: 1rem;
    }
  }
`;

export interface IRFQDetailsFormProps {
  rfq: any;
  rfqLoading: boolean;
  rfqQuotations: KeyValueObj[];
}

export function RFQDetailsForm({
  rfq,
  rfqLoading,
  rfqQuotations,
}: IRFQDetailsFormProps) {
  const [currentTab, setCurrentTab] = useState("Quotations");
  const [showAction, setShowAction] = useState(false);
  const [actions] = useState<any>([]);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [rfqData, setRfqData] = useState<KeyValueObj[]>([]);
  const [quotationLoading, setQuotationLoading] = useState(false);
  const [showQuotation, setShowQuotation] = useState(false);
  const [quotation, setQuotation] = useState<KeyValueObj>({});
  const [subtotal, setSubtotal] = useState(0);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showRfqCompare, setShowRfqCompare] = useState(false);

  const [, position] = usePositioning();
  const makeRequest = useApiCallHandling();

  useEffect(() => {
    const data: KeyValueObj[] = [];
    Object.keys(rfq)?.forEach((objKey: string) => {
      switch (objKey) {
        case "expected_delivery_date":
        case "expiration_date":
        case "payment_term":
        case "shipping_address":
        case "shipping_city":
          return data.push({
            title: objKey?.split("_").join(" "),
            value: rfq[objKey],
          });

        default:
          return null;
      }
    });
    setRfqData(data as KeyValueObj[]);
  }, [rfq]);

  function handleViewClicked(row: KeyValueObj) {
    setQuotationLoading(true);
    setShowQuotation(true);
    makeRequest(
      QUOTATION_URL + "/" + row?.id,
      "GET",
      {},
      (data) => {
        setQuotation(data);
        let subtotal = 0;
        data?.quotation_products?.forEach((product: KeyValueObj) => {
          subtotal += product?.price_per_unit * product?.quantity;
        });
        setSubtotal(subtotal);
      },
      console.log,
      () => setQuotationLoading(false)
    );
    console.log(row);
  }

  const declines = rfq?.quotation_request_responses?.filter(
    (resp: KeyValueObj) => resp?.status === "rejected"
  );

  const pendings = rfq?.quotation_request_responses?.filter(
    (resp: KeyValueObj) => resp?.status === "pending"
  );

  function acceptQuotation(options: any) {
    setSubmitLoading(true);

    makeRequest(
      QUOTATION_URL + "/accept",
      "POST",
      {
        quotation_request_id: options?.quotation_request_id,
        quotation_id: options?.quotation_id,
      },
      (data) => {
        // updateTable(rfqId, quotationId, data);

        setShowQuotation(false);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setSubmitLoading(false);
      }
    );
  }

  function rejectQuotation(options?: any) {
    setRejectLoading(true);
    makeRequest(
      QUOTATION_URL + "/reject",
      "POST",
      {
        quotation_request_id: options?.quotation_request_id,
        quotation_id: options?.quotation_id,
      },
      (data) => {
        setShowQuotation(false);
        // updateTable(rfqId, quotationId, data);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setRejectLoading(false);
      }
    );
  }

  function compareQuotations() {
    setShowRfqCompare(true);
  }

  // function onRejectRequest(reason: string) {
  //   setRejectLoading(true);
  //   makeRequest(
  //     DISTRIBUTORS_URL + "/rfq/reject",
  //     "POST",
  //     {
  //       rfq_id: currentRfq.id,
  //       reason_for_rejection: reason,
  //     },
  //     (data) => {
  //       setShowModal(false);
  //     },
  //     (err) => {
  //       console.log(err);
  //     },
  //     () => {
  //       setRejectLoading(false);
  //     }
  //   );
  // }

  return (
    <RFQWrapper>
      <ActionPopup
        position={position}
        open={showAction}
        actions={actions}
        onClose={() => setShowAction(false)}
      />

      <Modal isOpen={showQuotation} close={() => setShowQuotation(false)}>
        <div style={{ minWidth: "60rem", maxWidth: "95%" }}>
          <RespondViewRfq
            company={quotation?.distributor_info}
            rfq={{
              ...quotation?.quotation_request,
              quotation_request_products: quotation?.quotation_products,
              quotation_request_id: quotation?.quotation_request?.id,
              quotation_id: quotation?.id,
              payment_term: quotation?.payment_term,
            }}
            logisticsCost={quotation?.delivery_charges}
            tax={quotation?.tax}
            discount={quotation?.discount}
            total={quotation?.total_amount}
            rowTotals={{}}
            subTotal={subtotal}
            terms={quotation?.remark}
            handleSecondaryButton={rejectQuotation}
            handleMainButton={acceptQuotation}
            secondaryButtonLoading={rejectLoading}
            mainButtonLoading={submitLoading}
            isViewing
            viewer="retailer"
            loadingData={quotationLoading}
            quotation={quotation?.quotation_products}
            quotationExpiry={quotation?.expiration_date}
            title="Quotation"
            quotationStatus={quotation?.status}
          />
        </div>
      </Modal>

      <Modal
        isOpen={showRejectConfirmation}
        close={() => setShowRejectConfirmation(false)}
      >
        <Prompt
          prompt={
            "Are you sure you want to reject this RFQ? if Yes, kindly state the reason below"
          }
          onMainAction={() => {}}
          onSecondaryAction={() => setShowRejectConfirmation(false)}
          withInput
          mainLoading={rejectLoading}
        />
      </Modal>
      <Modal
        title="Quotation Comparison"
        close={() => setShowRfqCompare(false)}
        isOpen={showRfqCompare}
      >
        <CompareTable
          handleViewClicked={handleViewClicked}
          rfqQuotations={rfqQuotations}
        />
      </Modal>

      <div className="request-info">
        <div className="text">
          <h2 className="quote-title">RFQ Details</h2>
        </div>
        <KeyValueContainer loading={rfqLoading} data={rfqData || []} />
      </div>
      <div className="request-info">
        <div className="text">
          <h2 className="quote-title">Product Information</h2>
        </div>
        <Table
          tableHeaders={productTableHeaders}
          tableKeys={productTableKeys}
          tableData={getProductTableData(rfq?.quotation_request_products) || []}
          loading={rfqLoading}
          rowLineColor="light"
        />
      </div>
      <div className="table-tabs">
        <Tab
          tabsTitle={["Quotations", "Declines", "Pendings"]}
          onTabClick={(title: string) => setCurrentTab(title)}
          currentTab={currentTab}
        />
        <div className="request-info">
          {currentTab === "Quotations" ? (
            <Table
              tableTitle="Quotes"
              tableSubtitle="Quotes from distributors"
              tableHeaders={quotesTableHeaders}
              tableKeys={quotesTableKeys}
              tableData={getQuotationTableData(rfq?.quotations, () => {})}
              dropDownOptions={[{ action: handleViewClicked, label: "View" }]}
              showDropDown
              loading={rfqLoading}
              rowLineColor="light"
              headerAction={{
                label: "Compare Quotes",
                action: compareQuotations,
              }}
            />
          ) : currentTab === "Declines" ? (
            <Table
              tableTitle="Declines"
              tableSubtitle="Companies that decline to send quotation"
              tableHeaders={declineTableHeaders}
              tableKeys={declineTableKeys}
              tableData={getDeclineTableData(declines)}
              loading={rfqLoading}
              rowLineColor="light"
            />
          ) : (
            <Table
              tableTitle="Pending"
              tableSubtitle="Companies that are yet to respond to RFQ"
              tableHeaders={pendingTableHeaders}
              tableKeys={pendingTableKeys}
              tableData={getPendingTableData(pendings)}
              loading={rfqLoading}
              rowLineColor="light"
            />
          )}
        </div>
      </div>
    </RFQWrapper>
  );
}

import { useEffect, useState } from "react";
import { Modal } from "../../../common/components/modal";
import { Button } from "../../../common/components/button";
import Table from "../../../common/components/Table";
import {
  COMPANY_PRODUCTS,
  IMPORTER_POST_URL,
  MANUFACTURER_POST_URL,
  ORDERS_URL,
} from "../../../common/constants";
import { ModalFormWrapper, ModalWrapper } from "../../../common/elements";
import { KeyValueObj } from "../../../common/types";
import { beforeToday } from "../../../common/utils/isContain";
import { useApiCallHandling } from "../../../hooks/useApiCallHandling";
import useAxiosCall from "../../../hooks/useAxiosCall";
import {
  getManuImporterTableData,
  manuImporterTableHeaders,
  manuImporterTableKeys,
  tableHeaders,
  tableKeys,
} from "./subs/data";
import { OrderDeliveryDetails, ReqForm } from "./subs/ReqForm";
import { ReqWrapper } from "./subs/styles";
import { useNavigate } from "react-router-dom";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import useQuery from "src/hooks/useQuery";

export interface IRequisitionProps {}

export function MakeOrder(props: IRequisitionProps) {
  //STATES
  const [selectedList, setSelectedList] = useState<KeyValueObj[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<KeyValueObj[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [stage, setStage] = useState(1);
  const [tableData, setTableData] = useState<KeyValueObj[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<KeyValueObj[]>([]);
  const [orders, setOrders] = useState<KeyValueObj>({});
  const [deliveryDetails, setDeliveryDetails] = useState<KeyValueObj>({});
  const [isOrdersValid, setIsOrdersValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retry, setRetry] = useState(0);
  const [manuImporterTableData, setManuImportersTableData] = useState<
    KeyValueObj[]
  >([]);

  //HOOKES
  const [getProducts, fetchedProducts, productsError, productsLoading] =
    useAxiosCall();
  const {
    data: importers,
    loading: impotersLoading,
    error: importersError,
  } = useQuery(IMPORTER_POST_URL);
  const {
    data: manufacturers,
    loading: manufacturersLoading,
    error: manufacturersError,
  } = useQuery(MANUFACTURER_POST_URL);
  const apiCall = useApiCallHandling();
  const navigate = useNavigate();

  // USEFFECTS

  useEffect(() => {
    getProducts({
      method: "GET",
      url: COMPANY_PRODUCTS + `?company_id=${selectedCompany?.[0]?.id}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, selectedCompany]);

  useEffect(() => {

    const tempTD = fetchedProducts?.map((product: KeyValueObj) => ({
      id: product?.id,
      code: {
        type: "string",
        value: product?.medex_code,
      },
      brand: {
        type: "string",
        value: product?.brand_name,
      },
      atc_name: {
        type: "string",
        value: `${product?.atc_code?.readable_name}`,
      },
      classification: {
        type: "string",
        value: product?.classification,
      },
      form: {
        type: "string",
        value: product?.form,
      },
      strength: {
        type: "string",
        value: product?.strength + product?.strength_unit,
      },
    }));
    setTableData(tempTD);
  }, [fetchedProducts]);

  useEffect(() => {
    setManuImportersTableData(
      getManuImporterTableData(importers, manufacturers)
    );
  }, [importers, manufacturers]);

  //Validation for orders
  useEffect(() => {
    let orderValid = true;
    Object.values(orders).forEach((order: KeyValueObj) => {
      if (order?.quantity < 1) {
        setIsOrdersValid(false);
        return;
      }
    });

    if (
      deliveryDetails?.shipping_address?.length < 2 ||
      beforeToday(deliveryDetails?.expected_delivery_date)
    ) {
      setIsOrdersValid(false);
      return;
    }

    setIsOrdersValid(orderValid);
  }, [orders, deliveryDetails]);

  function closeModal() {
    setShowModal(false);
    setStage(1);
    setOrders({});
  }

  function handleContinue() {
    const selectedInv: KeyValueObj[] = [];

    selectedList.forEach((list: KeyValueObj) => {
      selectedInv.push(
        fetchedProducts.find((product: KeyValueObj) => product.id === list.id)
      );
    });

    setSelectedProducts(selectedInv);
    setModalTitle("New Order");
    setModalSubtitle("Product details");
    setShowModal(true);
  }

  function onOrderContinue() {
    setModalTitle("Purchase order summary");
    setModalSubtitle("Product details");
    setStage(2);
  }

  function orderBack() {
    setModalTitle("New Order");
    setModalSubtitle("Product details");
    setStage(1);
  }

  async function onOrderSubmit() {
    setLoading(true);
    const products = Object.keys(orders)?.map((orderKey: string) => {
      const product = fetchedProducts?.find(
        (selectedProduct: KeyValueObj) => selectedProduct?.id === orderKey
      );
     
      return {
        item_type: "drug",
        atc_code_id: product?.atc_code?.id,
        brand_name: product?.brand_name,
        quantity: orders?.[orderKey]?.quantity,
        package_type: orders?.[orderKey]?.package_type,
        price_per_unit: 0,
        description: "",
        product_strength: product?.strength,
        product_strength_unit: product?.strength_unit,
        product_form: product?.product_form,
      };
    });

    const payload = {
      ...deliveryDetails,
      shipping_address_id: deliveryDetails?.id,
      supplier_id: selectedCompany?.[0]?.id,
      products,
    };

    apiCall(
      ORDERS_URL,
      "POST",
      { ...payload },
      (data: any) => {
        closeModal();
        setSelectedList([]);
        navigate("/dashboard/requisition");
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        setLoading(false);
      }
    );
  }

  return (
    <ReqWrapper>
      <PageTitleNavs
        title="Create Order"
        backLink="/dashboard/requisition"
        bcrumbs={[
          { title: "Order", link: "/dashboard/requisition" },
          { title: "Create", link: "#" },
        ]}
      />
      <Modal
        title={modalTitle}
        subTitle={modalSubtitle}
        isOpen={showModal}
        close={closeModal}
      >
        <ModalWrapper>
          <ModalFormWrapper show={stage === 1}>
            {selectedList &&
              selectedProducts.map((product: any, i: number) => (
                <ReqForm
                  key={i}
                  product={product}
                  orders={orders}
                  setOrders={setOrders}
                />
              ))}
            <OrderDeliveryDetails
              deliveryDetails={deliveryDetails}
              setDeliveryDetails={setDeliveryDetails}
            />
            <Button onClick={onOrderContinue} disabled={!isOrdersValid}>
              Continue
            </Button>
          </ModalFormWrapper>
          <ModalFormWrapper show={stage === 2}>
            {selectedList &&
              selectedProducts.map((product: any, i: number) => (
                <ReqForm
                  key={i}
                  product={product}
                  orders={orders}
                  setOrders={setOrders}
                  type="preview"
                />
              ))}

            <OrderDeliveryDetails
              deliveryDetails={deliveryDetails}
              setDeliveryDetails={setDeliveryDetails}
              isPreview
            />
            <div className="button-wrapper">
              <Button onClick={orderBack} secondary>
                Back
              </Button>
              <Button
                loading={loading}
                onClick={onOrderSubmit}
                disabled={loading}
              >
                Submit
              </Button>
            </div>
          </ModalFormWrapper>
        </ModalWrapper>
      </Modal>

      <div className="table-wrapper">
        <Table
          tableHeaders={manuImporterTableHeaders}
          tableData={manuImporterTableData}
          tableKeys={manuImporterTableKeys}
          actionsData={[]}
          full
          withCheck
          isRadio
          setSelectedList={setSelectedCompany}
          selectedList={selectedCompany}
          loading={manufacturersLoading || impotersLoading}
          rowLineColor="light"
          tableTitle="Manufactuers and Importers"
          tableSubtitle="Select a Manufacturer or a importer to see their products"
          errorLoading={
            manufacturersError?.length > 0 || importersError?.length > 0
          }
          onRetry={() => {
            setRetry((prev) => prev + 1);
          }}
        />
      </div>

      {selectedCompany?.length > 0 ? (
        <div className="table-wrapper">
          <Table
            tableHeaders={tableHeaders}
            tableData={tableData}
            actionsData={[]}
            tableKeys={tableKeys}
            full
            withCheck
            rowLineColor="light"
            setSelectedList={setSelectedList}
            selectedList={selectedList}
            tableTitle="Manufacturer/Importer's Products"
            tableSubtitle="Select a Products and click continue to start the order process"
            loading={productsLoading}
            errorLoading={productsError?.message?.length > 0}
            onRetry={() => {
              setRetry((prev) => prev + 1);
            }}
          />
        </div>
      ) : null}

      <div className="top-button">
        <Button onClick={handleContinue} disabled={!!!selectedList?.length}>
          Continue
        </Button>
      </div>
    </ReqWrapper>
  );
}

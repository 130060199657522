import styled from "styled-components";
import approveImage from "../../assets/images/approve.png";
import { Button } from "../../common/components/button";

export interface ISuccessModalProps {
  onProceedClick: () => void;
}

const ModalWrapper = styled.div`
  width: 35rem;
  background-color: ${({ theme }) => theme.bg.prim_300};
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem 3rem 3rem;

  img {
    width: 5rem;
  }

  .congrat-title {
    margin-top: 1.5rem;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.text.secondary};
  }

  p {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.85rem;
    color: ${({ theme }) => theme.text.primary_100};
    line-height: normal;
  }

  .button-proceed {
    margin-top: 2rem;
  }
`;

export function SuccessModal({ onProceedClick }: ISuccessModalProps) {
  return (
    <ModalWrapper>
      <img src={approveImage} alt="approval" />
      <h2 className="congrat-title">Congratulation</h2>
      <p>
        Welcome! <br /> A verification link has been sent to your email and an
        OTP sent to your phone number, follow the link on your email to verify
        your phone number and email
      </p>

      <Button
        onClick={onProceedClick}
        className="button-proceed"
        width={"100%"}
        secondary
      >
        Proceed
      </Button>
    </ModalWrapper>
  );
}

export const tableKeys = ["email", "phone"];

export const tableHeaders = [
  "Email",
  "Phone",
];




export const singleDistributorData = [
  {
    inputType: "email",
    prompt: "Company email address",
    name: "email",
    placeholder: "Enter the company's email address",
    fullWidth: true,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Company Phone number",
    name: "phone",
    placeholder: "Enter the company's phone number",
    fullWidth: true,

  },
]
import Table from "src/common/components/Table";
import { H3 } from "src/common/elements";
import styled from "styled-components";
import {
  distributorTableHeaders,
  distributorTableKeys,
  getProductTableData,
  productTableHeaders,
  productTableKeys,
} from "./data";
import { KeyValueObj } from "src/common/types";
import { KeyValueContainer } from "src/common/components/key-value-wrapper";
import { useEffect, useState } from "react";
import { Button } from "src/common/components/button";
import { formatKey } from "src/common/utils/general";

const PreviewWrapper = styled.div`
  width: 52rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 4rem 2rem;
  margin-top: 0.1rem;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.2rem;
  }

  .preview-section {
    padding-bottom: 1rem;
  }

  .prev-title {
    padding: 1rem 0;
  }

  .bold {
    font-weight: 700;
  }

  .preview-control {
    padding: 1rem 2rem;
    width: 100%;
    justify-content: space-between;
  }
`;

export interface IPreviewProps {
  addedProducts: KeyValueObj[];
  addedDistributors: KeyValueObj[];
  paymentDeliveryInformation: KeyValueObj;
  distributorsCategory: string;
  onPreviewSubmit: () => void;
  onPreviewBack: () => void;
  submitLoading: boolean;
}

export function Preview({
  addedProducts,
  addedDistributors,
  distributorsCategory,
  paymentDeliveryInformation,
  onPreviewSubmit,
  onPreviewBack,
  submitLoading,
}: IPreviewProps) {
  const [paymentDeliveryData, setPaymentDeliveryData] = useState<KeyValueObj[]>(
    []
  );

  useEffect(() => {
    const data: any = [];
    Object.keys(paymentDeliveryInformation).forEach((objKey: string) => {
      if (
        objKey !== "id" &&
        objKey !== "address_id" &&
        objKey !== "building_number" &&
        objKey !== "street"
      ) {
        data.push({
          value: paymentDeliveryInformation[objKey],
          title: formatKey(objKey),
        });
      }
    });
    setPaymentDeliveryData(data?.reverse());
  }, [paymentDeliveryInformation]);

  return (
    <PreviewWrapper>
      <div className="preview-section">
        <H3 className="prev-title">PRODUCT INFORMATION</H3>
        <Table
          tableHeaders={productTableHeaders}
          tableData={getProductTableData(addedProducts)}
          tableKeys={productTableKeys}
          rowLineColor="light"
          full
          removeFilters
        />
      </div>
      <div className="preview-section">
        <H3 className="prev-title">DISTRIBUTORS INFORMATION</H3>
        <div className="sent-to">
          <h3 className="dash-text">
            Send to: <span className="dash-text">{distributorsCategory}</span>
          </h3>
        </div>
        {distributorsCategory === "select" ? (
          <Table
            tableHeaders={distributorTableHeaders}
            tableData={addedDistributors}
            tableKeys={distributorTableKeys}
            rowLineColor="light"
            removeFilters
            full
          />
        ) : null}
      </div>
      <div className="preview-section">
        <H3 className="prev-title">PAYMENT & DELIVERY INFORMATION</H3>
        <KeyValueContainer data={paymentDeliveryData} />
      </div>

      <div className="flex-row preview-control">
        <Button secondary onClick={onPreviewBack}>
          Back
        </Button>
        <Button loading={submitLoading} onClick={onPreviewSubmit}>
          Submit
        </Button>
      </div>
    </PreviewWrapper>
  );
}

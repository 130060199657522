import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useContexts";
// import useRefreshToken from "../../../hooks/useRefreshToken";
import { Outlet } from "react-router-dom";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const refresh = useRefreshToken();
  const { auth, setAuth, persist } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        //await refresh();

        const token = JSON.parse(localStorage.getItem("token") as string);
        const user = JSON.parse(localStorage.getItem("user") as string);
        
        setAuth({ token, user });
      } catch (err: any) {
        console.log(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accesstoken ? verifyRefreshToken() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    
     <>{!persist ? <Outlet /> : isLoading ? <p>Loading page</p> : <Outlet />}</>
  );
};

export default PersistLogin;

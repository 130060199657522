import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosCall from "../../../../hooks/useAxiosCall";
import { PRODUCT_URL } from "../../../../common/constants";
import { DataWrapper } from "../../../../common/components/dataWrapper";
import { ProductDetails } from "../../../../common/components/product-details";
import { useAuthDetails } from "../../../../hooks/useAuthDetails";
import { useApiCallHandling } from "../../../../hooks/useApiCallHandling";

import { ProductFactory } from "../../common/product-factory";
import { KeyValueObj } from "../../../../common/types";

const ProductDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem 0 4rem;
  flex-wrap: wrap;

  .loading-text {
    color: ${({ theme }) => theme.button.primary};
    font-size: 1.2rem;
    font-weight: 400;
  }

  .carousel-wrapper {
    width: 50%;
  }
  .details {
    width: 50%;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .top {
      margin-bottom: 1.5rem;

      .brand {
        font-size: 3rem;
        font-weight: 600;
        color: ${({ theme }) => theme.text.primary};
      }
      .name {
        color: ${({ theme }) => theme.text.input};
        font-weight: 400;
        font-size: 1.4rem;
      }
    }

    .further-details {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .item {
      min-width: 45%;

      .title {
        color: ${({ theme }) => theme.text.muted_200};
        font-weight: 400;
        font-size: 1.2rem;
      }

      .value {
        color: ${({ theme }) => theme.text.input};
        font-weight: 500;
        font-size: 1.2rem;
        text-transform: capitalize;
      }
    }
  }
`;

export interface IProductDetailsProps {}

export function ProductDetailsPage(props: IProductDetailsProps) {
  const [retry, setRetry] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [product, setProduct] = useState<KeyValueObj>({});

  const { id } = useParams();

  //CUSTOM HOOKES
  const [fetchProduct, fetchedProduct, productError, productLoading] =
    useAxiosCall();
  const { companyId } = useAuthDetails();
  const apiCall = useApiCallHandling();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProduct({ url: PRODUCT_URL + "/" + id, method: "GET" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, retry]);

  useEffect(() => {
    setProduct(fetchedProduct);
  }, [fetchedProduct]);

  function handleEditProduct() {
    setEditProduct(true);
  }

  function deleteProduct() {
    setDeleteLoading(true);
    apiCall(
      PRODUCT_URL + "/" + id,
      "DELETE",
      {},
      (resp: any) => {
  
        navigate("/dashboard/products");
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        setDeleteLoading(false);
      }
    );
  }

  function updateProductInfo(data: KeyValueObj, done: boolean) {
    setProduct(data);
    done && setEditProduct(false);
  }

  return (
    <ProductDetailsWrapper>
      <ProductFactory
        open={editProduct}
        close={() => {
          setEditProduct(false);
        }}
        editMode
        product={product}
        onSuccessfullMutation={updateProductInfo}
        productType="drug"
      />
      <DataWrapper
        data={product}
        noDataMessage={"No product to show"}
        loading={productLoading}
        error={productError}
        loadingMessage="Loading products data..."
        failedMessage="Could not fetch products data. Check your network and click the button below to retry"
        onFailAction={() => {
          setRetry((prev) => prev + 1);
        }}
      >
        <>
          <ProductDetails
            deleteLoading={deleteLoading}
            companyId={companyId}
            product={product}
            handleDelete={deleteProduct}
            handleEdit={handleEditProduct}
          />
        </>
      </DataWrapper>
    </ProductDetailsWrapper>
  );
}

import { useState } from "react";
import styled from "styled-components";
import { ClipSpinner } from "../../../../common/components/spinner";
import { Button, H4 } from "../../../../common/elements";
import { beforeToday } from "../../../../common/utils";

const EnquiryWrapper = styled.div`
  width: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 3rem;
  gap: 2.5rem;

  textarea {
    width: 100%;
    height: 6rem;
    resize: none;
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.border.input};
    outline: none;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.8rem;
  }

  input {
    width: 100%;
    height: 4rem;
    padding: 1rem;
    margin: 2rem 0;
    outline: none;
    border: 1px solid ${({ theme }) => theme.text.input};
    border-radius: 0.25rem;
  }

  .controls {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
`;

export interface IActionEnquiryProps {
  title: string;
  enquiryType: string;
  onCancel: () => void;
  onContinue: (text: string) => void;
  enquiryLoading: boolean;
}

const NewH3 = styled(H4)`
  text-align: center;
  padding: 0 4rem;
`;

export function ActionEnquiry({
  onCancel,
  title,
  enquiryType,
  onContinue,
  enquiryLoading,
}: IActionEnquiryProps) {
  const [text, setText] = useState("");

  let disableContinue = true;
  if (enquiryType === "date" && enquiryType.length > 0) {
    disableContinue = beforeToday(text);
  }
  if (enquiryType === "reason" && enquiryType.length) {
    disableContinue = false;
  }


  return (
    <EnquiryWrapper>
      <NewH3>{title}</NewH3>
      {enquiryType === "reason" && (
        <textarea
          onChange={(e) => setText(e.target.value)}
          autoFocus
        ></textarea>
      )}
      {enquiryType === "date" && (
        <input
          type={"date"}
          onChange={(e) => setText(e.target.value)}
          autoFocus
        />
      )}

      <div className="controls">
        <Button
          disabled={enquiryLoading}
          onClick={onCancel}
          color="danger"
          borderColor="danger"
          secondary
        >
          Cancel
        </Button>
        <Button
          disabled={disableContinue || enquiryLoading}
          onClick={() => onContinue(text)}
        >
          {enquiryLoading ? <ClipSpinner /> : "Continue"}
        </Button>
        {enquiryType === "reason" && (
          <Button
            disabled={enquiryLoading}
            secondary
            onClick={() => onContinue(text)}
          >
            Skip
          </Button>
        )}
      </div>
    </EnquiryWrapper>
  );
}

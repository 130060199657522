import "./styles";
import {
  AbstractCircle,
  AbstractCircleBottom,
  ContentWrapper,
  Description,
  Header,
  TeamContainer,
} from "./styles";
export default function OurTeam() {
  return (
    <>
      <TeamContainer>
        {/* Abstract Background Shapes */}
        <AbstractCircle />
        <AbstractCircleBottom />

        {/* Content */}
        <ContentWrapper>
          <Header>Meet Our Team</Header>
          <Description>
            <p>
              MedEX systems limited is an indigenous health technology company
              and member of the Bluetag Group (a group of renowned and leading
              technology businesses spanning critical sectors in Nigeria).
            </p>
            <p>
              {" "}
              MedEx offers a unique technology platform that connects
              drug/medical supply manufacturers and key distributors (supply
              side) with hospitals, pharmacies and delivery service providers
              (demand side)
            </p>
            <p>
              {" "}
              Through reliable online interaction with the inventory systems of
              these providers enabling them to enjoy automated supplies ordering
              and delivery using sophisticated supply chain planning/fulfillment
              systems and services.
            </p>
          </Description>
        </ContentWrapper>
      </TeamContainer>
    </>
  );
}

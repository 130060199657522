// import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import testimonial_img1 from "../../assets/images/uzo.png";
import quote from "../../assets/images/quote.svg";
import { Carousel } from "react-responsive-carousel";

const testimonials = [
  {
    content:
      "The platform connects manufacturers and key distributors (supply side) with hospitals, pharmacies and healthcare providers (demand side) through reliable online interaction with the inventory.",
    author: "Uzo Eziukwu",
    title: "CEO,",
    img: testimonial_img1,
  },

  {
    content:
      "The platform connects manufacturers and key distributors (supply side) with hospitals, pharmacies and healthcare providers (demand side) through reliable online interaction with the inventory.",
    author: "Uzo Eziukwu",
    title: "BlueTag",
    img: testimonial_img1,
  },
  {
    content:
      "The platform connects manufacturers and key distributors (supply side) with hospitals, pharmacies and healthcare providers (demand side) through reliable online interaction with the inventory.",
    author: "Uzo Eziukwu",
    title: "Group",
    img: testimonial_img1,
  },
  // Add more testimonial objects here
];

const carouselSettings = {
  autoPlay: true, // Enable auto sliding
  interval: 4000, // Time in milliseconds between slides (5 seconds)
  slidesToShow: 2, // Show two items at a time
  infiniteLoop: true,
  showArrows: true,
  dots: true,
  slidesToScroll: 1,
  showStatus: false, // Hide number of items
  // Other settings...
};
function Testimonial() {
  return (
    <div className="testimonial_slidder">
      <Carousel className="test_caro" {...carouselSettings}>
        {testimonials.map((testimonial, index) => (
          <div className="caro">
            <div key={index} className="testimonial_card">
              <div>
                <article>{testimonial.content}</article>
                <div className="test_id">
                  <img className="tes_img" src={testimonial.img} alt="desc" />
                  <div className="author">
                    <h3>{testimonial.author}</h3>
                    <p>{testimonial.title}</p>
                  </div>
                  <img className="quote" src={quote} alt="desc" />
                </div>
              </div>
            </div>
            <div key={index} className="testimonial_card">
              <div>
                <article>{testimonial.content}</article>
                <div className="test_id">
                  <img className="tes_img" src={testimonial.img} alt="desc" />
                  <div className="author">
                    <h3>{testimonial.author}</h3>
                    <p>{testimonial.title}</p>
                  </div>
                  <img className="quote" src={quote} alt="desc" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Testimonial;

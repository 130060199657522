import styled from "styled-components";
import { KeyValueObj } from "../../types";
import { KeyValue } from "../key-value";

const KVWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 18rem);
  justify-content: space-between;
  grid-gap: 2rem;
`;

interface IKVCProps {
  data: KeyValueObj[];
  loading?: boolean;
}

export function KeyValueContainer({ data, loading }: IKVCProps) {
  return (
    <KVWrapper>
      {loading
        ? [...Array(6)].map((arr, i: number) => (
            <KeyValue loading key={i} title="" value="" />
          ))
        : data?.map((datum: KeyValueObj, i: number) =>
            typeof datum.value === "string" ||
            typeof datum.value === "number" ? (
              <KeyValue
                key={i}
                title={datum.title}
                value={datum.value?.toString()}
              />
            ) : null
          )}
    </KVWrapper>
  );
} 

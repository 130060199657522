import { useState, useEffect } from "react";
import { KeyValueObj } from "../common/types";
import { useAxiosAuth } from "./useAxiosAuth";
import useLogout from "./useLogout";

const useQuery = (url: string) => {
  const [response, setResponse] = useState<any>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<KeyValueObj>({});
  const [requestUrl, setrequestUrl] = useState("");
  const logout = useLogout();

  const axiosAuth = useAxiosAuth();
  useEffect(() => {
    axiosCall(url);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  async function axiosCall(url: string) {
    setrequestUrl(url);
    setLoading(true);
    try {
      const res = await axiosAuth.get(url);
      if (res.data.success) {
        setMeta(res.data.meta);
        setResponse(res.data.data);
        setError("");
      } else {
        setError("Something went wrong");
      }
    } catch (err: any) {
      if (err?.response?.request?.status === 401) {
        return logout();
      }
      setError(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function refetch(url?: string) {
    axiosCall(url || requestUrl);
  }
  return { data: response, error, loading, meta, refetch };
};

export default useQuery;

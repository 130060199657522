import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useSearchParams, useNavigate } from "react-router-dom";
import { sendOtp } from "../../api";
import { useNotification } from "../../common/components/Notifications/NotificationProvider";
import { Button } from "../../common/components/button";

import dotsImage from "../../assets/images/dots.png";
import pharmaBottleImage from "../../assets/images/pharma-bottle.png";
import { OTPWrapper } from "./styles";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { RESEND_OTP_URL } from "src/common/constants";

interface InputProps {
  value: string | number;
}

const Input = styled.input<InputProps>`
  background-color: ${({ value, theme }) =>
    value ? theme.bg.secondary : theme.bg.prim_500};
  width: 3.5rem;
  height: 3.5rem;
  font-size: 2rem;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.border.sec_button};
  border-radius: 0.2rem;
  color: ${({ value, theme }) =>
    value ? theme.text.prim_500 : theme.text.primary};

  :focus {
    border: 2px solid ${({ theme }) => theme.border.sec_button};
    box-shadow: ${({ theme }) => theme.shadow.drag_over};
    outline: none;
  }
`;

export interface IOTPProps {}

var interval: any;

export function OTP(props: IOTPProps) {
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phone_number");
  const verificationCode = searchParams.get("verification_code");

  const navigate = useNavigate();
  const notify = useNotification();

  const ref1 = useRef<HTMLInputElement>(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const ref3 = useRef<HTMLInputElement>(null);
  const ref4 = useRef<HTMLInputElement>(null);
  const ref5 = useRef<HTMLInputElement>(null);
  const ref6 = useRef<HTMLInputElement>(null);

  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [currentInput, setCurrentInput] = useState(0);
  const [pasted, setPasted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countDown, setCountDown] = useState(59);
  const [resendLoading, setResendLoading] = useState(false);

  const makeRequest = useApiCallHandling();
  const refArr = useMemo(() => [ref1, ref2, ref3, ref4, ref5, ref6], []);

  useEffect(() => {
    if (currentInput <= refArr.length - 1) {
      refArr[currentInput].current?.focus();
    }
  }, [currentInput, refArr]);

  useEffect(() => {
    startTimer();
  }, []);

  function onInputChange(e: any, index: number) {
    if (!pasted) {
      const value = e.target.value;
      setValues((prevValue) => {
        const copy = [...prevValue];
        copy[index] = value.substring(value.length - 1);
        return copy;
      });

      if (e.target.value) {
        setCurrentInput(index + 1);
      }
    } else {
      setPasted(false);
    }
  }

  function onPaste(e: any, index: number) {
    setPasted(true);
    const value = e.clipboardData.getData("text").split("");
    const copy = [...values];

    for (let i = index, j = 0; i < values.length; i++, j++) {
      const asciiCode = value[j].charCodeAt();
      if (asciiCode > 57 || asciiCode < 48) return;
      copy[i] = value[j];
    }
    setValues(copy);
  }

  function handleKeyDown(event: any, index: number) {
    if ((event.keyCode === 8 || event.keyCode === 46) && index > 0) {
      setValues((prevValue) => {
        const copy = [...prevValue];
        copy[index] = "";
        return copy;
      });
      setCurrentInput(index - 1);
    }
  }

  async function handleConfirm() {
    setLoading(true);
    const otp = values.join("");
    try {
      const resp = await sendOtp(
        phoneNumber as string,
        verificationCode as string,
        otp
      );
      if (resp.data.success) {
        const { message } = resp.data;
        if (typeof message === "string") {
          notify({
            type: "SUCCESS",
            message: message,
          });
        } else {
          message.map((mess: string) =>
            notify({
              type: "SUCCESS",
              message: mess,
            })
          );
        }
        navigate("/login");
      }
    } catch (e: any) {
      console.log(e);
      const message = e.response?.data?.message;
      if (typeof message === "string") {
        notify({
          type: "ERROR",
          message: message,
        });
      } else {
        message &&
          message.map((mess: string) =>
            notify({
              type: "ERROR",
              message: mess,
            })
          );
      }
    } finally {
      setLoading(false);
    }
  }

  function startTimer() {
    setCountDown(59);
    interval =
      interval ??
      setInterval(() => {
        setCountDown((prev: number) => {
          if (prev <= 1) {
            clearInterval(interval);
            interval = null;
          }
          return prev - 1;
        });
      }, 1200);
  }

  function handleSkip() {
    notify({
      type: "SUCCESS",
      message: "Your phone number will be verified later",
    });

    navigate("/login");
  }

  function handleResendOtp() {
    startTimer();
    setResendLoading(true);
    makeRequest(
      RESEND_OTP_URL + `?phone_number=${phoneNumber}`,
      "GET",
      {},
      () => {},
      () => {},
      () => setResendLoading(false)
    );
  }

  return (
    <OTPWrapper>
      <section className="container">
        <section className="info-sec">
          <img className="pharma-image" src={pharmaBottleImage} alt="" />
          <img className="dots-image" src={dotsImage} alt="" />
        </section>
        <section className="input-sec">
          <div className="top flex-row">
            <div className="welcome-message">
              <h2 className="title">Enter Your OTP</h2>
              <p className="more-info">
                Kindly enter the 6 digits OTP sent to your phone number:{" "}
                <strong> {phoneNumber}</strong>{" "}
              </p>
            </div>
          </div>

          <div className="inputs">
            {refArr &&
              refArr.map((singleRef, i) => (
                <Input
                  key={i}
                  onChange={(e) => onInputChange(e, i)}
                  onPaste={(e) => onPaste(e, i)}
                  ref={singleRef}
                  type="number"
                  value={values[i]}
                  onKeyDown={(e) => handleKeyDown(e, i)}
                />
              ))}
          </div>

          <Button disabled={loading} loading={loading} onClick={handleConfirm}>
            Verify and Continue
          </Button>
          <div className="resend-wrapper">
            <Button
              loading={resendLoading}
              onClick={handleResendOtp}
              disabled={countDown > 0 || resendLoading}
              secondary
            >
              Resend OTP
            </Button>{" "}
            {countDown <= 0 ? null : <p>{countDown}</p>}
          </div>
          <button className="skip" onClick={handleSkip}>
            skip this step
          </button>
        </section>
      </section>
    </OTPWrapper>
  );
}

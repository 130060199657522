import React, { useState, Dispatch, SetStateAction } from "react";
// import { FormField, Input, Label, Select } from "../styles";

import { FormField, Input, Label } from "../styles";

import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { RESEND_OTP_URL } from "src/common/constants";
import { Button } from "src/common/components/button";

interface IOTPVerification {
  setOtp: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  otp: string;
}

function OTPVerification({ setOtp, phoneNumber, otp }: IOTPVerification) {
  const [resendLoading, setResendLoading] = useState(false);

  const MakeRequest = useApiCallHandling();

  function ResendOtp() {
    setResendLoading(true);
    MakeRequest(
      RESEND_OTP_URL + `?phone_number=${phoneNumber}`,
      "GET",
      {},
      () => {},
      () => {},
      () => setResendLoading(false)
    );
  }

  return (
    <div>
      <div className="input_container">
        <div className="heading">MEDEX Onboarding</div>
        <div className="sub-heading">
          <div className="stepcounter">Step 2 of 4</div>
          <div className="stepcountTitle">OTP Verification</div>
        </div>
      </div>
      <form>
        <FormField>
          <Label htmlFor="name">Enter OTP</Label>
          <Input
            id="name"
            name=""
            type="text"
            placeholder="012345"
            value={otp}
            onChange={(e) => setOtp(e?.target?.value)}
            required
          />
        </FormField>
      </form>
      <Button
        loading={resendLoading}
        secondary
        onClick={ResendOtp}
        style={{ textDecoration: "none" }}
      >
        Resend verification code
      </Button>
      {/* <div style={{ margin: "50px", fontSize: "30px" }}> Class room </div> */}
    </div>
  );
}

export default OTPVerification;

import { useCallback, useEffect, useState } from "react";
import dotsImage from "../../assets/images/dots.png";
import { Button } from "src/common/components/button";
import {
  SlidderWrapper,
  NewRegWrapper,
  Slide,
  Wrapper,
  RedirectModalWrapper,
} from "./styles";
import OTPVerification from "./onboarding/otp-verification";

import { FormContainer } from "./styles";
import UserDetail, { IStep1Data } from "./onboarding/user-details";
import TinVerification from "./onboarding/tin_verification";
import PcnIDVerification from "./onboarding/pcn-lincence";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { COMPANY_URL, REGISTER_USER_URL } from "src/common/constants";
import { Modal } from "src/common/components/modal";

// import { userRegContext } from "src/context/RegData";

export interface ILoginProps {}

const initialFormData: IStep1Data = {
  email: "",
  phone_number: undefined,
  company_type_id: "",
};

const initialPCNDetails = {
  pcn_licence_certificate: null as File | null,
  current_pcn_licence_number: "",
  current_pcn_licence_iss_date: "",
  current_pcn_licence_exp_date: "",
};

export function NewReg(props: ILoginProps) {
  const [formData, setFormData] = useState<IStep1Data>(initialFormData);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [tinData, setTinData] = useState<any>({});
  const [companyData, setCompanyData] = useState<any>({});
  const [pcnData, setPcnData] = useState(initialPCNDetails);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  const currentStep = parseInt(searchParams.get("currentStep") || "1");
  const phoneNumber = searchParams.get("phone_number");
  const verificationCode = searchParams.get("verification_code");

  const makeRequest = useApiCallHandling();

  useEffect(() => {
    const compData = JSON.parse(localStorage.getItem("companyData") || "{}");
    setCompanyData(compData);
  }, [setTinData]);

  const handleStepSucess = useCallback((data: any) => {}, []);

  const handleOtpSuccess = useCallback(
    (data: any) => {
      localStorage.setItem("companyData", JSON.stringify(data));
      window.location.href = "/register?currentStep=3";
      // setSearchParams({ currentStep: (currentStep + 1)?.toString() });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, setSearchParams]
  );

  const SendOTP = () => {
    localStorage.setItem("verificationCode", verificationCode || "");
    const payload = {
      phone_number: phoneNumber,
      verification_code: verificationCode,
      otp_code: otp,
    };
    setLoading(true);
    makeRequest(
      "api/v1/users/otp_verify",
      "post",
      payload,
      handleOtpSuccess,
      console.log,
      () => setLoading(false),
      () => {}
    );
  };

  const saveAndContinueStep1 = useCallback(() => {
    setLoading(true);
    makeRequest(
      REGISTER_USER_URL,
      "POST",
      formData,
      handleStepSucess,
      console.log,
      () => setLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleRedirectToPcn = useCallback((data: any, meta: any) => {
    let PCN_BASE_URL = process.env.REACT_APP_PCN_FRONTEND_BASE_URL;
    const url =
      PCN_BASE_URL +
      "self-service/auth/company-registration?token=" +
      meta?.token;
    setRedirectUrl(url);
    setShowRedirectModal(true);
  }, []);

  const pcnUpdate = useCallback(() => {
    setLoading(true);
    const formData = new FormData();
    (Object.keys(pcnData) as Array<keyof typeof initialPCNDetails>)?.forEach(
      (key) => {
        if (pcnData[key] && selectedOption?.toLowerCase() === "yes")
          formData.append(key, pcnData[key] as string | Blob);
      }
    );

    formData.append("admin_email", companyData?.email);
    formData.append("onboarding_step", "pcn_licence_stage");
    makeRequest(
      "/api/v1/companies",
      "POST",
      formData,
      handleRedirectToPcn,
      console.log,
      () => setLoading(false)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcnData, currentStep, selectedOption]);

  const tinUpdate = useCallback(() => {
    const payload = {
      admin_email: companyData?.email,
      corporate_address: tinData.Address,
      tin: tinData?.TIN,
      rc_number: tinData?.RCNumber,
      date_of_establishment: tinData?.date_of_establishment,
      name: tinData?.TaxPayerName,
      onboarding_step: "tin_verification_stage",
    };

    setLoading(true);
    makeRequest(
      COMPANY_URL,
      "POST",
      payload,
      () => setSearchParams({ currentStep: (currentStep + 1)?.toString() }),
      console.log,
      () => setLoading(false)
    );
  }, [companyData?.email, currentStep, makeRequest, setSearchParams, tinData]);

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        saveAndContinueStep1();
        break;
      case 2:
        SendOTP();
        break;
      case 3:
        tinUpdate();
        break;

      case 4:
        pcnUpdate();
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    setSearchParams({ currentStep: (currentStep - 1)?.toString() });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev: IStep1Data) => ({ ...prev, [name]: value }));
  };

  const handleChangePCN = (e: any) => {
    const { name, value } = e.target;
    if (name === "pcn_licence_certificate") {
      setPcnData((prev) => ({
        ...prev,
        [name]: (e.target as any)?.files?.[0],
      }));
    } else {
      setPcnData((prev) => ({ ...prev, [name]: value }));
    }
  };

  console.log({ currentStep });

  return (
    <>
      <NewRegWrapper>
        <section className="container">
          <section className="info-sec">
            <div className="text-wrapper">
              <h2>
                Getting started is easy. <br />
              </h2>
              <p>
                MedEX is a service that simplifies and automates pharmaceutical
                and medical supplies to hospitals, pharmacies, and other
                healthcare providers as well as process prescriptions and
                dispatch medicines to patients.
              </p>
            </div>
            <img className="dots-image" src={dotsImage} alt="" />
          </section>
          <section className="input-sec">
            <FormContainer>
              <Wrapper>
                <SlidderWrapper currentStep={currentStep}>
                  {/* Screen 1 */}
                  <Slide
                    style={{ maxHeight: currentStep === 1 ? "auto" : "2rem" }}
                  >
                    <UserDetail
                      formData={formData}
                      handleChange={handleChange}
                    />
                  </Slide>

                  {/* Screen 2 */}
                  <Slide  style={{ maxHeight: currentStep === 2 ? "auto" : "2rem" }}>
                    <OTPVerification
                      phoneNumber={phoneNumber || ""}
                      setOtp={setOtp}
                      otp={otp}
                    />
                  </Slide>

                  {/* Screen 3 */}
                  <Slide  style={{ maxHeight: currentStep === 3 ? "auto" : "2rem" }}>
                    <TinVerification
                      tinData={tinData}
                      setTinData={setTinData}
                    />
                  </Slide>

                  {/* Screen 4 */}
                  <Slide  style={{ maxHeight: currentStep === 4 ? "50rem" : "2rem" }}>
                    <PcnIDVerification
                      onChange={handleChangePCN}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                    />
                  </Slide>

                  {/* <Slide>
                    <CreatePassword
                      handleChangePassword={handleChangePassword}
                      handleBack={handleBack}
                    />
                  </Slide> */}
                </SlidderWrapper>

                {currentStep === 1 || currentStep > 4 ? null : (
                  <div className="nav_buttons">
                    {currentStep > 1 && (
                      <IoArrowBackCircleOutline
                        className="back-arrow"
                        onClick={handleBack}
                        size={"40"}
                      />
                    )}

                    <Button loading={loading} onClick={handleNext}>
                      Save and Continue
                    </Button>
                  </div>
                )}
              </Wrapper>{" "}
            </FormContainer>
          </section>
        </section>

        <Modal
          title="PCN Self Service Redirect"
          isOpen={showRedirectModal}
          close={() => setShowRedirectModal(false)}
        >
          <RedirectModalWrapper>
            <p>
              You are being redirected to the PCN self service portal for
              registeration. Click on the continue button to continue to PCN
              self service.
            </p>
            <Button onClick={() => window.open(redirectUrl, "_blank")?.focus()}>
              Continue
            </Button>
          </RedirectModalWrapper>
        </Modal>
      </NewRegWrapper>
    </>
  );
}

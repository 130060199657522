import styled from "styled-components";

export const RequisitionWrapper = styled.div`
  width: 100%;
  padding-bottom: 10rem;
  padding: 2rem;


  @media (max-width: ${({theme}) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;

    .button-wrapper{
      padding: 0 3rem;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;

    @media (max-width: ${({theme}) => theme.screen.mobile}) {
    padding: 3rem 0;


  }

  }
  .requisitions {
    display: flex;
    flex-direction: column;
    .table-wrapper1 {
      background-color: ${({ theme }) => theme.bg.prim_500};
      padding: 2rem;


      @media (max-width: ${({theme}) => theme.screen.mobile}) {
        padding: 2rem 0.5rem
  }
    }
  }

  .enquiry-text-wrapper {
   
    padding: 1.5rem;
    max-width: 15rem;
    background-color: white; 
    z-index: 9999; 
  
  }

  .filter-wrapper {
    padding: 2rem 1rem;

    .filter-type {
      padding: 0 2rem 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.input};
    }

    .status {
      .status-text {
        font-size: 1.1rem;
        margin: 0.75rem 0 0 2rem;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
`;

interface ActionProps {
  position: any;
}
export const ActionsWrapper = styled.div<ActionProps>`
  .backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .action-items {
    width: 15rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    position: fixed;
    ${({ position }) => position.cordinate + ":" + position.value};
    opacity: 0;
    right: 2rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    opacity: 1;
    right: 3rem;

    .no-action {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.prim_100};
    }
  }
`;

import useQuery from "src/hooks/useQuery";
import { RespondRfqWrapper } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  DISTRIBUTORS_URL,
  INVENTORY_URL,
  QUOTATION_URL,
  RFQ_URL,
} from "src/common/constants";
import { useAuthDetails } from "src/hooks/useAuthDetails";
import { RespondViewRfq } from "./respond-view-rfq";
import { useEffect, useState } from "react";
import { KeyValueObj } from "src/common/types";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { PageTitleNavs } from "src/common/components/page-title-navs";

export interface IRespondRfqProps {}

export function RespondRfq(props: IRespondRfqProps) {
  const { id } = useParams();
  const { companyId } = useAuthDetails();
  const { data: rfq, loading: rfqLoading } = useQuery(RFQ_URL + "/" + id);
  const { data: distributor, loading: distributorLoading } = useQuery(
    DISTRIBUTORS_URL + "/" + companyId
  );

  const [prices, setPrices] = useState<KeyValueObj>({});
  const [rowTotals, setRowTotals] = useState<KeyValueObj>({});
  const [rowStockQuantities, setRowStockQuantities] = useState<KeyValueObj>({});
  const [rowQuantities, setRowQuantities] = useState<KeyValueObj>({});
  const [subTotal, setSubtotal] = useState<string | number>(0);
  const [discount, setDiscout] = useState<string | number>(0.0);
  const [tax, setTax] = useState<string | number>(0.0);
  const [logisticsCost, setLogisticsCost] = useState<string | number>(0.0);
  const [total, setTotal] = useState(0);

  const [terms, setTerms] = useState("");
  const [counterPaymentInfo, setCounterPaymentInfo] = useState<KeyValueObj>({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [inspecting, setInspecting] = useState(true);
  const [rfqData, setRfqData] = useState<KeyValueObj[]>([]);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);

  const makeRequest = useApiCallHandling();
  const navigate = useNavigate();
  const { data: inventories } = useQuery(INVENTORY_URL);

  useEffect(() => {
    if (rfq?.quotation?.length > 0) {
      const quotation = rfq?.quotation?.[0];
      setTerms(quotation?.remark);
      setTax(quotation.tax);
      setDiscout(quotation?.discount);
      setLogisticsCost(quotation?.delivery_charges);
      setTotal(quotation?.total_amount);
      setSubtotal(
        quotation?.product_prices?.reduce(
          (acc: any, current: KeyValueObj) =>
            (acc += current?.quantity * current?.price_per_unit),
          0
        )
      );
    }

    const data: KeyValueObj[] = [];
    Object.keys(rfq)?.forEach((objKey: string) => {
      switch (objKey) {
        case "expected_delivery_date":
        case "expiration_date":
        case "payment_term":
        case "shipping_address":
        case "shipping_city":
          return data.push({
            title: objKey?.split("_").join(" "),
            value: rfq[objKey],
          });

        default:
          return null;
      }
    });
    setRfqData(data as KeyValueObj[]);

    const quotationProducts = rfq?.quotation_request_products;
    let innerRowTotals: KeyValueObj = {};
    let innerRowCosts: KeyValueObj = {};
    let innerRowQuantity: KeyValueObj = {};
    let innerRowStockQuantity: KeyValueObj = {};
    quotationProducts?.forEach((rfqProduct: KeyValueObj) => {
      let productInventory = inventories?.find(
        (inventory: KeyValueObj) =>
          rfqProduct?.product_form === inventory?.product?.form &&
          rfqProduct?.brand_name === inventory?.product?.brand_name &&
          rfqProduct?.product_strength?.toString() ===
            inventory?.product?.strength?.toString() &&
          rfqProduct?.product_strength_unit ===
            inventory?.product?.strength_unit
      );

      innerRowTotals[rfqProduct?.id] =
        parseFloat(productInventory?.selling_price_per_unit) *
        parseFloat(rfqProduct?.quantity);
      innerRowCosts[rfqProduct?.id] = parseFloat(
        productInventory?.selling_price_per_unit
      );
      innerRowQuantity[rfqProduct?.id] = parseInt(rfqProduct?.quantity);
      innerRowStockQuantity[rfqProduct?.id] =
        productInventory?.quantity_in_stock - productInventory?.quantity_sold;
    });
    setRowTotals(innerRowTotals);
    setPrices(innerRowCosts);
    setRowQuantities(innerRowQuantity);
    setRowStockQuantities(innerRowStockQuantity);
  }, [rfq, inventories]);

  useEffect(() => {
    const innerSubtotal = Object.values(rowTotals)?.reduce(
      (acc, currentPrice) => (acc += parseInt(currentPrice)),
      0
    );
    setSubtotal(innerSubtotal);
    let currentTotal =
      innerSubtotal -
      Math.round(
        (parseFloat(discount?.toString() || "0") / 100) * innerSubtotal
      );
    currentTotal += Math.round(
      (parseFloat(tax?.toString() || "0") / 100) * currentTotal
    );
    currentTotal += parseFloat(logisticsCost?.toString() || "0");

    setTotal(currentTotal);
  }, [rowTotals, discount, tax, logisticsCost]);

  function handleOtherInputChange(type: string, value: string) {
    switch (type) {
      case "tax":
        setTax(value);
        break;
      case "discount":
        setDiscout(value);
        break;
      case "logisticsCost":
        setLogisticsCost(value);
        break;
    }
  }
  function handleAccountChange(type: string, value: string) {
    switch (type) {
      case "terms":
        setTerms(value);
        break;
    }
  }

  function handleInputChange(value: string, id: string, unitPrice: string) {
    setPrices?.((prev: KeyValueObj) => ({
      ...prev,
      [id]: value,
    }));
    setRowTotals?.((prev: KeyValueObj) => ({
      ...prev,
      [id]: parseFloat(value) * parseFloat(unitPrice),
    }));
  }

  function handleQuantityChange(value: string, id: string, unitPrice: string) {
    setRowQuantities?.((prev: KeyValueObj) => ({
      ...prev,
      [id]: value,
    }));
    setRowTotals?.((prev: KeyValueObj) => ({
      ...prev,
      [id]: parseFloat(value) * parseFloat(unitPrice),
    }));
  }

  function handleMainButton(props?: any) {
    setSubmitLoading(true);
    const products = rfq?.quotation_request_products?.map(
      (quoteProduct: KeyValueObj) => ({
        ...quoteProduct,
        price_per_unit: prices[quoteProduct?.id],
        quantity: rowQuantities[quoteProduct?.id],
      })
    );
    const payload = {
      quotation_request_id: rfq?.id,
      payment_term: counterPaymentInfo?.payment_term || rfq?.payment_term,
      payment_plan: counterPaymentInfo?.payment_plan || rfq?.payment_plan,
      supplier_id: distributor?.id,
      expiration_date: counterPaymentInfo?.expiration_date,
      discount,
      tax,
      delivery_charges: logisticsCost,
      remark: terms,
      total_amount: total,
      products,
    };

    makeRequest(
      QUOTATION_URL,
      "POST",
      payload,
      () => navigate("/dashboard/rfq"),
      console.log,
      () => setSubmitLoading(false)
    );
  }

  function handleSecondaryButton(props?: any) {
    setShowRejectConfirmation(true);
  }

  function updateCounterPaymentTerm(name: string, value: any) {
    setCounterPaymentInfo((prev: KeyValueObj) => ({ ...prev, [name]: value }));
  }

  function handleRespond() {
    setInspecting(false);
  }

  function closeDeclineModal() {
    setShowRejectConfirmation(false);
  }

  function handleDecline(reason: any) {
  
    setDeclineLoading(true);
    makeRequest(
      DISTRIBUTORS_URL + "/rfq/reject",
      "POST",
      {
        rfq_id: rfq.id,
        reason_for_rejection: reason,
      },
      (data) => {
        setShowRejectConfirmation(false);
        navigate("/dashboard/rfq");
      },
      (err) => {
        console.log(err);
      },
      () => {
        setDeclineLoading(false);
      }
    );
  }


  return (
    <RespondRfqWrapper>
      <PageTitleNavs
        title="View RFQ"
        backLink="/dashboard/rfq"
        bcrumbs={[
          { title: "Requests for quotation", link: "/dashboard/rfq" },
          { title: "View RFQ", link: "#" },
        ]}
      />
      <RespondViewRfq
        discount={discount}
        tax={tax}
        logisticsCost={logisticsCost}
        rowTotals={rowTotals}
        rowCosts={prices}
        rowQuantities={rowQuantities}
        rowStockQuantities={rowStockQuantities}
        subTotal={subTotal}
        total={total}
        handleInputChange={handleInputChange}
        handleQuantityChange={handleQuantityChange}
        handleOtherInputChange={handleOtherInputChange}
        company={distributor}
        rfq={rfq}
        handleAccountChange={handleAccountChange}
        terms={terms}
        handleMainButton={handleMainButton}
        handleSecondaryButton={handleSecondaryButton}
        mainButtonLoading={submitLoading}
        secondaryButtonLoading={declineLoading}
        updateCounterPaymentTerm={updateCounterPaymentTerm}
        loadingData={rfqLoading || distributorLoading}
        viewer="distributor"
        isViewing={rfq?.quotation?.length > 0}
        quotation={rfq?.quotation?.[0]?.product_prices}
        quotationExpiry={rfq?.quotation?.[0]?.expiration_date}
        inspecting={inspecting && !(rfq?.quotation?.length > 0)}
        rfqData={rfqData}
        handleRespond={handleRespond}
        handleDecline={handleDecline}
        showRejectConfirmation={showRejectConfirmation}
        closeDelcineModal={closeDeclineModal}
      />
    </RespondRfqWrapper>
  );
}

import { createContext, FormEvent, MouseEvent, useState } from "react";

export interface IDashBoardContextProps {
  children: JSX.Element;
}

const DashContext = createContext<any>({});

export function DashboardProvider({ children }: IDashBoardContextProps) {
  const [showDashboard, setShowDashboard] = useState(false);
  const [removeFooter, setRemoveFooter] = useState(false);
  const [searching, setSearching] = useState(false);
  const [onGlobalSearch, setOnGlobalSearch] = useState<
    (e: FormEvent<HTMLInputElement>) => void
  >(() => (e: FormEvent<HTMLInputElement>) => {});
  const [showOnlyLogout, setShowOnlyLogout] = useState(false);
  const [selectedInventories, setSelectedInventories] = useState<any[]>([]);
  const [onGlobalFilter, setOnGlobalFilter] = useState<
    (e: MouseEvent<HTMLElement>) => void
  >(() => (e: MouseEvent<HTMLElement>) => {});

  return (
    <DashContext.Provider
      value={{
        showDashboard,
        setShowDashboard,
        onGlobalSearch,
        setOnGlobalSearch,
        searching,
        setSearching,
        showOnlyLogout,
        setShowOnlyLogout,
        selectedInventories,
        setSelectedInventories,
        onGlobalFilter,
        setOnGlobalFilter,
        removeFooter,
        setRemoveFooter,
      }}
    >
      {children}
    </DashContext.Provider>
  );
}

export default DashContext;

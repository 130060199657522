import { PageContainr } from "../../common/elements/containers";
import { LandingPage, Section } from "./style";
import { Button } from "../../common/components/button";
import medSupply from "../../assets/images/medical_supplies.svg";
import Ellipse from "../../assets/images/Ellipse.svg";
import line from "../../assets/images/line_group.svg";
import verify from "../../assets/images/verify.png";
import dots from "../../assets/images/dots.png";
import vector_3 from "../../assets/images/Vector_3.png";
import vector_4 from "../../assets/images/Vector_4.svg";
import smartmockup from "../../assets/images/smartmockup.png";
import saly from "../../assets/images/Saly-1.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ButtonArray from "../../pages/buttons/button";
import Testimonial from "../../pages/testimonials/testimonial";
import { useNavigate } from "react-router-dom";

export interface IHomeProps {}

export function Home(props: IHomeProps) {
  const buttons = [
    "Manufacturers",
    "Distributors",
    "Delivery Personnel",
    "Retailers",
  ];

  const nav = useNavigate();
  const login = () => {
    nav("/login");
  };

  return (
    <PageContainr>
      <LandingPage>
        <div className="hero-section">
          <div className="header-container">
            <div className="left-content">
              <div className="heading">
                <h1>
                  Revolutionizing medical supply chain & Delivering better care
                  through smarter inventory management
                </h1>

                <article>
                  MedEX is a service that simplifies and automates
                  pharmaceutical and medical supplies to hospitals, pharmacies,
                  and other healthcare providers as well as process
                  prescriptions and dispatch medicines to patients.
                </article>

                <div className="btn_class">
                  <Button onClick={login}>GET STARTED</Button>
                  <a href={"/register"}>SIGN-UP</a>
                </div>
              </div>
            </div>

            <div className="right-content">
              <div className="left">
                <div className="square1">
                  <h1>1m</h1>
                  <h2>USERS TRUST MEDEX</h2>
                </div>

                <div className="square2">
                  <h1>20x</h1>
                  <h2>BETTER EFFICIENCY</h2>
                </div>
              </div>
              <div className="right">
                <div className="mobile-card">
                  <h2 className="app-name">MedEx App</h2>
                  <p className="app-description">
                    Revolutionizing the medical supply chain & Delivering better
                    care through smarter inventory management
                  </p>
                  <button onClick={login} className="get-started-btn">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LandingPage>

      <Section>
        <div className="Section2design">
          <img className="dot_icon" src={dots} alt="desc" />
          <img className="jeticon" src={saly} alt="desc" />
        </div>

        <div className="medEx_user">
          <h2>WHY USE MEDEX</h2>
          <p>
            MedEX improves availability and reliability of pharmaceuticals and
            medical supplies
          </p>

          <article>
            MedEX aims to drive down the cost, while improving availability and
            reliability of pharmaceuticals and medical supplies to patients by
            increasing the efficiency of the supply chain and reducing losses to
            hospitals, pharmacies, and other healthcare service providers.
          </article>
        </div>

        <div className="buttonArray">
          <ButtonArray buttons={buttons} />
        </div>

        <div className="middle_section_container">
          <div className="mid2_container2">
            <div className="sqr1">
              <div className="combo">
                <img className="elipse" src={Ellipse} alt="desc" />
                <img className="img_medSup" src={medSupply} alt="desc" />
              </div>

              <h3>Direct Route to Retailers</h3>
              <p>
                Medex establishes direct route to retailers, thus eliminating
                middlemen.
              </p>
            </div>
            <div className="sqr1">
              <div className="combo">
                <img className="elipse" src={Ellipse} alt="desc" />
                <img className="img_medSup" src={medSupply} alt="desc" />
              </div>
              <h3>Improved drug efficacy</h3>
              <p>
                Medex provides improved drug efficacy through compliant
                warehousing and delivery systems
              </p>
            </div>
            <div className="sqr1">
              <div className="combo">
                <img className="elipse" src={Ellipse} alt="desc" />
                <img className="img_medSup" src={medSupply} alt="desc" />
              </div>
              <h3>Increased sales efficiency</h3>
              <p>
                Create custom application forms rach roal, allowing your capture
                the right information up- font and helpful your filter candidate
              </p>
            </div>

            <div className="sqr1">
              <div className="combo">
                <img className="elipse" src={Ellipse} alt="desc" />
                <img className="img_medSup" src={medSupply} alt="desc" />
              </div>
              <h3>Elimination of fake drugs</h3>
              <p>
                Attracting more applications is easy when you understand which
                channels are bringing you the best candidates at the lowest
                costs.
              </p>
            </div>
          </div>
        </div>

        <div className="whyUseMedEx_container">
          <div className="whyUseMedEx_container_header">
            <h2>Say goodbye to missing drugs </h2>
            <img className="vector_line1" src={line} alt="desc" />
            <img className="vector_line2" src={vector_3} alt="desc" />
            <img className="vector_doc_icon" src={dots} alt="desc" />
          </div>

          <div className="whyUseMedEx_container_2">
            <div className="card">
              <div className="verify_con">
                <img className="verify" src={verify} alt="desc" />
                <h3 className="tag">Simple</h3>
              </div>
              <p>
                The platform is designed to be adopted easily by phamarcies,
                suppliers, distributors and manufacturers.
              </p>
            </div>
            <div className="card">
              <div className="verify_con">
                <img className="verify" src={verify} alt="desc" />
                <h3 className="tag">Fast</h3>
              </div>
              <p>
                The platform is designed to be adopted easily by phamarcies,
                suppliers, distributors and manufacturers.
              </p>
            </div>
            <div className="card">
              <div className="verify_con">
                <img className="verify" src={verify} alt="desc" />
                <h3 className="tag">Reliable</h3>
              </div>
              <p>
                MedEX is designed to be easily adopted by phamarcies, suppliers,
                distributors and manufacturers.
              </p>
            </div>

            <div className="card">
              <div className="verify_con">
                <img className="verify" src={verify} alt="desc" />
                <h3 className="tag">Intuitive</h3>
              </div>
              <p>
                MedEX is designed to be easily adopted by phamarcies, suppliers,
                distributors and manufacturers.
              </p>
            </div>
          </div>

          <div className="whyUseMedEx_container_actions">
            <Button onClick={login}>GET STARTED</Button>
          </div>
        </div>

        {/* *************** inventory management section ***************** */}
        <div className="invent_mgr">
          <div className="invent_mgr_left"></div>

          <div className="square_mgr">
            <h1>20X</h1>
            <h2>BETTER EFFICIENCY</h2>
          </div>

          <div className="invent_mgr_right">
            <h2>INVENTORY MANAGEMENT</h2>
            <p>We are redefining how you keep track of your inventory</p>
            <img className="vector_line3" src={vector_4} alt="desc" />
            <article>
              The platform connects manufacturers and key distributors (supply
              side) with hospitals, pharmacies and healthcare providers (demand
              side) through reliable online interaction with the inventory
              systems of healthcare providers enabling them enjoy automated
              supplies ordering and delivery.
            </article>
            <a href="/"> Learn More </a>
          </div>
        </div>

        <div className="our_thinking">
          <div className="think_left_text">
            <h2>OUR THINKING</h2>
            <p>All your drug supply needs in one place</p>

            <article>
              Imagine a world where your healthcare facility always has the
              right medication, at the right time. A world where managing your
              drug inventory is effortless and efficient. Medex is that world.
            </article>
          </div>

          <div className="think_right_img"> </div>
        </div>

        <div className="intuitive_dashboard">
          {/* <h2>INTUITIVE DASHBOARD</h2> */}
          {/* <p>Designed with you in mind.</p> */}
          <p>Our Intuitive Dashboard</p>
          <article>
            Designed with everyone in the drug ecosystem in mind, our intuitive
            dashboard provides real-time insights into your inventory, orders,
            and deliveries. Streamline your operations, reduce costs, and
            improve patient care with a single, easy-to-use platform.
          </article>
          <img src={smartmockup} alt="desc" />
        </div>

        <div className="testimonials_section">
          <h2>TESTIMONIAL</h2>
          <p>What people are saying about MedEx</p>

          <img className="testimonia_line" src={vector_4} alt="desc" />
          <Testimonial />
        </div>
      </Section>

      <div className="btn_class2">
        <Button onClick={login} style={{ marginBottom: "40px" }}>
          GET STARTED
        </Button>
      </div>
    </PageContainr>
  );
}

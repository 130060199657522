import { Skeleton } from "@mui/material";
import styled from "styled-components";

export interface ITableLoaderProps {
  columnSize: number;
}

export const SkeletonTD = styled.td`
  padding: 0.25rem;

  .skeleton-style {
    border-radius: 0.15rem;
    height: 1.7rem;
    background-color: ${({ theme }) => theme.bg.prim_400};
  }
`;

export function TableLoader({ columnSize }: ITableLoaderProps) {
  return (
    <>
      {[...Array(5)].map((data, i) => (
        <tr key={i}>
          {[...Array(columnSize)].map((data, i) => (
            <SkeletonTD key={i}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                className="skeleton-style"
                style={{}}
              />
            </SkeletonTD>
          ))}
        </tr>
      ))}
    </>
  );
}

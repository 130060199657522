import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { GitFormWrapper } from "./style";
import Input from "../Input/Input";
import { Select } from "../selectInput";
import { SelectParent } from "../selectParent";
import { SelectChild } from "../selectChild";
import { Button } from "../button";
import { H3 } from "src/common/elements";

interface IForm {
  loadingState?: boolean;
  submitLabel?: string;
  formInputs: any;
  processInputs: (values: any) => void;
  spinnerComponent?: any;
  login?: boolean;
  biControl?: boolean;
  handleBack?: (values?: any) => void;
  handleForgotPassword?: () => void;
  showForgotPassword?: boolean;
  submitButtonWidth?: string;
  submitButtonType?: string;
  removePadding?: boolean;
  backButton?: ReactNode;
  externalUpdateData?: (name: string, value: any) => void;
  removeSubmitButton?: boolean;
  externalSetIsFormValid?: Dispatch<SetStateAction<boolean>>;
}

function Form({
  loadingState,
  submitLabel,
  formInputs,
  processInputs,
  login,
  biControl,
  handleBack,
  handleForgotPassword,
  showForgotPassword,
  submitButtonWidth,
  submitButtonType,
  removePadding,
  backButton,
  externalUpdateData,
  removeSubmitButton,
  externalSetIsFormValid,
}: IForm) {
  const [formValues, setFormValues] = useState<Object>({});
  const [validations, setValidations] = useState<any>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    const tempValidations: any = {};
    formInputs?.forEach((input: any) => {
      //if initial value exists, then we set the validation to true initially.
      tempValidations[input.name] =
        validations[input.name] ||
        !!input.initialValue ||
        Object.keys(input?.rules || {})?.length === 0;
    });
    setValidations(tempValidations);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInputs]);

  useEffect(() => {
    const isValid = !Object.values(validations).includes(false);
    setIsFormValid(isValid);
    externalSetIsFormValid?.(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations]);

  function updateData(name: any, inputValue: any) {
    externalUpdateData?.(name, inputValue);
    setFormValues((prevValue) => {
      return {
        ...prevValue,
        [name]: inputValue,
      };
    });
  }

  const updateValidity = (name: any, validity: any) => {
    setValidations((prevValue: any) => {
      const copy: any = { ...prevValue };
      copy[name] = validity;

      return copy;
    });
  };

  function handleFormSubmitted(e: any) {
    e.preventDefault();
    if (login) {
      processInputs({ ...formValues, persist: checked });
    } else {
      processInputs(formValues);
    }
  }

  const submitButton: any = (
    <div className="submit-wrapper submit-wrapper-single">
      <Button
        loading={loadingState}
        disabled={!isFormValid}
        className="submit"
        type="submit"
        width={submitButtonWidth}
        secondary={submitButtonType === "secondary"}
      >
        {submitLabel || "Submit"}
      </Button>
    </div>
  );

  const biControlButton = (
    <div className="submit-wrapper">
      {backButton ? (
        backButton
      ) : (
        <Button
          width={submitButtonWidth}
          onClick={handleBack}
          secondary
          type="button"
        >
          Back
        </Button>
      )}

      <Button
        disabled={!isFormValid}
        className=""
        type="submit"
        loading={loadingState}
        secondary={submitButtonType === "secondary"}
      >
        {submitLabel || "Submit"}
      </Button>
    </div>
  );

  return (
    <GitFormWrapper login={login} removePadding={removePadding}>
      <form onSubmit={handleFormSubmitted}>
        {formInputs?.map((formInput: any, i: number) => {
          if (formInput.inputType === "selectInput") {
            return (
              <Select
                options={formInput.options}
                fullWidth={formInput.fullWidth}
                shortWidth={formInput.shortWidth}
                key={i}
                prompt={formInput.prompt}
                name={formInput.name}
                placeholder={formInput.placeholder}
                returnId={formInput.returnId}
                updateData={updateData}
                updateValidity={updateValidity}
                indexLevel={i}
                valueType={formInput.valueType}
                onEveryChange={formInput.onEveryChange}
                formValues={formValues}
                setFormValues={setFormValues}
                initialValue={formInput.initialValue}
                tooltip={formInput.tooltip}
              />
            );
          }

          if (formInput.inputType === "selectParent") {
            return (
              <SelectParent
                options={formInput.options}
                fullWidth={formInput.fullWidth}
                key={i}
                prompt={formInput.prompt}
                name={formInput.name}
                placeholder={formInput.placeholder}
                updateData={updateData}
                updateValidity={updateValidity}
                indexLevel={i}
                shortWidth={formInput.shortWidth}
                tooltip={formInput.tooltip}
                initialValue={formInput?.initialValue}
              />
            );
          }

          if (formInput.inputType === "selectChild") {
            return (
              <SelectChild
                options={formInput.options}
                fullWidth={formInput.fullWidth}
                key={i}
                prompt={formInput.prompt}
                name={formInput.name}
                placeholder={formInput.placeholder}
                updateData={updateData}
                updateValidity={updateValidity}
                indexLevel={i}
                parentName={formInput.parentName}
                formValues={formValues}
                shortWidth={formInput.shortWidth}
                tooltip={formInput.tooltip}
                initialValue={formInput?.initialValue}
              />
            );
          }

          if (formInput.inputType === "label") {
            return <H3 className="form-category">{formInput?.label}</H3>;
          }

          return (
            <Input
              login={login}
              fullWidth={formInput.fullWidth}
              shortWidth={formInput.shortWidth}
              key={i}
              inputType={formInput.inputType}
              prompt={formInput.prompt}
              name={formInput.name}
              rules={formInput.rules}
              buttonDisabled={false}
              options={formInput.options}
              formValues={formValues}
              value={formInput.initialValue}
              placeholder={formInput.placeholder}
              updateData={updateData}
              updateValidity={updateValidity}
              indexLevel={i}
              maxLength={formInput.maxLength}
              readOnly={formInput.readOnly}
              initialValue={formInput.initialValue}
              loadingState={formInput.loadingState}
              tooltip={formInput.tooltip}
            />
          );
        })}

        {login && (
          <div className="remember-wrapper">
            {!showForgotPassword ? (
              <div className="remember-me">
                <input
                  onChange={(e) => setChecked(e.target.checked)}
                  type="checkbox"
                  defaultChecked
                />
                <h3>Remember me</h3>
              </div>
            ) : (
              <div></div>
            )}

            <h4 onClick={handleForgotPassword} className="forgot-password">
              {showForgotPassword ? "Back to Login" : "Forgot password ?"}
            </h4>
          </div>
        )}
        {removeSubmitButton ? null : biControl ? biControlButton : submitButton}
      </form>
    </GitFormWrapper>
  );
}

export default Form;

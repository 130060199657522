import styled from "styled-components";
import { useState, useEffect } from "react";
import { useApiCallHandling } from "../../../../../hooks/useApiCallHandling";
import { Button } from "../../../../../common/elements";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { PromptModal } from "src/common/components/prompt/prompt-modal";

const AdminActionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  border-top: 1px solid ${({ theme }) => theme.border.light};
  padding: 1rem 2rem 2rem;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    gap: 1rem 0rem;
    padding: 2rem 0;
   
  }

  .mid-section {
    margin: 0 2rem;
    display: flex;
    justify-content: space-between;
  }

  .arrows {
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    gap: 4rem;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    justify-content: space-between;
    padding: 0;
   
  }

    .arrow-icon {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.text.prim_100};
      cursor: pointer;

      :hover {
        color: ${({ theme }) => theme.text.secondary};
      }
    }
  }

  .status-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    gap: 0.25rem;
    width: 100%;

    .status-key {
      color: ${({ theme }) => theme.text.prim_100};
      font-weight: 400;
      font-size: 0.95rem;
    }

    .status-value {
      font-size: 0.85rem;
      text-transform: uppercase;
      font-weight: 500;
    }
    .approved {
      color: ${({ theme }) => theme.text.life};
    }

    .declined {
      color: ${({ theme }) => theme.text.danger};
    }

    .pending {
      color: ${({ theme }) => theme.text.pending};
    }

    .queried {
      color: ${({ theme }) => theme.text.mild_danger};
    }
  }

  /* .comment-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex-grow: 1;
    padding-right: 0.875rem;

    label {
      font-size: 0.85rem;
      color: ${({ theme }) => theme.text.input};
      font-weight: 400;
    }

    textarea {
      width: 100%;
      resize: none;
      height: 10rem;
      border: 1px solid ${({ theme }) => theme.text.muted_100};
      outline: none;
      border-radius: 0.25rem;
      padding: 1rem;
    }
  } */

  .admin-controls {
    width: 100%;
    gap: 2rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      gap: 2rem 0.3rem;
      justify-content: space-between;
    }
  }
`;

const promptMessage = {
  decline: "Kindly enter the reason to decline this  registeration",
  approve: "Kindly confirm the approval of this registeration",
  query: "Kindly enter the reason to query this registeration",
  "": "",
};

export interface IAdminActionsProps {
  postUrl: string;
  initialStatus: string;
  showArrows?: boolean;
  onArrowRightClick?: () => void;
  onArrowLeftClick?: () => void;
}

type IDecision = "" | "decline" | "query" | "approve";

export function AdminActions({
  postUrl,
  initialStatus,
  showArrows,
  onArrowLeftClick,
  onArrowRightClick,
}: IAdminActionsProps) {
  const [status, setStatus] = useState<string>(initialStatus);
  const [promtLoading, setPromtLoading] = useState(false);
  const [decision, setDecision] = useState<IDecision>("");
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const apiCall = useApiCallHandling();

  function onResponseClicked(response: IDecision) {
    setDecision(response);
    setShowPrompt(true);
  }

  function handleMainAction(comment: string) {
    setPromtLoading(true);
    apiCall(
      postUrl,
      "POST",
      { operation: decision, remark: comment },
      (data) => {
        setShowPrompt(false);
        decision === "query" && setStatus("queried");
        decision === "approve" && setStatus("approved");
        decision === "decline" && setStatus("declined");
      },
      (error) => {},
      () => {
        setPromtLoading(false);
      }
    );
  }

  return (
    <AdminActionWrapper>
      <PromptModal
        prompt={promptMessage[decision]}
        onMainAction={handleMainAction}
        onSecondaryAction={() => setShowPrompt(false)}
        show={showPrompt}
        withInput={decision === "query" || decision === "decline"}
        close={() => setShowPrompt(false)}
        mainLoading={promtLoading}
      />

      <div className="status-info">
        <h2 className="status-key">Current Status:</h2>
        <h3 className={"status-value " + status}>{status}</h3>
      </div>

      {showArrows ? (
        <div className="arrows">
          <FiArrowLeftCircle
            onClick={onArrowLeftClick}
            className="arrow-icon"
          />
          <FiArrowRightCircle
            onClick={onArrowRightClick}
            className="arrow-icon"
          />
        </div>
      ) : null}

      <div className="admin-controls flex-row">
        {status === "pending" ||
        status === "queried" ||
        status === "declined" ? (
          <Button onClick={() => onResponseClicked("approve")}>Approve</Button>
        ) : null}

        {status === "pending" ? (
          <Button onClick={() => onResponseClicked("query")} secondary>
            Query
          </Button>
        ) : null}

        {status === "pending" ? (
          <Button
            onClick={() => onResponseClicked("decline")}
            secondary
            borderColor="danger"
            color="danger"
          >
            Decline
          </Button>
        ) : null}

        {status === "approved" ? (
          <Button onClick={() => onResponseClicked("decline")}>Revoke</Button>
        ) : null}
      </div>
    </AdminActionWrapper>
  );
}

import { KeyValueObj } from "src/common/types";
import { formatToNaira } from "src/common/utils/general";

export const invoiceTableHeaders = [
  "Invoice ID",
  "Invoice due Date",
  "Receiving Company",
  "Email",
  "Total Amount",
  "Amount Paid",
];

export const invoiceTableKeys = [
  "invoiceId",
  "dueDate",
  "receivingCompany",
  "email",
  "totalAmount",
  "amountPaid"
];

export function getInvoiceTableData(invoices: KeyValueObj[]){
  const invoiceTable = invoices?.map((invoice: KeyValueObj) => ({
    id: invoice?.id,
    invoiceId: {
      type: "string",
      value: invoice?.invoice_number
    },
    receivingCompany: {
      type: "string",
      value: invoice?.recipient?.name
    },
    email: {
      type: "string",
      value: invoice?.recipient?.email
    },
    amountPaid:{
      type: "string",
      value: formatToNaira(invoice?.amount_paid)
    },
    dueDate:{
      type: "string",
      value: invoice?.invoice_due_date
    },
    
    totalAmount: {
      type: "string",
      value: formatToNaira(invoice?.total_amount)
    },
  }))
  return invoiceTable
}

import styled from "styled-components";

const NotifWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: .5rem;
  background-color: ${({theme}) => theme.bg.prim_500};
  border-radius: 0.25rem;
  gap: 1rem;
  img {
    width: 2.5rem;
    border-radius: 0.25rem;
  }

  .texts{
    display: flex;
    flex-direction: column;
    gap: .4rem;
  }

  h3 {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${({theme}) => theme.text.prim_100}
  }

  h4 {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.text.muted_200};
  }
`;

export interface INotificationProps {
  imageUrl: string;
  title: string;
  date: string;
}

export function Notification({ imageUrl, title, date }: INotificationProps) {
  return (
    <NotifWrapper>
      <img src={imageUrl} alt="" />
      <div className="texts">
        <h3>{title}</h3>
        <h4>{date}</h4>
      </div>
    </NotifWrapper>
  );
}

import { ProductUploadWrapper } from "./styles";
//@ts-ignore
import drugsTemplate from "src/assets/excel/drugs.xlsx";
import { BsCloudDownload } from "react-icons/bs";
import { useState } from "react";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { PRODUCT_UPLOAD_URL } from "src/common/constants";
import { Button } from "src/common/components/button";
import { GoCheckCircle, GoQuestion } from "react-icons/go";

export interface IUploadProductProps {
  handleUploadSuccess: (data: any) => void;
  scrapDownloadLink: string;
  currentStage: string;
  successCount: number;
  failureCount: number;
}

export function UploadProduct({
  handleUploadSuccess,
  currentStage,
  successCount,
  failureCount,
  scrapDownloadLink,
}: IUploadProductProps) {
  const [excelFile, setExcelFile] = useState<any>();
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [responseMessages, setResponseMessage] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const makeRequest = useApiCallHandling();

  function handleExcelSelected(e: any) {
    clearFileInfo();
    const file = e.target.files[0];
    const name = file.name;
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext !== "xlsx" && ext !== "csv") {
      setErrorMessage("File must be either .xlsx or .csv format");
      return;
    }

    setExcelFile(file);
    setFileName(name);
  }

  function clearFileInfo() {
    setErrorMessage("");
    setFileName("");
    setExcelFile("");
  }

  function handleExcleUpload() {
    setResponseMessage([]);
    setLoading(true);
    const formData = new FormData();
    formData.append("products_file", excelFile);
    formData.append("product_type", "drug");

    makeRequest(
      PRODUCT_UPLOAD_URL,
      "POST",
      formData,
      handleUploadSuccess,
      (error: any) => console.log(error),
      () => setLoading(false),
      { dontNotifyOnSucess: true }
    );
  }

  return (
    <ProductUploadWrapper>
      {currentStage === "uploading" ? (
        <div className="general-page uploading-page">
          <div className="info">
            <h3>
              {`Download the excel document below to serve as a template for
          uploading the list of products`}
            </h3>
          </div>
          <div className="controls">
            <a
              href={drugsTemplate}
              download={`Medex Products template`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="download-button">
                <BsCloudDownload className="download-icon" /> Download Template
              </button>
            </a>
            <div className="file-input">
              <label>
                <input
                  className="custom-file-input"
                  onChange={handleExcelSelected}
                  type="file"
                />
                Select Excel File
              </label>
            </div>
            {fileName.length > 0 && <h2 className="file-name">{fileName}</h2>}
            {errorMessage.length > 0 && (
              <h2 className="error-message">{errorMessage}</h2>
            )}
          </div>

          <div className="response-messagees">
            {responseMessages?.map((message: string) => (
              <p>{message}</p>
            ))}
          </div>
          <div className="controls">
            <Button
              loading={loading}
              disabled={loading || !excelFile}
              onClick={handleExcleUpload}
            >
              Upload Excel Document
            </Button>
          </div>
        </div>
      ) : (
        <div className="general-page uploaded-page">
          <div className="metrics">
            <span className="metric">
              <GoCheckCircle className="check-icon" />{" "}
              <p className="text">Total Products uploaded successfully</p>
              <h3 className="metric-value">{successCount}</h3>
            </span>
            <span className="metric">
              <GoQuestion className="cancel-icon" />{" "}
              <p className="text">Total Products uploaded unsuccessfully</p>
              <h3 className="metric-value">{failureCount}</h3>
            </span>
          </div>
          {failureCount > 0 ? (
            <div className="download-options">
              <p className="text">
                Click the button below to download detailed information
              </p>
              <a
                href={scrapDownloadLink}
                download={`Error files template`}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="download-button">Download</Button>
              </a>
            </div>
          ) : null}
        </div>
      )}
    </ProductUploadWrapper>
  );
}

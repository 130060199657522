/* eslint-disable no-useless-escape */

export function isContainSpecialChar(value: string) {
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return format.test(value);
}

export function isContainUpper(value: string) {
  return /[A-Z]/.test(value);
}

export function isContainLower(value: string) {
  return /[a-z]/.test(value);
}

export function isNumber(value: string) {
  return /[0-9]/.test(value);
}

export function isOnlyDigits (value: string){
  return /^[0-9]+$/.test(value);
}

//from today onwards
export function isInTheFuture (value: string|Date){
  return new Date(value) > new Date();
}

export function beforeToday(value: string | Date){
  const date = new Date(value);
      date.setDate(date.getDate() + 1);
  return !isInTheFuture(date);
}

export function isBefore(value1:string|Date, value2:string|Date){
return new Date(value1) > new Date(value2)

}

import styled from 'styled-components';
import logoImage from '../../../assets/images/logo.png';

export const Image = styled.img`
  height: 2.2rem;
`

export interface ILogoProps {
}

export function Logo (props: ILogoProps) {
  return (
  <Image src={logoImage} alt="image logo" />
  );
}

import styled, { createGlobalStyle } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { useEffect, useRef } from "react";

export interface IModalProps {
  isOpen: boolean;
  close?: () => void;
  children?: JSX.Element;
  title?: string;
  subTitle?: string;
}

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.58);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7vh 0;
  z-index: 100;

  .overlay-click {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Content = styled.div`
  max-width: 95%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  opacity: 0;
  margin-top: 0.75rem;
  animation: fadeInModal forwards 0.4s 0.2s ease-in-out;

  @keyframes fadeInModal {
    to {
      margin-top: 0;
      opacity: 1;
    }
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  font-size: 1.3rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bg.prim_500};
  cursor: pointer;
  z-index: 10;
  padding: 0.5rem;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadow.shad_100};

  :hover {
    color: red;
  }
`;

const ChildrenWrapper = styled.div`
  max-width: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.bg.prim_400};
  border-radius: 4px;
  transition: all 0.5s ease-in;
  padding: 0;

  .text-wrapper {
    padding: 2rem 2rem 1rem;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.border.light};

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.text.primary};
      text-transform: capitalize;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 1.1rem;
      }
    }

    h3 {
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.input};
      margin-top: 1rem;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 0.875rem;
      }
    }
  }
`;

const ScrollDisabler = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

export function ModalContent({
  isOpen,
  close,
  children,
  title,
  subTitle,
}: IModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modalRef.current && modalRef.current.scrollTo(0, 0);
  }, [title]);

  if (!isOpen) return null;

  return (
    <>
      <Background>
        <div onClick={close} className="overlay-click"></div>
        <Content>
          {close && (
            <CloseWrapper>
              <AiOutlineClose onClick={close} />
            </CloseWrapper>
          )}

          <ChildrenWrapper ref={modalRef}>
            {(title || subTitle) && (
              <div className="text-wrapper">
                {title ? <h2>{title}</h2> : null}
                {subTitle ? <h3>{subTitle}</h3> : null}
              </div>
            )}
            {children}
          </ChildrenWrapper>
        </Content>
      </Background>
      <ScrollDisabler />
    </>
  );
}

import { KeyValueObj } from "src/common/types";

//DATA
export const retailerTableHeaders = [
  <div style={{ maxWidth: "8rem" }}>Date Created</div>,
  <div style={{ maxWidth: "8rem" }}>Expected Delivery Date</div>,
  <div style={{ maxWidth: "8rem" }}> Quotation Expiry Date</div>,
  "Delivery Address",
  "Delivery City",
  "Payment Term",
];

export const retailerTableKeys = [
  "created_at",
  "expected_delivery_date",
  "expiration_date",
  "shipping_address",
  "shipping_city",
  "paymentTerm",
];

export const distributorTableHeaders = [
  <div style={{ maxWidth: "8rem" }}>Date Created</div>,
  <div style={{ maxWidth: "6rem" }}>Expected Delivery Date</div>,
  <div style={{ maxWidth: "6rem" }}> Quotation Expiry Date</div>,
  "Client",
  "Delivery Address",
  "Delivery City",
  "Payment Term",
];

export const distributorTableKeys = [
  "created_at",
  "expected_delivery_date",
  "expiration_date",
  "client",
  "shipping_address",
  "shipping_city",
  "paymentTerm",
];

export const quotesTableHeaders = ["Company Name", "Payment Term", "Status"];

export const quotesTableKeys = [
  "companyName",
  "paymentTerm",
  "status",
  "action",
];

export function getQuotationTableData(
  quotations: KeyValueObj[] | undefined,
  dotClicked: (e: any, rowId: string) => void
) {
  const data = quotations?.map((quotation: KeyValueObj) => ({
    id: quotation?.id,
    companyName: { value: quotation?.distributor_info?.name, type: "string" },
    pricePerUnit: { value: quotation?.price_per_unit, type: "string" },
    paymentTerm: { value: quotation?.payment_term, type: "string" },
    status: {
      value: quotation?.status,
      type: "status",
      statusType: quotation?.status === "draft" ? "pending" : quotation?.status,
    },
  }));

  return data as KeyValueObj[];
}

export const declineTableHeaders = [
  "Company Name",
  "Reason for decline",
  "Phone Number",
  "Email",
];

export const declineTableKeys = [
  "companyName",
  "reasonForDecline",
  "phoneNumber",
  "email",
];

export function getDeclineTableData(declines: KeyValueObj[] | undefined) {
  return declines?.map((decline: KeyValueObj) => ({
    companyName: { type: "string", value: decline?.distributor?.name },
    reasonForDecline: { type: "string", value: decline?.reason_for_rejection },
    phoneNumber: {
      type: "string",
      value: decline?.distributor?.phone_number,
    },
    email: { type: "string", value: decline?.distributor?.email },
  })) as KeyValueObj[];
}

export const pendingTableHeaders = [
  "Company Name",
  "Phone Number",
  "Email",
  "State",
  "Lga",
];

export const pendingTableKeys = [
  "companyName",
  "phoneNumber",
  "email",
  "state",
  "lga",
];

export function getPendingTableData(pendings: KeyValueObj[] | undefined) {
  return pendings?.map((pending: KeyValueObj) => ({
    companyName: { type: "string", value: pending?.distributor?.name },
    phoneNumber: {
      type: "string",
      value: pending?.distributor.phone_number,
    },
    email: { type: "string", value: pending?.distributor?.email },
    state: { type: "string", value: pending?.distributor?.state },
    lga: { type: "string", value: pending?.distributor?.local_govt },
  })) as KeyValueObj[];
}

import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "../../../common/components/carousel";
import { KeyValueObj } from "../../../common/types";
import { Button } from "../../elements";
import { ClipSpinner } from "../spinner";

const ProductDetailsWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  flex-wrap: wrap;
  margin: auto;
  background-color: ${({theme}) => theme.bg.prim_500};
  border-radius: .25rem;

  @media screen and (max-width: ${({theme}) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .product-details {
    width: 100%;
    display: flex;

    @media screen and (max-width: ${({theme}) => theme.screen.mobile}) {
    flex-direction: column;
  }
  }

  .loading-text {
    color: ${({ theme }) => theme.bg.secondary};
    font-size: 1.2rem;
    font-weight: 400;
  }

  .carousel-wrapper {
    width: 50%;

    @media screen and (max-width: ${({theme}) => theme.screen.mobile}) {
    width: 100%;
  }
  }
  .details {
    width: 50%;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: ${({theme}) => theme.screen.mobile}) {
    width: 100%;
    padding: 0;
  }

    .top {
      margin-bottom: 1.5rem;

      .brand {
        font-size: 1.2rem;
        font-weight: 700;
        color: ${({ theme }) => theme.text.primary};
      }
      .name {
        color: ${({ theme }) => theme.text.prim_100};
        font-weight: 500;
        font-size: 1.1rem;
      }
    }

    .further-details {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .item {
      min-width: 45%;
      display: flex;
      flex-direction: column;
      gap: .4rem;

      .title {
        color: ${({ theme }) => theme.text.prim_100};
        font-weight: 400;
        font-size: .85rem;
      }

      .value {
        color: ${({ theme }) => theme.text.prim_100};
        font-weight: 600;
        font-size: .85rem;
        text-transform: capitalize;
      }
    }
  }

  .query-details {
    width: 100%;
    margin-top: 3rem;
    padding-left: 3rem;
    h2 {
      color: ${({ theme }) => theme.text.input};
      font-size: 1.1rem;
      font-weight: 500;
    }

    h3 {
      margin-top: 0.5rem;
      font-size: .85rem;
      font-weight: 400;
      color: ${({ theme }) => theme.text.muted_200};
    }
  }

  .controls {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;
    align-items: center;
    gap: 5rem;
    padding-right: 4rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      justify-content: space-between;
      padding: 0;
      padding-bottom: 2rem;
   
  }
  }
`;

export interface IProductDetailsProps {
  product: KeyValueObj;
  companyId?: string;
  handleEdit?: (product: any) => void;
  handleDelete?: (id?: string) => void;
  deleteLoading?: boolean;
  isAdmin?: boolean
}

export function ProductDetails({
  product,
  companyId,
  handleDelete,
  handleEdit,
  deleteLoading,
  isAdmin
}: IProductDetailsProps) {
  const isProductFetched = typeof product?.id === "string";
  const imageUrls = product?.images?.map((image: KeyValueObj) => ({
    type: "image",
    fileUrl: image.url,
  }));

  return (
    <ProductDetailsWrapper>
      {isProductFetched && (
        <>
          <div className="product-details">
            <div className="carousel-wrapper">
              <Carousel files={imageUrls} />
            </div>
            <div className="details">
              <div className="top">
                <h2 className="brand">{product?.brand_name}</h2>
                <h3 className="name">{product?.atc_code?.code_title}</h3>
              </div>
              <div className="further-details">
                <div className="item">
                  <h3 className="title">ATC code</h3>
                  <h3 className="value">{product?.atc_code?.readable_name}</h3>
                </div>
                <div className="item">
                  <h3 className="title">Strength</h3>
                  <h3 className="value">
                    {product.strength}
                    {product.strength_unit === "milligrams" ? "mg" : "g"}
                  </h3>
                </div>
                <div className="item">
                  <h3 className="title">Form</h3>
                  <h3 className="value">{product?.form}</h3>
                </div>
                <div className="item">
                  <h3 className="title">Classification</h3>
                  <h3 className="value">{product?.classification}</h3>
                </div>
                <div className="item">
                  <h3 className="title">Regulation</h3>
                  <h3 className="value">{product?.regulation}</h3>
                </div>
                <div className="item">
                  <h3 className="title">Primary Packaging</h3>
                  <h3 className="value">{product?.primary_packaging}</h3>
                </div>
                <div className="item">
                  <h3 className="title">Secondary Packaging</h3>
                  <h3 className="value">{product?.secondary_packaging}</h3>
                </div>
                <div className="item">
                  <h3 className="title">Tertiary Packaging</h3>
                  <h3 className="value">{product?.tertiary_packaging}</h3>
                </div>
                <div className="item">
                  <h3 className="title">Approval Status</h3>
                  <h3 className="value">{product?.approval_status}</h3>
                </div>
              </div>
            </div>
          </div>
          {product.approval_status === "queried" && (
            <div className="query-details">
              <h2>Query Remark:</h2>
              <h3>{product?.remark}</h3>
            </div>
          )}

          {product.owner_id === companyId && !isAdmin && (
            <div className="controls">
              <Button
                disabled={deleteLoading}
                onClick={() => handleDelete && handleDelete(product?.id)}
                secondary
              >
                {deleteLoading ? <ClipSpinner /> : "Delete"}
              </Button>
              <Button disabled={deleteLoading} onClick={() => handleEdit && handleEdit(product)}>
                Edit
              </Button>
            </div>
          )}
        </>
      )}
    </ProductDetailsWrapper>
  );
}

import { useEffect, useState, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../common/components/Table";
import {
  // DISTRIBUTORS_URL,
  // QUOTATION_URL,
  RFQ_URL,
} from "../../../common/constants";
import { Button } from "../../../common/elements";
import useAxiosCall from "../../../hooks/useAxiosCall";
import { RequisitionWrapper } from "./subs/style";

import { KeyValueObj } from "../../../common/types";
import useAuth, { useDashboardContext } from "../../../hooks/useContexts";
import {
  distributorTableHeaders,
  distributorTableKeys,
  retailerTableHeaders,
  retailerTableKeys,
} from "./subs/data";
import { useApiCallHandling } from "../../../hooks/useApiCallHandling";
import { useAuthDetails } from "../../../hooks/useAuthDetails";
import { Pagination } from "../../../common/components/pagination";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { getAddress } from "src/common/utils/general";

export interface IRequisitionProps {}

export function Rfq(props: IRequisitionProps) {
  //STATES
  const [tableData, setTableData] = useState<KeyValueObj[]>([]);
  const [retry, setRetry] = useState(0);
  const [tableHeaders, setTableHeaders] = useState<(string | JSX.Element)[]>(
    []
  );
  const [tableKeys, setTableKeys] = useState<string[]>([]);
  const [rfqs, setRfqs] = useState<KeyValueObj[]>([]);
  const [, setNoRfqMessageRetailer] = useState(
    "You have not placed any request for quotations yet, it will show here when you do."
  );
  const [, setNoRfqMessageDistributor] = useState(
    "There is no incoming request for quotations, it will show here when you have one."
  );


  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);

  //HOOKES
  const [fetchRfqs, fetchedRfqs, rfqsError, rfqsLoading, meta] = useAxiosCall();
  const apiCall = useApiCallHandling();
  const { auth } = useAuth();
  let { companyType } = useAuthDetails();
  let navigate = useNavigate();

  const { setOnGlobalSearch, setSearching } = useDashboardContext();

  //Variables
  let companyId = auth.user.company.id;

  const onProductSearch = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearching(true);
    apiCall(
      RFQ_URL + `?search_term=${value}&page=${page}&per_page=${perPage}`,
      "GET",
      {},
      (data, metaData) => {
        setRfqs(data);
        typeof metaData.total_pages === "number" &&
          setPageCount(metaData.total_pages);
        if (data.length === 0)
          setNoRfqMessageDistributor(
            "The search term you entered returned no data!"
          );
        setNoRfqMessageRetailer(
          "The search term you entered returned no data!"
        );
      },
      (err) => {
        console.log(err);
      },
      () => {
        setSearching(false);
      },
      { dontNotifyOnSucess: true, dontNotifyonFail: true }
    );
  };

  //USEEFFECTS
  useEffect(() => {
    //this is where the function that runs on search is asigned
    setOnGlobalSearch(() => onProductSearch);

    return () => {
      setOnGlobalSearch(() => () => {});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOnGlobalSearch]);

  useEffect(() => {
    fetchedRfqs && setRfqs(fetchedRfqs);
    typeof meta.total_pages === "number" && setPageCount(meta.total_pages);
  }, [fetchedRfqs, meta.total_pages]);

  useEffect(() => {
    fetchRfqs({
      method: "GET",
      url: RFQ_URL + `?page=${page}&per_page=${perPage}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, retry, page, perPage]);

  //Handles when requisitions are fetched
  useEffect(() => {
    const tempTableData: KeyValueObj[] = [];
 

    rfqs?.forEach((rfq: KeyValueObj) => {
      // const product = rfq?.product;
      // const strength =
      //   rfq.product_strength + rfq.product_strength_unit === "milligrams"
      //     ? "g"
      //     : "mg";
      const distributor = rfq?.rfq_responses?.filter(
        (rfqRes: any) => rfqRes.distributor_id === companyId
      )[0];
      let status: string;
      if (distributor) {
        status = distributor.rfq_status;
      } else {
        status = "pending";
      }

      if (
        companyType?.toLowerCase() === "retailer" ||
        companyType?.toLowerCase() === "distributor"
      ) {
        tempTableData.push({
          id: rfq.id,
          expected_delivery_date: {
            type: "string",
            value: rfq?.expected_delivery_date,
          },
          expiration_date: {
            type: "string",
            value: rfq?.expiration_date,
          },
          shipping_address: {
            type: "string",
            value: getAddress(rfq?.address),
          },
          shipping_city: {
            type: "string",
            value: rfq.address?.city,
          },

          created_at: {
            type: "string",
            value: rfq.created_at?.substring(0, 10),
          },

          paymentTerm: {
            type: "string",
            value: rfq.payment_term,
          },
          status: {
            type: "status",
            statusType: status,
            value: status === "accepted" ? "Sent" : status,
          },

          retailerStatus: {
            type: "status",
            statusType: rfq.status === "responded_to" ? "accepted" : rfq.status,
            value: rfq.status === "responded_to" ? "Responded" : rfq.status,
          },
          client: {
            type: "string",
            value: rfq.owner.name,
          },
          // action: {
          //   type: "button",
          //   buttonType: "secondary",
          //   buttonName: "View",
          //   onClick: () => onTRClicked(rfq.id),
          // },
        });
      }
    });

    setTableData(tempTableData);
    if (companyType?.toLowerCase() === "retailer") {
      setTableHeaders(retailerTableHeaders);
      setTableKeys(retailerTableKeys);
    } else if (companyType?.toLowerCase() === "distributor") {
      setTableHeaders(distributorTableHeaders);
      setTableKeys(distributorTableKeys);
    }
  }, [companyId, rfqs, rfqsError, companyType]);

  function handleView(row: KeyValueObj) {
    navigate(`${companyType?.toLowerCase()}/view-rfq/${row?.id}`);
  }

  //PAGINATION FUNCTIONS
  function handleNextPage(event: { selected: number }) {
    setPage(event.selected + 1);
  }

  function handlePerPageChange(event: FormEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    value.length > 0 && setPerPage(+value);
  }

  return (
    <RequisitionWrapper>
      <PageTitleNavs
        title="Requests for Quotation"
        backLink="#"
        bcrumbs={[{ title: "RFQs", link: "#" }]}
      />
      {companyType?.toLowerCase() === "retailer" && (
        <div className="button-wrapper">
          <Button>
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to="/dashboard/rfq/make-rfq"
            >
              New RFQ
            </Link>
          </Button>
        </div>
      )}

      <div className="requisitions">
        <>
          <Table
            tableKeys={tableKeys}
            tableHeaders={tableHeaders}
            tableData={tableData}
            full
            tableTitle="RFQs"
            tableSubtitle="My Quotation Requests"
            loading={rfqsLoading}
            dropDownOptions={[
              {
                label: "View",
                action: handleView,
              },
            ]}
            showDropDown={true}
            rowLineColor="light"
            errorLoading={rfqsError?.message?.length > 0}
            onRetry={() => setRetry((prev) => prev + 1)}
          />
        </>
        <Pagination
          pageCount={pageCount}
          onNextPage={handleNextPage}
          onPerPageChange={handlePerPageChange}
        />
      </div>
    </RequisitionWrapper>
  );
}

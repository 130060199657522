import styled from "styled-components";
import { KeyValueObj } from "../../types";
import { ClipSpinner } from "../spinner";
import { Button } from "../../elements";
import { useEffect, useState } from "react";
import { Carousel } from "../carousel";

const PreviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg.prim_500};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  max-width: 100%;


 

  .preview-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .carousel-wrapper {
    width: 100%;
    margin-bottom: 2rem;
  }

  .category-out-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0 1rem;
  }

  .category-title {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.text.input};
    text-transform: capitalize;
  }

  .sub-items-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 1rem;
  }

  .items-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;

    .item {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.5rem;
      padding-left: 1rem;
      text-transform: capitalize;

      .title {
        width: 100%;
        font-weight: 500;
        font-size: 0.85rem;
        color: ${({ theme }) => theme.text.prim_100};
      }

      .value {
        font-weight: 600;
        width: 100%;
        font-size: 0.85rem;
        line-height: 1.1875rem;
        color: ${({ theme }) => theme.text.primary};
        word-wrap: break-word;
      }

      img {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  .file-wrapper {
    width: 100%;
    display: flex;
    align-items: space-between;
    height: 100%;
    gap: 2rem;
    flex-wrap: no-wrap;

    .file-item {
      width: 75%;
      flex-shrink: 0;
      margin: 1rem;
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: 1rem;
        width: 100%;
        font-weight: 400;
        font-size: 1.4rem;
        text-transform: capitalize;
        color: ${({ theme }) => theme.text.muted_200};
      }
    }
  }

  .bicontrol-wrapper {
    width: 100%;
    justify-content: space-around;
    padding-bottom: 3rem;
  }
`;

export interface IPreviewProps {
  data: KeyValueObj[];
  submitLabel?: string;
  title?: string;
  biControl?: boolean;
  loading?: boolean;
  onCancel?: () => void;
  onSubmit: () => void;
  onBack?: () => void;
}

export function Preview({
  data,
  biControl,
  submitLabel,
  loading,
  onBack,
  onSubmit,
}: IPreviewProps) {
  const [innerData, setInnerData] = useState<any>();

  useEffect(() => {
    setInnerData(data);
  }, [data]);

  return (
    <PreviewWrapper>
      <PreviewInner data={innerData} />
      {biControl ? (
        <div className="bicontrol-wrapper flex-row btn-submit">
          <Button secondary onClick={onBack}>
            Back
          </Button>
          <Button disabled={loading} onClick={onSubmit}>
            {loading ? <ClipSpinner /> : submitLabel ? submitLabel : "Submit"}
          </Button>
        </div>
      ) : (
        <div className="bicontrol-wrapper flex-row btn-submit">
          <Button disabled={loading} onClick={onSubmit}>
            {loading ? <ClipSpinner /> : submitLabel ? submitLabel : "Submit"}
          </Button>
        </div>
      )}
    </PreviewWrapper>
  );
}

interface InnerProps {
  data: KeyValueObj[];
}

interface IFile {
  type: string;
  fileUrl: string;
}

function PreviewInner({ data }: InnerProps) {
  const [files, setFiles] = useState<IFile[]>([]);
  useEffect(() => {
    let files: IFile[] = [];
    data?.forEach((datum: any) => {
      if (datum?.value instanceof File) {
        const [fileType, fileExt] = datum?.value?.type?.split("/");

        if (fileType === "image") {
          files.push({
            type: "image",
            fileUrl: URL.createObjectURL(datum?.value),
          });
        } else if (fileExt === "pdf") {
          files.push({
            type: "pdf",
            fileUrl: URL.createObjectURL(datum?.value),
          });
        }
        //this is neccessary since we have to return something
        return null;
      } else if (datum?.type === "imageUrl") {
        files.push({
          type: "image",
          fileUrl: datum?.value,
        });
      }
    });

    setFiles(files);
  }, [data]);

  const images: any = [];
  const pdfs: any = [];
  const imageUrls: KeyValueObj[] = [];


  return (
    <div className="preview-inner">
      <div className="carousel-wrapper">
        <Carousel files={files} />
      </div>

      <div className="items-wrapper">
        {data &&
          data.map((datum: KeyValueObj, i: number) => {
            //Check for files and separate them into different lists
            if (datum.value instanceof File) {
              const [fileType, fileExt] = datum.value.type.split("/");

              if (fileType === "image") {
                images.push(datum);
              } else if (fileExt === "pdf") {
                pdfs.push(datum);
              }
              //this is neccessary since we have to return something
              return null;
            } else if (datum.type === "imageUrl") {
              imageUrls.push(datum);

              //neccessary since we have to return something
              return null;
            } else if (datum.type === "category") {
              const title = datum.name;
              const subs = datum.value;
              return (
                <div className="category-out-wrapper" key={i}>
                  <h2 className="category-title">{title}</h2>
                  <div className="sub-items-wrapper">
                    <PreviewInner data={subs} />
                  </div>
                </div>
              );
            } else {
              return (
                <div className="item" key={i}>
                  <h3 className="title">{datum.name.split("_").join(" ")}:</h3>
                  <h4 className="value">{datum.value}</h4>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}

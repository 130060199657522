import styled from "styled-components";
import { PageContainr } from "../../common/elements/containers";
import signUpBg from "../../assets/images/onboard4.webp";
// import { userRegDataContext } from "src/context/RegData";
// Styled components
export const FormContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 15rem;
`;

export const FormField = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  /* font-weight: bold; */
`;

export const Input = styled.input`
  width: 100%;
  /* max-width: 50px; */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 45px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 45px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0144px;
  background: ${({ theme }) => theme.bg.prim_500};
  border: 1px solid ${({ theme }) => theme.border.input};
  border-radius: 3.2px;
  height: 48px;
  width: 100%;
  cursor: pointer;

  img {
    width: 20px;
    margin-right: 18px;
  }
`;

export const NewRegWrapper = styled(PageContainr)`
  z-index: 4;
  align-items: flex-start;

  .container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding: 32px;
    gap: 32px;

    @media screen and (max-width: 850px) {
      flex-direction: column;
    }

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 0;
    }
  }

  .info-sec {
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    background-image: url(${signUpBg});
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 36px;
    padding: 1.5rem;

    .text-wrapper {
      background-color: rgba(69, 0, 1, 0.6);
      width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }

    h2 {
      font-size: 28.8px;
      color: ${({ theme }) => theme.text.prim_500};
      font-family: "Vidaloka", serif;
      z-index: 2;
    }

    p {
      font-size: 14.4px;
      color: ${({ theme }) => theme.text.prim_500};
      text-align: left;
      line-height: 1.5rem;
      z-index: 2;
    }

    .dots-image {
      position: absolute;
      left: 0;
      top: 115%;
    }
  }

  .input-sec {
    width: 65%;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    padding: 1.5rem 1rem;
    min-height: 100vh;

    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }

  .input_container {
    margin-bottom: 2rem;
  }

  .heading {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 1rem;
  }

  .footer {
    display: flex;
    justify-content: center;
  }

  .sub-heading {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: auto;
    align-items: center;
  }

  .stepcounter {
    border-radius: 20px 20px 20px 20px;
    background: #575757;
    width: 7rem;
    height: 2rem;
    padding: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }

  .stepcountTitle {
    font-weight: 600;
    font-size: 20px;
  }

  .nav_buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    bottom: 10px;
    padding: 3rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 3rem 0.5rem;
    }
  }

  .back-arrow {
    :hover {
      cursor: pointer;
    }
  }

  /* slidder styles   */

  .top {
    width: 100%;
    .welcome-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .sign-up {
        font-weight: 700;
        font-size: 48px;
        letter-spacing: 0.0144px;
        color: ${({ theme }) => theme.text.primary};

        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          font-size: 35.2px;
        }
      }
    }
  }

  .form {
    width: 100%;
    margin-top: 64px;
  }

  .phone {
    display: flex;
    flex-direction: column;
  }

  .controls {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2.25rem;
    gap: 2.25rem;

    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 1.44px;
        color: ${({ theme }) => theme.text.input};
      }
    }

    .or:before,
    .or:after {
      content: "";
      width: 45%;
      height: 0.0313rem;
      position: absolute;
      background-color: ${({ theme }) => theme.border.input};
      top: 55%;
      right: 0;
      z-index: -1;
    }

    .or:after {
      left: 0;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.0144px;
      background: ${({ theme }) => theme.bg.prim_500};
      border: 1px solid ${({ theme }) => theme.border.input};
      border-radius: 3.2px;
      height: 48px;
      width: 100%;
      cursor: pointer;

      img {
        width: 20px;
        margin-right: 18px;
      }
    }
  }
`;

// Slidder constainer style
export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const SlidderWrapper = styled.div<{ currentStep: number }>`
  display: flex;
  transition: transform 0.4s ease-in-out;
  transform: ${({ currentStep }) => `translateX(-${(currentStep - 1) * 100}%)`};
  width: 100%; /* Adjust for number of steps */
  height: max-content;
`;

export const Slide = styled.div`
  width: 100%;
  min-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: max-content;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 0;
  }
`;

// End slidder styles

export const Text = styled.h2`
  padding: 0 32px;
  font-weight: 400;
  font-size: 22.4px;
  color: ${({ theme }) => theme.text.input};
  text-align: justify;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export const MessageWrapper = styled.div`
  width: 640px;
  padding: 48px 0 80px;
  margin: 0;
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-top: 40px;
  /* font-size: 14px; */
`;

export const TinDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 35px;

  .Tin_heading {
    color: #34c759;
    font-size: 20px;
    font-style: bold;
    font-family: Montserrat;
    margin-top: 20px;
  }
  .Tin_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #727272;
    width: 100%;
    padding-top: 30px;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .Sub_title {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 6px;
  }
  .content {
    width: 16rem;
  }

  .tin_address {
    width: 15rem;
    height: 5rem;
    padding: 5px;
    color: #727272;
    font-size: 17px;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 100%;
    }
  }

  .date-wrapper {
    width: 16rem;
    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 100%;
    }
  }

  .date-input {
    width: 100%;
    padding: 0.35rem 0.5rem;
  }

  .redirect-modal {
    max-width: 15rem;
  }
`;

export const VerificationFailed = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  align-items: center;

  .icon {
    color: red;
    margin-bottom: 20px;
    align-items: center;
  }
`;

export const RadioInput = styled.div`
  .radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }
  .radio input {
    cursor: pointer;
  }

  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    width: 20px;
    height: 20px;
    border: 2px solid #999;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }

  /* Selected radio button style */
  input[type="radio"]:checked {
    /* border-color: #020c26; */
    background-color: #020c26;
  }

  /* The dot inside the radio button when checked */
  input[type="radio"]:checked::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: 4px;
    left: 4px;
  }

  /* Focus style */
  input[type="radio"]:focus {
    /* outline: 2px solid #020c26; */
    /* outline-offset: 2px; */
  }
`;

export const DatePickerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* background-color: slategray; */
  .Date {
    width: 100%;
    /* height: 20px; */
  }

  .DateStyle {
    margin-top: 30px;
  }
`;

export const Fileupload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #575757;
  width: 100%;
  margin-bottom: 20px;

  .fileupload {
    display: flex;
    flex-direction: row;
    height: 15px;
    background-color: #eeeeee;
    font-size: 12px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
    width: 100%;
    height: 50px;
  }
`;

export const DateSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;

  .input {
    padding: 10px;
    width: 100%;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
    height: 50px;
  }

  &:focus {
    border-color: #007bff; /* Change border color when focused */
  }

  &:hover {
    border-color: #0056b3; /* Change border color when hovered */
  }

  /* Customize the date input appearance */
  &::-webkit-calendar-picker-indicator {
    filter: invert(50%);
  }
`;

export const RedirectModalWrapper = styled.div`
  width: 30rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 3rem;
  background-color: ${({ theme }) => theme.bg.prim_500};

  p {
    width: 100%;
    text-align: justify;
    font-size: 0.95rem;
  }
`;

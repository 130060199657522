
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="left" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9",
  },
}));

interface ITooltip {
  children: JSX.Element;
  title: string;
}

export default function CustomTooltip({ children, title }: ITooltip) {
  return title ? (
    <HtmlTooltip
      title={
        <>
          <Typography style={{padding: ".2rem .5rem", fontSize: ".85rem"}} color="inherit">{title}</Typography>
        </>
      }
    >
      {children}
    </HtmlTooltip>
  ) : (
    <>{children}</>
  );
}

import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  h2 {
    color: ${({ theme }) => theme.text.prim_500};
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    font-feature-settings: "liga" off;
    margin-bottom: 1.2rem;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.75rem;

    li{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .link {
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: .85rem;
      line-height: 1.5rem;
      font-feature-settings: "liga" off;
      color: ${({ theme }) => theme.text.prim_500};
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .75rem;
    }

    span {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-family: 'Nanum Pen Script', cursive;
      color: #2ec5ce;
      margin-left: .5rem;
    }
  }
`;

export interface IFooterGroupProps {
  title: string;
  items: {
    text: string;
    more?: string;
    link: string;
    icon?: string;
  }[];
}

export function FooterGroup({ title, items }: IFooterGroupProps) {
  return (
    <Wrapper>
      <h2>{title}</h2>
      <ul>
        {items &&
          items.map((item, i) => (
            <li key={i}>
              <Link className="link" to={item.link}>
               {item.icon && <img src={item.icon} alt="itmelogo" />} {item.text}
              </Link>
              {item.more && <span>{item.more}</span>}
            </li>
          ))}
      </ul>
    </Wrapper>
  );
}

export const resetForm = [
  {
    inputType: "password",
    prompt: "Password",
    name: "password",
    placeholder: "Enter password",
    fullWidth: true,
    rules: {
      containSpecialChar: {
        expectedValue: true,
        errorMessage: "Include a special character",
      },
      minLength: {
        expectedValue: 6,
        errorMessage: "Should be atleast 6 characters",
      },
      containUppercase: {
        expectedValue: true,
        errorMessage: "Include uppercase letter",
      },
      containLowercase: {
        expectedValue: true,
        errorMessage: "Include lowercase letter",
      },
    },
  },
  {
    inputType: "password",
    prompt: "Confirm",
    name: "repeatPassword",
    placeholder: "Confirm password",
    fullWidth: true,
    rules: {
      compareWithExisting: {
        expectedValue: "password",
        errorMessage: "Does not match the password",
      },
    },
  },
]
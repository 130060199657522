import { ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
// import { ScrollDisabler } from "../scrollDisabler";

export interface ILightBackdropProps {
  children: ReactNode;
  onBackdropClick: () => void;
}

const LBWrapper = styled.div`
  position: fixed;
  z-index: 200;
  .main-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
`;

const backdropRoot = document.getElementById("backdrop") as HTMLElement;

export function LightBackdrop({
  children,
  onBackdropClick,
}: ILightBackdropProps) {
  return ReactDOM.createPortal(
    <LBWrapper>
      <div onClick={onBackdropClick} className="main-backdrop"></div>
      {children}
      {/* <ScrollDisabler /> */}
    </LBWrapper>,
    backdropRoot
  );
}

interface IBWrapperProps{
  zIndex?: string;
  dark?: boolean;
}

const BackdropWrapper = styled.div<IBWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({zIndex}) => zIndex || "200"};
  background-color: ${({dark}) => dark ? "rbg(0, 0, 0, .8)": "transparent"};

`

export interface IBackdropProps {
  backdropClicked: () => void;
  dark?: boolean;
  zIndex?: string;
}

export function Backdrop ({
  backdropClicked,
  dark,
  zIndex
}: IBackdropProps) {
  return (
    <BackdropWrapper zIndex={zIndex} dark={dark} onClick={backdropClicked} />
  );
}


import { COMPANY_ADDRESSES_URL } from "src/common/constants";
import { KeyValueObj } from "src/common/types";
import { useAuthDetails } from "src/hooks/useAuthDetails";
import useQuery from "src/hooks/useQuery";
import styled from "styled-components";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Address, AddressSkeleton } from "./address";

import { useCallback } from "react";
import { Button } from "../button";
import { IoIosAdd } from "react-icons/io";
import { Modal } from "../modal";
import Form from "../form/Form";
import { getDeliveryAddressFormInputs } from "./data";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";

const AddressWrapper = styled.div`
  width: 100%;

  .add-wrapper {
    margin-top: 2rem;
  }

  .add-icon {
    font-size: 1.5rem;
  }

  .no-address {
    width: 100%;
    text-align: center;
    background-color: ${({ theme }) => theme.bg.primary}07;
    padding: 1.5rem;
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.text.secondary};
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

export interface IAddreessManagerProps {
  selectedAddress: KeyValueObj;
  setSelectedAddress: Dispatch<SetStateAction<KeyValueObj>>;
}

export function AddressManager({
  selectedAddress,
  setSelectedAddress,
}: IAddreessManagerProps) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [editingAddress, setEditingAddress] = useState<KeyValueObj>({});
  const [editedOrCreated, setEditedOrCreated] = useState(false);

  const { companyId } = useAuthDetails();
  const {
    data: addresses,
    loading: addressesLoading,
    error: addressesError,
    refetch: refetchAddress,
  } = useQuery(COMPANY_ADDRESSES_URL + companyId);
  const makeRequest = useApiCallHandling();

  const handleEditAddress = useCallback((address: KeyValueObj) => {
    setEditingAddress(address);
    setShowEditModal(true);
  }, []);

  useEffect(() => {
    if (addresses.length > 0 && !editedOrCreated) {
      setSelectedAddress(addresses?.[0]);
    }

    // eslint-disable-next-line
  }, [addresses]);

  const handleDeleteAddress = useCallback(
    (id: string) => {
      setDeletingId(id);
      makeRequest(
        COMPANY_ADDRESSES_URL + companyId + "?address_ids=" + id,
        "DELETE",
        { address_ids: [id] },
        (data) => {
          refetchAddress(COMPANY_ADDRESSES_URL + companyId);
          if (selectedAddress.id === id) setSelectedAddress({});
        },
        console.log,
        () => setDeletingId("")
      );
    },
    // eslint-disable-next-line
    [companyId]
  );

  const handleAddressSubmit = useCallback(
    (formValues: KeyValueObj) => {
      setFormLoading(true);
      makeRequest(
        COMPANY_ADDRESSES_URL + companyId,
        "POST",
        formValues,
        (data) => {
          setEditedOrCreated(true);
          setSelectedAddress(data);
          setShowAddModal(false);
          refetchAddress(COMPANY_ADDRESSES_URL + companyId);
        },
        console.log,
        () => setFormLoading(false)
      );
    },
    // eslint-disable-next-line
    [companyId]
  );

  const handleAddressEdit = useCallback(
    (formValues: KeyValueObj) => {
      setFormLoading(true);
      makeRequest(
        COMPANY_ADDRESSES_URL + companyId + "?address_id=" + editingAddress?.id,
        "PUT",
        formValues,
        (data) => {
          setEditedOrCreated(true);
          setSelectedAddress(data);
          setShowAddModal(false);
          refetchAddress(COMPANY_ADDRESSES_URL + companyId);
        },
        console.log,
        () => setFormLoading(false)
      );
    },

    // eslint-disable-next-line
    [editingAddress, companyId]
  );
  if (addressesLoading)
    return (
      <AddressWrapper>
        <AddressSkeleton />
        <AddressSkeleton />
        <AddressSkeleton />
      </AddressWrapper>
    );

  return (
    <AddressWrapper>
      <Modal
        title="New Delivery Address"
        subTitle="Enter new delivery address details"
        isOpen={showAddModal}
        close={() => setShowAddModal(false)}
      >
        <Form
          formInputs={getDeliveryAddressFormInputs()}
          processInputs={handleAddressSubmit}
          loadingState={formLoading}
        />
      </Modal>

      <Modal
        title="Edit Delivery Address"
        subTitle="Edit delivery address details"
        isOpen={showEditModal}
        close={() => setShowEditModal(false)}
      >
        <Form
          formInputs={getDeliveryAddressFormInputs(editingAddress)}
          processInputs={handleAddressEdit}
          loadingState={formLoading}
        />
      </Modal>
      <div className="addresses">
        {addresses.length === 0 && addressesError.length < 1 ? (
          <p className="no-address">
            No address saved at the moment, click to add delivery address
          </p>
        ) : null}

        {addresses?.map((address: KeyValueObj) => (
          <div className="address">
            <Address
              address={address}
              handleAddressSelected={(chosenAddress: KeyValueObj) =>
                setSelectedAddress(chosenAddress)
              }
              selectedAddress={selectedAddress}
              handleDeleteAddress={handleDeleteAddress}
              handleEditAddress={handleEditAddress}
              deletingId={deletingId}
            />
          </div>
        ))}
      </div>
      <div className="add-wrapper">
        <Button secondary onClick={() => setShowAddModal(true)}>
          <IoIosAdd className="add-icon" /> Add Address
        </Button>
      </div>
    </AddressWrapper>
  );
}

import styled from "styled-components";

export const RequestsWrapper = styled.div`
  padding: 2rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .table-wrapper {
    padding: 2rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    margin-top: 0rem;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 2rem 0.2rem;
    }
  }

  .requests {
  }
`;

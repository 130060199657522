import {
  IAddedBatch,
  IBatchData,
  IProductBatches,
  SelectOptions,
} from "src/common/types/others";

export function getTableKeys(companyType: string) {
  if (companyType === "retailer" || companyType === "distributor") {
    return [
      "date",
      "deliveryAddress",
      "paymentTerm",
      "client",
      "callbackStatus",
      "status",
      "action",
    ];
  }

  return [ 
    "date",
    "deliveryAddress",
    "paymentTerm",
    "client",
    "status",
    "action",
  ];
}

export function getTableHeaders(orderType: string, companyType: string) {
  if (companyType === "retailer" || companyType === "distributor") {
    return [
      "Delivery Date",
      "Delivery Address",
      "Payment Term",
      orderType === "sent" ? "Supplier" : "Client",
      <div style={{ maxWidth: "9rem" }}>
        External Inventory Callback Status
      </div>,
      "Status",
      "Action",
    ];
  }

  return [
    "Delivery Date",
    "Delivery Address",
    "Payment Term",
    orderType === "sent" ? "Supplier" : "Client",
    "Status",
    "Action",
  ];
}

export const filterOptions = [
  {
    name: "expected_delivery_date",
    label: "Expected Delivery Date",
    type: "date",
  },
  {
    name: "brand",
    label: "Brand",
    type: "text",
  },
  {
    name: "atc_code",
    label: "ATC Code",
    type: "text",
  },
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "supplier",
    label: "Supplier",
    type: "text",
  },
  {
    name: "delivery_status",
    label: "Delivery Status",
    type: "radio",
    options: [
      { label: "Accepted", value: "accepted" },
      { label: "Rejected", value: "rejected" },
      { label: "Pending", value: "pending" },
      { label: "Shipped", value: "shipped" },
      { label: "Received", value: "received" },
    ],
  },
];

export const batchTableHeaders = [
  "S/N",
  "Brand",
  "Weight",
  "Batch Selected ?",
  "Action",
];

export const batchTableKeys = [
  "s_n",
  "brand",
  "weight",
  "isBatchSelected",
  "action",
];

export function getBatchTableData(
  data: any,
  handleButtonClick: (productId: string) => void,
  productBatches: IProductBatches
) {
  return data?.map((datum: any, i: number) => ({
    id: datum?.[0]?.product_id,
    s_n: {
      type: "string",
      value: i + 1,
    },
    brand: {
      type: "string",
      value: datum?.[0]?.brand_name,
    },
    weight: {
      type: "string",
      value: datum?.[0]?.strength + datum?.[0]?.strength_unit,
    },

    isBatchSelected: {
      type: "string",
      value: Object.keys(productBatches).includes(datum?.[0]?.atc_code_id)
        ? "Yes"
        : "No", //check that product id exists in the list of batches already selected
    },
    action: {
      type: "button",
      buttonType: "regular",
      buttonName: "Select Batches",
      onClick: () => handleButtonClick(datum?.[0]?.product_id),
    },
  }));
}

export const batchData = [
  [
    {
      product_id: "1847bd40-5a6d-4782-9024-4a4094a18c90",
      batch_number: "adfadf4qr44",
      quantity_in_stock: 750,
      brand_name: "Doninin",
      strength: 400,
      strength_unit: "milligrams",
      id: null,
    },
  ],
  [
    {
      product_id: "ac9ca60d-74a0-4325-b3b2-392dcbbd85af",
      batch_number: "8900987",
      quantity_in_stock: 92,
      brand_name: "Emzor",
      strength: 500,
      strength_unit: "milligrams",
      id: null,
    },
    {
      product_id: "ac9ca60d-74a0-4325-b3b2-392dcbbd85af",
      batch_number: "58585",
      quantity_in_stock: 800,
      brand_name: "Emzor",
      strength: 500,
      strength_unit: "milligrams",
      id: null,
    },
  ],
];

export const getBatchesbatchFormInputs = (
  batchesData: IBatchData[],
  maxQuantity: number,
  handleSelectChange: (
    id: string,
    name: string,
    updateValidity: any,
    setFormValues: any,
    formValues: any
  ) => void
) => {
  const batchOptions: SelectOptions[] = batchesData?.map(
    (batch: IBatchData) => ({
      id: batch?.batch_number,
      title: `${batch?.batch_number} (Stock Quantity: ${batch?.quantity_in_stock}, Expiry Date: ${batch?.expiry_date})`,
    })
  );
  return [
    {
      inputType: "selectInput",
      prompt: "Select Batch",
      name: "batch",
      options_name: "batche",
      placeholder: "Select a batch",
      fullWidth: false,
      options: batchOptions,
      onEveryChange: handleSelectChange,
    },
    {
      inputType: "number",
      prompt: "Enter Quantity",
      name: "quantity",
      options_name: "batche",
      placeholder: "Eg: 300",
      fullWidth: false,
      options: batchOptions,
      rules: {
        maxNumber: {
          expectedValue: maxQuantity,
          errorMessage: `Size should not exceed quantity in stock - ${maxQuantity}`,
        },
        aboveZero: {
          errorMessage: "Quantity should be greater than zero",
        },
      },
    },
  ];
};

export const getAddedBatchTableData = (
  addedBatches: IAddedBatch[],
  handleRemove: (batchNo: string) => void
) => {
  return addedBatches.map((addedBatch: IAddedBatch, i: number) => ({
    id: addedBatch.batch_number,
    batchNo: {
      type: "string",
      value: addedBatch.batch_number,
    },
    quantity: {
      type: "string",
      value: addedBatch.quantity,
    },
    action: {
      type: "button",
      buttonType: "danger",
      buttonName: "Remove",
      onClick: () => handleRemove(addedBatch.batch_number),
    },
  }));
};

export const AddedBatchTableHeader = ["Batch Number", "Quantity", "Action"];
export const addedBatchesTableKeys = ["batchNo", "quantity", "action"];

import {
  AbstractCircle,
  AbstractCircleBottom,
  ContentWrapper,
  Description,
  Header,
  PortfolioContainer,
} from "./styles";

export default function Portfolio() {
  return (
    <>
      <PortfolioContainer>
        {/* Abstract Background Shapes */}
        <AbstractCircle />
        <AbstractCircleBottom />

        {/* Content */}
        <ContentWrapper>
          <Header>Our Portfolio</Header>
          <Description>
            <p>
              {" "}
              MedEX is currently integrated with the Health-in-a-box Hospital
              Management Information System (HMIS) currently deployed across 25
              Federal Teaching Hospitals in conjunction with the Federal
              Ministry of Health.{" "}
            </p>

            <p>
              The PCN NPSMS - MedEX - HMIS linkage across Federal Teaching
              Hospitals will gravitate a demand pool strong enough to influence
              the desired shift towards pre-conditioned regulatory compliance
              from supply side players (Manufacturers/Importers/Distributors)
              desirous of keying into this massive demand pool.
            </p>
          </Description>
        </ContentWrapper>
      </PortfolioContainer>
    </>
  );
}

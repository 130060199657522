export const lightTheme = {
  text: {
    primary: "#1C2222",
    prim_50: "#525252",
    prim_100: "#646F7B",
    prim_200: "#828282",
    prim_500: "#fff",
    secondary: "#2B1656",

    error: "red",
    success: "green",
    progress: "#336CFB",
    danger: "#ff5100",
    life: "#06dd06",
    mild_danger: "#ffa500",
    pending: "#952bff",
  },
  bg: {
    primary: "#0C2057",
    prim_100: "",
    prim_200: "#5DA9F9",
    prim_300: "#D6E3f7",
    prim_400: "#F0F4FF",
    prim_500: "#FFFFFF",
    secondary: "#020C25",
    sec_100: "#443C73",
    sec_200: "#C7ACFF",
    sec_300: "#d5c3fa",
    sec_400: "#f0ebfa",
    tertiary: "#575757",
    tert_100: "#828282",
    tert_200: "#a8a8a8",
    tert_300: "#d6d6d6",
    tert_400: "#ededed",
    tert_500: "#fdfdfd",

    btn_disabled: "#9e9e9e",

    warn: "#fa9b02"
  },

  border: {
    input: "#CACACA",
    light: "#EAEAEA",
    sec_button: "#2B1656",
    active_input: "#0C2057",
  },

  shadow: {
    shad_100: "1px 1px 7px rgba(0, 0, 0, 0.15)",
    drag_over: "1px 1px 7px #336CFBAA",
  },
  dashboard: {
    stat_1: "#8BA5E8",
    stat_2: "#36CDB0",
    stat_3: "#ECAF57", 
    stat_4: "#E96B80",
  },
  status: {
    level_1: "#07d907",
    level_2: "#006d80",
    level_3: "#336cfb",
    level_4: "#ffa500",
    level_5: "#ff5100",
  },
  screen: {
    mobile: "480px",
    tablet: "768px",
    smallScreen: "1400px",
  },

  // text: {
  //   primary: "#393e43",
  //   prim_100: "#231C1C",
  //   prim_200: "#0A0A0A",
  //   prim_300: "#13263C",
  //   prim_400: "#404040",
  //   prim_500: " #25282B",
  //   label: "#62655D",
  //   input: "#434540",
  //   inverted: "#FFFFFF",
  //   muted: "#D9DBE1",
  //   muted_50: "#F2F2F2",
  //   muted_100: "#dbdde0",
  //   muted_200: "#838383",
  //   error: "red",
  //   success: "green",
  //   progress: "#336CFB",
  //   danger: "#ff5100",
  //   life: "#06dd06",
  //   mild_danger: "#ffa500",
  //   pending: "#952bff"
  // },
  // bg: {
  //   primary: "#FFFFFF",
  //   prim_100: "#F2F2F2",
  //   prim_200: "#F9F9F9",
  //   inverted: "#010000",
  //   secondary: "#5D7AB2",
  //   otp: "#09792C",
  //   progress: "#336CFB",
  //   muted: "#E8E8E8",
  //   muted_100: " #F6F6F6",
  //   icon: "#93A1A3",
  // },
  // button: {
  //   primary: "#336CFB",
  //   prim_disabled: "#224f8d",
  //   secondary: "#FFFFFF",
  //   sec_disabled: "transparent",
  // },
  // shadow: {
  //   shad_100: "1px 1px 7px rgba(0, 0, 0, 0.15)",
  //   drag_over: "1px 1px 7px #336CFBAA",
  // },
  // dashboard: {
  //   stat_1: "#FFF0E6",
  //   stat_2: "#ECEAFE",
  //   stat_3: "#E5F7FF",
  // },
  // status:{
  //   level_1:"#07d907",
  //   level_2:"#006d80",
  //   level_3:"#336cfb",
  //   level_4:"#ffa500",
  //   level_5:"#ff5100",
  // },
  // screen: {
  //   mobile: "480px",
  //   tablet: "768px",
  //   smallScreen: "1400px",
  // },
};

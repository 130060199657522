import React from 'react';
import './button.css'; 

interface ButtonArrayProps {
  buttons: string[];
}

const ButtonArray: React.FC<ButtonArrayProps> = ({ buttons }) => {
    
  return (
    <div className="button-array">
      {buttons.map((button, index) => (
        <button key={index} className="array-button">
          {button}
        </button>
      ))}
    </div>
  );
};

export default ButtonArray;
import { H2, H3 } from "src/common/elements";
import { NewDeliveryWrapper } from "./style";
import Form from "src/common/components/form/Form";
import {
  addedProductHeaders,
  addedProductKeys,
  getAddedProductTableBody,
  getDeliveryCompanyOptions,
  getReceivingCompanyOptions,
  getTotalRange,
  getTotalWeight,
  productDetailsForm,
  vehicleOptions,
} from "../data";
import { KeyValueObj } from "src/common/types";
import Table from "src/common/components/Table";
import { useCallback, useState } from "react";
import { Guid } from "guid-typescript";
import { Select } from "src/common/components/selectInput";
import useQuery from "src/hooks/useQuery";
import {
  COMPANIES,
  DELIVERY_REQUEST_URLS,
  LOGISTICS_COMPANIES_URL,
} from "src/common/constants";
import { debounce } from "src/common/utils/general";
import { Address } from "src/common/components/addresses/address";
import { getNewDeliveryAddressFormInputs } from "src/common/components/addresses/data";
import { KeyValue } from "src/common/components/key-value";
import { Button } from "src/common/components/button";
import { useNotification } from "src/common/components/Notifications/NotificationProvider";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { useNavigate } from "react-router-dom";
import { AddressManager } from "src/common/components/addresses";
import { Modal } from "src/common/components/modal";
import { DeliveryRequestPreview } from "./preview";
import Input from "src/common/components/Input/Input";
import { PageTitleNavs } from "src/common/components/page-title-navs";

export interface INewDeliveryProps {}

export function NewDelivery(props: INewDeliveryProps) {
  const [addedProducts, setAddedProducts] = useState<KeyValueObj[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentRow, setCurrentRow] = useState<KeyValueObj>({});
  const [recipentCompany, setRecipentCompany] = useState<KeyValueObj>({});
  const [logisticCompany, setLogisticCompany] = useState<KeyValueObj>({});
  const [selectedAddress, setSelectedAddress] = useState<KeyValueObj>({});
  const [isUseCustomAddress, setIsUseCustomAddress] = useState(false);
  const [customAddress, setCustomAddress] = useState<KeyValueObj>({});
  const [dropOffAddress, setDropOffAddress] = useState<KeyValueObj>({});
  const [pickupType, setPickupType] = useState("logistic_pickup");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [senderAddressType, setSenderAddressType] = useState("company_address");
  const [pickupAddress, setPickupAddress] = useState<KeyValueObj>({});
  const [showPreview, setShowPreview] = useState(false);
  const [payload, setPayload] = useState<KeyValueObj>({});
  const [otherDetails, setOtherDetails] = useState<KeyValueObj>({});

  const { data: companies, refetch: refetchRecipentCompanies } =
    useQuery(COMPANIES);
  const { data: logisticsCompanies, refetch: refetchLogisticsCompanies } =
    useQuery(LOGISTICS_COMPANIES_URL);

  const notify = useNotification();
  const makeRequest = useApiCallHandling();
  const navigate = useNavigate();

  const handleDropdownClick = useCallback(
    (row: KeyValueObj) => {
      showDropdown ? setCurrentRow({}) : setCurrentRow(row);
      setShowDropdown((prev) => !prev);
    },
    [showDropdown]
  );

  const handleRemove = useCallback(() => {
    setAddedProducts((prev: KeyValueObj[]) => {
      const filtered = prev?.filter(
        (product: KeyValueObj) => product?.id !== currentRow?.id
      );

      return filtered;
    });
    setShowDropdown(false);
  }, [currentRow?.id]);

  function handleSubmit(values: KeyValueObj) {
    setAddedProducts((prev: KeyValueObj[]) => [
      ...prev,
      { ...values, id: Guid.raw() },
    ]);
    notify({ message: "Product added to list", type: "SUCCESS" });
  }

  const handleUpdateData = useCallback(
    (name: string, value: any) => {
      const company = companies.find(
        (company: KeyValueObj) => company?.id === value
      );
      setRecipentCompany(company);
      setSelectedAddress({});
    },
    [companies]
  );

  const handleUpdateLogistic = useCallback(
    (name: string, value: any) => {
      const company = logisticsCompanies.find(
        (company: KeyValueObj) => company?.id === value
      );
      setLogisticCompany(company);
    },
    [logisticsCompanies]
  );

  const handleRecipentCompanySearch = useCallback(
    (value: string) => {
      refetchRecipentCompanies(COMPANIES + `?search=${value}`);
    },
    [refetchRecipentCompanies]
  );

  const handleLogisticCompanySearch = useCallback(
    (value: string) => {
      refetchLogisticsCompanies(LOGISTICS_COMPANIES_URL + `?search=${value}`);
    },
    [refetchLogisticsCompanies]
  );

  const validateDeliveryRequestSubmission = useCallback(() => {
    let errorMessages: string[] = [];

    if (addedProducts.length === 0)
      errorMessages.push("You must add products to ship");
    if (!recipentCompany?.id)
      errorMessages.push("You must select recipent company");
    if (!logisticCompany.id)
      errorMessages.push("You must choose a Logistic company");
    if (!selectedAddress.id && !customAddress.lga)
      errorMessages.push("You must provide the delivery address");
    if (pickupType === "location_pickup" && !dropOffAddress.lga)
      errorMessages.push(
        "If you choose pickup type as 'Pickup from a designated office address', then you must provide the address for pick up. "
      );
    return errorMessages;
  }, [
    addedProducts.length,
    customAddress.lga,
    dropOffAddress.lga,
    logisticCompany.id,
    pickupType,
    recipentCompany?.id,
    selectedAddress.id,
  ]);

  const handleSubmitDeliveryRequest = useCallback(() => {
    setLoading(true);
    makeRequest(
      DELIVERY_REQUEST_URLS,
      "POST",
      payload,
      () => navigate("/dashboard/deliveries"),
      console.log,
      () => setLoading(false)
    );
  }, [makeRequest, navigate, payload]);

  const handleProceedDeliveryRequest = useCallback(() => {
    const errorMessages = validateDeliveryRequestSubmission();
    setErrorMessages(errorMessages);
    if (errorMessages.length > 0) return;

    const sender_details: KeyValueObj = {
      secondary_phone_number: otherDetails?.sender_phone_number,
    };
    if (pickupType === "logistics_pickup") {
      if (senderAddressType === "company_address") {
        sender_details.address_id = pickupAddress.id;
      } else {
        sender_details.custom_address = dropOffAddress;
      }
    }
    const recipient_details: KeyValueObj = {
      id: recipentCompany?.id,
      secondary_phone_number: otherDetails?.recipent_phone_number,
    };
    if (isUseCustomAddress) {
      recipient_details.custom_address = customAddress;
    } else {
      recipient_details.address_id = selectedAddress?.id;
    }

    const pickup_option = pickupType;
    const logistics_company = logisticCompany?.id;
    const delivery_items_details = addedProducts;
    const payload = {
      sender_details,
      recipient_details,
      logistics_company,
      pickup_option,
      delivery_items_details,
      vehicle_needed: otherDetails?.vehicle_needed,
    };

    setPayload(payload);
    setShowPreview(true);
  }, [
    validateDeliveryRequestSubmission,
    otherDetails?.sender_phone_number,
    otherDetails?.recipent_phone_number,
    otherDetails?.vehicle_needed,
    pickupType,
    recipentCompany?.id,
    isUseCustomAddress,
    logisticCompany?.id,
    addedProducts,
    senderAddressType,
    pickupAddress,
    dropOffAddress,
    customAddress,
    selectedAddress?.id,
  ]);

  const debouncedSearch = debounce(handleRecipentCompanySearch, 500);
  const debouncedLogisticSearch = debounce(handleLogisticCompanySearch, 500);

  return (
    <NewDeliveryWrapper>
      <PageTitleNavs
        title="New Delivery Request"
        backLink="/dashboard/deliveries"
        bcrumbs={[
          { title: "Deliveries", link: "/dashboard/deliveries" },
          { title: "New Delivery", link: "#" },
        ]}
      />
      <H2>New Delivery</H2>
      <div className="main-wrapper">
        <H3>Product Details</H3>
        <Form
          formInputs={productDetailsForm}
          processInputs={handleSubmit}
          submitLabel="Add"
          removePadding
        />
        <Table
          tableHeaders={addedProductHeaders}
          tableData={getAddedProductTableBody(addedProducts)}
          tableKeys={addedProductKeys}
          handleDropDownClicked={handleDropdownClick}
          showDropDown={showDropdown}
          removeFilters
          rowLineColor="light"
          dropDownOptions={[{ label: "Remove", action: handleRemove }]}
        />

        <div className="separate"></div>

        <H3>Delivery Details</H3>

        <Select
          options={getReceivingCompanyOptions(companies)}
          placeholder={"ABC Manufacturers"}
          prompt={"Select Recipent Company"}
          fullWidth={false}
          shortWidth={false}
          name={"receipent_company"}
          indexLevel={999}
          formValues={{}}
          setFormValues={() => {}}
          handleSearch={debouncedSearch}
          updateData={handleUpdateData}
          returnId
        />
        {recipentCompany?.addresses?.map((address: KeyValueObj) => (
          <Address
            address={address}
            handleAddressSelected={(address) => {
              setSelectedAddress(address);
              setIsUseCustomAddress(false);
            }}
            selectedAddress={selectedAddress}
            removeControl
          />
        ))}

        {recipentCompany && recipentCompany?.addresses?.length === 0 ? (
          <p className="no-address">
            The selected company have no registered address
          </p>
        ) : null}

        <div className="checkbox-wrapper">
          <input
            checked={isUseCustomAddress}
            onChange={() => {
              setSelectedAddress({});
              setIsUseCustomAddress((prev) => !prev);
            }}
            type="checkbox"
            id={"custom-address"}
          />
          <label htmlFor="custom-address">Enter Custom address</label>
        </div>
        {isUseCustomAddress ? (
          <Form
            formInputs={getNewDeliveryAddressFormInputs()}
            processInputs={() => {}}
            removeSubmitButton
            removePadding
            externalUpdateData={(name, value) =>
              setCustomAddress((prev) => ({ ...prev, [name]: value }))
            }
          />
        ) : null}

        <div className="separate"></div>

        <H3>Logistics Provider Details</H3>

        <Select
          options={getDeliveryCompanyOptions(logisticsCompanies)}
          placeholder={"ABC Manufacturers"}
          prompt={"Select Logistic Company"}
          fullWidth={false}
          shortWidth={false}
          name={"logistic_company"}
          indexLevel={4}
          formValues={{}}
          setFormValues={() => {}}
          handleSearch={debouncedLogisticSearch}
          updateData={handleUpdateLogistic}
          returnId
        />
        <div className="logistic-details">
          <KeyValue
            title="Address: "
            value={`${logisticCompany.street_address || "--"}, ${
              logisticCompany.state || "--"
            }`}
            row
          />
          <KeyValue
            title="Price Range (Per kg): "
            value={`${logisticCompany.lowest_price || "--"} - ${
              logisticCompany.highest_price || "--"
            }`}
            row
          />
          <KeyValue
            title="Total Delivery Weight: "
            value={getTotalWeight(addedProducts) + "kg"}
            row
          />
          <KeyValue
            title="Total Cost Range: "
            value={getTotalRange(addedProducts, logisticCompany)}
            row
          />
        </div>

        <div className="separate"></div>

        <H3>Other Details</H3>
        <Select
          options={vehicleOptions}
          placeholder={"Bus"}
          prompt={"Preferred Vehicle Type"}
          fullWidth={false}
          shortWidth={false}
          name={"vehicle_needed"}
          indexLevel={90}
          formValues={{}}
          setFormValues={() => {}}
          updateData={(name: string, value: any) =>
            setOtherDetails((prev) => ({ ...prev, [name]: value }))
          }
          returnId
        />

        <Input
          placeholder={"07012345678"}
          prompt={"Recipent's Secondary Phone Number"}
          fullWidth={false}
          shortWidth={false}
          name={"recipent_phone_number"}
          indexLevel={98}
          formValues={{}}
          setFormValues={() => {}}
          updateValidity={() => {}}
          updateData={(name: string, value: any) =>
            setOtherDetails((prev) => ({ ...prev, [name]: value }))
          }
          returnId
        />

        <Input
          placeholder={"09012345678"}
          prompt={"Sender's Secondary Phone Number"}
          fullWidth={false}
          shortWidth={false}
          name={"sender_phone_number"}
          indexLevel={97}
          formValues={{}}
          setFormValues={() => {}}
          updateValidity={() => {}}
          updateData={(name: string, value: any) =>
            setOtherDetails((prev) => ({ ...prev, [name]: value }))
          }
          returnId
        />

        <div className="separate"></div>

        <H3>Pick-up Type</H3>
        <div className="pickup-wrapper">
          <span className="radio-wrapper">
            <input
              type="radio"
              value="logistics_pickup"
              name="pickup_option"
              id="logistics-pickup"
              checked={pickupType === "logistics_pickup"}
              onChange={(e) => setPickupType(e.target.value)}
            />
            <label htmlFor="logistics-pickup">
              Logistic Pickup - The logistic company will come to either your
              office address or an address you will specify.
            </label>
          </span>
          <span className="radio-wrapper">
            <input
              type="radio"
              value="sender_drop_off"
              name="pickup_option"
              id="sender_drop_off"
              checked={pickupType === "sender_drop_off"}
              onChange={(e) => setPickupType(e.target.value)}
            />
            <label htmlFor="sender_drop_off">
              Sender Dropoff - You will take the items to the logistics
              company's address
            </label>
          </span>
        </div>

        {pickupType === "logistics_pickup" ? (
          <div className="logistics-type">
            <H3>Where should the logistic company pick up the items</H3>
            <div className="pickup-wrapper">
              <span className="radio-wrapper">
                <input
                  type="radio"
                  value="company_address"
                  name="sender_address_type"
                  id="company_address"
                  checked={senderAddressType === "company_address"}
                  onChange={(e) => setSenderAddressType(e.target.value)}
                />
                <label htmlFor="company_address">Company Address</label>
              </span>
              <span className="radio-wrapper">
                <input
                  type="radio"
                  value="custom_address"
                  name="sender_address_type"
                  id="custom_address"
                  checked={senderAddressType === "custom_address"}
                  onChange={(e) => setSenderAddressType(e.target.value)}
                />
                <label htmlFor="custom_address">Custom Address</label>
              </span>
            </div>
          </div>
        ) : null}

        {pickupType === "logistics_pickup" &&
        senderAddressType === "custom_address" ? (
          <Form
            formInputs={getNewDeliveryAddressFormInputs()}
            processInputs={() => {}}
            removeSubmitButton
            removePadding
            externalUpdateData={(name, value) =>
              setDropOffAddress((prev) => ({ ...prev, [name]: value }))
            }
          />
        ) : null}

        {pickupType === "logistics_pickup" &&
        senderAddressType === "company_address" ? (
          <AddressManager
            selectedAddress={pickupAddress}
            setSelectedAddress={setPickupAddress}
          />
        ) : null}
        {errorMessages?.length > 0 ? (
          <div>
            {errorMessages.map((error: string) => (
              <p className="error">{error}</p>
            ))}
          </div>
        ) : null}
        <div className="flex-col control">
          <Button loading={loading} onClick={handleProceedDeliveryRequest}>
            Proceed
          </Button>
        </div>
      </div>

      <Modal
        isOpen={showPreview}
        close={() => setShowPreview(false)}
        title="Delivery Details Preview"
      >
        <DeliveryRequestPreview
          payload={payload}
          recipentCompany={recipentCompany}
          recipentAddress={isUseCustomAddress ? customAddress : selectedAddress}
          logisticCompany={logisticCompany}
          senderAddressType={senderAddressType}
          pickupAddress={
            senderAddressType === "company_address"
              ? pickupAddress
              : dropOffAddress
          }
          handleSubmitDeliveryRequest={handleSubmitDeliveryRequest}
          loading={loading}
          otherDetails={otherDetails}
        />
      </Modal>
    </NewDeliveryWrapper>
  );
}

import { useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import dotsImage from "../../assets/images/dots.png";
import resetImage from "../../assets/images/password-reset-image.png";
import { ResetWrapper } from "./styles";
import Form from "../../common/components/form/Form";
import { resetForm } from "./data";
import { useApiCallHandling } from "../../hooks/useApiCallHandling";
import { CHANGE_PASSWORD_URL } from "../../common/constants";

export interface IOTPProps {}

export function PasswordReset(props: IOTPProps) {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("reset_token");

  const navigate = useNavigate();
  const makeRequst = useApiCallHandling();

  const [loading, setLoading] = useState(false);

  function handleResetForm(formValues: any) {
    setLoading(true);
    const password = formValues?.password;
    const payload = {
      password,
      token,
    };
    makeRequst(
      CHANGE_PASSWORD_URL,
      "POST",
      payload,
      (data: any) => navigate("/login"),
      (err: any) => console.log(err),
      () => setLoading(false)
    );
  }

  return (
    <ResetWrapper>
      <section className="container">
        <section className="info-sec">
          <img className="pharma-image" src={resetImage} alt="" />
          <img className="dots-image" src={dotsImage} alt="" />
        </section>
        <section className="input-sec">
          <div className="top flex-row">
            <div className="welcome-message">
              <h2 className="title">Password Reset</h2>
              <p className="more-info">Select new password</p>
            </div>
          </div>
          <div className="form">
            <Form
              formInputs={resetForm}
              loadingState={loading}
              submitLabel="Reset"
              processInputs={handleResetForm}
              login={false}
              biControl={false}
              handleBack={undefined}
              spinnerComponent={undefined}
            />
          </div>
        </section>
      </section>
    </ResetWrapper>
  );
}

import { NewRfqWrapper } from "./styles";
import { useState, useEffect } from "react";
import Tab from "src/common/components/Tab/Tab";
import { ProductInformation } from "./product-info";
import { KeyValueObj } from "src/common/types";
import uuid from "react-uuid";
import { Distributors } from "./distributors";
import { PaymentTerms } from "./payment-term";
import { Preview } from "./preview";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { OTHER_BASE_SETTINGS_URL, RFQ_URL } from "src/common/constants";
import { useNavigate } from "react-router-dom";
import { useNotification } from "src/common/components/Notifications/NotificationProvider";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import useQuery from "src/hooks/useQuery";

export interface INewRFQProps {}

const tabs = ["Product Information", "Distributors", "Payment Term", "Preview"];

export function NewRFQ(props: INewRFQProps) {
  const [currentTab, setCurrentTab] = useState("Product Information");
  const [addedProducts, setAddedProducts] = useState<KeyValueObj[]>([]);
  const [addedDistributors, setAddedDistributors] = useState<KeyValueObj[]>([]);
  const [distributorsCategory, setDistributorsCategory] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paymentDeliveryInformation, setPaymentDeliveryInformation] =
    useState<KeyValueObj>({});
  const [paymentTermsList, setPaymentTermsList] = useState<KeyValueObj[]>([]);

  const { data: PaymentTermsData } = useQuery(OTHER_BASE_SETTINGS_URL);

  useEffect(() => {
    const paymentTerms = PaymentTermsData?.[0]?.values?.payment_terms?.map(
      (paymentTerm: string) => ({
        title: paymentTerm,
        id: paymentTerm,
      })
    );

    setPaymentTermsList(paymentTerms);
  }, [PaymentTermsData]);

  const makeRequest = useApiCallHandling();
  const navigate = useNavigate();
  const notify = useNotification();

  function handleAddProduct(values: KeyValueObj) {
    let [strength, form] = values?.["product_strength"]?.split(" ");
    const splitPoint = strength.charAt(strength.length - 6) === "i" ? 10 : 5;
    values.product_strength = strength.substring(
      0,
      strength.length - splitPoint
    );
    values.product_strength_unit = strength.substring(
      strength.length - splitPoint
    );
    
    values.product_form = form?.substring(1, form.length - 1);
    values.id = uuid();

    setAddedProducts((prev: KeyValueObj[]) => {
      const filtered = prev?.filter(
        (prevData: KeyValueObj) =>
          prevData.product_strength_unit === values?.product_strength_unit &&
          prevData.product_strength === values?.product_strength &&
          prevData.product_form === values?.product_form &&
          prevData.brand_name === values?.brand_name
      );
      if (filtered?.length > 0) {
        notify({
          type: "ERROR",
          message: "Product already Added",
        });
        return prev;
      } else {
        notify({
          type: "SUCCESS",
          message: "Product Added",
        });

        return [...prev, values];
      }
    });
  }

  function handleAddDistributor(distributors: KeyValueObj[]) {
    setAddedDistributors((prev: KeyValueObj[]) => {
      const newDistributors = [...prev, ...distributors];
      const uniqueDistributors = Array.from(new Set(newDistributors));
      return uniqueDistributors;
    });
  }

  function handleRemoveDistributor(row: KeyValueObj) {
    setAddedDistributors((prev: KeyValueObj[]) => {
      const filtered = prev.filter((data: KeyValueObj) => data?.id !== row.id);
      return filtered;
    });
  }

  function handleRemoveProduct(row: KeyValueObj) {
    setAddedProducts((prev: KeyValueObj[]) => {
      const filtered = prev.filter((data: KeyValueObj) => data?.id !== row.id);
      return filtered;
    });
  }

  function handleProductInfoContinue() {
    if (addedProducts.length > 0) setCurrentTab("Distributors");
  }
  function handleDistributorsContinue() {
    if (addedProducts.length > 0) setCurrentTab("Payment Term");
  }
  function handleDistributorsBack() {
    if (addedProducts.length > 0) setCurrentTab("Product Information");
  }

  function handlePaymentContinue() {
    if (addedProducts.length > 0) setCurrentTab("Preview");
    setCurrentTab("Preview");
  }
  function handlePaymentBack() {
    if (addedProducts.length > 0) setCurrentTab("Distributors");
  }

  function handleSetCurrentTab(tab: string) {
    switch (tab) {
      case "Distributors":
        if (addedProducts.length > 0) setCurrentTab(tab);
        break;
      case "Payment Term":
        setCurrentTab(tab);
        if (
          addedDistributors.length > 0 ||
          distributorsCategory === "linked" ||
          distributorsCategory === "all"
        ) {
          setCurrentTab(tab);
        }
        break;
      case "Preview":
        if (
          addedProducts.length > 0 &&
          addedDistributors.length > 0 &&
          (addedDistributors.length > 0 ||
            distributorsCategory === "linked" ||
            distributorsCategory === "all") &&
          Object.keys(paymentDeliveryInformation).length > 0
        ) {
          setCurrentTab(tab);
        }
        break;

      default:
        setCurrentTab(tab);
        break;
    }
  }

  function handlePreviewSubmit() {
    setSubmitLoading(true);
    const distributors = addedDistributors.map(
      (addedDistributor: KeyValueObj) => ({
        name: addedDistributor.companyName?.value,
        email: addedDistributor.email?.value,
        phone_number: addedDistributor?.phoneNumber?.value,
        id: addedDistributor?.id,
      })
    );
    const payload = {
      ...paymentDeliveryInformation,
      products: addedProducts,
      distributors,
      send_to: distributorsCategory,
    };

    makeRequest(
      RFQ_URL,
      "POST",
      payload,
      () => navigate("/dashboard/rfq"),
      console.log,
      () => setSubmitLoading(false)
    );
  }

  function handlePreviewBack() {
    setCurrentTab("Payment Term");
  }

  const TabView: any = {
    "Product Information": (
      <ProductInformation
        addProduct={handleAddProduct}
        removeProduct={handleRemoveProduct}
        addedProducts={addedProducts}
        handleProductInfoContinue={handleProductInfoContinue}
      />
    ),
    Distributors: (
      <Distributors
        addedDistributors={addedDistributors}
        removeDistributor={handleRemoveDistributor}
        addDistributor={handleAddDistributor}
        distributorsCategory={distributorsCategory}
        setDistributorsCategory={setDistributorsCategory}
        handleDistributorsBack={handleDistributorsBack}
        handleDistributorsContinue={handleDistributorsContinue}
      />
    ),
    "Payment Term": (
      <PaymentTerms
        paymentDeliveryInformation={paymentDeliveryInformation}
        setPaymentDeliveryInformation={setPaymentDeliveryInformation}
        handlePaymentContinue={handlePaymentContinue}
        handlePaymentBack={handlePaymentBack}
        paymentTermsList={paymentTermsList}
      />
    ),
    Preview: (
      <Preview
        addedProducts={addedProducts}
        addedDistributors={addedDistributors}
        distributorsCategory={distributorsCategory}
        paymentDeliveryInformation={paymentDeliveryInformation}
        onPreviewBack={handlePreviewBack}
        onPreviewSubmit={handlePreviewSubmit}
        submitLoading={submitLoading}
      />
    ),
  };

  return (
    <NewRfqWrapper>
      <PageTitleNavs
        title="New RFQ"
        backLink="#"
        bcrumbs={[
          { title: "RFQs", link: "/dashboard/rfq" },
          { title: "New", link: "#" },
        ]}
      />
      <div className="new-rfq-container">
        <Tab
          tabsTitle={tabs}
          onTabClick={handleSetCurrentTab}
          currentTab={currentTab}
        />
        {TabView[currentTab]}
      </div>
    </NewRfqWrapper>
  );
}

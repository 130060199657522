import styled from "styled-components";

export const PortfolioContainer = styled.div`
  padding: 40px 20px;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  font-family: Arial, sans-serif;
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`;

export const AbstractCircle = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  top: 10%;
  left: 10%;
  z-index: 0;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    top: 5%;
    left: 5%;
  }
`;

export const AbstractCircleBottom = styled(AbstractCircle)`
  top: auto;
  bottom: 10%;
  left: auto;
  right: 10%;

  @media (max-width: 768px) {
    bottom: 5%;
    right: 5%;
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 5rem;
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
`;

export const Header = styled.h1`
  color: #4a90e2;
  font-family: Recoleta Alt;
  margin-bottom: 20px;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Description = styled.p`
  color: #555;
  line-height: 1.6;
  font-size: 1.1rem;
  margin: 0 auto;
  padding: 0 15px;
  p {
    margin-bottom: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

import { BsCheck2Circle } from "react-icons/bs";
import { Button, H3 } from "src/common/elements";
import { KeyValueObj } from "src/common/types";
import { formatToNaira } from "src/common/utils/general";
import styled from "styled-components";

const InvoiceCardWrapper = styled.div`
  width: 16rem;
  padding: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadow.shad_100};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    width: 100%;
    padding: 1rem 0.5rem;
  }

  .invoice-title {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  .invoice-value {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.text.prim_100};
    font-weight: 500;

    span {
      color: ${({ theme }) => theme.text.prim_50};
      font-weight: 600;
      padding-left: 0.5rem;
    }

    .pending {
      color: orange;
    }

    .paid {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      justify-content: center;
      display: inline;
      color: green;
    }
  }

  .mg-top {
    margin-top: 0.5rem;
  }
`;

export interface IInvoiceCardProps {
  invoice: KeyValueObj;
}

function handlePayNow(url: string){
  window.open(url, "_blank")?.focus();
}

export function InvoiceCard({ invoice }: IInvoiceCardProps) {
  return (
    <InvoiceCardWrapper>
      <H3 className="invoice-title">Generated Invoice</H3>
      <h4 className="invoice-value">
        Invoice Number: <span>{invoice?.invoice_number}</span>
      </h4>
      <h4 className="invoice-value">
        Date Generated: <span>{}</span>
      </h4>
      <h4 className="invoice-value">
        Amount: <span>{formatToNaira(invoice?.amount_due)}</span>
      </h4>
      <h4 className="invoice-value">
        Status:
        <span>
          {invoice.paid ? (
            <span className="paid">
              Paid <BsCheck2Circle className="paid-icon" />{" "}
            </span>
          ) : (
            <span className="pending">Pending</span>
          )}
        </span>
      </h4>
      <h4 className="invoice-value mg-top">
        Recipient Email: <span className={""}>{""}</span>
      </h4>
      <h4 className="invoice-value">
        Phone Number: <span className={""}>{""}</span>
      </h4>
      <h4 className="invoice-value">
        Due Date: <span className={""}>{""}</span>
      </h4>

      {invoice?.paid ? null : (
        <Button
          onClick={() => handlePayNow(invoice?.payment_url)}
          style={{ marginTop: "1rem" }}
        >
          Pay Now
        </Button>
      )}
    </InvoiceCardWrapper>
  );
}

import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Logo } from "../../../common/components/logo";
import useAuth, { useDashboardContext } from "../../../hooks/useContexts";
import useLogout from "src/hooks/useLogout";
import { RxHamburgerMenu } from "react-icons/rx";
import { Sidebar } from "./sidebar";
import { useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6vw;
  /* background-color: ${({ theme }) => theme.bg.prim_500}; */
  background-color: ${({ theme }) => theme.bg.primary};

  border-bottom: 1px solid ${({ theme }) => theme.border.light};

  @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
    padding: 0 5vw;
  }

  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  .logo-wrapper {
    flex: 1;

    .logo {
      height: 2.2rem;
      text-align: left;
    }
  }

  .nav-items {
    list-style: none;
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .nav-wrapper {
      width: 85%;
      max-width: 40rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    li {
      padding: 0.2rem;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1.5rem;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: ${({ theme }) => theme.text.prim_100};

      /* @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
        padding: 0 1.5rem;
        font-weight: 500;
        font-size: 0.8rem;
      } */
    }

    @media screen and (max-width: ${({ theme }) => theme.screen.tablet}) {
      display: none;
    }
  }

  .nav-link {
    text-decoration: none;
    /* color: ${({ theme }) => theme.text.prim_100}; */
    color: ${({ theme }) => theme.text.prim_500};
  }

  .nav-gap {
    margin-left: 1rem;
  }

  .active-nav-link {
    text-decoration: none;
    color: ${({ theme }) => theme.text.secondary};
    position: relative;
    color: #ff4545;

    :before {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      width: 100%;
      height: 0.2rem;
      /* background-color: ${({ theme }) => theme.text.prim_500}; */
      background-color: #ff4545;
    }
  }

  .logout {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .hamburger-icon {
    position: relative;
    z-index: 9;
    color: ${({ theme }) => theme.text.prim_500};
    font-size: 1.8rem;
    animation: bounceIn 0.35s ease-in-out forwards;

    @media screen and (min-width: ${({ theme }) => theme.screen.tablet}) {
      display: none;
    }
  }

  .hamburger-close-icon {
    position: relative;
    z-index: 9;
    color: ${({ theme }) => theme.text.danger};
    font-size: 1.8rem;
    animation: bounceIn 0.35s ease-in-out forwards;

    @media screen and (min-width: ${({ theme }) => theme.screen.tablet}) {
      display: none;
    }
  }
`;

export interface INavigation {
  link: string;
  title: string;
}

export const navigationList: INavigation[] = [
  {
    link: "/",
    title: "Home",
  },
  {
    link: "/our-team",
    title: "Our Team",
  },
  {
    link: "/portfolio",
    title: "Portfolio",
  },
  // {
  //   link: "/purchase",
  //   title: "Purchase",
  // },
];

export interface IMainNavProps {}

export function MainNav(props: IMainNavProps) {
  const [showSidebar, setShowSidebar] = useState(false);

  const { auth } = useAuth();
  const { showOnlyLogout } = useDashboardContext();

  const getLoginTo = (auth: any) => {
    if (auth?.user?.registration_completed) {
      const title = auth?.user?.role?.title;
      if (title?.toLowerCase() === "admin") {
        return "/dashboard/admin/products/approved";
      }
      if (title?.toLowerCase() === "pcn-admin") {
        return "/pcn-admin/invite-companies";
      }
      return "/dashboard/stats";
    } else {
      return "/login";
    }
  };

  const logout = useLogout();

  return (
    <Wrapper>
      <div className="logo-wrapper">
        <div className="logo">
          <NavLink className="nav-link" to="/">
            <Logo />
          </NavLink>
        </div>
      </div>

      <ul className="nav-items flex-row">
        <div className="nav-wrapper">
          {showOnlyLogout
            ? null
            : navigationList?.map((navList: INavigation, idx: number) => (
                <li key={idx}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active-nav-link" : "nav-link"
                    }
                    to={navList.link}
                  >
                    {navList.title}
                  </NavLink>
                </li>
              ))}

          {showOnlyLogout ? (
            <li className="logout">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-nav-link" : "nav-link"
                }
                onClick={() => logout()}
                to={""}
              >
                Logout
              </NavLink>
            </li>
          ) : (
            <>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav-link nav-gap" : "nav-link nav-gap"
                  }
                  to="/register"
                >
                  Register
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav-link" : "nav-link"
                  }
                  to={getLoginTo(auth)}
                >
                  Login
                </NavLink>
              </li>
            </>
          )}
        </div>
      </ul>

      {showSidebar ? (
        <FaArrowLeftLong
          onClick={() => setShowSidebar((prev: boolean) => !prev)}
          className="hamburger-close-icon"
        />
      ) : (
        <RxHamburgerMenu
          onClick={() => setShowSidebar((prev: boolean) => !prev)}
          className="hamburger-icon"
        />
      )}
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

      <div
        style={{ display: showSidebar ? "block" : "none" }}
        className="sidebar-backdrop"
        onClick={() => setShowSidebar(false)}
      ></div>
    </Wrapper>
  );
}

import Table from "src/common/components/Table";
import styled from "styled-components";
import { batchTableHeaders, batchTableKeys, getBatchTableData } from "./data";
import {
  IBatchData,
  IProductBatches,
  KeyValueObj,
} from "src/common/types/others";
import { H3 } from "src/common/elements";
import Input from "src/common/components/Input/Input";
import { useState } from "react";
import { Button } from "src/common/components/button";
import useQuery from "src/hooks/useQuery";
import { ORDERS_URL } from "src/common/constants";
import { PageSkeletonLoader } from "src/common/components/skeleton/page-skeleton";

const ShipWrapper = styled.div`
  width: 50rem;
  padding: 1.5rem;
  padding-bottom: 3rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.2rem;
    .delivery-wrapper {
      flex-direction: column;
      gap: 2rem;

    }

  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    padding: 1.5rem;

    @media (max-width: ${({theme}) => theme.screen.mobile}){
      padding: 0.5rem;
    }
  }

  .error {
    margin: 2rem;
    padding: 1.5rem;
    background-color: ${({ theme }) => theme.bg.warn}20;
    color: ${({ theme }) => theme.bg.warn};
    font-weight: 500;
    font-size: 0.95rem;
    border-radius: 0.25rem;
  }
`;

export interface IShippingDetailsProps {
  productBatches: IProductBatches;
  handleProductSelected: (
    batchData: IBatchData[],
    orderProduct: KeyValueObj
  ) => void;
  orderDetails: KeyValueObj;
  shipping: boolean;
  handleShipOrder: (
    productBatches: IProductBatches,
    deliveryDate: string
  ) => void;
}

interface IExpectedDelivery {
  delivery_date_beginning: string;
  delivery_date_end: string;
}

export function ShippingDetails({
  handleProductSelected,
  productBatches,
  orderDetails,
  shipping,
  handleShipOrder,
}: IShippingDetailsProps) {
  const [expectedDeliveryDate, setExpectedDeliveryDate] =
    useState<IExpectedDelivery>({
      delivery_date_beginning: "",
      delivery_date_end: "",
    });

  const {
    data: batchesData,
    loading: batchesLoading,
    error: batchesError,
  } = useQuery(ORDERS_URL + `/${orderDetails?.id}/products-batches`);

  function handleButtonClick(productId: string) {
    const batchDatum = batchesData.find(
      (batchDatumInner: IBatchData[]) =>
        batchDatumInner[0]?.product_id === productId
    );

    if (batchDatum) {
      const batchInfo = batchDatum?.[0];
      const orderProduct = orderDetails.order_products?.find(
        (productData: any) =>
          batchInfo?.brand_name +
            batchInfo?.strength +
            batchInfo?.strength_unit ===
          productData.brand_name +
            productData?.product_strength +
            productData?.product_strength_unit
      );
      handleProductSelected(batchDatum, orderProduct);
    }
  }

  function onDateChange(name: string, value: string) {
    setExpectedDeliveryDate((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  }

  if (batchesLoading) return <PageSkeletonLoader />;

  if (batchesError)
    return (
      <ShipWrapper>
        <h3 className="error">
          {(batchesError as unknown as KeyValueObj)?.response?.data?.message}
        </h3>
      </ShipWrapper>
    );

  return (
    <ShipWrapper>
      <section>
        <H3>Batch Selection</H3>
        <Table
          tableHeaders={batchTableHeaders}
          tableKeys={batchTableKeys}
          removeFilters
          tableData={getBatchTableData(
            batchesData,
            handleButtonClick,
            productBatches
          )}
          spaced
          full
          rowLineColor="light"
        />
      </section>
      <section>
        <H3>Estimated Delivery Date</H3>
        <div className="flex-row space-between delivery-wrapper">
          <Input
            updateData={onDateChange}
            prompt={"Estimated Beginning Date"}
            name={"delivery_date_beginning"}
            inputType={"date"}
            updateValidity={() => {}}
          />
          <Input
            updateData={onDateChange}
            prompt={"Estimated End date"}
            name={"delivery_date_end"}
            inputType={"date"}
            updateValidity={() => {}}
          />
        </div>
      </section>

      <Button
        disabled={batchesData.length !== Object.keys(productBatches).length}
        onClick={() =>
          handleShipOrder(
            productBatches,
            formatExpectedDelivery(expectedDeliveryDate)
          )
        }
        loading={shipping}
      >
        Ship Order
      </Button>
    </ShipWrapper>
  );
}

function formatExpectedDelivery(expectedDelivery: IExpectedDelivery): string {
  return (
    expectedDelivery?.delivery_date_beginning.split("-").join("/") +
    "-" +
    expectedDelivery?.delivery_date_end.split("-").join("/")
  );
}

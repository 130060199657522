import styled from "styled-components";
import { LoadingSkeleton } from "../skeleton";
import { ReactNode } from "react";

interface IKeyWrapper {
  isEmail: boolean;
  row?: boolean;
}
const KeyValueWrapper = styled.div<IKeyWrapper>`
  width: 100%;
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  gap: 0.2rem;
  align-items: flex-start;
  display: inline-block;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: ${({ row }) => (row ? "row" : "column")};
    gap: 0.5rem;
  }
  .value {
    font-weight: 600;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text.prim_100};
    text-transform: ${({ isEmail }) => (isEmail ? "none" : "capitalize")};
    word-wrap: break-word;
  }

  .title {
    font-weight: 400;
    font-size: 0.85rem;
    color: ${({ theme }) => theme.text.prim_100};
    text-transform: capitalize;
    word-wrap: break-word;
  }
`;

export interface IKeyValueProps {
  title: string;
  value: string | ReactNode;
  loading?: boolean;
  row?: boolean;
}

export function KeyValue({ title, value, loading, row }: IKeyValueProps) {
  return (
    <KeyValueWrapper row={row} isEmail={title === "email" || title === "Email"}>
      {loading ? (
        <>
          <LoadingSkeleton />
          <LoadingSkeleton />
        </>
      ) : (
        <div className="wrapper">
          <h2 className="title">{title}</h2>
          {typeof value === "string" ? (
            <h3 className="value">{value}</h3>
          ) : (
            value
          )}
        </div>
      )}
    </KeyValueWrapper>
  );
}

import axios, { AxiosInstance } from "axios";
// const BASE_URL = "https://api-medex.ogtlprojects.com";

// const BASE_URL = "https://medex-development.ogtlprojects.com";
let BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// BASE_URL = "http://localhost:4000";

export const axiosInstance: any = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  // withCredentials: true,
});

export const axiosAuth: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  //withCredentials: true,
});

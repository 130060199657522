import {useContext} from 'react'
import AuthContext from '../context/AuthProvider'
import DashContext from '../context/DashboardProvider';
import StoreContext from 'src/context/GeneralProvider';

const useAuth = () => {
  return useContext(AuthContext);
}

export default useAuth;

export const useDashboardContext = () => {
  return useContext(DashContext)
}

export const useStore = () => {
  return useContext(StoreContext)
}


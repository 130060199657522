import { useEffect, useState } from "react";
import { Button } from "src/common/components/button";
import { PageSkeletonLoader } from "src/common/components/skeleton/page-skeleton";
import { COMPANY_URL } from "src/common/constants";
import { H3 } from "src/common/elements";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { useAuthDetails } from "src/hooks/useAuthDetails";
import styled from "styled-components";

const GeneralSettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 1rem 0;
  }

  .inventory-update-url {
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    width: 40rem;
    max-width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    label {
      color: ${({ theme }) => theme.text.prim_100};
      font-weight: 500;
      font-size: 0.9rem;
    }

    .controls {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;
    }

    input {
      width: 100%;
      border: 1px solid ${({ theme }) => theme.border.input};
      padding: 0.6rem 0.75rem;
      font-size: 0.95rem;
      border-radius: 0.25rem;

      :focus,
      :active {
        border: none;
        outline: 1.5px solid ${({ theme }) => theme.border.active_input};
        box-shadow: ${({ theme }) => theme.shadow.drag_over};
      }
    }
  }
`;

export interface ISettingsGeneralProps {
  existingNotificationUrl: string;
  loading: boolean;
}

export function SettingsGeneral({
  existingNotificationUrl,
  loading
}: ISettingsGeneralProps) {
  const makeRequest = useApiCallHandling();
  const { companyId } = useAuthDetails();

  const [urlValue, setURlValue] = useState("");
  const [updatingUrl, setUpdatingUrl] = useState(false);

  useEffect(() => {
    setURlValue(existingNotificationUrl);
  }, [existingNotificationUrl]);

  const handleSaveUrl = () => {
    setUpdatingUrl(true);
    const payload = { goods_received_notification_url: urlValue };

    makeRequest(
      COMPANY_URL + `/${companyId}/goods-received-notification`,
      "POST",
      payload,
      () => {},
      console.log,
      () => setUpdatingUrl(false)
    );
  };

  if(loading) return <PageSkeletonLoader />

  return (
    <GeneralSettingsWrapper>
      <section className="inventory-update-url">
        <H3 className="sub-title">Set Order Received Url</H3>

        <div className="inputs">
          <label htmlFor="urlInput">Input Url</label>
          <span className="controls">
            <input
              type="text"
              value={urlValue}
              onChange={(e) => setURlValue(e.target.value)}
            />
            <Button
              loading={updatingUrl}
              disabled={urlValue.length < 1 || updatingUrl}
              onClick={handleSaveUrl}
            >
              {existingNotificationUrl.length > 0 ? "Update" : "Save"}
            </Button> 
          </span>
        </div>
      </section>
    </GeneralSettingsWrapper>
  );
}

import styled from "styled-components";

export const MyDistributorWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0rem;

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .tabs{
    margin-top: 1rem;
  }

  .table-wrapper-1 {
    background-color: ${({ theme }) => theme.bg.prim_500};
    padding: 2rem;
  }

`;

export const ViewModalWrapper = styled.div`
  width: 50rem;
  padding: 2rem;
`;

export const OptionItem = styled.div`
  width: 24rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;

export const ExcelWrapper = styled.div`
  width: 35rem;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;

  .info {
    h3 {
      font-size: 0.875rem;
      font-weight: 400;
      text-align: center;

      color: ${({ theme }) => theme.text.input};
    }
  }

  .download-button {
    border: none;
    color: ${({ theme }) => theme.text.secondary};
    font-size: 1rem;
    background-color: transparent;
    display: flex;
    gap: 0.5rem;
    cursor: pointer;

    .download-icon {
      font-size: 1.5rem;
    }
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    position: relative;

    a {
      text-decoration: none;
    }

    .error-message,
    .file-name {
      position: absolute;
      top: 100%;
      font-size: 0.75rem;
      font-weight: 400;
      width: 100%;
      text-align: center;
      margin-top: 0.3rem;
    }

    .error-message {
      color: ${({ theme }) => theme.text.danger};
    }

    .file-name {
      color: ${({ theme }) => theme.text.life};
    }
  }

  .file-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    input[type="file"] {
      display: none;
    }

    label {
      border: 1px solid ${({ theme }) => theme.text.muted_200};
      display: inline-block;
      width: 100%;
      padding: 0.75rem 2.2rem;
      border-radius: 0.25rem;
      font-weight: 400;
      font-size: 0.875rem;
      text-align: center;
      cursor: pointer;
      color: ${({ theme }) => theme.text.muted_200};
    }
  }

  .response-messagees {
    width: 100%;
    padding-left: 3rem;

    p {
      font-weight: 500;
      font-size: 0.75rem;
      padding: 0.2rem;
    }
  }
`;

export const ExisitingDistributors = styled.div`
  width: 55rem;
  padding: 1rem;
  padding-bottom: 3rem;
  background-color: ${({ theme }) => theme.bg.prim_400};

  .add-distributor-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
  }
  .table-wrapper {
    margin-top: 1rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    padding: 2rem;
  }
`;

export const SingleDistributor = styled.div`
  width: 35rem;
  padding: 1rem 3rem;
  padding-bottom: 3rem;
`;

import { useParams } from "react-router";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { INVOICES_URL } from "../../../../../common/constants";
import useQuery from "src/hooks/useQuery";
import { RespondViewRfq } from "src/pages/dashboard/rfq/respond-rfq/respond-view-rfq";
import { KeyValueObj } from "src/common/types";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { InvoiceList } from "./invoice-list";
import { DataWrapper } from "src/common/components/dataWrapper";

export const InvoiceWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }
`;

export interface IInvoiceProps {}

export function Invoice(props: IInvoiceProps) {
  const params = useParams();
  const [invoiceData, setInvoiceData] = useState<KeyValueObj>({});
  const [subTotal, setSubTotal] = useState(0);
  const [showInvoiceList, setShowInvoiceList] = useState(false);
  const {
    data: fetchedInvoice,
    loading: invoiceLoading,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery(INVOICES_URL + "/" + params?.quotationId);

  useEffect(() => {
    let invoiceData: KeyValueObj = {};
    let rfqClone: KeyValueObj = {};
    rfqClone.owner = fetchedInvoice?.recipient;
    rfqClone.shipping_address = "";
    rfqClone.shippring_city = "";
    rfqClone.state = "";
    rfqClone.payment_term = fetchedInvoice?.payment_term;

    const invoiceDetails = {
      invoice_due_date: fetchedInvoice?.invoice_due_date,
      invoice_number: fetchedInvoice?.invoice_number,
      invoice_issue_date: fetchedInvoice?.invoice_issue_date,
      order_number: fetchedInvoice?.order_data?.order_number,
    };

    const subTotal = fetchedInvoice?.order_data?.products?.reduce(
      (acc: any, product: any) =>
        acc + product?.price_per_unit * product?.quantity,
      0
    );
    setSubTotal(subTotal);
    invoiceData.rfq = rfqClone;
    invoiceData.invoiceDetails = invoiceDetails;
    setInvoiceData(invoiceData);
  }, [fetchedInvoice]);

  function reloadInvoice() {
    refetchInvoice(INVOICES_URL + "/" + params?.quotationId);
  }

  return (
    <InvoiceWrapper>
      <PageTitleNavs
        title="View Invoice"
        backLink="/dashboard/invoices"
        bcrumbs={[
          { title: "Invoices", link: "/dashboard/invoices" },
          { title: "View", link: "#" },
        ]}
      />
      <DataWrapper
        loading={invoiceLoading}
        error={invoiceError}
        onFailAction={reloadInvoice}
      >
        <>
          {showInvoiceList ? (
            <InvoiceList
              outstandingAmount={fetchedInvoice?.outstanding_amount}
              totalAmount={fetchedInvoice?.total_amount}
              amountPaid={fetchedInvoice?.amount_paid}
              generatedInvoices={fetchedInvoice?.cbs_invoices}
              paymentTerm={fetchedInvoice?.payment_term}
              invoiceId={fetchedInvoice?.id}
              orderId={fetchedInvoice?.order_id}
              reloadInvoice = {reloadInvoice}
            />
          ) : (
            <RespondViewRfq
              company={fetchedInvoice?.sender}
              rfq={invoiceData?.rfq}
              logisticsCost={fetchedInvoice?.order_data?.delivery_charges}
              discount={fetchedInvoice?.order_data?.discount}
              tax={fetchedInvoice?.order_data?.tax}
              total={fetchedInvoice?.order_data?.total_amount}
              subTotal={subTotal}
              rowTotals={{}}
              terms={fetchedInvoice?.terms}
              isViewing
              quotation={fetchedInvoice?.order_data?.products}
              isInvoice
              title="Invoice"
              invoiceDetails={invoiceData?.invoiceDetails}
              handleSecondaryButton={() => {}}
              handleMainButton={() => setShowInvoiceList(true)}
            />
          )}
        </>
      </DataWrapper>
    </InvoiceWrapper>
  );
}

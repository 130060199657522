import styled from "styled-components";
import dispImg from "src/assets/images/empty-cart.png";
import searchImg from "src/assets/images/search.png";
import { H3 } from "src/common/elements";

export const RowWRapper = styled.tr`
  width: 100%;

  td {
    width: 100%;
    .wrapper {
      border-top: 1px solid ${({theme}) => theme.border.light};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      gap: 0.5rem;
    }
  }
`;

export interface IEmptyTableProps {
  colSize: number;
  isSearching: boolean;
}

export function EmptyTable({ colSize, isSearching }: IEmptyTableProps) {
  return (
    <RowWRapper>
      <td colSpan={colSize + 1}>
        <div className="wrapper">
          <img src={isSearching ? searchImg : dispImg} alt="Empty cart" />
          <H3>
            {isSearching
              ? "The search/filter term did not return any data"
              : "No data to show"}{" "}
          </H3>
        </div>
      </td>
    </RowWRapper>
  );
}

import ReactDOM from "react-dom";
import { useEffect, useRef } from "react";
import { ModalContent } from "./modal-content";

export interface IModalProps {
  isOpen: boolean;
  close?: () => void;
  children?: JSX.Element;
  title?: string;
  subTitle?: string;
}

const modalRoot = document.getElementById("modal") as HTMLElement;
const modalRoot2 = document.getElementById("modal-level2") as HTMLElement;

export function Modal({
  isOpen,
  close,
  children,
  title,
  subTitle,
}: IModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modalRef.current && modalRef.current.scrollTo(0, 0);
  }, [title]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalContent
      isOpen={isOpen}
      close={close}
      children={children}
      title={title}
      subTitle={subTitle}
    />,

    modalRoot
  );
}

export function ModalLevel2({
  isOpen,
  close,
  children,
  title,
  subTitle,
}: IModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modalRef.current && modalRef.current.scrollTo(0, 0);
  }, [title]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalContent
      isOpen={isOpen}
      close={close}
      children={children}
      title={title}
      subTitle={subTitle}
    />,

    modalRoot2
  );
}

import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { DataWrapper } from "../../../../common/components/dataWrapper";
import Form from "../../../../common/components/form/Form";
import { Modal } from "../../../../common/components/modal";
import { Preview } from "../../../../common/components/preview";
import { Button, H3 } from "../../../../common/elements";
import { ImageInput } from "./subs/ImageInput";
import {
  ModalFormWrapper,
  ModalImageWrapper,
  ModalPreviewWrapper,
  ModalWrapper,
} from "./subs/style";

import { RiImageAddFill } from "react-icons/ri";

import {
  FilesObject,
  KeyValueObj,
  SelectOptions,
} from "../../../../common/types";
import { sleep } from "../../../../common/utils";
import useAxiosCall from "../../../../hooks/useAxiosCall";
import { useAuthDetails } from "../../../../hooks/useAuthDetails";
import { importerProductForm, manufacturerProductForm } from "../Data";
import { useApiCallHandling } from "../../../../hooks/useApiCallHandling";
import {
  ADD_PRODUCT_URL,
  ATC_CODES_URL,
  GET_BRANDS_URL,
  PRODUCT_SETTINGS_URL,
  PRODUCT_URL,
} from "../../../../common/constants";
import { ClipSpinner } from "../../../../common/components/spinner";
import { ExistingImage } from "./subs/existing-image";
import { fitImageInSquareBox } from "src/common/utils/general";

export interface IProductFactoryProps {
  open: boolean;
  close: () => void;
  onSuccessfullMutation?: (data: KeyValueObj, done: boolean) => void;
  editMode?: boolean;
  product?: KeyValueObj;
  productType: string;
}

export function ProductFactory({
  open,
  close,
  onSuccessfullMutation,
  editMode,
  product,
  productType,
}: IProductFactoryProps) {
  const [retry, setRetry] = useState(0);
  const [actualForm, setActualForm] = useState<KeyValueObj[]>([]);
  const [files, setFiles] = useState<FilesObject>({});
  const [imageInputs, setImageInputs] = useState<string[]>([]);
  const [previewData, setPreviewData] = useState<KeyValueObj[]>([]);
  const [stage, setStage] = useState(1);
  const [modalTitle, setModalTitle] = useState("Product Details");
  const [modalSubtitle, setModalSubtitle] = useState(
    "Enter details of the product"
  );
  const [formValues, setFormValues] = useState<KeyValueObj>({});
  const [loading, setLoading] = useState(false);
  const [loadingInputs, setLoadingInputs] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<KeyValueObj>({});
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  const [getProductSettings, baseSetting, baseError, baseLoading] =
    useAxiosCall();
  const [getAtcCodes, atcData, , atcLoading] = useAxiosCall();

  // const {
  //   data: atcData,
  //   error: atcError,
  //   loading: atcLoading,
  //   meta: atcMeta,
  // } = useQuery(ATC_CODES_URL);

  // const {
  //   data: atcData,
  //   error: atcError,
  //   loading: atcLoading,
  //   meta: atcMeta,
  // } = useQuery(PRODUCT_SETTINGS_URL);

  const { companyType } = useAuthDetails();
  const apiCall = useApiCallHandling();

  async function onBrandSelected(
    latestInput: string,
    name: string,
    updateValidity: (name: string, validity: boolean) => void,
    setFormValues: Dispatch<SetStateAction<KeyValueObj>>
  ) {
    let tempLowerPart: any = [];

    if (latestInput === "Others(Specify)") {
      setActualForm((prev: KeyValueObj[]) => {
        const existingIndex = prev.findIndex(
          (formItem: KeyValueObj) =>
            formItem.inputType === "text" && formItem.name === "brand_name"
        );
        const index = prev.findIndex(
          (formItem: KeyValueObj) =>
            formItem.inputType === "selectInput" &&
            formItem.name === "brand_name"
        );

        const brandTextInput = {
          inputType: "text",
          prompt: "Product Brand",
          name: "brand_name",
          placeholder: "Type your product brand",
          fullWidth: false,
          shortWidth: true,
          rules: {
            minLength: {
              errorMessage: "should be at least 2 characters",
              expectedValue: 2,
            },
          },
        };

        if (existingIndex === -1) {
          prev.splice(index + 1, 0, brandTextInput);
          //The lower part is cut off so that the page can properly reload
          //the options
          tempLowerPart = prev.slice(2, prev.length);
          return prev.slice(0, 2);
        } else {
          prev.splice(existingIndex, 1, brandTextInput);
          //The lower part is cut off so that the page can properly reload
          //the options
          tempLowerPart = prev.slice(2, prev.length);
          return prev.slice(0, 2);
        }
      });

      //we need to make it invalid since a new thing now needs to be set
      updateValidity(name, false);
    } else {
      setActualForm((prev: any) => {
        const existingIndex = prev.findIndex(
          (formItem: KeyValueObj) =>
            formItem.inputType === "text" && formItem.name === "brand"
        );

        if (existingIndex !== -1) {
          prev.splice(existingIndex, 1);
          //The lower part is cut off so that the page can properly reload
          //the options
          tempLowerPart = prev.slice(2, prev.length);
          return prev.slice(0, 2);
        }
        return prev.slice();
      });
    }

    //Wait for few miliseconds
    setLoadingInputs(true);
    await sleep(200);

    //put back the onces cut off
    setActualForm((prev) => {
      return [...prev, ...tempLowerPart];
    });
    setLoadingInputs(false);
  }

  async function onATCSelected(
    latestInput: string,
    name: string,
    updateValidity: (name: string, validity: boolean) => void,
    setFormValues: Dispatch<SetStateAction<KeyValueObj>>
  ) {
    //replace the options of brand with a loading state
    setActualForm((prev: KeyValueObj[]) => {
      const loadingInput = {
        inputType: "text",
        prompt: "Product Brand",
        name: "brand_name",
        options_name: "product_brand",
        readOnly: true,
        fullWidth: false,
        shortWidth: true,
        placeholder: "existing brands loading...",
        loadingState: true,
      };

      prev.splice(1, 1, loadingInput);
      return prev;
    });

    await apiCall(
      GET_BRANDS_URL + "?product_type=drug&atc_code_id=" + latestInput,
      "GET",
      {},
      (data: any) => {
        //replace now with actual options
        const existingBrand: KeyValueObj[] = [];
        data?.forEach((datum: KeyValueObj) => {
          existingBrand.push({
            id: datum.brand_name,
            title: datum.brand_name,
          });
        });
        setActualForm((prev: KeyValueObj[]) => {
          const index = prev.findIndex(
            (formItem: KeyValueObj) =>
              formItem.readOnly === true && formItem.name === "brand_name"
          );
          const brandInput = {
            prompt: "Select a brand",
            placeholder: "Select a brand from already existing list",
            inputType: "selectInput",
            name: "brand_name",
            options: [
              ...existingBrand,
              { id: 566576556, title: "Others(Specify)" },
            ],
            fullWidth: false,
            shortWidth: true,
            onEveryChange: onBrandSelected,
          };

          prev.splice(index, 1, brandInput);

          return prev.slice();
        });
      },
      (err: any) => {
        //replace now with actual options
        setActualForm((prev: KeyValueObj[]) => {
          const index = prev.findIndex(
            (formItem: KeyValueObj) =>
              formItem.readOnly === true && formItem.name === "brand"
          );
          const brandInput = {
            inputType: "text",
            prompt: "Product Brand",
            name: "brand_name",
            options_name: "product_brand",
            placeholder: "Select ATC code first",
            readOnly: true,
            fullWidth: false,
            shortWidth: true,
          };

          prev.splice(index, 1, brandInput);

          return prev.slice();
        });

        console.log(err);
      },
      () => {}
    );
  }

  function onPackagingSelected(
    latestInput: string,
    name: string,
    updateValidity: (name: string, validity: boolean) => void,
    setFormValues: Dispatch<SetStateAction<KeyValueObj>>,
    formValues: KeyValueObj
  ) {
    const packagingMap: KeyValueObj = {
      primary_packaging: "form_quantity",
      secondary_packaging: "primary_package_quantity_per_secondary_package",
      tertiary_packaging: "secondary_package_quantity_per_tertiary_package",
    };
    const messageMap: KeyValueObj = {
      primary_packaging: `Number of units in one ${latestInput}`,
      secondary_packaging: `Number of ${formValues.primary_packaging}(s) in one ${latestInput}`,
      tertiary_packaging: `Number of ${formValues.secondary_packaging}(s) in one ${latestInput}`,
    };

    setActualForm((prev: KeyValueObj[]) => {
      const copy = prev.map((formItem: KeyValueObj) => {
        if (formItem.name === packagingMap[name]) {
          formItem.prompt = messageMap[name];
        }
        return formItem;
      });

      return copy;
    });
  }

  useEffect(() => {
    getProductSettings({ url: PRODUCT_SETTINGS_URL, method: "GET" });
    getAtcCodes({ url: ATC_CODES_URL, method: "GET" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry]);

  //handling forms setup
  useEffect(() => {
    if (companyType && baseSetting.length) {
      const settings = baseSetting[0].values;

      let productForm:
        | typeof manufacturerProductForm
        | typeof importerProductForm = [];

      if (companyType?.toLowerCase() === "manufacturer") {
        productForm = manufacturerProductForm;
      } else if (companyType?.toLowerCase() === "importer") {
        productForm = importerProductForm;
      }

      const modifiedForm = productForm.map((formItem: KeyValueObj) => {
        //check if any of the form option_name match with one in the base settings,
        //if true, then replace the options with the one at baseSetting
        if (
          formItem?.inputType === "selectInput" &&
          settings[formItem.options_name]
        ) {
          // modify the content based on the base settings
          let options: SelectOptions[] = [];

          settings[formItem.options_name]?.forEach(
            (item: string, i: number) => {
              options.push({ id: i.toString(), title: item });
            }
          );
          const tempFormItem = { ...formItem };
          tempFormItem.options = options;
          return tempFormItem;

          //find the ATC codes and pass it onEveryChange function that will now fetch
          //the brands that alreay exist
        } else if (
          formItem?.inputType === "selectInput" &&
          formItem?.name === "atc_code_id"
        ) {
          const tempFormItem = { ...formItem };
          tempFormItem.onEveryChange = onATCSelected;
          tempFormItem.options = atcData;
          return tempFormItem;

          //check for the packaging type and  pass it the fuction that will change it's coresponding
          //input promt
        } else if (
          formItem.name === "primary_packaging" ||
          formItem.name === "secondary_packaging" ||
          formItem.name === "tertiary_packaging"
        ) {
          const tempFormItem = { ...formItem };
          tempFormItem.onEveryChange = onPackagingSelected;
          return tempFormItem;
        } else {
          return formItem;
        }
      });

      //Update the form with initial data if it is in edit mode
      if (editMode && product) {
        const editForm = modifiedForm.map((formItem: KeyValueObj) => {
          //set initial values for the ones that will be read only
          if (formItem.name === "brand" || formItem.name === "atc_code") {
            return {
              ...formItem,
              inputType: "text",
              readOnly: true,
              initialValue: product[formItem.name],
            };
          } else {
            return {
              ...formItem,
              initialValue: product[formItem.name],
            };
          }
        });
        setActualForm(editForm);
      } else {
        setActualForm(modifiedForm);
      }
    }

    //set the product in a state so that we can update it when it is changed
    product && setCurrentProduct(product);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseSetting, baseError, baseLoading, companyType, product, atcData]);

  const onFormContinue = (formValues: KeyValueObj) => {
    setFormValues({
      ...formValues,
      name: formValues?.brand_name,
    });
    setStage(2);
    setModalTitle("Product image upload");
    setModalSubtitle("Upload clear images of the product");
  };

  const onImageContinue = async () => {
    const formPreviewData: KeyValueObj[] = [];
    Object.keys(formValues).forEach((formKey: any) => {
      formPreviewData.push({
        name: formKey,
        value: formValues[formKey],
      });
    });

    const imagePreviewData = await Promise.all(
      Object.keys(files).map(async (formKey: string, i: number) => {
        const formattedImage = await fitImageInSquareBox(files[formKey], 600);
        setFiles((prev: any) => ({ ...prev, [formKey]: formattedImage }));
        return {
          name: "New Product Image " + (i + 1),
          value: formattedImage,
        };
      })
    );

    const oldImageData: KeyValueObj[] = [];
    if (editMode) {
      currentProduct?.images?.forEach((image: KeyValueObj, i: number) => {
        oldImageData.push({
          type: "imageUrl",
          name: "Existing Image " + (i + 1),
          value: image.url,
        });
      });
    }

    setPreviewData([...formPreviewData, ...oldImageData, ...imagePreviewData]);

    console.log({ imagePreviewData });
    setModalTitle("Review your product");
    setModalSubtitle("");
    setStage(3);
  };

  const onPreviewBack = () => {
    setModalTitle("Details of item");
    setModalSubtitle("Enter details of the product you are trying to ship");
    setStage(2);
  };

  const onPreviewSubmit = async (
    formValues: KeyValueObj,
    files: KeyValueObj
  ) => {
    setLoading(true);
    const formData = new FormData();

    //add form values
    Object.keys(formValues).forEach((formKey: string) => {
      formData.append(formKey, formValues[formKey]);
    });

    //add image values
    Object.keys(files).forEach((fileKey: string) => {
      formData.append("images[]", files[fileKey]);
    });

    //add the product type
    formData.append("product_type", productType);

    const url = editMode
      ? ADD_PRODUCT_URL + "/" + currentProduct?.id
      : ADD_PRODUCT_URL;

    await apiCall(
      url,
      editMode ? "PUT" : "POST",
      formData,
      (data: any) => {
        setFormValues({});
        setFiles({});
        setStage(1);
        onSuccessfullMutation && onSuccessfullMutation(data, true);
      },
      (err: any) => {
        console.log(err);
      },
      () => {
        setLoading(false);
      }
    );
  };

  function hadleFileCancel(e: any, fileName: string) {
    setImageInputs((prev: any) => {
      const temp = [...prev];
      temp.splice(temp.indexOf(fileName), 1);

      return temp;
    });
    delete files[fileName];
  }

  function deleteProductImage(imageId: string) {
    setCurrentImageUrl(imageId);
    apiCall(
      `${PRODUCT_URL}/${product?.id}/images/${imageId}`,
      "DELETE",
      {},
      (data: any) => {
        const copy = { ...currentProduct };
        const copyImage = [...copy.images];
        copy.images = copyImage.filter(
          (image: KeyValueObj) => image?.id !== imageId
        );
        setCurrentProduct(copy);
        onSuccessfullMutation && onSuccessfullMutation(copy, false);
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        setCurrentImageUrl("");
      }
    );
  }


  const handleAddMore = () =>
    setImageInputs((prev) => [...prev, "image_" + new Date().getTime()]);

  return (
    <Modal
      title={modalTitle}
      subTitle={modalSubtitle}
      isOpen={open}
      close={close}
    >
      <ModalWrapper stage={stage}>
        <ModalFormWrapper stage={stage}>
          <DataWrapper
            loading={baseLoading || atcLoading}
            error={baseError}
            failedMessage="Error loading form data. Click the button below to retry"
            loadingMessage="Loading form data..."
            onFailAction={() => setRetry((prev) => prev + 1)}
          >
            <Form
              loadingState={loadingInputs}
              submitLabel={"Continue"}
              formInputs={actualForm}
              processInputs={onFormContinue}
              spinnerComponent={<ClipSpinner />}
              login={false}
              biControl={false}
              handleBack={undefined}
            />
          </DataWrapper>
        </ModalFormWrapper>
        <ModalImageWrapper stage={stage}>
          {editMode && currentProduct?.images?.length > 0 && (
            <div className="existing-images-wrapper">
              <div className="text-prompt">
                <H3>Existing Images</H3>
              </div>
              <div className="existing-images">
                {currentProduct?.images?.map((image: KeyValueObj) => (
                  <ExistingImage
                    key={image?.attachment_data?.id}
                    deleteImage={deleteProductImage}
                    imageUrl={image?.url}
                    imageId={image?.id}
                    currentImageUrl={currentImageUrl}
                    canDelete={currentProduct?.images?.length > 1}
                  />
                ))}
              </div>
              {/* <div className="delete-all">
                <Button
                  onClick={deleteAllProductImages}
                  secondary
                  borderColor="danger"
                  color="danger"
                  disabled={currentImageUrl === "all"}
                >
                  {currentImageUrl === "all" ? (
                    <ClipSpinner />
                  ) : (
                    "Delete All Images"
                  )}
                </Button>
              </div> */}
            </div>
          )}
          <div className="image-inputs">
            <div className="add-more">
              <Button secondary onClick={handleAddMore}>
                {" "}
                <RiImageAddFill className="addmore-icon" /> Add more
              </Button>
            </div>
            <ImageInput name={"default1"} setFiles={setFiles} files={files} />
            <ImageInput name={"default2"} setFiles={setFiles} files={files} />
            {/* ADDED Images inputs */}
            {imageInputs?.map((inputName: string, i: number) => (
              <ImageInput
                key={i}
                name={inputName}
                setFiles={setFiles}
                files={files}
                cancelable={true}
                onFileCancel={hadleFileCancel}
              />
            ))}

            <div className="image-controls flex-row">
              <Button secondary onClick={() => setStage(1)}>
                Back
              </Button>
              <Button
                disabled={editMode ? false : Object.keys(files).length < 2}
                onClick={onImageContinue}
              >
                Continue
              </Button>
            </div>
          </div>
        </ModalImageWrapper>
        <ModalPreviewWrapper stage={stage}>
          <Preview
            data={previewData}
            biControl
            onBack={onPreviewBack}
            loading={loading}
            onSubmit={() => onPreviewSubmit(formValues, files)}
          />
        </ModalPreviewWrapper>
      </ModalWrapper>
    </Modal>
  );
}

import styled from "styled-components";
import { PageContainr } from "../../../../common/elements";

export const DashboardWrapper = styled(PageContainr)`
  margin-top: 0;
  align-items: flex-start;
  flex-direction: row;
  z-index: 1;

  .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99998;
    opacity: 0.75;

  }

  .dashboard-main {
    width: 100%;
    background-color: ${({ theme }) => theme.bg.prim_400};
    min-height: 100vh;
    padding-left: 12.5rem;
    justify-content: flex-start;

    @media (max-width: 1024px) {
      padding-left: 4.5rem;
    }

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding-left: 0;
    }

    .dashboard-pages {
      width: 100%;
    }
  }
`;

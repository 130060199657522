import styled from "styled-components";
import { PageContainr } from "../../../common/elements/containers";
interface StyleProps {
  stage: number;
  page: number;
}

export const TinWrapper: any = styled.div`
  width: 100%;
  .tin-input {
    gap: 3rem;
    width: 100%;

    .tin-input-wrapper {
      gap: 1.5rem;
      align-items: center;
      position: relative;

      label {
        font-size: 1rem;
        color: ${({ theme }) => theme.text.input};
        font-weight: 500;
      }

      input {
        width: 100%;
        font-size: 1.2rem;
        font-weight: 400;
        outline: none;
        border: 1px solid ${({ theme }) => theme.border.input};
        color: ${({ theme }) => theme.text.input};
        padding: 0.75rem;
        border-radius: 0.25rem;
      }

      .tin-error {
        color: red;
        font-weight: 400;
        position: absolute;
        top: 100%;
        font-size: 0.85rem;
      }
    }
  }
`;
export const CompleteWrapper = styled(PageContainr)<StyleProps>`
  padding: 1.5rem 2vw;
  position: relative;
  min-height: 100vh;

  @media screen and (max-width: ${({ theme }) => theme.screen.smallScreen}) {
    margin-top: 4.5rem;
  }

  .progress-wrapper {
    max-width: 70rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 4rem;

    @media (max-width: ${({ theme }) => theme.screen.tablet}) {
      font-size: 1.1rem;
      padding: 1rem 0;
    }

    h3 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.875rem;
      @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
        font-size: 1.5rem;
        padding-left: 2rem;
      }
    }

    .progress {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 2rem 0.2rem;

      @media (max-width: ${({ theme }) => theme.screen.tablet}) {
        padding: 1rem;
      }

      .one,
      .two,
      .three,
      .four {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.bg.sec_200};
        color: ${({ theme }) => theme.text.prim_500};
        font-size: 0.9rem;

        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          width: 1.5rem;
          height: 1.5rem;
          font-size: 0.8rem;
        }
      }

      .two {
        background-color: ${({ theme, stage }) =>
          stage > 1 ? theme.text.sec_200 : theme.bg.tertiary};
      }

      .three {
        background-color: ${({ theme, stage }) =>
          stage > 2 ? theme.text.sec_200 : theme.bg.tertiary};
      }

      .four {
        background-color: ${({ theme, stage }) =>
          stage > 3 ? theme.text.sec_200 : theme.bg.tertiary};
      }

      .first-link,
      .middle-link,
      .last-link {
        background-color: ${({ theme }) => theme.bg.sec_200};
        height: 2px;
        flex-grow: 1;
      }

      .first-link {
        background-color: ${({ theme, stage }) =>
          stage > 1 ? theme.text.sec_200 : theme.bg.tertiary};
      }

      .middle-link {
        background-color: ${({ theme, stage }) =>
          stage > 2 ? theme.text.sec_200 : theme.bg.tertiary};
      }

      .last-link {
        background-color: ${({ theme, stage }) =>
          stage > 3 ? theme.text.sec_200 : theme.bg.tertiary};
      }
    }
  }

  .progress-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.text.muted_200};

      @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
        font-size: 0.9rem;
      }
    }
  }

  .file-input-wrapper {
    width: 100%;
    top: 7.5rem;
    padding: 1rem 8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 1rem;
    }

    .file-progress {
      width: 40rem;
      max-width: 65rem;
      margin: 0;

      h3 {
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 1.375rem;
      }
    }

    .file-select-wrapper {
      width: 40rem;
      max-width: 90%;
      margin-top: 1.5rem;

      .desc {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 0rem;
        border: 1px solid red;
        h3 {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5625rem;
          text-align: left;
        }

        h4 {
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 1.5625rem;
          margin-top: 1rem;
        }
      }

      .files-component-wrapper {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .more-form {
          width: 100%;
          height: 100%;
          flex-direction: column;
          padding: 0.5rem;
          padding-right: 1.5rem;
          position: relative;

          .input-control-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 1rem;
            gap: 4rem;

            @media (max-width: ${({ theme }) => theme.screen.mobile}) {
              gap: 2rem;
            }
          }

          .input-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 0.5rem;

            label {
              font-size: 0.9rem;
              font-weight: 400;
              color: ${({ theme }) => theme.text.muted_200};
            }

            input {
              border: none;
              border: 1px solid ${({ theme }) => theme.border.input};
              background-color: transparent;
              outline: none;
              font-size: 0.9rem;
              font-weight: 400;
              padding: 0.3rem;
              border-radius: 2px;
              color: ${({ theme }) => theme.text.muted_300};

              :focus {
                box-shadow: ${({ theme }) => theme.shadow.drag_over};
              }
            }
          }

          button {
            border: 1px solid ${({ theme }) => theme.bg.progress};
            color: ${({ theme }) => theme.bg.progress};
            border-radius: 2px;
            padding: 0.5rem 2rem;
            cursor: pointer;
          }

          button:disabled,
          button[disabled] {
            background-color: transparent;
            border: 1px solid grey;
            color: grey;
            cursor: not-allowed;
          }

          .error-wrapper {
            position: absolute;
            width: 100%;
            top: 100%;
            background-color: white;
            z-index: 4;
            padding-left: 2rem;
            padding-bottom: 2rem;

            p {
              color: red;
              font-size: 0.9rem;
            }
          }
        }
      }

      .input-add-more {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 0.9375rem;
          color: ${({ theme }) => theme.text.muted_200};
        }
      }

      .form-controls {
        justify-content: space-between;
        margin-top: 5.5rem;
        width: 100%;

        .btn-back,
        .btn-preview {
          width: 9rem;
          height: 2.8rem;
          font-weight: 400;
          font-size: 1rem;
          border-radius: 0.25rem;
          color: ${({ theme }) => theme.bg.primary};
          border: 1px solid ${({ theme }) => theme.bg.primary};
          background-color: transparent;
        }

        button.btn-preview {
          background-color: ${({ theme }) => theme.bg.primary};
          color: ${({ theme }) => theme.text.prim_500};

          :disabled {
            background-color: ${({ theme }) => theme.bg.btn_disabled};
            cursor: not-allowed;
            border: none;
          }
        }
      }
    }
  }

  /* .form-container {
    width: 100%;
    padding: 1rem;
    position: absolute;
    top: 11rem;
    transition: all 0.5s ease-in-out;
    @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
      padding: 1rem 3vw 20rem;
    }
  } */

  .stages-wrapper {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    align-items: flex-start;
  }

  .form-container {
    width: 100%;
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    translate: ${({page}) => -100 * (page - 1)}%;
    @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
      padding: 1rem 3vw;
    }
  }

  .switch-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`;

export const FileGeneralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40rem;
  max-width: 90%;
  margin-top: 1.5rem;

  .desc {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 0rem;
    h3 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5625rem;
      text-align: left;
    }

    h4 {
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.5625rem;
      margin-top: 1rem;
    }
  }

  .files-component-wrapper {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .more-form {
      width: 100%;
      height: 100%;
      flex-direction: column;
      padding: 0.5rem;
      padding-right: 1.5rem;
      position: relative;

      .input-control-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1rem;
        gap: 4rem;

        @media (max-width: ${({ theme }) => theme.screen.mobile}) {
          gap: 2rem;
        }
      }

      .input-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.5rem;

        label {
          font-size: 0.9rem;
          font-weight: 400;
          color: ${({ theme }) => theme.text.muted_200};
        }

        input {
          border: none;
          border: 1px solid ${({ theme }) => theme.border.input};
          background-color: transparent;
          outline: none;
          font-size: 0.9rem;
          font-weight: 400;
          padding: 0.3rem;
          border-radius: 2px;
          color: ${({ theme }) => theme.text.muted_300};

          :focus {
            box-shadow: ${({ theme }) => theme.shadow.drag_over};
          }
        }
      }

      button {
        border: 1px solid ${({ theme }) => theme.bg.progress};
        color: ${({ theme }) => theme.bg.progress};
        border-radius: 2px;
        padding: 0.5rem 2rem;
        cursor: pointer;
      }

      button:disabled,
      button[disabled] {
        background-color: transparent;
        border: 1px solid grey;
        color: grey;
        cursor: not-allowed;
      }

      .error-wrapper {
        position: absolute;
        width: 100%;
        top: 100%;
        background-color: white;
        z-index: 4;
        padding-left: 2rem;
        padding-bottom: 2rem;

        p {
          color: red;
          font-size: 0.9rem;
        }
      }
    }
  }

  .input-add-more {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 0.9375rem;
      color: ${({ theme }) => theme.text.muted_200};
    }
  }

  .form-controls {
    justify-content: space-between;
    margin-top: 5.5rem;
    width: 100%;

    .btn-back,
    .btn-preview {
      width: 9rem;
      height: 2.8rem;
      font-weight: 400;
      font-size: 1rem;
      border-radius: 0.25rem;
      color: ${({ theme }) => theme.bg.primary};
      border: 1px solid ${({ theme }) => theme.bg.primary};
      background-color: transparent;
    }

    button.btn-preview {
      background-color: ${({ theme }) => theme.bg.primary};
      color: ${({ theme }) => theme.text.prim_500};

      :disabled {
        background-color: ${({ theme }) => theme.bg.btn_disabled};
        cursor: not-allowed;
        border: none;
      }
    }
  }
`;

interface ProgressProps {
  percent: number;
}

export const ProgressBox = styled.div<ProgressProps>`
  width: 11.6875rem;
  height: 0.75rem;
  margin-top: 0.9375rem;
  background-color: ${({ theme }) => theme.bg.tert_300};
  border-radius: 0.125rem;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: all 0.5s ease-in;
    width: ${({ percent }) => percent + "%"};
    border-radius: 0.125rem;
    background-color: ${({ theme }) => theme.bg.sec_200};
  }
`;

export const ModalPreviewWrapper = styled.div`
  width: 25rem;
  max-width: 25rem;
  padding: 2rem;
  border-radius: 0.25rem;
`;

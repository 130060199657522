import Form from "src/common/components/form/Form";
import styled from "styled-components";
import { useState, Dispatch, SetStateAction } from "react";
import { KeyValueObj } from "src/common/types";
import {
  getDrugForm,
  getProductInfoForm,
  getProductTableData,
  packagingOptions,
  productTableHeaders,
  productTableKeys,
} from "./data";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import {
  ATC_CODES_URL,
  BRAND_STRENGTHS_URL,
  GET_BRANDS_URL,
} from "src/common/constants";
import { IOption } from "src/common/types/others";
import Table from "src/common/components/Table";
import { Button } from "src/common/components/button";

const ProductInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0;
  width: 52rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
  
  }

  .form-wrapper {
    width: 50rem;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
    background-color: ${({ theme }) => theme.bg.prim_500};

  }

  .products-wrapper {
    border-top: 1px solid ${({ theme }) => theme.border.light};
    padding-top: 2rem;
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export interface IProductInformationProps {
  addProduct: (values: KeyValueObj) => void;
  addedProducts: KeyValueObj[];
  removeProduct: (row: KeyValueObj) => void;
  handleProductInfoContinue: () => void;
}

export function ProductInformation({
  addProduct,
  addedProducts,
  removeProduct,
  handleProductInfoContinue,
}: IProductInformationProps) {
  const makeRequest = useApiCallHandling();

  const [formInputs, setFormInputs] = useState<KeyValueObj>(
    getProductInfoForm(onEveryChange)
  );

  function onEveryChange(
    latestInput: string,
    name: string,
    updateValidity: (name: string, validity: boolean) => void,
    setFormValues: Dispatch<SetStateAction<KeyValueObj>>,
    fomrValues?: KeyValueObj
  ) {
    switch (name) {
      case "item_type":
        handleProductTypeSelected(latestInput);
        break;
      case "atc_code_id":
        handleAtcCodeSelected(latestInput);
        break;

      case "brand_name":
        handleBrandNameSelected(latestInput);
        break;
      case "product_strength":
        handleProductStrengthSelected(latestInput);
        break;
    }
  }

  function setInputToLoading(name: string, title: string) {
    setFormInputs((prev: KeyValueObj[]) => {
      const copy = [...prev];
      let index = copy.findIndex((form: KeyValueObj) => form.name === name);
      if (index !== -1) {
        const newAtcInput = {
          ...copy?.[index],
          inputType: "text",
          placeholder: `Loading ${title}...`,
          loadingState: true,
        };

        copy[index] = newAtcInput;
        return copy;
      }
    });
  }

  function updateInputOptions(
    name: string,
    options: IOption[] | any,
    placeholder: string
  ) {
    setFormInputs((prev: KeyValueObj[]) => {
      const copy = [...prev];
      let index = copy.findIndex((form: KeyValueObj) => form.name === name);
      if (index !== -1) {
        const newAtcInput = {
          ...copy?.[index],
          inputType: "selectInput",
          placeholder: placeholder,
          loadingState: false,
          readOnly: false,
          options: options,
        };

        copy[index] = newAtcInput;
        return copy;
      }
    });
  }

  function enableInput(name: string) {
    setFormInputs((prev: KeyValueObj[]) => {
      const copy = [...prev];
      let index = copy.findIndex((form: KeyValueObj) => form.name === name);
      if (index !== -1) {
        const newAtcInput = {
          ...copy?.[index],
          loadingState: false,
          readOnly: false,
        };

        copy[index] = newAtcInput;
        return copy;
      }
    });
  }

  function handleProductStrengthSelected(selectedValue: string) {
    updateInputOptions(
      "package_type",
      packagingOptions,
      "Select Packaging Type"
    );
    enableInput("quantity");
  }

  function handleStrengthsFetched(data: any) {
    const options = data.map((datum: KeyValueObj) => ({
      id: datum?.strength + datum?.strength_unit + ` (${datum.form})`,
      title: datum?.strength + datum?.strength_unit + ` (${datum.form})`,
    }));

    updateInputOptions(
      "product_strength",
      options,
      "Select the product strength and form"
    );
  }

  function handleBrandNameSelected(selectedValue: string) {
    setInputToLoading("product_strength", "Drug Strength");
    makeRequest(
      BRAND_STRENGTHS_URL + "?product_type=drug&brand_name=" + selectedValue,
      "GET",
      {},
      handleStrengthsFetched,
      () => {},
      () => {}
    );
  }

  function handleBrandsFetched(data: any) {
    const options: any = [];
    data.map((datum: KeyValueObj) =>
      options.push({ id: datum.brand_name, title: datum.brand_name })
    );
    updateInputOptions("brand_name", options, "Select brand name");
  }

  function handleAtcCodeSelected(selectedInput: string) {
    setInputToLoading("brand_name", "Brand Names");
    makeRequest(
      GET_BRANDS_URL + "?product_type=drug&atc_code_id=" + selectedInput,
      "GET",
      {},
      handleBrandsFetched,
      () => {},
      () => {}
    );
  }

  function handleProductTypeSelected(selectedValue: string) {
    setFormInputs((prev: KeyValueObj[]) => [prev[0]]);
    if (selectedValue?.toLowerCase() === "drug") {
      setFormInputs((prev: KeyValueObj[]) => [
        ...prev,
        ...getDrugForm(onEveryChange),
      ]);
      makeRequest(
        ATC_CODES_URL + "?scope=platform-products",
        "GET",
        {},
        (data) =>
          updateInputOptions(
            "atc_code_id",
            data,
            "Enter upto 3 characters to search"
          ),
        () => {},
        () => {}
      );
    }
  }

  return (
    <ProductInfoWrapper>
      <div className="form-wrapper">
        <Form
          formInputs={formInputs}
          processInputs={addProduct}
          submitLabel="Add Product"
          submitButtonType="secondary"
          removePadding 
        />
        <div className="products-wrapper">
          <Table
            tableHeaders={productTableHeaders}
            tableData={getProductTableData(addedProducts)}
            tableKeys={productTableKeys}
            tableTitle="Added Products"
            rowLineColor="light"
            showDropDown={true}
            full
            dropDownOptions={[{ label: "Remove", action: removeProduct }]}
          />
        </div>
        <div className="controls">
          <Button
            disabled={addedProducts.length <= 0}
            onClick={handleProductInfoContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </ProductInfoWrapper>
  );
}

import { BsChevronDown } from "react-icons/bs";

import profileImage from "src/assets/images/generic_face.png";
import styled from "styled-components";
import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { usePositioning } from "../../../../hooks/usePositioning";
import { LightBackdrop } from "../../../../common/components/lightBackdrop";
import { PopupWrapper } from "../../../../common/elements/containers";
import { ProfileCard } from "./ProfileCard";
import { useAuthDetails } from "../../../../hooks/useAuthDetails";
import chatIcon from "src/assets/images/Chat.png";
import notificationIcon from "src/assets/images/Notification.png";

import { ModalWrapper } from "src/common/elements";
import Form from "src/common/components/form/Form";
import { Button } from "src/common/components/button";
import { ClipSpinner } from "src/common/components/spinner";
import { BASE_SETTINGS_URL, COMPANY_URL, USERS } from "src/common/constants";
import { KeyValueObj } from "src/common/types";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { Modal } from "src/common/components/modal";
import {
  getCompanyAddressUpdateForm,
  getContactUpdateForm,
  getDistributorsCompanyForm,
  getManuImporterUpdateForm,
  getProfileEditForm,
  getRetailerUpdateForm,
} from "./data";
import useQuery from "src/hooks/useQuery";
import { FileUpdate } from "./files";
import { Prompt } from "src/common/components/prompt";
import { RxHamburgerMenu } from "react-icons/rx";



const DashboardNavWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 0.5rem 2rem;
  box-shadow: ${({ theme }) => theme.shadow.shad_100};

  .greeting {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.35rem;
    margin-right: auto;

    .main-greet {
      color: ${({ theme }) => theme.text.prim_100};
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: capitalize;
    }

    .minor-greet {
      color: ${({ theme }) => theme.text.prim_200};
      font-size: 0.8rem;
      font-weight: 400;
    }

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      display: none;
    }
  }



  .hamburger-icon{
    font-size: 1.5rem;

    display: none;

    @media (max-width: ${({theme}) => theme.screen.mobile}) {
      display: block;
    }
  }

  .search-wrapper {
    justify-content: flex-start;
    gap: 2rem;
    width: auto;
    padding-right: 3rem;
  }

  .search-icon {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.text.prim_200};
  }

  .search-filter {
    border: 1px solid ${({ theme }) => theme.border.input};
    padding: 0.5rem 1rem;
    gap: 1.1rem;
    color: ${({ theme }) => theme.text.muted_200};
    border-radius: 0.25rem;
    cursor: pointer;

    h3 {
      font-size: 1.1rem;
      font-weight: 400;
    }

    .filter-icon {
      font-size: 1.3rem;
      transform: rotate(90deg);
    }
  }

  .dash-profile {
    gap: 2.6875rem;

    .bell-icon {
      color: ${({ theme }) => theme.text.primary};
      font-size: 2.125rem;
    }

    .profile-image {
      gap: 1.3125rem;
      cursor: pointer;
      img {
        width: 2.5rem;
        border-radius: 50%;
      }
    }
  }

  .status-notification {
    width: 15rem;
    margin-right: 1rem;

    button {
      font-size: 0.8rem;
      font-weight: 500;
      background-color: #fef6e6;
      padding: 0.3rem 0.4rem;
      border-radius: 0.2rem;
      text-align: center;
      color: orange;
      cursor: pointer;
      outline: none;
      border: none;
    }
  }

  .status-notification.decline {
    button {
      color: #f84a37;
      background-color: #ffe4e1;
    }
  }
`;

export interface IDashboardNavProps {
  setShowSidebar: Dispatch<SetStateAction<boolean>>
}

export function DashboardNav({setShowSidebar}: IDashboardNavProps) {
  const [showProfile, setShowProfile] = useState(false);

  //hookes
  const [setPosition, position1] = usePositioning();

  function onProfileClick(e: MouseEvent<HTMLElement>) {
    setPosition(e);
    setShowProfile(!showProfile);
  }

  const greeting = (name: String) => {
    let hourOfDay = new Date().getHours();
    let message = "Good ";
    let timeOfDay =
      hourOfDay >= 4 && hourOfDay <= 11
        ? "Morning"
        : hourOfDay >= 12 && hourOfDay <= 16
        ? "Afternoon"
        : hourOfDay >= 17 && hourOfDay <= 23
        ? "Evening"
        : "Day";

    return message + timeOfDay + ` ${name}`;
  };

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<KeyValueObj[]>([]);
  const [updateUrl, setUpdateUrl] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showApprovalStatusModal, setShowApprovalStatusModal] = useState(false);
  const [approvalNotificationTitle, setApprovalNotificationTitle] =
    useState("");
  const [approvalNotificationText, setApprovalNotificationText] = useState("");

  const {
    firstName,
    lastName,
    address,
    middleName,
    userId,
    email,
    phone,
    role,
    companyName,
    companyId,
    companyType,
    companyApprovalStatus,
    companyApprovalRemark,
  } = useAuthDetails();
  const apiCall = useApiCallHandling();
  const {
    data: companyData,
    // loading: companyDataLoading,
    // error: CompanyDataError,
    // meta: companyDataMeta,
  } = useQuery(COMPANY_URL + "/" + companyId);
  const {
    data: baseData,
    // loading: baseLoading,
    // error: baseError,
    // meta: baseMeta,
  } = useQuery(BASE_SETTINGS_URL);

  const [authDetails, setAuthDetails] = useState<KeyValueObj>({
    firstName,
    lastName,
    address,
    middleName,
    userId,
    phone,
    email,
    role,
    companyName,
  });

  function updateAuthDetails(data: KeyValueObj) {
    localStorage.setItem("user", JSON.stringify(data));
    setAuthDetails({
      ...authDetails,
      firstName: data.first_name,
      lastName: data.last_name,
      middleName: data.middleName,
      userId: data.id,
      phone: data.phone_number,
      role: data?.role?.title,
      address: data.address,
      email: data.email,
    });
  }

  function processInputs(formValues: any) {
    if (currentTitle === "Edit Company's Address") {
      formValues.lga = formValues?.local_govt;
    }
    setLoading(true);
    apiCall(
      updateUrl,
      "PUT",
      formValues,
      (data: any) => {
        if (updateUrl === USERS + `/${userId}`) updateAuthDetails(data);
        setShowModal(false);
      },
      (err: any) => {
        console.log(err);
      },
      () => {
        setLoading(false);
      }
    );
  }

  function handleProfileEdit() {
    setForm(getProfileEditForm(authDetails, address));
    setShowModal(true);
    setShowProfile(false);
    setCurrentTitle("Edit your profile");
    setUpdateUrl(USERS + `/${userId}`);
  }

  function handleShowEdit(type: string) {
    switch (type) {
      case "edit-company-info":
        switch (companyType) {
          case "Distributor":
            setForm(getDistributorsCompanyForm(companyData, baseData?.[0]));
            setUpdateUrl("/api/v1/distributors/" + companyId);
            break;

          case "Manufacturer":
            setForm(getManuImporterUpdateForm(companyData, baseData?.[0]));
            setUpdateUrl("/api/v1/manufacturers/" + companyId);
            break;

          case "Importer":
            setForm(getManuImporterUpdateForm(companyData, baseData?.[0]));
            setUpdateUrl("/api/v1/importers/" + companyId);
            break;

          case "Retailer":
            setForm(getRetailerUpdateForm(companyData, baseData?.[0]));
            setUpdateUrl("/api/v1/retailers/" + companyId);

            break;
          default:
            break;
        }
        setCurrentTitle("Edit Company Information");
        setShowModal(true);
        setShowProfile(false);
        break;

      case "edit-contact-info":
        switch (companyType) {
          case "Distributor":
            setUpdateUrl("/api/v1/distributors/contact/" + companyId);
            break;
          case "Manufacturer":
            setUpdateUrl("/api/v1/manufacturers/contact/" + companyId);
            break;
          case "Importer":
            setUpdateUrl("/api/v1/importers/contact/" + companyId);
            break;
          case "Retailer":
            setUpdateUrl("/api/v1/retailers/contact/" + companyId);
            break;

          default:
            break;
        }
        setForm(getContactUpdateForm(companyData?.users?.[0], baseData?.[0]));
        setShowModal(true);
        setShowProfile(false);
        setCurrentTitle("Edit Company's Contact Information");
        break;
      case "edit-company-address":
        setUpdateUrl("/api/v1/company/address/" + companyId);
        setShowModal(true);
        setShowProfile(false);
        setCurrentTitle("Edit Company's Address");
        setForm(getCompanyAddressUpdateForm(companyData));
        break;
      case "edit-file-upload":
        // switch (companyType) {
        //   case "Distributor":
        //     setUpdateUrl("/api/v1/company/documents/" + companyId);
        //     break;
        //   case "Manufacturer":
        //     setUpdateUrl("/api/v1/manufacturers/documents/" + companyId);
        //     break;
        //   case "Importer":
        //     setUpdateUrl("/api/v1/importers/documents/" + companyId);
        //     break;
        //   case "Retailer":
        //     setUpdateUrl("/api/v1/retailers/documents/" + companyId);
        //     break;

        //   default:
        //     break;
        // }
        setUpdateUrl("/api/v1/company/documents/" + companyId);
        setShowUpdateModal(true);
        setShowProfile(false);
        break;
      default:
        break;
    }
  }

  function handleUpdateFiles(files: any) {
    setLoading(true);
    const formData = new FormData();
    Object.keys(files).forEach((fileKey: string) => {
      formData.append(fileKey, files[fileKey]);
    });

    apiCall(
      updateUrl,
      "PUT",
      formData,
      (data) => setShowUpdateModal(false),
      (error) => console.log(error),
      () => setLoading(false)
    );
  }

  function handleStatusNotificationClicked() {
    if (companyApprovalStatus === "queried") {
      setApprovalNotificationText(
        companyApprovalRemark +
          ". Click on the Profile icon to update the registeration information"
      );
      setApprovalNotificationTitle("Query Details");
    }
    if (companyApprovalStatus === "declined") {
      setApprovalNotificationText(companyApprovalRemark);
      setApprovalNotificationTitle("Decline Details");
    }

    setShowApprovalStatusModal(true);
  }

  return (
    <DashboardNavWrapper>
      <Modal
        isOpen={showModal}
        title={currentTitle}
        close={() => setShowModal(false)}
      >
        <ModalWrapper short>
          <Form
            loadingState={loading}
            submitLabel="Submit"
            formInputs={form}
            processInputs={processInputs}
            spinnerComponent={
              <Button disabled={loading}>
                <ClipSpinner />
              </Button>
            }
            login={false}
            biControl={false}
            handleBack={undefined}
          />
        </ModalWrapper>
      </Modal>

      <Modal
        isOpen={showUpdateModal}
        close={() => setShowUpdateModal(false)}
        title="Update Files"
      >
        <FileUpdate
          requiredFiles={baseData?.[0]?.values?.requisite_files}
          companyType={companyType}
          handleUpdateFiles={handleUpdateFiles}
          loading={loading}
          companyData={companyData}
        />
      </Modal>
      <Modal
        isOpen={showApprovalStatusModal}
        close={() => setShowApprovalStatusModal(false)}
        title={approvalNotificationTitle}
      >
        <Prompt
          prompt={approvalNotificationText}
          onMainAction={() => setShowApprovalStatusModal(false)}
          mainActionText="Ok"
          onSecondaryAction={() => setShowApprovalStatusModal(false)}
          mainLoading={false}
        />
      </Modal>

      {showProfile && (
        <LightBackdrop onBackdropClick={() => setShowProfile(false)}>
          <PopupWrapper position={position1}>
            <ProfileCard
              handleShowEdit={handleShowEdit}
              handleProfileEdit={handleProfileEdit}
            />
          </PopupWrapper>
        </LightBackdrop>
      )}

      <div className="greeting">
        <h2 className="main-greet">{companyName}</h2>
        <h5 className="minor-greet">{greeting(firstName || "Admin")}</h5>
      </div>
      {companyApprovalStatus === "queried" ? (
        <div className="status-notification">
          <button onClick={handleStatusNotificationClicked}>
            This company's application has been queried. click to view details
          </button>
        </div>
      ) : null}

      {companyApprovalStatus === "decline" ? (
        <div className="status-notification decline">
          <button onClick={handleStatusNotificationClicked}>
            This company's application has been declined. click to view details
          </button>
        </div>
      ) : null}

      <RxHamburgerMenu
        onClick={() => setShowSidebar((prev: boolean) => !prev)}
        className="hamburger-icon"
      />

      <div className="flex-row search-wrapper">
        <img src={chatIcon} alt="chat" />
        <img src={notificationIcon} alt="notification" />
      </div>

      <div className="flex-row dash-profile">
        <div onClick={onProfileClick} className="flex-row profile-image">
          <img src={profileImage} alt="profile" />
          <BsChevronDown className="profile-down-icon" />
        </div>
      </div>
    </DashboardNavWrapper>
  );
}

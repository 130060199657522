import { axiosAuth } from "../api/axios";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useContexts";
import { useEffect } from "react";


export function useAxiosAuth() {
  const refresh = useRefreshToken();
  const { auth, setAuth } = useAuth();

  useEffect(() => {

    const requestIntercept = axiosAuth.interceptors.request.use(
      (config:any) => {
        if(!config.headers["authorization"]){
          //config.headers["Authorization"] = auth.authToken;
          config.headers["authorization"] = "Bearer " + auth.token;
        
          
        }
        return config
      },
      (error: any) => Promise.reject(error)
    )

    const responseIntercept = axiosAuth.interceptors.response.use(
      (response) => response,
      // async (err: any) => {
      //   // The previous request that was rejected is saved in the err.config
      //   const prevRequest = err?.config;
      //   if (err?.response.status === 403 && !prevRequest.sent) {
      //     prevRequest.sent = true;
      //     const newAccessToken = await refresh();
      //     setAuth((prev: any) => ({ ...prev, authToken: newAccessToken }));
      //     prevRequest.headers["authorization"] = newAccessToken;
      //     //We have placed the new auth token, now we need to repeat the request
      //     return axiosAuth(prevRequest);
      //   }
      //   //if its is not a 403, we can pass the error forward as done below
      //   return Promise.reject(err);
      // }
    );

    return () => {
      axiosAuth.interceptors.request.eject(requestIntercept);
      axiosAuth.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh, setAuth]);

  return axiosAuth as any;
}

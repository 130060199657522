import React, { Dispatch, SetStateAction, useState } from "react";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import {
  FormField,
  Input,
  Label,
  TinDetails,
  VerificationFailed,
} from "../styles";
import { Button } from "src/common/components/button";
import { FiAlertCircle } from "react-icons/fi";

type Props = { setTinData: Dispatch<SetStateAction<any>>; tinData: any };

function TinVerification({ setTinData, tinData }: Props) {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [data, setData] = useState<any>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent page reload
  };

  const formData = new FormData();
  formData.set("tin", inputValue);

  function onFinally() {
    setLoading(false);
  }

  function onSuccess(data: any) {
    setData(data);
    setTinData(data);
    setIsSuccess(true);
  }

  function onFail() {
    setIsSuccess(false);
  }

  const MakeReq: any = useApiCallHandling();
  const verifyTin = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    MakeReq(
      "/api/v1/company/tin/verify",
      "post",
      formData,
      onSuccess,
      onFail,
      onFinally
    );
  };

  return (
    <div>
      <div className="input_container">
        <div className="heading">MEDEX Onboarding</div>
        <div className="sub-heading">
          <div className="stepcounter">Step 3 of 4</div>
          <div className="stepcountTitle">TIN verification</div>
        </div>
      </div>

      <FormField>
        <Label htmlFor="name">Enter Tax Identification Number (TIN)</Label>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "Flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Input
              id="tin"
              name="tin"
              type="text"
              placeholder=""
              value={inputValue}
              onChange={handleChange}
              required
            />
            <Button secondary type="submit" onClick={verifyTin} loading={loading}>
              Verify
            </Button>
          </div>
        </form>
      </FormField>

      {isSuccess === true && (
        <TinDetails>
          <div className="Tin_heading">TIN Verified</div>
          <div className="Tin_content">
            <div>
              <div className="Sub_title">TIN</div>
              <div className="content">{data.TIN}</div>
            </div>
            <div>
              <div className="Sub_title">Tax Payer Name</div>
              <div className="content">{data.TaxPayerName}</div>
            </div>
          </div>
          <div className="Tin_content">
            <div>
              <div className="Sub_title">Phone Number</div>
              <div className="content">{data.Phone}</div>
            </div>
            <div>
              <div className="Sub_title">Tax Payer Type</div>
              <div className="content">{data.TaxPayerName}</div>
            </div>
          </div>
          <div className="Tin_content">
            <div>
              <div className="Sub_title">Tax Office Name</div>
              <div className="content">{data.TaxOfficeName}</div>
            </div>
            <div>
              <div className="Sub_title">RC Number</div>
              <div className="content">{data.RCNumber}</div>
            </div>
          </div>
          <div className="Tin_content">
            <div>
              <div className="Sub_title">Address</div>
              <textarea
                className="tin_address"
                value={tinData.Address}
                onChange={(e: any) =>
                  setTinData((prev: any) => ({
                    ...prev,
                    Address: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            <div className="date-wrapper">
              <div className="Sub_title">Date of Establishment</div>
              <input
                type="date"
                className="date-input"
                value={tinData.date_of_establishment}
                onChange={(e: any) =>
                  setTinData((prev: any) => ({
                    ...prev,
                    date_of_establishment: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </TinDetails>
      )}

      {isSuccess === false && (
        <VerificationFailed>
          <FiAlertCircle size={"50px"} className="icon" />
          <div>
            TIN Verification failed click here to <b>Retry. </b>
          </div>
        </VerificationFailed>
      )}
    </div>
  );
}

export default TinVerification;

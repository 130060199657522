import { ClipLoader } from "react-spinners";
import styled, { useTheme } from "styled-components";
import { themeType } from "../../../App";
import { css } from "@emotion/react";

export interface IClipLoaderProps {
  size?: number;
  message?: string;
  color?: string;
}

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const PageSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    color: ${({ theme }) => theme.bg.secondary};
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
`;

const override = css``;

export function ClipSpinner({ size = 19, color }: IClipLoaderProps) {
  const theme = useTheme() as themeType;
  return (
    <SpinnerWrapper>
      <ClipLoader
        size={size}
        color={color ? color : theme.bg.sec_200}
        css={override}
      />
    </SpinnerWrapper>
  );
}

export function PageClipSpinner({ message }: IClipLoaderProps) {
  const theme = useTheme() as themeType;
  return (
    <PageSpinnerWrapper>
      <ClipLoader size={50} color={theme.bg.sec_200} css={override} />
      <h3>{message}</h3>
    </PageSpinnerWrapper>
  );
}

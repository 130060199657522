import { NavLink } from "react-router-dom";
import { Logo } from "src/common/components/logo";
import useAuth, { useDashboardContext } from "src/hooks/useContexts";
import useLogout from "src/hooks/useLogout";
import { INavigation, navigationList } from ".";
import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";

const Wrapper = styled.div<{ showSidebar: boolean }>`
  width: 15rem;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.bg.primary};
  position: fixed;
  top: 0;
  left: ${({ showSidebar }) => (showSidebar ? 0 : "-16rem")};
  transition: left ease-in-out 0.3s;
  bottom: 0;
  z-index: 99;

  border-bottom: 1px solid ${({ theme }) => theme.border.light};

  @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
    padding: 0 5vw;
  }

  .logo-wrapper {
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    max-height: 4rem;
    .logo {
      text-align: left;
    }
  }

  .sidebar-nav-items {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .nav-wrapper {
      width: 100%;
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    li {
      padding: 0.2rem;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1.5rem;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: ${({ theme }) => theme.text.prim_100};
    }
  }

  .nav-link {
    text-decoration: none;
    /* color: ${({ theme }) => theme.text.prim_100}; */
    color: ${({ theme }) => theme.text.prim_500};
  }

  .nav-gap {
    margin-left: 2rem;
  }

  .active-nav-link {
    text-decoration: none;
    color: ${({ theme }) => theme.text.secondary};
    position: relative;
    color: #ff4545;

    :before {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      width: 100%;
      height: 0.2rem;
      /* background-color: ${({ theme }) => theme.text.prim_500}; */
      background-color: #ff4545;
    }
  }

  .logout {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .hamburger-icon {
    color: ${({ theme }) => theme.text.prim_500};
    font-size: 1.8rem;

    @media screen and (min-width: ${({ theme }) => theme.screen.tablet}) {
      display: none;
    }
  }
`;

export interface ISideNavProps {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
}
export function Sidebar({ showSidebar, setShowSidebar }: ISideNavProps) {
  const { auth } = useAuth();
  const { showOnlyLogout } = useDashboardContext();

  const getLoginTo = (auth: any) => {
    if (auth?.user?.registration_completed) {
      const title = auth?.user?.role?.title;
      if (title?.toLowerCase() === "admin") {
        return "/dashboard/admin/products/approved";
      }
      if (title?.toLowerCase() === "pcn-admin") {
        return "/pcn-admin/invite-companies";
      }
      return "/dashboard/stats";
    } else {
      return "/login";
    }
  };

  const logout = useLogout();
  return (
    <Wrapper showSidebar={showSidebar}>
      <div className="logo-wrapper">
        <NavLink className="nav-link" to="/">
          <Logo />
        </NavLink>
      </div>

      <ul className="sidebar-nav-items flex-row">
        <div className="nav-wrapper">
          {showOnlyLogout
            ? null
            : navigationList?.map((navList: INavigation, idx: number) => (
                <li key={idx} onClick={() => setShowSidebar(false)}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active-nav-link" : "nav-link"
                    }
                    to={navList.link}
                  >
                    {navList.title}
                  </NavLink>
                </li>
              ))}

          {showOnlyLogout ? (
            <li className="logout" onClick={() => setShowSidebar(false)}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-nav-link" : "nav-link"
                }
                onClick={() => logout()}
                to={""}
              >
                Logout
              </NavLink>
            </li>
          ) : (
            <>
              <li onClick={() => setShowSidebar(false)}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav-link" : "nav-link "
                  }
                  to="/register"
                >
                  Register
                </NavLink>
              </li>
              <li onClick={() => setShowSidebar(false)}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav-link" : "nav-link"
                  }
                  to={getLoginTo(auth)}
                >
                  Login
                </NavLink>
              </li>
            </>
          )}
        </div>
      </ul>
    </Wrapper>
  );
}

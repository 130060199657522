import { createContext, useState } from "react";

interface AuthProps {
  children: JSX.Element;
}

const AuthContext = createContext<any>({});

export const AuthProvider = ({ children }: AuthProps) => {
  const shouldPersist =
    JSON.parse(localStorage.getItem("persist") as string) || false;
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(shouldPersist);
  const [goBack, setGoBack] = useState(false);

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, persist, setPersist, goBack, setGoBack }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import Form from "../../common/components/form/Form";
import { LoginWrapper } from "./style";
import { formInputs, resetPasswordInputs, stageToNumber } from "./data";
import { LoginProps } from "../../common/types";
import { loginUser } from "../../api";
import useAuth from "../../hooks/useContexts";

import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ClipSpinner } from "../../common/components/spinner";
import { useNotification } from "../../common/components/Notifications/NotificationProvider";

import dotsImage from "../../assets/images/dots.png";
import { useApiCallHandling } from "../../hooks/useApiCallHandling";
import { RESET_PASSWORD_URL } from "../../common/constants";
import { sleep } from "src/common/utils";
import { Modal } from "src/common/components/modal";
import { RedirectModalWrapper } from "../new-registration/styles";
import { Button } from "src/common/components/button";

export interface ILoginProps {}

export function Login(props: ILoginProps) {
  const [loading, setLoading] = useState(false);
  const { setAuth, goBack, setGoBack } = useAuth();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [initializeUrl, setInitializeUrl] = useState("");
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const makeRequest = useApiCallHandling();

  const navigate = useNavigate();
  const location = useLocation() as any;
  const notify = useNotification();

  const from = goBack
    ? location.state?.from?.pathname || "/dashboard/stats"
    : "/dashboard/stats";

  async function onFormSubmit(formValues: LoginProps) {
    setLoading(true);
    try {
      const response = await loginUser(formValues);
      if (response.data.success) {
        const user = response.data.data.user;
        const token = response.data?.data?.token;

        const authToken = response.data.data.token;
        setAuth({ user, authToken });
        localStorage.setItem("persist", JSON.stringify(formValues.persist));

        sleep(5000);

        //We will temporarily save the user auth token in local storage;
        localStorage.setItem("token", JSON.stringify(authToken));
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("companyData", JSON.stringify(user));

        if (user?.role?.designation === "super-admin") {
          navigate("/dashboard/admin/companies/queried", { replace: true });
          return;
        } else if (user?.role?.title?.toLowerCase() === "pcn admin") {
          navigate("/dashboard/pcn-admin/invite-companies", { replace: true });
          return;
        }

        const pcnStage = user?.company?.pcn_registration_status;

        if (user.registration_completed && pcnStage === "completed") {
          notify({
            type: "SUCCESS",
            message: "Welcome!, Login successful",
          });
          //continue to where it came from or to the dashboard;
          setGoBack(false);
          navigate(from, { replace: true });
          return;
        }

        if (user.registration_completed && pcnStage === "initialized") {
          notify({
            type: "SUCCESS",
            message:
              "Welcome!, Obtain PCN license to enjoy the full features on Medex",
          });
          //continue to where it came from or to the dashboard;
          setGoBack(false);
          navigate(from, { replace: true });
          return;
        }
        const nextStep = user?.company?.next_step;
        if (nextStep === "phone_number_verification_stage") {
          notify({
            type: "SUCCESS",
            message: "Check your email to continue with email verification",
          });
          return;
        }

        if (!nextStep && pcnStage?.toLowerCase() === "uninitialized") {
          let PCN_BASE_URL = process.env.REACT_APP_PCN_FRONTEND_BASE_URL;
          const url =
            PCN_BASE_URL +
            "self-service/auth/company-registration?token=" +
            token;
          setInitializeUrl(url);
          setShowRedirectModal(true);
          return;
        }

        notify({
          type: "SUCCESS",
          message: "Welcome!, continue with the rest of the registeration",
        });
        navigate("/register?currentStep=" + stageToNumber[nextStep] || "1", {
          replace: true,
        });

        //continue to the complete completion page;
      }
    } catch (err: any) {
      //handle all the error here
      console.log(err);
      if (err.response?.data?.message) {
        notify({
          type: "ERROR",
          message: err.response.data.message,
        });
      } else {
        notify({
          type: "ERROR",
          message: "Something went wrong, please try again",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleResetPassword(formValues: any) {
    setLoading(true);
    makeRequest(
      RESET_PASSWORD_URL,
      "POST",
      formValues,
      console.log,
      console.log,
      () => setLoading(false)
    );
  }

  const spinnerComponent = (
    <button disabled className="submit">
      <ClipSpinner /> registering
    </button>
  );

  return (
    <LoginWrapper>
      <section className="container">
        <section className="info-sec">
          <div className="text-wrapper">
            <h2>Welcome back !</h2>
            <p>
              Enjoy unlimited access to Drug Supply Management, inventory,
              Real-time Tracking a lot more
            </p>
          </div>
          <img className="dots-image" src={dotsImage} alt="" />
        </section>
        <section className="input-sec">
          <div className="top flex-row">
            <div className="welcome-message">
              <h2 className="sign-up">Log in</h2>
              <p className="other-route">
                Don't have account?{" "}
                <span>
                  <Link className="route-link" to="/register">
                    Create an Account
                  </Link>
                </span>
              </p>
            </div>
          </div>
          <div className="form">
            {showForgotPassword ? (
              <Form
                formInputs={resetPasswordInputs}
                loadingState={loading}
                submitLabel="Send Reset Link"
                processInputs={handleResetPassword}
                spinnerComponent={spinnerComponent}
                login={true}
                biControl={false}
                handleBack={undefined}
                handleForgotPassword={() =>
                  setShowForgotPassword((prev: boolean) => !prev)
                }
                showForgotPassword={showForgotPassword}
                removePadding
              />
            ) : (
              <Form
                formInputs={formInputs}
                loadingState={loading}
                submitLabel="Log In"
                processInputs={onFormSubmit}
                spinnerComponent={spinnerComponent}
                login={true}
                biControl={false}
                handleBack={undefined}
                handleForgotPassword={() =>
                  setShowForgotPassword((prev: boolean) => !prev)
                }
                showForgotPassword={showForgotPassword}
                removePadding
              />
            )}
          </div>

          {/* <div className="controls">
            <div className="or">
              <h3>or</h3>
            </div>
            <button>
              <img src={googleLogo} alt="google logo" />
              Continue with Google
            </button>
          </div> */}
        </section>
      </section>

      <Modal
        title="PCN Self Service Redirect"
        isOpen={showRedirectModal}
        close={() => setShowRedirectModal(false)}
      >
        <RedirectModalWrapper>
          <p>
            You have not started your registration with PCN. You will now be
            redirected to the PCN self service portal for registeration. Click
            on the continue button to continue to PCN self service.
          </p>
          <Button onClick={() => window.open(initializeUrl, "_blank")?.focus()}>
            Continue
          </Button>
        </RedirectModalWrapper>
      </Modal>
    </LoginWrapper>
  );
}


import { countries } from "../../../common/data/countries";

export const UserData = [
  {
    id: 1,
    year: "Dec 2021",
    achieved: 6,
    target: 7,
  },
  {
    id: 2,
    year: "Jan 2022",
    achieved: 7,
    target: 6,
  },

  {
    id: 3,
    year: "Feb 2022",
    achieved: 7.5,
    target: 8,
  },
  {
    id: 4,
    year: "Mar 2022",
    achieved: 7,
    target: 6,
  },
  {
    id: 5,
    year: "Apr 2022",
    achieved: 7,
    target: 6,
  },
  {
    id: 6,
    year: "May 2022",
    achieved: 6,
    target: 7,
  },
];

export const barUserData = [
  {
    id: 1,
    year: "Dec",
    achieved: 6,
    target: 7,
  },
  {
    id: 2,
    year: "Jan",
    achieved: 7,
    target: 6,
  },
  {
    id: 3,
    year: "Feb",
    achieved: 3,
    target: 3,
  },
  {
    id: 4,
    year: "Mar",
    achieved: 4,
    target: 5,
  },
  {
    id: 5,
    year: "Apr",
    achieved: 7,
    target: 6,
  },
  {
    id: 6,
    year: "May",
    achieved: 3,
    target: 5,
  },
  {
    id: 4,
    year: "Mar",
    achieved: 7,
    target: 4,
  },
  {
    id: 5,
    year: "Apr",
    achieved: 7,
    target: 6,
  },

  {
    id: 6,
    year: "May",
    achieved: 3,
    target: 5,
  },
  {
    id: 4,
    year: "Mar",
    achieved: 7,
    target: 4,
  },
];

export const lineOptions = {
  aspectRatio: 2.4,
  responsive: true,
  elements: {
    line: {
      borderWidth: 2,
      tension: 0.5,
      borderColor: ["#FB896B", "#6956e5"],
      borderRadius: 1,
    },
    point: {
      borderWidth: 0,
      borderColor: "transparent",
      radius: 10,
      backgroundColor: "rgba(0,0,0,.0)",
      hoverBackgroundColor: "#FB896B",
      hoverBorderWidth: 2,
      hoverBorderColor: "white",
      hoverRadius: 5,
      display: false,
    },
  },
  plugins: {
    legend: {
      align: "end",
      position: "top",
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
      display: false,
    },
  },
  scales: {
    xAxis: {
      display: true,
      grid: {
        color: "rgba(0,0,0,0.02)",
      },
    },
    yAxis: {
      grid: {
        color: "rgba(0,0,0,.05)",
      },
      beginAtZero: true,
      max: 12,
    },
  },
};

export const barOptions = {
  aspectRatio: 1.8,
  responsive: true,
  elements: {
    bar: {
      borderWidth: 0,
      innerWidth: 2,
      barThickness: 2,
    },
  },
  plugins: {
    legend: {
      align: "center",
      position: "top",
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
      display: false,
    },
  },
  scales: {
    xAxis: {
      display: true,
      grid: {
        color: "rgba(0,0,0,0.02)",
      },
      barThickness: 1, // number (pixels) or 'flex'
      maxBarThickness: 1,
    },
    yAxis: {
      grid: {
        color: "rgba(0,0,0,.05)",
      },
      beginAtZero: true,
    },
  },
};

export const importerProductForm = [
  {
    inputType: "selectInput",
    prompt: "ATC code",
    options_name: "atc_code",
    name: "atc_code_id",
    placeholder: "Enter upto 3 characters to search",
    fullWidth: false,
    shortWidth: true,
    options: [],
    valueType: "ATC",
  },
  {
    inputType: "text",
    prompt: "Product Brand",
    options_name: "product_brand",
    name: "brand_name",
    placeholder: "Type your product brand",
    fullWidth: false,
    shortWidth: true,
    rules: {
      minLength: {
        errorMessage: "should be at least 2 characters",
        expectedValue: 2,
      },
    },
  },
  {
    inputType: "text",
    prompt: "Product Manufacturer",
    name: "manufacturer_name",
    placeholder: "Type your product manufacturer",
    fullWidth: false,
    shortWidth: true,
    rules: {
      minLength: {
        errorMessage: "should be at least 2 characters",
        expectedValue: 2,
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Where is your product shipping from",
    options_name: "shipping_continent",
    name: "country_of_origin",
    placeholder: "Choose a country",
    fullWidth: false,
    shortWidth: true,
    options: countries,
  },

  {
    inputType: "selectInput",
    prompt: "Product Classification",
    name: "classification",
    options_name: "classifications",
    placeholder: "Select your product classification",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Pharmaceuticals" }],
  },

  //comback here
  {
    inputType: "selectInput",
    prompt: "Product Storage Requirement",
    name: "storage_requirement",
    options_name: "storage_requirements",
    placeholder: "Select your product storage requirement",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Pharmaceuticals" }],
  },
  {
    inputType: "selectInput",
    prompt: "Product form",
    options_name: "forms",
    name: "form",
    placeholder: "Select your product form",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Liquid" }],
  },

  {
    inputType: "selectInput",
    prompt: "Product regulation",
    name: "regulation",
    options_name: "regulations",
    placeholder: "Select your product regulation",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Prescription" }],
  },

  {
    inputType: "text",
    prompt: "NAFDAC number",
    name: "nafdac_number",
    placeholder: "Type your NAFDAC number",
    fullWidth: false,
    shortWidth: true,
    maxLength: 7,
    rules: {
      length: {
        errorMessage: "Should be 7 digits",
        expectedValue: 7,
      },
      onlyDigit: {
        expectedValue: true,
        errorMessage: "NAFDAC number can only be digits",
      },
    },
  },

  {
    inputType: "selectInput",
    prompt: "Product Weight unit",
    name: "strength_unit",
    options_name: "strength_units",
    placeholder: "Select your product strength units",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Miligrams" }],
  },
  {
    inputType: "number",
    prompt: "Product Weight",
    name: "strength",
    placeholder: "Enter the product weight",
    fullWidth: false,
    shortWidth: true,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },

  {
    inputType: "selectInput",
    prompt: "Primary Packaging",
    name: "primary_packaging",
    placeholder: "Select your product's primary packaging",
    fullWidth: false,
    shortWidth: true,
    options: [
      { id: 1, title: "Ampoules" },
      { id: 2, title: "Vials" },
      { id: 3, title: "Blister" },
      { id: 4, title: "Strip" },
      { id: 5, title: "Bottles" },
      { id: 6, title: "Sachet" },
    ],
  },

  {
    inputType: "number",
    prompt: "Number of units in primary packaging",
    name: "form_quantity",
    placeholder: "Number of the units that make up a primary package",
    fullWidth: false,
    shortWidth: true,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Secondary Packaging",
    name: "secondary_packaging",
    placeholder: "Select your product's secondary packaging",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Pack" }],
  },
  {
    inputType: "number",
    prompt: "Primary Package Quantity per Secondary Package",
    name: "primary_package_quantity_per_secondary_package",
    placeholder: "Number of primary packages in a single Secondary package",
    fullWidth: false,
    shortWidth: true,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Tertiary Packaging",
    name: "tertiary_packaging",
    placeholder: "Select your product's tertiary packaging",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Carton" }],
  },
  {
    inputType: "number",
    prompt: "Secondary Package Quantity per Tertiary Package",
    name: "secondary_package_quantity_per_tertiary_package",
    placeholder: "Number of secondary packages in a single tertiary package",
    fullWidth: false,
    shortWidth: true,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },
];

export const manufacturerProductForm = [
  {
    inputType: "selectInput",
    prompt: "ATC code",
    options_name: "atc_code",
    name: "atc_code_id",
    placeholder: "Enter upto 3 characters to search",
    fullWidth: false,
    shortWidth: true,
    options: [],
    valueType: "ATC",
  },
  {
    inputType: "text",
    prompt: "Product Brand",
    name: "brand_name",
    options_name: "product_brand",
    placeholder: "Select ATC code first",
    readOnly: true,
    fullWidth: false,
    shortWidth: true,
  },

  {
    inputType: "selectInput",
    prompt: "Product Classification",
    name: "classification",
    options_name: "classifications",
    placeholder: "Select your product classification",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Pharmaceuticals" }],
  },

  //comback here
  {
    inputType: "selectInput",
    prompt: "Product Storage Requirement",
    name: "storage_requirement",
    options_name: "storage_requirements",
    placeholder: "Select your product storage requirement",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Pharmaceuticals" }],
  },
  {
    inputType: "selectInput",
    prompt: "Product form",
    options_name: "forms",
    name: "form",
    placeholder: "Select your product form",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Liquid" }],
  },

  {
    inputType: "selectInput",
    prompt: "Product regulation",
    name: "regulation",
    options_name: "regulations",
    placeholder: "Select your product regulation",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Prescription" }],
  },

  {
    inputType: "text",
    prompt: "NAFDAC number",
    name: "nafdac_number",
    placeholder: "Type your NAFDAC number",
    fullWidth: false,
    shortWidth: true,
    maxLength: 7,
    rules: {
      length: {
        errorMessage: "Should be 7 digits",
        expectedValue: 7,
      },
      onlyDigit: {
        expectedValue: true,
        errorMessage: "NAFDAC number can only be digits",
      },
    },
  },

  {
    inputType: "selectInput",
    prompt: "Product Strength unit",
    name: "strength_unit",
    options_name: "strength_units",
    placeholder: "Select your product strength units",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Miligrams" }],
  },
  {
    inputType: "number",
    prompt: "Product Strength",
    name: "strength",
    placeholder: "Enter the product weight",
    fullWidth: false,
    shortWidth: true,
    tooltip: "This refers to the strenth in terms of 500Mg",
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },

  {
    inputType: "selectInput",
    prompt: "Primary Packaging",
    name: "primary_packaging",
    placeholder: "Select your product's primary packaging",
    fullWidth: false,
    shortWidth: true,
    options: [
      { id: 1, title: "Ampoules" },
      { id: 2, title: "Vials" },
      { id: 3, title: "Blister" },
      { id: 4, title: "Strip" },
      { id: 5, title: "Bottles" },
      { id: 6, title: "Sachet" },
    ],
  },

  {
    inputType: "number",
    prompt: "Number of units in primary packaging",
    name: "form_quantity",
    placeholder: "Number of the units that make up a primary package",
    fullWidth: false,
    shortWidth: true,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Secondary Packaging",
    name: "secondary_packaging",
    placeholder: "Select your product's secondary packaging",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Pack" }],
  },
  {
    inputType: "number",
    prompt: "Primary Package Quantity per Secondary Package",
    name: "primary_package_quantity_per_secondary_package",
    placeholder: "Number of primary packages in a single Secondary package",
    fullWidth: false,
    shortWidth: true,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Tertiary Packaging",
    name: "tertiary_packaging",
    placeholder: "Select your product's tertiary packaging",
    fullWidth: false,
    shortWidth: true,
    options: [{ id: 1, title: "Carton" }],
  },
  {
    inputType: "number",
    prompt: "Secondary Package Quantity per Tertiary Package",
    name: "secondary_package_quantity_per_tertiary_package",
    placeholder: "Number of secondary packages in a single tertiary package",
    fullWidth: false,
    shortWidth: true,
    rules: {
      aboveZero: {
        expectedValue: true,
        errorMessage: "Value must be more than zero",
      },
    },
  },
];

export const tableKeys = ["name", "tin", "address", "email", "action"];
export const unlinkedKeys = ["name", "tin", "address", "email",];

export const tableHeaders = [
  "Company Name",
  "TIN Number",
  "Address",
  "Email",
];

export const unlinkedHeaders = [
  "Company Name",
  "TIN Number",
  <div style={{minWidth: "15rem"}}>Address</div>,
  "Email"
];

export const tableData = [
  {
    id: "1",
    name: {
      type: "string",
      value: "Quado Distributions",
    },
    tin: {
      type: "string",
      value: "TIN7828284",
    },
    address: {
      type: "string",
      value: "Number 11 new man Road, Yaba, Kogi state",
    },
    email:{
      type: "string",
      value:"engchikwas404@gmail.com"
    },
    action:{
      type: "button",
      buttonType:"dots",
      onclick: () => console.log("clicked")
    }
    
  },
  {
    id: "1",
    name: {
      type: "string",
      value: "Francis Distributions",
    },
    tin: {
      type: "string",
      value: "TIN7828284",
    },
    address: {
      type: "string",
      value: "no 16, 123 road, Kado estate, Abuja",
    },
    email:{
      type: "string",
      value:"israelite404@gmail.com"
    },
    action:{
      type: "button",
      buttonType:"dots",
      onclick: () => console.log("clicked")
    }
  },
];


export const singleDistributorData = [
  {
    inputType: "email",
    prompt: "Company email address",
    name: "email",
    placeholder: "Enter the company's email address",
    fullWidth: true,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Company Phone number",
    name: "phone",
    placeholder: "Enter the company's phone number",
    fullWidth: true,

  },
]
import styled from "styled-components";

export const H4 = styled.h4`
  color: ${({ theme }) => theme.text.input};
  font-size: 0.85rem;
  font-weight: 400;
`;

export const H3 = styled.h4`
  color: ${({ theme }) => theme.text.input};
  font-size: 1rem;
  font-weight: 500;
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.text.input};
  font-size: 1.5rem;
  font-weight: 600;
`;

export const StatusText = styled.h4<{ level: string }>`
  color: ${({ theme, level }) => theme.status[level]};
`;
export const MessageText = styled.h3`
  color: ${({ theme }) => theme.bg.secondary};
  padding: 2.5rem;
  border: 1px solid ${({ theme }) => theme.bg.secondary};
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.bg.secondary}1f;
`;

import { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface ISelectOption {
  value: string;
  title: string;
}

interface IBasicSelect {
  label: string;
  options: ISelectOption[];
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  minWidth?: string;
}

export default function BasicSelect({
  setSelectedValue,
  selectedValue,
  options,
  label,
  minWidth
}: IBasicSelect) {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: minWidth || 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId=""
          id=""
          value={selectedValue}
          label={label}
          onChange={handleChange}
        >
          {options?.map((option) => (
            <MenuItem value={option.value}>{option.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

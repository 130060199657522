import styled from "styled-components";
import { Flex } from "../../../../common/elements";

export const ProductsWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 1rem 2rem;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
   
  }

  .form-wrapper {
    padding: 10rem 0 3rem;
    width: 100%;
    background-color: ${({ theme }) => theme.bg.primary};
  }

  .top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2.5rem;
    gap: 3rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      justify-content: space-between;
   
  }

    .excel_icon {
      width: 1.8rem;
    }
  }

  .tabs-wrapper {
    width: 100%;
  }

  .products {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100%);
    gap: 1.5rem;
    padding: 3rem 1rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    justify-content: center;

    /* Mobile first approach for the card width */
    @media screen and (min-width: 600px) {
      grid-template-columns: repeat(auto-fill, 48%);
      padding: 2rem;
    }

    @media screen and (min-width: 1100px) {
      grid-template-columns: repeat(auto-fill, 32%);
      gap: 2rem 2%;
    }

    @media screen and (min-width: 1340px) {
      grid-template-columns: repeat(auto-fill, 23.875%);
      gap: 2rem 1.5%;
    }

    @media screen and (min-width: 1630px) {
      grid-template-columns: repeat(auto-fill, 18.5%);
    }

    @media screen and (min-width: 2100px) {
      grid-template-columns: repeat(auto-fill, 17rem);
    }

    .no-product-message {
      color: ${({ theme }) => theme.bg.secondary};
      font-weight: 400;
      border: 1px solid ${({ theme }) => theme.bg.secondary};
      padding: 2.5rem;
      border-radius: 0.25rem;
      background-color: ${({ theme }) => theme.bg.secondary}20;
    }
  }
`;

export const ProductModalWrapper = styled.div`
  width: 60rem;
  padding: 2rem;
  max-width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 0;
   
  }
`;

export const ProductUploadWrapper = styled.div`
  width: 35rem;
  padding: 2rem;

  .general-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    opacity: 0;

    animation: fadeIn forwards 0.5s;
  }

  .uploading-page {
  }

  .uploaded-page {
    .metrics {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .metric {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .text {
      font-size: 0.95rem;
      font-weight: 400;
      color: ${({ theme }) => theme.text.prim_100};
    }

    .metric-value {
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.prim_50};
      border: 1px solid ${({ theme }) => theme.border.input};
      border-radius: 0.1rem;
      padding: 0.3rem 0.5rem;
      margin-left: auto;
    }

    .cancel-icon {
      font-size: 1.6rem;
      color: red;
    }

    .check-icon {
      font-size: 1.6rem;
      color: green;
    }

    .download-options {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      a {
        text-decoration: none;

        button {
          background-color: ${({ theme }) => theme.bg.secondary};
          color: white;
        }
      }
    }
  }

  .info {
    h3 {
      font-size: 0.875rem;
      font-weight: 400;
      text-align: center;

      color: ${({ theme }) => theme.text.input};
    }
  }

  .download-button {
    border: none;
    color: ${({ theme }) => theme.text.secondary};
    font-size: 1rem;
    background-color: transparent;
    display: flex;
    gap: 0.5rem;
    cursor: pointer;

    .download-icon {
      font-size: 1.5rem;
    }
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    position: relative;

    a {
      text-decoration: none;
    }

    .error-message,
    .file-name {
      position: absolute;
      top: 100%;
      font-size: 0.75rem;
      font-weight: 400;
      width: 100%;
      text-align: center;
      margin-top: 0.3rem;
    }

    .error-message {
      color: ${({ theme }) => theme.text.danger};
    }

    .file-name {
      color: ${({ theme }) => theme.text.life};
    }
  }

  .file-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    input[type="file"] {
      display: none;
    }

    label {
      border: 1px solid ${({ theme }) => theme.text.muted_200};
      display: inline-block;
      width: 100%;
      padding: 0.75rem 2.2rem;
      border-radius: 0.25rem;
      font-weight: 400;
      font-size: 0.875rem;
      text-align: center;
      cursor: pointer;
      color: ${({ theme }) => theme.text.muted_200};
    }
  }

  .response-messagees {
    width: 100%;
    padding-left: 3rem;

    p {
      font-weight: 500;
      font-size: 0.75rem;
      padding: 0.2rem;
    }
  }
`;

import styled from "styled-components";
import dispImg from "src/assets/images/error-loading.png";
import { H4 } from "src/common/elements";
import { Button } from "../../button";

export const RowWRapper = styled.tr`
  width: 100%;
  td {
    width: 100%;
    border-top: 1px solid ${({theme}) => theme.border.light};
    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      gap: 1rem;
    }
  }
`;

export interface IEmptyTableProps {
  colSize: number;
  onRetry: () => void;
}

export function ErrorLoading({ colSize, onRetry }: IEmptyTableProps) {
  return (
    <RowWRapper>
      <td colSpan={colSize+1}> 
        <div className="wrapper">
          <img src={dispImg} alt="Empty cart" />
          <H4>
            Unable to load table data, click the button below to try again.{" "}
          </H4>
          <Button onClick={onRetry}>Retry</Button>
        </div>
      </td>
    </RowWRapper>
  );
}

import {
  ModalCover,
  ModalCover2,
} from "src/common/components/modal/modal-cover";
import { ProductDetails } from "src/common/components/product-details";
import { useStore } from "src/hooks/useContexts";
import { ProductModalWrapper } from "../styles";
import { Prompt } from "src/common/components/prompt";

export interface IRegisterationModalsProps {}

export function ProductModals(props: IRegisterationModalsProps) {
  const { modalOptions, level2ModalOptions } = useStore();

  return (
    <>
      <ModalCover
        modalName={"view-product-modal"}
        title="View Product"
        canClose
      >
        <ProductModalWrapper>
          <ProductDetails
            deleteLoading={modalOptions?.deleteLoading}
            companyId={modalOptions?.companyId}
            product={modalOptions?.product}
            handleDelete={modalOptions?.deleteProduct}
            handleEdit={modalOptions?.handleEditProduct}
          />
        </ProductModalWrapper>
      </ModalCover>

      <ModalCover2 modalName="confirm-delete" canClose>
        <Prompt
          type="delete"
          prompt={"Are you sure you want to permanently delete this item ?"}
          onMainAction={() => level2ModalOptions?.confirmedDeleteProduct(level2ModalOptions?.id)} 
          onSecondaryAction={level2ModalOptions?.cancelDelete}
          mainLoading={level2ModalOptions?.loading}
        />
      </ModalCover2>
    </>
  );
}

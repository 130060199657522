export const BASE_URL = "https://api-medex.ogtlprojects.com";
export const REGISTER_USER_URL = "/api/v1/users/register";
export const LOGIN_URL = "/api/v1/users/auth/login";
export const RESEND_OTP_URL = "/api/v1/users/otp_resend";
export const ROLE_URL = "/api/v1/roles";
export const BASE_SETTINGS_URL = "/api/v1/settings/system";
export const DELIVERY_SERVICE_POST_URL = "/api/v1/delivery-providers";
export const DISTRIBUTOR_POST_URL = "/api/v1/distributors";
export const RETAILER_POST_URL = "/api/v1/retailers";
export const MANUFACTURER_POST_URL = "/api/v1/manufacturers";
export const PRODUCT_SETTINGS_URL = "/api/v1/settings/product";
export const ADD_PRODUCT_URL = "/api/v1/products";
export const IMPORTER_POST_URL = "/api/v1/importers";
export const PRODUCT_URL = "/api/v1/products";
export const PRODUCT_UPLOAD_URL = "/api/v1/products/upload";
export const INVENTORY_URL = "api/v1/inventories";
export const TIN_VERIFICATION_URL = "/api/v1/company/tin/verify";
export const ORDERS_URL = "/api/v1/orders";
export const RFQ_URL = "/api/v1/quotation-requests";
export const INVENTORY_FETCH = "api/v1/retailer/inventory/fetch";
export const DISTRIBUTORS_URL = "/api/v1/distributors";
export const QUOTATION_REQUEST = "/api/v1/quotation-requests";
export const QUOTATION_URL = "/api/v1/quotations";
export const USERS = "/api/v1/users";
export const COMPANIES = "/api/v1/admin/companies";
export const COMPANY_URL = "/api/v1/companies";
export const ADMIN_PRODUUCTS_URL = "/api/v1/admin/products";
export const SELECT_DISTRIBUTORS_URL =
  "/api/v1/manufacturer/select-distributors";

export const GET_BRANDS_URL = "/api/v1/products/brands";
export const BRAND_STRENGTHS_URL = "/api/v1/products/brand/strength";

// export const UPLOAD_DISTRIBUTORS_URL = "/api/v1/upload-document";
export const UPLOAD_DISTRIBUTORS_URL =
  "/api/v1/manufacturer/upload-distributors";
export const RESET_PASSWORD_URL = "/api/v1/users/auth/password-reset";
export const CHANGE_PASSWORD_URL = "/api/v1/users/auth/change-password";

export const DISTRIBUTOR_URL = "/api/v1/distributor";
export const MANUFACTURER_URL = "/api/v1/manufacturer";
export const RETAILER_URL = "/api/v1/retailer";
export const IMPORT_URL = "/api/v1/importer";

export const COMPANY_TYPES_URL = "/api/v1/company-types";
export const RESEND_CONFIRMATION_URL =
  "/api/v1/users/resend-confirmation-email";
export const ATC_CODES_URL = "/api/v1/atc-codes";

export const MANUFACTURER_INBOUND_REQUEST_URL =
  "/api/v1/manufacturer/distribution-requests-received";
export const MANUFACTURER_OUTBOUND_REQUEST_URL =
  "/api/v1/manufacturer/distribution-requests-sent";

export const IMPORTER_INBOUND_REQUEST_URL =
  "/api/v1/importer/distribution-requests-received";
export const IMPORTER_OUTBOUND_REQUEST_URL =
  "/api/v1/importer/distribution-requests-sent";

export const DISTRIBUTOR_INBOUND_REQUEST_URL =
  "/api/v1/distributor/inbound-requests";
export const DISTRIBUTOR_OUTBOUND_REQUEST_URL =
  "/api/v1/distributor/outbound-requests";

export const RETAILER_INBOUND_REQUEST_URL =
  "/api/v1/retailer/distribution-requests-received";
export const RETAILER_OUTBOUND_REQUEST_URL =
  "/api/v1/retailer/distribution-requests-sent";

export const MANUFACTURER_RESPOND_REQUEST_URL =
  "/api/v1/manufacturer/accept-decline-request";
export const IMPORTER_RESPOND_REQUEST_URL =
  "/api/v1/importer/accept-decline-request";
export const DISTRIBUTOR_RESPOND_REQUEST_URL =
  "/api/v1/distributor/accept-decline-request";
export const RETAILER_RESPOND_REQUEST_URL =
  "/api/v1/retailer/accept-decline-request";

export const MANUFACTURER_LINKED_DISTRIBUTORS_URL =
  "/api/v1/manufacturer/linked-distributors";
export const MANUFACTURER_UNLINKED_DISTRIBUTORS_URL =
  "/api/v1/manufacturer/unlinked-distributors";
export const MANUFACTURER_ADD_DISTRIBUTOR_URL =
  "/api/v1/manufacturer/add-distributor";
export const MANUFACTURER_UNLINK_A_DISTRIBUTOR =
  "/api/v1/manufacturer/unlink-distributor/";
export const MANUFACTURER_UPLOAD_DISTRIBUTORS_URL =
  "/api/v1/manufacturer/upload-distributors";
export const MANUFACTURER_ADD_EXISTING_DISTRIBUTOR_URL =
  "/api/v1/manufacturer/select-distributors";

export const DISTRIBUTOR_LINKED_MI_URL =
  "/api/v1/distributor/importers-manufacturers";
export const DISTRIBUTOR_UNLINKED_RETAILERS_URL =
  "/api/v1/distributor/retailers/unlinked";
export const DISTRIBUTOR_LINKED_RETAILERS_URL = "/api/v1/distributor/retailers";
export const DISTRIBUTOR_UNLINKED_MI_URL =
  "/api/v1/distributor/importers-manufacturers/unlinked";
export const DISTRIBUTOR_ADD_COMPANY_URL = "/api/v1/distributor/add-company";
export const DISTRIBUTOR_UNLINK_A_COMPANY_URL =
  "/api/v1/manufacturer/unlink-distributor/";
export const DISTRIBUTOR_UPLOAD_COMPANY_URL =
  "/api/v1/distributor/upload-companies";
export const DISTRIBUTOR_ADD_EXISTING_COMPANY_URL =
  "/api/v1/distributor/select-companies";

export const RETAILER_LINKED_DISTRIBUTORS_URL =
  "/api/v1/retailer/linked-distributors";
export const RETAILER_UNLINKED_DISTRIBUTORS_URL =
  "/api/v1/retailer/unlinked-distributors";
export const RETAILER_ADD_DISTRIBUTOR_URL = "/api/v1/retailer/add-distributor";
export const RETAILER_UNLINK_A_DISTRIBUTOR =
  "/api/v1/retailer/unlink-distributor/";
export const RETAILER_UPLOAD_DISTRIBUTORS_URL =
  "/api/v1/retailer/upload-distributors";
export const RETAILER_ADD_EXISTING_DISTRIBUTOR_URL =
  "/api/v1/retailer/select-distributors";

export const DISTRIBUTORS_LINKED_LSDP_URL =
  "/api/v1/distributor/delivery-providers";
export const DISTRIBUTORS_UNLINKED_LSDP_URL =
  "/api/v1/distributor/delivery-providers/unlinked";
export const DISTRIBUTOR_UNLINK_LSDPT_URL =
  "/api/v1/distributor/unlink-delivery-provider/";
export const DISTRIBUTOR_UPLOAD_LSDPT_URL =
  "/api/v1/distributor/upload-companies";
export const DISTRIBUTOR_ADD_LSDPT_URL = "/api/v1/distributor/add-company";

export const LSDP_LINKED_DISTRIBUTORS_URL =
  "/api/v1/delivery-providers/distributors";
export const LSDP_UNLINKED_DISTRIBUTORS_URL =
  "/api/v1//delivery-providers/distributors/unlinked";
export const LSDP_UNLINK_DISTRIBUTORS_URL =
  "/api/v1/delivery-providers/unlink-distributor/";
export const LSDP_UPLOAD_DISTRIBUTORS_URL =
  "/api/v1/delivery-providers/upload-distributors";
export const LSDP_ADD_EXISITING_DISTRIBUTORS_URL =
  "/api/v1/delivery-providers/select-distributors";
export const LSDP_ADD_DISTRIBUTORS_URL =
  "/api/v1/delivery-providers/add-distributor";

export const COMPANY_PRODUCTS = "/api/v1/company-products";
export const OTHER_BASE_SETTINGS_URL = "/api/v1/settings/other";

export const RECEIVED_INVOICES = "/api/v1/invoices/received";
export const INVOICES_URL = "/api/v1/invoices/";
export const GENERATE_CBS_INVOICE_URL = "/api/v1/payments/invoice/pay";

export const COMPANY_ADDRESSES_URL = "/api/v1/company/addresses/";
export const RFQ_QUOTATIONS = "/api/v1/quotations/rfq/";

export const COMPANIES_INVITATION = "/api/v1/invitation/companies";
export const COMPANY_INVITATION = "/api/v1/invitation/company";
export const ACCEPTED_INVITATIONS_URL = "/api/v1/invitations/accepted";
export const PENDING_INVITATIONS_URL = "/api/v1/invitations/pending";

export const LSDP_REQUEST_RESPONSE_URL =
  "/api/v1/delivery-providers/accept-decline-request";
export const LSDP_INBOUND_REQUEST_URL =
  "/api/v1/delivery-providers/received-requests";
export const LSDP_OUTBOUND_REQUEST_URL =
  "/api/v1/delivery-providers/sent-requests";

export const API_KEY = "/api/v1/api-keys";

export const LOGISTICS_COMPANIES_URL = "/api/v1/logistics/companies";
export const DELIVERY_REQUEST_URLS = "/api/v1/delivery-requests";
export const DELIVERIES = "/api/v1/deliveries";

export const LOGISTICS_APP_BASE_URL =
  process.env.REACT_APP_LOGISTICS_FRONTEND_BASE_URL;

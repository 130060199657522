import styled from "styled-components";
import { LoadingSkeleton } from ".";

const PageSkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .skeleton-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    width: 100%;
    background-color: ${({ theme }) => theme.bg.prim_500};

    @media (max-width: ${({theme}) => theme.screen.mobile}){
      display: none
    }

    .skeleton-section {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 2rem;
      padding: 1rem;
      border-radius: .25rem;
      box-shadow: ${({ theme }) => theme.shadow.shad_100};
    }
  }

  .skeleton-container-mobile {
    display: flex;
    flex-direction: column;
    padding: 2rem 0.5rem;
    gap: 1rem;
    width: 100%;
    background-color: ${({ theme }) => theme.bg.prim_500};

    @media (min-width: ${({theme}) => theme.screen.mobile}){
      display: none
    }

    .skeleton-section {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 1rem;
      padding: 1rem .25rem;
      border-radius: .25rem;
      box-shadow: ${({ theme }) => theme.shadow.shad_100};
    }
  }
`;

export interface IAppProps {}

export function PageSkeletonLoader(props: IAppProps) {
  return (
    <PageSkeletonWrapper>
      <div className="skeleton-container">
        <section className="top">
          <LoadingSkeleton width={"100%"} height="7rem" />
        </section>
        <section className="skeleton-section">
          <LoadingSkeleton width="30%" height="7rem" />
          <LoadingSkeleton width="30%" height="7rem" />
          <LoadingSkeleton width="30%" height="7rem" />
        </section>
        <section className="skeleton-section">
          <LoadingSkeleton width="20%" height="10rem" />
          <LoadingSkeleton width="20%" height="10rem" />
          <LoadingSkeleton width="20%" height="10rem" />
          <LoadingSkeleton width="20%" height="10rem" />
        </section>
      </div>


      <div className="skeleton-container-mobile">
        <section className="top">
          <LoadingSkeleton width={"100%"} height="7rem" />
        </section>
        <section className="skeleton-section">
          <LoadingSkeleton width="32%" height="5rem" />
          <LoadingSkeleton width="32%" height="5rem" />
          <LoadingSkeleton width="32%" height="5rem" />
        </section>
        <section className="skeleton-section">
          <LoadingSkeleton width="45%" height="10rem" />
          <LoadingSkeleton width="48%" height="10rem" />
        </section>
      </div>
    </PageSkeletonWrapper> 
  );
}

import { FaUserEdit } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import styled from "styled-components";
import profileImage from "../../../../assets/images/generic_face.png";
import { useAuthDetails } from "../../../../hooks/useAuthDetails";
import useLogout from "../../../../hooks/useLogout";
import { useEffect, useRef, useState } from "react";
import { KeyValueObj } from "../../../../common/types";
import { LuFileEdit } from "react-icons/lu";

export const ProfileWrapper = styled.div`
  width: 20rem;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  background: ${({ theme }) => theme.bg.prim_500};
  border-top: 2px solid #219653;
  z-index: -1;

  .section {
    width: 100%;
    padding: 1rem 1.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    border-bottom: 1px solid ${({ theme }) => theme.border.light};
  }

  .section.image-wrapper {
    flex-direction: row;
    align-items: center;
    img {
      width: 3rem;
      border-radius: 50%;
    }
  }

  .company {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.7rem;
  }

  .title {
    font-weight: 400;
    color: ${({ theme }) => theme.text.muted_200};
    font-size: 0.85rem;
  }

  .value {
    font-weight: 500;
    color: ${({ theme }) => theme.text.input};
    font-size: 0.85rem;
    word-wrap: break-word;
    text-transform: lowercase;
    text-transform: capitalize;
  }

  .name {
    font-weight: 500;
    color: ${({ theme }) => theme.text.primary};
    font-size: 1rem;
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 1.4rem;
    background-color: #fafafa;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.border.light};
  }

  .logout-icon {
    font-size: 1.2rem;
    color: red;
  }
  .edit-profile-icon {
    color: ${({ theme }) => theme.bg.secondary};
    font-size: 1.2rem;
  }

  .company-edit {
    position: relative;
  }

  .company-edit-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.bg.prim_500};
    padding: 1rem 0.5rem;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    top: 95%;
    margin-top: 0rem;

    button {
      background-color: ${({ theme }) => theme.bg.prim_500};
      outline: none;
      border: none;
      text-align: left;
      padding: 0.75rem 1rem;
      cursor: pointer;

      :hover {
        background-color: ${({ theme }) => theme.bg.prim_400};
      }
    }
  }

  .hide {
    display: none;
  }
`;

export interface IProfileCardProps {
  handleProfileEdit: () => void;
  handleShowEdit: (type: string) => void;
}

export function ProfileCard({
  handleProfileEdit,
  handleShowEdit,
}: IProfileCardProps) {
  const logout = useLogout();
  const {
    firstName,
    lastName,
    address,
    middleName,
    userId,
    email,
    phone,
    role,
    companyName,
    companyType,
  } = useAuthDetails();

  const [authDetails] = useState<KeyValueObj>({
    firstName,
    lastName,
    address,
    middleName,
    userId,
    phone,
    email,
    role,
    companyName,
    companyType,
  });

  const [showCompanyOptions, setShowCompanyOptions] = useState(false);
  const lastButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    lastButtonRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [lastButtonRef, showCompanyOptions]);

  return (
    <>
      <ProfileWrapper>
        <div className="image-wrapper section">
          <img src={profileImage} alt="profile" />
          <div className="company">
            <h2 className="name">
              {authDetails.firstName} {authDetails.lastName}
            </h2>
            <h3 className="title">{authDetails.companyName}</h3>
          </div>
        </div>
        <div className="section">
          <h3 className="title">Email Address</h3>
          <h3 className="value">{authDetails.email}</h3>
        </div>
        <div className="section">
          <h3 className="title">Phone</h3>
          <h3 className="value">{authDetails.phone}</h3>
        </div>
        <div className="section">
          <h3 className="title">Address</h3>
          <h3 className="value">{authDetails.address}</h3>
        </div>
        <div className="section">
          <h3 className="title">Company Type</h3>
          <h3 className="value">{authDetails.companyType}</h3>
        </div>
        <div className="action-wrapper" onClick={() => handleProfileEdit()}>
          <FaUserEdit className="edit-profile-icon" />{" "}
          <h2 className="name">Edit Profile</h2>
        </div>
        <div
          className="action-wrapper company-edit"
          onClick={() => setShowCompanyOptions(!showCompanyOptions)}
        >
          <LuFileEdit className={`edit-profile-icon`} />
          <h2 className="name">Edit Company Data</h2>
          <div
            className={`company-edit-options ${
              showCompanyOptions ? "" : "hide"
            }`}
          >
            <button onClick={() => handleShowEdit("edit-company-info")}>
              Edit Company Information
            </button>
            <button onClick={() => handleShowEdit("edit-contact-info")}>
              Edit Contact Person Info{" "}
            </button>
            <button onClick={() => handleShowEdit("edit-company-address")}>
              Edit Company Addreess
            </button>
            <button
              ref={lastButtonRef}
              onClick={() => handleShowEdit("edit-file-upload")}
            >
              Edit File Uploads
            </button>
          </div>
        </div>
        <div onClick={() => logout()} className="action-wrapper">
          <FiLogOut className="logout-icon" /> <h2 className="name">Log Out</h2>
        </div>
      </ProfileWrapper>
    </>
  );
}

import { useEffect } from "react";
import styled from "styled-components";
import { PageContainr } from "../../common/elements";

export interface IRegisterSuccessProps {}

const SuccessrWrapper = styled(PageContainr)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10rem;
  gap: 2rem;

  .title {
    font-size: 3rem;
    font-weight: 500;
    color: ${({ theme }) => theme.text.input};
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${({ theme }) => theme.text.muted_200};
    width: 40rem;
    max-width: 90%;
    text-align: justify;
  }
`;

export function RegisterSuccess(props: IRegisterSuccessProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SuccessrWrapper>
      <h1 className="title">Your registeration is succesful !!</h1>
      <p>
        A one time password(OTP) has been sent to your phone number while the
        link to the verification was sent to your email. Check your email and
        follow the link, then enter the OTP sent to your phone for a one swipe
        phone number and email verification.
      </p>
    </SuccessrWrapper>
  );
}

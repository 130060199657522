import { useState } from "react";
import { KeyValueObj, RequestConfig } from "../common/types";
import { useAxiosAuth } from "./useAxiosAuth";
import useLogout from "./useLogout";

const useAxiosCall = () => {
  const [response, setResponse] = useState<any>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<KeyValueObj>({});
  const [, setController] = useState<AbortController>();

  const axiosAuth = useAxiosAuth();
  const logout = useLogout();

  async function axiosCall(configObj: RequestConfig) {
    const { method, url, requestConfig = {} } = configObj;
    setLoading(true);
    try {
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosAuth[method.toLowerCase()](url, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      if (res.data.success) {
        setMeta(res.data.meta);
        setResponse(res.data.data);
        setError("");
        return [res.data.data, res.data.meta];
      } else {
        setError("Something went wrong");
      }
    } catch (err: any) {
      if (err?.response?.request?.status === 401) {
        return logout();
      }
      setError(err);
      console.log(err);
    } finally {
      setLoading(false);
      return [response, error, loading];
    }
  }

  return [axiosCall, response, error, loading, meta];
};

export default useAxiosCall;

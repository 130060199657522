import { RFQDetailsForm } from "../subs/rfqdetails";
import { ViewRfqContainer } from "./styles";
import { RFQ_QUOTATIONS, RFQ_URL } from "src/common/constants";
import useQuery from "src/hooks/useQuery";
import { useParams } from "react-router-dom";
import { PageTitleNavs } from "src/common/components/page-title-navs";

interface IViewRFQProps {}

export function ViewRFQ(props: IViewRFQProps) {
  const { id } = useParams();

  const { data: currentRfq, loading: rfqLoading } = useQuery(
    RFQ_URL + "/" + id
  );
  const { data: rfqQuotations } = useQuery(RFQ_QUOTATIONS + id);

  return (
    <ViewRfqContainer>
      <PageTitleNavs
        title="View RFQ"
        backLink="/dashboard/rfq"
        bcrumbs={[
          { title: "RFQs", link: "/dashboard/rfq" },
          { title: "View", link: "#" },
        ]}
      />
      <RFQDetailsForm
        rfqQuotations={rfqQuotations}
        rfq={currentRfq}
        rfqLoading={rfqLoading}
      />
    </ViewRfqContainer>
  );
}

import styled from "styled-components";
import { BsTrash } from "react-icons/bs";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { Button } from "../button";
import { useState } from "react";

const PromptWrapper = styled.div`
  width: 30rem;
  min-height: 23rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3rem;
  max-width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
   padding: 1rem;
   min-height: 20rem;;
   
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
  }
  .controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .icon {
    font-size: 3rem;
    color: ${({ theme }) => theme.bg.secondary};
  }

  .prompt-input-text {
    width: 100%;
    outline: none;
    border: 1px solid ${({ theme }) => theme.border.input};
    padding: 1rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text.prim_100};
    resize: none;
    border-radius: 0.25rem;
  }
`;

export interface IPromptProps {
  type ?: "delete" | "reject";
  prompt: string;
  onMainAction: (options?: any) => void;
  onSecondaryAction: (options?: any) => void;
  mainActionText?: string;
  secondaryActionText?: string;
  mainLoading?: boolean;
  secondaryLoading?: boolean;
  withInput?: boolean;
}

const icons = {
  delete: <BsTrash className="icon" />,
  reject: <LiaFileInvoiceDollarSolid className="icon" />,
};

export function Prompt({
  type,
  prompt,
  onMainAction,
  onSecondaryAction,
  mainActionText,
  secondaryActionText,
  mainLoading,
  secondaryLoading,
  withInput,
}: IPromptProps) {
  const [inputText, setInputText] = useState("");
  return (
    <PromptWrapper>
      { type ? icons[type] : null}
      <p className="prompt-text">{prompt}</p>
      {withInput ? (
        <textarea
          className="prompt-input-text"
          value={inputText}
          onChange={(e: any) => setInputText(e.target.value)}
        ></textarea>
      ) : null}
      <div className="controls">
        <Button
          loading={secondaryLoading}
          secondary
          onClick={() => onSecondaryAction(inputText)}
        >
          {secondaryActionText ?? "Cancel"}
        </Button>
        <Button
          loading={mainLoading}
          onClick={(e: any) => onMainAction(inputText)}
        >
          {mainActionText || "Confirm"}
        </Button>
      </div>
    </PromptWrapper>
  );
}

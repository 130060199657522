import styled from "styled-components";

export const RequisitionWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  padding-bottom: 10rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  .requisitions {
    padding: 2rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    .tabs-wrapper {
      margin-bottom: 2rem;
    }

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 0.5rem;
    }
  }
`;

interface ActionProps {
  position: any;
}
export const ActionsWrapper = styled.div<ActionProps>`
  .backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .action-items {
    width: 15rem;
    background-color: ${({ theme }) => theme.bg.primary};
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    position: fixed;
    ${({ position }) => position.cordinate + ":" + position.value};
    opacity: 0;
    right: 2rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1rem;
    opacity: 1;
    right: 3rem;

    .no-action {
      font-weight: 500;
      color: ${({ theme }) => theme.text.input};
    }
  }
`;

export const tableKeys = [
  "name",
  "brand",
  "distributor",
  "deliveryDate",
  "productQuantity",
  "productPackaging",
  "paymentTerms",
];

export const tableHeaders = [
  <div style={{ width: "5rem" }}>Name</div>,
  "Brand",
  "Distributor",
  <div style={{ width: "7.5rem" }}>Expected Delivery Date</div>, 
  "Quantity",
  "Packaging",
  "Payment Terms",
];

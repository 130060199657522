/* eslint-disable react-hooks/exhaustive-deps */
import validator from "validator";
import { InputWrapper } from "./Input.style";
import { useState, useRef, useEffect } from "react";
import { BsEyeSlash, BsEye, BsUpload, BsQuestionSquare } from "react-icons/bs";
import {
  isContainLower,
  isContainSpecialChar,
  isContainUpper,
} from "../../utils";
import { isBefore, isInTheFuture, isOnlyDigits } from "../../utils/isContain";
import { ClipSpinner } from "../spinner";
import CustomTooltip from "../Tooltip";

function Input(props) {
  const {
    list,
    prompt,
    name,
    inputType,
    clearAllFields,
    updateData,
    rules,
    formValues,
    login,
    placeholder,
    fullWidth,
    shortWidth,
    updateValidity,
    indexLevel,
    maxLength,
    readOnly,
    initialValue,
    loadingState,
    tooltip,
  } = props;

  let timeOut;

  const [currentValue, setCurrentValue] = useState("");
  const [isValidInput, setIsValidInput] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [, setInputValue] = useState(null);
  const [checkBoxItems, setCheckBoxItem] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [fileName, setFileName] = useState("");

  const inputRef = useRef();

  useEffect(() => {
    if (initialValue) {
      setIsValidInput(true);
      setCurrentValue(initialValue);
      updateData(name, initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    updateValidity(name, isValidInput);

    return () => clearTimeout(timeOut);
  }, [isValidInput]);

  function handleCheckBoxChange(e) {
    const { id, checked } = e.target;
    const prevItems = checkBoxItems;
    const index = prevItems.indexOf(id);

    if (checked) {
      prevItems.push(id);
      setCheckBoxItem(prevItems);
    } else {
      prevItems.splice(index, 1);
    }

    setInputValue(checkBoxItems);
    validateData(checkBoxItems);
  }

  function validateData(value) {
    if (!rules) {
      setIsValidInput(true);
      return;
    }

    let errorMessagesIn = [];
    setIsValidInput(true);
    Object.keys(rules).map((ruleKey) => {
      switch (ruleKey) {
        case "maxLength":
          if (value.length > rules[ruleKey].expectedValue) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }

          break;

        case "minLength":
          if (value.length < rules[ruleKey].expectedValue) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "length":
          if (value.length !== rules[ruleKey].expectedValue) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;
        case "contains":
          if (!validator.contains(value, rules[ruleKey].expectedValue)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }

          break;

        case "isEmail":
          if (!(validator.isEmail(value) === rules[ruleKey].expectedValue)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }

          break;

        case "compareWithExisting":
          if (!(formValues[rules[ruleKey].expectedValue] === value)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "validNigerianPhoneNumber":
          if (
            false
            // validatePhoneNumberSync(value).isValid ===
            // rules[ruleKey].expectedValue
          ) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "containSpecialChar":
          if (!isContainSpecialChar(value)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;
        case "containUppercase":
          if (!isContainUpper(value)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;
        case "containLowercase":
          if (!isContainLower(value)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;
        case "onlyDigit":
          if (!isOnlyDigits(value)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "notInTheFuture":
          if (isInTheFuture(value) === rules[ruleKey].expectedValue) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "notBefore":
          if (!isBefore(value, formValues[rules[ruleKey].expectedValue])) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "imageType":
          if (value && value["type"].split("/")[0] !== "image") {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "maxSize":
          if (value && value.size > rules[ruleKey].expectedValue) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "aboveZero":
          if (!(parseInt(value) > 0)) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;
        case "notNegative":
          if (parseInt(value) < 0) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        case "maxNumber":
          if (parseInt(value) > rules[ruleKey].expectedValue) {
            setIsValidInput(false);
            errorMessagesIn.push(rules[ruleKey].errorMessage);
          }
          break;

        default:
          break;
      }
      return null;
    });
    setErrorMessages(errorMessagesIn);
  }

  function handleChange(e) {
    const value = e.target.value;
    validateData(value);
    setInputValue(value);
    setCurrentValue(value);
    updateData(name, value);
  }

  function handleFileChange(e) {
    const chosenFile = e.target.files && e.target.files[0];
    if (!chosenFile) return;
    const fileName = chosenFile.name;
    const onlyNameIndex = fileName.lastIndexOf(".");
    const onlyName = fileName.substr(0, onlyNameIndex);
    if (onlyName.length > 20) {
      const fileExt = fileName.substr(onlyNameIndex, fileName.length);
      setFileName(onlyName.substr(0, 20) + ".." + fileExt);
    } else {
      setFileName(fileName);
    }

    validateData(chosenFile);
    setInputValue(chosenFile);
    updateData(name, chosenFile);
  }

  if (clearAllFields) {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }

  return (
    <InputWrapper
      shortWidth={shortWidth}
      indexLevel={indexLevel}
      fullWidth={fullWidth}
      login={login}
    >
      <div className="label-wrapper">
        <label>{prompt}</label>
        {tooltip ? (
          <CustomTooltip title={tooltip}>
            <div>
              <BsQuestionSquare className="tooltip-icon" />
            </div>
          </CustomTooltip>
        ) : null}
      </div>

      <div className="inner-our-input">
        <div className="inner-level-2">
          {loadingState && (
            <div className="loading-spinner">
              <ClipSpinner />
            </div>
          )}
          {inputType === "checkBox" ? (
            <div className="checkbox-wrapper">
              {list.split(",").map((item, i) => {
                return (
                  <div key={i} className="checkbox-item">
                    <input
                      id={item}
                      onChange={handleCheckBoxChange}
                      type="checkbox"
                      name={name}
                    />{" "}
                    <label>{item}</label>
                  </div>
                );
              })}
            </div>
          ) : inputType === "textarea" ? (
            <textarea
              ref={inputRef}
              onChange={handleChange}
              name={name}
              // autoFocus={true}
              value={currentValue}
              readOnly={readOnly}
              placeholder={placeholder}
              maxLength={maxLength ? maxLength : null}
            ></textarea>
          ) : inputType === "password" ? (
            <div className="password-input">
              <input
                ref={inputRef}
                onChange={handleChange}
                name={name}
                type={showPassword ? "text" : "password"}
                value={currentValue}
                placeholder={placeholder}
                readOnly={readOnly}
                maxLength={maxLength ? maxLength : null}
              />
              {showPassword ? (
                <BsEyeSlash
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-eye"
                />
              ) : (
                <BsEye
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-eye"
                />
              )}
            </div>
          ) : inputType === "fileInput" ? (
            <div className="file-input">
              <label className="file-input-label">
                {placeholder}
                {isValidInput && <span>{fileName}</span>}

                <input
                  ref={inputRef}
                  onChange={handleFileChange}
                  name={name}
                  type="file"
                  style={{ display: "none" }}
                />
              </label>
              <BsUpload className="icon" />
            </div>
          ) : (
            <input
              ref={inputRef}
              onChange={handleChange}
              name={name}
              type={inputType}
              value={currentValue}
              placeholder={placeholder}
              maxLength={maxLength ? maxLength : null}
              readOnly={readOnly}
            />
          )}
        </div>

        {errorMessages.length > 0 ? (
          <div className="error-message">
            {errorMessages.map((errorMessage, i) => (
              <p key={i}>{errorMessage}</p>
            ))}
          </div>
        ) : null}
      </div>
    </InputWrapper>
  );
}

export default Input;

import { KeyValueObj } from "src/common/types";
import styled from "styled-components";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin3Line } from "react-icons/ri";
import { ClipSpinner } from "../spinner";
import { LoadingSkeleton } from "../skeleton";

type SchemeProps = {
  isSelected: boolean;
};

export const AddressWrapper = styled.div<SchemeProps>`
  width: 100%;
  min-height: 6rem;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  justify-content: flex-start;
  gap: 2rem;
  padding: 0.5rem 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
  border-radius: 0.15rem;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.bg.sec_100 : "transparent"};

  :hover {
    border: 1px solid ${({ theme }) => theme.border.input};
  }

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    gap: 1rem;
    padding: 0.5rem;
  }

  .dot {
    min-width: 1.5rem;
    min-height: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.bg.sec_100};
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-dot {
      transition: all 0.3s ease-in-out;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.bg.sec_100};
      width: ${({ isSelected }) => (isSelected ? "1rem" : "0")};
      height: ${({ isSelected }) => (isSelected ? "1rem" : "0")};
    }
  }

  .address-details {
    width: 70%;
  }

  .address-lines {
    font-size: 0.95rem;
    font-weight: 500;
    color: ${({ theme }) => theme.text.prim_100};
    width: 100%;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      font-size: 0.85rem;
    }
  }
  .states {
    font-size: 0.95rem;
    font-weight: 500;
    margin-top: 0.35rem;
    color: ${({ theme }) => theme.text.prim_50};
    
    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      font-size: 0.85rem;
    }
  }

  .address-controls {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;

    button {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 0.9rem;
      cursor: pointer;
      display: flex;
      gap: 0.35rem;
    }

    .delete-button:hover {
      color: red;
    }

    .edit-button:hover {
      color: ${({ theme }) => theme.bg.sec_100};
    }
  }

  .skeleton-dot {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    overflow: hidden;
  }
`;

export interface IAddressProps {
  address: KeyValueObj;
  handleAddressSelected: (address: KeyValueObj) => void;
  selectedAddress: KeyValueObj;
  handleDeleteAddress?: (id: string) => void;
  handleEditAddress?: (address: KeyValueObj) => void;
  deletingId?: string;
  removeControl?: boolean;
}

export function Address({
  address,
  selectedAddress,
  handleAddressSelected,
  handleDeleteAddress,
  handleEditAddress,
  deletingId,
  removeControl,
}: IAddressProps) {
  return (
    <AddressWrapper
      isSelected={selectedAddress?.id === address?.id}
      onClick={() => handleAddressSelected(address)}
    >
      <div className="dot">
        <div className={`inner-dot`}></div>
      </div>
      <div className="address-details">
        <p className="address-lines">
          {address?.address_line_one}, {address?.address_line_two},{" "}
          {address?.city}
        </p>
        <p className="states">
          {address?.lga}, {address?.state} - ({address?.postal_code})
        </p>
      </div>
      {removeControl ? null : (
        <div className="address-controls">
          <button
            onClick={() => handleEditAddress?.(address)}
            className="edit-button"
          >
            Edit <CiEdit />
          </button>
          <button
            onClick={() => handleDeleteAddress?.(address?.id)}
            className="delete-button"
          >
            Delete <RiDeleteBin3Line />{" "}
            {deletingId === address?.id ? (
              <ClipSpinner color="red" size={16} />
            ) : null}
          </button>
        </div>
      )}
    </AddressWrapper>
  );
}

export function AddressSkeleton() {
  return (
    <AddressWrapper isSelected={false}>
      <div className="skeleton-dot">
        <LoadingSkeleton noPadding width={"1.5rem"} height="1.5rem" />
      </div>
      <div className="address-details">
        <p className="address-lines">
          <LoadingSkeleton width={"100%"} height="1.8rem" />
        </p>
        <p className="states">
          <LoadingSkeleton width={"100%"} height="1.8rem" />
        </p>
      </div>
      <div className="address-controls">
        <LoadingSkeleton width="6rem" height="1.6rem" />
        <LoadingSkeleton width="6rem" height="1.6rem" />
      </div>
    </AddressWrapper>
  );
}

import { LoadingSkeleton } from "src/common/components/skeleton";
import styled from "styled-components";

const ProductSkeletonWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  align-items: center;

  @media screen and (max-width:  800px) and (min-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
   
  }

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    grid-template-columns: repeat(1, 1fr);
   
  }

  .product-skeleton {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    padding: 0 0 2rem;

    .padded {
      padding: 0 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
  }
`;

export interface IProductLoadingSkeletonProps {}

export function ProductLoadingSkeleton(props: IProductLoadingSkeletonProps) {
  return (
    <ProductSkeletonWrapper>
      { Array(8).fill(0).map(() => ( 
        <div className="product-skeleton">
          <LoadingSkeleton width="100%" height="6rem" noPadding />
          <div className="padded">
            <LoadingSkeleton noPadding width="50%" height="1rem" />
            <LoadingSkeleton noPadding width="50%" height="1rem" />
            <LoadingSkeleton noPadding width="100%" height="1rem" />
          </div>
        </div>
      ))}
    </ProductSkeletonWrapper>
  );
}

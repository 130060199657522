import { Button } from "src/common/components/button";
import { KeyValueObj } from "src/common/types";
import styled from "styled-components";
import { ViewCompanyDetails } from "src/common/components/view-company";

const CompanyWrapper = styled.div`
  width: 45rem;
  padding: 1.5rem;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 1rem 0.2rem;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    border-radius: 0.45rem;

    .top-container {
    }

    .other-info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        padding: 1rem 0.1rem;
      }

      .info {
        display: flex;
        gap: 1.5rem 1rem;
        flex-wrap: wrap;
        justify-content: space-between;
        
        @media (max-width: ${({ theme }) => theme.screen.mobile}) {
          gap: 1rem 0.5rem
  }
      }

      .key-wrapper {
        width: 30%;
      }

      .controls {
        width: 100%;
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
      }

      .centered-controls {
        justify-content: center;
        margin-top: 3rem;
        display: flex;
      }
    }
  }
`;

export interface IViewCompanyProps {
  data: KeyValueObj;
  onResponse: (companyId: string, response: string) => void;
  respondLoading: string;
  requestType: string;
}

export function ViewCompany({
  data,
  onResponse,
  respondLoading,
  requestType,
}: IViewCompanyProps) {
  return (
    <CompanyWrapper>
      <div className="container">
        <ViewCompanyDetails data={data} />

        <div className="other-info">
          {requestType === "Inbound" ? (
            <div className="controls">
              <Button
                loading={respondLoading === "decline"}
                disabled={respondLoading === "decline"}
                secondary
                onClick={() => onResponse(data?.id, "decline")}
              >
                Decline
              </Button>
              <Button
                loading={respondLoading === "accept"}
                disabled={respondLoading === "accept"}
                onClick={() => onResponse(data?.id, "accept")}
              >
                Accept
              </Button>
            </div>
          ) : (
            <div className="centered-controls">
              <Button
                loading={respondLoading === "decline"}
                disabled={respondLoading === "decline"}
                onClick={() => onResponse(data?.id, "withdraw")}
              >
                Withdraw
              </Button>
            </div>
          )}
        </div>
      </div>
    </CompanyWrapper>
  );
}

import { createContext, useState } from "react";

interface AuthProps {
  children: JSX.Element;
}

const StoreContext = createContext<any>({});

export const GeneralProvider = ({ children }: AuthProps) => {
  const [currentModal, setCurrentModal] = useState("");
  const [modalOptions, setModalOptions] = useState<any>();

  const [currentLevel2Modal, setCurrentLevel2Modal] = useState("");
  const [level2ModalOptions, setLevel2ModalOptions] = useState<any>();
  const [removeMainScroller, setRemoveMainScroller] = useState(false);

  return (
    <StoreContext.Provider
      value={{
        currentModal,
        setCurrentModal,
        modalOptions,
        setModalOptions,
        level2ModalOptions,
        setLevel2ModalOptions,
        currentLevel2Modal,
        setCurrentLevel2Modal,
        removeMainScroller,
        setRemoveMainScroller
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;

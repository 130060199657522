import styled from "styled-components";

export const RfqWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  .top-wrapper {
    width: 100%;
    gap: .5rem;
    align-items: flex-start
  }

  .inputs {
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .input-wrapper {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    flex-shrink: 0;

    label {
      font-size: 0.85rem;
      font-weight: 400;
      color: ${({ theme }) => theme.text.input};
    }

    input,
    textarea {
      width: 20rem;
      padding: 0.7rem;
      outline: none;
      border: 1px solid ${({ theme }) => theme.border.input};
      color: ${({ theme }) => theme.text.prim_100};
      border-radius: 0.2rem;
    }

    textarea {
      height: 5rem;
      resize: none;
    }

    h4 {
      font-weight: 400;
      margin-top: -0.5rem;
    }
  }
`;

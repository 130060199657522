import { useEffect, useState, FormEvent, MouseEvent } from "react";
import { Link } from "react-router-dom";
import Table from "../../../common/components/Table";
import { ORDERS_URL } from "../../../common/constants";
import { Button, H4 } from "../../../common/elements";
import useAxiosCall from "../../../hooks/useAxiosCall";
import { RequisitionWrapper } from "./subs/style";
import { filterOptions, getTableHeaders, getTableKeys } from "./subs/data";
import { KeyValueObj } from "../../../common/types";
import useAuth from "../../../hooks/useContexts";
import { useApiCallHandling } from "../../../hooks/useApiCallHandling";
import Tab from "../../../common/components/Tab/Tab";
import { useAuthDetails } from "../../../hooks/useAuthDetails";
import { Pagination } from "../../../common/components/pagination";
import { usePositioning } from "../../../hooks/usePositioning";
import { PopupWrapper } from "../../../common/elements/containers";
import { Modal } from "../../../common/components/modal";
import { ActionPopup } from "src/common/components/action-popup";
import {
  formatKey,
  getAddress,
  toParamsFormat,
} from "src/common/utils/general";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { RespondViewRfq } from "../rfq/respond-rfq/respond-view-rfq";
import { ShippingDetails } from "./subs/ship-details";
import { BatchSelector } from "./subs/batch-selector";
import {
  IAddedBatch,
  IBatchData,
  IProductBatches,
} from "src/common/types/others";
import { ActionEnquiry } from "./subs/action-enquiry";

export interface IRequisitionProps {}

interface ActionFormData {
  status: string;
  reason_for_rejection?: string;
  estimated_delivery_date?: string;
}

export function Orders(props: IRequisitionProps) {
  //STATES
  const [showActions, setShowActions] = useState(false);
  const [actions, setActions] = useState<string[]>([]);
  const [tableData, setTableData] = useState<KeyValueObj[]>([]);
  const [orderId, setOrderId] = useState("");
  const [orders, setOrders] = useState<KeyValueObj[]>([]);
  const [retry, setRetry] = useState(0);
  const [, setLoading] = useState(false);
  const [orderType, setOrderType] = useState("received");
  const [, setNoInboundOrderMessage] = useState(
    "No incoming orders placed yet, incoming orders will show here when you have one."
  );
  const [, setNoOutboundOrderMessage] = useState(
    "You have not placed any order yet, orders you placed will show once."
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [pageCount, setPagCount] = useState(1);
  const [showShippingDetails, setShowShippingDetails] = useState(false);
  const [showActionEnquiry, setShowActionEnquiry] = useState(false);
  const [enquiryType, setEnquiryType] = useState("");
  const [enquiryPrompt, setEnquiryPrompt] = useState("");
  const [enquiryLoading, setEnquiryLoading] = useState(false);
  const [enquiryText, setEnquiryText] = useState("");
  const [showEnquiryText, setShowEnquiryText] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<KeyValueObj>({});
  const [showOrder, setShowOrder] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [productBatches, setProductBatches] = useState<IProductBatches>({});
  const [currentProductBatches, setCurrentProductBatches] = useState<
    IBatchData[]
  >([]);
  const [showBatchAdd, setShowBatchAdd] = useState(false);
  const [currentOrderProduct, setCurrentOrderProduct] = useState<KeyValueObj>(
    {}
  );
  const [shipping, setShipping] = useState(false);
  const [retryingNotification, setRetryingNotification] = useState(false);

  //CUSTOM HOOKES
  const [fetchReqs, fetchedReqs, reqsError, reqsLoading, meta] = useAxiosCall();
  const { auth } = useAuth();
  const apiCall = useApiCallHandling();
  const { companyType } = useAuthDetails();

  const [searching, setSearching] = useState(false);
  const [setCordinate, cordinate] = usePositioning();

  //FUNCTION HANDLERS
  function dotClicked(e: any, rowId: string) {
    //set the order id to know which one we are dealing with
    setCordinate(e);
    setOrderId(rowId);

    const selectedReq = orders.find(
      (req: KeyValueObj) => req.id === rowId
    ) as KeyValueObj;
    const orderStatus = selectedReq.order_status;
    const deliveryStatus = selectedReq.delivery_status;

    if (orderType === "sent") {
      //Every thing about a distrubutor comes here
      if (deliveryStatus === "pending" && orderStatus !== "rejected") {
        setActions(["View", "Cancel"]);
      } else if (deliveryStatus === "shipped") {
        setActions(["Goods Received"]);
      } else {
        setActions(["View"]);
      }
    } else if (orderType === "received") {
      switch (orderStatus) {
        case "draft":
          setActions(["View", "Accept", "Reject"]);
          break;
        case "accepted":
          if (deliveryStatus === "pending")
            setActions(["View", "Ship", "Cancel"]);
          else if (deliveryStatus === "shipped") setActions(["View", "Cancel"]);
          else if (deliveryStatus === "received") setActions(["View"]);
          break;

        default:
          setActions(["View"]);
      }
    }

    setShowActions(true);
  }

  function setTableLoading(flag: boolean) {
    /*set the button in the table to loading state*/
    const index = tableData.findIndex((data: any) => data.id === orderId);
    const tableRow = tableData[index];
    tableRow.action = { ...tableRow.action, buttonLoading: flag };
    const tempTable = [...tableData];
    tempTable[index] = tableRow;
    setTableData(tempTable);
  }

  function handleShip() {
    setTableLoading(false);
    setLoading(false);
    setEnquiryPrompt("Pick an estimated date of delivery");
    setEnquiryType("date");
    setShowShippingDetails(true);
    setShowActions(false);
  }
  function onActionClicked(action: string) {
    const currentOrderInner = orders.find(
      (order: KeyValueObj) => order.id === orderId
    );
    setCurrentOrder(currentOrderInner ?? {});
    let subtotal = 0;
    currentOrderInner?.order_products?.forEach((product: KeyValueObj) => {
      subtotal += product?.price_per_unit * product?.quantity;
    });

    if (action?.toLowerCase() === "view") {
      setSubTotal(subtotal);
      setShowOrder(true);
      setShowActions(false);
      return;
    }

    setTableLoading(true);
    setLoading(true);

    //create the approprate url
    let url: string = "";
    let formData: ActionFormData = { status: "" };
    switch (action) {
      case "Accept":
        url = ORDERS_URL + "/assess/" + orderId;
        formData = { status: "accept" };
        break;

      case "Reject":
        setTableLoading(false);
        setLoading(false);
        setEnquiryPrompt(
          "Are you sure you want to reject this Order? if yes, kindly state reason below"
        );
        setEnquiryType("reason");
        setShowActionEnquiry(true);

        break;

      case "Cancel":
        url = ORDERS_URL + "/assess/" + orderId;
        formData = { status: "reject" };
        break;

      case "Ship":
        handleShip();
        break;

      case "Goods Received":
        url = ORDERS_URL + "/received/" + orderId;
        formData = { status: "delivered" };
        break;
      default:
        url = "";
    }

    action !== "Reject" && action !== "Ship" && handleActionCall(url, formData);
  }

  async function handleActionCall(url: string, formData: ActionFormData) {
    await apiCall(
      url,
      "POST",
      formData,
      (data: any) => {
        const gottenData = data.order ? data.order : data;
        const index = orders.findIndex(
          (order: KeyValueObj) => order.id === gottenData.id
        );
        setOrders((prev: KeyValueObj[]) => {
          const copy = [...prev];
          copy[index] = gottenData;
          return copy;
        });
        setShowActions(false);
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        setTableLoading(false);
        setLoading(false);
        setEnquiryLoading(false);
      }
    );
  }

  async function onEnquiryContinue(text: string) {
    setEnquiryLoading(true);
    let url = "";
    let formData: ActionFormData = { status: "" };

    if (enquiryType === "reason") {
      url = ORDERS_URL + "/assess/" + orderId;
      formData = { status: "reject", reason_for_rejection: text };
    } else if (enquiryType === "date") {
      url = ORDERS_URL + "/shipped/" + orderId;
      formData = { status: "shipped", estimated_delivery_date: text };
    }

    await handleActionCall(url, formData);
    setShowActionEnquiry(false);
    setEnquiryLoading(false);
  }

  function updateTableData(queryParams: string) {
    setSearching(true);
    apiCall(
      `${ORDERS_URL}/${orderType}${queryParams}`,
      "GET",
      {},
      (data, metaData) => {
        setOrders(data);
        typeof metaData.total_pages === "number" &&
          setPagCount(metaData.total_pages);
        if (data.length === 0)
          setNoOutboundOrderMessage(
            "The filter or search term you entered returned no data!"
          );
        setNoInboundOrderMessage(
          "The filter or search term you entered returned no data!"
        );
      },
      (err) => console.log(err),
      () => setSearching(false),
      { dontNotifyOnSucess: true, dontNotifyonFail: true }
    );
  }

  function handleSearch(value: string) {
    let values = { page, perPage, search_string: value };
    let params = toParamsFormat(values);
    updateTableData(params);
  }

  function handlOrderFilter(values: KeyValueObj) {
    values["page"] = page;
    values["per_page"] = perPage;
    const params = toParamsFormat(values);
    updateTableData(params);
  }

  function onMouseLeaveTablStatus(e: MouseEvent<HTMLElement>, rowId: string) {
    setShowEnquiryText(false);
  }

  function onMouseEnterTableStatus(e: MouseEvent<HTMLElement>, rowId: string) {
    const order = orders.find((order: KeyValueObj) => order.id === rowId);
    setCordinate(e);
    if (order?.order_status === "rejected") {
      const rejectionReason = order?.reason_for_rejection;
      rejectionReason
        ? setEnquiryText(rejectionReason)
        : setEnquiryText("No reason was given for this rejection");
      setShowEnquiryText(true);
    } else {
      if (order?.delivery_status === "shipped") {
        const estimatedDeliveryDate = order?.estimated_delivery_date_range;
        estimatedDeliveryDate
          ? setEnquiryText(
              "Estimated delivery date: " +
                estimatedDeliveryDate.split("...").join(" to ")
            )
          : setEnquiryText("No estimated delivery date");
        setShowEnquiryText(true);
      }
    }
  }

  //USEEFFECTS
  useEffect(() => {
    if (companyType?.toLowerCase() === "retailer") setOrderType("sent");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyType]);

  useEffect(() => {
    fetchReqs({
      method: "GET",
      url:
        ORDERS_URL +
        `/${
          orderType === "sent" ? "outbound" : "inbound"
        }?page=${page}&per_page=${perPage}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, retry, orderType, perPage, page]);

  //Handles when requisitions are fetched
  useEffect(() => {
    setOrders(fetchedReqs);
    typeof meta.total_pages === "number" && setPagCount(meta.total_pages);
  }, [fetchedReqs, meta.total_pages, orderType]);

  //Handles setting the table data once orders change
  useEffect(() => {
    const tempData = orders?.map((req: KeyValueObj) => ({
      id: req.id,
      date: {
        type: "string",
        value: req.expected_delivery_date,
      },
      deliveryAddress: {
        type: "string",
        value: getAddress(req?.address),
      },
      paymentTerm: {
        type: "string",
        value: req?.payment_term,
      },
      client: {
        type: "string",
        value:
          orderType === "sent" ? req.distributor_info?.name : req?.owner?.name,
      },
      callbackStatus: {
        type: "string",
        value: formatKey(req?.external_inventory_callback_status),
      },
      status: {
        type: "status",
        statusType:
          req.delivery_status === "pending"
            ? req.order_status === "draft"
              ? "pending"
              : req.order_status
            : req.delivery_status,
        value:
          req.delivery_status === "pending"
            ? req.order_status === "draft"
              ? "pending"
              : req.order_status
            : req.delivery_status,
        onMouseInnerEnter: onMouseEnterTableStatus,
        onMouseInnerLeave: onMouseLeaveTablStatus,
      },
      action: {
        type: "button",
        buttonType: "dots",
        buttonLoading: false,
        buttonName: "",
        onClick: dotClicked,
      },
    }));

    setTableData(tempData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, companyType]);

  function handleNextPage(event: { selected: number }) {
    setPage(event.selected + 1);
  }

  function handlePerPage(event: FormEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    value.length > 0 && setPerPage(+value);
  }

  function handleAddBatchContinue(
    addedBatches: IAddedBatch[],
    productId: string
  ) {
    if (addedBatches.length > 0) {
      setProductBatches((prev: IProductBatches) => ({
        ...prev,
        [productId]: addedBatches,
      }));
    }
    setShowBatchAdd(false);
  }

  const handleProductSelected = (
    productBatches: IBatchData[],
    currentOrderProduct: KeyValueObj
  ) => {
    setCurrentProductBatches(productBatches);
    setCurrentOrderProduct(currentOrderProduct);
    setShowBatchAdd(true);
  };

  function handleShipSuccess(data: any) {
    setShowShippingDetails(false);
    setRetry((prev: number) => prev + 1);
  }

  function handleShipOrder(
    productBatches: IProductBatches,
    deliveryDate: string
  ) {
    let products: any = [];

    Object.keys(productBatches).forEach((atcCodeId: string) => {
      products.push({
        atc_code_id: atcCodeId,
        brand_name: productBatches[atcCodeId]?.[0]?.brand_name,
        strength: productBatches[atcCodeId]?.[0]?.strength,
        strength_unit: productBatches[atcCodeId]?.[0]?.strength_unit,
        batching: productBatches[atcCodeId],
      });
    });

    const payload = {
      estimated_delivery_date_range: deliveryDate,
      products,
    };
    setShipping(true);
    apiCall(
      ORDERS_URL + `/shipped/${currentOrder.id}`,
      "POST",
      payload,
      handleShipSuccess,
      console.log,
      () => {
        setShipping(false);
      }
    );
  }

  function onRetryNotificationSuccess() {
    setRetry((prev: number) => prev + 1);
    setShowOrder(false);
  }

  function retryNotification() {
    setRetryingNotification(true);
    apiCall(
      ORDERS_URL + `/${currentOrder.id}/retry-notification`,
      "PATCH",
      {},
      onRetryNotificationSuccess,
      console.log,
      () => setRetryingNotification(false)
    );
  }

  return (
    <RequisitionWrapper>
      <PageTitleNavs
        title="Orders"
        backLink="#"
        bcrumbs={[{ title: "Orders", link: "#" }]}
      />
      <Modal
        isOpen={showActionEnquiry}
        close={() => setShowActionEnquiry(false)}
      >
        <ActionEnquiry
          enquiryType={enquiryType}
          title={enquiryPrompt}
          onCancel={() => setShowActionEnquiry(false)}
          onContinue={onEnquiryContinue}
          enquiryLoading={enquiryLoading}
        />
      </Modal>
      <Modal
        isOpen={showShippingDetails}
        close={() => setShowShippingDetails(false)}
        title="Order Products Batching"
        subTitle="Set the batches and the corresponding quantity on each product in the order"
      >
        <ShippingDetails
          productBatches={productBatches}
          handleProductSelected={handleProductSelected}
          orderDetails={currentOrder}
          handleShipOrder={handleShipOrder}
          shipping={shipping}
        />
      </Modal>
      <Modal
        title="Select Batches"
        subTitle="Select a batch and the enter the quantity"
        isOpen={showBatchAdd}
        close={() => setShowBatchAdd(false)}
      >
        <BatchSelector
          handleContinue={handleAddBatchContinue}
          productData={currentProductBatches}
          orderProduct={currentOrderProduct}
        />
      </Modal>
      {showEnquiryText && (
        <PopupWrapper position={cordinate}>
          <div className="enquiry-text-wrapper">
            <H4>{enquiryText}</H4>
          </div>
        </PopupWrapper>
      )}

      <ActionPopup
        position={cordinate}
        open={showActions}
        actions={actions.map((act: string) => ({
          label: act,
          action: () => onActionClicked(act),
        }))}
        onClose={() => setShowActions(false)}
      />

      <Modal isOpen={showOrder} close={() => setShowOrder(false)}>
        <div style={{ minWidth: "60rem", maxWidth: "95%" }}>
          <RespondViewRfq
            company={
              currentOrder?.distributor_info || currentOrder?.manufacturer_info
            }
            rfq={{
              quotation_request_products: currentOrder?.order_products,
              quotation_request_id: "",
              quotation_id: "",
              payment_term: currentOrder?.payment_term,
              shipping_address: currentOrder?.shipping_address,
              owner: currentOrder?.owner,
              external_inventory_callback_status:
                currentOrder.external_inventory_callback_status,
              retryingNotification: retryingNotification,
            }}
            logisticsCost={currentOrder?.delivery_charges}
            tax={currentOrder?.tax}
            discount={currentOrder?.discount}
            total={currentOrder?.total_amount}
            rowTotals={{}}
            subTotal={subTotal}
            terms={""}
            handleSecondaryButton={() => {}}
            handleMainButton={() => {}}
            isViewing
            viewer=""
            quotation={currentOrder?.order_products}
            quotationExpiry={currentOrder?.expected_delivery_date}
            title="Order"
            ordertype={orderType}
            retryNotification={retryNotification}
          />
        </div>
      </Modal>

      {companyType?.toLowerCase() === "distributor" && (
        <div className="button-wrapper">
          <Button>
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to="/dashboard/requisition/make-requisition"
            >
              New Order
            </Link>
          </Button>
        </div>
      )}

      <div className="requisitions">
        {companyType?.toLowerCase() === "distributor" && (
          <div className="tabs-wrapper">
            <Tab
              tabsTitle={["received", "sent"]}
              onTabClick={(title: string) => setOrderType(title)}
              currentTab={orderType}
            />
          </div>
        )}

        <div className="table-wrapper1">
          <Table
            tableKeys={getTableKeys(companyType)}
            tableHeaders={getTableHeaders(orderType, companyType)}
            tableData={tableData}
            full
            spaced
            onTDClick={() => {}}
            filterInputs={filterOptions}
            handleFilter={handlOrderFilter}
            rowLineColor="light"
            tableTitle="My Orders"
            tableSubtitle={`${orderType} orders`}
            handleSearch={handleSearch}
            loading={reqsLoading || searching}
            errorLoading={!!reqsError}
            onRetry={() => setRetry((prev) => prev + 1)}
          />
        </div>
      </div>
      <Pagination
        onNextPage={handleNextPage}
        onPerPageChange={handlePerPage}
        pageCount={pageCount}
      />
    </RequisitionWrapper>
  );
}

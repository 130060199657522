import { useEffect, useState } from "react";
import { BsChevronDown, BsQuestionSquare } from "react-icons/bs";

import { SelectWrapper } from "../selectInput/style";
import CustomTooltip from "../Tooltip";

export interface ISelectProps {
  options: any[];
  placeholder: string;
  prompt: string;
  fullWidth: boolean;
  name: string;
  indexLevel: number;
  returnId?: boolean;
  parentName: string;
  formValues: any;
  shortWidth: boolean;
  tooltip?: string;
  updateValidity: (name: string, validity: boolean) => void;
  updateData: (
    name: string,
    value: { id: string | number; title: string }
  ) => void;
  initialValue?: string;
}

export function SelectChild({
  options,
  placeholder,
  prompt,
  fullWidth,
  updateValidity,
  updateData,
  name,
  indexLevel,
  returnId,
  parentName,
  formValues,
  shortWidth,
  tooltip,
  initialValue,
}: ISelectProps) {
  const [selectedValue, setSelectedValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [actualOptions, setActualOptions] = useState<any>([]);
  const [currentParent, setCurrentParent] = useState("");

  useEffect(() => {
    if (initialValue) {
      updateValidity(name, true);
      setSelectedValue(initialValue);
      updateData(name, initialValue as any);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  useEffect(() => {
    if (currentParent !== formValues[parentName] && !initialValue) {
      setSelectedValue("");
      updateValidity(name, false);
    }
    setCurrentParent(formValues[parentName]);
    options.forEach((option: any) => {
      if (option.state.name === formValues[parentName]) {
        setActualOptions(option.state.locals);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  function onSelectClicked() {
    setShowOptions(!showOptions);
    setClicked(true);
  }
  const onSelectOption = (item: any) => {
    setSelectedValue(item);
    setShowOptions(false);
    updateValidity(name, true);
    updateData(name, item);
  };

  return (
    <SelectWrapper
      indexLevel={indexLevel}
      showOptions={showOptions}
      fullWidth={fullWidth}
      shortWidth={shortWidth}
    >
      <div className="label-wrapper">
        <label>{prompt}</label>
        {tooltip ? (
          <CustomTooltip title={tooltip}>
            <div>
              <BsQuestionSquare className="tooltip-icon" />
            </div>
          </CustomTooltip>
        ) : null}
      </div>
      <div className="select-wrapper">
        <div onClick={onSelectClicked} className="select-input">
          {selectedValue ? <h3>{selectedValue}</h3> : <h4>{placeholder}</h4>}
          <BsChevronDown className="icon" />
        </div>
        {clicked && !showOptions && !selectedValue && (
          <h3 className="error-message">Please select an option</h3>
        )}
        {showOptions && (
          <ul className="select-options">
            {actualOptions &&
              actualOptions.map((item: any, i: number) => {
                return (
                  <li
                    onClick={() => onSelectOption(item.name)}
                    key={item.name}
                    value={item.name}
                  >
                    {item.name}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </SelectWrapper>
  );
}

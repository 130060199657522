import { createBrowserRouter } from "react-router-dom";
import { Home } from "./pages/home";
import { Login } from "./pages/login";
import { PasswordReset } from "./pages/password-reset";
import { NewReg } from "./pages/new-registration";
import { Register } from "./pages/register";
import { RegisterReffered } from "./pages/register-reffered";
import { OTP } from "./pages/otp";
import { RegisterSuccess } from "./pages/register-sucess";
import PersistLogin from "./common/components/persistence/PersistLogin";
import { RequireAuth } from "./common/components/requireAuth";
import { Dashboard } from "./pages/dashboard/layout";
import { NotFound } from "./pages/404";
import { Layout } from "./layout";
import { Stats } from "./pages/dashboard/stats";
import { Products } from "./pages/dashboard/products";
import { ProductDetailsPage } from "./pages/dashboard/players/product-details";
import { AddInventory } from "./pages/dashboard/add-inventory";
import { Orders } from "./pages/dashboard/orders";
import { MyDistributors } from "./pages/dashboard/my-distributors";
import { MakeOrder } from "./pages/dashboard/make-order";
import { Settings } from "./pages/dashboard/settings";
import { Invoices } from "./pages/dashboard/invoices";
import { Invoice } from "./pages/dashboard/invoices/subs/view";
import { Delivery } from "./pages/dashboard/delivery";
import { NewDelivery } from "./pages/dashboard/delivery/new-delivery";
import { Requests } from "./pages/dashboard/requests";
import { Companies } from "./pages/dashboard/admin/companies";
import { ComapanyDetails } from "./pages/dashboard/admin/company-details";
import { Rfq } from "./pages/dashboard/rfq";
import { ViewRFQ } from "./pages/dashboard/rfq/view-rfq";
import { RespondRfq } from "./pages/dashboard/rfq/respond-rfq";
import { NewRFQ } from "./pages/dashboard/rfq/new-rfq";
import { EditRfq } from "./pages/dashboard/edit-rfq";
import { AdminProducts } from "./pages/dashboard/admin/products";
import { AdminProductDetails } from "./pages/dashboard/admin/product-details";
import { InviteCompanies } from "./pages/dashboard/nafdac/invite-companies";
import OurTeam from "./pages/our-team";
import Portfolio from "./pages/portfolio";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <Home /> },
      { path: "login", element: <Login /> },
      { path: "reset-password", element: <PasswordReset /> },
      { path: "register", element: <NewReg /> },
      { path: "old-registrationadf", element: <Register /> },
      { path: "register/referred", element: <RegisterReffered /> },
      { path: "otp", element: <OTP /> },
      { path: "register/success", element: <RegisterSuccess /> },
      { path: "our-team", element: <OurTeam /> },
      { path: "portfolio", element: <Portfolio /> },
      { path: "*", element: <NotFound /> },
    ],
  },

  {
    element: <PersistLogin />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          {
            path: "/dashboard/",
            element: <Dashboard />,

            children: [
              { path: "stats", element: <Stats /> },
              { path: "products", element: <Products /> },
              { path: "products/:id", element: <ProductDetailsPage /> },
              { path: "inventories", element: <AddInventory /> },
              { path: "requisition", element: <Orders /> },
              { path: "my-team/:companyType", element: <MyDistributors /> },
              { path: "requisition/make-requisition", element: <MakeOrder /> },
              { path: "settings", element: <Settings /> },
              { path: "invoices", element: <Invoices /> },
              { path: "invoices/:quotationId", element: <Invoice /> },
              { path: "deliveries", element: <Delivery /> },
              {
                path: "deliveries/new-delivery-request",
                element: <NewDelivery />,
              },
              { path: "requests/:companyType", element: <Requests /> },
              { path: "admin/companies/queried", element: <Companies /> },
              { path: "admin/companies/approved", element: <Companies /> },
              { path: "admin/companies/declined", element: <Companies /> },
              { path: "admin/companies/pending", element: <Companies /> },
              {
                path: "admin/companies/details/:id/:type",
                element: <ComapanyDetails />,
              },
              {
                element: (
                  <RequireAuth allowedRoles={["retailer", "distributor"]} />
                ),
                children: [
                  { path: "rfq", element: <Rfq /> },
                  { path: "rfq/retailer/view-rfq/:id", element: <ViewRFQ /> },
                  {
                    path: "rfq/distributor/view-rfq/:id",
                    element: <RespondRfq />,
                  },
                  { path: "rfq/make-rfq", element: <NewRFQ /> },
                  { path: "rfq/edit-rfq", element: <EditRfq /> },
                ],
              },
              { path: "admin/products/queried", element: <AdminProducts /> },
              { path: "admin/products/approved", element: <AdminProducts /> },
              { path: "admin/products/declined", element: <AdminProducts /> },
              { path: "admin/products/pending", element: <AdminProducts /> },
              {
                path: "admin/products/details/:type/:id",
                element: <AdminProductDetails />,
              },
              {
                path: "pcn-admin/invite-companies",
                element: <InviteCompanies />,
              },
              { path: "*", element: <NotFound /> },
            ],
          },
        ],
      },
    ],
  },
]);

import * as React from "react";
import { ModalCover2 } from "../../modal/modal-cover";
import { Filter } from "../filter";
import { useStore } from "src/hooks/useContexts";

export interface ITableModalsProps {}



export function TableModals(props: ITableModalsProps) {
  const { level2ModalOptions } = useStore();
  return (
    <div>
      <ModalCover2 modalName="table-filters" canClose>
        <Filter
          filterInputs={level2ModalOptions?.filterInputs}
          onFilter={level2ModalOptions?.onFilter}
        />
      </ModalCover2>
    </div>
  );
}

import styled from "styled-components";
import {
  ChangeEvent,
  DragEvent,
  useState,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";

import { BsTrash } from "react-icons/bs";
import { RiImageAddFill } from "react-icons/ri";
import { BsCloudUpload } from "react-icons/bs";
import { FilesObject } from "../../../../../common/types";

interface FileProps {
  dragEntered?: boolean;
  error?: string;
  fileName?: string;
}

export const FileWrapper = styled.div<FileProps>`
  width: 15rem;
  height: 15rem;
  padding: 2.5rem 1.2rem;
  margin-bottom: 1rem;
  border: ${({ theme, dragEntered }) =>
    dragEntered
      ? `1px solid ${theme.bg.secondary}`
      : `1px dashed ${theme.border.input}`};
  ${({ theme, dragEntered }) =>
    dragEntered && `box-shadow: ${theme.shadow.drag_over}`};
  background-color: ${({ theme }) => theme.bg.tert_500};
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1.2rem;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
   width: 100%;
   
  }

  .prompt {
    display: flex;
    gap: 0.5rem;
  }

  p {
    font-weight: 400;
    font-size: 0.85rem;
    text-align: center;
  }

  .file-icon {
    color: ${({ theme }) => theme.bg.sec_100};
    font-size: 2rem;
  }

  .error {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.text.error};
  }

  .success {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: ${({ theme }) => theme.text.success};
    gap: 0.3rem;
    width: 65%;
  }

  label {
    color: ${({ theme }) => theme.bg.sec_100};
    font-weight: 500;
    font-size: 0.85rem;
  }

  .drag-overlay,
  .image-overlay,
  .hover-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .hover-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    color: ${({ theme }) => theme.text.prim_500};
    background-color: #00000080;
    display: none;

    p {
      font-size: 1.1rem;
    }

    .image-icon {
      font-size: 1.5rem;
    }
  }

  .show {
    display: flex !important;
  }

  .image-overlay {
    background-color: ${({ theme }) => theme.bg.prim_400};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .cancel-icon {
    position: absolute;
    right: 7rem;
    bottom: -1.4rem;
    font-size: 1.1rem;
    cursor: pointer;

    :hover {
      color: red;
    }
  }
`;

export interface IFileInputProps {
  name: string;
  setFiles: Dispatch<SetStateAction<FilesObject>>;
  cancelable?: boolean;
  files: FilesObject;
  onFileCancel?: (e: any, name: string) => void;
}

export function ImageInput({
  name,
  setFiles,
  files,
  cancelable,
  onFileCancel,
}: IFileInputProps) {
  const [dragEntered, setDragEntered] = useState(false);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");
  const [imageHover, setImageHover] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const MAX_SIZE = 2000000;

  function handleFileChanged(e: ChangeEvent<HTMLInputElement>) {
    const chosenFile = e.target.files && e.target.files[0];
    chosenFile && handleFile(chosenFile as File);
  }

  function handleFile(chosenFile: File) {
    const fileType = chosenFile.type;
    const fileSize = chosenFile.size;
    const fileName = chosenFile.name;

    if (fileType.split("/")[0] !== "image" && fileSize > MAX_SIZE) {
      setError("File must be an image and size not more than 2MB");
      setFileName("");
      return;
    }
    if (fileType.split("/")[0] !== "image") {
      setError("File must be an image ");
      setFileName("");

      return;
    }
    if (fileSize > MAX_SIZE) {
      setError("File size must not be more than 2MB");
      setFileName("");
      return;
    }

    let alreadyExist = false;
    Object.values(files).forEach((file: any) => {
      if (file.name === fileName) {
        alreadyExist = true;
        return;
      }
    });

    if (alreadyExist) {
      setError("File with same name already selected, choose another file.");
      return;
    }

    //If it gets here then are conditions are fulfilled
    setError("");
    setFileName(fileName);
    setFiles((prev: FilesObject) => {
      return {
        ...prev,
        [name]: chosenFile,
      };
    });
  }

  function handleDragEnter(e: DragEvent) {
    e.preventDefault();
    setDragEntered(true);
  }

  function handleDragLeave(e: DragEvent) {
    e.preventDefault();
    setDragEntered(false);
  }

  function handleDrop(e: DragEvent) {
    e.preventDefault();
    setDragEntered(false);
    const chosenFile = e.dataTransfer.files && e.dataTransfer.files[0];
    chosenFile && handleFile(chosenFile as File);
  }

  function handleDragOver(e: DragEvent) {
    e.preventDefault();
  }

  function getImagePreview(image: string | File) {
    // if (typeof image === undefined) return "";
    if (typeof image === "string") return image;
    if (image) return URL.createObjectURL(image);
  }

  return (
    <FileWrapper dragEntered={dragEntered} error={error} fileName={fileName}>
      <BsCloudUpload className="file-icon" />
      <div className="prompt">
        <p> Drag and Drop or</p>
        <label className="input">
          Browse
          <input
            onChange={handleFileChanged}
            type="file"
            name="image"
            style={{ display: "none" }}
            ref={inputRef}
          />
        </label>
      </div>

      <p>
        Maximum size of 2MB,
        <br /> jpg, jpeg, or png
      </p>

      {error && <p className="error">{error}</p>}

      {files[name] ? (
        <div className="image-overlay">
          <img src={getImagePreview(files[name])} alt="" />
          <div className={`hover-overlay ${imageHover ? "show" : ""}`}>
            <RiImageAddFill className="image-icon" />
            <p>
              Click to <b>Replace</b>
            </p>
          </div>
        </div>
      ) : null}

      {cancelable && (
        <BsTrash
          onClick={(e) => onFileCancel && onFileCancel(e, name)}
          className="cancel-icon"
        />
      )}

      <div
        onClick={() => inputRef.current?.click()}
        className="drag-overlay"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onMouseEnter={() => setImageHover(true)}
        onMouseLeave={() => setImageHover(false)}
      ></div>
    </FileWrapper>
  );
}

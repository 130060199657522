import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDashboardContext } from "../../../hooks/useContexts";
import { DashboardWrapper } from "./subs/style";

import { DashboardSidebar } from "./subs/DashSidebar";
import { DashboardNav } from "./subs/DashNav";

export interface IDashboardProps {}

export function Dashboard(props: IDashboardProps) {
  const { setShowDashboard } = useDashboardContext();

  const [showSidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    setShowDashboard(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => setShowDashboard(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardWrapper>
      <DashboardSidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div
        style={{
          display: showSidebar ? "block" : "none",
        }}
        onClick={() => setShowSidebar(false)}
        className="backdrop"
      ></div>
      <div className="flex-col dashboard-main">
        <DashboardNav setShowSidebar={setShowSidebar} />
        <div className="dashboard-pages">
          <Outlet />
        </div>
      </div>
    </DashboardWrapper>
  );
}

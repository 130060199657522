import { SetStateAction, Dispatch, useEffect, useState } from "react";
import styled from "styled-components";
import { KeyValueObj } from "../../../../common/types";
import Input from "src/common/components/Input/Input";
import { states } from "src/common/data";
import { AddressManager } from "src/common/components/addresses";

export interface IReqFormProps {
  product: KeyValueObj;
  orders: KeyValueObj;
  setOrders: Dispatch<SetStateAction<KeyValueObj>>;
  type?: string;
}

interface ReqProps {
  isPreview: boolean;
}

const ReqFormWrapper = styled.div<ReqProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 2rem 0;

  .forms-wrapper {
    width: 90%;
    gap: 2rem;
    padding: 1rem 0;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      width: 100%;
      padding: 0;
    }

    .info,
    .inputs {
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1.5rem 1.1rem;
      padding: 0.85rem;

      .info-wrapper,
      .input-wrapper {
        width: 23%;
        align-items: flex-start;
        gap: 0.5rem;

        @media (max-width: ${({ theme }) => theme.screen.mobile}) {
          width: 46%;
        }

        h3 {
          font-weight: 500;
          font-size: 0.85rem;
          color: ${({ theme }) => theme.text.primary};
        }

        .title {
          color: ${({ theme }) => theme.text.prim_100};
          font-weight: 400;
        }
      }
    }

    .line {
      width: 90%;
      height: 1px;
      background-color: ${({ theme }) => theme.border.light};
    }

    .inputs {
      justify-content: flex-start;

      .input-wrapper {
        width: ${({ isPreview }) => (isPreview ? "22%" : "47%")};
        gap: 0.5rem;
        position: relative;

        @media (max-width: ${({ theme }) => theme.screen.mobile}) {
          width: 46%;
        }

        .input-error-message {
          color: red;
          font-size: 0.8rem;
        }

        input,
        textarea,
        select {
          width: 100%;
          padding: 0.65rem;
          outline: none;
          border: 1px solid ${({ theme }) => theme.border.input};
          border-radius: 0.25rem;
          resize: none;
          color: ${({ theme }) => theme.text_prim_100};
          font-weight: 400;
          font-size: 0.8rem;
        }
      }

      .buyer-note {
        flex-basis: 100%;
        align-self: stretch;
      }
    }
  }
`;

export function ReqForm({ product, orders, setOrders, type }: IReqFormProps) {
  //STATES
  const [quantityError, setQuantityError] = useState(false);

  //HOOKES

  const isPreview = type === "preview";
  const id = product?.id;

  //USEEFFECTS
  //initialize the values
  useEffect(() => {
    setOrders((prev: any) => {
      return {
        ...prev,
        [product?.id]: {
          product_id: product?.id,
          supplier_id: "",
          package_type: "",
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputChange(e: any, source: string) {
    const { value } = e.target;
    //Update the orders
    setOrders((prev: KeyValueObj) => {
      const tempValue = prev[product.id];
      if (source === "quantity") {
        tempValue[source] = parseInt(value);
      } else {
        tempValue[source] = value;
      }

      return {
        ...prev,
        [product.id]: tempValue,
      };
    });

    //check for error
    switch (source) {
      case "quantity":
        if (value < 1) setQuantityError(true);
        else setQuantityError(false);
        break;

      default:
        break;
    }
  }

  return (
    <ReqFormWrapper isPreview={isPreview}>
      <div className="forms-wrapper flex-col">
        <div className="info flex-row">
          <div className="info-wrapper flex-col">
            <h3 className="title">Name</h3>
            <h3 className="value">{product?.atc_code?.code_title}</h3>
          </div>

          <div className="info-wrapper flex-col">
            <h3 className="title">ATC Code</h3>
            <h3 className="value">{product?.atc_code?.code_identifier}</h3>
          </div>

          <div className="info-wrapper flex-col">
            <h3 className="title">Medex Code</h3>
            <h3 className="value">{product?.medex_code}</h3>
          </div>

          <div className="info-wrapper flex-col">
            <h3 className="title">Brand</h3>
            <h3 className="value">{product?.brand_name}</h3>
          </div>

          <div className="info-wrapper flex-col">
            <h3 className="title">Product strength</h3>
            <h3 className="value">
              {product?.strength + product?.strength_unit}
            </h3>
          </div>

          <div className="info-wrapper flex-col">
            <h3 className="title">Form</h3>
            <h3 className="value">{product?.form}</h3>
          </div>

          <div className="info-wrapper flex-col">
            <h3 className="title">Classification</h3>
            <h3 className="value">{product?.classification}</h3>
          </div>

          <div className="info-wrapper flex-col">
            <h3 className="title">Regulation</h3>
            <h3 className="value">{product?.regulation}</h3>
          </div>
        </div>

        <div className="line"></div>
        <div className="inputs flex-row">
          <div className="input-wrapper flex-col">
            <h3 className="title">Package type</h3>
            {isPreview ? (
              <h3 className="preview">{orders[id]?.package_type}</h3>
            ) : (
              <select onChange={(e) => handleInputChange(e, "package_type")}>
                <option value={product?.primary_packaging}>
                  {product?.primary_packaging} (Primary Packaging)
                </option>
                <option value={product?.secondary_packaging}>
                  {product?.secondary_packaging} (Secondary Packaging)
                </option>
                <option value={product?.tertiary_packaging}>
                  {product?.tertiary_packaging} (Tertiary Packaging)
                </option>
              </select>
            )}
          </div>

          <div className="input-wrapper flex-col">
            <h3 className="title">Product Quantity</h3>
            {isPreview ? (
              <h3 className="preview">{orders[id]?.quantity}</h3>
            ) : (
              <input
                onChange={(e) => handleInputChange(e, "quantity")}
                type="number"
              />
            )}

            {quantityError && (
              <h3 className="input-error-message">
                Quantity cannot be less than one
              </h3>
            )}
          </div>
        </div>
      </div>
    </ReqFormWrapper>
  );
}

export interface IOrderDeliveryDetailsProps {
  isPreview?: boolean;
  deliveryDetails: KeyValueObj;
  setDeliveryDetails: Dispatch<SetStateAction<KeyValueObj>>;
}

export function OrderDeliveryDetails({
  isPreview,
  deliveryDetails,
  setDeliveryDetails,
}: IOrderDeliveryDetailsProps) {
  const [selectedAddress, setSelectedAddress] = useState<KeyValueObj>({});

  useEffect(() => {
    setDeliveryDetails((prev: any) => ({ ...prev, ...selectedAddress }));

    // eslint-disable-next-line
  }, [selectedAddress]);

  function updateData(name: string, value: any) {
    setDeliveryDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  return (
    <ReqFormWrapper isPreview={!!isPreview}>
      <div className="forms-wrapper flex-col">
        <div className="inputs flex-row">
          {!isPreview ? (
            <>
              <AddressManager
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
              />
              <>
                <Input
                  placeholder={"Enter order note"}
                  prompt={"Order Note"}
                  fullWidth={false}
                  shortWidth={false}
                  name={"buyer_note"}
                  inputType="textarea"
                  indexLevel={5}
                  updateData={updateData}
                  options={states}
                  updateValidity={(name: string, validity: boolean) => {}}
                  formValues={deliveryDetails}
                  setFormValues={setDeliveryDetails}
                />

                <Input
                  placeholder={"Enter delivery date"}
                  prompt={"Expected Delivery Date"}
                  fullWidth={false}
                  shortWidth={false}
                  name={"expected_delivery_date"}
                  inputType="date"
                  indexLevel={6}
                  updateData={updateData}
                  options={states}
                  updateValidity={(name: string, validity: boolean) => {}}
                  formValues={deliveryDetails}
                  setFormValues={setDeliveryDetails}
                />
              </>
            </>
          ) : null}

          {isPreview ? (
            <>
              <div className="input-wrapper  flex-col">
                <h3 className="title">Delivery State</h3>
                <h3 className="preview">{deliveryDetails?.state}</h3>
              </div>
              <div className="input-wrapper flex-col">
                <h3 className="title">Delivery Lga</h3>
                <h3 className="preview">{deliveryDetails?.lga}</h3>
              </div>
              <div className="input-wrapper flex-col">
                <h3 className="title">Delivery City</h3>
                <h3 className="preview">{deliveryDetails?.city}</h3>
              </div>
              <div className="input-wrapper  flex-col">
                <h3 className="title">Address Line one</h3>
                <h3 className="preview">{deliveryDetails?.address_line_one}</h3>
              </div>
              <div className="input-wrapper flex-col">
                <h3 className="title">Address Line two</h3>
                <h3 className="preview">{deliveryDetails?.address_line_two}</h3>
              </div>
              <div className="input-wrapper  flex-col">
                <h3 className="title">Postal Code</h3>
                <h3 className="preview">{deliveryDetails?.postal_code}</h3>
              </div>

              <div className="input-wrapper flex-col">
                <h3 className="title"> Expected Delivery Date</h3>
                <h3 className="preview">
                  {deliveryDetails?.expected_delivery_date}
                </h3>
              </div>
              <div className="input-wrapper flex-col">
                <h3 className="title">Buyer Note</h3>
                <h3 className="preview">{deliveryDetails?.buyer_note}</h3>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </ReqFormWrapper>
  );
}

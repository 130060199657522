import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../common/components/modal";
import { useNotification } from "../../../common/components/Notifications/NotificationProvider";
import Table from "../../../common/components/Table";
import { DISTRIBUTORS_URL, QUOTATION_REQUEST } from "../../../common/constants";
import { ModalWrapper } from "../../../common/elements";
import { KeyValueObj } from "../../../common/types";
import { getNameWithATC } from "../../../common/utils";
import { useApiCallHandling } from "../../../hooks/useApiCallHandling";
import useAuth, { useDashboardContext } from "../../../hooks/useContexts";
import { tableHeaders, tableKeys } from "./subs/data";
import { RfqWrapper } from "./subs/style";

export interface IRfqProps {}

export function EditRfq(props: IRfqProps) {
  const [tableData, setTableData] = useState<KeyValueObj[]>([]);
  const [previewTableData, setPreviewTableData] = useState<KeyValueObj[]>([]);
  const [rfqFormData, setRfqFormData] = useState<KeyValueObj[]>([]);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [shippingCity, setShippingCity] = useState("");
  const [showNoAddressError, setShowNoAddressError] = useState(false);

  const { selectedInventories } = useDashboardContext();
  const { auth } = useAuth();

  const apiCall = useApiCallHandling();
  const notify = useNotification();
  const navigate = useNavigate();

  async function getDistributors(id: string) {
    const inv = selectedInventories.filter(
      (inventory: KeyValueObj) => inventory.id === id
    );
    const { atc_code, brand } = inv[0]?.product;
    const options: KeyValueObj[] = [];

    await apiCall(
      DISTRIBUTORS_URL + `/${atc_code}/${brand}`,
      "GET",
      {},
      (data: any) => {
        data.forEach((datum: KeyValueObj) => {
          options.push({
            id: datum.id,
            title: datum.name,
          });
        });
      },
      (err: any) => {
        console.log(err);
      },
      () => {},
      { dontNotifyOnSucess: true }
    );

    return options;
  }

  useEffect(() => {
    const tempTableData = selectedInventories?.map((inv: any) => ({
      id: inv.id,
      name: { type: "string", value: getNameWithATC(inv.product?.atc_code) },
      brand: { type: "string", value: inv.product?.brand },
      distributor: {
        type: "input",
        inputType: "checkBox",
        name: "distributor",
        placeholder: "Pick a distributor",
        options: [],
        fetchOptions: getDistributors,
      },
      deliveryDate: {
        type: "input",
        inputType: "date",
        name: "delivery_date",
        rules: {
          fromToday: {
            expectedValue: true,
            errorMessage: "Delivery date should not be in the past",
          },
        },
      },
      productQuantity: {
        type: "input",
        inputType: "number",
        name: "quantity",
        placeholder: "Type in the quantity",
        rules: {
          aboveZero: {
            expectedValue: true,
            errorMessage: "Quantity must be a number above zero",
          },
        },
      },
      productPackaging: {
        type: "input",
        inputType: "select",
        name: "package_type",
        placeholder: "Package Type",
        options: [
          { id: "1", title: inv.product?.primary_packaging },
          { id: "2", title: inv.product?.secondary_packaging },
          { id: "33", title: inv.product?.tertiary_packaging },
        ],
      },
      paymentTerms: {
        value: "",
        type: "input",
        inputType: "select",
        name: "payment_term",
        placeholder: "Payment Term",
        options: [
          { id: "1", title: "one-off" },
          { id: "2", title: "instalment" },
          { id: "3", title: "postpaid" },
        ],
      },
    }));

    setTableData(tempTableData);
    setDeliveryAddress(auth?.user?.company?.corporate_address);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.company, selectedInventories]);

  function onFormContinue(formValues: KeyValueObj) {
    //check that address details are entered

    if (shippingCity.length < 1 || deliveryAddress.length < 1) {
      setShowNoAddressError(true);
      return;
    }

    setShowNoAddressError(false);
    const tempTableData: KeyValueObj[] = [];
    const tempRfqFormData: KeyValueObj[] = [];

    //go through the form values
    Object.keys(formValues).forEach((theKey: string) => {
      //get the product data
      const product = selectedInventories?.filter(
        (inv: KeyValueObj) => inv.id === theKey
      )[0].product;
    

      //get distributors info
      const formValue = formValues[theKey];
      const distributors = formValue.distributor;
      const distributorIds: string[] = [];
      let distributorNames: string = "";
      distributors.forEach((distributor: KeyValueObj) => {
        distributorIds.push(distributor.id);
        distributorNames = distributorNames + distributor.title + ",  ";
      });

      //set the table data
      tempTableData.push({
        id: theKey,
        product: { type: "image", value: product?.images?.[0]?.url },
        name: { type: "string", value: getNameWithATC(product?.atc_code) },
        brand: { type: "string", value: product?.brand },
        distributor: {
          type: "string",
          value: distributorNames,
        },
        deliveryDate: {
          type: "string",
          value: formValue.delivery_date,
        },
        productQuantity: {
          type: "string",
          value: formValue.quantity,
        },
        productPackaging: {
          type: "string",
          value: formValue.package_type,
        },
        paymentTerms: {
          value: formValue.payment_term,
          type: "string",
        },
      });

      //set the formData
      tempRfqFormData.push({
        shipping_city: shippingCity,
        shipping_address: deliveryAddress,
        expected_delivery_date: formValue.delivery_date,
        buyer_note: "",
        send_to: distributorIds,
        product_id: product.id,
        packaging: formValue.package_type,
        payment_term: formValue.payment_term,
        quantity: parseInt(formValue.quantity),
        product_strength: product.strength,
        product_strength_unit: product.strength_unit,
      });
    });

    setPreviewTableData(tempTableData);
    setRfqFormData(tempRfqFormData);
    setShowModal(true);
  }

  function submitRequest() {
    setSubmitLoading(true);
    apiCall(
      QUOTATION_REQUEST,
      "POST",
      {
        rfqs: rfqFormData,
      },
      (data) => {
        data.forEach((datum: any) => {
          notify({
            message: datum.message,
            type: "SUCCESS",
          });
          setShowModal(false);
          navigate("/dashboard/rfq");
        });
      },
      (error) => {
        console.log(error);
      },
      () => {
        setSubmitLoading(false);
      }
    );
  }

  return (
    <RfqWrapper>
      <Modal
        isOpen={showModal}
        close={() => setShowModal(false)}
        title="Quotation Request Preview"
        subTitle="A summary of the values selected"
      >
        <ModalWrapper>
          <Table
            tableKeys={tableKeys}
            tableHeaders={tableHeaders}
            tableData={previewTableData}
            full
            isForm
            submitLabel="Submit"
            onFormSubmit={submitRequest}
            submitLoading={submitLoading}
          />
        </ModalWrapper>
      </Modal>
      <div className="top-wrapper flex-col">
        <div className="inputs flex-row">
          <div className="input-wrapper flex-col">
            <label htmlFor="delivery_address">Delivery Address</label>
            <textarea
              name="delivery_address"
              onChange={(e) => setDeliveryAddress(e.target.value)}
              value={deliveryAddress}
            ></textarea>
          </div>
          <div className="input-wrapper flex-col">
            <label htmlFor="delivery_address">Delivery City</label>
            <input
              name="delivery_city"
              type="text"
              onChange={(e) => setShippingCity(e.target.value)}
              value={shippingCity}
            />
          </div>
        </div>
        {showNoAddressError ? (
          <p className="error-message">Enter delivery address information</p>
        ) : null}
      </div>

      <Table
        tableKeys={tableKeys}
        tableHeaders={tableHeaders}
        tableData={tableData}
        full
        isForm
        onTDClick={() => {}}
        onFormSubmit={onFormContinue}
      />
    </RfqWrapper>
  );
}

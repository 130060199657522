import { RequestsWrapper } from "./subs/styles";
import Tab from "src/common/components/Tab/Tab";
import { useState, useEffect } from "react";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { useParams } from "react-router-dom";
import {
  DISTRIBUTOR_INBOUND_REQUEST_URL,
  DISTRIBUTOR_OUTBOUND_REQUEST_URL,
  DISTRIBUTOR_RESPOND_REQUEST_URL,
  IMPORTER_INBOUND_REQUEST_URL,
  IMPORTER_OUTBOUND_REQUEST_URL,
  IMPORTER_RESPOND_REQUEST_URL,
  LSDP_INBOUND_REQUEST_URL,
  LSDP_OUTBOUND_REQUEST_URL,
  LSDP_REQUEST_RESPONSE_URL,
  MANUFACTURER_INBOUND_REQUEST_URL,
  MANUFACTURER_OUTBOUND_REQUEST_URL,
  MANUFACTURER_RESPOND_REQUEST_URL,
  RETAILER_INBOUND_REQUEST_URL,
  RETAILER_OUTBOUND_REQUEST_URL,
  RETAILER_RESPOND_REQUEST_URL,
} from "src/common/constants";
import useAxiosCall from "src/hooks/useAxiosCall";
import Table from "src/common/components/Table";
import {
  getRequestTableData,
  getRequestTableHeaders,
  getRequestTableKeys,
} from "./subs/data";
import { Modal } from "src/common/components/modal";
import { ViewCompany } from "./subs/view-company";
import { KeyValueObj } from "src/common/types";
import { Pagination } from "src/common/components/pagination";
import { PageTitleNavs } from "src/common/components/page-title-navs";

export interface IAppProps {}

type ITabOptions = "Inbound" | "Outbound";

export function Requests(props: IAppProps) {
  const [respondUrl, setRespondUrl] = useState("");
  const [currentTab, setCurrentTab] = useState<ITabOptions>("Inbound");
  const [respondLoading, setRespondLoading] = useState("");
  const [showCompany, setShowCompany] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<KeyValueObj>({});
  const [retry, setRetry] = useState(0);
  const [inboundParams, setInboundParams] = useState({
    page: 1,
    per_page: 25,
  });

  const [outboundParams, setOutboundParams] = useState({
    page: 1,
    per_page: 25,
  });

  const [
    fetchInbounds,
    inboundData,
    inboundError,
    inboundDataLoading,
    inboundMeta,
  ] = useAxiosCall();
  const [
    fetchOutbounds,
    outboundData,
    outboundError,
    outboundDataLoading,
    outboundMeta,
  ] = useAxiosCall();

  let { companyType } = useParams();
  const makeRequest = useApiCallHandling();


  useEffect(() => {
    switch (companyType) {
      case "manufacturer":
        setRespondUrl(MANUFACTURER_RESPOND_REQUEST_URL);
        fetchInbounds({
          method: "GET",
          url:
            MANUFACTURER_INBOUND_REQUEST_URL +
            `?page=${inboundParams.page}&per_page=${inboundParams.per_page}`,
        });
        fetchOutbounds({
          method: "GET",
          url:
            MANUFACTURER_OUTBOUND_REQUEST_URL +
            `?page=${outboundParams.page}&per_page=${outboundParams.per_page}`,
        });
        break;
      case "importer":
        setRespondUrl(IMPORTER_RESPOND_REQUEST_URL);
        fetchInbounds({
          method: "GET",
          url:
            IMPORTER_INBOUND_REQUEST_URL +
            `?page=${inboundParams.page}&per_page=${inboundParams.per_page}`,
        });
        fetchOutbounds({
          method: "GET",
          url:
            IMPORTER_OUTBOUND_REQUEST_URL +
            `?page=${outboundParams.page}&per_page=${outboundParams.per_page}`,
        });
        break;
      case "distributor":
        setRespondUrl(DISTRIBUTOR_RESPOND_REQUEST_URL);
        fetchInbounds({
          method: "GET",
          url:
            DISTRIBUTOR_INBOUND_REQUEST_URL +
            `?page=${inboundParams.page}&per_page=${inboundParams.per_page}`,
        });
        fetchOutbounds({
          method: "GET",
          url:
            DISTRIBUTOR_OUTBOUND_REQUEST_URL +
            `?page=${outboundParams.page}&per_page=${outboundParams.per_page}`,
        });
        break;
      case "retailer":
        setRespondUrl(RETAILER_RESPOND_REQUEST_URL);
        fetchInbounds({
          method: "GET",
          url:
            RETAILER_INBOUND_REQUEST_URL +
            `?page=${inboundParams.page}&per_page=${inboundParams.per_page}`,
        });
        fetchOutbounds({
          method: "GET",
          url:
            RETAILER_OUTBOUND_REQUEST_URL +
            `?page=${outboundParams.page}&per_page=${outboundParams.per_page}`,
        });
        break;

      case "lsdp":
        setRespondUrl(LSDP_REQUEST_RESPONSE_URL);
        fetchInbounds({
          method: "GET",
          url:
            LSDP_INBOUND_REQUEST_URL +
            `?page=${inboundParams.page}&per_page=${inboundParams.per_page}`,
        });
        fetchOutbounds({
          method: "GET",
          url:
            LSDP_OUTBOUND_REQUEST_URL +
            `?page=${outboundParams.page}&per_page=${outboundParams.per_page}`,
        });
        break;
    }

    // eslint-disable-next-line
  }, [companyType, retry, inboundParams, outboundParams]);

  function handleViewCompany(id: string) {
    const data = currentTab === "Inbound" ? inboundData : outboundData;
    let currentCompany: any;
    const company = data?.find((datum: any) => {
      let entity: any;
      if (currentTab === "Inbound") {
        entity = datum[datum?.sent_by?.toLowerCase()];
        if (datum?.sent_by === "DeliveryServiceProvider") {
          entity = datum?.delivery_service_provider;
        }
      } else {
        entity = datum[datum?.received_by?.toLowerCase()];
        if (datum?.received_by === "DeliveryServiceProvider") {
          entity = datum?.delivery_service_provider;
        }
      }
      currentCompany = entity;
      return entity?.id === id;
    });

    if (company) {
      setCurrentCompany(currentCompany);
      setShowCompany(true);
    }
  }

  function handleRespondSuccess(data: any) {
    setShowCompany(false);
    setRetry((prev: number) => prev + 1);
  }

  function handleRespondFail(data: any) {}

  function handleRespond(company_id: string, operation: string) {
    setRespondLoading(operation);
    makeRequest(
      respondUrl,
      "POST",
      { company_id, operation },
      handleRespondSuccess,
      handleRespondFail,
      () => setRespondLoading("")
    );
  }

  function handlePerPageChange(event: any, requestType: string) {
    if (requestType === "inbound") {
      setInboundParams((prev: any) => ({
        ...prev,
        per_page: event?.target?.value,
      }));
    } else {
      setOutboundParams((prev: any) => ({
        ...prev,
        per_page: event?.target?.value,
      }));
    }
  }

  function handleNextPage(event: any, requestType: string) {
    if (requestType === "inbound") {
      setInboundParams((prev: any) => ({
        ...prev,
        page: event?.target?.value,
      }));
    } else {
      setOutboundParams((prev: any) => ({
        ...prev,
        page: event?.target?.value,
      }));
    }
  }

  const tableDropDowns = [
    {
      action: (row: KeyValueObj) => handleViewCompany(row?.id),
      label: "View",
    },
  ];

  const outboundView = (
    <div className="table-wrapper">
      <Table
        tableKeys={getRequestTableKeys()}
        tableHeaders={getRequestTableHeaders()}
        tableData={getRequestTableData(
          outboundData,
          handleViewCompany,
          "outbound"
        )}
        rowLineColor="light"
        dropDownOptions={tableDropDowns}
        showDropDown={true}
        errorLoading={outboundError?.message?.length > 0}
        full
        loading={outboundDataLoading}
        onRetry={() => setRetry((prev: number) => prev + 1)}
      />
      <Pagination
        pageCount={outboundMeta.total_pages}
        onNextPage={(event) => handleNextPage(event, "outbound")}
        onPerPageChange={(event) => handlePerPageChange(event, "outbound")}
      />
    </div>
  );

  const inboundView = (
    <div className="table-wrapper">
      <Table
        tableKeys={getRequestTableKeys()}
        tableHeaders={getRequestTableHeaders()}
        tableData={getRequestTableData(
          inboundData,
          handleViewCompany,
          "inbound"
        )}
        dropDownOptions={tableDropDowns}
        rowLineColor="light"
        errorLoading={inboundError?.message?.length > 0}
        full
        loading={inboundDataLoading}
        onRetry={() => setRetry((prev: number) => prev + 1)}
        showDropDown={true}
      />
      <Pagination
        pageCount={inboundMeta?.total_pages}
        onNextPage={(event) => handleNextPage(event, "inbound")}
        onPerPageChange={(event) => handlePerPageChange(event, "inbound")}
      />
    </div>
  );

  const TabsView = {
    Inbound: inboundView,
    Outbound: outboundView,
  };

  return (
    <RequestsWrapper>
      <PageTitleNavs
        title="Company Requests"
        backLink="#"
        bcrumbs={[{ title: "Requests", link: "#" }]}
      />
      <Modal isOpen={showCompany} close={() => setShowCompany(false)}>
        <ViewCompany
          onResponse={handleRespond}
          data={currentCompany}
          respondLoading={respondLoading}
          requestType={currentTab}
        />
      </Modal>
      <Tab
        tabsTitle={["Inbound", "Outbound"]}
        onTabClick={(title: string) => setCurrentTab(title as ITabOptions)}
        currentTab={currentTab}
      />

      {TabsView[currentTab]}
    </RequestsWrapper>
  );
}

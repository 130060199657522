export const tableKeys = [
  "name",
  "tin",
  "category",
  "email",
  "action"
]

export const tableHeaders = [
  "Company Name",
  "TIN Number",
  "Category",
  "Email",
  "Action"
]
import styled from 'styled-components';
import { PdfViewer } from "../pdfViewer";


const FileViewWrapper = styled.div`
  width: 100%;
  max-width: 100%;

  img{
    max-width: 100%;
  }

  .pdf-viewer{
   
   
    overflow: auto;
    position: relative;
    max-width: 100%;
  }
`;

export interface IFileViewerProps {
  fileUrl: string;
  fileType?: string;
}

export function FileViewer({fileUrl, fileType}: IFileViewerProps) {
  const fileExt = fileType || fileUrl?.split(".")?.pop();
  return (
    <FileViewWrapper>
      {fileExt?.toLowerCase() === 'pdf' ? (
        <div className='pdf-viewer'>
            <PdfViewer fileUrl={fileUrl} />
        </div>
      
      ) : (
        <img src={fileUrl} alt="preview" />
      )}
    </FileViewWrapper>
  );
}

import styled from "styled-components";

interface InputProps {
  login: boolean;
  fullWidth: boolean;
  indexLevel: number;
  shortWidth?: boolean;
}

export const InputWrapper = styled.div<InputProps>`
  text-align: left;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "47%")};
  opacity: 0;
  animation: inputFadeIn 0.3s ease-in forwards;
  /* neccessary to solve z index issue due to stacking context */
  z-index: ${({ indexLevel }) => 1000 - indexLevel};

  @media screen and (max-width: 831px) {
    width: 100%;
  }

  .label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: .5rem;

    .tooltip-icon {
      font-size: 1.1rem;
      color: ${({theme}) => theme.text.prim_100};
      cursor: pointer;
    }

  }

  label {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.text.primary};
    display: inline-block;
  }

  .error-message {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: ${({theme}) => theme.bg.prim_500};
    padding: .5rem;
    box-shadow: ${({theme}) => theme.shadow.shad_100};

    p {
      color: ${({ theme }) => theme.text.error};
      font-size: 0.75rem;
      margin: 4px 0;
      text-align: left;
    }
  }

  .inner-our-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .inner-level-2 {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .loading-spinner {
        position: absolute;
        top: 30%;
        right: 2rem;
        z-index: 999;
      }

      .password-input,
      .file-input {
        width: 100%;
        position: relative;

        .file-input-label {
          color: #c4c4c4;
          display: inline-block;
          font-weight: 400;
          font-size: 0.875rem;

          span {
            margin-left: 1rem;
            height: 100%;
            color: green;
          }
        }

        .password-eye,
        .icon {
          position: absolute;
          right: 1rem;
          top: 40%;
          font-size: 1.5rem;
          @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
            font-size: 1.2rem;
            top: 30%;
          }
        }
      }

      input,
      .file-input-label{
        width: 100%;
        height: 3rem;
        border: 0.5px solid ${({ theme }) => theme.border.input};
        border-radius: 0.2rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 1.125rem;
        position: relative;
        z-index: 0;

        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          margin-top: 0rem;
          font-size: 0.875rem;
        }
      }
      input:focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.bg.secondary};
        box-shadow: ${({ theme }) => theme.shadow.drag_over};
      }

      input:read-only {
        background-color: ${({ theme }) => theme.bg.prim_400};
      }

      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        color: #c4c4c4;
      }

      textarea {
        background: transparent;
        border: transparent;
        outline: transparent;
        font-size: 0.875rem;
        font-weight: 300;
        padding: 1rem;
        border: 1px solid #899ec244;
        width: 100%;
        height: 4rem;
        resize: none;
        border-radius: 0.2rem;


        :focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.bg.secondary};
        box-shadow: ${({ theme }) => theme.shadow.drag_over};
      }
      }


      .checkbox-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .checkbox-item {
          margin: 5px 25px;
          width: 120px;
          display: flex;

          input {
            width: 20px;
            height: 20px;
          }
          label {
            margin-left: 10px;
            color: white;
          }
        }
      }
    }
    button {
      background-color: transparent;
      outline: green;
      border: 1px solid green;
      padding: 5px 7px;
      font-size: 16px;
      color: green;
      border-radius: 5px;
      margin-top: 1rem;
      cursor: pointer;
      max-height: 40px;
      align-self: center;
    }

    button:disabled {
      color: #627597;
      border: 1px solid #627597;
    }
  }

  @keyframes inputFadeIn {
    to {
      opacity: 1;
    }
  }
`;

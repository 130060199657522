import {  RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./theme";
import { GlobalStyle } from "./theme/GlobalStyle";
import useAuth from "./hooks/useContexts";
import { useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { routes } from "./routes";

type themeType = typeof lightTheme;

function App() {
  const { setAuth } = useAuth();

  useEffect(() => {
    try {
      const token = localStorage.getItem("token") || "";
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      setAuth({ token, user });
    } catch (e: any) {
      console.log(e.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Disable scroll that changes number values app wide
  document.addEventListener("wheel", function (event) {
    try {
      if (
        (document.activeElement as HTMLInputElement)?.type === "number" &&
        !document.activeElement?.classList?.contains("noscroll")
      ) {
        (document.activeElement as HTMLElement)?.blur();
      }
    } catch (e: any) {
      console.log(e.message);
    }
  });

  return (
    <ThemeProvider theme={lightTheme}>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <GlobalStyle />
        <RouterProvider router={routes} />
      </SkeletonTheme>
    </ThemeProvider>
  );
}

export type { themeType };
export default App;

import { axiosInstance } from "../api/axios";
import useAuth from "./useContexts";

export function useRefreshToken() {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axiosInstance.get("/refresh", {
      withCredentials: true,
    });
    if (response.data.success) {
      setAuth((prev: any) => {
        return { ...prev, authToken: response.data.authToken, user: response.data.user };
      });
    }

    return response.data.authToken;
  };

  return refresh;
}

export default useRefreshToken;

import { axiosInstance } from "../axios";
import { LoginProps } from "../../common/types";
import { LOGIN_URL, ROLE_URL } from "../../common/constants";

// export async function registerUser(user: User) {
//   const { email, role, phone, password, repeatPassword } = user;

//   return axiosInstance.post(USER_URL, {
//     user: {
//       email,
//       password,
//       password_confirmation: repeatPassword,
//       phone_number: phone,
//       role_id: role,
//     },
//   });
// }r

export async function loginUser(user: LoginProps) {
  const { email, password } = user;
  return axiosInstance.post(LOGIN_URL, {
    email,
    password,
  });
}

export const getRoles = async () => {
  return axiosInstance.get(ROLE_URL);
};

export const sendOtp = async (
  phone: string,
  verificationCode: string,
  otp: string
) => {
  return axiosInstance.post(
    `/api/v1/users/otp_verify?phone_number=${phone}&otp_code=${otp}&verification_code=${verificationCode}`
  );
};

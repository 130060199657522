import { MainNav } from "./navigation/mainNav";
import { Footer } from "./footer";
import styled, { createGlobalStyle } from "styled-components";
import { useDashboardContext, useStore } from "../hooks/useContexts";
import { Outlet } from "react-router-dom";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .nav-container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.bg.primary};
  }

  .footer-container {
    width: 100%;
    overflow-x: hidden;
    z-index: 10;
  }
`;

export interface ILayoutProps {
  children?: JSX.Element;
}

const ScrollDisabler = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

export function Layout({ children }: ILayoutProps) {
  const { showDashboard, removeFooter } = useDashboardContext();
  const { removeMainScroller } = useStore();

  return (
    <LayoutContainer>
      {!showDashboard && (
        <nav className="nav-container">
          <MainNav />
        </nav>
      )}
      {/* {children} */}
      <Outlet />
      {showDashboard || removeFooter ? null : (
        <footer className="footer-container">
          <Footer />
        </footer>
      )}
      {removeMainScroller ? <ScrollDisabler /> : null}
    </LayoutContainer>
  );
}

import { useCallback, useEffect, useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import { FaPlus, FaRegCopy } from "react-icons/fa";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useNotification } from "src/common/components/Notifications/NotificationProvider";
import { Button } from "src/common/components/button";
import { PageSkeletonLoader } from "src/common/components/skeleton/page-skeleton";
import { API_KEY } from "src/common/constants";
import { H3, H4 } from "src/common/elements";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import styled from "styled-components";

const GeneralSettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 1rem 0;
  }

  .api-key {
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    
  }

    .api-key-top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        gap: 2rem;
      }
    }

    .icon {
      font-size: 1.5rem;
      cursor: pointer;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    label {
      color: ${({ theme }) => theme.text.prim_100};
      font-weight: 500;
      font-size: 0.9rem;
    }

    .controls {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;
    }

    .input-wrapper {
      position: relative;
      width: 100%;
    }

    .copy-icon {
      position: absolute;
      right: 1rem;
      top: 0.45rem;
      color: ${({ theme }) => theme.text.prim_100};
    }

    input {
      width: 40rem;
      max-width: 100%;
      border: 1px solid ${({ theme }) => theme.border.input};
      padding: 0.6rem 0.75rem;
      font-size: 0.95rem;
      border-radius: 0.25rem;

      :focus,
      :active {
        border: none;
        outline: 1.5px solid ${({ theme }) => theme.border.active_input};
        box-shadow: ${({ theme }) => theme.shadow.drag_over};
      }
    }
  }

  .danger-control {
    margin-top: 2rem;
  }
`;

export interface IApiKeyProps {
  existingApiKey: string;
  loading: boolean;
}

export function ApiKey({ existingApiKey, loading }: IApiKeyProps) {
  useEffect(() => {
    const hiddenKey = getHiddenKey(existingApiKey);
    setPlainKey(existingApiKey);
    setHiddenKey(hiddenKey);
  }, [existingApiKey]);
  const makeRequest = useApiCallHandling();
  const notify = useNotification();

  const [creatingKey, setCreatingKey] = useState(false);
  const [revokingKey, setRevokingKey] = useState(false);
  const [unhide, setUnhide] = useState(false);
  const [plainKey, setPlainKey] = useState("");
  const [hiddenKey, setHiddenKey] = useState("");
  const [, setApiKeyId] = useState("");

  const apiKey = unhide ? plainKey : hiddenKey;

  function getHiddenKey(apiKey: string) {
    if (!apiKey) return "";

    const firstPart = apiKey.substring(0, 5);
    const lastPart = apiKey.substring(apiKey.length - 5);

    return `${firstPart}XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX${lastPart}`;
  }

  function handleCreateKeySuccess(data: any) {
    const apiKey = data?.api_key;
    const hiddenKey = getHiddenKey(apiKey);
    setHiddenKey(hiddenKey);
    setPlainKey(apiKey);
    setApiKeyId(data?.id);
  }

  function handleRevokeKeySuccess() {
    setHiddenKey("");
    setPlainKey("");
  }

  const handleCreateKey = () => {
    setCreatingKey(true);
    makeRequest(API_KEY, "GET", {}, handleCreateKeySuccess, console.log, () =>
      setCreatingKey(false)
    );
  };
  const handleRevokeKey = useCallback(() => {
    setRevokingKey(true);
    makeRequest(
      API_KEY,
      "DELETE",
      {},
      handleRevokeKeySuccess,
      console.log,
      () => setRevokingKey(false)
    );
  }, [makeRequest]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(plainKey);
      notify({ type: "SUCCESS", message: "Copied to Clipboard" });
    } catch (e: any) {
      notify({ type: "ERROR", message: "Could not copy to clipboard" });
      console.log(e.message);
    }
  };

  if (loading) return <PageSkeletonLoader />;

  return (
    <GeneralSettingsWrapper>
      <section className="api-key">
        <div className="api-key-top">
          <div className="desc">
            <H3 className="sub-title">API Key</H3>
            <H4>Generate API Key for integration with third party</H4>
          </div>

          <Button
            loading={creatingKey}
            disabled={creatingKey}
            onClick={handleCreateKey}
          >
            <FaPlus className="icon-button" /> Generate New Key
          </Button>
        </div>

        <div className="inputs">
          {/* <label htmlFor="urlInput">Api Key</label> */}
          <span className="controls">
            <div className="input-wrapper">
              <input type="text" value={apiKey} readOnly />
              <FaRegCopy onClick={handleCopy} className="icon copy-icon" />
            </div>

            {unhide ? (
              <IoEyeOffOutline
                onClick={() => setUnhide(false)}
                className="icon"
              />
            ) : (
              <IoEyeOutline onClick={() => setUnhide(true)} className="icon" />
            )}
          </span>
        </div>
        <div className="danger-control">
          <Button
            onClick={handleRevokeKey}
            style={{ backgroundColor: "#d73d3d", color: "white" }}
            loading={revokingKey}
          >
            <BsTrash3 /> Revoke API Key
          </Button>
        </div>
      </section>
    </GeneralSettingsWrapper>
  );
}

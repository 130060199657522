import { IPromptProps, Prompt } from '.';
import { Modal } from '../modal';

export interface IPromptModalProps extends IPromptProps {
  show: boolean
  close: () => void;
}

export function PromptModal (props: IPromptModalProps) {
  return (
    <Modal isOpen={props.show} close={props.close}>
      <Prompt {...props} />
    </Modal>
  );
}

import { useNavigate, useLocation } from "react-router-dom";
// import { axiosInstance } from "../api/axios";
import useAuth from "./useContexts";

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = async () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/login", { state: { from: location }, replace: true });
      setAuth({});
      // const response = await axiosInstance("/logout");
      // if (response.data.success) {
      //   setAuth({});
      //   navigate("/login", { state: { from: location }, replace: true });
      // }
    } catch (err: any) {
      console.log(err);
    }
  };

  return logout;
};

export default useLogout;

import styled from "styled-components";
import { LightBackdrop } from "../lightBackdrop";
import { PopupWrapper } from "src/common/elements/containers";
import { RxCross2 } from "react-icons/rx";
import { ClipSpinner } from "../spinner";
import {  useEffect, useRef } from "react";
import { useStore } from "src/hooks/useContexts";

const ActionWrapper: any = styled.div<{ width?: string; maxHeight?: string }>`
  width: ${({ width }) => (width ? width : "12rem")};
  background-color: ${({ theme }) => theme.bg.prim_500};
  max-height: ${({ maxHeight }) => maxHeight ?? "auto"};
  overflow-y: ${({ maxHeight }) => (maxHeight ? "auto" : "visible")};
  overflow-x: ${({ maxHeight }) => (maxHeight ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0.5rem;
  position: relative;

  button {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 0.8rem;
    font-weight: 500;
    color: ${({ theme }) => theme.text.prim_100};
    padding: 0.75rem 1rem;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;

    :hover {
      background-color: ${({ theme }) => theme.bg.prim_400};
    }
  }

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${({ theme }) => theme.text.prim_100};
  }

  .cancel-icon {
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    background-color: ${({ theme }) => theme.bg.prim_500};
    color: red;
    position: absolute;
    right: -0.5rem;
    top: -1rem;
    cursor: pointer;
  }
`;

export interface IAction {
  label: string;
  action: (props: any) => any;
}

export interface IActionPopupProps {
  position: any;
  open: boolean;
  width?: string;
  actions: IAction[];
  onClose: () => void;
  noActionMessage?: string;
  maxHeight?: string;
  loading?: boolean;
}

export function ActionPopup({
  position,
  open,
  onClose,
  width,
  actions,
  noActionMessage,
  maxHeight,
  loading,
}: IActionPopupProps) {
  const { setRemoveMainScroller } = useStore();
  useEffect(() => {
    setRemoveMainScroller(open);

   // eslint-disable-next-line
  }, [open, setRemoveMainScroller]);

  return open ? (
    <LightBackdrop onBackdropClick={onClose}>
      <PopupWrapper position={position}>
        <Options
          actions={actions}
          width={width}
          maxHeight={maxHeight}
          onClose={onClose}
          noActionMessage={noActionMessage}
          loading={loading}
        />
      </PopupWrapper>
    </LightBackdrop>
  ) : null;
}

interface IActionOptions {
  width?: string;
  maxHeight?: string;
  actions: IAction[];
  loading?: boolean;
  onClose?: () => void;
  noActionMessage?: string;
  callActionWith?: any;
  scrollLastToView?: boolean;
  scrollParentBy?: (value: number) => void;
}

export function Options({
  loading,
  actions,
  maxHeight,
  width,
  onClose,
  noActionMessage,
  callActionWith,
  scrollLastToView,
  scrollParentBy,
}: IActionOptions) {
  const lastRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (scrollLastToView) {
      scrollParentBy?.(100);
    }

    // eslint-disable-next-line
  }, [lastRef, scrollLastToView]);

  return (
    <ActionWrapper maxHeight={maxHeight} width={width}>
      {actions.map((action: IAction, idx: number) => (
        <button
          key={idx}
          onClick={(e) =>
            callActionWith ? action.action(callActionWith) : action.action(e)
          }
        >
          <span ref={idx === 0 ? lastRef : null} className="flex-row">
            {action.label} {loading ? <ClipSpinner /> : null}
          </span>
        </button>
      ))}

      {actions?.length === 0 ? (
        <p>{noActionMessage || "No actions to perform now"}</p>
      ) : null}
      {maxHeight ? null : onClose ? (
        <RxCross2 onClick={onClose} className="cancel-icon" />
      ) : null}
    </ActionWrapper>
  );
}

import styled from "styled-components";
import { PageContainr } from "../../common/elements";

export const OTPWrapper = styled(PageContainr)`
  z-index: 4;
  align-items: flex-start;
  min-height: 90vh;
  padding: 4rem 0;

  .container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding: 32px;
    gap: 32px;
  }

  .info-sec {
    width: 40%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 36px;

    .pharma-image {
      width: 60%;
      max-width: 17rem;
    }
    .dots-image {
      position: absolute;
      left: 4rem;
      bottom: 0;
    }
  }

  .input-sec {
    width: 60%;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    padding: 56px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  .top {
    width: 100%;
    .welcome-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-weight: 700;
        font-size: 40px;
        letter-spacing: .0144px;
        color: ${({ theme }) => theme.text.primary};

        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          font-size: 35.2px;
        }
      }

      .more-info {
        margin-top: 3.2px;
        font-weight: 300;
        font-size: 13.6px;
        letter-spacing: .0144px;
        color: ${({ theme }) => theme.text.prim_100};
        max-width: 288px;
        text-align: center;
      }
    }
  }


  .inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .7rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  .resend-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    margin: 1rem 0 2rem;

    p {
      font-size: 1.2rem;
    }
  }

  .verify-btn {
    margin-top: 3rem;
  }

  .skip{
    background-color: transparent;
    color: ${({theme}) => theme.text.primary};
    outline: none;
    border: none;
    font-size: 1rem;;
  }
`;

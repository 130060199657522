import {
  ExcelWrapper,
  MyDistributorWrapper,
  SingleDistributor,
  ViewModalWrapper,
} from "./subs/style";
import { MouseEvent, useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { usePositioning } from "src/hooks/usePositioning";
import { Modal } from "src/common/components/modal";

import { BsCloudDownload } from "react-icons/bs";

//@ts-ignore
import distributorTemplate from "src/assets/excel/distributors.xlsx";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import { ClipSpinner } from "src/common/components/spinner";
import {
  COMPANY_INVITATION,
  COMPANIES_INVITATION,
  PENDING_INVITATIONS_URL,
  ACCEPTED_INVITATIONS_URL,
} from "src/common/constants";
import { singleDistributorData, tableHeaders, tableKeys } from "./subs/data";
import Table from "src/common/components/Table";
import Form from "src/common/components/form/Form";
import { KeyValueObj } from "src/common/types";
import { Button } from "src/common/components/button";
import { useNotification } from "src/common/components/Notifications/NotificationProvider";
import { ActionPopup } from "src/common/components/action-popup";
import { useParams } from "react-router-dom";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { ViewCompanyDetails } from "src/common/components/view-company";
import Tab from "src/common/components/Tab/Tab";
import useQuery from "src/hooks/useQuery";

export interface IMyDistributorsProps {}

export function InviteCompanies(props: IMyDistributorsProps) {
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [showExcelUpload, setShowExcelUpload] = useState(false);

  const [showUploadSingle, setShowUploadSingle] = useState(false);
  const [excelFile, setExcelFile] = useState<any>();
  const [responseMessages, setResponseMessage] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [, setRetry] = useState(0);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [requestCompanyType, setRequestCompanyType] = useState("");
  const [currentCompanyType] = useState("");
  const [showCompanyOptions, setShowCompanyOptions] = useState(false);

  const [registeredCompanies, setRegisteredCompanies] = useState<
    KeyValueObj[]
  >([]);
  const [pendingCompany, setPendingCompanies] = useState<
    KeyValueObj[]
  >([]);

  const [selectedCompany, setSelectedCompany] = useState<KeyValueObj>({});
  const [showViewCompanyModal, setShowViewCompanyModal] = useState(false);
  const [currentTab, setCurrentTab] = useState("Registered Invitees");

  const apiCall = useApiCallHandling();
  const notify = useNotification();
  const { companyType } = useParams();

  const [setOptionPosition, optionPosition] = usePositioning();
  const {
    data: pendingInvitees,
    loading: pendingLoading,
    error: pendingError,
  } = useQuery(PENDING_INVITATIONS_URL);
  const {
    data: acceptedInvitees,
    loading: acceptedLoading,
    error: acceptedError,
  } = useQuery(ACCEPTED_INVITATIONS_URL);


  useEffect(() => {
    const pendingCompanies =
    pendingInvitees?.map((distributor: KeyValueObj) => ({
        id: distributor?.id,
      
        email: {
          type: "string",
          value: distributor.company_email,
        },
      })) || [];

      const registeredCompanies =
      acceptedInvitees?.map((distributor: KeyValueObj) => ({
          id: distributor?.id,
          email: {
            type: "string",
            value: distributor.company_email,
          },
        })) || [];
    setPendingCompanies(pendingCompanies);
    setRegisteredCompanies(registeredCompanies);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInvitees, acceptedInvitees]);

  function handleAddDistributor(event: MouseEvent<HTMLElement>) {
    if (companyType === "distributor-importer-manufacturers") {
      setShowCompanyOptions(true);
    } else {
      setShowAddOptions(true);
    }
    setOptionPosition(event);
  }

  function handleCompanyOptionSelect(
    event: MouseEvent<HTMLElement>,
    company: string
  ) {
    setRequestCompanyType(company);
    setShowAddOptions(true);
    setShowCompanyOptions(false);
  }

  function handleUploadExcel() {
    setShowAddOptions(false);
    setShowExcelUpload(true);
    clearFileInfo();
  }

  function handleInvite() {
    setShowUploadSingle(true);
    setShowAddOptions(false);
  }

  function handleExcelSelected(e: any) {
    clearFileInfo();
    const file = e.target.files[0];
    const name = file.name;
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext !== "xlsx" && ext !== "csv") {
      setErrorMessage("File must be either .xlsx or .csv format");
      return;
    }

    setExcelFile(file);
    setFileName(name);
  }

  function clearFileInfo() {
    setErrorMessage("");
    setFileName("");
    setExcelFile("");
  }

  function handleExcelUploadSucccess(data: any) {
    if (data) {
      const messages: string[] = [];

      const invited = data?.invited?.join(", ");
      const linked = data?.linked?.join(", ");

      if (invited?.length > 1) {
        notify({
          type: "SUCCESS",
          message: "The following emails were sent invitations: " + invited,
        });
      }

      if (linked?.length > 1) {
        notify({
          type: "SUCCESS",
          message:
            `The ${currentCompanyType?.toLowerCase()}s with the following emails were linked : ` +
            linked,
        });
      }

      if (invited?.length < 1 && linked?.length < 1) {
        notify({
          type: "ERROR",
          message: "Something went wrong, check the document and try again",
        });
      } else {
        setShowExcelUpload(false);
      }

      setResponseMessage(messages);
    }
  }

  function handleExcleUpload() {
    setResponseMessage([]);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", excelFile);

    apiCall(
      COMPANIES_INVITATION,
      "POST",
      formData,
      handleExcelUploadSucccess,
      (error: any) => console.log(error),
      () => setLoading(false),
      { dontNotifyOnSucess: true }
    );
  }

  function onSingleSubmit(formValues: KeyValueObj) {
    let payload = formValues;
    if (requestCompanyType) {
      payload = { ...formValues, company_type: requestCompanyType };
    }
    setButtonLoading(true);
    apiCall(
      COMPANY_INVITATION,
      "POST",
      payload,
      () => setShowUploadSingle(false),
      (err) => console.log(err),
      () => setButtonLoading(false)
    );
  }

  const handleViewCompany = (row: KeyValueObj) => {
    const currentCompany = registeredCompanies?.find(
      (company: KeyValueObj) => company?.id === row?.id
    );
    setSelectedCompany(currentCompany || {});
    setShowViewCompanyModal(true);
  };

  const addDistributorActins = [
    {
      label: `Invite a Company`,
      action: handleInvite,
    },
    {
      label: "Upload Excel document",
      action: handleUploadExcel,
    },
  ];

  const companyOptions = [
    {
      label: `Manufacturer`,
      action: (event: MouseEvent<HTMLElement>) =>
        handleCompanyOptionSelect(event, "Manufacturer"),
    },
    {
      label: `Importer`,
      action: (event: MouseEvent<HTMLElement>) =>
        handleCompanyOptionSelect(event, "Importer"),
    },
  ];

  return (
    <MyDistributorWrapper>
      <PageTitleNavs
        title="Invite Companies"
        backLink="#"
        bcrumbs={[{ title: "invitations", link: "#" }]}
      />
      <Modal
        close={() => setShowExcelUpload(false)}
        isOpen={showExcelUpload}
        title="Upload Excel document"
      >
        <ExcelWrapper>
          <div className="info">
            <h3>
              {`Download the excel document below to serve as a template for
                uploading the list of companies`}
            </h3>
          </div>
          <div className="controls">
            <a
              href={distributorTemplate}
              download={`companies invitation template`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="download-button">
                <BsCloudDownload className="download-icon" /> Download Template
              </button>
            </a>
            <div className="file-input">
              <label>
                <input
                  className="custom-file-input"
                  onChange={handleExcelSelected}
                  type="file"
                />
                Select Excel File
              </label>
            </div>
            {fileName.length > 0 && <h2 className="file-name">{fileName}</h2>}
            {errorMessage.length > 0 && (
              <h2 className="error-message">{errorMessage}</h2>
            )}
          </div>

          <div className="response-messagees">
            {responseMessages?.map((message: string) => (
              <p>{message}</p>
            ))}
          </div>
          <div className="controls">
            <Button
              disabled={loading || !excelFile}
              onClick={handleExcleUpload}
            >
              {loading ? <ClipSpinner /> : "Upload Excel Document"}
            </Button>
          </div>
        </ExcelWrapper>
      </Modal>

      <Modal
        isOpen={showViewCompanyModal}
        close={() => setShowViewCompanyModal(false)}
      >
        <ViewModalWrapper>
          <ViewCompanyDetails data={selectedCompany} />
        </ViewModalWrapper>
      </Modal>

      {showUploadSingle && (
        <Modal
          isOpen={showUploadSingle}
          close={() => setShowUploadSingle(false)}
          title={"Enter Company Details"}
        >
          <SingleDistributor>
            <Form
              loadingState={buttonLoading}
              submitLabel={"Send Invitation"}
              formInputs={singleDistributorData}
              processInputs={onSingleSubmit}
              spinnerComponent={<ClipSpinner />}
              login={false}
              biControl={false}
              handleBack={undefined}
              submitButtonWidth="100%"
            />
          </SingleDistributor>
        </Modal>
      )}

      <ActionPopup
        position={optionPosition}
        open={showAddOptions}
        width="18rem"
        onClose={() => setShowAddOptions(false)}
        actions={addDistributorActins}
      />

      <ActionPopup
        position={optionPosition}
        open={showCompanyOptions}
        onClose={() => setShowCompanyOptions(false)}
        actions={companyOptions}
      />

      <div className="button-wrapper">
        <Button
          onClick={(e: MouseEvent<HTMLElement>) => handleAddDistributor(e)}
        >
          <BsPlusLg />
          {`Invite Company`}
        </Button>
      </div>
      <div className="tabs">
        <Tab
          tabsTitle={["Registered Invitees", "Pending Invitees"]}
          onTabClick={(title: string) => setCurrentTab(title)}
          currentTab={currentTab}
        />
      </div>
      <div className="table-wrapper-1">
        {currentTab === "Registered Invitees" ? (
          <Table
            tableData={registeredCompanies}
            tableHeaders={tableHeaders}
            tableKeys={tableKeys}
            full
            spaced
            tableTitle={`Registered Companies`}
            tableSubtitle={`Invited companies that have already registered`}
            rowLineColor="light"
            loading={acceptedLoading}
            errorLoading={!!acceptedError}
            onRetry={() => setRetry((prev) => prev + 1)}
            dropDownOptions={[
              {
                action: (row: KeyValueObj) => handleViewCompany(row),
                label: "View Company",
              },
            ]}
            showDropDown={true}
          />
        ) : (
          <Table
            tableData={pendingCompany}
            tableHeaders={tableHeaders}
            tableKeys={tableKeys}
            full
            spaced
            tableTitle={`Pending Companies`}
            tableSubtitle={`Invited companies that are yet to register in the platform`}
            rowLineColor="light"
            loading={pendingLoading}
            errorLoading={!!pendingError}
            onRetry={() => setRetry((prev) => prev + 1)}
            dropDownOptions={[
              {
                action: (row: KeyValueObj) => handleViewCompany(row),
                label: "Resend Invite",
              },
            ]}
            showDropDown={true}
          />
        )}
      </div>
    </MyDistributorWrapper>
  );
}

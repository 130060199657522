import { useEffect, useState } from "react";
import Table from "src/common/components/Table";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { RECEIVED_INVOICES } from "src/common/constants";
import useQuery from "src/hooks/useQuery";
import styled from "styled-components";
import {
  getInvoiceTableData,
  invoiceTableHeaders,
  invoiceTableKeys,
} from "./subs/data";
import { KeyValueObj } from "src/common/types";
import { useNavigate } from "react-router-dom";

const InvoicesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }
`;

export interface IInvoicesProps {}

export function Invoices(props: IInvoicesProps) {
  const {
    data: receivedInvoices,
    loading: loadingInvoices,
    error: invoicesError,
    refetch: refetchInvoices,
  } = useQuery(RECEIVED_INVOICES);
  const [invoiceTableData, setInvoiceTableData] = useState<KeyValueObj[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setInvoiceTableData(getInvoiceTableData(receivedInvoices));
  }, [receivedInvoices]);

  return (
    <InvoicesWrapper>
      <PageTitleNavs
        title="Invoices"
        backLink="#"
        bcrumbs={[{ title: "Invoices", link: "#" }]}
      />

      <div className="table-wrapper">
        <Table
          tableHeaders={invoiceTableHeaders}
          tableKeys={invoiceTableKeys}
          tableData={invoiceTableData}
          showDropDown
          loading={loadingInvoices}
          errorLoading={invoicesError?.length > 0}
          onRetry={() => refetchInvoices(RECEIVED_INVOICES)}
          dropDownOptions={[
            {
              action: (row) => navigate(row?.id),
              label: "View",
            },
          ]}
        />
      </div>
    </InvoicesWrapper>
  );
}

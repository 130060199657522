import {
  ExcelWrapper,
  ExisitingDistributors,
  MyDistributorWrapper,
  SingleDistributor,
  ViewModalWrapper,
} from "./subs/style";
import { MouseEvent, useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { usePositioning } from "../../../hooks/usePositioning";
import { Modal } from "../../../common/components/modal";

import { BsCloudDownload } from "react-icons/bs";

//@ts-ignore
import distributorTemplate from "../../../assets/excel/distributors.xlsx";
import { useApiCallHandling } from "../../../hooks/useApiCallHandling";
import { ClipSpinner } from "../../../common/components/spinner";
import {
  DISTRIBUTORS_LINKED_LSDP_URL,
  DISTRIBUTORS_UNLINKED_LSDP_URL,
  DISTRIBUTOR_ADD_COMPANY_URL,
  DISTRIBUTOR_ADD_EXISTING_COMPANY_URL,
  DISTRIBUTOR_ADD_LSDPT_URL,
  DISTRIBUTOR_LINKED_MI_URL,
  DISTRIBUTOR_LINKED_RETAILERS_URL,
  DISTRIBUTOR_UNLINKED_MI_URL,
  DISTRIBUTOR_UNLINKED_RETAILERS_URL,
  DISTRIBUTOR_UNLINK_A_COMPANY_URL,
  DISTRIBUTOR_UNLINK_LSDPT_URL,
  DISTRIBUTOR_UPLOAD_COMPANY_URL,
  DISTRIBUTOR_UPLOAD_LSDPT_URL,
  LSDP_ADD_DISTRIBUTORS_URL,
  LSDP_ADD_EXISITING_DISTRIBUTORS_URL,
  LSDP_LINKED_DISTRIBUTORS_URL,
  LSDP_UNLINKED_DISTRIBUTORS_URL,
  LSDP_UNLINK_DISTRIBUTORS_URL,
  LSDP_UPLOAD_DISTRIBUTORS_URL,
  MANUFACTURER_ADD_DISTRIBUTOR_URL,
  MANUFACTURER_ADD_EXISTING_DISTRIBUTOR_URL,
  MANUFACTURER_LINKED_DISTRIBUTORS_URL,
  MANUFACTURER_UNLINKED_DISTRIBUTORS_URL,
  MANUFACTURER_UPLOAD_DISTRIBUTORS_URL,
  RETAILER_ADD_DISTRIBUTOR_URL,
  RETAILER_ADD_EXISTING_DISTRIBUTOR_URL,
  RETAILER_LINKED_DISTRIBUTORS_URL,
  RETAILER_UNLINKED_DISTRIBUTORS_URL,
  RETAILER_UNLINK_A_DISTRIBUTOR,
  RETAILER_UPLOAD_DISTRIBUTORS_URL,
} from "../../../common/constants";
import useAxiosCall from "../../../hooks/useAxiosCall";
import {
  singleDistributorData,
  tableHeaders,
  tableKeys,
  unlinkedHeaders,
  unlinkedKeys,
} from "./subs/data";
import Table from "../../../common/components/Table";
import Form from "../../../common/components/form/Form";
import { KeyValueObj } from "../../../common/types";
import { Button } from "../../../common/components/button";
import { useNotification } from "../../../common/components/Notifications/NotificationProvider";
import { ActionPopup } from "src/common/components/action-popup";
import { useParams } from "react-router-dom";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { ViewCompanyDetails } from "src/common/components/view-company";

export interface IMyDistributorsProps {}

export function MyDistributors(props: IMyDistributorsProps) {
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [showExcelUpload, setShowExcelUpload] = useState(false);
  const [showExistingDistributors, setShowExistingDistributors] =
    useState(false);
  const [showUploadSingle, setShowUploadSingle] = useState(false);
  const [excelFile, setExcelFile] = useState<any>();
  const [responseMessages, setResponseMessage] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [retry, setRetry] = useState(0);
  const [unlinkedLoading, setUnlinkedLoading] = useState(false);
  const [unlinkedError, setUnlinkedError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedDistributors, setSelectedDistributors] = useState<
    KeyValueObj[]
  >([]);
  const [requestCompanyType, setRequestCompanyType] = useState("");
  const [currentCompanyType, setCurrentCompanyType] = useState("");
  const [showCompanyOptions, setShowCompanyOptions] = useState(false);
  const [urls, setUrls] = useState({
    linkTeam: "",
    unlinkTeam: "",
    uploadTeams: "",
    unlinkedTeams: "",
    linkExistingTeam: "",
  });

  const [linkedDistributorsTable, setLinkedDistributorsTable] = useState<
    KeyValueObj[]
  >([]);
  const [unlinkedDistributorsTable, setUnlinkedDistributorsTable] = useState<
    KeyValueObj[]
  >([]);

  const [selectedCompany, setSelectedCompany] = useState<KeyValueObj>({});
  const [showViewCompanyModal, setShowViewCompanyModal] = useState(false);

  const apiCall = useApiCallHandling();
  const notify = useNotification();
  const { companyType } = useParams();

  const [setOptionPosition, optionPosition] = usePositioning();
  const [
    fetchDistributors,
    fetchedCompanies,
    distributorsError,
    distributorsLoading,
  ] = useAxiosCall();

  useEffect(() => {
    switch (companyType) {
      case "manufacturer-distributors":
        fetchDistributors({
          method: "get",
          url: MANUFACTURER_LINKED_DISTRIBUTORS_URL,
        });
        setCurrentCompanyType("Distributor");
        setUrls({
          linkTeam: MANUFACTURER_ADD_DISTRIBUTOR_URL,
          unlinkTeam: MANUFACTURER_UNLINKED_DISTRIBUTORS_URL,
          uploadTeams: MANUFACTURER_UPLOAD_DISTRIBUTORS_URL,
          unlinkedTeams: MANUFACTURER_UNLINKED_DISTRIBUTORS_URL,
          linkExistingTeam: MANUFACTURER_ADD_EXISTING_DISTRIBUTOR_URL,
        });
        break;

      case "distributor-importer-manufacturers":
        setCurrentCompanyType("Manufacturer/importer");
        fetchDistributors({
          method: "get",
          url: DISTRIBUTOR_LINKED_MI_URL,
        });
        setUrls({
          linkTeam: DISTRIBUTOR_ADD_COMPANY_URL,
          unlinkTeam: DISTRIBUTOR_UNLINK_A_COMPANY_URL,
          uploadTeams: DISTRIBUTOR_UPLOAD_COMPANY_URL,
          unlinkedTeams: DISTRIBUTOR_UNLINKED_MI_URL,
          linkExistingTeam: DISTRIBUTOR_ADD_EXISTING_COMPANY_URL,
        });
        break;

      case "distributor-retailers":
        setCurrentCompanyType("Retailer");
        setRequestCompanyType("Retailer");
        fetchDistributors({
          method: "get",
          url: DISTRIBUTOR_LINKED_RETAILERS_URL,
        });
        setUrls({
          linkTeam: DISTRIBUTOR_ADD_COMPANY_URL,
          unlinkTeam: DISTRIBUTOR_UNLINK_A_COMPANY_URL,
          uploadTeams: DISTRIBUTOR_UPLOAD_COMPANY_URL,
          unlinkedTeams: DISTRIBUTOR_UNLINKED_RETAILERS_URL,
          linkExistingTeam: DISTRIBUTOR_ADD_EXISTING_COMPANY_URL,
        });
        break;

      case "retailer-distributors":
        setCurrentCompanyType("Distributor");
        fetchDistributors({
          method: "get",
          url: RETAILER_LINKED_DISTRIBUTORS_URL,
        });
        setUrls({
          linkTeam: RETAILER_ADD_DISTRIBUTOR_URL,
          unlinkTeam: RETAILER_UNLINK_A_DISTRIBUTOR,
          uploadTeams: RETAILER_UPLOAD_DISTRIBUTORS_URL,
          unlinkedTeams: RETAILER_UNLINKED_DISTRIBUTORS_URL,
          linkExistingTeam: RETAILER_ADD_EXISTING_DISTRIBUTOR_URL,
        });
        break;

      case "distributor-lsdp":
        setCurrentCompanyType("Logistic Service Provider");
        fetchDistributors({
          method: "get",
          url: DISTRIBUTORS_LINKED_LSDP_URL,
        });
        setUrls({
          linkTeam: DISTRIBUTOR_ADD_LSDPT_URL,
          unlinkTeam: DISTRIBUTOR_UNLINK_LSDPT_URL,
          uploadTeams: DISTRIBUTOR_UPLOAD_LSDPT_URL,
          unlinkedTeams: DISTRIBUTORS_UNLINKED_LSDP_URL,
          linkExistingTeam: DISTRIBUTOR_ADD_EXISTING_COMPANY_URL,
        });

        break;
      case "lsdp-distributors":
        setCurrentCompanyType("Distributor");
        fetchDistributors({
          method: "get",
          url: LSDP_LINKED_DISTRIBUTORS_URL,
        });
        setUrls({
          linkTeam: LSDP_ADD_DISTRIBUTORS_URL,
          unlinkTeam: LSDP_UNLINK_DISTRIBUTORS_URL,
          uploadTeams: LSDP_UPLOAD_DISTRIBUTORS_URL,
          unlinkedTeams: LSDP_UNLINKED_DISTRIBUTORS_URL,
          linkExistingTeam: LSDP_ADD_EXISITING_DISTRIBUTORS_URL,
        });

        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyType, retry]);

  useEffect(() => {
    const linkedDistributor =
      fetchedCompanies?.map((distributor: KeyValueObj) => ({
        id: distributor?.id,
        name: {
          type: "string",
          value: distributor.name,
        },
        tin: {
          type: "string",
          value: distributor.tin,
        },
        address: {
          type: "string",
          value: distributor.corporate_address,
        },
        email: {
          type: "string",
          value: distributor.email,
        },
      })) || [];
    setLinkedDistributorsTable(linkedDistributor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCompanies]);

  function handleAddDistributor(event: MouseEvent<HTMLElement>) {
    if (companyType === "distributor-importer-manufacturers") {
      setShowCompanyOptions(true);
    } else {
      setShowAddOptions(true);
    }
    setOptionPosition(event);
  }

  function handleCompanyOptionSelect(
    event: MouseEvent<HTMLElement>,
    company: string
  ) {
    setRequestCompanyType(company);
    setShowAddOptions(true);
    setShowCompanyOptions(false);
  }

  function unlinkDistributor(distributorId: string) {
    //Set loading state on the button
    setLinkedDistributorsTable((prev: KeyValueObj[]) => {
      const index = prev.findIndex(
        (distributor: KeyValueObj) => distributor.id === distributorId
      );
      prev[index] = {
        ...prev[index],
        action: {
          ...prev[index].action,
          buttonLoading: true,
        },
      };
      return prev.slice();
    });

    apiCall(
      `${urls.unlinkTeam}/${distributorId}`,
      "DELETE",
      {},
      (data: any) => {
        setLinkedDistributorsTable((prev: KeyValueObj[]) => {
          prev = prev.filter(
            (distributor: KeyValueObj) => distributor.id !== distributorId
          );
          return prev.slice();
        });
      },
      (error: any) => {
        setLinkedDistributorsTable((prev: KeyValueObj[]) => {
          const index = prev.findIndex(
            (distributor: KeyValueObj) => distributor.id === distributorId
          );
          prev[index] = {
            ...prev[index],
            action: {
              ...prev[index].action,
              buttonLoading: false,
            },
          };
          return prev.slice();
        });
      },
      () => {}
    );
  }

  function handleUploadExcel() {
    setShowAddOptions(false);
    setShowExcelUpload(true);
    clearFileInfo();
  }

  function handleSelectFromList() {
    setUnlinkedLoading(true);
    setShowAddOptions(false);
    setShowExistingDistributors(true);
    setUnlinkedError("");

    apiCall(
      urls.unlinkedTeams,
      "GET",
      {},
      (data: any) => {
        let unlinkedTeams = data;
        if (requestCompanyType) {
          unlinkedTeams = data?.filter(
            (datum: KeyValueObj) =>
              datum?.type?.toLowerCase() === requestCompanyType?.toLowerCase()
          );
        }
        const unlinkedDistributor = unlinkedTeams.map(
          (distributor: KeyValueObj) => ({
            id: distributor?.id,
            name: {
              type: "string",
              value: distributor.name,
            },
            tin: {
              type: "string",
              value: distributor.tin,
            },
            address: {
              type: "string",
              value: distributor.corporate_address,
            },
            email: {
              type: "string",
              value: distributor.email,
            },
          })
        );

        setUnlinkedDistributorsTable(unlinkedDistributor);
      },
      (error: any) => {
        setUnlinkedError(
          `Could not fetch unlinked ${currentCompanyType?.toLowerCase()}s`
        );
      },
      () => {
        setUnlinkedLoading(false);
      }
    );
  }

  function handleInvite() {
    setShowUploadSingle(true);
    setShowAddOptions(false);
  }

  function handleExcelSelected(e: any) {
    clearFileInfo();
    const file = e.target.files[0];
    const name = file.name;
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext !== "xlsx" && ext !== "csv") {
      setErrorMessage("File must be either .xlsx or .csv format");
      return;
    }

    setExcelFile(file);
    setFileName(name);
  }

  function clearFileInfo() {
    setErrorMessage("");
    setFileName("");
    setExcelFile("");
  }

  function handleExcelUploadSucccess(data: any) {
    if (data) {
      const messages: string[] = [];

      const invited = data?.invited?.join(", ");
      const linked = data?.linked?.join(", ");

      if (invited?.length > 1) {
        notify({
          type: "SUCCESS",
          message: "The following emails were sent invitations: " + invited,
        });
      }

      if (linked?.length > 1) {
        notify({
          type: "SUCCESS",
          message:
            `The ${currentCompanyType?.toLowerCase()}s with the following emails were linked : ` +
            linked,
        });
      }

      if (invited?.length < 1 && linked?.length < 1) {
        notify({
          type: "ERROR",
          message: "Something went wrong, check the document and try again",
        });
      } else {
        setShowExcelUpload(false);
      }

      setResponseMessage(messages);
    }
  }

  function handleExcleUpload() {
    setResponseMessage([]);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", excelFile);

    if (requestCompanyType) {
      formData.append("company_type", requestCompanyType);
    }
    apiCall(
      urls.uploadTeams,
      "POST",
      formData,
      handleExcelUploadSucccess,
      (error: any) => console.log(error),
      () => setLoading(false),
      { dontNotifyOnSucess: true }
    );
  }

  function onSingleSubmit(formValues: KeyValueObj) {
    let payload = formValues;
    if (requestCompanyType) {
      payload = { ...formValues, company_type: requestCompanyType };
    }
    setButtonLoading(true);
    apiCall(
      urls.linkTeam,
      "POST",
      payload,
      () => setShowUploadSingle(false),
      (err) => console.log(err),
      () => setButtonLoading(false)
    );
  }

  function onAddExistingDistSucess() {
    setShowExistingDistributors(false);
    setRetry((prev: number) => prev + 1);
  }

  function addExisingDistributors() {
    const distributors = selectedDistributors?.map((selected: KeyValueObj) => ({
      email: selected.email?.value,
    }));

    setButtonLoading(true);
    let url = urls.linkExistingTeam;
    let payload: any = { distributors: distributors };
    if (requestCompanyType) {
      url = url + "?company_type=" + requestCompanyType;
      payload = { companies: distributors };
    }
    if (companyType === "distributor-lsdp") {
      payload = { companies: distributors };
      url = url + "?company_type=DeliveryServiceProvider";
    }

  
    apiCall(
      url,
      "POST",
      payload,
      onAddExistingDistSucess,
      console.log,
      () => {
        setButtonLoading(false);
      }
    );
  }

  const handleViewCompany = (row: KeyValueObj) => {
    const currentCompany = fetchedCompanies?.find(
      (company: KeyValueObj) => company?.id === row?.id
    );
    setSelectedCompany(currentCompany);
    setShowViewCompanyModal(true);
  };

  const addDistributorActins = [
    {
      label: `Select From Existing  ${
        requestCompanyType || currentCompanyType
      }s`,
      action: handleSelectFromList,
    },
    {
      label: `Invite a(n) ${
        requestCompanyType || currentCompanyType?.toLowerCase()
      }`,
      action: handleInvite,
    },
    {
      label: "Upload Excel document",
      action: handleUploadExcel,
    },
  ];

  const companyOptions = [
    {
      label: `Manufacturer`,
      action: (event: MouseEvent<HTMLElement>) =>
        handleCompanyOptionSelect(event, "Manufacturer"),
    },
    {
      label: `Importer`,
      action: (event: MouseEvent<HTMLElement>) =>
        handleCompanyOptionSelect(event, "Importer"),
    },
  ];

  return (
    <MyDistributorWrapper>
      <PageTitleNavs
        title="My Companies"
        backLink="#"
        bcrumbs={[{ title: "My Companies", link: "#" }]}
      />
      <Modal
        close={() => setShowExcelUpload(false)}
        isOpen={showExcelUpload}
        title="Upload Excel document"
      >
        <ExcelWrapper>
          <div className="info">
            <h3>
              {`Download the excel document below to serve as a template for
                uploading the list of ${currentCompanyType?.toLowerCase()}s`}
            </h3>
          </div>
          <div className="controls">
            <a
              href={distributorTemplate}
              download={`${requestCompanyType || currentCompanyType}s template`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="download-button">
                <BsCloudDownload className="download-icon" /> Download Template
              </button>
            </a>
            <div className="file-input">
              <label>
                <input
                  className="custom-file-input"
                  onChange={handleExcelSelected}
                  type="file"
                />
                Select Excel File
              </label>
            </div>
            {fileName.length > 0 && <h2 className="file-name">{fileName}</h2>}
            {errorMessage.length > 0 && (
              <h2 className="error-message">{errorMessage}</h2>
            )}
          </div>

          <div className="response-messagees">
            {responseMessages?.map((message: string) => (
              <p>{message}</p>
            ))}
          </div>
          <div className="controls">
            <Button
              disabled={loading || !excelFile}
              onClick={handleExcleUpload}
            >
              {loading ? <ClipSpinner /> : "Upload Excel Document"}
            </Button>
          </div>
        </ExcelWrapper>
      </Modal>

      <Modal
        isOpen={showViewCompanyModal}
        close={() => setShowViewCompanyModal(false)}
      >
        <ViewModalWrapper>
          <ViewCompanyDetails data={selectedCompany} />
        </ViewModalWrapper>
      </Modal>

      {showExistingDistributors && (
        <Modal
          isOpen={showExistingDistributors}
          close={() => setShowExistingDistributors(false)}
          title={`Select ${
            requestCompanyType || currentCompanyType?.toLowerCase()
          }s to add to your list`}
        >
          <ExisitingDistributors>
            <>
              <div className="table-wrapper">
                <Table
                  tableData={unlinkedDistributorsTable}
                  tableHeaders={unlinkedHeaders}
                  tableKeys={unlinkedKeys}
                  full
                  spaced
                  withCheck
                  rowLineColor="light"
                  selectedList={selectedDistributors}
                  setSelectedList={setSelectedDistributors}
                  loading={unlinkedLoading}
                  errorLoading={!!unlinkedError}
                  onRetry={handleSelectFromList}
                />
              </div>
              <div
                className="add-distributor-btn"
                onClick={addExisingDistributors}
              >
                <Button
                  disabled={selectedDistributors?.length === 0}
                  loading={buttonLoading}
                >
                  {`  Add ${requestCompanyType || currentCompanyType}(s)`}
                </Button>
              </div>
            </>
          </ExisitingDistributors>
        </Modal>
      )}

      {showUploadSingle && (
        <Modal
          isOpen={showUploadSingle}
          close={() => setShowUploadSingle(false)}
          title={"Enter User Details"}
        >
          <SingleDistributor>
            <Form
              loadingState={buttonLoading}
              submitLabel={"Send Invitation"}
              formInputs={singleDistributorData}
              processInputs={onSingleSubmit}
              spinnerComponent={<ClipSpinner />}
              login={false}
              biControl={false}
              handleBack={undefined}
              submitButtonWidth="100%"
            />
          </SingleDistributor>
        </Modal>
      )}

      <ActionPopup
        position={optionPosition}
        open={showAddOptions}
        width="18rem"
        onClose={() => setShowAddOptions(false)}
        actions={addDistributorActins}
      />

      <ActionPopup
        position={optionPosition}
        open={showCompanyOptions}
        onClose={() => setShowCompanyOptions(false)}
        actions={companyOptions}
      />

      <div className="button-wrapper">
        <Button
          onClick={(e: MouseEvent<HTMLElement>) => handleAddDistributor(e)}
        >
          <BsPlusLg />
          {`Add ${currentCompanyType}`}
        </Button>
      </div>
      <div className="table-wrapper">
        <Table
          tableData={linkedDistributorsTable}
          tableHeaders={tableHeaders}
          tableKeys={tableKeys}
          full
          spaced
          tableTitle={`My ${currentCompanyType?.toLowerCase()}s`}
          tableSubtitle={`Favorite ${currentCompanyType?.toLowerCase()}s`}
          rowLineColor="light"
          loading={distributorsLoading}
          errorLoading={!!distributorsError}
          onRetry={() => setRetry((prev) => prev + 1)}
          dropDownOptions={[
            {
              action: (row: KeyValueObj) => handleViewCompany(row),
              label: "View",
            },
            {
              action: (row: KeyValueObj) => unlinkDistributor(row?.id),
              label: "Unlink",
            },
          ]}
          showDropDown={true}
        />
      </div>
    </MyDistributorWrapper>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "src/common/components/modal";
import { SuccessModal } from "src/pages/register/success-modal";
import { COMPANY_TYPES_URL, REGISTER_USER_URL } from "src/common/constants";
import useQuery from "src/hooks/useQuery";
import { useNotification } from "src/common/components/Notifications/NotificationProvider";
import Form from "src/common/components/form/Form";
import { User } from "src/common/types";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";

export const formInputs = [
  {
    inputType: "input",
    loadingState: true,
    prompt: "Company type type",
    name: "company_type",
    placeholder: "Loading company types",
    fullWidth: true,
    readOnly: true,
  },
  {
    inputType: "email",
    prompt: "Company email address",
    name: "email",
    placeholder: "Enter your company's email address",
    fullWidth: false,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Company Phone number",
    name: "phone",
    placeholder: "Enter the company's phone number",
    fullWidth: false,
    rules: {
      validNigerianPhoneNumber: {
        expectedValue: true,
        errorMessage: "Invalid Nigerian phone number",
      },
    },
  },

  {
    inputType: "password",
    prompt: "Password",
    name: "password",
    placeholder: "Enter password",
    fullWidth: false,
    rules: {
      containSpecialChar: {
        expectedValue: true,
        errorMessage: "Include a special character",
      },
      minLength: {
        expectedValue: 8,
        errorMessage: "Should be atleast 8 characters",
      },
      containUppercase: {
        expectedValue: true,
        errorMessage: "Include uppercase letter",
      },
      containLowercase: {
        expectedValue: true,
        errorMessage: "Include lowercase letter",
      },
    },
  },
  {
    inputType: "password",
    prompt: "Confirm",
    name: "repeatPassword",
    placeholder: "Confirm password",
    fullWidth: false,
    rules: {
      compareWithExisting: {
        expectedValue: "password",
        errorMessage: "Does not match the password",
      },
    },
  },
];

// import { SlidderWrapper } from "../styles";

// import UserDataContext from "../../../context/RegData.tsx";

export interface IStep1Data {
  email: string;
  phone_number?: number | undefined;
  company_type_id: string;
}

interface IUserDetail {
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => any;
  formData: IStep1Data;
}

function UserDetail({ handleChange, formData }: IUserDetail) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [actualForm, setActualForm] = useState<any>([]);
  const [, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setRoles] = useState<any>([]);
  const [, setLoadingRolesFailed] = useState(false);
  const [, setCurrentStep] = useState(0);

  const notify = useNotification();

  const makeRequest = useApiCallHandling();

  const {
    loading: companyTypesLoading,
    data: companyTypes,
    error: companyTypesError,
  } = useQuery(COMPANY_TYPES_URL);

  useEffect(() => {
    setFetching(true);
    setActualForm(formInputs);
    setRoles(companyTypes);
    if (!companyTypesLoading) {
      const companyType = {
        inputType: "selectInput",
        returnId: true,
        prompt: "Company type",
        name: "company_type",
        placeholder: "Select Company Type",
        fullWidth: true,
        options: companyTypes,
      };

      setActualForm((prev: any) => {
        const copy = [...prev];
        copy[0] = companyType;
        return copy;
      });
    }

    if (!companyTypesLoading && companyTypesError) {
      notify({
        type: "ERROR",
        message: "Unable to load roles, refresh the page to try again.",
      });
      setLoadingRolesFailed(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyTypes, companyTypesError, companyTypesLoading]);

  const handleProceedClick = useCallback(() => {
    setShowSuccessModal(false);
    setCurrentStep((prev) => prev + 1);
  }, []);

  async function processFormInputs(values: User) {
    let { email, company_type, phone, password, repeatPassword } = values;
    let referer = "";

    setLoading(true);
    makeRequest(
      REGISTER_USER_URL,
      "POST",
      {
        email,
        password,
        password_confirmation: repeatPassword,
        phone_number: phone,
        company_type_id: company_type,
        invited_by: referer,
      },
      (_data: any) => {},
      console.log,
      () => setLoading(false)
    );
  }

  return (
    <div>
      <Modal isOpen={showSuccessModal}>
        <SuccessModal onProceedClick={handleProceedClick} />
      </Modal>
      <div className="input_container">
        <div className="heading">MEDEX Onboarding</div>
        <div className="sub-heading">
          <div className="stepcounter">Step 1 of 4</div>
          <div className="stepcountTitle">User Details</div>
        </div>
      </div>
      <Form
        formInputs={actualForm}
        loadingState={loading}
        submitLabel="Begin Registration"
        processInputs={processFormInputs}
        login={false}
        biControl={false}
        handleBack={undefined}
        removePadding
      />
    </div>
  );
}

export default UserDetail;

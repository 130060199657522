import { Dispatch, FC, SetStateAction, useState } from "react";
import { BsCaretDown } from "react-icons/bs";

import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { KeyValueObj } from "../../../../common/types";

interface AdminProps {
  reveal: boolean;
}
const AdminSideWrapper = styled.li<AdminProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .admin-navelink {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.7rem;
    text-decoration: none;
    padding-right: 2rem;
    cursor: pointer;

    color: ${({ theme }) => theme.bg.prim_500};
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    :hover {
      background-color: ${({ theme }) => theme.bg.sec_100};
    }

    .main-dashboard-icon {
      font-size: 1.5rem;
      flex-shrink: 0;
    }

    h3 {
      font-weight: 400;
      font-size: 0.95rem;
      flex-shrink: 0;
    }
  }

  .inner-navlink {
    gap: 0.95rem;
    width: 100%;
  }

  .inner-dashboard-icon {
    font-size: 1rem;
  }

  .reveal-icon {
    margin-left: auto;
    font-size: 1.5rem;
    transition: transform 0.3s ease-in;
    transform: ${({ reveal }) => (reveal ? "rotate(-90deg)" : "rotate(0deg)")};
  }

  .nav-wrapper {
    width: 30rem;
  }

  .options {
    transition: all 0.3s ease-in;
    padding-top: ${({ reveal }) => (reveal ? "1.1rem" : "0rem")};
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    max-height: ${({ reveal }) => (reveal ? "12rem" : "0rem")};
    overflow: hidden;

    li {
      h3.inner-text {
        font-size: 0.85rem;
      }
    }
  }
`;

export interface IAdminSideItemProps {
  name: string;
  Icon: any;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  options: {
    name: string;
    link: string;
    icon: FC;
  }[];
}

export function AdminSideItem({
  name,
  Icon,
  options,
  setShowSidebar,
}: IAdminSideItemProps) {
  const [reveal, setReveal] = useState(false);

  return (
    <AdminSideWrapper reveal={reveal}>
      <div
        onClick={() => setReveal((prev: boolean) => !prev)}
        className="admin-navelink"
      >
        <Icon className="main-dashboard-icon" />
        <h3>{name}</h3>
        <BsCaretDown className="reveal-icon" />
      </div>

      <ul className="options">
        {options.map((item: KeyValueObj, i: number) => (
          <li key={i} className="flex-row nav-wrapper ">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? " admin-navelink inner-navlink active-nav"
                  : "admin-navelink inner-navlink"
              }
              to={item.link}
              onClick={() => setShowSidebar(false)}
            >
              <item.icon className="inner-dashboard-icon" />
              <h3 className="inner-text">{item.name}</h3>
            </NavLink>
          </li>
        ))}
      </ul>
    </AdminSideWrapper>
  );
}

import styled from "styled-components";
import { FilterInput } from "./input";
import { IFilterInput, KeyValueObj } from "src/common/types";
import { useCallback, useState } from "react";
import { DateInput } from "./date-input";
import { FilterSelectInput } from "./filter-select-input";
import { Button } from "../../button";
import { H3 } from "src/common/elements";

const FilterWrapper = styled.div`
  width: 35rem;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 4rem 2.1rem;
  gap: 2rem;
  background-color: ${({theme}) => theme.bg.prim_500};

  .inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 2rem 1rem;
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem 4rem;
  }
`;



export interface IFilterProps {
  onFilter?: (formValues: KeyValueObj) => void;
  filterInputs: IFilterInput[];
}

export function Filter({ onFilter, filterInputs }: IFilterProps) {
  const [formValues, setFormValues] = useState<KeyValueObj>({});

  const clearFormValues = useCallback(() => {
    const clearedValues: KeyValueObj = {};
    Object.keys(formValues).forEach((formKey: string) => {
      clearedValues[formKey] = "";
    });
    setFormValues(clearedValues);
  }, [setFormValues, formValues]);

  return (
    <FilterWrapper>
      <div className="text"><H3>Filter By: </H3></div>
      <div className="inputs">
        {filterInputs?.map((input: IFilterInput, i: number) =>
          input.type === "text" ? (
            <FilterInput
              key={i}
              indexLevel={1000 - i}
              label={input.label}
              setFormValues={setFormValues}
              name={input.name}
              formValues={formValues}
            />
          ) : input.type === "date" ? (
            <DateInput
              name={input.name}
              setFormValues={setFormValues}
              formValues={formValues}
              label={input.label}
              key={i}
              indexLevel={1000 - i}
            />
          ) : input.type === "radio" ? (
            <FilterSelectInput
              name={input.name}
              setFormValues={setFormValues}
              formValues={formValues}
              label={input.label}
              key={i}
              indexLevel={1000 - i}
              options={input.options}
            />
          ) :  input.type === "select" ? (
            <FilterSelectInput
              name={input.name}
              setFormValues={setFormValues}
              formValues={formValues}
              label={input.label}
              key={i}
              indexLevel={1000 - i}
              options={input.options}
            />
          ) : null
        )}
      </div>
      <div className="controls">
        <Button secondary onClick={clearFormValues}>
          Clear
        </Button>
        <Button onClick={() => onFilter?.(formValues)}>Filter</Button>
      </div>
    </FilterWrapper>
  );
}

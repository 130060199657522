export const formInputs = [
  {
    inputType: "input",
    loadingState: true,
    prompt: "Company type type",
    name: "company_type",
    placeholder: "Loading company types",
    fullWidth: true,
    readOnly: true,
  },
  {
    inputType: "email",
    prompt: "Company email address",
    name: "email",
    placeholder: "Enter your company's email address",
    fullWidth: false,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Company Phone number",
    name: "phone",
    placeholder: "Enter the company's phone number",
    fullWidth: false,
    rules: {
      validNigerianPhoneNumber: {
        expectedValue: true,
        errorMessage: "Invalid Nigerian phone number",
      },
    },
  },

  {
    inputType: "password",
    prompt: "Password",
    name: "password",
    placeholder: "Enter password",
    fullWidth: false,
    rules: {
      containSpecialChar: {
        expectedValue: true,
        errorMessage: "Include a special character",
      },
      minLength: {
        expectedValue: 6,
        errorMessage: "Should be atleast 6 characters",
      },
      containUppercase: {
        expectedValue: true,
        errorMessage: "Include uppercase letter",
      },
      containLowercase: {
        expectedValue: true,
        errorMessage: "Include lowercase letter",
      },
    },
  },
  {
    inputType: "password",
    prompt: "Confirm",
    name: "repeatPassword",
    placeholder: "Confirm password",
    fullWidth: false,
    rules: {
      compareWithExisting: {
        expectedValue: "password",
        errorMessage: "Does not match the password",
      },
    },
  },
];

export const noDataFormInputs = [
  {
    inputType: "selectInput",
    returnId: true,
    prompt: "User type",
    name: "role",
    placeholder: "Select User Type",
    fullWidth: true,
    options: [],
    loading: true,
  },
  {
    inputType: "email",
    prompt: "Company email address",
    name: "email",
    placeholder: "Enter your company's email address",
    fullWidth: false,
    readonly: true,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Company Phone number",
    name: "phone",
    placeholder: "Enter the company's phone number",
    fullWidth: false,
    readonly: true,
    rules: {
      validNigerianPhoneNumber: {
        expectedValue: true,
        errorMessage: "Invalid Nigerian phone number",
      },
    },
  },

  {
    inputType: "password",
    prompt: "Password",
    name: "password",
    placeholder: "Enter password",
    fullWidth: false,
    readonly: true,
    rules: {
      containSpecialChar: {
        expectedValue: true,
        errorMessage: "Include a special character",
      },
      minLength: {
        expectedValue: 6,
        errorMessage: "Should be atleast 6 characters",
      },
      containUppercase: {
        expectedValue: true,
        errorMessage: "Include uppercase letter",
      },
      containLowercase: {
        expectedValue: true,
        errorMessage: "Include lowercase letter",
      },
    },
  },
  {
    inputType: "password",
    prompt: "Confirm",
    name: "repeatPassword",
    placeholder: "Confirm password",
    fullWidth: false,
    readonly: true,
    rules: {
      compareWithExisting: {
        expectedValue: "password",
        errorMessage: "Does not match the password",
      },
    },
  },
];

export const registerRefferedInput = [
  {
    inputType: "text",
    prompt: "Company Phone number",
    name: "phone",
    placeholder: "Enter the company's phone number",

    fullWidth: true,
    rules: {
      validNigerianPhoneNumber: {
        expectedValue: true,
        errorMessage: "Invalid Nigerian phone number",
      },
    },
  },

  {
    inputType: "password",
    prompt: "Password",
    name: "password",
    placeholder: "Enter password",
    fullWidth: false,
    rules: {
      containSpecialChar: {
        expectedValue: true,
        errorMessage: "Include a special character",
      },
      minLength: {
        expectedValue: 6,
        errorMessage: "Should be atleast 6 characters",
      },
      containUppercase: {
        expectedValue: true,
        errorMessage: "Include uppercase letter",
      },
      containLowercase: {
        expectedValue: true,
        errorMessage: "Include lowercase letter",
      },
    },
  },
  {
    inputType: "password",
    prompt: "Confirm",
    name: "repeatPassword",
    placeholder: "Confirm password",
    fullWidth: false,
    rules: {
      compareWithExisting: {
        expectedValue: "password",
        errorMessage: "Does not match the password",
      },
    },
  },
];

import { StatsWrapper } from "./subs/style";
import { Product } from "./subs/product";
import { Notification } from "../common/Notification";

import statImage from "src/assets/images/stat_dougnut.png";
import image2 from "../../../assets/images/face2.jpg";
import image3 from "../../../assets/images/face3.jpg";
import image4 from "../../../assets/images/face4.jpg";
import { LineChart } from "./subs/LIneChart";

interface IDistributor {
  name: string;
  address: string;
}

const distributors = [
  {
    name: "Quado Medical Distribution",
    address: "No.156 Baga Road, Off Bolori Road, Maiduguri, Borno State",
  },
  {
    name: "Ibra and Sons",
    address: "No.156 Creseent, Off Bolori Road, Dutse, Jigawa State",
  },
  {
    name: "Martins Distribution",
    address: "No.42 Aduga Street, New Layout, Ikeja, Lagos State",
  },
  {
    name: "Francis Distribution Limited",
    address: "Plot 13, Off Bolori Road, Akwaba, Minna State",
  },
];

export interface IStatsProps {}

export function Stats(props: IStatsProps) {
  return (
    <StatsWrapper>
      <div className="product-cards">
        <Product
          amount={10}
          title="Products Delivered"
          increase="20% increase from last week"
          bgColor="#8BA5E8"
        />
        <Product
          amount={26}
          title="Products Shipped"
          increase="15% increase from last month"
          bgColor="#ECAF57"
        />
        <Product
          amount={10}
          title="Products Delivered"
          increase="5% increase from last week"
          bgColor="#36CDB0"
        />
      </div>
      <div className="top-row flex-row">
        <div className="client-chart top-card flex-col">
          <div className="title flex-row">
            <h2 className="dash-title">Order Statistics</h2>
            <h3 className="dash-text">Aug 25 - Sep 25</h3>
          </div>
          <div className="client-lower flex-row">
            <div className="statuses flex-col">
              <div className="status flex-col">
                <div className="status-inner flex-row">
                  <div className="dot green-dot"></div>
                  <h3 className="dash-text">Delivered</h3>
                </div>
                <h2 className="value">60%</h2>
              </div>
              <div className="status flex-col">
                <div className="status-inner flex-row">
                  <div className="dot orange-dot"></div>
                  <h3 className="dash-text">Pending</h3>
                </div>
                <h2 className="value">46%</h2>
              </div>
              <div className="status flex-col">
                <div className="status-inner flex-row">
                  <div className="dot red-dot"></div>
                  <h3 className="dash-text">Rejected</h3>
                </div>
                <h2 className="value">30%</h2>
              </div>
              <div className="status flex-col">
                <div className="status-inner flex-row">
                  <div className="dot blue-dot"></div>
                  <h3 className="dash-text">Shipped</h3>
                </div>
                <h2 className="value">50%</h2>
              </div>
            </div>
            <div className="image">
              <img src={statImage} alt="clinet stats" />
            </div>
          </div>
        </div>

        <div className="distributors top-card">
          <h2 className="dash-title underline">Active Distributors</h2>
          <div className="distri-table flex-col">
            <div className="distri-tb-row flex-row">
              <h3 className="table-header left">Company Name</h3>
              <h3 className="table-header right">Address</h3>
            </div>

            {distributors?.map((distributor: IDistributor, i: number) => (
              <div key={i} className="distri-tb-row flex-row">
                <h3 className="table-text left">{distributor.name}</h3>
                <h3 className="table-text right">{distributor.address}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="lower-row flex-row">
        <div className="line-chart">
          <LineChart />
        </div>
        <div className="notifications flex-col">
          <div className="title flex-row">
            <h2 className="dash-title">Notifications</h2>
            <h3>View All</h3>
          </div>
          <div className="notifications-wrapper flex-col">
            <Notification
              title="Ellie acknowledged reciept of quotation"
              date="04 April, 2021 | 04: 00PM"
              imageUrl={image2}
            />
            <Notification
              title="Jenny joined team HR"
              date="04 April, 2021 | 04: 00PM"
              imageUrl={image3}
            />
            <Notification
              title="Adam got employee of the month"
              date="04 April, 2021 | 04: 00PM"
              imageUrl={image4}
            />
            <Notification
              title="Rober joined the distributors"
              date="04 April, 2021 | 04: 00PM"
              imageUrl={image2}
            />
            <Notification
              title="Jack acknowledged receipt"
              date="04 April, 2021 | 04: 00PM"
              imageUrl={image4}
            />
          </div>
        </div>
      </div>
    </StatsWrapper>
  );
}

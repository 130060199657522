export const formInputs = [
  {
    inputType: "email",
    prompt: "Email address",
    name: "email",
    placeholder: "Enter email",
    fullWidth: true,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },

  {
    fullWidth: true,
    inputType: "password",
    prompt: "Password",
    name: "password",
    placeholder: "Enter password",
  },
];

export const resetPasswordInputs = [
  {
    inputType: "email",
    prompt: "Email address",
    name: "email",
    placeholder: "Enter email",
    fullWidth: true,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },
];

export const stageToNumber: any = {
  email_verification_stage: 1,
  phone_number_verification_stage: 2,
  tin_verification_stage: 3,
  pcn_licence_stage: 4,
};

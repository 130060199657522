import { FiLogOut } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Logo } from "../../../../common/components/logo";
import useLogout from "../../../../hooks/useLogout";
import { useAuthDetails } from "../../../../hooks/useAuthDetails";
import { KeyValueObj } from "../../../../common/types";
import { AdminSideItem } from "./admin-side-item";

import { sidebarItems, adminsideItems, pcnSideItems } from "./data";
import React, { Dispatch, SetStateAction } from "react";
import { MdOutlineClose } from "react-icons/md";

const DashSideWrapper = styled.div<{ showSidebar: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.2s ease-in;
  padding: 2.75rem 1rem;
  background-color: ${({ theme }) => theme.bg.secondary};
  min-height: 100vh;
  width: 12.5rem;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  .icon-wrapper {
    width: 100%;
    justify-content: flex-end;
    display: none;

    .close-icon {
      color: red;
      width: 2rem;
      height: 2rem;
      background-color: #ffc20c21;
      border-radius: 50%;
      margin-top: -2rem;
    }

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      display: flex;
    }
  }

  .logo-wrapper {
    text-decoration: none;
    width: 100%;
    .logo {
      margin-right: 1rem;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      color: ${({ theme }) => theme.bg.secondary};
      font-weight: 700;
    }
  }

  .dashboard-items {
    width: 100%;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 2rem;

    .dashboard-item {
      justify-content: flex-start;
      width: 100%;
    }
    .line-wrapper {
      width: 100%;
      padding-right: 2rem;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.text.muted};
    }

    .navlink {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
      cursor: pointer;
      color: ${({ theme }) => theme.bg.prim_500};
      padding: 0.75rem 0.5rem;
      font-size: 1rem;
      border-radius: 0.25rem;
      :hover {
        background-color: ${({ theme }) => theme.bg.sec_100};
      }

      .dashboard-icon {
        font-size: 1rem;
        flex-shrink: 0;
        color: ${({ theme }) => theme.bg.prim_500};
        transition: all ease-in-out 0.5s;
      }

      h3 {
        font-weight: 400;
        font-size: 0.9rem;
        flex-shrink: 0;
        max-width: fit-content;
      }
    }

    .active-nav {
      background-color: ${({ theme }) => theme.bg.sec_100};
    }
  }

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    width: 18rem;
    height: 100vh;
    bottom: 0;
    top: 0;
    height: 100vh;
    left: ${({ showSidebar }) => (showSidebar ? 0 : "-18rem")};
    transition: left ease-in-out 0.3s;
  }

  .logo {
    padding: 0;
    margin: 0;
    transform: scale(1);
  }

  @media (max-width: 1024px) and (min-width: ${({ theme }) => theme.screen.mobile}) {
    width: 5rem;
    overflow: hidden;
    :hover {
      width: 12.5rem;
      box-shadow: ${({ theme }) => theme.shadow.shad_100};
    }

    .dashboard-items .navlink {
      gap: 2rem;
      padding-left: 0.75rem;

      .dashboard-icon {
        font-size: 1.5rem;
      }
    }

    .logo {
      padding: 0;
      margin: 0;
      margin-left: -1.6rem;
      transform: scale(0.7);
    }
  }
`;

export interface IDashSidebarProps {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
}

export function DashboardSidebar({
  showSidebar,
  setShowSidebar,
}: IDashSidebarProps) {
  const logout = useLogout();
  const { companyType, role } = useAuthDetails();

  function isEligible(roles: string[]): boolean {
    let eligible = false;
    roles.forEach((eachRole: string) => {
      if (
        eachRole === "*" ||
        eachRole === companyType?.toLowerCase() ||
        eachRole === role?.toLowerCase()
      ) {
        eligible = true;
      }
    });
    return eligible;
  }

  return (
    <DashSideWrapper showSidebar={showSidebar}>
      <div className="icon-wrapper">
        <MdOutlineClose
          onClick={() => setShowSidebar(false)}
          className="close-icon"
        />
      </div>
      <NavLink to="/" className="flex-row logo-wrapper">
        <div className="logo">
          <Logo />
        </div>
      </NavLink>
      <ul className="flex-col dashboard-items">
        {sidebarItems.map((item: KeyValueObj, i: number) => (
          <React.Fragment key={i}>
            {item.type === "line" &&
              companyType?.toLowerCase() !== "super admin" && (
                <div key={i} className="line-wrapper">
                  <div className="line"></div>
                </div>
              )}
            {item.type === "item" && isEligible(item.roles) && (
              <li
                onClick={() => setShowSidebar(false)}
                key={i}
                className="flex-row dashboard-item"
              >
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "navlink active-nav" : "navlink"
                  }
                  to={item.link}
                >
                  <item.icon className="dashboard-icon" />
                  <h3>{item.name}</h3>
                </NavLink>
              </li>
            )}
          </React.Fragment>
        ))}

        {pcnSideItems.map(
          (item: KeyValueObj, i: number) =>
            item.type === "item" &&
            isEligible(item.roles) && (
              <li
                onClick={() => setShowSidebar(false)}
                key={i}
                className="flex-row dashboard-item"
              >
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "navlink active-nav" : "navlink"
                  }
                  to={item.link}
                >
                  <item.icon className="dashboard-icon" />
                  <h3>{item.name}</h3>
                </NavLink>
              </li>
            )
        )}

        {/* Admin side bar items */}
        {role?.toLowerCase() === "super admin" &&
          adminsideItems.map((item: KeyValueObj, index: number) => (
            <AdminSideItem
              key={index}
              name={item.name}
              Icon={item.icon}
              options={item.options}
              setShowSidebar={setShowSidebar}
            />
          ))}

        {/* Logout */}

        <li
          onClick={() => {
            setShowSidebar(false);
            logout();
          }}
          className="flex-row dashboard-item"
        >
          <div className="logout-wrapper navlink">
            <FiLogOut className="dashboard-icon" />
            <h3>Logout</h3>
          </div>
        </li>
      </ul>
    </DashSideWrapper>
  );
}

import { states } from "src/common/data";
import { KeyValueObj } from "src/common/types";

export const getDeliveryAddressFormInputs = (editingAddress?: KeyValueObj) => [
  {
    inputType: "textarea",
    prompt: "Address line 1",
    name: "address_line_one",
    placeholder: "e.g. No 2, Jabbi street",
    fullWidth: false,
    shortWidth: false,
    options: [],
    initialValue: editingAddress?.address_line_one,
    rules: {
      minLength: {
        expectedValue: 2,
        errorMessage: "Address line 1 should be atleast two characters",
      },
    },
  },

  {
    inputType: "textarea",
    prompt: "Address line 2",
    name: "address_line_two",
    placeholder: "e.g. Apo resettlement",
    fullWidth: false,
    shortWidth: false,
    options: [],
    initialValue: editingAddress?.address_line_two,
  },

  {
    inputType: "text",
    prompt: "City",
    name: "city",
    placeholder: "eg. Abuja",
    fullWidth: false,
    shortWidth: false,
    initialValue: editingAddress?.city,
    rules: {
      minLength: {
        expectedValue: 2,
        errorMessage: "City should be atleast two characters",
      },
    },
  },

  {
    inputType: "text",
    prompt: "Postal Code",
    name: "postal_code",
    placeholder: "eg. 420220",
    fullWidth: false,
    shortWidth: false,
    maxLength: 6,
    initialValue: editingAddress?.postal_code,
    rules: {
      length: {
        expectedValue: 6,
        errorMessage: "Postal code should 6 characters",
      },
    },
  },

  {
    inputType: "selectParent",
    prompt: "State",
    name: "state",
    placeholder: "Select your state",
    fullWidth: false,
    options: states,
    initialValue: editingAddress?.state,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "State must be selected",
      },
    },
  },
  {
    inputType: "selectChild",
    prompt: "Local Government Area",
    name: "lga",
    placeholder: "Select your LGA",
    fullWidth: false,
    options: states,
    parentName: "state",
    initialValue: editingAddress?.lga,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Lga must be selected",
      },
    },
  },
];

export const getNewDeliveryAddressFormInputs = (
  editingAddress?: KeyValueObj
) => [
  {
    inputType: "text",
    prompt: "Building Number",
    name: "building_number",
    placeholder: "Shop B7",
    fullWidth: false,
    shortWidth: false,
    initialValue: editingAddress?.building_number,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Building number should be atleast one character",
      },
    },
  },

  {
    inputType: "text",
    prompt: "Street",
    name: "street",
    placeholder: "Jalabi crescent",
    fullWidth: false,
    shortWidth: false,
    initialValue: editingAddress?.street,
  },

  {
    inputType: "text",
    prompt: "City",
    name: "city",
    placeholder: "eg. Abuja",
    fullWidth: false,
    shortWidth: false,
    initialValue: editingAddress?.city,
    rules: {
      minLength: {
        expectedValue: 2,
        errorMessage: "City should be atleast two characters",
      },
    },
  },

  {
    inputType: "selectParent",
    prompt: "State",
    name: "state",
    placeholder: "Select your state",
    fullWidth: false,
    options: states,
    initialValue: editingAddress?.state,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "State must be selected",
      },
    },
  },
  {
    inputType: "selectChild",
    prompt: "Local Government Area",
    name: "lga",
    placeholder: "Select your LGA",
    fullWidth: false,
    options: states,
    parentName: "state",
    initialValue: editingAddress?.lga,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Lga must be selected",
      },
    },
  },
];

import styled from "styled-components";

interface ModalProps {
  stage: number;
}

export const ModalWrapper = styled.div<ModalProps>`
  transition: all 0.3s ease-in;
  width: ${({ stage }) => (stage === 1 ? "55rem" : "40rem")};
  max-width: 95vw;
  height: 90vh;
  position: relative;
`;

export const ModalFormWrapper = styled.div<ModalProps>`
  width: 100%;
  border-radius: 0.25rem;
  padding: 3rem 0rem;
  position: absolute;
  top: 0;
  z-index: ${({ stage }) => (stage === 1 ? "1" : "0")};
  max-height: ${({ stage }) => (stage === 1 ? "none" : "90vh")};
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  animation: ${({ stage }) =>
    stage === 1 ? "fadeIn .5s forwards ease-in .3s" : "none"};

  .form-error {
    width: 100%;
    gap: 2rem;

    .error-text {
      color: ${({ theme }) => theme.bg.secondary};
      padding: 2rem;
      border: 1px solid ${({ theme }) => theme.bg.secondary};
      border-radius: 0.25rem;
      font-weight: 500;
      font-size: 1.3rem;
      background-color: ${({ theme }) => theme.bg.secondary}1f;
    }
  }
`;

export const ModalPreviewWrapper = styled.div<ModalProps>`
  position: absolute;
  top: 0;
  z-index: ${({ stage }) => (stage === 3 ? "1" : "0")};
  max-width: 40rem;
  padding: 0 3rem;
  border-radius: 0.25rem;
  opacity: 0;
  animation: ${({ stage }) =>
    stage === 3 ? "fadeIn .5s forwards ease-in .3s" : "none"};

@media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: .5rem;
   
  }
`;

export const ModalImageWrapper = styled.div<ModalProps>`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: ${({ stage }) => (stage === 2 ? "1" : "0")};
  max-width: 50rem;
  padding: 0rem 3rem;
  border-radius: 0.25rem;
  opacity: 0;
  animation: ${({ stage }) =>
    stage === 2 ? "fadeIn .5s forwards ease-in .3s" : "none"};


@media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 0.5rem;
   
  }

  .existing-images-wrapper {
    border-bottom: 1px solid ${({ theme }) => theme.text.prim_400};
    padding-bottom: 3rem;
    margin-bottom: 1rem;

    .existing-images {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2rem;
    }
  }

  .delete-all {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
  }

  .text-prompt {
    margin: 3rem 0 1rem;
  }
  .image-inputs {
    width: 100%;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;

   
  }

  .add-more {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .addmore-icon {
      font-size: 1.3rem;
    }
  }

  .image-controls {
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;
  }
`;

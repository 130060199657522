import styled from "styled-components";
interface FormProps {
  login?: boolean;
  removePadding?: boolean;
}
export const GitFormWrapper = styled.div<FormProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: ${({ removePadding }) => (removePadding ? "0" : "2rem")};
  border-radius: 0.25rem;
  z-index: 9999;

  form {
    width: 100%;
    opacity: 0.95;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2.5rem 4%;
    max-width: 50rem;

  

    .remember-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -1rem;

      .remember-me {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        input {
          width: 1.25rem;
          height: 1.25rem;
        }

        h3 {
          font-weight: 400;
          font-size: 0.95rem;
          line-height: 1.375rem;
          letter-spacing: 0.0009rem;
          color: ${({ theme }) => theme.text.input};
        }
      }

      h4 {
        color: ${({ theme }) => theme.bg.secondary};
        font-weight: 700;
        font-size: 0.95rem;
        line-height: 1.4375rem;
        letter-spacing: 0.0009rem;
        cursor: pointer;
      }
    }

    .submit-wrapper {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-back,
      .btn-continue {
        width: 12rem;
        height: 3.5rem;
        font-weight: 400;
        font-size: 1.2rem;
        color: ${({ theme }) => theme.text.progress};
        border: 1px solid ${({ theme }) => theme.text.progress};
        background-color: transparent;
      }

      button.btn-continue {
        background-color: ${({ theme }) => theme.text.progress};
        color: ${({ theme }) => theme.text.inverted};

        :disabled {
          background-color: ${({ theme }) => theme.bg.btn_disabled};
          cursor: not-allowed;
        }
      }
    }

    .submit-wrapper-single {
      justify-content: center;
    }
  }

  .form-category {
    width: 100%;
    margin-top: 1rem;
  }
`;

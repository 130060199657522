import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { useState, MouseEvent } from "react";
import {
  PiCaretRightBold,
  PiCaretLeftBold,
  PiCaretDownBold,
} from "react-icons/pi";
import { ActionPopup } from "../action-popup";
import { usePositioning } from "src/hooks/usePositioning";

const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 6rem;
  overflow: auto;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    gap:1rem;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .per-page {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    padding: 0.35rem;
    background-color: ${({ theme }) => theme.bg.prim_500};

    .page-size {
      padding: 0.5rem 0.75rem;
      display: flex;
      gap: 1rem;
      text-align: center;
      outline: none;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.bg.prim_500};
      border-radius: 0.25rem;
      cursor: pointer;
    }
  }

  .pagination {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .pagination-container {
    padding: 0.6rem 0rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    position: relative;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      
    }
  }

  .pagination a {
    padding: 0.2rem 0.6rem;
    color: ${({ theme }) => theme.text.prim_100};
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 0.2rem;
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => theme.bg.secondary};
      color: white;
    }
  }

  .icon {
    font-size: 1.2rem;
  }

  .previous-btn,
  .next-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 2.5rem;
    top: 0;
    background-color: ${({ theme }) => theme.bg.prim_500};
  }

  .previous-btn {
    left: -3rem;
  }
  .next-btn {
    right: -3rem;
  }

  .pagination-active a {
    background-color: ${({ theme }) => theme.bg.secondary};
    padding: 0.2rem 0.6rem;
    color: white;
  }
`;

export interface IPaginationProps {
  pageCount: number;
  onNextPage: (event: { selected: number }) => void;
  onPerPageChange: (event: any) => void;
}

const actions = [5, 10, 15, 20, 25, 30, 50, 100];

export function Pagination({
  pageCount,
  onNextPage,
  onPerPageChange,
}: IPaginationProps) {
  const [setPosition, position] = usePositioning();
  const [open, setOpen] = useState(false);
  const [perPage, setPerpage] = useState("25");

  function onActionClicked(act: string) {
    setPerpage(act);
    const event = {
      target: {
        value: act,
      },
      currentTarget: {
        value: act,
      },
    };
    onPerPageChange(event);
    setOpen(false);
  }

  function onPerpageClick(e: MouseEvent<HTMLElement>) {
    setPosition(e);
    setOpen(true);
  }

  const popActions = actions.map((act: number) => ({
    label: act.toString(),
    action: () => onActionClicked(act.toString()),
  }));

  return (
    <PaginationWrapper>
      <ActionPopup
        position={position}
        open={open}
        actions={popActions}
        onClose={() => setOpen(false)}
        width="5rem"
        maxHeight="15rem"
      />
      {pageCount > 1 && (
        <div className="pagination-container">
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={(val) => {console.log({val});onNextPage({selected: val?.selected+1})}}
            previousLabel={<PiCaretLeftBold className="icon" />}
            nextLabel={<PiCaretRightBold className="icon" />}
            containerClassName="pagination"
            previousLinkClassName="previous-btn"
            nextLinkClassName="next-btn"
            disabledClassName="pagination-disabled"
            activeClassName="pagination-active"
            forcePage={0}
          />
        </div>
      )}

      <div className="per-page">
        <label className="dash-text">Show</label>
        <button className="page-size" onClick={onPerpageClick}>
          {perPage}
          <PiCaretDownBold />
        </button>
      </div>
    </PaginationWrapper>
  );
}

import Form from "src/common/components/form/Form";
import { KeyValueContainer } from "src/common/components/key-value-wrapper";
import { H3, H4 } from "src/common/elements";
import { IAddedBatch, IBatchData, KeyValueObj } from "src/common/types/others";
import styled from "styled-components";
import {
  AddedBatchTableHeader,
  addedBatchesTableKeys,
  getAddedBatchTableData,
  getBatchesbatchFormInputs,
} from "./data";
import { useEffect, useState } from "react";
import Table from "src/common/components/Table";
import { Button } from "src/common/components/button";

const BatchSelectors = styled.div`
  width: 45rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .total {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.text.prim_50};
    font-weight: 500;

    .total-value {
      font-weight: 600;
    }
  }
`;

export interface IBatchSelectorProps {
  productData: IBatchData[];
  handleContinue: (addedBatches: IAddedBatch[], productId: string) => void;
  orderProduct: KeyValueObj;
}

export function BatchSelector({
  productData,
  handleContinue,
  orderProduct,
}: IBatchSelectorProps) {
  const [maxQuantity, setMaxQuantity] = useState(Infinity);
  const [addedBatches, setAddedBatches] = useState<IAddedBatch[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let total = 0;
    addedBatches.forEach((batch: IAddedBatch) => {
      total += batch.quantity;
    });
    setTotalProducts(total);
  }, [addedBatches]);

  const product = productData?.[0];

  const productKeyValueData = [
    {
      title: "Brand Name",
      value: product.brand_name,
    },
    {
      title: "Strength",
      value: product.strength + product.strength_unit,
    },
    {
      title: "Total Quantity Required",
      value: orderProduct?.quantity || "",
    },
  ];

  function handleSelectChange(id: string) {
    let quantity = id.split(": ")[1]?.slice(0, -1);
    setMaxQuantity(parseInt(quantity));
  }

  function handleRemoveAddedBatch(batchNo: string) {
    setAddedBatches((prev: IAddedBatch[]) => {
      const copy = [...prev];
      const batchIndex = copy.findIndex(
        (addedBatch: IAddedBatch) => addedBatch.batch_number === batchNo
      );
      if (batchIndex !== -1) {
        copy.splice(batchIndex, 1);
      }
      return copy;
    });
  }

  function handleAddToBatches(values: any) {
    setErrorMessage("");

    // Check that quantity does not exceed the required quantity
    const quantity = parseInt(values.quantity);
    let totalQuantity =
      addedBatches.reduce(
        (acc, currentValue) => acc + currentValue.quantity,
        0
      ) + quantity;
    if (totalQuantity > parseInt(orderProduct.quantity)) {
      return setErrorMessage("Quantity selected exceeds required quantity");
    }

    const batch_number = values?.batch?.split(" ")?.[0];
    const batchInfo = productData.find(
      (product: IBatchData) => product.batch_number === batch_number
    );
    //check that the batch does not already exist before adding
    const batchIndex = addedBatches.findIndex(
      (addedBatch: IAddedBatch) => addedBatch.batch_number === batch_number
    );
    if (batchIndex !== -1) {
      return setErrorMessage("Batch already added");
    }

  
    setAddedBatches((prev: IAddedBatch[]) => [
      ...prev,
      {
        batch_number,
        quantity,
        inventory_id: batchInfo?.inventory_id || "",
        brand_name: orderProduct?.brand_name || "",
        strength: orderProduct.product_strength || "",
        strength_unit: orderProduct.product_strength_unit || "",
      },
    ]);
  }

  return (
    <BatchSelectors>
      <section>
        <H3>Product Details</H3>
        <KeyValueContainer data={productKeyValueData} />
      </section>

      <section>
        <H3>Select Batches</H3>
        <Form
          removePadding
          formInputs={getBatchesbatchFormInputs(
            productData,
            maxQuantity,
            handleSelectChange
          )}
          submitLabel="Add"
          submitButtonType="secondary"
          processInputs={handleAddToBatches}
        />
        {errorMessage.length > 0 ? (
          <H4 className="error-message">{errorMessage}</H4>
        ) : null}
        <div>
          <Table
            tableHeaders={AddedBatchTableHeader}
            tableKeys={addedBatchesTableKeys}
            rowLineColor="light"
            removeFilters
            tableData={getAddedBatchTableData(
              addedBatches,
              handleRemoveAddedBatch
            )}
          />
          <h4 className="total">
            Total Quantity Selected:{" "}
            <span className="total-value">{totalProducts}</span>
          </h4>
        </div>
      </section>

      <div style={{ marginTop: "1.5rem" }} className="flex-row">
        <Button
          disabled={
            totalProducts?.toString() !== orderProduct?.quantity?.toString()
          }
          onClick={() =>
            handleContinue(addedBatches, orderProduct?.atc_code_id)
          }
        >
          Continue
        </Button>
      </div>
    </BatchSelectors>
  );
}

import { useRef, useState, useEffect } from "react";
import { Button } from "src/common/components/button";
import { Modal } from "src/common/components/modal";
import { GENERATE_CBS_INVOICE_URL } from "src/common/constants";
import { H2, H3 } from "src/common/elements";
import { KeyValueObj } from "src/common/types";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";
import styled from "styled-components";
import { InvoiceCard } from "./invoice-card";
import { formatToNaira } from "src/common/utils/general";
import { useReactToPrint } from "react-to-print";
import { FcPrint } from "react-icons/fc";

const InvoiceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rm;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .top-control {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .print-wrapper {
    margin-top: 2rem;
    width: 100%;
  }
  .main-page {
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;

  .generated-title {
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    text-align: left;
  }
  .generated-invoices {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
  }

  .payment-stats {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.border.light};
    padding-bottom: 2rem;
  }

  .payment-stat {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    border-radius: 0.2rem;

    .stat-title {
      font-size: 1.1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.prim_100};
    }

    .stat-value {
      font-size: 1.5rem;
      font-weight: 700;
      color: ${({ theme }) => theme.text.prim_50};
    }
  }
`;

const GenerateModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 25rem;

  .inputs {
    width: 100%;
  }

  .amount-input {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.text.prim_100};
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
  }

  label {
    font-size: 0.85rem;
    margin-top: 0.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.text.prim_100};
    display: block;
  }

  .amount-check {
    width: 1rem;
    height: 1rem;
  }
`;

export interface IInvoiceListProps {
  outstandingAmount: string;
  totalAmount: string;
  generatedInvoices: KeyValueObj[];
  amountPaid: string;
  paymentTerm: string;
  invoiceId: string;
  orderId: string;
  reloadInvoice: () => void;
}
let timeout: any;

export function InvoiceList({
  outstandingAmount,
  totalAmount,
  amountPaid,
  paymentTerm,
  generatedInvoices,
  orderId,
  invoiceId,
  reloadInvoice,
}: IInvoiceListProps) {
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [isFullChecked, setIsFullChecked] = useState(false);
  const [generatingInvoice, setGeneratingInvoice] = useState(false);
  const [showCheck, setShowCheck] = useState(true);
  const [amount, setAmount] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const makeRequest = useApiCallHandling();

  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  function handleGenerateNew() {
    setShowGenerateModal(true);
    timeout = setTimeout(() => {
      inputRef?.current?.focus();
    }, 500);
    if (
      paymentTerm === "Advance Payment" ||
      paymentTerm === "Cash on Delivery (COD)"
    ) {
      setIsFullChecked(true);
      setAmount(outstandingAmount);
      setShowCheck(false);
    }
  }
  function handleAmountCheck(event: any) {
    const checked = event.target.checked;
    setIsFullChecked(checked);
    if (checked) {
      setAmount(outstandingAmount);
    }
  }

  function generateCbsInvoice() {
    setGeneratingInvoice(true);
    makeRequest(
      GENERATE_CBS_INVOICE_URL,
      "POST",
      {
        amount: parseFloat(amount),
        order_id: orderId,
        invoice_id: invoiceId,
      },
      (data: any) => {
        setShowGenerateModal(false);
        reloadInvoice();
      },
      console.log,
      () => setGeneratingInvoice(false)
    );
  }

  const handlePrint = useReactToPrint({
    content: () => invoicesRef?.current,
    documentTitle: "Quotation",
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });

  const invoicesRef = useRef<HTMLDivElement>(null);

  return (
    <InvoiceListWrapper>
      <Modal
        isOpen={showGenerateModal}
        close={() => setShowGenerateModal(false)}
      >
        <GenerateModalWrapper>
          <H3>Enter Amout to pay</H3>
          <div className="inputs">
            <input
              ref={inputRef}
              value={amount}
              onChange={(event) => setAmount(event?.target?.value)}
              type="number"
              className="amount-input"
              readOnly={isFullChecked}
            />
            {showCheck ? (
              <label htmlFor="isAll">
                <input
                  onChange={handleAmountCheck}
                  className="amount-check"
                  type="checkbox"
                  id="isAll"
                />{" "}
                Pay Full ballance
              </label>
            ) : null}
          </div>
          <Button
            onClick={generateCbsInvoice}
            loading={generatingInvoice}
            disabled={parseInt(amount) < 1 || amount.length === 0}
          >
            Generate Payment Invoice
          </Button>
        </GenerateModalWrapper>
      </Modal>
      <div className="top-control ">
        <Button onClick={handleGenerateNew}>Generate New</Button>
      </div>

      <div className="print-wrapper">
        <FcPrint
          onClick={handlePrint}
          style={{ fontSize: "1.8rem", cursor: "pointer" }}
        />
      </div>
      <MainWrapper ref={invoicesRef}>
        <div className="payment-stats">
          <div className="payment-stat">
            <h4 className="stat-title">Total Amount</h4>
            <h2 className="stat-value">{formatToNaira(totalAmount)}</h2>
          </div>
          <div className="payment-stat">
            <h4 className="stat-title">Amount Paid</h4>
            <h2 className="stat-value">{formatToNaira(amountPaid)}</h2>
          </div>
          <div className="payment-stat">
            <h4 className="stat-title">Outstanding Amount</h4>
            <h2 className="stat-value">{formatToNaira(outstandingAmount)}</h2>
          </div>
        </div>
        <H2 className="generated-title">Generated Invoices</H2>
        <div className="generated-invoices">
          {generatedInvoices?.map((invoice: KeyValueObj) => (
            <InvoiceCard invoice={invoice} />
          ))}
        </div>
      </MainWrapper>
    </InvoiceListWrapper>
  );
}

import { MouseEvent, useState } from "react";

interface PositionType {
  cordinate: string;
  value: string;
}

interface CordinateType {
  yAxis: PositionType;
  xAxis: PositionType;
}

export function usePositioning() {
  const [cordinate, setCordinate] = useState<CordinateType>({
    yAxis: { cordinate: "top", value: "0" },
    xAxis: { cordinate: "left", value: "0" },
  });

  function setPosition(e: MouseEvent<HTMLElement>) {
    //set the vertical coordinate
    let horizontal: PositionType;
    let vertical: PositionType;

    if (e.clientY * 2 > window.innerHeight) {
      vertical = {
        cordinate: "bottom",
        value: (window.innerHeight - e.clientY + 20).toString(),
      };
    } else {
      vertical = {
        cordinate: "top",
        value: (e.clientY + 20).toString(),
      };
    }

    //settting the horizontal coordinate
    if (e.clientX * 2 > window.innerWidth) {
      horizontal = {
        cordinate: "right",
        value: (window.innerWidth - e.clientX - 40).toString(),
      };
    } else {
      horizontal = {
        cordinate: "left",
        value: (e.clientX - 40).toString(),
      };
    }

    setCordinate({
      yAxis: vertical,
      xAxis: horizontal,
    });
  }

  return [setPosition, cordinate] as const;
}

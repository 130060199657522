import { useState, SetStateAction, Dispatch } from "react";
import { Select } from "src/common/components/selectInput";
import { H3 } from "src/common/elements";
import { KeyValueObj } from "src/common/types";
import styled from "styled-components";
import { getDeliveryFormInputs } from "./data";
import Form from "src/common/components/form/Form";
import { AddressManager } from "src/common/components/addresses";

const PaymentTermWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg.prim_500};
  padding: 2rem 0;

  .payment-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
  }

  .terms-text {
    padding: 0 2rem;
  }

  .terms-inputs {
    display: flex;
    padding: 2rem;
    justify-content: space-between;
  }

  .address-wrapper {
    padding: 2rem;
  }
`;

export interface IPaymentTermsProps {
  paymentDeliveryInformation: KeyValueObj;
  setPaymentDeliveryInformation: Dispatch<SetStateAction<KeyValueObj>>;
  handlePaymentBack: () => void;
  handlePaymentContinue: () => void;
  paymentTermsList: KeyValueObj[];
}

export function PaymentTerms({
  setPaymentDeliveryInformation,
  handlePaymentContinue,
  handlePaymentBack,
  paymentTermsList,
}: IPaymentTermsProps) {
  const [formValues, setFormValues] = useState<KeyValueObj>({});
  const [selectedAddress, setSelectedAddress] = useState<KeyValueObj>({});

  function handleContinue(innerFormValues: KeyValueObj) {
    setPaymentDeliveryInformation({
      ...formValues,
      ...innerFormValues,
      shipping_address_id: selectedAddress?.id,
      ...selectedAddress,
    });
    handlePaymentContinue();
  }

  function updateData(name: string, value: any) {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  return (
    <PaymentTermWrapper>
      <div className="payment-section">
        <H3 className="terms-text">PAYMENT OTIONS</H3>
        <div className="terms-inputs">
          <Select
            options={paymentTermsList}
            placeholder={"Select payment term"}
            prompt={"Payment Term"}
            fullWidth={false}
            shortWidth={false}
            name={"payment_term"}
            indexLevel={0}
            formValues={formValues}
            setFormValues={setFormValues}
            updateData={updateData}
            returnId
          />
        </div>
      </div>
      <div className="payment-section">
        <H3 className="terms-text">DELIVERY DETAILS</H3>
        <div className="address-wrapper">
          <AddressManager
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
          />
        </div>

        <Form
          formInputs={getDeliveryFormInputs()}
          biControl
          submitLabel="Continue"
          handleBack={handlePaymentBack}
          processInputs={handleContinue}
        />
      </div>
    </PaymentTermWrapper>
  );
}

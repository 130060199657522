import styled from "styled-components";

export const PageContainr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 4rem;
  opacity: 0;
  animation: fadeIn 1s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ModalProps {
  show: boolean;
}

interface ModalWrapperProps {
  short?: boolean;
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  transition: all 0.3s ease-in;
  width: ${({ short }) => (short ? "45rem" : "55rem")};
  max-width: 92vw;
  min-height: 90vh;
  position: relative;
  padding-bottom: 3rem;
  overflow-x: hidden;
`;

export const ModalFormWrapper = styled.div<ModalProps>`
  width: 100%;
  border-radius: 0.25rem;
  padding: 3rem 2rem;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  z-index: ${({ show }) => (show ? "1" : "0")};
  max-height: ${({ show }) => (show ? "none" : "90vh")};
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  animation: ${({ show }) => (show ? "fadeIn .5s forwards ease-in " : "none")};

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.2rem;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
  }
  .form-error {
    width: 100%;
    gap: 2rem;

    .error-text {
      color: ${({ theme }) => theme.bg.secondary};
      padding: 2rem;
      border: 1px solid ${({ theme }) => theme.bg.secondary};
      border-radius: 0.25rem;
      font-weight: 500;
      font-size: 1.3rem;
      background-color: ${({ theme }) => theme.bg.secondary}1f;
    }
  }
`;

export const ModalPreviewWrapper = styled.div<ModalProps>`
  position: absolute;
  top: 0;
  z-index: ${({ show }) => (show ? "1" : "0")};
  max-width: 45rem;
  width: 45rem;
  padding: 0 3rem;
  border-radius: 0.25rem;
  opacity: 0;
  animation: ${({ show }) => (show ? "fadeIn .5s forwards ease-in " : "none")};

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    width: 100%;
    padding: 0;
    max-width: 100%;
    overflow: hidden;
  }
`;

export const ModalImageWrapper = styled.div<ModalProps>`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: ${({ show }) => (show ? "1" : "0")};
  max-width: 50rem;
  padding: 0 3rem;
  border-radius: 0.25rem;
  opacity: 0;
  animation: ${({ show }) => (show ? "fadeIn .5s forwards ease-in " : "none")};

  .text-prompt {
    margin-bottom: 2rem;
  }
  .image-inputs {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .add-more {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }

  .image-controls {
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;
  }
`;

interface PopupProps {
  position: {
    yAxis: {
      cordinate: string;
      value: string;
    };
    xAxis: { cordinate: string; value: string };
  };
  children: React.ReactNode;
}

const PopupContainer = styled.div<PopupProps>`
  background-color: ${({ theme }) => theme.bg.prim_500};
  box-shadow: ${({ theme }) => theme.shadow.shad_100};
  position: fixed;
  opacity: 0;
  ${({ position }) =>
    position.yAxis.cordinate +
    ":" +
    (parseInt(position.yAxis.value) + 5) +
    "px"};
  ${({ position }) =>
    position.xAxis.cordinate + ":" + position.xAxis.value + "px"};
  display: flex;
  flex-direction: column;
  z-index: 99999;
  gap: 0.1rem;
  padding: 0;
  animation: fadeInPopup forwards 0.4s ease-in-out;

  @keyframes fadeInPopup {
    to {
      ${({ position }) =>
        position.yAxis.cordinate + ":" + position.yAxis.value + "px"};
      opacity: 1;
    }
  }
`;

export function PopupWrapper({ position, children }: PopupProps) {
  return <PopupContainer position={position}>{children}</PopupContainer>;
}

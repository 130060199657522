import Table from "src/common/components/Table";
import { H3 } from "src/common/elements";
import styled from "styled-components";
import {
  addedProductHeaders,
  addedProductKeys,
  getAddedProductTableBody,
  getLogisticViewDetails,
  getRecipentViewDetails,
} from "./data";
import { KeyValueObj } from "src/common/types";
import { KeyValue } from "src/common/components/key-value";
import { Button } from "src/common/components/button";
import { formatToNaira } from "src/common/utils/general";

const DeliveryWrapper = styled.div`
  width: 65rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.2rem;
  }

  .double-column-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .separate {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.bg.prim_300};
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .control {
    padding: 2rem;
  }

  .amount {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.text.secondary};
  }
`;

export interface IDeliveryRequestPreviewProps {
  deliveryData: KeyValueObj;
  handleAcceptDeliveryFee: () => void;
  accepting: boolean;
}

export function DeliveryRequestView({
  deliveryData,
  handleAcceptDeliveryFee,
  accepting,
}: IDeliveryRequestPreviewProps) {
  return (
    <DeliveryWrapper>
      <H3>Product Details</H3>
      <Table
        tableHeaders={addedProductHeaders}
        tableData={getAddedProductTableBody(deliveryData?.delivery_items)}
        tableKeys={addedProductKeys}
        rowLineColor="light"
        removeFilters
      />

      <div className="separate"></div>

      <H3>Recipent Company Details</H3>
      <div className="double-column-wrapper">
        {getRecipentViewDetails(deliveryData?.delivery_request?.recipient)?.map(
          (detail: KeyValueObj, i: number) => (
            <KeyValue key={i} title={detail?.title} value={detail?.value} />
          )
        )}
      </div>

      <div className="separate"></div>

      <H3>Logistic Company Details</H3>
      <div className="double-column-wrapper">
        {getLogisticViewDetails(deliveryData?.delivery_request?.company)?.map(
          (detail: KeyValueObj, i: number) => (
            <KeyValue key={i} title={detail?.title} value={detail?.value} />
          )
        )}
      </div>

      <div className="separate"></div>

      <H3>Delivery Information</H3>
      <div className="double-column-wrapper">
        <KeyValue
          title={"Pickup Type"}
          value={deliveryData?.delivery_request?.pickup_option}
        />
        <KeyValue
          title={"Pickup Address"}
          value={deliveryData?.delivery_request?.pick_up_address}
        />
        <KeyValue
          title={"Drop off Address"}
          value={deliveryData?.delivery_request?.drop_off_address}
        />
        <KeyValue
          title={"Vehicle Needed"}
          value={deliveryData?.delivery_request?.vehicle_needed}
        />
        <KeyValue
          title={"Transaction ID"}
          value={deliveryData?.delivery_request?.transaction_id}
        />
        <KeyValue
          title={"Delivery Request Status"}
          value={deliveryData?.delivery_request?.request_status}
        />
        <KeyValue
          title={"Delivery Status"}
          value={deliveryData?.delivery?.delivery_status ?? "--"}
        />
      </div>

      <div className="separate"></div>

      <H3>Deliery Fees Information</H3>
      <div className="double-column-wrapper">
        <KeyValue
          title={"Total Amount"}
          value={
            <h2 className="amount">
              {formatToNaira(deliveryData?.delivery?.total_amount ?? "0")}
            </h2>
          }
        />
      </div>

      <div className="separate"></div>

      <div className="control flex-col">
        {deliveryData?.delivery_request?.request_status?.toLowerCase() ===
          "accepted" &&
        deliveryData?.delivery?.delivery_status?.toLowerCase() === "pending" ? (
          <Button loading={accepting} onClick={handleAcceptDeliveryFee}>
            Accept Delivery Charge
          </Button>
        ) : null}
      </div>
    </DeliveryWrapper>
  );
}

import Table from "src/common/components/Table";
import { DISTRIBUTORS_URL } from "src/common/constants";
import useQuery from "src/hooks/useQuery";
import styled from "styled-components";
import {
  distributorFilterOptions,
  distributorTableHeaders,
  distributorTableKeys,
} from "./data";
import { getDistributorsTableData } from "./data";
import { KeyValueObj } from "src/common/types";
import { SetStateAction, useState, Dispatch, useEffect } from "react";
import { Button } from "src/common/components/button";
import BasicSelect from "src/common/components/Input/input-components/selects";
import { Pagination } from "src/common/components/pagination";

const DistributorsWrapper = styled.div`
  width: 52rem;
  padding: 0 0 2rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  max-width: 100%;

  .select-wrapper {
    padding-top: 2rem;
  }

  .add-controls {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2.5rem;
  }

  .distributors-wrapper {
    margin-top: 2rem;
    width: 100%;
    padding: 2rem;

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 2rem 0.5rem;
    }
  }

  .distributor-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 3rem 1.5rem 2rem;
  }
`;

const distributorOptions = [
  {
    title: "All Distributors",
    value: "all",
  },
  {
    title: "My Favourite Distributors",
    value: "linked",
  },
  {
    title: "Select from list",
    value: "select",
  },
];
export interface IDistributorsProps {
  addDistributor: (distributors: KeyValueObj[]) => void;
  addedDistributors: KeyValueObj[];
  removeDistributor: (distributor: KeyValueObj) => void;
  distributorsCategory: string;
  setDistributorsCategory: Dispatch<SetStateAction<string>>;
  handleDistributorsContinue: () => void;
  handleDistributorsBack: () => void;
}
export function Distributors({
  addDistributor,
  addedDistributors,
  removeDistributor,
  distributorsCategory,
  setDistributorsCategory,
  handleDistributorsContinue,
  handleDistributorsBack,
}: IDistributorsProps) {
  const [selectedList, setSelectedList] = useState<KeyValueObj[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [scope, setScope] = useState("");
  const [state, setState] = useState("");

  const {
    data: distributorsData,
    loading: distributorLoading,
    meta: distributorMeta,
    refetch: refetchDistributors,
  } = useQuery(DISTRIBUTORS_URL);

  useEffect(() => {
    refetchDistributors(
      DISTRIBUTORS_URL +
        `?scope=${scope}&state=${state}&page=${page}$per_page=${perPage}`
    );
    // eslint-disable-next-line
  }, [state, scope, perPage, page]);

  function handleDistributorsFilter(values: KeyValueObj) {
    setScope(values?.favorites === "yes" ? "favourites" : "");
    setState(values?.state || "");
  }

  return (
    <DistributorsWrapper>
      <div className="select-wrapper">
        <BasicSelect
          label={"Select Distributor Category"}
          options={distributorOptions}
          selectedValue={distributorsCategory}
          setSelectedValue={setDistributorsCategory}
          minWidth="20rem"
        />
      </div>
      {distributorsCategory === "select" ? (
        <>
          <div className="distributors-wrapper">
            <Table
              full
              withCheck
              loading={distributorLoading}
              tableHeaders={distributorTableHeaders}
              tableData={getDistributorsTableData(distributorsData)}
              tableKeys={distributorTableKeys}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              filterInputs={distributorFilterOptions}
              handleFilter={handleDistributorsFilter}
              rowLineColor="light"
            />
            <Pagination
              pageCount={distributorMeta?.total_pages}
              onNextPage={(event) => setPage(event?.selected)}
              onPerPageChange={(event) => setPerPage(event?.target?.value)}
            />
            <div className="add-controls">
              <Button onClick={() => addDistributor(selectedList)} secondary>
                Add Distributor
              </Button>
            </div>
          </div>

          <div className="distributors-wrapper">
            <Table
              tableHeaders={distributorTableHeaders}
              tableData={addedDistributors}
              tableKeys={distributorTableKeys}
              tableTitle="Added Distributors"
              rowLineColor="light"
              showDropDown={true}
              full
              dropDownOptions={[{ label: "Remove", action: removeDistributor }]}
            />
          </div>
        </>
      ) : null}
      <div className="distributor-controls">
        <Button secondary onClick={handleDistributorsBack}>
          Back
        </Button>
        <Button onClick={handleDistributorsContinue}>Continue</Button>
      </div>
    </DistributorsWrapper>
  );
}

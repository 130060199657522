export const ACTcodes = [
  { id: "A", title: "ALIMENTARY TRACT AND METABOLISM" },
  { id: "A01", title: "STOMATOLOGICAL PREPARATIONS" },
  { id: "A01A", title: "STOMATOLOGICAL PREPARATIONS" },
  { id: "A01AA", title: "Caries prophylactic agents" },
  { id: "A01AA01", title: "sodium fluoride" },
  { id: "A01AA02", title: "sodium monofluorophosphate" },
  { id: "A01AA03", title: "olaflur" },
  { id: "A01AA04", title: "stannous fluoride" },
  { id: "A01AA30", title: "combinations" },
  { id: "A01AA51", title: "sodium fluoride, combinations" },
  {
    id: "A01AB",
    title: "Antiinfectives and antiseptics for local oral treatment",
  },
  { id: "A01AB02", title: "hydrogen peroxide" },
  { id: "A01AB03", title: "chlorhexidine" },
  { id: "A01AB04", title: "amphotericin B" },
  { id: "A01AB05", title: "polynoxylin" },
  { id: "A01AB06", title: "domiphen" },
  { id: "A01AB07", title: "oxyquinoline" },
  { id: "A01AB08", title: "neomycin" },
  { id: "A01AB09", title: "miconazole" },
  { id: "A01AB10", title: "natamycin" },
  { id: "A01AB11", title: "various" },
  { id: "A01AB12", title: "hexetidine" },
  { id: "A01AB13", title: "tetracycline" },
  { id: "A01AB14", title: "benzoxonium chloride" },
  { id: "A01AB15", title: "tibezonium iodide" },
  { id: "A01AB16", title: "mepartricin" },
  { id: "A01AB17", title: "metronidazole" },
  { id: "A01AB18", title: "clotrimazole" },
  { id: "A01AB19", title: "sodium perborate" },
  { id: "A01AB21", title: "chlortetracycline" },
  { id: "A01AB22", title: "doxycycline" },
  { id: "A01AB23", title: "minocycline" },
  { id: "A01AC", title: "Corticosteroids for local oral treatment" },
  { id: "A01AC01", title: "triamcinolone" },
  { id: "A01AC02", title: "dexamethasone" },
  { id: "A01AC03", title: "hydrocortisone" },
  { id: "A01AC54", title: "prednisolone, combinations" },
  { id: "A01AD", title: "Other agents for local oral treatment" },
  { id: "A01AD01", title: "epinephrine" },
  { id: "A01AD02", title: "benzydamine" },
  { id: "A01AD05", title: "acetylsalicylic acid" },
  { id: "A01AD06", title: "adrenalone" },
  { id: "A01AD07", title: "amlexanox" },
  { id: "A01AD08", title: "becaplermin" },
  { id: "A01AD11", title: "various" },
  { id: "A02", title: "DRUGS FOR ACID RELATED DISORDERS" },
  { id: "A02A", title: "ANTACIDS" },
  { id: "A02AA", title: "Magnesium compounds" },
  { id: "A02AA01", title: "magnesium carbonate" },
  { id: "A02AA02", title: "magnesium oxide" },
  { id: "A02AA03", title: "magnesium peroxide" },
  { id: "A02AA04", title: "magnesium hydroxide" },
  { id: "A02AA05", title: "magnesium silicate" },
  { id: "A02AA10", title: "combinations" },
  { id: "A02AB", title: "Aluminium compounds" },
  { id: "A02AB01", title: "aluminium hydroxide" },
  { id: "A02AB02", title: "algeldrate" },
  { id: "A02AB03", title: "aluminium phosphate" },
  { id: "A02AB04", title: "dihydroxialumini sodium carbonate" },
  { id: "A02AB05", title: "aluminium acetoacetate" },
  { id: "A02AB06", title: "aloglutamol" },
  { id: "A02AB07", title: "aluminium glycinate" },
  { id: "A02AB10", title: "combinations" },
  { id: "A02AC", title: "Calcium compounds" },
  { id: "A02AC01", title: "calcium carbonate" },
  { id: "A02AC02", title: "calcium silicate" },
  { id: "A02AC10", title: "combinations" },
  {
    id: "A02AD",
    title:
      "Combinations and complexes of aluminium, calcium and magnesium compounds",
  },
  { id: "A02AD01", title: "ordinary salt combinations" },
  { id: "A02AD02", title: "magaldrate" },
  { id: "A02AD03", title: "almagate" },
  { id: "A02AD04", title: "hydrotalcite" },
  { id: "A02AD05", title: "almasilate" },
  { id: "A02AF", title: "Antacids with antiflatulents" },
  { id: "A02AF01", title: "magaldrate and antiflatulents" },
  { id: "A02AF02", title: "ordinary salt combinations and antiflatulents" },
  { id: "A02AG", title: "Antacids with antispasmodics" },
  { id: "A02AH", title: "Antacids with sodium bicarbonate" },
  { id: "A02AX", title: "Antacids, other combinations" },
  {
    id: "A02B",
    title:
      "DRUGS FOR PEPTIC ULCER AND GASTRO-OESOPHAGEAL REFLUX DISEASE (GORD)",
  },
  { id: "A02BB", title: "Prostaglandins" },
  { id: "A02BB01", title: "misoprostol" },
  { id: "A02BB02", title: "enprostil" },
  { id: "A02BC", title: "Proton pump inhibitors" },
  { id: "A02BC01", title: "omeprazole" },
  { id: "A02BC02", title: "pantoprazole" },
  { id: "A02BC03", title: "lansoprazole" },
  { id: "A02BC04", title: "rabeprazole" },
  { id: "A02BC05", title: "esomeprazole" },
  { id: "A02BC06", title: "dexlansoprazole" },
  {
    id: "A02BD",
    title: "Combinations for eradication of Helicobacter pylori",
  },
  { id: "A02BD01", title: "omeprazole, amoxicillin and metronidazole" },
  { id: "A02BD02", title: "lansoprazole, tetracycline and metronidazole" },
  { id: "A02BD03", title: "lansoprazole, amoxicillin and metronidazole" },
  { id: "A02BD04", title: "pantoprazole, amoxicillin and clarithromycin" },
  { id: "A02BD05", title: "omeprazole, amoxicillin and clarithromycin" },
  { id: "A02BD06", title: "esomeprazole, amoxicillin and clarithromycin" },
  { id: "A02BD07", title: "lansoprazole, amoxicillin and clarithromycin" },
  {
    id: "A02BD08",
    title: "bismuth subcitrate, tetracycline and metronidazole",
  },
  {
    id: "A02BX",
    title:
      "Other drugs for peptic ulcer and gastro-oesophageal reflux disease (GORD)",
  },
  { id: "A02BX01", title: "carbenoxolone" },
  { id: "A02BX02", title: "sucralfate" },
  { id: "A02BX03", title: "pirenzepine" },
  { id: "A02BX04", title: "methiosulfonium chloride" },
  { id: "A02BX05", title: "bismuth subcitrate" },
  { id: "A02BX06", title: "proglumide" },
  { id: "A02BX07", title: "gefarnate" },
  { id: "A02BX08", title: "sulglicotide" },
  { id: "A02BX09", title: "acetoxolone" },
  { id: "A02BX10", title: "zolimidine" },
  { id: "A02BX11", title: "troxipide" },
  { id: "A02BX12", title: "bismuth subnitrate" },
  { id: "A02BX13", title: "alginic acid" },
  {
    id: "A02BX51",
    title: "carbenoxolone, combinations excl. psycholeptics",
  },
  {
    id: "A02BX71",
    title: "carbenoxolone, combinations with psycholeptics",
  },
  { id: "A02BX77", title: "gefarnate, combinations with psycholeptics" },
  { id: "A02X", title: "OTHER DRUGS FOR ACID RELATED DISORDERS" },
  { id: "A03", title: "DRUGS FOR FUNCTIONAL GASTROINTESTINAL DISORDERS" },
  { id: "A03A", title: "DRUGS FOR FUNCTIONAL GASTROINTESTINAL DISORDERS" },
  {
    id: "A03AA",
    title: "Synthetic anticholinergics, esters with tertiary amino group",
  },
  { id: "A03AA01", title: "oxyphencyclimine" },
  { id: "A03AA03", title: "camylofin" },
  { id: "A03AA04", title: "mebeverine" },
  { id: "A03AA05", title: "trimebutine" },
  { id: "A03AA06", title: "rociverine" },
  { id: "A03AA07", title: "dicycloverine" },
  { id: "A03AA08", title: "dihexyverine" },
  { id: "A03AA09", title: "difemerine" },
  { id: "A03AA30", title: "piperidolate" },
  {
    id: "A03AB",
    title: "Synthetic anticholinergics, quaternary ammonium compounds",
  },
  { id: "A03AB01", title: "benzilone" },
  { id: "A03AB02", title: "glycopyrronium bromide" },
  { id: "A03AB03", title: "oxyphenonium" },
  { id: "A03AB04", title: "penthienate" },
  { id: "A03AB05", title: "propantheline" },
  { id: "A03AB06", title: "otilonium bromide" },
  { id: "A03AB07", title: "methantheline" },
  { id: "A03AB08", title: "tridihexethyl" },
  { id: "A03AB09", title: "isopropamide" },
  { id: "A03AB10", title: "hexocyclium" },
  { id: "A03AB11", title: "poldine" },
  { id: "A03AB12", title: "mepenzolate" },
  { id: "A03AB13", title: "bevonium" },
  { id: "A03AB14", title: "pipenzolate" },
  { id: "A03AB15", title: "diphemanil" },
  {
    id: "A03AB16",
    title: "(2-benzhydryloxyethyl)diethyl-methylammonium iodide",
  },
  { id: "A03AB17", title: "tiemonium iodide" },
  { id: "A03AB18", title: "prifinium bromide" },
  { id: "A03AB19", title: "timepidium bromide" },
  { id: "A03AB21", title: "fenpiverinium" },
  { id: "A03AB53", title: "oxyphenonium, combinations" },
  {
    id: "A03AC",
    title: "Synthetic antispasmodics, amides with tertiary amines",
  },
  { id: "A03AC02", title: "dimethylaminopropionylphenothiazine" },
  { id: "A03AC04", title: "nicofetamide" },
  { id: "A03AC05", title: "tiropramide" },
  { id: "A03AD", title: "Papaverine and derivatives" },
  { id: "A03AD01", title: "papaverine" },
  { id: "A03AD02", title: "drotaverine" },
  { id: "A03AD30", title: "moxaverine" },
  { id: "A03AE", title: "Serotonin receptor antagonists" },
  { id: "A03AE01", title: "alosetron" },
  { id: "A03AE03", title: "cilansetron" },
  {
    id: "A03AX",
    title: "Other drugs for functional gastrointestinal disorders",
  },
  { id: "A03AX01", title: "fenpiprane" },
  { id: "A03AX02", title: "diisopromine" },
  { id: "A03AX03", title: "chlorbenzoxamine" },
  { id: "A03AX04", title: "pinaverium" },
  { id: "A03AX05", title: "fenoverine" },
  { id: "A03AX06", title: "idanpramine" },
  { id: "A03AX07", title: "proxazole" },
  { id: "A03AX08", title: "alverine" },
  { id: "A03AX09", title: "trepibutone" },
  { id: "A03AX10", title: "isometheptene" },
  { id: "A03AX11", title: "caroverine" },
  { id: "A03AX12", title: "phloroglucinol" },
  { id: "A03AX13", title: "silicones" },
  { id: "A03AX30", title: "trimethyldiphenylpropylamine" },
  { id: "A03AX58", title: "alverine, combinations" },
  { id: "A03B", title: "BELLADONNA AND DERIVATIVES, PLAIN" },
  { id: "A03BA", title: "Belladonna alkaloids, tertiary amines" },
  { id: "A03BA01", title: "atropine" },
  { id: "A03BA03", title: "hyoscyamine" },
  { id: "A03BA04", title: "belladonna total alkaloids" },
  {
    id: "A03BB",
    title: "Belladonna alkaloids, semisynthetic, quaternary ammonium compounds",
  },
  { id: "A03BB01", title: "butylscopolamine" },
  { id: "A03BB02", title: "methylatropine" },
  { id: "A03BB03", title: "methylscopolamine" },
  { id: "A03BB04", title: "fentonium" },
  { id: "A03BB05", title: "cimetropium bromide" },
  { id: "A03C", title: "ANTISPASMODICS IN COMBINATION WITH PSYCHOLEPTICS" },
  {
    id: "A03CA",
    title: "Synthetic anticholinergic agents in combination with psycholeptics",
  },
  { id: "A03CA01", title: "isopropamide and psycholeptics" },
  { id: "A03CA02", title: "clidinium and psycholeptics" },
  { id: "A03CA03", title: "oxyphencyclimine and psycholeptics" },
  { id: "A03CA04", title: "otilonium bromide and psycholeptics" },
  { id: "A03CA05", title: "glycopyrronium bromide and psycholeptics" },
  { id: "A03CA06", title: "bevonium and psycholeptics" },
  { id: "A03CA07", title: "ambutonium and psycholeptics" },
  { id: "A03CA08", title: "diphemanil and psycholeptics" },
  { id: "A03CA30", title: "emepronium and psycholeptics" },
  { id: "A03CA34", title: "propantheline and psycholeptics" },
  {
    id: "A03CB",
    title: "Belladonna and derivatives in combination with psycholeptics",
  },
  { id: "A03CB01", title: "methylscopolamine and psycholeptics" },
  { id: "A03CB02", title: "belladonna total alkaloids and psycholeptics" },
  { id: "A03CB03", title: "atropine and psycholeptics" },
  { id: "A03CB04", title: "methylhomatropine and psycholeptics" },
  { id: "A03CB31", title: "hyoscyamine and psycholeptics" },
  {
    id: "A03CC",
    title: "Other antispasmodics in combination with psycholeptics",
  },
  { id: "A03D", title: "ANTISPASMODICS IN COMBINATION WITH ANALGESICS" },
  {
    id: "A03DA",
    title: "Synthetic anticholinergic agents in combination with analgesics",
  },
  { id: "A03DA01", title: "tropenzilone and analgesics" },
  { id: "A03DA02", title: "pitofenone and analgesics" },
  { id: "A03DA03", title: "bevonium and analgesics" },
  { id: "A03DA04", title: "ciclonium and analgesics" },
  { id: "A03DA05", title: "camylofin and analgesics" },
  { id: "A03DA06", title: "trospium and analgesics" },
  { id: "A03DA07", title: "tiemonium iodide and analgesics" },
  {
    id: "A03DB",
    title: "Belladonna and derivatives in combination with analgesics",
  },
  { id: "A03DB04", title: "butylscopolamine and analgesics" },
  {
    id: "A03DC",
    title: "Other antispasmodics in combination with analgesics",
  },
  {
    id: "A03E",
    title:
      "ANTISPASMODICS AND ANTICHOLINERGICS IN COMBINATION WITH OTHER DRUGS",
  },
  {
    id: "A03EA",
    title: "Antispasmodics, psycholeptics and analgesics in combination",
  },
  { id: "A03ED", title: "Antispasmodics in combination with other drugs" },
  { id: "A03F", title: "PROPULSIVES" },
  { id: "A03FA", title: "Propulsives" },
  { id: "A03FA01", title: "metoclopramide" },
  { id: "A03FA02", title: "cisapride" },
  { id: "A03FA03", title: "domperidone" },
  { id: "A03FA04", title: "bromopride" },
  { id: "A03FA05", title: "alizapride" },
  { id: "A03FA06", title: "clebopride" },
  { id: "A04", title: "ANTIEMETICS AND ANTINAUSEANTS" },
  { id: "A04A", title: "ANTIEMETICS AND ANTINAUSEANTS" },
  { id: "A04AA", title: "Serotonin (5HT3) antagonists" },
  { id: "A04AA01", title: "ondansetron" },
  { id: "A04AA02", title: "granisetron" },
  { id: "A04AA03", title: "tropisetron" },
  { id: "A04AA04", title: "dolasetron" },
  { id: "A04AA05", title: "palonosetron" },
  { id: "A04AD", title: "Other antiemetics" },
  { id: "A04AD01", title: "scopolamine" },
  { id: "A04AD02", title: "cerium oxalate" },
  { id: "A04AD04", title: "chlorobutanol" },
  { id: "A04AD05", title: "metopimazine" },
  { id: "A04AD10", title: "dronabinol" },
  { id: "A04AD11", title: "nabilone" },
  { id: "A04AD12", title: "aprepitant" },
  { id: "A04AD13", title: "casopitant" },
  { id: "A04AD51", title: "scopolamine, combinations" },
  { id: "A04AD54", title: "chlorobutanol, combinations" },
  { id: "A05", title: "BILE AND LIVER THERAPY" },
  { id: "A05A", title: "BILE THERAPY" },
  { id: "A05AA", title: "Bile acid preparations" },
  { id: "A05AA01", title: "chenodeoxycholic acid" },
  { id: "A05AA02", title: "ursodeoxycholic acid" },
  { id: "A05AA03", title: "cholic acid" },
  { id: "A05AB", title: "Preparations for biliary tract therapy" },
  { id: "A05AB01", title: "nicotinyl methylamide" },
  { id: "A05AX", title: "Other drugs for bile therapy" },
  { id: "A05AX01", title: "piprozolin" },
  { id: "A05AX02", title: "hymecromone" },
  { id: "A05AX03", title: "cyclobutyrol" },
  { id: "A05B", title: "LIVER THERAPY, LIPOTROPICS" },
  { id: "A05BA", title: "Liver therapy" },
  { id: "A05BA01", title: "arginine glutamate" },
  { id: "A05BA03", title: "silymarin" },
  { id: "A05BA04", title: "citiolone" },
  { id: "A05BA05", title: "epomediol" },
  { id: "A05BA06", title: "ornithine oxoglurate" },
  { id: "A05BA07", title: "tidiacic arginine" },
  { id: "A05BA08", title: "glycyrrhizic acid" },
  {
    id: "A05C",
    title: "DRUGS FOR BILE THERAPY AND LIPOTROPICS IN COMBINATION",
  },
  { id: "A06", title: "DRUGS FOR CONSTIPATION" },
  { id: "A06A", title: "DRUGS FOR CONSTIPATION" },
  { id: "A06AA", title: "Softeners, emollients" },
  { id: "A06AA01", title: "liquid paraffin" },
  { id: "A06AA02", title: "docusate sodium" },
  { id: "A06AA51", title: "liquid paraffin, combinations" },
  { id: "A06AB", title: "Contact laxatives" },
  { id: "A06AB01", title: "oxyphenisatine" },
  { id: "A06AB02", title: "bisacodyl" },
  { id: "A06AB03", title: "dantron" },
  { id: "A06AB04", title: "phenolphthalein" },
  { id: "A06AB05", title: "castor oil" },
  { id: "A06AB06", title: "senna glycosides" },
  { id: "A06AB07", title: "cascara" },
  { id: "A06AB08", title: "sodium picosulfate" },
  { id: "A06AB09", title: "bisoxatin" },
  { id: "A06AB20", title: "contact laxatives in combination" },
  {
    id: "A06AB30",
    title: "contact laxatives in combination with belladonna alkaloids",
  },
  { id: "A06AB52", title: "bisacodyl, combinations" },
  { id: "A06AB53", title: "dantron, combinations" },
  { id: "A06AB56", title: "senna glycosides, combinations" },
  { id: "A06AB57", title: "cascara, combinations" },
  { id: "A06AB58", title: "sodium picosulfate, combinations" },
  { id: "A06AC", title: "Bulk-forming laxatives" },
  { id: "A06AC01", title: "ispaghula (psylla seeds)" },
  { id: "A06AC02", title: "ethulose" },
  { id: "A06AC03", title: "sterculia" },
  { id: "A06AC05", title: "linseed" },
  { id: "A06AC06", title: "methylcellulose" },
  { id: "A06AC07", title: "triticum (wheat fibre)" },
  { id: "A06AC08", title: "polycarbophil calcium" },
  { id: "A06AC51", title: "ispaghula, combinations" },
  { id: "A06AC53", title: "sterculia, combinations" },
  { id: "A06AC55", title: "linseed, combinations" },
  { id: "A06AD", title: "Osmotically acting laxatives" },
  { id: "A06AD01", title: "magnesium carbonate" },
  { id: "A06AD02", title: "magnesium oxide" },
  { id: "A06AD03", title: "magnesium peroxide" },
  { id: "A06AD04", title: "magnesium sulfate" },
  { id: "A06AD10", title: "mineral salts in combination" },
  { id: "A06AD11", title: "lactulose" },
  { id: "A06AD12", title: "lactitol" },
  { id: "A06AD13", title: "sodium sulfate" },
  { id: "A06AD14", title: "pentaerithrityl" },
  { id: "A06AD15", title: "macrogol" },
  { id: "A06AD16", title: "mannitol" },
  { id: "A06AD17", title: "sodium phosphate" },
  { id: "A06AD18", title: "sorbitol" },
  { id: "A06AD19", title: "magnesium citrate" },
  { id: "A06AD21", title: "sodium tartrate" },
  { id: "A06AD61", title: "lactulose, combinations" },
  { id: "A06AD65", title: "macrogol, combinations" },
  { id: "A06AG", title: "Enemas" },
  { id: "A06AG01", title: "sodium phosphate" },
  { id: "A06AG02", title: "bisacodyl" },
  { id: "A06AG03", title: "dantron, incl. combinations" },
  { id: "A06AG04", title: "glycerol" },
  { id: "A06AG06", title: "oil" },
  { id: "A06AG07", title: "sorbitol" },
  { id: "A06AG10", title: "docusate sodium, incl. combinations" },
  { id: "A06AG11", title: "laurilsulfate, incl. combinations" },
  { id: "A06AG20", title: "combinations" },
  { id: "A06AH", title: "Peripheral opioid receptor antagonists" },
  { id: "A06AH01", title: "methylnaltrexone bromide" },
  { id: "A06AH02", title: "alvimopan" },
  { id: "A06AH03", title: "naloxegol" },
  { id: "A06AX", title: "Other drugs for constipation" },
  { id: "A06AX01", title: "glycerol" },
  { id: "A06AX02", title: "carbon dioxide producing drugs" },
  { id: "A06AX03", title: "lubiprostone" },
  { id: "A06AX04", title: "linaclotide" },
  { id: "A06AX05", title: "prucalopride" },
  { id: "A06AX06", title: "tegaserod" },
  {
    id: "A07",
    title: "ANTIDIARRHEALS, INTESTINAL ANTIINFLAMMATORY/ANTIINFECTIVE AGENTS",
  },
  { id: "A07A", title: "INTESTINAL ANTIINFECTIVES" },
  { id: "A07AA", title: "Antibiotics" },
  { id: "A07AA01", title: "neomycin" },
  { id: "A07AA02", title: "nystatin" },
  { id: "A07AA03", title: "natamycin" },
  { id: "A07AA04", title: "streptomycin" },
  { id: "A07AA05", title: "polymyxin B" },
  { id: "A07AA06", title: "paromomycin" },
  { id: "A07AA07", title: "amphotericin B" },
  { id: "A07AA08", title: "kanamycin" },
  { id: "A07AA09", title: "vancomycin" },
  { id: "A07AA10", title: "colistin" },
  { id: "A07AA11", title: "rifaximin" },
  { id: "A07AA12", title: "fidaxomicin" },
  { id: "A07AA51", title: "neomycin, combinations" },
  { id: "A07AA54", title: "streptomycin, combinations" },
  { id: "A07AB", title: "Sulfonamides" },
  { id: "A07AB02", title: "phthalylsulfathiazole" },
  { id: "A07AB03", title: "sulfaguanidine" },
  { id: "A07AB04", title: "succinylsulfathiazole" },
  { id: "A07AC", title: "Imidazole derivatives" },
  { id: "A07AC01", title: "miconazole" },
  { id: "A07AX", title: "Other intestinal antiinfectives" },
  { id: "A07AX01", title: "broxyquinoline" },
  { id: "A07AX02", title: "acetarsol" },
  { id: "A07AX03", title: "nifuroxazide" },
  { id: "A07AX04", title: "nifurzide" },
  { id: "A07B", title: "INTESTINAL ADSORBENTS" },
  { id: "A07BA", title: "Charcoal preparations" },
  { id: "A07BA01", title: "medicinal charcoal" },
  { id: "A07BA51", title: "medicinal charcoal, combinations" },
  { id: "A07BB", title: "Bismuth preparations" },
  { id: "A07BC", title: "Other intestinal adsorbents" },
  { id: "A07BC01", title: "pectin" },
  { id: "A07BC02", title: "kaolin" },
  { id: "A07BC03", title: "crospovidone" },
  { id: "A07BC04", title: "attapulgite" },
  { id: "A07BC05", title: "diosmectite" },
  { id: "A07BC30", title: "combinations" },
  { id: "A07BC54", title: "attapulgite, combinations" },
  { id: "A07C", title: "ELECTROLYTES WITH CARBOHYDRATES" },
  { id: "A07CA", title: "Oral rehydration salt formulations" },
  { id: "A07D", title: "ANTIPROPULSIVES" },
  { id: "A07DA", title: "Antipropulsives" },
  { id: "A07DA01", title: "diphenoxylate" },
  { id: "A07DA02", title: "opium" },
  { id: "A07DA03", title: "loperamide" },
  { id: "A07DA04", title: "difenoxin" },
  { id: "A07DA05", title: "loperamide oxide" },
  { id: "A07DA52", title: "morphine, combinations" },
  { id: "A07DA53", title: "loperamide, combinations" },
  { id: "A07E", title: "INTESTINAL ANTIINFLAMMATORY AGENTS" },
  { id: "A07EA", title: "Corticosteroids acting locally" },
  { id: "A07EA01", title: "prednisolone" },
  { id: "A07EA02", title: "hydrocortisone" },
  { id: "A07EA03", title: "prednisone" },
  { id: "A07EA04", title: "betamethasone" },
  { id: "A07EA05", title: "tixocortol" },
  { id: "A07EA06", title: "budesonide" },
  { id: "A07EA07", title: "beclometasone" },
  { id: "A07EB", title: "Antiallergic agents, excl. corticosteroids" },
  { id: "A07EB01", title: "cromoglicic acid" },
  { id: "A07EC", title: "Aminosalicylic acid and similar agents" },
  { id: "A07EC01", title: "sulfasalazine" },
  { id: "A07EC02", title: "mesalazine" },
  { id: "A07EC03", title: "olsalazine" },
  { id: "A07EC04", title: "balsalazide" },
  { id: "A07F", title: "ANTIDIARRHEAL MICROORGANISMS" },
  { id: "A07FA", title: "Antidiarrheal microorganisms" },
  { id: "A07FA01", title: "lactic acid producing organisms" },
  { id: "A07FA02", title: "saccharomyces boulardii" },
  { id: "A07FA51", title: "lactic acid producing organisms, combinations" },
  { id: "A07X", title: "OTHER ANTIDIARRHEALS" },
  { id: "A07XA", title: "Other antidiarrheals" },
  { id: "A07XA01", title: "albumin tannate" },
  { id: "A07XA02", title: "ceratonia" },
  { id: "A07XA03", title: "calcium compounds" },
  { id: "A07XA04", title: "racecadotril" },
  { id: "A07XA51", title: "albumin tannate, combinations" },
  { id: "A08", title: "ANTIOBESITY PREPARATIONS, EXCL. DIET PRODUCTS" },
  { id: "A08A", title: "ANTIOBESITY PREPARATIONS, EXCL. DIET PRODUCTS" },
  { id: "A08AA", title: "Centrally acting antiobesity products" },
  { id: "A08AA01", title: "phentermine" },
  { id: "A08AA02", title: "fenfluramine" },
  { id: "A08AA03", title: "amfepramone" },
  { id: "A08AA04", title: "dexfenfluramine" },
  { id: "A08AA05", title: "mazindol" },
  { id: "A08AA06", title: "etilamfetamine" },
  { id: "A08AA07", title: "cathine" },
  { id: "A08AA08", title: "clobenzorex" },
  { id: "A08AA09", title: "mefenorex" },
  { id: "A08AA10", title: "sibutramine" },
  { id: "A08AA11", title: "lorcaserin" },
  { id: "A08AA56", title: "ephedrine, combinations" },
  { id: "A08AB", title: "Peripherally acting antiobesity products" },
  { id: "A08AB01", title: "orlistat" },
  { id: "A08AX", title: "Other antiobesity drugs" },
  { id: "A08AX01", title: "rimonabant" },
  { id: "A09", title: "DIGESTIVES, INCL. ENZYMES" },
  { id: "A09A", title: "DIGESTIVES, INCL. ENZYMES" },
  { id: "A09AA", title: "Enzyme preparations" },
  { id: "A09AA01", title: "diastase" },
  { id: "A09AA02", title: "multienzymes (lipase, protease etc.)" },
  { id: "A09AA03", title: "pepsin" },
  { id: "A09AA04", title: "tilactase" },
  { id: "A09AB", title: "Acid preparations" },
  { id: "A09AB01", title: "glutamic acid hydrochloride" },
  { id: "A09AB02", title: "betaine hydrochloride" },
  { id: "A09AB03", title: "hydrochloric acid" },
  { id: "A09AB04", title: "citric acid" },
  { id: "A09AC", title: "Enzyme and acid preparations, combinations" },
  { id: "A09AC01", title: "pepsin and acid preparations" },
  { id: "A09AC02", title: "multienzymes and acid preparations" },
  { id: "A10", title: "DRUGS USED IN DIABETES" },
  { id: "A10A", title: "INSULINS AND ANALOGUES" },
  {
    id: "A10AB",
    title: "Insulins and analogues for injection, fast-acting",
  },
  { id: "A10AB01", title: "insulin (human)" },
  { id: "A10AB02", title: "insulin (beef)" },
  { id: "A10AB03", title: "insulin (pork)" },
  { id: "A10AB04", title: "insulin lispro" },
  { id: "A10AB05", title: "insulin aspart" },
  { id: "A10AB06", title: "insulin glulisine" },
  { id: "A10AB30", title: "combinations" },
  {
    id: "A10AC",
    title: "Insulins and analogues for injection, intermediate-acting",
  },
  { id: "A10AC01", title: "insulin (human)" },
  { id: "A10AC02", title: "insulin (beef)" },
  { id: "A10AC03", title: "insulin (pork)" },
  { id: "A10AC04", title: "insulin lispro" },
  { id: "A10AC30", title: "combinations" },
  {
    id: "A10AD",
    title:
      "Insulins and analogues for injection, intermediate- or long-acting combined with fast-acting",
  },
  { id: "A10AD01", title: "insulin (human)" },
  { id: "A10AD02", title: "insulin (beef)" },
  { id: "A10AD03", title: "insulin (pork)" },
  { id: "A10AD04", title: "insulin lispro" },
  { id: "A10AD05", title: "insulin aspart" },
  { id: "A10AD06", title: "insulin degludec and insulin aspart" },
  { id: "A10AD30", title: "combinations" },
  {
    id: "A10AE",
    title: "Insulins and analogues for injection, long-acting",
  },
  { id: "A10AE01", title: "insulin (human)" },
  { id: "A10AE02", title: "insulin (beef)" },
  { id: "A10AE03", title: "insulin (pork)" },
  { id: "A10AE04", title: "insulin glargine" },
  { id: "A10AE05", title: "insulin detemir" },
  { id: "A10AE06", title: "insulin degludec" },
  { id: "A10AE30", title: "combinations" },
  { id: "A10AF", title: "Insulins and analogues for inhalation" },
  { id: "A10AF01", title: "insulin (human)" },
  { id: "A10B", title: "BLOOD GLUCOSE LOWERING DRUGS, EXCL. INSULINS" },
  { id: "A10BA", title: "Biguanides" },
  { id: "A10BA01", title: "phenformin" },
  { id: "A10BA02", title: "metformin" },
  { id: "A10BA03", title: "buformin" },
  { id: "A10BB", title: "Sulfonamides, urea derivatives" },
  { id: "A10BB01", title: "glibenclamide" },
  { id: "A10BB02", title: "chlorpropamide" },
  { id: "A10BB03", title: "tolbutamide" },
  { id: "A10BB04", title: "glibornuride" },
  { id: "A10BB05", title: "tolazamide" },
  { id: "A10BB06", title: "carbutamide" },
  { id: "A10BB07", title: "glipizide" },
  { id: "A10BB08", title: "gliquidone" },
  { id: "A10BB09", title: "gliclazide" },
  { id: "A10BB10", title: "metahexamide" },
  { id: "A10BB11", title: "glisoxepide" },
  { id: "A10BB12", title: "glimepiride" },
  { id: "A10BB31", title: "acetohexamide" },
  { id: "A10BC", title: "Sulfonamides (heterocyclic)" },
  { id: "A10BC01", title: "glymidine" },
  {
    id: "A10BD",
    title: "Combinations of oral blood glucose lowering drugs",
  },
  { id: "A10BD01", title: "phenformin and sulfonamides" },
  { id: "A10BD02", title: "metformin and sulfonamides" },
  { id: "A10BD03", title: "metformin and rosiglitazone" },
  { id: "A10BD04", title: "glimepiride and rosiglitazone" },
  { id: "A10BD05", title: "metformin and pioglitazone" },
  { id: "A10BD06", title: "glimepiride and pioglitazone" },
  { id: "A10BD07", title: "metformin and sitagliptin" },
  { id: "A10BD08", title: "metformin and vildagliptin" },
  { id: "A10BD09", title: "pioglitazone and alogliptin" },
  { id: "A10BD10", title: "metformin and saxagliptin" },
  { id: "A10BD11", title: "metformin and linagliptin" },
  { id: "A10BD12", title: "pioglitazone and sitagliptin" },
  { id: "A10BD13", title: "metformin and alogliptin" },
  { id: "A10BD14", title: "metformin and repaglinide" },
  { id: "A10BD15", title: "metformin and dapagliflozin" },
  { id: "A10BF", title: "Alpha glucosidase inhibitors" },
  { id: "A10BF01", title: "acarbose" },
  { id: "A10BF02", title: "miglitol" },
  { id: "A10BF03", title: "voglibose" },
  { id: "A10BG", title: "Thiazolidinediones" },
  { id: "A10BG01", title: "troglitazone" },
  { id: "A10BG02", title: "rosiglitazone" },
  { id: "A10BG03", title: "pioglitazone" },
  { id: "A10BH", title: "Dipeptidyl peptidase 4 (DPP-4) inhibitors" },
  { id: "A10BH01", title: "sitagliptin" },
  { id: "A10BH02", title: "vildagliptin" },
  { id: "A10BH03", title: "saxagliptin" },
  { id: "A10BH04", title: "alogliptin" },
  { id: "A10BH05", title: "linagliptin" },
  { id: "A10BH06", title: "gemigliptin" },
  { id: "A10BH51", title: "sitagliptin and simvastatin" },
  {
    id: "A10BX",
    title: "Other blood glucose lowering drugs, excl. insulins",
  },
  { id: "A10BX01", title: "guar gum" },
  { id: "A10BX02", title: "repaglinide" },
  { id: "A10BX03", title: "nateglinide" },
  { id: "A10BX04", title: "exenatide" },
  { id: "A10BX05", title: "pramlintide" },
  { id: "A10BX06", title: "benfluorex" },
  { id: "A10BX07", title: "liraglutide" },
  { id: "A10BX08", title: "mitiglinide" },
  { id: "A10BX09", title: "dapagliflozin" },
  { id: "A10BX10", title: "lixisenatide" },
  { id: "A10BX11", title: "canagliflozin" },
  { id: "A10BX12", title: "empagliflozin" },
  { id: "A10X", title: "OTHER DRUGS USED IN DIABETES" },
  { id: "A10XA", title: "Aldose reductase inhibitors" },
  { id: "A10XA01", title: "tolrestat" },
  { id: "A11", title: "VITAMINS" },
  { id: "A11A", title: "MULTIVITAMINS, COMBINATIONS" },
  { id: "A11AA", title: "Multivitamins with minerals" },
  { id: "A11AA01", title: "multivitamins and iron" },
  { id: "A11AA02", title: "multivitamins and calcium" },
  {
    id: "A11AA03",
    title: "multivitamins and other minerals, incl. combinations",
  },
  { id: "A11AA04", title: "multivitamins and trace elements" },
  { id: "A11AB", title: "Multivitamins, other combinations" },
  { id: "A11B", title: "MULTIVITAMINS, PLAIN" },
  { id: "A11BA", title: "Multivitamins, plain" },
  { id: "A11C", title: "VITAMIN A AND D, INCL. COMBINATIONS OF THE TWO" },
  { id: "A11CA", title: "Vitamin A, plain" },
  { id: "A11CA01", title: "retinol (vit A)" },
  { id: "A11CA02", title: "betacarotene" },
  { id: "A11CB", title: "Vitamin A and D in combination" },
  { id: "A11CC", title: "Vitamin D and analogues" },
  { id: "A11CC01", title: "ergocalciferol" },
  { id: "A11CC02", title: "dihydrotachysterol" },
  { id: "A11CC03", title: "alfacalcidol" },
  { id: "A11CC04", title: "calcitriol" },
  { id: "A11CC05", title: "colecalciferol" },
  { id: "A11CC06", title: "calcifediol" },
  { id: "A11CC20", title: "combinations" },
  {
    id: "A11D",
    title: "VITAMIN B1, PLAIN AND IN COMBINATION WITH VITAMIN B6 AND B12",
  },
  { id: "A11DA", title: "Vitamin B1, plain" },
  { id: "A11DA01", title: "thiamine (vit B1)" },
  { id: "A11DA02", title: "sulbutiamine" },
  { id: "A11DA03", title: "benfotiamine" },
  {
    id: "A11DB",
    title: "Vitamin B1 in combination with vitamin B6 and/or vitamin B12",
  },
  { id: "A11E", title: "VITAMIN B-COMPLEX, INCL. COMBINATIONS" },
  { id: "A11EA", title: "Vitamin B-complex, plain" },
  { id: "A11EB", title: "Vitamin B-complex with vitamin C" },
  { id: "A11EC", title: "Vitamin B-complex with minerals" },
  { id: "A11ED", title: "Vitamin B-complex with anabolic steroids" },
  { id: "A11EX", title: "Vitamin B-complex, other combinations" },
  { id: "A11G", title: "ASCORBIC ACID (VITAMIN C), INCL. COMBINATIONS" },
  { id: "A11GA", title: "Ascorbic acid (vitamin C), plain" },
  { id: "A11GA01", title: "ascorbic acid (vit C)" },
  { id: "A11GB", title: "Ascorbic acid (vitamin C), combinations" },
  { id: "A11GB01", title: "ascorbic acid (vit C) and calcium" },
  { id: "A11H", title: "OTHER PLAIN VITAMIN PREPARATIONS" },
  { id: "A11HA", title: "Other plain vitamin preparations" },
  { id: "A11HA01", title: "nicotinamide" },
  { id: "A11HA02", title: "pyridoxine (vit B6)" },
  { id: "A11HA03", title: "tocopherol (vit E)" },
  { id: "A11HA04", title: "riboflavin (vit B2)" },
  { id: "A11HA05", title: "biotin" },
  { id: "A11HA06", title: "pyridoxal phosphate" },
  { id: "A11HA07", title: "inositol" },
  { id: "A11HA08", title: "tocofersolan" },
  { id: "A11HA30", title: "dexpanthenol" },
  { id: "A11HA31", title: "calcium pantothenate" },
  { id: "A11HA32", title: "pantethine" },
  { id: "A11J", title: "OTHER VITAMIN PRODUCTS, COMBINATIONS" },
  { id: "A11JA", title: "Combinations of vitamins" },
  { id: "A11JB", title: "Vitamins with minerals" },
  { id: "A11JC", title: "Vitamins, other combinations" },
  { id: "A12", title: "MINERAL SUPPLEMENTS" },
  { id: "A12A", title: "CALCIUM" },
  { id: "A12AA", title: "Calcium" },
  { id: "A12AA01", title: "calcium phosphate" },
  { id: "A12AA02", title: "calcium glubionate" },
  { id: "A12AA03", title: "calcium gluconate" },
  { id: "A12AA04", title: "calcium carbonate" },
  { id: "A12AA05", title: "calcium lactate" },
  { id: "A12AA06", title: "calcium lactate gluconate" },
  { id: "A12AA07", title: "calcium chloride" },
  { id: "A12AA08", title: "calcium glycerylphosphate" },
  { id: "A12AA09", title: "calcium citrate lysine complex" },
  { id: "A12AA10", title: "calcium glucoheptonate" },
  { id: "A12AA11", title: "calcium pangamate" },
  { id: "A12AA12", title: "calcium acetate anhydrous" },
  { id: "A12AA13", title: "calcium citrate" },
  { id: "A12AA20", title: "calcium (different salts in combination)" },
  { id: "A12AA30", title: "calcium laevulate" },
  {
    id: "A12AX",
    title: "Calcium, combinations with vitamin D and/or other drugs",
  },
  { id: "A12B", title: "POTASSIUM" },
  { id: "A12BA", title: "Potassium" },
  { id: "A12BA01", title: "potassium chloride" },
  { id: "A12BA02", title: "potassium citrate" },
  { id: "A12BA03", title: "potassium hydrogentartrate" },
  { id: "A12BA04", title: "potassium hydrogencarbonate" },
  { id: "A12BA05", title: "potassium gluconate" },
  { id: "A12BA30", title: "combinations" },
  { id: "A12BA51", title: "potassium chloride, combinations" },
  { id: "A12C", title: "OTHER MINERAL SUPPLEMENTS" },
  { id: "A12CA", title: "Sodium" },
  { id: "A12CA01", title: "sodium chloride" },
  { id: "A12CA02", title: "sodium sulfate" },
  { id: "A12CB", title: "Zinc" },
  { id: "A12CB01", title: "zinc sulfate" },
  { id: "A12CB02", title: "zinc gluconate" },
  { id: "A12CB03", title: "zinc protein complex" },
  { id: "A12CC", title: "Magnesium" },
  { id: "A12CC01", title: "magnesium chloride" },
  { id: "A12CC02", title: "magnesium sulfate" },
  { id: "A12CC03", title: "magnesium gluconate" },
  { id: "A12CC04", title: "magnesium citrate" },
  { id: "A12CC05", title: "magnesium aspartate" },
  { id: "A12CC06", title: "magnesium lactate" },
  { id: "A12CC07", title: "magnesium levulinate" },
  { id: "A12CC08", title: "magnesium pidolate" },
  { id: "A12CC09", title: "magnesium orotate" },
  { id: "A12CC10", title: "magnesium oxide" },
  { id: "A12CC30", title: "magnesium (different salts in combination)" },
  { id: "A12CD", title: "Fluoride" },
  { id: "A12CD01", title: "sodium fluoride" },
  { id: "A12CD02", title: "sodium monofluorophosphate" },
  { id: "A12CD51", title: "fluoride, combinations" },
  { id: "A12CE", title: "Selenium" },
  { id: "A12CE01", title: "sodium selenate" },
  { id: "A12CE02", title: "sodium selenite" },
  { id: "A12CX", title: "Other mineral products" },
  { id: "A13", title: "TONICS" },
  { id: "A13A", title: "TONICS" },
  { id: "A14", title: "ANABOLIC AGENTS FOR SYSTEMIC USE" },
  { id: "A14A", title: "ANABOLIC STEROIDS" },
  { id: "A14AA", title: "Androstan derivatives" },
  { id: "A14AA01", title: "androstanolone" },
  { id: "A14AA02", title: "stanozolol" },
  { id: "A14AA03", title: "metandienone" },
  { id: "A14AA04", title: "metenolone" },
  { id: "A14AA05", title: "oxymetholone" },
  { id: "A14AA06", title: "quinbolone" },
  { id: "A14AA07", title: "prasterone" },
  { id: "A14AA08", title: "oxandrolone" },
  { id: "A14AA09", title: "norethandrolone" },
  { id: "A14AB", title: "Estren derivatives" },
  { id: "A14AB01", title: "nandrolone" },
  { id: "A14AB02", title: "ethylestrenol" },
  { id: "A14AB03", title: "oxabolone cipionate" },
  { id: "A14B", title: "OTHER ANABOLIC AGENTS" },
  { id: "A15", title: "APPETITE STIMULANTS" },
  { id: "A16", title: "OTHER ALIMENTARY TRACT AND METABOLISM PRODUCTS" },
  { id: "A16A", title: "OTHER ALIMENTARY TRACT AND METABOLISM PRODUCTS" },
  { id: "A16AA", title: "Amino acids and derivatives" },
  { id: "A16AA01", title: "levocarnitine" },
  { id: "A16AA02", title: "ademetionine" },
  { id: "A16AA03", title: "glutamine" },
  { id: "A16AA04", title: "mercaptamine" },
  { id: "A16AA05", title: "carglumic acid" },
  { id: "A16AA06", title: "betaine" },
  { id: "A16AB", title: "Enzymes" },
  { id: "A16AB01", title: "alglucerase" },
  { id: "A16AB02", title: "imiglucerase" },
  { id: "A16AB03", title: "agalsidase alfa" },
  { id: "A16AB04", title: "agalsidase beta" },
  { id: "A16AB05", title: "laronidase" },
  { id: "A16AB06", title: "sacrosidase" },
  { id: "A16AB07", title: "alglucosidase alfa" },
  { id: "A16AB08", title: "galsulfase" },
  { id: "A16AB09", title: "idursulfase" },
  { id: "A16AB10", title: "velaglucerase alfa" },
  { id: "A16AB11", title: "taliglucerase alfa" },
  {
    id: "A16AX",
    title: "Various alimentary tract and metabolism products",
  },
  { id: "A16AX01", title: "thioctic acid" },
  { id: "A16AX02", title: "anethole trithione" },
  { id: "A16AX03", title: "sodium phenylbutyrate" },
  { id: "A16AX04", title: "nitisinone" },
  { id: "A16AX05", title: "zinc acetate" },
  { id: "A16AX06", title: "miglustat" },
  { id: "A16AX07", title: "sapropterin" },
  { id: "A16AX08", title: "teduglutide" },
  { id: "A16AX09", title: "glycerol phenylbutyrate" },
  { id: "A16AX10", title: "eliglustat" },
  { id: "B", title: "BLOOD AND BLOOD FORMING ORGANS" },
  { id: "B01", title: "ANTITHROMBOTIC AGENTS" },
  { id: "B01A", title: "ANTITHROMBOTIC AGENTS" },
  { id: "B01AA", title: "Vitamin K antagonists" },
  { id: "B01AA01", title: "dicoumarol" },
  { id: "B01AA02", title: "phenindione" },
  { id: "B01AA03", title: "warfarin" },
  { id: "B01AA04", title: "phenprocoumon" },
  { id: "B01AA07", title: "acenocoumarol" },
  { id: "B01AA08", title: "ethyl biscoumacetate" },
  { id: "B01AA09", title: "clorindione" },
  { id: "B01AA10", title: "diphenadione" },
  { id: "B01AA11", title: "tioclomarol" },
  { id: "B01AA12", title: "fluindione" },
  { id: "B01AB", title: "Heparin group" },
  { id: "B01AB01", title: "heparin" },
  { id: "B01AB02", title: "antithrombin III" },
  { id: "B01AB04", title: "dalteparin" },
  { id: "B01AB05", title: "enoxaparin" },
  { id: "B01AB06", title: "nadroparin" },
  { id: "B01AB07", title: "parnaparin" },
  { id: "B01AB08", title: "reviparin" },
  { id: "B01AB09", title: "danaparoid" },
  { id: "B01AB10", title: "tinzaparin" },
  { id: "B01AB11", title: "sulodexide" },
  { id: "B01AB12", title: "bemiparin" },
  { id: "B01AB51", title: "heparin, combinations" },
  { id: "B01AC", title: "Platelet aggregation inhibitors excl. heparin" },
  { id: "B01AC01", title: "ditazole" },
  { id: "B01AC02", title: "cloricromen" },
  { id: "B01AC03", title: "picotamide" },
  { id: "B01AC04", title: "clopidogrel" },
  { id: "B01AC05", title: "ticlopidine" },
  { id: "B01AC06", title: "acetylsalicylic acid" },
  { id: "B01AC07", title: "dipyridamole" },
  { id: "B01AC08", title: "carbasalate calcium" },
  { id: "B01AC09", title: "epoprostenol" },
  { id: "B01AC10", title: "indobufen" },
  { id: "B01AC11", title: "iloprost" },
  { id: "B01AC13", title: "abciximab" },
  { id: "B01AC15", title: "aloxiprin" },
  { id: "B01AC16", title: "eptifibatide" },
  { id: "B01AC17", title: "tirofiban" },
  { id: "B01AC18", title: "triflusal" },
  { id: "B01AC19", title: "beraprost" },
  { id: "B01AC21", title: "treprostinil" },
  { id: "B01AC22", title: "prasugrel" },
  { id: "B01AC23", title: "cilostazol" },
  { id: "B01AC24", title: "ticagrelor" },
  { id: "B01AC30", title: "combinations" },
  { id: "B01AC56", title: "acetylsalicylic acid and esomeprazole" },
  { id: "B01AD", title: "Enzymes" },
  { id: "B01AD01", title: "streptokinase" },
  { id: "B01AD02", title: "alteplase" },
  { id: "B01AD03", title: "anistreplase" },
  { id: "B01AD04", title: "urokinase" },
  { id: "B01AD05", title: "fibrinolysin" },
  { id: "B01AD06", title: "brinase" },
  { id: "B01AD07", title: "reteplase" },
  { id: "B01AD08", title: "saruplase" },
  { id: "B01AD09", title: "ancrod" },
  { id: "B01AD10", title: "drotrecogin alfa (activated)" },
  { id: "B01AD11", title: "tenecteplase" },
  { id: "B01AD12", title: "protein C" },
  { id: "B01AE", title: "Direct thrombin inhibitors" },
  { id: "B01AE01", title: "desirudin" },
  { id: "B01AE02", title: "lepirudin" },
  { id: "B01AE03", title: "argatroban" },
  { id: "B01AE04", title: "melagatran" },
  { id: "B01AE05", title: "ximelagatran" },
  { id: "B01AE06", title: "bivalirudin" },
  { id: "B01AE07", title: "dabigatran etexilate" },
  { id: "B01AF", title: "Direct factor Xa inhibitors" },
  { id: "B01AF01", title: "rivaroxaban" },
  { id: "B01AF02", title: "apixaban" },
  { id: "B01AX", title: "Other antithrombotic agents" },
  { id: "B01AX01", title: "defibrotide" },
  { id: "B01AX04", title: "dermatan sulfate" },
  { id: "B01AX05", title: "fondaparinux" },
  { id: "B02", title: "ANTIHEMORRHAGICS" },
  { id: "B02A", title: "ANTIFIBRINOLYTICS" },
  { id: "B02AA", title: "Amino acids" },
  { id: "B02AA01", title: "aminocaproic acid" },
  { id: "B02AA02", title: "tranexamic acid" },
  { id: "B02AA03", title: "aminomethylbenzoic acid" },
  { id: "B02AB", title: "Proteinase inhibitors" },
  { id: "B02AB01", title: "aprotinin" },
  { id: "B02AB02", title: "alfa1 antitrypsin" },
  { id: "B02AB04", title: "camostat" },
  { id: "B02B", title: "VITAMIN K AND OTHER HEMOSTATICS" },
  { id: "B02BA", title: "Vitamin K" },
  { id: "B02BA01", title: "phytomenadione" },
  { id: "B02BA02", title: "menadione" },
  { id: "B02BB", title: "Fibrinogen" },
  { id: "B02BB01", title: "fibrinogen, human" },
  { id: "B02BC", title: "Local hemostatics" },
  { id: "B02BC01", title: "absorbable gelatin sponge" },
  { id: "B02BC02", title: "oxidized cellulose" },
  { id: "B02BC03", title: "tetragalacturonic acid hydroxymethylester" },
  { id: "B02BC05", title: "adrenalone" },
  { id: "B02BC06", title: "thrombin" },
  { id: "B02BC07", title: "collagen" },
  { id: "B02BC08", title: "calcium alginate" },
  { id: "B02BC09", title: "epinephrine" },
  { id: "B02BC30", title: "combinations" },
  { id: "B02BD", title: "Blood coagulation factors" },
  {
    id: "B02BD01",
    title: "coagulation factor IX, II, VII and X in combination",
  },
  { id: "B02BD02", title: "coagulation factor VIII" },
  { id: "B02BD03", title: "factor VIII inhibitor bypassing activity" },
  { id: "B02BD04", title: "coagulation factor IX" },
  { id: "B02BD05", title: "coagulation factor VII" },
  {
    id: "B02BD06",
    title: "von Willebrand factor and coagulation factor VIII in combination",
  },
  { id: "B02BD07", title: "coagulation factor XIII" },
  { id: "B02BD08", title: "eptacog alfa (activated)" },
  { id: "B02BD09", title: "nonacog alfa" },
  { id: "B02BD10", title: "von Willebrand factor" },
  { id: "B02BD11", title: "catridecacog" },
  { id: "B02BD12", title: "trenonacog alfa" },
  { id: "B02BD30", title: "thrombin" },
  { id: "B02BX", title: "Other systemic hemostatics" },
  { id: "B02BX01", title: "etamsylate" },
  { id: "B02BX02", title: "carbazochrome" },
  { id: "B02BX03", title: "batroxobin" },
  { id: "B02BX04", title: "romiplostim" },
  { id: "B02BX05", title: "eltrombopag" },
  { id: "B03", title: "ANTIANEMIC PREPARATIONS" },
  { id: "B03A", title: "IRON PREPARATIONS" },
  { id: "B03AA", title: "Iron bivalent, oral preparations" },
  { id: "B03AA01", title: "ferrous glycine sulfate" },
  { id: "B03AA02", title: "ferrous fumarate" },
  { id: "B03AA03", title: "ferrous gluconate" },
  { id: "B03AA04", title: "ferrous carbonate" },
  { id: "B03AA05", title: "ferrous chloride" },
  { id: "B03AA06", title: "ferrous succinate" },
  { id: "B03AA07", title: "ferrous sulfate" },
  { id: "B03AA08", title: "ferrous tartrate" },
  { id: "B03AA09", title: "ferrous aspartate" },
  { id: "B03AA10", title: "ferrous ascorbate" },
  { id: "B03AA11", title: "ferrous iodine" },
  { id: "B03AB", title: "Iron trivalent, oral preparations" },
  { id: "B03AB01", title: "ferric sodium citrate" },
  { id: "B03AB02", title: "saccharated iron oxide" },
  { id: "B03AB03", title: "sodium feredetate" },
  { id: "B03AB04", title: "ferric hydroxide" },
  { id: "B03AB05", title: "ferric oxide polymaltose complexes" },
  { id: "B03AB06", title: "ferric citrate" },
  { id: "B03AB07", title: "chondroitin sulfate-iron complex" },
  { id: "B03AB08", title: "ferric acetyl transferrin" },
  { id: "B03AB09", title: "ferric proteinsuccinylate" },
  { id: "B03AC", title: "Iron, parenteral preparations" },
  { id: "B03AD", title: "Iron in combination with folic acid" },
  { id: "B03AD01", title: "ferrous amino acid complex" },
  { id: "B03AD02", title: "ferrous fumarate" },
  { id: "B03AD03", title: "ferrous sulfate" },
  { id: "B03AD04", title: "ferric oxide polymaltose complexes" },
  { id: "B03AE", title: "Iron in other combinations" },
  { id: "B03AE01", title: "iron, vitamin B12 and folic acid" },
  { id: "B03AE02", title: "iron, multivitamins and folic acid" },
  { id: "B03AE03", title: "iron and multivitamins" },
  { id: "B03AE04", title: "iron, multivitamins and minerals" },
  { id: "B03AE10", title: "various combinations" },
  { id: "B03B", title: "VITAMIN B12 AND FOLIC ACID" },
  { id: "B03BA", title: "Vitamin B12 (cyanocobalamin and analogues)" },
  { id: "B03BA01", title: "cyanocobalamin" },
  { id: "B03BA02", title: "cyanocobalamin tannin complex" },
  { id: "B03BA03", title: "hydroxocobalamin" },
  { id: "B03BA04", title: "cobamamide" },
  { id: "B03BA05", title: "mecobalamin" },
  { id: "B03BA51", title: "cyanocobalamin, combinations" },
  { id: "B03BA53", title: "hydroxocobalamin, combinations" },
  { id: "B03BB", title: "Folic acid and derivatives" },
  { id: "B03BB01", title: "folic acid" },
  { id: "B03BB51", title: "folic acid, combinations" },
  { id: "B03X", title: "OTHER ANTIANEMIC PREPARATIONS" },
  { id: "B03XA", title: "Other antianemic preparations" },
  { id: "B03XA01", title: "erythropoietin" },
  { id: "B03XA02", title: "darbepoetin alfa" },
  { id: "B03XA03", title: "methoxy polyethylene glycol-epoetin beta" },
  { id: "B03XA04", title: "peginesatide" },
  { id: "B05", title: "BLOOD SUBSTITUTES AND PERFUSION SOLUTIONS" },
  { id: "B05A", title: "BLOOD AND RELATED PRODUCTS" },
  { id: "B05AA", title: "Blood substitutes and plasma protein fractions" },
  { id: "B05AA01", title: "albumin" },
  { id: "B05AA02", title: "other plasma protein fractions" },
  { id: "B05AA03", title: "fluorocarbon blood substitutes" },
  { id: "B05AA05", title: "dextran" },
  { id: "B05AA06", title: "gelatin agents" },
  { id: "B05AA07", title: "hydroxyethylstarch" },
  { id: "B05AA08", title: "hemoglobin crosfumaril" },
  { id: "B05AA09", title: "hemoglobin raffimer" },
  { id: "B05AA10", title: "hemoglobin glutamer (bovine)" },
  { id: "B05AX", title: "Other blood products" },
  { id: "B05AX01", title: "erythrocytes" },
  { id: "B05AX02", title: "thrombocytes" },
  { id: "B05AX03", title: "blood plasma" },
  { id: "B05AX04", title: "stem cells from umbilical cord blood" },
  { id: "B05B", title: "I.V. SOLUTIONS" },
  { id: "B05BA", title: "Solutions for parenteral nutrition" },
  { id: "B05BA01", title: "amino acids" },
  { id: "B05BA02", title: "fat emulsions" },
  { id: "B05BA03", title: "carbohydrates" },
  { id: "B05BA04", title: "protein hydrolysates" },
  { id: "B05BA10", title: "combinations" },
  { id: "B05BB", title: "Solutions affecting the electrolyte balance" },
  { id: "B05BB01", title: "electrolytes" },
  { id: "B05BB02", title: "electrolytes with carbohydrates" },
  { id: "B05BB03", title: "trometamol" },
  { id: "B05BB04", title: "electrolytes in combination with other drugs" },
  { id: "B05BC", title: "Solutions producing osmotic diuresis" },
  { id: "B05BC01", title: "mannitol" },
  { id: "B05BC02", title: "carbamide" },
  { id: "B05C", title: "IRRIGATING SOLUTIONS" },
  { id: "B05CA", title: "Antiinfectives" },
  { id: "B05CA01", title: "cetylpyridinium" },
  { id: "B05CA02", title: "chlorhexidine" },
  { id: "B05CA03", title: "nitrofural" },
  { id: "B05CA04", title: "sulfamethizole" },
  { id: "B05CA05", title: "taurolidine" },
  { id: "B05CA06", title: "mandelic acid" },
  { id: "B05CA07", title: "noxytiolin" },
  { id: "B05CA08", title: "ethacridine lactate" },
  { id: "B05CA09", title: "neomycin" },
  { id: "B05CA10", title: "combinations" },
  { id: "B05CB", title: "Salt solutions" },
  { id: "B05CB01", title: "sodium chloride" },
  { id: "B05CB02", title: "sodium citrate" },
  { id: "B05CB03", title: "magnesium citrate" },
  { id: "B05CB04", title: "sodium bicarbonate" },
  { id: "B05CB10", title: "combinations" },
  { id: "B05CX", title: "Other irrigating solutions" },
  { id: "B05CX01", title: "glucose" },
  { id: "B05CX02", title: "sorbitol" },
  { id: "B05CX03", title: "glycine" },
  { id: "B05CX04", title: "mannitol" },
  { id: "B05CX10", title: "combinations" },
  { id: "B05D", title: "PERITONEAL DIALYTICS" },
  { id: "B05DA", title: "Isotonic solutions" },
  { id: "B05DB", title: "Hypertonic solutions" },
  { id: "B05X", title: "I.V. SOLUTION ADDITIVES" },
  { id: "B05XA", title: "Electrolyte solutions" },
  { id: "B05XA01", title: "potassium chloride" },
  { id: "B05XA02", title: "sodium bicarbonate" },
  { id: "B05XA03", title: "sodium chloride" },
  { id: "B05XA04", title: "ammonium chloride" },
  { id: "B05XA05", title: "magnesium sulfate" },
  {
    id: "B05XA06",
    title: "potassium phosphate, incl. combinations with other potassium salts",
  },
  { id: "B05XA07", title: "calcium chloride" },
  { id: "B05XA08", title: "sodium acetate" },
  { id: "B05XA09", title: "sodium phosphate" },
  { id: "B05XA10", title: "magnesium phosphate" },
  { id: "B05XA11", title: "magnesium chloride" },
  { id: "B05XA12", title: "zinc chloride" },
  { id: "B05XA13", title: "hydrochloric acid" },
  { id: "B05XA14", title: "sodium glycerophosphate" },
  { id: "B05XA15", title: "potassium lactate" },
  { id: "B05XA16", title: "cardioplegia solutions" },
  { id: "B05XA17", title: "potassium acetate" },
  { id: "B05XA30", title: "combinations of electrolytes" },
  { id: "B05XA31", title: "electrolytes in combination with other drugs" },
  { id: "B05XB", title: "Amino acids" },
  { id: "B05XB01", title: "arginine hydrochloride" },
  { id: "B05XB02", title: "alanyl glutamine" },
  { id: "B05XB03", title: "lysine" },
  { id: "B05XC", title: "Vitamins" },
  { id: "B05XX", title: "Other i.v. solution additives" },
  { id: "B05XX02", title: "trometamol" },
  { id: "B05Z", title: "HEMODIALYTICS AND HEMOFILTRATES" },
  { id: "B05ZA", title: "Hemodialytics, concentrates" },
  { id: "B05ZB", title: "Hemofiltrates" },
  { id: "B06", title: "OTHER HEMATOLOGICAL AGENTS" },
  { id: "B06A", title: "OTHER HEMATOLOGICAL AGENTS" },
  { id: "B06AA", title: "Enzymes" },
  { id: "B06AA02", title: "fibrinolysin and desoxyribonuclease" },
  { id: "B06AA03", title: "hyaluronidase" },
  { id: "B06AA04", title: "chymotrypsin" },
  { id: "B06AA07", title: "trypsin" },
  { id: "B06AA10", title: "desoxyribonuclease" },
  { id: "B06AA55", title: "streptokinase, combinations" },
  { id: "B06AB", title: "Other hem products" },
  { id: "B06AB01", title: "hematin" },
  { id: "B06AC", title: "Drugs used in hereditary angioedema" },
  { id: "B06AC01", title: "c1-inhibitor, plasma derived" },
  { id: "B06AC02", title: "icatibant" },
  { id: "B06AC03", title: "ecallantide" },
  { id: "B06AC04", title: "conestat alfa" },
  { id: "C", title: "CARDIOVASCULAR SYSTEM" },
  { id: "C01", title: "CARDIAC THERAPY" },
  { id: "C01A", title: "CARDIAC GLYCOSIDES" },
  { id: "C01AA", title: "Digitalis glycosides" },
  { id: "C01AA01", title: "acetyldigitoxin" },
  { id: "C01AA02", title: "acetyldigoxin" },
  { id: "C01AA03", title: "digitalis leaves" },
  { id: "C01AA04", title: "digitoxin" },
  { id: "C01AA05", title: "digoxin" },
  { id: "C01AA06", title: "lanatoside C" },
  { id: "C01AA07", title: "deslanoside" },
  { id: "C01AA08", title: "metildigoxin" },
  { id: "C01AA09", title: "gitoformate" },
  { id: "C01AA52", title: "acetyldigoxin, combinations" },
  { id: "C01AB", title: "Scilla glycosides" },
  { id: "C01AB01", title: "proscillaridin" },
  { id: "C01AB51", title: "proscillaridin, combinations" },
  { id: "C01AC", title: "Strophantus glycosides" },
  { id: "C01AC01", title: "g-strophanthin" },
  { id: "C01AC03", title: "cymarin" },
  { id: "C01AX", title: "Other cardiac glycosides" },
  { id: "C01AX02", title: "peruvoside" },
  { id: "C01B", title: "ANTIARRHYTHMICS, CLASS I AND III" },
  { id: "C01BA", title: "Antiarrhythmics, class Ia" },
  { id: "C01BA01", title: "quinidine" },
  { id: "C01BA02", title: "procainamide" },
  { id: "C01BA03", title: "disopyramide" },
  { id: "C01BA04", title: "sparteine" },
  { id: "C01BA05", title: "ajmaline" },
  { id: "C01BA08", title: "prajmaline" },
  { id: "C01BA12", title: "lorajmine" },
  { id: "C01BA51", title: "quinidine, combinations excl. psycholeptics" },
  { id: "C01BA71", title: "quinidine, combinations with psycholeptics" },
  { id: "C01BB", title: "Antiarrhythmics, class Ib" },
  { id: "C01BB01", title: "lidocaine" },
  { id: "C01BB02", title: "mexiletine" },
  { id: "C01BB03", title: "tocainide" },
  { id: "C01BB04", title: "aprindine" },
  { id: "C01BC", title: "Antiarrhythmics, class Ic" },
  { id: "C01BC03", title: "propafenone" },
  { id: "C01BC04", title: "flecainide" },
  { id: "C01BC07", title: "lorcainide" },
  { id: "C01BC08", title: "encainide" },
  { id: "C01BC09", title: "ethacizine" },
  { id: "C01BD", title: "Antiarrhythmics, class III" },
  { id: "C01BD01", title: "amiodarone" },
  { id: "C01BD02", title: "bretylium tosilate" },
  { id: "C01BD03", title: "bunaftine" },
  { id: "C01BD04", title: "dofetilide" },
  { id: "C01BD05", title: "ibutilide" },
  { id: "C01BD06", title: "tedisamil" },
  { id: "C01BD07", title: "dronedarone" },
  { id: "C01BG", title: "Other antiarrhythmics, class I and III" },
  { id: "C01BG01", title: "moracizine" },
  { id: "C01BG07", title: "cibenzoline" },
  { id: "C01BG11", title: "vernakalant" },
  { id: "C01C", title: "CARDIAC STIMULANTS EXCL. CARDIAC GLYCOSIDES" },
  { id: "C01CA", title: "Adrenergic and dopaminergic agents" },
  { id: "C01CA01", title: "etilefrine" },
  { id: "C01CA02", title: "isoprenaline" },
  { id: "C01CA03", title: "norepinephrine" },
  { id: "C01CA04", title: "dopamine" },
  { id: "C01CA05", title: "norfenefrine" },
  { id: "C01CA06", title: "phenylephrine" },
  { id: "C01CA07", title: "dobutamine" },
  { id: "C01CA08", title: "oxedrine" },
  { id: "C01CA09", title: "metaraminol" },
  { id: "C01CA10", title: "methoxamine" },
  { id: "C01CA11", title: "mephentermine" },
  { id: "C01CA12", title: "dimetofrine" },
  { id: "C01CA13", title: "prenalterol" },
  { id: "C01CA14", title: "dopexamine" },
  { id: "C01CA15", title: "gepefrine" },
  { id: "C01CA16", title: "ibopamine" },
  { id: "C01CA17", title: "midodrine" },
  { id: "C01CA18", title: "octopamine" },
  { id: "C01CA19", title: "fenoldopam" },
  { id: "C01CA21", title: "cafedrine" },
  { id: "C01CA22", title: "arbutamine" },
  { id: "C01CA23", title: "theodrenaline" },
  { id: "C01CA24", title: "epinephrine" },
  { id: "C01CA25", title: "amezinium metilsulfate" },
  { id: "C01CA26", title: "ephedrine" },
  { id: "C01CA30", title: "combinations" },
  { id: "C01CA51", title: "etilefrine, combinations" },
  { id: "C01CE", title: "Phosphodiesterase inhibitors" },
  { id: "C01CE01", title: "amrinone" },
  { id: "C01CE02", title: "milrinone" },
  { id: "C01CE03", title: "enoximone" },
  { id: "C01CE04", title: "bucladesine" },
  { id: "C01CX", title: "Other cardiac stimulants" },
  { id: "C01CX06", title: "angiotensinamide" },
  { id: "C01CX07", title: "xamoterol" },
  { id: "C01CX08", title: "levosimendan" },
  { id: "C01D", title: "VASODILATORS USED IN CARDIAC DISEASES" },
  { id: "C01DA", title: "Organic nitrates" },
  { id: "C01DA02", title: "glyceryl trinitrate" },
  { id: "C01DA04", title: "methylpropylpropanediol dinitrate" },
  { id: "C01DA05", title: "pentaerithrityl tetranitrate" },
  { id: "C01DA07", title: "propatylnitrate" },
  { id: "C01DA08", title: "isosorbide dinitrate" },
  { id: "C01DA09", title: "trolnitrate" },
  { id: "C01DA13", title: "eritrityl tetranitrate" },
  { id: "C01DA14", title: "isosorbide mononitrate" },
  { id: "C01DA20", title: "organic nitrates in combination" },
  { id: "C01DA38", title: "tenitramine" },
  { id: "C01DA52", title: "glyceryl trinitrate, combinations" },
  {
    id: "C01DA54",
    title: "methylpropylpropanediol dinitrate, combinations",
  },
  { id: "C01DA55", title: "pentaerithrityl tetranitrate, combinations" },
  { id: "C01DA57", title: "propatylnitrate, combinations" },
  { id: "C01DA58", title: "isosorbide dinitrate, combinations" },
  { id: "C01DA59", title: "trolnitrate, combinations" },
  { id: "C01DA63", title: "eritrityl tetranitrate, combinations" },
  {
    id: "C01DA70",
    title: "organic nitrates in combination with psycholeptics",
  },
  { id: "C01DB", title: "Quinolone vasodilators" },
  { id: "C01DB01", title: "flosequinan" },
  { id: "C01DX", title: "Other vasodilators used in cardiac diseases" },
  { id: "C01DX01", title: "itramin tosilate" },
  { id: "C01DX02", title: "prenylamine" },
  { id: "C01DX03", title: "oxyfedrine" },
  { id: "C01DX04", title: "benziodarone" },
  { id: "C01DX05", title: "carbocromen" },
  { id: "C01DX06", title: "hexobendine" },
  { id: "C01DX07", title: "etafenone" },
  { id: "C01DX08", title: "heptaminol" },
  { id: "C01DX09", title: "imolamine" },
  { id: "C01DX10", title: "dilazep" },
  { id: "C01DX11", title: "trapidil" },
  { id: "C01DX12", title: "molsidomine" },
  { id: "C01DX13", title: "efloxate" },
  { id: "C01DX14", title: "cinepazet" },
  { id: "C01DX15", title: "cloridarol" },
  { id: "C01DX16", title: "nicorandil" },
  { id: "C01DX18", title: "linsidomine" },
  { id: "C01DX19", title: "nesiritide" },
  { id: "C01DX21", title: "serelaxin" },
  { id: "C01DX51", title: "itramin tosilate, combinations" },
  { id: "C01DX52", title: "prenylamine, combinations" },
  { id: "C01DX53", title: "oxyfedrine, combinations" },
  { id: "C01DX54", title: "benziodarone, combinations" },
  { id: "C01E", title: "OTHER CARDIAC PREPARATIONS" },
  { id: "C01EA", title: "Prostaglandins" },
  { id: "C01EA01", title: "alprostadil" },
  { id: "C01EB", title: "Other cardiac preparations" },
  { id: "C01EB02", title: "camphora" },
  { id: "C01EB03", title: "indometacin" },
  { id: "C01EB04", title: "crataegus glycosides" },
  { id: "C01EB05", title: "creatinolfosfate" },
  { id: "C01EB06", title: "fosfocreatine" },
  { id: "C01EB07", title: "fructose 1,6-diphosphate" },
  { id: "C01EB09", title: "ubidecarenone" },
  { id: "C01EB10", title: "adenosine" },
  { id: "C01EB11", title: "tiracizine" },
  { id: "C01EB13", title: "acadesine" },
  { id: "C01EB15", title: "trimetazidine" },
  { id: "C01EB16", title: "ibuprofen" },
  { id: "C01EB17", title: "ivabradine" },
  { id: "C01EB18", title: "ranolazine" },
  { id: "C01EB21", title: "regadenoson" },
  { id: "C01EB22", title: "meldonium" },
  { id: "C01EX", title: "Other cardiac combination products" },
  { id: "C02", title: "ANTIHYPERTENSIVES" },
  { id: "C02A", title: "ANTIADRENERGIC AGENTS, CENTRALLY ACTING" },
  { id: "C02AA", title: "Rauwolfia alkaloids" },
  { id: "C02AA01", title: "rescinnamine" },
  { id: "C02AA02", title: "reserpine" },
  { id: "C02AA03", title: "combinations of rauwolfia alkaloids" },
  { id: "C02AA04", title: "rauwolfia alkaloids, whole root" },
  { id: "C02AA05", title: "deserpidine" },
  { id: "C02AA06", title: "methoserpidine" },
  { id: "C02AA07", title: "bietaserpine" },
  { id: "C02AA52", title: "reserpine, combinations" },
  {
    id: "C02AA53",
    title: "combinations of rauwolfia alkoloids, combinations",
  },
  { id: "C02AA57", title: "bietaserpine, combinations" },
  { id: "C02AB", title: "Methyldopa" },
  { id: "C02AB01", title: "methyldopa (levorotatory)" },
  { id: "C02AB02", title: "methyldopa (racemic)" },
  { id: "C02AC", title: "Imidazoline receptor agonists" },
  { id: "C02AC01", title: "clonidine" },
  { id: "C02AC02", title: "guanfacine" },
  { id: "C02AC04", title: "tolonidine" },
  { id: "C02AC05", title: "moxonidine" },
  { id: "C02AC06", title: "rilmenidine" },
  { id: "C02B", title: "ANTIADRENERGIC AGENTS, GANGLION-BLOCKING" },
  { id: "C02BA", title: "Sulfonium derivatives" },
  { id: "C02BA01", title: "trimetaphan" },
  { id: "C02BB", title: "Secondary and tertiary amines" },
  { id: "C02BB01", title: "mecamylamine" },
  { id: "C02BC", title: "Bisquaternary ammonium compounds" },
  { id: "C02C", title: "ANTIADRENERGIC AGENTS, PERIPHERALLY ACTING" },
  { id: "C02CA", title: "Alpha-adrenoreceptor antagonists" },
  { id: "C02CA01", title: "prazosin" },
  { id: "C02CA02", title: "indoramin" },
  { id: "C02CA03", title: "trimazosin" },
  { id: "C02CA04", title: "doxazosin" },
  { id: "C02CA06", title: "urapidil" },
  { id: "C02CC", title: "Guanidine derivatives" },
  { id: "C02CC01", title: "betanidine" },
  { id: "C02CC02", title: "guanethidine" },
  { id: "C02CC03", title: "guanoxan" },
  { id: "C02CC04", title: "debrisoquine" },
  { id: "C02CC05", title: "guanoclor" },
  { id: "C02CC06", title: "guanazodine" },
  { id: "C02CC07", title: "guanoxabenz" },
  { id: "C02D", title: "ARTERIOLAR SMOOTH MUSCLE, AGENTS ACTING ON" },
  { id: "C02DA", title: "Thiazide derivatives" },
  { id: "C02DA01", title: "diazoxide" },
  { id: "C02DB", title: "Hydrazinophthalazine derivatives" },
  { id: "C02DB01", title: "dihydralazine" },
  { id: "C02DB02", title: "hydralazine" },
  { id: "C02DB03", title: "endralazine" },
  { id: "C02DB04", title: "cadralazine" },
  { id: "C02DC", title: "Pyrimidine derivatives" },
  { id: "C02DC01", title: "minoxidil" },
  { id: "C02DD", title: "Nitroferricyanide derivatives" },
  { id: "C02DD01", title: "nitroprusside" },
  { id: "C02DG", title: "Guanidine derivatives" },
  { id: "C02DG01", title: "pinacidil" },
  { id: "C02K", title: "OTHER ANTIHYPERTENSIVES" },
  { id: "C02KA", title: "Alkaloids, excl. rauwolfia" },
  { id: "C02KA01", title: "veratrum" },
  { id: "C02KB", title: "Tyrosine hydroxylase inhibitors" },
  { id: "C02KB01", title: "metirosine" },
  { id: "C02KC", title: "MAO inhibitors" },
  { id: "C02KC01", title: "pargyline" },
  { id: "C02KD", title: "Serotonin antagonists" },
  { id: "C02KD01", title: "ketanserin" },
  { id: "C02KX", title: "Other antihypertensives" },
  { id: "C02KX01", title: "bosentan" },
  { id: "C02KX02", title: "ambrisentan" },
  { id: "C02KX03", title: "sitaxentan" },
  { id: "C02KX04", title: "macitentan" },
  { id: "C02L", title: "ANTIHYPERTENSIVES AND DIURETICS IN COMBINATION" },
  {
    id: "C02LA",
    title: "Rauwolfia alkaloids and diuretics in combination",
  },
  { id: "C02LA01", title: "reserpine and diuretics" },
  { id: "C02LA02", title: "rescinnamine and diuretics" },
  { id: "C02LA03", title: "deserpidine and diuretics" },
  { id: "C02LA04", title: "methoserpidine and diuretics" },
  { id: "C02LA07", title: "bietaserpine and diuretics" },
  { id: "C02LA08", title: "rauwolfia alkaloids, whole root and diuretics" },
  { id: "C02LA09", title: "syrosingopine and diuretics" },
  {
    id: "C02LA50",
    title:
      "combination of rauwolfia alkaloids and diuretics incl. other combinations",
  },
  {
    id: "C02LA51",
    title: "reserpine and diuretics, combinations with other drugs",
  },
  {
    id: "C02LA52",
    title: "rescinnamine and diuretics, combinations with other drugs",
  },
  {
    id: "C02LA71",
    title: "reserpine and diuretics, combinations with psycholeptics",
  },
  { id: "C02LB", title: "Methyldopa and diuretics in combination" },
  { id: "C02LB01", title: "methyldopa (levorotatory) and diuretics" },
  {
    id: "C02LC",
    title: "Imidazoline receptor agonists in combination with diuretics",
  },
  { id: "C02LC01", title: "clonidine and diuretics" },
  { id: "C02LC05", title: "moxonidine and diuretics" },
  {
    id: "C02LC51",
    title: "clonidine and diuretics, combinations with other drugs",
  },
  { id: "C02LE", title: "Alpha-adrenoreceptor antagonists and diuretics" },
  { id: "C02LE01", title: "prazosin and diuretics" },
  { id: "C02LF", title: "Guanidine derivatives and diuretics" },
  { id: "C02LF01", title: "guanethidine and diuretics" },
  { id: "C02LG", title: "Hydrazinophthalazine derivatives and diuretics" },
  { id: "C02LG01", title: "dihydralazine and diuretics" },
  { id: "C02LG02", title: "hydralazine and diuretics" },
  { id: "C02LG03", title: "picodralazine and diuretics" },
  {
    id: "C02LG51",
    title: "dihydralazine and diuretics, combinations with other drugs",
  },
  {
    id: "C02LG73",
    title: "picodralazine and diuretics, combinations with psycholeptics",
  },
  {
    id: "C02LK",
    title: "Alkaloids, excl. rauwolfia, in combination with diuretics",
  },
  { id: "C02LK01", title: "veratrum and diuretics" },
  { id: "C02LL", title: "MAO inhibitors and diuretics" },
  { id: "C02LL01", title: "pargyline and diuretics" },
  { id: "C02LN", title: "Serotonin antagonists and diuretics" },
  { id: "C02LX", title: "Other antihypertensives and diuretics" },
  { id: "C02LX01", title: "pinacidil and diuretics" },
  { id: "C02N", title: "COMBINATIONS OF ANTIHYPERTENSIVES IN ATC-GR. C02" },
  { id: "C03", title: "DIURETICS" },
  { id: "C03A", title: "LOW-CEILING DIURETICS, THIAZIDES" },
  { id: "C03AA", title: "Thiazides, plain" },
  { id: "C03AA01", title: "bendroflumethiazide" },
  { id: "C03AA02", title: "hydroflumethiazide" },
  { id: "C03AA03", title: "hydrochlorothiazide" },
  { id: "C03AA04", title: "chlorothiazide" },
  { id: "C03AA05", title: "polythiazide" },
  { id: "C03AA06", title: "trichlormethiazide" },
  { id: "C03AA07", title: "cyclopenthiazide" },
  { id: "C03AA08", title: "methyclothiazide" },
  { id: "C03AA09", title: "cyclothiazide" },
  { id: "C03AA13", title: "mebutizide" },
  { id: "C03AB", title: "Thiazides and potassium in combination" },
  { id: "C03AB01", title: "bendroflumethiazide and potassium" },
  { id: "C03AB02", title: "hydroflumethiazide and potassium" },
  { id: "C03AB03", title: "hydrochlorothiazide and potassium" },
  { id: "C03AB04", title: "chlorothiazide and potassium" },
  { id: "C03AB05", title: "polythiazide and potassium" },
  { id: "C03AB06", title: "trichlormethiazide and potassium" },
  { id: "C03AB07", title: "cyclopenthiazide and potassium" },
  { id: "C03AB08", title: "methyclothiazide and potassium" },
  { id: "C03AB09", title: "cyclothiazide and potassium" },
  {
    id: "C03AH",
    title: "Thiazides, combinations with psycholeptics and/or analgesics",
  },
  { id: "C03AH01", title: "chlorothiazide, combinations" },
  { id: "C03AH02", title: "hydroflumethiazide, combinations" },
  { id: "C03AX", title: "Thiazides, combinations with other drugs" },
  { id: "C03AX01", title: "hydrochlorothiazide, combinations" },
  { id: "C03B", title: "LOW-CEILING DIURETICS, EXCL. THIAZIDES" },
  { id: "C03BA", title: "Sulfonamides, plain" },
  { id: "C03BA02", title: "quinethazone" },
  { id: "C03BA03", title: "clopamide" },
  { id: "C03BA04", title: "chlortalidone" },
  { id: "C03BA05", title: "mefruside" },
  { id: "C03BA07", title: "clofenamide" },
  { id: "C03BA08", title: "metolazone" },
  { id: "C03BA09", title: "meticrane" },
  { id: "C03BA10", title: "xipamide" },
  { id: "C03BA11", title: "indapamide" },
  { id: "C03BA12", title: "clorexolone" },
  { id: "C03BA13", title: "fenquizone" },
  { id: "C03BA82", title: "clorexolone, combinations with psycholeptics" },
  { id: "C03BB", title: "Sulfonamides and potassium in combination" },
  { id: "C03BB02", title: "quinethazone and potassium" },
  { id: "C03BB03", title: "clopamide and potassium" },
  { id: "C03BB04", title: "chlortalidone and potassium" },
  { id: "C03BB05", title: "mefruside and potassium" },
  { id: "C03BB07", title: "clofenamide and potassium" },
  { id: "C03BC", title: "Mercurial diuretics" },
  { id: "C03BC01", title: "mersalyl" },
  { id: "C03BD", title: "Xanthine derivatives" },
  { id: "C03BD01", title: "theobromine" },
  { id: "C03BK", title: "Sulfonamides, combinations with other drugs" },
  { id: "C03BX", title: "Other low-ceiling diuretics" },
  { id: "C03BX03", title: "cicletanine" },
  { id: "C03C", title: "HIGH-CEILING DIURETICS" },
  { id: "C03CA", title: "Sulfonamides, plain" },
  { id: "C03CA01", title: "furosemide" },
  { id: "C03CA02", title: "bumetanide" },
  { id: "C03CA03", title: "piretanide" },
  { id: "C03CA04", title: "torasemide" },
  { id: "C03CB", title: "Sulfonamides and potassium in combination" },
  { id: "C03CB01", title: "furosemide and potassium" },
  { id: "C03CB02", title: "bumetanide and potassium" },
  { id: "C03CC", title: "Aryloxyacetic acid derivatives" },
  { id: "C03CC01", title: "etacrynic acid" },
  { id: "C03CC02", title: "tienilic acid" },
  { id: "C03CD", title: "Pyrazolone derivatives" },
  { id: "C03CD01", title: "muzolimine" },
  { id: "C03CX", title: "Other high-ceiling diuretics" },
  { id: "C03CX01", title: "etozolin" },
  { id: "C03D", title: "POTASSIUM-SPARING AGENTS" },
  { id: "C03DA", title: "Aldosterone antagonists" },
  { id: "C03DA01", title: "spironolactone" },
  { id: "C03DA02", title: "potassium canrenoate" },
  { id: "C03DA03", title: "canrenone" },
  { id: "C03DA04", title: "eplerenone" },
  { id: "C03DB", title: "Other potassium-sparing agents" },
  { id: "C03DB01", title: "amiloride" },
  { id: "C03DB02", title: "triamterene" },
  {
    id: "C03E",
    title: "DIURETICS AND POTASSIUM-SPARING AGENTS IN COMBINATION",
  },
  {
    id: "C03EA",
    title: "Low-ceiling diuretics and potassium-sparing agents",
  },
  {
    id: "C03EA01",
    title: "hydrochlorothiazide and potassium-sparing agents",
  },
  {
    id: "C03EA02",
    title: "trichlormethiazide and potassium-sparing agents",
  },
  { id: "C03EA03", title: "epitizide and potassium-sparing agents" },
  { id: "C03EA04", title: "altizide and potassium-sparing agents" },
  { id: "C03EA05", title: "mebutizide and potassium-sparing agents" },
  { id: "C03EA06", title: "chlortalidone and potassium-sparing agents" },
  { id: "C03EA07", title: "cyclopenthiazide and potassium-sparing agents" },
  { id: "C03EA12", title: "metolazone and potassium-sparing agents" },
  {
    id: "C03EA13",
    title: "bendroflumethiazide and potassium-sparing agents",
  },
  { id: "C03EA14", title: "butizide and potassium-sparing agents" },
  {
    id: "C03EB",
    title: "High-ceiling diuretics and potassium-sparing agents",
  },
  { id: "C03EB01", title: "furosemide and potassium-sparing agents" },
  { id: "C03EB02", title: "bumetanide and potassium-sparing agents" },
  { id: "C03X", title: "OTHER DIURETICS" },
  { id: "C03XA", title: "Vasopressin antagonists" },
  { id: "C03XA01", title: "tolvaptan" },
  { id: "C03XA02", title: "conivaptan" },
  { id: "C04", title: "PERIPHERAL VASODILATORS" },
  { id: "C04A", title: "PERIPHERAL VASODILATORS" },
  { id: "C04AA", title: "2-amino-1-phenylethanol derivatives" },
  { id: "C04AA01", title: "isoxsuprine" },
  { id: "C04AA02", title: "buphenine" },
  { id: "C04AA31", title: "bamethan" },
  { id: "C04AB", title: "Imidazoline derivatives" },
  { id: "C04AB01", title: "phentolamine" },
  { id: "C04AB02", title: "tolazoline" },
  { id: "C04AC", title: "Nicotinic acid and derivatives" },
  { id: "C04AC01", title: "nicotinic acid" },
  { id: "C04AC02", title: "nicotinyl alcohol (pyridylcarbinol)" },
  { id: "C04AC03", title: "inositol nicotinate" },
  { id: "C04AC07", title: "ciclonicate" },
  { id: "C04AD", title: "Purine derivatives" },
  { id: "C04AD01", title: "pentifylline" },
  { id: "C04AD02", title: "xantinol nicotinate" },
  { id: "C04AD03", title: "pentoxifylline" },
  { id: "C04AD04", title: "etofylline nicotinate" },
  { id: "C04AE", title: "Ergot alkaloids" },
  { id: "C04AE01", title: "ergoloid mesylates" },
  { id: "C04AE02", title: "nicergoline" },
  { id: "C04AE04", title: "dihydroergocristine" },
  { id: "C04AE51", title: "ergoloid mesylates, combinations" },
  { id: "C04AE54", title: "dihydroergocristine, combinations" },
  { id: "C04AF", title: "Enzymes" },
  { id: "C04AF01", title: "kallidinogenase" },
  { id: "C04AX", title: "Other peripheral vasodilators" },
  { id: "C04AX01", title: "cyclandelate" },
  { id: "C04AX02", title: "phenoxybenzamine" },
  { id: "C04AX07", title: "vincamine" },
  { id: "C04AX10", title: "moxisylyte" },
  { id: "C04AX11", title: "bencyclane" },
  { id: "C04AX17", title: "vinburnine" },
  { id: "C04AX19", title: "suloctidil" },
  { id: "C04AX20", title: "buflomedil" },
  { id: "C04AX21", title: "naftidrofuryl" },
  { id: "C04AX23", title: "butalamine" },
  { id: "C04AX24", title: "visnadine" },
  { id: "C04AX26", title: "cetiedil" },
  { id: "C04AX27", title: "cinepazide" },
  { id: "C04AX28", title: "ifenprodil" },
  { id: "C04AX30", title: "azapetine" },
  { id: "C04AX32", title: "fasudil" },
  { id: "C05", title: "VASOPROTECTIVES" },
  {
    id: "C05A",
    title:
      "AGENTS FOR TREATMENT OF HEMORRHOIDS AND ANAL FISSURES FOR TOPICAL USE",
  },
  { id: "C05AA", title: "Corticosteroids" },
  { id: "C05AA01", title: "hydrocortisone" },
  { id: "C05AA04", title: "prednisolone" },
  { id: "C05AA05", title: "betamethasone" },
  { id: "C05AA06", title: "fluorometholone" },
  { id: "C05AA08", title: "fluocortolone" },
  { id: "C05AA09", title: "dexamethasone" },
  { id: "C05AA10", title: "fluocinolone acetonide" },
  { id: "C05AA11", title: "fluocinonide" },
  { id: "C05AA12", title: "triamcinolone" },
  { id: "C05AB", title: "Antibiotics" },
  { id: "C05AD", title: "Local anesthetics" },
  { id: "C05AD01", title: "lidocaine" },
  { id: "C05AD02", title: "tetracaine" },
  { id: "C05AD03", title: "benzocaine" },
  { id: "C05AD04", title: "cinchocaine" },
  { id: "C05AD05", title: "procaine" },
  { id: "C05AD06", title: "oxetacaine" },
  { id: "C05AD07", title: "pramocaine" },
  { id: "C05AE", title: "Muscle relaxants" },
  { id: "C05AE01", title: "glyceryl trinitrate" },
  { id: "C05AE02", title: "isosorbide dinitrate" },
  {
    id: "C05AX",
    title:
      "Other agents for treatment of hemorrhoids and anal fissures for topical use",
  },
  { id: "C05AX01", title: "aluminium preparations" },
  { id: "C05AX02", title: "bismuth preparations, combinations" },
  { id: "C05AX03", title: "other preparations, combinations" },
  { id: "C05AX04", title: "zinc preparations" },
  { id: "C05AX05", title: "tribenoside" },
  { id: "C05B", title: "ANTIVARICOSE THERAPY" },
  { id: "C05BA", title: "Heparins or heparinoids for topical use" },
  { id: "C05BA01", title: "organo-heparinoid" },
  { id: "C05BA02", title: "sodium apolate" },
  { id: "C05BA03", title: "heparin" },
  { id: "C05BA04", title: "pentosan polysulfate sodium" },
  { id: "C05BA51", title: "heparinoid, combinations" },
  { id: "C05BA53", title: "heparin, combinations" },
  { id: "C05BB", title: "Sclerosing agents for local injection" },
  { id: "C05BB01", title: "monoethanolamine oleate" },
  { id: "C05BB02", title: "polidocanol" },
  { id: "C05BB03", title: "invert sugar" },
  { id: "C05BB04", title: "sodium tetradecyl sulfate" },
  { id: "C05BB05", title: "phenol" },
  { id: "C05BB56", title: "glucose, combinations" },
  { id: "C05BX", title: "Other sclerosing agents" },
  { id: "C05BX01", title: "calcium dobesilate" },
  { id: "C05BX51", title: "calcium dobesilate, combinations" },
  { id: "C05C", title: "CAPILLARY STABILIZING AGENTS" },
  { id: "C05CA", title: "Bioflavonoids" },
  { id: "C05CA01", title: "rutoside" },
  { id: "C05CA02", title: "monoxerutin" },
  { id: "C05CA03", title: "diosmin" },
  { id: "C05CA04", title: "troxerutin" },
  { id: "C05CA05", title: "hidrosmin" },
  { id: "C05CA51", title: "rutoside, combinations" },
  { id: "C05CA53", title: "diosmin, combinations" },
  { id: "C05CA54", title: "troxerutin, combinations" },
  { id: "C05CX", title: "Other capillary stabilizing agents" },
  { id: "C05CX01", title: "tribenoside" },
  { id: "C05CX02", title: "naftazone" },
  { id: "C05CX03", title: "Hippocastani semen" },
  { id: "C07", title: "BETA BLOCKING AGENTS" },
  { id: "C07A", title: "BETA BLOCKING AGENTS" },
  { id: "C07AA", title: "Beta blocking agents, non-selective" },
  { id: "C07AA01", title: "alprenolol" },
  { id: "C07AA02", title: "oxprenolol" },
  { id: "C07AA03", title: "pindolol" },
  { id: "C07AA05", title: "propranolol" },
  { id: "C07AA06", title: "timolol" },
  { id: "C07AA07", title: "sotalol" },
  { id: "C07AA12", title: "nadolol" },
  { id: "C07AA14", title: "mepindolol" },
  { id: "C07AA15", title: "carteolol" },
  { id: "C07AA16", title: "tertatolol" },
  { id: "C07AA17", title: "bopindolol" },
  { id: "C07AA19", title: "bupranolol" },
  { id: "C07AA23", title: "penbutolol" },
  { id: "C07AA27", title: "cloranolol" },
  { id: "C07AA57", title: "sotalol, combinations" },
  { id: "C07AB", title: "Beta blocking agents, selective" },
  { id: "C07AB01", title: "practolol" },
  { id: "C07AB02", title: "metoprolol" },
  { id: "C07AB03", title: "atenolol" },
  { id: "C07AB04", title: "acebutolol" },
  { id: "C07AB05", title: "betaxolol" },
  { id: "C07AB06", title: "bevantolol" },
  { id: "C07AB07", title: "bisoprolol" },
  { id: "C07AB08", title: "celiprolol" },
  { id: "C07AB09", title: "esmolol" },
  { id: "C07AB10", title: "epanolol" },
  { id: "C07AB11", title: "s-atenolol" },
  { id: "C07AB12", title: "nebivolol" },
  { id: "C07AB13", title: "talinolol" },
  { id: "C07AB52", title: "metoprolol, combinations" },
  { id: "C07AB57", title: "bisoprolol, combinations" },
  { id: "C07AG", title: "Alpha and beta blocking agents" },
  { id: "C07AG01", title: "labetalol" },
  { id: "C07AG02", title: "carvedilol" },
  { id: "C07B", title: "BETA BLOCKING AGENTS AND THIAZIDES" },
  {
    id: "C07BA",
    title: "Beta blocking agents, non-selective, and thiazides",
  },
  { id: "C07BA02", title: "oxprenolol and thiazides" },
  { id: "C07BA05", title: "propranolol and thiazides" },
  { id: "C07BA06", title: "timolol and thiazides" },
  { id: "C07BA07", title: "sotalol and thiazides" },
  { id: "C07BA12", title: "nadolol and thiazides" },
  { id: "C07BA68", title: "metipranolol and thiazides, combinations" },
  { id: "C07BB", title: "Beta blocking agents, selective, and thiazides" },
  { id: "C07BB02", title: "metoprolol and thiazides" },
  { id: "C07BB03", title: "atenolol and thiazides" },
  { id: "C07BB04", title: "acebutolol and thiazides" },
  { id: "C07BB06", title: "bevantolol and thiazides" },
  { id: "C07BB07", title: "bisoprolol and thiazides" },
  { id: "C07BB12", title: "nebivolol and thiazides" },
  { id: "C07BB52", title: "metoprolol and thiazides, combinations" },
  { id: "C07BG", title: "Alpha and beta blocking agents and thiazides" },
  { id: "C07BG01", title: "labetalol and thiazides" },
  { id: "C07C", title: "BETA BLOCKING AGENTS AND OTHER DIURETICS" },
  {
    id: "C07CA",
    title: "Beta blocking agents, non-selective, and other diuretics",
  },
  { id: "C07CA02", title: "oxprenolol and other diuretics" },
  { id: "C07CA03", title: "pindolol and other diuretics" },
  { id: "C07CA17", title: "bopindolol and other diuretics" },
  { id: "C07CA23", title: "penbutolol and other diuretics" },
  {
    id: "C07CB",
    title: "Beta blocking agents, selective, and other diuretics",
  },
  { id: "C07CB02", title: "metoprolol and other diuretics" },
  { id: "C07CB03", title: "atenolol and other diuretics" },
  { id: "C07CB53", title: "atenolol and other diuretics, combinations" },
  {
    id: "C07CG",
    title: "Alpha and beta blocking agents and other diuretics",
  },
  { id: "C07CG01", title: "labetalol and other diuretics" },
  {
    id: "C07D",
    title: "BETA BLOCKING AGENTS, THIAZIDES AND OTHER DIURETICS",
  },
  {
    id: "C07DA",
    title: "Beta blocking agents, non-selective, thiazides and other diuretics",
  },
  { id: "C07DA06", title: "timolol, thiazides and other diuretics" },
  {
    id: "C07DB",
    title: "Beta blocking agents, selective, thiazides and other diuretics",
  },
  { id: "C07DB01", title: "atenolol, thiazides and other diuretics" },
  { id: "C07E", title: "BETA BLOCKING AGENTS AND VASODILATORS" },
  {
    id: "C07EA",
    title: "Beta blocking agents, non-selective, and vasodilators",
  },
  {
    id: "C07EB",
    title: "Beta blocking agents, selective, and vasodilators",
  },
  { id: "C07F", title: "BETA BLOCKING AGENTS AND OTHER ANTIHYPERTENSIVES" },
  {
    id: "C07FA",
    title: "Beta blocking agents, non-selective, and other antihypertensives",
  },
  { id: "C07FA05", title: "propranolol and other antihypertensives" },
  {
    id: "C07FB",
    title: "Beta blocking agents, selective, and other antihypertensives",
  },
  { id: "C07FB02", title: "metoprolol and other antihypertensives" },
  { id: "C07FB03", title: "atenolol and other antihypertensives" },
  { id: "C07FB07", title: "bisoprolol and other antihypertensives" },
  { id: "C08", title: "CALCIUM CHANNEL BLOCKERS" },
  {
    id: "C08C",
    title: "SELECTIVE CALCIUM CHANNEL BLOCKERS WITH MAINLY VASCULAR EFFECTS",
  },
  { id: "C08CA", title: "Dihydropyridine derivatives" },
  { id: "C08CA01", title: "amlodipine" },
  { id: "C08CA02", title: "felodipine" },
  { id: "C08CA03", title: "isradipine" },
  { id: "C08CA04", title: "nicardipine" },
  { id: "C08CA05", title: "nifedipine" },
  { id: "C08CA06", title: "nimodipine" },
  { id: "C08CA07", title: "nisoldipine" },
  { id: "C08CA08", title: "nitrendipine" },
  { id: "C08CA09", title: "lacidipine" },
  { id: "C08CA10", title: "nilvadipine" },
  { id: "C08CA11", title: "manidipine" },
  { id: "C08CA12", title: "barnidipine" },
  { id: "C08CA13", title: "lercanidipine" },
  { id: "C08CA14", title: "cilnidipine" },
  { id: "C08CA15", title: "benidipine" },
  { id: "C08CA16", title: "clevidipine" },
  { id: "C08CA55", title: "nifedipine, combinations" },
  {
    id: "C08CX",
    title:
      "Other selective calcium channel blockers with mainly vascular effects",
  },
  { id: "C08CX01", title: "mibefradil" },
  {
    id: "C08D",
    title: "SELECTIVE CALCIUM CHANNEL BLOCKERS WITH DIRECT CARDIAC EFFECTS",
  },
  { id: "C08DA", title: "Phenylalkylamine derivatives" },
  { id: "C08DA01", title: "verapamil" },
  { id: "C08DA02", title: "gallopamil" },
  { id: "C08DA51", title: "verapamil, combinations" },
  { id: "C08DB", title: "Benzothiazepine derivatives" },
  { id: "C08DB01", title: "diltiazem" },
  { id: "C08E", title: "NON-SELECTIVE CALCIUM CHANNEL BLOCKERS" },
  { id: "C08EA", title: "Phenylalkylamine derivatives" },
  { id: "C08EA01", title: "fendiline" },
  { id: "C08EA02", title: "bepridil" },
  { id: "C08EX", title: "Other non-selective calcium channel blockers" },
  { id: "C08EX01", title: "lidoflazine" },
  { id: "C08EX02", title: "perhexiline" },
  { id: "C08G", title: "CALCIUM CHANNEL BLOCKERS AND DIURETICS" },
  { id: "C08GA", title: "Calcium channel blockers and diuretics" },
  { id: "C08GA01", title: "nifedipine and diuretics" },
  { id: "C08GA02", title: "amlodipine and diuretics" },
  { id: "C09", title: "AGENTS ACTING ON THE RENIN-ANGIOTENSIN SYSTEM" },
  { id: "C09A", title: "ACE INHIBITORS, PLAIN" },
  { id: "C09AA", title: "ACE inhibitors, plain" },
  { id: "C09AA01", title: "captopril" },
  { id: "C09AA02", title: "enalapril" },
  { id: "C09AA03", title: "lisinopril" },
  { id: "C09AA04", title: "perindopril" },
  { id: "C09AA05", title: "ramipril" },
  { id: "C09AA06", title: "quinapril" },
  { id: "C09AA07", title: "benazepril" },
  { id: "C09AA08", title: "cilazapril" },
  { id: "C09AA09", title: "fosinopril" },
  { id: "C09AA10", title: "trandolapril" },
  { id: "C09AA11", title: "spirapril" },
  { id: "C09AA12", title: "delapril" },
  { id: "C09AA13", title: "moexipril" },
  { id: "C09AA14", title: "temocapril" },
  { id: "C09AA15", title: "zofenopril" },
  { id: "C09AA16", title: "imidapril" },
  { id: "C09B", title: "ACE INHIBITORS, COMBINATIONS" },
  { id: "C09BA", title: "ACE inhibitors and diuretics" },
  { id: "C09BA01", title: "captopril and diuretics" },
  { id: "C09BA02", title: "enalapril and diuretics" },
  { id: "C09BA03", title: "lisinopril and diuretics" },
  { id: "C09BA04", title: "perindopril and diuretics" },
  { id: "C09BA05", title: "ramipril and diuretics" },
  { id: "C09BA06", title: "quinapril and diuretics" },
  { id: "C09BA07", title: "benazepril and diuretics" },
  { id: "C09BA08", title: "cilazapril and diuretics" },
  { id: "C09BA09", title: "fosinopril and diuretics" },
  { id: "C09BA12", title: "delapril and diuretics" },
  { id: "C09BA13", title: "moexipril and diuretics" },
  { id: "C09BA15", title: "zofenopril and diuretics" },
  { id: "C09BB", title: "ACE inhibitors and calcium channel blockers" },
  { id: "C09BB02", title: "enalapril and lercanidipine" },
  { id: "C09BB03", title: "lisinopril and amlodipine" },
  { id: "C09BB04", title: "perindopril and amlodipine" },
  { id: "C09BB05", title: "ramipril and felodipine" },
  { id: "C09BB06", title: "enalapril and nitrendipine" },
  { id: "C09BB07", title: "ramipril and amlodipine" },
  { id: "C09BB10", title: "trandolapril and verapamil" },
  { id: "C09BB12", title: "delapril and manidipine" },
  { id: "C09BX", title: "ACE inhibitors, other combinations" },
  { id: "C09BX01", title: "perindopril, amlodipine and indapamide" },
  { id: "C09C", title: "ANGIOTENSIN II ANTAGONISTS, PLAIN" },
  { id: "C09CA", title: "Angiotensin II antagonists, plain" },
  { id: "C09CA01", title: "losartan" },
  { id: "C09CA02", title: "eprosartan" },
  { id: "C09CA03", title: "valsartan" },
  { id: "C09CA04", title: "irbesartan" },
  { id: "C09CA05", title: "tasosartan" },
  { id: "C09CA06", title: "candesartan" },
  { id: "C09CA07", title: "telmisartan" },
  { id: "C09CA08", title: "olmesartan medoxomil" },
  { id: "C09CA09", title: "azilsartan medoxomil" },
  { id: "C09D", title: "ANGIOTENSIN II ANTAGONISTS, COMBINATIONS" },
  { id: "C09DA", title: "Angiotensin II antagonists and diuretics" },
  { id: "C09DA01", title: "losartan and diuretics" },
  { id: "C09DA02", title: "eprosartan and diuretics" },
  { id: "C09DA03", title: "valsartan and diuretics" },
  { id: "C09DA04", title: "irbesartan and diuretics" },
  { id: "C09DA06", title: "candesartan and diuretics" },
  { id: "C09DA07", title: "telmisartan and diuretics" },
  { id: "C09DA08", title: "olmesartan medoxomil and diuretics" },
  {
    id: "C09DB",
    title: "Angiotensin II antagonists and calcium channel blockers",
  },
  { id: "C09DB01", title: "valsartan and amlodipine" },
  { id: "C09DB02", title: "olmesartan medoxomil and amlodipine" },
  { id: "C09DB04", title: "telmisartan and amlodipine" },
  { id: "C09DB05", title: "irbesartan and amlodipine" },
  { id: "C09DB06", title: "losartan and amlodipine" },
  { id: "C09DB07", title: "candesartan and amlodipine" },
  { id: "C09DX", title: "Angiotensin II antagonists, other combinations" },
  { id: "C09DX01", title: "valsartan, amlodipine and hydrochlorothiazide" },
  { id: "C09DX02", title: "valsartan and aliskiren" },
  {
    id: "C09DX03",
    title: "olmesartan medoxomil, amlodipine and hydrochlorothiazide",
  },
  {
    id: "C09X",
    title: "OTHER AGENTS ACTING ON THE RENIN-ANGIOTENSIN SYSTEM",
  },
  { id: "C09XA", title: "Renin-inhibitors" },
  { id: "C09XA01", title: "remikiren" },
  { id: "C09XA02", title: "aliskiren" },
  { id: "C09XA52", title: "aliskiren and hydrochlorothiazide" },
  { id: "C09XA53", title: "aliskiren and amlodipine" },
  { id: "C09XA54", title: "aliskiren, amlodipine and hydrochlorothiazide" },
  { id: "C10", title: "LIPID MODIFYING AGENTS" },
  { id: "C10A", title: "LIPID MODIFYING AGENTS, PLAIN" },
  { id: "C10AA", title: "HMG CoA reductase inhibitors" },
  { id: "C10AA01", title: "simvastatin" },
  { id: "C10AA02", title: "lovastatin" },
  { id: "C10AA03", title: "pravastatin" },
  { id: "C10AA04", title: "fluvastatin" },
  { id: "C10AA05", title: "atorvastatin" },
  { id: "C10AA06", title: "cerivastatin" },
  { id: "C10AA07", title: "rosuvastatin" },
  { id: "C10AA08", title: "pitavastatin" },
  { id: "C10AB", title: "Fibrates" },
  { id: "C10AB01", title: "clofibrate" },
  { id: "C10AB02", title: "bezafibrate" },
  { id: "C10AB03", title: "aluminium clofibrate" },
  { id: "C10AB04", title: "gemfibrozil" },
  { id: "C10AB05", title: "fenofibrate" },
  { id: "C10AB06", title: "simfibrate" },
  { id: "C10AB07", title: "ronifibrate" },
  { id: "C10AB08", title: "ciprofibrate" },
  { id: "C10AB09", title: "etofibrate" },
  { id: "C10AB10", title: "clofibride" },
  { id: "C10AB11", title: "choline fenofibrate" },
  { id: "C10AC", title: "Bile acid sequestrants" },
  { id: "C10AC01", title: "colestyramine" },
  { id: "C10AC02", title: "colestipol" },
  { id: "C10AC03", title: "colextran" },
  { id: "C10AC04", title: "colesevelam" },
  { id: "C10AD", title: "Nicotinic acid and derivatives" },
  { id: "C10AD01", title: "niceritrol" },
  { id: "C10AD02", title: "nicotinic acid" },
  { id: "C10AD03", title: "nicofuranose" },
  { id: "C10AD04", title: "aluminium nicotinate" },
  { id: "C10AD05", title: "nicotinyl alcohol (pyridylcarbinol)" },
  { id: "C10AD06", title: "acipimox" },
  { id: "C10AD52", title: "nicotinic acid, combinations" },
  { id: "C10AX", title: "Other lipid modifying agents" },
  { id: "C10AX01", title: "dextrothyroxine" },
  { id: "C10AX02", title: "probucol" },
  { id: "C10AX03", title: "tiadenol" },
  { id: "C10AX05", title: "meglutol" },
  {
    id: "C10AX06",
    title: "omega-3-triglycerides incl. other esters and acids",
  },
  { id: "C10AX07", title: "magnesium pyridoxal 5-phosphate glutamate" },
  { id: "C10AX08", title: "policosanol" },
  { id: "C10AX09", title: "ezetimibe" },
  { id: "C10AX10", title: "alipogene tiparvovec" },
  { id: "C10AX11", title: "mipomersen" },
  { id: "C10AX12", title: "lomitapide" },
  { id: "C10B", title: "LIPID MODIFYING AGENTS, COMBINATIONS" },
  {
    id: "C10BA",
    title:
      "HMG CoA reductase inhibitors in combination with other lipid modifying agents",
  },
  { id: "C10BA01", title: "lovastatin and nicotinic acid" },
  { id: "C10BA02", title: "simvastatin and ezetimibe" },
  { id: "C10BA03", title: "pravastatin and fenofibrate" },
  { id: "C10BA04", title: "simvastatin and fenofibrate" },
  { id: "C10BA05", title: "atorvastatin and ezetimibe" },
  { id: "C10BA06", title: "rosuvastatin and ezetimibe" },
  {
    id: "C10BX",
    title: "HMG CoA reductase inhibitors, other combinations",
  },
  { id: "C10BX01", title: "simvastatin and acetylsalicylic acid" },
  { id: "C10BX02", title: "pravastatin and acetylsalicylic acid" },
  { id: "C10BX03", title: "atorvastatin and amlodipine" },
  {
    id: "C10BX04",
    title: "simvastatin, acetylsalicylic acid and ramipril",
  },
  { id: "C10BX05", title: "rosuvastatin and acetylsalicylic acid" },
  { id: "D", title: "DERMATOLOGICALS" },
  { id: "D01", title: "ANTIFUNGALS FOR DERMATOLOGICAL USE" },
  { id: "D01A", title: "ANTIFUNGALS FOR TOPICAL USE" },
  { id: "D01AA", title: "Antibiotics" },
  { id: "D01AA01", title: "nystatin" },
  { id: "D01AA02", title: "natamycin" },
  { id: "D01AA03", title: "hachimycin" },
  { id: "D01AA04", title: "pecilocin" },
  { id: "D01AA06", title: "mepartricin" },
  { id: "D01AA07", title: "pyrrolnitrin" },
  { id: "D01AA08", title: "griseofulvin" },
  { id: "D01AA20", title: "combinations" },
  { id: "D01AC", title: "Imidazole and triazole derivatives" },
  { id: "D01AC01", title: "clotrimazole" },
  { id: "D01AC02", title: "miconazole" },
  { id: "D01AC03", title: "econazole" },
  { id: "D01AC04", title: "chlormidazole" },
  { id: "D01AC05", title: "isoconazole" },
  { id: "D01AC06", title: "tiabendazole" },
  { id: "D01AC07", title: "tioconazole" },
  { id: "D01AC08", title: "ketoconazole" },
  { id: "D01AC09", title: "sulconazole" },
  { id: "D01AC10", title: "bifonazole" },
  { id: "D01AC11", title: "oxiconazole" },
  { id: "D01AC12", title: "fenticonazole" },
  { id: "D01AC13", title: "omoconazole" },
  { id: "D01AC14", title: "sertaconazole" },
  { id: "D01AC15", title: "fluconazole" },
  { id: "D01AC16", title: "flutrimazole" },
  { id: "D01AC17", title: "eberconazole" },
  { id: "D01AC20", title: "combinations" },
  { id: "D01AC52", title: "miconazole, combinations" },
  { id: "D01AC60", title: "bifonazole, combinations" },
  { id: "D01AE", title: "Other antifungals for topical use" },
  { id: "D01AE01", title: "bromochlorosalicylanilide" },
  { id: "D01AE02", title: "methylrosaniline" },
  { id: "D01AE03", title: "tribromometacresol" },
  { id: "D01AE04", title: "undecylenic acid" },
  { id: "D01AE05", title: "polynoxylin" },
  { id: "D01AE06", title: "2-(4-chlorphenoxy)-ethanol" },
  { id: "D01AE07", title: "chlorphenesin" },
  { id: "D01AE08", title: "ticlatone" },
  { id: "D01AE09", title: "sulbentine" },
  { id: "D01AE10", title: "ethyl hydroxybenzoate" },
  { id: "D01AE11", title: "haloprogin" },
  { id: "D01AE12", title: "salicylic acid" },
  { id: "D01AE13", title: "selenium sulfide" },
  { id: "D01AE14", title: "ciclopirox" },
  { id: "D01AE15", title: "terbinafine" },
  { id: "D01AE16", title: "amorolfine" },
  { id: "D01AE17", title: "dimazole" },
  { id: "D01AE18", title: "tolnaftate" },
  { id: "D01AE19", title: "tolciclate" },
  { id: "D01AE20", title: "combinations" },
  { id: "D01AE21", title: "flucytosine" },
  { id: "D01AE22", title: "naftifine" },
  { id: "D01AE23", title: "butenafine" },
  { id: "D01AE54", title: "undecylenic acid, combinations" },
  { id: "D01B", title: "ANTIFUNGALS FOR SYSTEMIC USE" },
  { id: "D01BA", title: "Antifungals for systemic use" },
  { id: "D01BA01", title: "griseofulvin" },
  { id: "D01BA02", title: "terbinafine" },
  { id: "D02", title: "EMOLLIENTS AND PROTECTIVES" },
  { id: "D02A", title: "EMOLLIENTS AND PROTECTIVES" },
  { id: "D02AA", title: "Silicone products" },
  { id: "D02AB", title: "Zinc products" },
  { id: "D02AC", title: "Soft paraffin and fat products" },
  { id: "D02AD", title: "Liquid plasters" },
  { id: "D02AE", title: "Carbamide products" },
  { id: "D02AE01", title: "carbamide" },
  { id: "D02AE51", title: "carbamide, combinations" },
  { id: "D02AF", title: "Salicylic acid preparations" },
  { id: "D02AX", title: "Other emollients and protectives" },
  { id: "D02B", title: "PROTECTIVES AGAINST UV-RADIATION" },
  {
    id: "D02BA",
    title: "Protectives against UV-radiation for topical use",
  },
  { id: "D02BA01", title: "aminobenzoic acid" },
  { id: "D02BA02", title: "octinoxate" },
  {
    id: "D02BB",
    title: "Protectives against UV-radiation for systemic use",
  },
  { id: "D02BB01", title: "betacarotene" },
  { id: "D02BB02", title: "afamelanotide" },
  { id: "D03", title: "PREPARATIONS FOR TREATMENT OF WOUNDS AND ULCERS" },
  { id: "D03A", title: "CICATRIZANTS" },
  { id: "D03AA", title: "Cod-liver oil ointments" },
  { id: "D03AX", title: "Other cicatrizants" },
  { id: "D03AX01", title: "cadexomer iodine" },
  { id: "D03AX02", title: "dextranomer" },
  { id: "D03AX03", title: "dexpanthenol" },
  { id: "D03AX04", title: "calcium pantothenate" },
  { id: "D03AX05", title: "hyaluronic acid" },
  { id: "D03AX06", title: "becaplermin" },
  { id: "D03AX09", title: "crilanomer" },
  { id: "D03AX10", title: "enoxolone" },
  { id: "D03AX11", title: "sodium chlorite" },
  { id: "D03B", title: "ENZYMES" },
  { id: "D03BA", title: "Proteolytic enzymes" },
  { id: "D03BA01", title: "trypsin" },
  { id: "D03BA02", title: "collagenase" },
  { id: "D03BA03", title: "bromelains" },
  { id: "D03BA52", title: "collagenase, combinations" },
  {
    id: "D04",
    title: "ANTIPRURITICS, INCL. ANTIHISTAMINES, ANESTHETICS, ETC.",
  },
  {
    id: "D04A",
    title: "ANTIPRURITICS, INCL. ANTIHISTAMINES, ANESTHETICS, ETC.",
  },
  { id: "D04AA", title: "Antihistamines for topical use" },
  { id: "D04AA01", title: "thonzylamine" },
  { id: "D04AA02", title: "mepyramine" },
  { id: "D04AA03", title: "thenalidine" },
  { id: "D04AA04", title: "tripelennamine" },
  { id: "D04AA09", title: "chloropyramine" },
  { id: "D04AA10", title: "promethazine" },
  { id: "D04AA12", title: "tolpropamine" },
  { id: "D04AA13", title: "dimetindene" },
  { id: "D04AA14", title: "clemastine" },
  { id: "D04AA15", title: "bamipine" },
  { id: "D04AA22", title: "isothipendyl" },
  { id: "D04AA32", title: "diphenhydramine" },
  { id: "D04AA33", title: "diphenhydramine methylbromide" },
  { id: "D04AA34", title: "chlorphenoxamine" },
  { id: "D04AB", title: "Anesthetics for topical use" },
  { id: "D04AB01", title: "lidocaine" },
  { id: "D04AB02", title: "cinchocaine" },
  { id: "D04AB03", title: "oxybuprocaine" },
  { id: "D04AB04", title: "benzocaine" },
  { id: "D04AB05", title: "quinisocaine" },
  { id: "D04AB06", title: "tetracaine" },
  { id: "D04AB07", title: "pramocaine" },
  { id: "D04AX", title: "Other antipruritics" },
  { id: "D05", title: "ANTIPSORIATICS" },
  { id: "D05A", title: "ANTIPSORIATICS FOR TOPICAL USE" },
  { id: "D05AA", title: "Tars" },
  { id: "D05AC", title: "Antracen derivatives" },
  { id: "D05AC01", title: "dithranol" },
  { id: "D05AC51", title: "dithranol, combinations" },
  { id: "D05AD", title: "Psoralens for topical use" },
  { id: "D05AD01", title: "trioxysalen" },
  { id: "D05AD02", title: "methoxsalen" },
  { id: "D05AX", title: "Other antipsoriatics for topical use" },
  { id: "D05AX01", title: "fumaric acid" },
  { id: "D05AX02", title: "calcipotriol" },
  { id: "D05AX03", title: "calcitriol" },
  { id: "D05AX04", title: "tacalcitol" },
  { id: "D05AX05", title: "tazarotene" },
  { id: "D05AX52", title: "calcipotriol, combinations" },
  { id: "D05B", title: "ANTIPSORIATICS FOR SYSTEMIC USE" },
  { id: "D05BA", title: "Psoralens for systemic use" },
  { id: "D05BA01", title: "trioxysalen" },
  { id: "D05BA02", title: "methoxsalen" },
  { id: "D05BA03", title: "bergapten" },
  { id: "D05BB", title: "Retinoids for treatment of psoriasis" },
  { id: "D05BB01", title: "etretinate" },
  { id: "D05BB02", title: "acitretin" },
  { id: "D05BX", title: "Other antipsoriatics for systemic use" },
  { id: "D05BX51", title: "fumaric acid derivatives, combinations" },
  {
    id: "D06",
    title: "ANTIBIOTICS AND CHEMOTHERAPEUTICS FOR DERMATOLOGICAL USE",
  },
  { id: "D06A", title: "ANTIBIOTICS FOR TOPICAL USE" },
  { id: "D06AA", title: "Tetracycline and derivatives" },
  { id: "D06AA01", title: "demeclocycline" },
  { id: "D06AA02", title: "chlortetracycline" },
  { id: "D06AA03", title: "oxytetracycline" },
  { id: "D06AA04", title: "tetracycline" },
  { id: "D06AX", title: "Other antibiotics for topical use" },
  { id: "D06AX01", title: "fusidic acid" },
  { id: "D06AX02", title: "chloramphenicol" },
  { id: "D06AX04", title: "neomycin" },
  { id: "D06AX05", title: "bacitracin" },
  { id: "D06AX07", title: "gentamicin" },
  { id: "D06AX08", title: "tyrothricin" },
  { id: "D06AX09", title: "mupirocin" },
  { id: "D06AX10", title: "virginiamycin" },
  { id: "D06AX11", title: "rifaximin" },
  { id: "D06AX12", title: "amikacin" },
  { id: "D06AX13", title: "retapamulin" },
  { id: "D06B", title: "CHEMOTHERAPEUTICS FOR TOPICAL USE" },
  { id: "D06BA", title: "Sulfonamides" },
  { id: "D06BA01", title: "silver sulfadiazine" },
  { id: "D06BA02", title: "sulfathiazole" },
  { id: "D06BA03", title: "mafenide" },
  { id: "D06BA04", title: "sulfamethizole" },
  { id: "D06BA05", title: "sulfanilamide" },
  { id: "D06BA06", title: "sulfamerazine" },
  { id: "D06BA51", title: "silver sulfadiazine, combinations" },
  { id: "D06BB", title: "Antivirals" },
  { id: "D06BB01", title: "idoxuridine" },
  { id: "D06BB02", title: "tromantadine" },
  { id: "D06BB03", title: "aciclovir" },
  { id: "D06BB04", title: "podophyllotoxin" },
  { id: "D06BB05", title: "inosine" },
  { id: "D06BB06", title: "penciclovir" },
  { id: "D06BB07", title: "lysozyme" },
  { id: "D06BB08", title: "ibacitabine" },
  { id: "D06BB09", title: "edoxudine" },
  { id: "D06BB10", title: "imiquimod" },
  { id: "D06BB11", title: "docosanol" },
  { id: "D06BB12", title: "sinecatechins" },
  { id: "D06BB53", title: "aciclovir, combinations" },
  { id: "D06BX", title: "Other chemotherapeutics" },
  { id: "D06BX01", title: "metronidazole" },
  { id: "D06BX02", title: "ingenol mebutate" },
  { id: "D06C", title: "ANTIBIOTICS AND CHEMOTHERAPEUTICS, COMBINATIONS" },
  { id: "D07", title: "CORTICOSTEROIDS, DERMATOLOGICAL PREPARATIONS" },
  { id: "D07A", title: "CORTICOSTEROIDS, PLAIN" },
  { id: "D07AA", title: "Corticosteroids, weak (group I)" },
  { id: "D07AA01", title: "methylprednisolone" },
  { id: "D07AA02", title: "hydrocortisone" },
  { id: "D07AA03", title: "prednisolone" },
  { id: "D07AB", title: "Corticosteroids, moderately potent (group II)" },
  { id: "D07AB01", title: "clobetasone" },
  { id: "D07AB02", title: "hydrocortisone butyrate" },
  { id: "D07AB03", title: "flumetasone" },
  { id: "D07AB04", title: "fluocortin" },
  { id: "D07AB05", title: "fluperolone" },
  { id: "D07AB06", title: "fluorometholone" },
  { id: "D07AB07", title: "fluprednidene" },
  { id: "D07AB08", title: "desonide" },
  { id: "D07AB09", title: "triamcinolone" },
  { id: "D07AB10", title: "alclometasone" },
  { id: "D07AB11", title: "hydrocortisone buteprate" },
  { id: "D07AB19", title: "dexamethasone" },
  { id: "D07AB21", title: "clocortolone" },
  { id: "D07AB30", title: "combinations of corticosteroids" },
  { id: "D07AC", title: "Corticosteroids, potent (group III)" },
  { id: "D07AC01", title: "betamethasone" },
  { id: "D07AC02", title: "fluclorolone" },
  { id: "D07AC03", title: "desoximetasone" },
  { id: "D07AC04", title: "fluocinolone acetonide" },
  { id: "D07AC05", title: "fluocortolone" },
  { id: "D07AC06", title: "diflucortolone" },
  { id: "D07AC07", title: "fludroxycortide" },
  { id: "D07AC08", title: "fluocinonide" },
  { id: "D07AC09", title: "budesonide" },
  { id: "D07AC10", title: "diflorasone" },
  { id: "D07AC11", title: "amcinonide" },
  { id: "D07AC12", title: "halometasone" },
  { id: "D07AC13", title: "mometasone" },
  { id: "D07AC14", title: "methylprednisolone aceponate" },
  { id: "D07AC15", title: "beclometasone" },
  { id: "D07AC16", title: "hydrocortisone aceponate" },
  { id: "D07AC17", title: "fluticasone" },
  { id: "D07AC18", title: "prednicarbate" },
  { id: "D07AC19", title: "difluprednate" },
  { id: "D07AC21", title: "ulobetasol" },
  { id: "D07AD", title: "Corticosteroids, very potent (group IV)" },
  { id: "D07AD01", title: "clobetasol" },
  { id: "D07AD02", title: "halcinonide" },
  { id: "D07B", title: "CORTICOSTEROIDS, COMBINATIONS WITH ANTISEPTICS" },
  {
    id: "D07BA",
    title: "Corticosteroids, weak, combinations with antiseptics",
  },
  { id: "D07BA01", title: "prednisolone and antiseptics" },
  { id: "D07BA04", title: "hydrocortisone and antiseptics" },
  {
    id: "D07BB",
    title: "Corticosteroids, moderately potent, combinations with antiseptics",
  },
  { id: "D07BB01", title: "flumetasone and antiseptics" },
  { id: "D07BB02", title: "desonide and antiseptics" },
  { id: "D07BB03", title: "triamcinolone and antiseptics" },
  { id: "D07BB04", title: "hydrocortisone butyrate and antiseptics" },
  {
    id: "D07BC",
    title: "Corticosteroids, potent, combinations with antiseptics",
  },
  { id: "D07BC01", title: "betamethasone and antiseptics" },
  { id: "D07BC02", title: "fluocinolone acetonide and antiseptics" },
  { id: "D07BC03", title: "fluocortolone and antiseptics" },
  { id: "D07BC04", title: "diflucortolone and antiseptics" },
  {
    id: "D07BD",
    title: "Corticosteroids, very potent, combinations with antiseptics",
  },
  { id: "D07C", title: "CORTICOSTEROIDS, COMBINATIONS WITH ANTIBIOTICS" },
  {
    id: "D07CA",
    title: "Corticosteroids, weak, combinations with antibiotics",
  },
  { id: "D07CA01", title: "hydrocortisone and antibiotics" },
  { id: "D07CA02", title: "methylprednisolone and antibiotics" },
  { id: "D07CA03", title: "prednisolone and antibiotics" },
  {
    id: "D07CB",
    title: "Corticosteroids, moderately potent, combinations with antibiotics",
  },
  { id: "D07CB01", title: "triamcinolone and antibiotics" },
  { id: "D07CB02", title: "fluprednidene and antibiotics" },
  { id: "D07CB03", title: "fluorometholone and antibiotics" },
  { id: "D07CB04", title: "dexamethasone and antibiotics" },
  { id: "D07CB05", title: "flumetasone and antibiotics" },
  {
    id: "D07CC",
    title: "Corticosteroids, potent, combinations with antibiotics",
  },
  { id: "D07CC01", title: "betamethasone and antibiotics" },
  { id: "D07CC02", title: "fluocinolone acetonide and antibiotics" },
  { id: "D07CC03", title: "fludroxycortide and antibiotics" },
  { id: "D07CC04", title: "beclometasone and antibiotics" },
  { id: "D07CC05", title: "fluocinonide and antibiotics" },
  { id: "D07CC06", title: "fluocortolone and antibiotics" },
  {
    id: "D07CD",
    title: "Corticosteroids, very potent, combinations with antibiotics",
  },
  { id: "D07CD01", title: "clobetasol and antibiotics" },
  { id: "D07X", title: "CORTICOSTEROIDS, OTHER COMBINATIONS" },
  { id: "D07XA", title: "Corticosteroids, weak, other combinations" },
  { id: "D07XA01", title: "hydrocortisone" },
  { id: "D07XA02", title: "prednisolone" },
  {
    id: "D07XB",
    title: "Corticosteroids, moderately potent, other combinations",
  },
  { id: "D07XB01", title: "flumetasone" },
  { id: "D07XB02", title: "triamcinolone" },
  { id: "D07XB03", title: "fluprednidene" },
  { id: "D07XB04", title: "fluorometholone" },
  { id: "D07XB05", title: "dexamethasone" },
  { id: "D07XB30", title: "combinations of corticosteroids" },
  { id: "D07XC", title: "Corticosteroids, potent, other combinations" },
  { id: "D07XC01", title: "betamethasone" },
  { id: "D07XC02", title: "desoximetasone" },
  { id: "D07XC03", title: "mometasone" },
  { id: "D07XC04", title: "diflucortolone" },
  {
    id: "D07XD",
    title: "Corticosteroids, very potent, other combinations",
  },
  { id: "D08", title: "ANTISEPTICS AND DISINFECTANTS" },
  { id: "D08A", title: "ANTISEPTICS AND DISINFECTANTS" },
  { id: "D08AA", title: "Acridine derivatives" },
  { id: "D08AA01", title: "ethacridine lactate" },
  { id: "D08AA02", title: "aminoacridine" },
  { id: "D08AA03", title: "euflavine" },
  { id: "D08AB", title: "Aluminium agents" },
  { id: "D08AC", title: "Biguanides and amidines" },
  { id: "D08AC01", title: "dibrompropamidine" },
  { id: "D08AC02", title: "chlorhexidine" },
  { id: "D08AC03", title: "propamidine" },
  { id: "D08AC04", title: "hexamidine" },
  { id: "D08AC05", title: "polihexanide" },
  { id: "D08AC52", title: "chlorhexidine, combinations" },
  { id: "D08AD", title: "Boric acid products" },
  { id: "D08AE", title: "Phenol and derivatives" },
  { id: "D08AE01", title: "hexachlorophene" },
  { id: "D08AE02", title: "policresulen" },
  { id: "D08AE03", title: "phenol" },
  { id: "D08AE04", title: "triclosan" },
  { id: "D08AE05", title: "chloroxylenol" },
  { id: "D08AE06", title: "biphenylol" },
  { id: "D08AF", title: "Nitrofuran derivatives" },
  { id: "D08AF01", title: "nitrofural" },
  { id: "D08AG", title: "Iodine products" },
  { id: "D08AG01", title: "iodine/octylphenoxypolyglycolether" },
  { id: "D08AG02", title: "povidone-iodine" },
  { id: "D08AG03", title: "iodine" },
  { id: "D08AG04", title: "diiodohydroxypropane" },
  { id: "D08AH", title: "Quinoline derivatives" },
  { id: "D08AH01", title: "dequalinium" },
  { id: "D08AH02", title: "chlorquinaldol" },
  { id: "D08AH03", title: "oxyquinoline" },
  { id: "D08AH30", title: "clioquinol" },
  { id: "D08AJ", title: "Quaternary ammonium compounds" },
  { id: "D08AJ01", title: "benzalkonium" },
  { id: "D08AJ02", title: "cetrimonium" },
  { id: "D08AJ03", title: "cetylpyridinium" },
  { id: "D08AJ04", title: "cetrimide" },
  { id: "D08AJ05", title: "benzoxonium chloride" },
  { id: "D08AJ06", title: "didecyldimethylammonium chloride" },
  { id: "D08AJ08", title: "benzethonium chloride" },
  { id: "D08AJ57", title: "octenidine, combinations" },
  { id: "D08AJ58", title: "benzethonium chloride, combinations" },
  { id: "D08AJ59", title: "dodeclonium bromide, combinations" },
  { id: "D08AK", title: "Mercurial products" },
  { id: "D08AK01", title: "mercuric amidochloride" },
  { id: "D08AK02", title: "phenylmercuric borate" },
  { id: "D08AK03", title: "mercuric chloride" },
  { id: "D08AK04", title: "mercurochrome" },
  { id: "D08AK05", title: "mercury, metallic" },
  { id: "D08AK06", title: "thiomersal" },
  { id: "D08AK30", title: "mercuric iodide" },
  { id: "D08AL", title: "Silver compounds" },
  { id: "D08AL01", title: "silver nitrate" },
  { id: "D08AL30", title: "silver" },
  { id: "D08AX", title: "Other antiseptics and disinfectants" },
  { id: "D08AX01", title: "hydrogen peroxide" },
  { id: "D08AX02", title: "eosin" },
  { id: "D08AX03", title: "propanol" },
  { id: "D08AX04", title: "tosylchloramide sodium" },
  { id: "D08AX05", title: "isopropanol" },
  { id: "D08AX06", title: "potassium permanganate" },
  { id: "D08AX07", title: "sodium hypochlorite" },
  { id: "D08AX08", title: "ethanol" },
  { id: "D08AX53", title: "propanol, combinations" },
  { id: "D09", title: "MEDICATED DRESSINGS" },
  { id: "D09A", title: "MEDICATED DRESSINGS" },
  { id: "D09AA", title: "Medicated dressings with antiinfectives" },
  { id: "D09AA01", title: "framycetin" },
  { id: "D09AA02", title: "fusidic acid" },
  { id: "D09AA03", title: "nitrofural" },
  { id: "D09AA04", title: "phenylmercuric nitrate" },
  { id: "D09AA05", title: "benzododecinium" },
  { id: "D09AA06", title: "triclosan" },
  { id: "D09AA07", title: "cetylpyridinium" },
  { id: "D09AA08", title: "aluminium chlorohydrate" },
  { id: "D09AA09", title: "povidone-iodine" },
  { id: "D09AA10", title: "clioquinol" },
  { id: "D09AA11", title: "benzalkonium" },
  { id: "D09AA12", title: "chlorhexidine" },
  { id: "D09AA13", title: "iodoform" },
  { id: "D09AB", title: "Zinc bandages" },
  { id: "D09AB01", title: "zinc bandage without supplements" },
  { id: "D09AB02", title: "zinc bandage with supplements" },
  { id: "D09AX", title: "Soft paraffin dressings" },
  { id: "D10", title: "ANTI-ACNE PREPARATIONS" },
  { id: "D10A", title: "ANTI-ACNE PREPARATIONS FOR TOPICAL USE" },
  {
    id: "D10AA",
    title: "Corticosteroids, combinations for treatment of acne",
  },
  { id: "D10AA01", title: "fluorometholone" },
  { id: "D10AA02", title: "methylprednisolone" },
  { id: "D10AA03", title: "dexamethasone" },
  { id: "D10AB", title: "Preparations containing sulfur" },
  { id: "D10AB01", title: "bithionol" },
  { id: "D10AB02", title: "sulfur" },
  { id: "D10AB03", title: "tioxolone" },
  { id: "D10AB05", title: "mesulfen" },
  { id: "D10AD", title: "Retinoids for topical use in acne" },
  { id: "D10AD01", title: "tretinoin" },
  { id: "D10AD02", title: "retinol" },
  { id: "D10AD03", title: "adapalene" },
  { id: "D10AD04", title: "isotretinoin" },
  { id: "D10AD05", title: "motretinide" },
  { id: "D10AD51", title: "tretinoin, combinations" },
  { id: "D10AD53", title: "adapalene, combinations" },
  { id: "D10AD54", title: "isotretinoin, combinations" },
  { id: "D10AE", title: "Peroxides" },
  { id: "D10AE01", title: "benzoyl peroxide" },
  { id: "D10AE51", title: "benzoyl peroxide, combinations" },
  { id: "D10AF", title: "Antiinfectives for treatment of acne" },
  { id: "D10AF01", title: "clindamycin" },
  { id: "D10AF02", title: "erythromycin" },
  { id: "D10AF03", title: "chloramphenicol" },
  { id: "D10AF04", title: "meclocycline" },
  { id: "D10AF51", title: "clindamycin, combinations" },
  { id: "D10AF52", title: "erythromycin, combinations" },
  { id: "D10AX", title: "Other anti-acne preparations for topical use" },
  { id: "D10AX01", title: "aluminium chloride" },
  { id: "D10AX02", title: "resorcinol" },
  { id: "D10AX03", title: "azelaic acid" },
  { id: "D10AX04", title: "aluminium oxide" },
  { id: "D10AX05", title: "dapsone" },
  { id: "D10AX30", title: "various combinations" },
  { id: "D10B", title: "ANTI-ACNE PREPARATIONS FOR SYSTEMIC USE" },
  { id: "D10BA", title: "Retinoids for treatment of acne" },
  { id: "D10BA01", title: "isotretinoin" },
  { id: "D10BX", title: "Other anti-acne preparations for systemic use" },
  { id: "D10BX01", title: "ichtasol" },
  { id: "D11", title: "OTHER DERMATOLOGICAL PREPARATIONS" },
  { id: "D11A", title: "OTHER DERMATOLOGICAL PREPARATIONS" },
  { id: "D11AA", title: "Antihidrotics" },
  { id: "D11AC", title: "Medicated shampoos" },
  { id: "D11AC01", title: "cetrimide" },
  { id: "D11AC02", title: "cadmium compounds" },
  { id: "D11AC03", title: "selenium compounds" },
  { id: "D11AC06", title: "povidone-iodine" },
  { id: "D11AC08", title: "sulfur compounds" },
  { id: "D11AC09", title: "xenysalate" },
  { id: "D11AC30", title: "others" },
  { id: "D11AE", title: "Androgens for topical use" },
  { id: "D11AE01", title: "metandienone" },
  { id: "D11AF", title: "Wart and anti-corn preparations" },
  {
    id: "D11AH",
    title: "Agents for dermatitis, excluding corticosteroids",
  },
  { id: "D11AH01", title: "tacrolimus" },
  { id: "D11AH02", title: "pimecrolimus" },
  { id: "D11AH03", title: "cromoglicic acid" },
  { id: "D11AH04", title: "alitretinoin" },
  { id: "D11AX", title: "Other dermatologicals" },
  { id: "D11AX01", title: "minoxidil" },
  { id: "D11AX02", title: "gamolenic acid" },
  { id: "D11AX03", title: "calcium gluconate" },
  { id: "D11AX04", title: "lithium succinate" },
  { id: "D11AX05", title: "magnesium sulfate" },
  { id: "D11AX06", title: "mequinol" },
  { id: "D11AX08", title: "tiratricol" },
  { id: "D11AX09", title: "oxaceprol" },
  { id: "D11AX10", title: "finasteride" },
  { id: "D11AX11", title: "hydroquinone" },
  { id: "D11AX12", title: "pyrithione zinc" },
  { id: "D11AX13", title: "monobenzone" },
  { id: "D11AX16", title: "eflornithine" },
  { id: "D11AX18", title: "diclofenac" },
  { id: "D11AX21", title: "brimonidine" },
  { id: "D11AX52", title: "gamolenic acid, combinations" },
  { id: "D11AX57", title: "collagen, combinations" },
  { id: "G", title: "GENITO URINARY SYSTEM AND SEX HORMONES" },
  { id: "G01", title: "GYNECOLOGICAL ANTIINFECTIVES AND ANTISEPTICS" },
  {
    id: "G01A",
    title:
      "ANTIINFECTIVES AND ANTISEPTICS, EXCL. COMBINATIONS WITH CORTICOSTEROIDS",
  },
  { id: "G01AA", title: "Antibiotics" },
  { id: "G01AA01", title: "nystatin" },
  { id: "G01AA02", title: "natamycin" },
  { id: "G01AA03", title: "amphotericin B" },
  { id: "G01AA04", title: "candicidin" },
  { id: "G01AA05", title: "chloramphenicol" },
  { id: "G01AA06", title: "hachimycin" },
  { id: "G01AA07", title: "oxytetracycline" },
  { id: "G01AA08", title: "carfecillin" },
  { id: "G01AA09", title: "mepartricin" },
  { id: "G01AA10", title: "clindamycin" },
  { id: "G01AA11", title: "pentamycin" },
  { id: "G01AA51", title: "nystatin, combinations" },
  { id: "G01AB", title: "Arsenic compounds" },
  { id: "G01AB01", title: "acetarsol" },
  { id: "G01AC", title: "Quinoline derivatives" },
  { id: "G01AC01", title: "diiodohydroxyquinoline" },
  { id: "G01AC02", title: "clioquinol" },
  { id: "G01AC03", title: "chlorquinaldol" },
  { id: "G01AC05", title: "dequalinium" },
  { id: "G01AC06", title: "broxyquinoline" },
  { id: "G01AC30", title: "oxyquinoline" },
  { id: "G01AD", title: "Organic acids" },
  { id: "G01AD01", title: "lactic acid" },
  { id: "G01AD02", title: "acetic acid" },
  { id: "G01AD03", title: "ascorbic acid" },
  { id: "G01AE", title: "Sulfonamides" },
  { id: "G01AE01", title: "sulfatolamide" },
  { id: "G01AE10", title: "combinations of sulfonamides" },
  { id: "G01AF", title: "Imidazole derivatives" },
  { id: "G01AF01", title: "metronidazole" },
  { id: "G01AF02", title: "clotrimazole" },
  { id: "G01AF04", title: "miconazole" },
  { id: "G01AF05", title: "econazole" },
  { id: "G01AF06", title: "ornidazole" },
  { id: "G01AF07", title: "isoconazole" },
  { id: "G01AF08", title: "tioconazole" },
  { id: "G01AF11", title: "ketoconazole" },
  { id: "G01AF12", title: "fenticonazole" },
  { id: "G01AF13", title: "azanidazole" },
  { id: "G01AF14", title: "propenidazole" },
  { id: "G01AF15", title: "butoconazole" },
  { id: "G01AF16", title: "omoconazole" },
  { id: "G01AF17", title: "oxiconazole" },
  { id: "G01AF18", title: "flutrimazole" },
  { id: "G01AF20", title: "combinations of imidazole derivatives" },
  { id: "G01AG", title: "Triazole derivatives" },
  { id: "G01AG02", title: "terconazole" },
  { id: "G01AX", title: "Other antiinfectives and antiseptics" },
  { id: "G01AX01", title: "clodantoin" },
  { id: "G01AX02", title: "inosine" },
  { id: "G01AX03", title: "policresulen" },
  { id: "G01AX05", title: "nifuratel" },
  { id: "G01AX06", title: "furazolidone" },
  { id: "G01AX09", title: "methylrosaniline" },
  { id: "G01AX11", title: "povidone-iodine" },
  { id: "G01AX12", title: "ciclopirox" },
  { id: "G01AX13", title: "protiofate" },
  { id: "G01AX14", title: "lactobacillus fermentum" },
  { id: "G01AX15", title: "copper usnate" },
  { id: "G01AX66", title: "octenidine, combinations" },
  {
    id: "G01B",
    title: "ANTIINFECTIVES/ANTISEPTICS IN COMBINATION WITH CORTICOSTEROIDS",
  },
  { id: "G01BA", title: "Antibiotics and corticosteroids" },
  { id: "G01BC", title: "Quinoline derivatives and corticosteroids" },
  { id: "G01BD", title: "Antiseptics and corticosteroids" },
  { id: "G01BE", title: "Sulfonamides and corticosteroids" },
  { id: "G01BF", title: "Imidazole derivatives and corticosteroids" },
  { id: "G02", title: "OTHER GYNECOLOGICALS" },
  { id: "G02A", title: "UTEROTONICS" },
  { id: "G02AB", title: "Ergot alkaloids" },
  { id: "G02AB01", title: "methylergometrine" },
  { id: "G02AB02", title: "ergot alkaloids" },
  { id: "G02AB03", title: "ergometrine" },
  {
    id: "G02AC",
    title: "Ergot alkaloids and oxytocin incl. analogues, in combination",
  },
  { id: "G02AC01", title: "methylergometrine and oxytocin" },
  { id: "G02AD", title: "Prostaglandins" },
  { id: "G02AD01", title: "dinoprost" },
  { id: "G02AD02", title: "dinoprostone" },
  { id: "G02AD03", title: "gemeprost" },
  { id: "G02AD04", title: "carboprost" },
  { id: "G02AD05", title: "sulprostone" },
  { id: "G02AD06", title: "misoprostol" },
  { id: "G02AX", title: "Other uterotonics" },
  { id: "G02B", title: "CONTRACEPTIVES FOR TOPICAL USE" },
  { id: "G02BA", title: "Intrauterine contraceptives" },
  { id: "G02BA01", title: "plastic IUD" },
  { id: "G02BA02", title: "plastic IUD with copper" },
  { id: "G02BA03", title: "plastic IUD with progestogen" },
  { id: "G02BB", title: "Intravaginal contraceptives" },
  { id: "G02BB01", title: "vaginal ring with progestogen and estrogen" },
  { id: "G02C", title: "OTHER GYNECOLOGICALS" },
  { id: "G02CA", title: "Sympathomimetics, labour repressants" },
  { id: "G02CA01", title: "ritodrine" },
  { id: "G02CA02", title: "buphenine" },
  { id: "G02CA03", title: "fenoterol" },
  { id: "G02CB", title: "Prolactine inhibitors" },
  { id: "G02CB01", title: "bromocriptine" },
  { id: "G02CB02", title: "lisuride" },
  { id: "G02CB03", title: "cabergoline" },
  { id: "G02CB04", title: "quinagolide" },
  { id: "G02CB05", title: "metergoline" },
  { id: "G02CB06", title: "terguride" },
  {
    id: "G02CC",
    title: "Antiinflammatory products for vaginal administration",
  },
  { id: "G02CC01", title: "ibuprofen" },
  { id: "G02CC02", title: "naproxen" },
  { id: "G02CC03", title: "benzydamine" },
  { id: "G02CC04", title: "flunoxaprofen" },
  { id: "G02CX", title: "Other gynecologicals" },
  { id: "G02CX01", title: "atosiban" },
  { id: "G02CX03", title: "Agni casti fructus" },
  { id: "G02CX04", title: "Cimicifugae rhizoma" },
  { id: "G03", title: "SEX HORMONES AND MODULATORS OF THE GENITAL SYSTEM" },
  { id: "G03A", title: "HORMONAL CONTRACEPTIVES FOR SYSTEMIC USE" },
  { id: "G03AA", title: "Progestogens and estrogens, fixed combinations" },
  { id: "G03AA01", title: "etynodiol and ethinylestradiol" },
  { id: "G03AA02", title: "quingestanol and ethinylestradiol" },
  { id: "G03AA03", title: "lynestrenol and ethinylestradiol" },
  { id: "G03AA04", title: "megestrol and ethinylestradiol" },
  { id: "G03AA05", title: "norethisterone and ethinylestradiol" },
  { id: "G03AA06", title: "norgestrel and ethinylestradiol" },
  { id: "G03AA07", title: "levonorgestrel and ethinylestradiol" },
  { id: "G03AA08", title: "medroxyprogesterone and ethinylestradiol" },
  { id: "G03AA09", title: "desogestrel and ethinylestradiol" },
  { id: "G03AA10", title: "gestodene and ethinylestradiol" },
  { id: "G03AA11", title: "norgestimate and ethinylestradiol" },
  { id: "G03AA12", title: "drospirenone and ethinylestradiol" },
  { id: "G03AA13", title: "norelgestromin and ethinylestradiol" },
  { id: "G03AA14", title: "nomegestrol and estradiol" },
  { id: "G03AA15", title: "chlormadinone and ethinylestradiol" },
  { id: "G03AA16", title: "dienogest and ethinylestradiol" },
  {
    id: "G03AB",
    title: "Progestogens and estrogens, sequential preparations",
  },
  { id: "G03AB01", title: "megestrol and ethinylestradiol" },
  { id: "G03AB02", title: "lynestrenol and ethinylestradiol" },
  { id: "G03AB03", title: "levonorgestrel and ethinylestradiol" },
  { id: "G03AB04", title: "norethisterone and ethinylestradiol" },
  { id: "G03AB05", title: "desogestrel and ethinylestradiol" },
  { id: "G03AB06", title: "gestodene and ethinylestradiol" },
  { id: "G03AB07", title: "chlormadinone and ethinylestradiol" },
  { id: "G03AB08", title: "dienogest and estradiol" },
  { id: "G03AC", title: "Progestogens" },
  { id: "G03AC01", title: "norethisterone" },
  { id: "G03AC02", title: "lynestrenol" },
  { id: "G03AC03", title: "levonorgestrel" },
  { id: "G03AC04", title: "quingestanol" },
  { id: "G03AC05", title: "megestrol" },
  { id: "G03AC06", title: "medroxyprogesterone" },
  { id: "G03AC07", title: "norgestrienone" },
  { id: "G03AC08", title: "etonogestrel" },
  { id: "G03AC09", title: "desogestrel" },
  { id: "G03AD", title: "Emergency contraceptives" },
  { id: "G03AD01", title: "levonorgestrel" },
  { id: "G03AD02", title: "ulipristal" },
  { id: "G03B", title: "ANDROGENS" },
  { id: "G03BA", title: "3-oxoandrosten (4) derivatives" },
  { id: "G03BA01", title: "fluoxymesterone" },
  { id: "G03BA02", title: "methyltestosterone" },
  { id: "G03BA03", title: "testosterone" },
  { id: "G03BB", title: "5-androstanon (3) derivatives" },
  { id: "G03BB01", title: "mesterolone" },
  { id: "G03BB02", title: "androstanolone" },
  { id: "G03C", title: "ESTROGENS" },
  { id: "G03CA", title: "Natural and semisynthetic estrogens, plain" },
  { id: "G03CA01", title: "ethinylestradiol" },
  { id: "G03CA03", title: "estradiol" },
  { id: "G03CA04", title: "estriol" },
  { id: "G03CA06", title: "chlorotrianisene" },
  { id: "G03CA07", title: "estrone" },
  { id: "G03CA09", title: "promestriene" },
  { id: "G03CA53", title: "estradiol, combinations" },
  { id: "G03CA57", title: "conjugated estrogens" },
  { id: "G03CB", title: "Synthetic estrogens, plain" },
  { id: "G03CB01", title: "dienestrol" },
  { id: "G03CB02", title: "diethylstilbestrol" },
  { id: "G03CB03", title: "methallenestril" },
  { id: "G03CB04", title: "moxestrol" },
  { id: "G03CC", title: "Estrogens, combinations with other drugs" },
  { id: "G03CC02", title: "dienestrol" },
  { id: "G03CC03", title: "methallenestril" },
  { id: "G03CC04", title: "estrone" },
  { id: "G03CC05", title: "diethylstilbestrol" },
  { id: "G03CC06", title: "estriol" },
  { id: "G03CX", title: "Other estrogens" },
  { id: "G03CX01", title: "tibolone" },
  { id: "G03D", title: "PROGESTOGENS" },
  { id: "G03DA", title: "Pregnen (4) derivatives" },
  { id: "G03DA01", title: "gestonorone" },
  { id: "G03DA02", title: "medroxyprogesterone" },
  { id: "G03DA03", title: "hydroxyprogesterone" },
  { id: "G03DA04", title: "progesterone" },
  { id: "G03DB", title: "Pregnadien derivatives" },
  { id: "G03DB01", title: "dydrogesterone" },
  { id: "G03DB02", title: "megestrol" },
  { id: "G03DB03", title: "medrogestone" },
  { id: "G03DB04", title: "nomegestrol" },
  { id: "G03DB05", title: "demegestone" },
  { id: "G03DB06", title: "chlormadinone" },
  { id: "G03DB07", title: "promegestone" },
  { id: "G03DB08", title: "dienogest" },
  { id: "G03DC", title: "Estren derivatives" },
  { id: "G03DC01", title: "allylestrenol" },
  { id: "G03DC02", title: "norethisterone" },
  { id: "G03DC03", title: "lynestrenol" },
  { id: "G03DC04", title: "ethisterone" },
  { id: "G03DC06", title: "etynodiol" },
  { id: "G03DC31", title: "methylestrenolone" },
  { id: "G03E", title: "ANDROGENS AND FEMALE SEX HORMONES IN COMBINATION" },
  { id: "G03EA", title: "Androgens and estrogens" },
  { id: "G03EA01", title: "methyltestosterone and estrogen" },
  { id: "G03EA02", title: "testosterone and estrogen" },
  { id: "G03EA03", title: "prasterone and estrogen" },
  {
    id: "G03EB",
    title: "Androgen, progestogen and estrogen in combination",
  },
  {
    id: "G03EK",
    title: "Androgens and female sex hormones in combination with other drugs",
  },
  { id: "G03EK01", title: "methyltestosterone" },
  { id: "G03F", title: "PROGESTOGENS AND ESTROGENS IN COMBINATION" },
  { id: "G03FA", title: "Progestogens and estrogens, fixed combinations" },
  { id: "G03FA01", title: "norethisterone and estrogen" },
  { id: "G03FA02", title: "hydroxyprogesterone and estrogen" },
  { id: "G03FA03", title: "ethisterone and estrogen" },
  { id: "G03FA04", title: "progesterone and estrogen" },
  { id: "G03FA05", title: "methylnortestosterone and estrogen" },
  { id: "G03FA06", title: "etynodiol and estrogen" },
  { id: "G03FA07", title: "lynestrenol and estrogen" },
  { id: "G03FA08", title: "megestrol and estrogen" },
  { id: "G03FA09", title: "noretynodrel and estrogen" },
  { id: "G03FA10", title: "norgestrel and estrogen" },
  { id: "G03FA11", title: "levonorgestrel and estrogen" },
  { id: "G03FA12", title: "medroxyprogesterone and estrogen" },
  { id: "G03FA13", title: "norgestimate and estrogen" },
  { id: "G03FA14", title: "dydrogesterone and estrogen" },
  { id: "G03FA15", title: "dienogest and estrogen" },
  { id: "G03FA16", title: "trimegestone and estrogen" },
  { id: "G03FA17", title: "drospirenone and estrogen" },
  {
    id: "G03FB",
    title: "Progestogens and estrogens, sequential preparations",
  },
  { id: "G03FB01", title: "norgestrel and estrogen" },
  { id: "G03FB02", title: "lynestrenol and estrogen" },
  { id: "G03FB03", title: "chlormadinone and estrogen" },
  { id: "G03FB04", title: "megestrol and estrogen" },
  { id: "G03FB05", title: "norethisterone and estrogen" },
  { id: "G03FB06", title: "medroxyprogesterone and estrogen" },
  { id: "G03FB07", title: "medrogestone and estrogen" },
  { id: "G03FB08", title: "dydrogesterone and estrogen" },
  { id: "G03FB09", title: "levonorgestrel and estrogen" },
  { id: "G03FB10", title: "desogestrel and estrogen" },
  { id: "G03FB11", title: "trimegestone and estrogen" },
  { id: "G03FB12", title: "nomegestrol and estrogen" },
  { id: "G03G", title: "GONADOTROPINS AND OTHER OVULATION STIMULANTS" },
  { id: "G03GA", title: "Gonadotropins" },
  { id: "G03GA01", title: "chorionic gonadotrophin" },
  { id: "G03GA02", title: "human menopausal gonadotrophin" },
  { id: "G03GA03", title: "serum gonadotrophin" },
  { id: "G03GA04", title: "urofollitropin" },
  { id: "G03GA05", title: "follitropin alfa" },
  { id: "G03GA06", title: "follitropin beta" },
  { id: "G03GA07", title: "lutropin alfa" },
  { id: "G03GA08", title: "choriogonadotropin alfa" },
  { id: "G03GA09", title: "corifollitropin alfa" },
  { id: "G03GA30", title: "combinations" },
  { id: "G03GB", title: "Ovulation stimulants, synthetic" },
  { id: "G03GB01", title: "cyclofenil" },
  { id: "G03GB02", title: "clomifene" },
  { id: "G03GB03", title: "epimestrol" },
  { id: "G03H", title: "ANTIANDROGENS" },
  { id: "G03HA", title: "Antiandrogens, plain" },
  { id: "G03HA01", title: "cyproterone" },
  { id: "G03HB", title: "Antiandrogens and estrogens" },
  { id: "G03HB01", title: "cyproterone and estrogen" },
  {
    id: "G03X",
    title: "OTHER SEX HORMONES AND MODULATORS OF THE GENITAL SYSTEM",
  },
  { id: "G03XA", title: "Antigonadotropins and similar agents" },
  { id: "G03XA01", title: "danazol" },
  { id: "G03XA02", title: "gestrinone" },
  { id: "G03XB", title: "Progesterone receptor modulators" },
  { id: "G03XB01", title: "mifepristone" },
  { id: "G03XB02", title: "ulipristal" },
  { id: "G03XC", title: "Selective estrogen receptor modulators" },
  { id: "G03XC01", title: "raloxifene" },
  { id: "G03XC02", title: "bazedoxifene" },
  { id: "G03XC03", title: "lasofoxifene" },
  { id: "G03XC04", title: "ormeloxifene" },
  { id: "G03XC05", title: "ospemifene" },
  { id: "G04", title: "UROLOGICALS" },
  { id: "G04B", title: "UROLOGICALS" },
  { id: "G04BA", title: "Acidifiers" },
  { id: "G04BA01", title: "ammonium chloride" },
  { id: "G04BA03", title: "calcium chloride" },
  { id: "G04BC", title: "Urinary concrement solvents" },
  { id: "G04BD", title: "Drugs for urinary frequency and incontinence" },
  { id: "G04BD01", title: "emepronium" },
  { id: "G04BD02", title: "flavoxate" },
  { id: "G04BD03", title: "meladrazine" },
  { id: "G04BD04", title: "oxybutynin" },
  { id: "G04BD05", title: "terodiline" },
  { id: "G04BD06", title: "propiverine" },
  { id: "G04BD07", title: "tolterodine" },
  { id: "G04BD08", title: "solifenacin" },
  { id: "G04BD09", title: "trospium" },
  { id: "G04BD10", title: "darifenacin" },
  { id: "G04BD11", title: "fesoterodine" },
  { id: "G04BD12", title: "mirabegron" },
  { id: "G04BE", title: "Drugs used in erectile dysfunction" },
  { id: "G04BE01", title: "alprostadil" },
  { id: "G04BE02", title: "papaverine" },
  { id: "G04BE03", title: "sildenafil" },
  { id: "G04BE04", title: "yohimbine" },
  { id: "G04BE06", title: "moxisylyte" },
  { id: "G04BE07", title: "apomorphine" },
  { id: "G04BE08", title: "tadalafil" },
  { id: "G04BE09", title: "vardenafil" },
  { id: "G04BE30", title: "combinations" },
  { id: "G04BE52", title: "papaverine, combinations" },
  { id: "G04BX", title: "Other urologicals" },
  { id: "G04BX01", title: "magnesium hydroxide" },
  { id: "G04BX03", title: "acetohydroxamic acid" },
  { id: "G04BX06", title: "phenazopyridine" },
  { id: "G04BX10", title: "succinimide" },
  { id: "G04BX11", title: "collagen" },
  { id: "G04BX12", title: "phenyl salicylate" },
  { id: "G04BX13", title: "dimethyl sulfoxide" },
  { id: "G04BX14", title: "dapoxetine" },
  { id: "G04BX15", title: "pentosan polysulfate sodium" },
  { id: "G04C", title: "DRUGS USED IN BENIGN PROSTATIC HYPERTROPHY" },
  { id: "G04CA", title: "Alpha-adrenoreceptor antagonists" },
  { id: "G04CA01", title: "alfuzosin" },
  { id: "G04CA02", title: "tamsulosin" },
  { id: "G04CA03", title: "terazosin" },
  { id: "G04CA04", title: "silodosin" },
  { id: "G04CA51", title: "alfuzosin and finasteride" },
  { id: "G04CA52", title: "tamsulosin and dutasteride" },
  { id: "G04CA53", title: "tamsulosin and solifenacin" },
  { id: "G04CB", title: "Testosterone-5-alpha reductase inhibitors" },
  { id: "G04CB01", title: "finasteride" },
  { id: "G04CB02", title: "dutasteride" },
  {
    id: "G04CX",
    title: "Other drugs used in benign prostatic hypertrophy",
  },
  { id: "G04CX01", title: "Prunus africanae cortex" },
  { id: "G04CX02", title: "Sabalis serrulatae fructus" },
  { id: "G04CX03", title: "mepartricin" },
  {
    id: "H",
    title: "SYSTEMIC HORMONAL PREPARATIONS, EXCL. SEX HORMONES AND INSULINS",
  },
  { id: "H01", title: "PITUITARY AND HYPOTHALAMIC HORMONES AND ANALOGUES" },
  { id: "H01A", title: "ANTERIOR PITUITARY LOBE HORMONES AND ANALOGUES" },
  { id: "H01AA", title: "ACTH" },
  { id: "H01AA01", title: "corticotropin" },
  { id: "H01AA02", title: "tetracosactide" },
  { id: "H01AB", title: "Thyrotropin" },
  { id: "H01AB01", title: "thyrotropin alfa" },
  { id: "H01AC", title: "Somatropin and somatropin agonists" },
  { id: "H01AC01", title: "somatropin" },
  { id: "H01AC02", title: "somatrem" },
  { id: "H01AC03", title: "mecasermin" },
  { id: "H01AC04", title: "sermorelin" },
  { id: "H01AC05", title: "mecasermin rinfabate" },
  { id: "H01AC06", title: "tesamorelin" },
  {
    id: "H01AX",
    title: "Other anterior pituitary lobe hormones and analogues",
  },
  { id: "H01AX01", title: "pegvisomant" },
  { id: "H01B", title: "POSTERIOR PITUITARY LOBE HORMONES" },
  { id: "H01BA", title: "Vasopressin and analogues" },
  { id: "H01BA01", title: "vasopressin" },
  { id: "H01BA02", title: "desmopressin" },
  { id: "H01BA03", title: "lypressin" },
  { id: "H01BA04", title: "terlipressin" },
  { id: "H01BA05", title: "ornipressin" },
  { id: "H01BA06", title: "argipressin" },
  { id: "H01BB", title: "Oxytocin and analogues" },
  { id: "H01BB01", title: "demoxytocin" },
  { id: "H01BB02", title: "oxytocin" },
  { id: "H01BB03", title: "carbetocin" },
  { id: "H01C", title: "HYPOTHALAMIC HORMONES" },
  { id: "H01CA", title: "Gonadotropin-releasing hormones" },
  { id: "H01CA01", title: "gonadorelin" },
  { id: "H01CA02", title: "nafarelin" },
  { id: "H01CB", title: "Somatostatin and analogues" },
  { id: "H01CB01", title: "somatostatin" },
  { id: "H01CB02", title: "octreotide" },
  { id: "H01CB03", title: "lanreotide" },
  { id: "H01CB04", title: "vapreotide" },
  { id: "H01CB05", title: "pasireotide" },
  { id: "H01CC", title: "Anti-gonadotropin-releasing hormones" },
  { id: "H01CC01", title: "ganirelix" },
  { id: "H01CC02", title: "cetrorelix" },
  { id: "H02", title: "CORTICOSTEROIDS FOR SYSTEMIC USE" },
  { id: "H02A", title: "CORTICOSTEROIDS FOR SYSTEMIC USE, PLAIN" },
  { id: "H02AA", title: "Mineralocorticoids" },
  { id: "H02AA01", title: "aldosterone" },
  { id: "H02AA02", title: "fludrocortisone" },
  { id: "H02AA03", title: "desoxycortone" },
  { id: "H02AB", title: "Glucocorticoids" },
  { id: "H02AB01", title: "betamethasone" },
  { id: "H02AB02", title: "dexamethasone" },
  { id: "H02AB03", title: "fluocortolone" },
  { id: "H02AB04", title: "methylprednisolone" },
  { id: "H02AB05", title: "paramethasone" },
  { id: "H02AB06", title: "prednisolone" },
  { id: "H02AB07", title: "prednisone" },
  { id: "H02AB08", title: "triamcinolone" },
  { id: "H02AB09", title: "hydrocortisone" },
  { id: "H02AB10", title: "cortisone" },
  { id: "H02AB11", title: "prednylidene" },
  { id: "H02AB12", title: "rimexolone" },
  { id: "H02AB13", title: "deflazacort" },
  { id: "H02AB14", title: "cloprednol" },
  { id: "H02AB15", title: "meprednisone" },
  { id: "H02AB17", title: "cortivazol" },
  { id: "H02B", title: "CORTICOSTEROIDS FOR SYSTEMIC USE, COMBINATIONS" },
  { id: "H02BX", title: "Corticosteroids for systemic use, combinations" },
  { id: "H02BX01", title: "methylprednisolone, combinations" },
  { id: "H02C", title: "ANTIADRENAL PREPARATIONS" },
  { id: "H02CA", title: "Anticorticosteroids" },
  { id: "H02CA01", title: "trilostane" },
  { id: "H03", title: "THYROID THERAPY" },
  { id: "H03A", title: "THYROID PREPARATIONS" },
  { id: "H03AA", title: "Thyroid hormones" },
  { id: "H03AA01", title: "levothyroxine sodium" },
  { id: "H03AA02", title: "liothyronine sodium" },
  {
    id: "H03AA03",
    title: "combinations of levothyroxine and liothyronine",
  },
  { id: "H03AA04", title: "tiratricol" },
  { id: "H03AA05", title: "thyroid gland preparations" },
  { id: "H03B", title: "ANTITHYROID PREPARATIONS" },
  { id: "H03BA", title: "Thiouracils" },
  { id: "H03BA01", title: "methylthiouracil" },
  { id: "H03BA02", title: "propylthiouracil" },
  { id: "H03BA03", title: "benzylthiouracil" },
  { id: "H03BB", title: "Sulfur-containing imidazole derivatives" },
  { id: "H03BB01", title: "carbimazole" },
  { id: "H03BB02", title: "thiamazole" },
  { id: "H03BB52", title: "thiamazole, combinations" },
  { id: "H03BC", title: "Perchlorates" },
  { id: "H03BC01", title: "potassium perchlorate" },
  { id: "H03BX", title: "Other antithyroid preparations" },
  { id: "H03BX01", title: "diiodotyrosine" },
  { id: "H03BX02", title: "dibromotyrosine" },
  { id: "H03C", title: "IODINE THERAPY" },
  { id: "H03CA", title: "Iodine therapy" },
  { id: "H04", title: "PANCREATIC HORMONES" },
  { id: "H04A", title: "GLYCOGENOLYTIC HORMONES" },
  { id: "H04AA", title: "Glycogenolytic hormones" },
  { id: "H04AA01", title: "glucagon" },
  { id: "H05", title: "CALCIUM HOMEOSTASIS" },
  { id: "H05A", title: "PARATHYROID HORMONES AND ANALOGUES" },
  { id: "H05AA", title: "Parathyroid hormones and analogues" },
  { id: "H05AA01", title: "parathyroid gland extract" },
  { id: "H05AA02", title: "teriparatide" },
  { id: "H05AA03", title: "parathyroid hormone" },
  { id: "H05B", title: "ANTI-PARATHYROID AGENTS" },
  { id: "H05BA", title: "Calcitonin preparations" },
  { id: "H05BA01", title: "calcitonin (salmon synthetic)" },
  { id: "H05BA02", title: "calcitonin (pork natural)" },
  { id: "H05BA03", title: "calcitonin (human synthetic)" },
  { id: "H05BA04", title: "elcatonin" },
  { id: "H05BX", title: "Other anti-parathyroid agents" },
  { id: "H05BX01", title: "cinacalcet" },
  { id: "H05BX02", title: "paricalcitol" },
  { id: "H05BX03", title: "doxercalciferol" },
  { id: "J", title: "ANTIINFECTIVES FOR SYSTEMIC USE" },
  { id: "J01", title: "ANTIBACTERIALS FOR SYSTEMIC USE" },
  { id: "J01A", title: "TETRACYCLINES" },
  { id: "J01AA", title: "Tetracyclines" },
  { id: "J01AA01", title: "demeclocycline" },
  { id: "J01AA02", title: "doxycycline" },
  { id: "J01AA03", title: "chlortetracycline" },
  { id: "J01AA04", title: "lymecycline" },
  { id: "J01AA05", title: "metacycline" },
  { id: "J01AA06", title: "oxytetracycline" },
  { id: "J01AA07", title: "tetracycline" },
  { id: "J01AA08", title: "minocycline" },
  { id: "J01AA09", title: "rolitetracycline" },
  { id: "J01AA10", title: "penimepicycline" },
  { id: "J01AA11", title: "clomocycline" },
  { id: "J01AA12", title: "tigecycline" },
  { id: "J01AA20", title: "combinations of tetracyclines" },
  { id: "J01AA56", title: "oxytetracycline, combinations" },
  { id: "J01B", title: "AMPHENICOLS" },
  { id: "J01BA", title: "Amphenicols" },
  { id: "J01BA01", title: "chloramphenicol" },
  { id: "J01BA02", title: "thiamphenicol" },
  { id: "J01BA52", title: "thiamphenicol, combinations" },
  { id: "J01C", title: "BETA-LACTAM ANTIBACTERIALS, PENICILLINS" },
  { id: "J01CA", title: "Penicillins with extended spectrum" },
  { id: "J01CA01", title: "ampicillin" },
  { id: "J01CA02", title: "pivampicillin" },
  { id: "J01CA03", title: "carbenicillin" },
  { id: "J01CA04", title: "amoxicillin" },
  { id: "J01CA05", title: "carindacillin" },
  { id: "J01CA06", title: "bacampicillin" },
  { id: "J01CA07", title: "epicillin" },
  { id: "J01CA08", title: "pivmecillinam" },
  { id: "J01CA09", title: "azlocillin" },
  { id: "J01CA10", title: "mezlocillin" },
  { id: "J01CA11", title: "mecillinam" },
  { id: "J01CA12", title: "piperacillin" },
  { id: "J01CA13", title: "ticarcillin" },
  { id: "J01CA14", title: "metampicillin" },
  { id: "J01CA15", title: "talampicillin" },
  { id: "J01CA16", title: "sulbenicillin" },
  { id: "J01CA17", title: "temocillin" },
  { id: "J01CA18", title: "hetacillin" },
  { id: "J01CA19", title: "aspoxicillin" },
  { id: "J01CA20", title: "combinations" },
  { id: "J01CA51", title: "ampicillin, combinations" },
  { id: "J01CE", title: "Beta-lactamase sensitive penicillins" },
  { id: "J01CE01", title: "benzylpenicillin" },
  { id: "J01CE02", title: "phenoxymethylpenicillin" },
  { id: "J01CE03", title: "propicillin" },
  { id: "J01CE04", title: "azidocillin" },
  { id: "J01CE05", title: "pheneticillin" },
  { id: "J01CE06", title: "penamecillin" },
  { id: "J01CE07", title: "clometocillin" },
  { id: "J01CE08", title: "benzathine benzylpenicillin" },
  { id: "J01CE09", title: "procaine benzylpenicillin" },
  { id: "J01CE10", title: "benzathine phenoxymethylpenicillin" },
  { id: "J01CE30", title: "combinations" },
  { id: "J01CF", title: "Beta-lactamase resistant penicillins" },
  { id: "J01CF01", title: "dicloxacillin" },
  { id: "J01CF02", title: "cloxacillin" },
  { id: "J01CF03", title: "meticillin" },
  { id: "J01CF04", title: "oxacillin" },
  { id: "J01CF05", title: "flucloxacillin" },
  { id: "J01CF06", title: "nafcillin" },
  { id: "J01CG", title: "Beta-lactamase inhibitors" },
  { id: "J01CG01", title: "sulbactam" },
  { id: "J01CG02", title: "tazobactam" },
  {
    id: "J01CR",
    title: "Combinations of penicillins, incl. beta-lactamase inhibitors",
  },
  { id: "J01CR01", title: "ampicillin and enzyme inhibitor" },
  { id: "J01CR02", title: "amoxicillin and enzyme inhibitor" },
  { id: "J01CR03", title: "ticarcillin and enzyme inhibitor" },
  { id: "J01CR04", title: "sultamicillin" },
  { id: "J01CR05", title: "piperacillin and enzyme inhibitor" },
  { id: "J01CR50", title: "combinations of penicillins" },
  { id: "J01D", title: "OTHER BETA-LACTAM ANTIBACTERIALS" },
  { id: "J01DB", title: "First-generation cephalosporins" },
  { id: "J01DB01", title: "cefalexin" },
  { id: "J01DB02", title: "cefaloridine" },
  { id: "J01DB03", title: "cefalotin" },
  { id: "J01DB04", title: "cefazolin" },
  { id: "J01DB05", title: "cefadroxil" },
  { id: "J01DB06", title: "cefazedone" },
  { id: "J01DB07", title: "cefatrizine" },
  { id: "J01DB08", title: "cefapirin" },
  { id: "J01DB09", title: "cefradine" },
  { id: "J01DB10", title: "cefacetrile" },
  { id: "J01DB11", title: "cefroxadine" },
  { id: "J01DB12", title: "ceftezole" },
  { id: "J01DC", title: "Second-generation cephalosporins" },
  { id: "J01DC01", title: "cefoxitin" },
  { id: "J01DC02", title: "cefuroxime" },
  { id: "J01DC03", title: "cefamandole" },
  { id: "J01DC04", title: "cefaclor" },
  { id: "J01DC05", title: "cefotetan" },
  { id: "J01DC06", title: "cefonicid" },
  { id: "J01DC07", title: "cefotiam" },
  { id: "J01DC08", title: "loracarbef" },
  { id: "J01DC09", title: "cefmetazole" },
  { id: "J01DC10", title: "cefprozil" },
  { id: "J01DC11", title: "ceforanide" },
  { id: "J01DC12", title: "cefminox" },
  { id: "J01DC13", title: "cefbuperazone" },
  { id: "J01DC14", title: "flomoxef" },
  { id: "J01DD", title: "Third-generation cephalosporins" },
  { id: "J01DD01", title: "cefotaxime" },
  { id: "J01DD02", title: "ceftazidime" },
  { id: "J01DD03", title: "cefsulodin" },
  { id: "J01DD04", title: "ceftriaxone" },
  { id: "J01DD05", title: "cefmenoxime" },
  { id: "J01DD06", title: "latamoxef" },
  { id: "J01DD07", title: "ceftizoxime" },
  { id: "J01DD08", title: "cefixime" },
  { id: "J01DD09", title: "cefodizime" },
  { id: "J01DD10", title: "cefetamet" },
  { id: "J01DD11", title: "cefpiramide" },
  { id: "J01DD12", title: "cefoperazone" },
  { id: "J01DD13", title: "cefpodoxime" },
  { id: "J01DD14", title: "ceftibuten" },
  { id: "J01DD15", title: "cefdinir" },
  { id: "J01DD16", title: "cefditoren" },
  { id: "J01DD17", title: "cefcapene" },
  { id: "J01DD54", title: "ceftriaxone, combinations" },
  { id: "J01DD62", title: "cefoperazone, combinations" },
  { id: "J01DE", title: "Fourth-generation cephalosporins" },
  { id: "J01DE01", title: "cefepime" },
  { id: "J01DE02", title: "cefpirome" },
  { id: "J01DE03", title: "cefozopran" },
  { id: "J01DF", title: "Monobactams" },
  { id: "J01DF01", title: "aztreonam" },
  { id: "J01DF02", title: "carumonam" },
  { id: "J01DH", title: "Carbapenems" },
  { id: "J01DH02", title: "meropenem" },
  { id: "J01DH03", title: "ertapenem" },
  { id: "J01DH04", title: "doripenem" },
  { id: "J01DH05", title: "biapenem" },
  { id: "J01DH51", title: "imipenem and enzyme inhibitor" },
  { id: "J01DH55", title: "panipenem and betamipron" },
  { id: "J01DI", title: "Other cephalosporins and penems" },
  { id: "J01DI01", title: "ceftobiprole medocaril" },
  { id: "J01DI02", title: "ceftaroline fosamil" },
  { id: "J01DI03", title: "faropenem" },
  { id: "J01E", title: "SULFONAMIDES AND TRIMETHOPRIM" },
  { id: "J01EA", title: "Trimethoprim and derivatives" },
  { id: "J01EA01", title: "trimethoprim" },
  { id: "J01EA02", title: "brodimoprim" },
  { id: "J01EA03", title: "iclaprim" },
  { id: "J01EB", title: "Short-acting sulfonamides" },
  { id: "J01EB01", title: "sulfaisodimidine" },
  { id: "J01EB02", title: "sulfamethizole" },
  { id: "J01EB03", title: "sulfadimidine" },
  { id: "J01EB04", title: "sulfapyridine" },
  { id: "J01EB05", title: "sulfafurazole" },
  { id: "J01EB06", title: "sulfanilamide" },
  { id: "J01EB07", title: "sulfathiazole" },
  { id: "J01EB08", title: "sulfathiourea" },
  { id: "J01EB20", title: "combinations" },
  { id: "J01EC", title: "Intermediate-acting sulfonamides" },
  { id: "J01EC01", title: "sulfamethoxazole" },
  { id: "J01EC02", title: "sulfadiazine" },
  { id: "J01EC03", title: "sulfamoxole" },
  { id: "J01EC20", title: "combinations" },
  { id: "J01ED", title: "Long-acting sulfonamides" },
  { id: "J01ED01", title: "sulfadimethoxine" },
  { id: "J01ED02", title: "sulfalene" },
  { id: "J01ED03", title: "sulfametomidine" },
  { id: "J01ED04", title: "sulfametoxydiazine" },
  { id: "J01ED05", title: "sulfamethoxypyridazine" },
  { id: "J01ED06", title: "sulfaperin" },
  { id: "J01ED07", title: "sulfamerazine" },
  { id: "J01ED08", title: "sulfaphenazole" },
  { id: "J01ED09", title: "sulfamazone" },
  { id: "J01ED20", title: "combinations" },
  {
    id: "J01EE",
    title: "Combinations of sulfonamides and trimethoprim, incl. derivatives",
  },
  { id: "J01EE01", title: "sulfamethoxazole and trimethoprim" },
  { id: "J01EE02", title: "sulfadiazine and trimethoprim" },
  { id: "J01EE03", title: "sulfametrole and trimethoprim" },
  { id: "J01EE04", title: "sulfamoxole and trimethoprim" },
  { id: "J01EE05", title: "sulfadimidine and trimethoprim" },
  { id: "J01EE06", title: "sulfadiazine and tetroxoprim" },
  { id: "J01EE07", title: "sulfamerazine and trimethoprim" },
  { id: "J01F", title: "MACROLIDES, LINCOSAMIDES AND STREPTOGRAMINS" },
  { id: "J01FA", title: "Macrolides" },
  { id: "J01FA01", title: "erythromycin" },
  { id: "J01FA02", title: "spiramycin" },
  { id: "J01FA03", title: "midecamycin" },
  { id: "J01FA05", title: "oleandomycin" },
  { id: "J01FA06", title: "roxithromycin" },
  { id: "J01FA07", title: "josamycin" },
  { id: "J01FA08", title: "troleandomycin" },
  { id: "J01FA09", title: "clarithromycin" },
  { id: "J01FA10", title: "azithromycin" },
  { id: "J01FA11", title: "miocamycin" },
  { id: "J01FA12", title: "rokitamycin" },
  { id: "J01FA13", title: "dirithromycin" },
  { id: "J01FA14", title: "flurithromycin" },
  { id: "J01FA15", title: "telithromycin" },
  { id: "J01FF", title: "Lincosamides" },
  { id: "J01FF01", title: "clindamycin" },
  { id: "J01FF02", title: "lincomycin" },
  { id: "J01FG", title: "Streptogramins" },
  { id: "J01FG01", title: "pristinamycin" },
  { id: "J01FG02", title: "quinupristin/dalfopristin" },
  { id: "J01G", title: "AMINOGLYCOSIDE ANTIBACTERIALS" },
  { id: "J01GA", title: "Streptomycins" },
  { id: "J01GA01", title: "streptomycin" },
  { id: "J01GA02", title: "streptoduocin" },
  { id: "J01GB", title: "Other aminoglycosides" },
  { id: "J01GB01", title: "tobramycin" },
  { id: "J01GB03", title: "gentamicin" },
  { id: "J01GB04", title: "kanamycin" },
  { id: "J01GB05", title: "neomycin" },
  { id: "J01GB06", title: "amikacin" },
  { id: "J01GB07", title: "netilmicin" },
  { id: "J01GB08", title: "sisomicin" },
  { id: "J01GB09", title: "dibekacin" },
  { id: "J01GB10", title: "ribostamycin" },
  { id: "J01GB11", title: "isepamicin" },
  { id: "J01GB12", title: "arbekacin" },
  { id: "J01GB13", title: "bekanamycin" },
  { id: "J01M", title: "QUINOLONE ANTIBACTERIALS" },
  { id: "J01MA", title: "Fluoroquinolones" },
  { id: "J01MA01", title: "ofloxacin" },
  { id: "J01MA02", title: "ciprofloxacin" },
  { id: "J01MA03", title: "pefloxacin" },
  { id: "J01MA04", title: "enoxacin" },
  { id: "J01MA05", title: "temafloxacin" },
  { id: "J01MA06", title: "norfloxacin" },
  { id: "J01MA07", title: "lomefloxacin" },
  { id: "J01MA08", title: "fleroxacin" },
  { id: "J01MA09", title: "sparfloxacin" },
  { id: "J01MA10", title: "rufloxacin" },
  { id: "J01MA11", title: "grepafloxacin" },
  { id: "J01MA12", title: "levofloxacin" },
  { id: "J01MA13", title: "trovafloxacin" },
  { id: "J01MA14", title: "moxifloxacin" },
  { id: "J01MA15", title: "gemifloxacin" },
  { id: "J01MA16", title: "gatifloxacin" },
  { id: "J01MA17", title: "prulifloxacin" },
  { id: "J01MA18", title: "pazufloxacin" },
  { id: "J01MA19", title: "garenoxacin" },
  { id: "J01MA21", title: "sitafloxacin" },
  { id: "J01MB", title: "Other quinolones" },
  { id: "J01MB01", title: "rosoxacin" },
  { id: "J01MB02", title: "nalidixic acid" },
  { id: "J01MB03", title: "piromidic acid" },
  { id: "J01MB04", title: "pipemidic acid" },
  { id: "J01MB05", title: "oxolinic acid" },
  { id: "J01MB06", title: "cinoxacin" },
  { id: "J01MB07", title: "flumequine" },
  { id: "J01R", title: "COMBINATIONS OF ANTIBACTERIALS" },
  { id: "J01RA", title: "Combinations of antibacterials" },
  {
    id: "J01RA01",
    title: "penicillins, combinations with other antibacterials",
  },
  {
    id: "J01RA02",
    title:
      "sulfonamides, combinations with other antibacterials (excl. trimethoprim)",
  },
  {
    id: "J01RA03",
    title: "cefuroxime, combinations with other antibacterials",
  },
  {
    id: "J01RA04",
    title: "spiramycin, combinations with other antibacterials",
  },
  { id: "J01RA05", title: "levofloxacin and ornidazole" },
  { id: "J01X", title: "OTHER ANTIBACTERIALS" },
  { id: "J01XA", title: "Glycopeptide antibacterials" },
  { id: "J01XA01", title: "vancomycin" },
  { id: "J01XA02", title: "teicoplanin" },
  { id: "J01XA03", title: "telavancin" },
  { id: "J01XA04", title: "dalbavancin" },
  { id: "J01XA05", title: "oritavancin" },
  { id: "J01XB", title: "Polymyxins" },
  { id: "J01XB01", title: "colistin" },
  { id: "J01XB02", title: "polymyxin B" },
  { id: "J01XC", title: "Steroid antibacterials" },
  { id: "J01XC01", title: "fusidic acid" },
  { id: "J01XD", title: "Imidazole derivatives" },
  { id: "J01XD01", title: "metronidazole" },
  { id: "J01XD02", title: "tinidazole" },
  { id: "J01XD03", title: "ornidazole" },
  { id: "J01XE", title: "Nitrofuran derivatives" },
  { id: "J01XE01", title: "nitrofurantoin" },
  { id: "J01XE02", title: "nifurtoinol" },
  { id: "J01XX", title: "Other antibacterials" },
  { id: "J01XX01", title: "fosfomycin" },
  { id: "J01XX02", title: "xibornol" },
  { id: "J01XX03", title: "clofoctol" },
  { id: "J01XX04", title: "spectinomycin" },
  { id: "J01XX05", title: "methenamine" },
  { id: "J01XX06", title: "mandelic acid" },
  { id: "J01XX07", title: "nitroxoline" },
  { id: "J01XX08", title: "linezolid" },
  { id: "J01XX09", title: "daptomycin" },
  { id: "J01XX10", title: "bacitracin" },
  { id: "J02", title: "ANTIMYCOTICS FOR SYSTEMIC USE" },
  { id: "J02A", title: "ANTIMYCOTICS FOR SYSTEMIC USE" },
  { id: "J02AA", title: "Antibiotics" },
  { id: "J02AA01", title: "amphotericin B" },
  { id: "J02AA02", title: "hachimycin" },
  { id: "J02AB", title: "Imidazole derivatives" },
  { id: "J02AB01", title: "miconazole" },
  { id: "J02AB02", title: "ketoconazole" },
  { id: "J02AC", title: "Triazole derivatives" },
  { id: "J02AC01", title: "fluconazole" },
  { id: "J02AC02", title: "itraconazole" },
  { id: "J02AC03", title: "voriconazole" },
  { id: "J02AC04", title: "posaconazole" },
  { id: "J02AX", title: "Other antimycotics for systemic use" },
  { id: "J02AX01", title: "flucytosine" },
  { id: "J02AX04", title: "caspofungin" },
  { id: "J02AX05", title: "micafungin" },
  { id: "J02AX06", title: "anidulafungin" },
  { id: "J04", title: "ANTIMYCOBACTERIALS" },
  { id: "J04A", title: "DRUGS FOR TREATMENT OF TUBERCULOSIS" },
  { id: "J04AA", title: "Aminosalicylic acid and derivatives" },
  { id: "J04AA01", title: "4-aminosalicylic acid" },
  { id: "J04AA02", title: "sodium aminosalicylate" },
  { id: "J04AA03", title: "calcium aminosalicylate" },
  { id: "J04AB", title: "Antibiotics" },
  { id: "J04AB01", title: "cycloserine" },
  { id: "J04AB02", title: "rifampicin" },
  { id: "J04AB03", title: "rifamycin" },
  { id: "J04AB04", title: "rifabutin" },
  { id: "J04AB05", title: "rifapentine" },
  { id: "J04AB30", title: "capreomycin" },
  { id: "J04AC", title: "Hydrazides" },
  { id: "J04AC01", title: "isoniazid" },
  { id: "J04AC51", title: "isoniazid, combinations" },
  { id: "J04AD", title: "Thiocarbamide derivatives" },
  { id: "J04AD01", title: "protionamide" },
  { id: "J04AD02", title: "tiocarlide" },
  { id: "J04AD03", title: "ethionamide" },
  { id: "J04AK", title: "Other drugs for treatment of tuberculosis" },
  { id: "J04AK01", title: "pyrazinamide" },
  { id: "J04AK02", title: "ethambutol" },
  { id: "J04AK03", title: "terizidone" },
  { id: "J04AK04", title: "morinamide" },
  { id: "J04AK05", title: "bedaquiline" },
  { id: "J04AK06", title: "delamanid" },
  {
    id: "J04AM",
    title: "Combinations of drugs for treatment of tuberculosis",
  },
  { id: "J04AM01", title: "streptomycin and isoniazid" },
  { id: "J04AM02", title: "rifampicin and isoniazid" },
  { id: "J04AM03", title: "ethambutol and isoniazid" },
  { id: "J04AM04", title: "thioacetazone and isoniazid" },
  { id: "J04AM05", title: "rifampicin, pyrazinamide and isoniazid" },
  {
    id: "J04AM06",
    title: "rifampicin, pyrazinamide, ethambutol and isoniazid",
  },
  { id: "J04B", title: "DRUGS FOR TREATMENT OF LEPRA" },
  { id: "J04BA", title: "Drugs for treatment of lepra" },
  { id: "J04BA01", title: "clofazimine" },
  { id: "J04BA02", title: "dapsone" },
  { id: "J04BA03", title: "aldesulfone sodium" },
  { id: "J05", title: "ANTIVIRALS FOR SYSTEMIC USE" },
  { id: "J05A", title: "DIRECT ACTING ANTIVIRALS" },
  { id: "J05AA", title: "Thiosemicarbazones" },
  { id: "J05AA01", title: "metisazone" },
  {
    id: "J05AB",
    title: "Nucleosides and nucleotides excl. reverse transcriptase inhibitors",
  },
  { id: "J05AB01", title: "aciclovir" },
  { id: "J05AB02", title: "idoxuridine" },
  { id: "J05AB03", title: "vidarabine" },
  { id: "J05AB04", title: "ribavirin" },
  { id: "J05AB06", title: "ganciclovir" },
  { id: "J05AB09", title: "famciclovir" },
  { id: "J05AB11", title: "valaciclovir" },
  { id: "J05AB12", title: "cidofovir" },
  { id: "J05AB13", title: "penciclovir" },
  { id: "J05AB14", title: "valganciclovir" },
  { id: "J05AB15", title: "brivudine" },
  { id: "J05AC", title: "Cyclic amines" },
  { id: "J05AC02", title: "rimantadine" },
  { id: "J05AC03", title: "tromantadine" },
  { id: "J05AD", title: "Phosphonic acid derivatives" },
  { id: "J05AD01", title: "foscarnet" },
  { id: "J05AD02", title: "fosfonet" },
  { id: "J05AE", title: "Protease inhibitors" },
  { id: "J05AE01", title: "saquinavir" },
  { id: "J05AE02", title: "indinavir" },
  { id: "J05AE03", title: "ritonavir" },
  { id: "J05AE04", title: "nelfinavir" },
  { id: "J05AE05", title: "amprenavir" },
  { id: "J05AE07", title: "fosamprenavir" },
  { id: "J05AE08", title: "atazanavir" },
  { id: "J05AE09", title: "tipranavir" },
  { id: "J05AE10", title: "darunavir" },
  { id: "J05AE11", title: "telaprevir" },
  { id: "J05AE12", title: "boceprevir" },
  {
    id: "J05AF",
    title: "Nucleoside and nucleotide reverse transcriptase inhibitors",
  },
  { id: "J05AF01", title: "zidovudine" },
  { id: "J05AF02", title: "didanosine" },
  { id: "J05AF03", title: "zalcitabine" },
  { id: "J05AF04", title: "stavudine" },
  { id: "J05AF05", title: "lamivudine" },
  { id: "J05AF06", title: "abacavir" },
  { id: "J05AF07", title: "tenofovir disoproxil" },
  { id: "J05AF08", title: "adefovir dipivoxil" },
  { id: "J05AF09", title: "emtricitabine" },
  { id: "J05AF10", title: "entecavir" },
  { id: "J05AF11", title: "telbivudine" },
  { id: "J05AF12", title: "clevudine" },
  { id: "J05AG", title: "Non-nucleoside reverse transcriptase inhibitors" },
  { id: "J05AG01", title: "nevirapine" },
  { id: "J05AG02", title: "delavirdine" },
  { id: "J05AG03", title: "efavirenz" },
  { id: "J05AG04", title: "etravirine" },
  { id: "J05AG05", title: "rilpivirine" },
  { id: "J05AH", title: "Neuraminidase inhibitors" },
  { id: "J05AH01", title: "zanamivir" },
  { id: "J05AH02", title: "oseltamivir" },
  {
    id: "J05AR",
    title: "Antivirals for treatment of HIV infections, combinations",
  },
  { id: "J05AR01", title: "zidovudine and lamivudine" },
  { id: "J05AR02", title: "lamivudine and abacavir" },
  { id: "J05AR03", title: "tenofovir disoproxil and emtricitabine" },
  { id: "J05AR04", title: "zidovudine, lamivudine and abacavir" },
  { id: "J05AR05", title: "zidovudine, lamivudine and nevirapine" },
  {
    id: "J05AR06",
    title: "emtricitabine, tenofovir disoproxil and efavirenz",
  },
  { id: "J05AR07", title: "stavudine, lamivudine and nevirapine" },
  {
    id: "J05AR08",
    title: "emtricitabine, tenofovir disoproxil and rilpivirine",
  },
  {
    id: "J05AR09",
    title: "emtricitabine, tenofovir disoproxil, elvitegravir and cobicistat",
  },
  { id: "J05AR10", title: "lopinavir and ritonavir" },
  {
    id: "J05AR11",
    title: "lamivudine, tenofovir disoproxil and efavirenz",
  },
  { id: "J05AX", title: "Other antivirals" },
  { id: "J05AX01", title: "moroxydine" },
  { id: "J05AX02", title: "lysozyme" },
  { id: "J05AX05", title: "inosine pranobex" },
  { id: "J05AX06", title: "pleconaril" },
  { id: "J05AX07", title: "enfuvirtide" },
  { id: "J05AX08", title: "raltegravir" },
  { id: "J05AX09", title: "maraviroc" },
  { id: "J05AX10", title: "maribavir" },
  { id: "J05AX11", title: "elvitegravir" },
  { id: "J05AX12", title: "dolutegravir" },
  { id: "J06", title: "IMMUNE SERA AND IMMUNOGLOBULINS" },
  { id: "J06A", title: "IMMUNE SERA" },
  { id: "J06AA", title: "Immune sera" },
  { id: "J06AA01", title: "diphtheria antitoxin" },
  { id: "J06AA02", title: "tetanus antitoxin" },
  { id: "J06AA03", title: "snake venom antiserum" },
  { id: "J06AA04", title: "botulinum antitoxin" },
  { id: "J06AA05", title: "gas-gangrene sera" },
  { id: "J06AA06", title: "rabies serum" },
  { id: "J06B", title: "IMMUNOGLOBULINS" },
  { id: "J06BA", title: "Immunoglobulins, normal human" },
  {
    id: "J06BA01",
    title: "immunoglobulins, normal human, for extravascular adm.",
  },
  {
    id: "J06BA02",
    title: "immunoglobulins, normal human, for intravascular adm.",
  },
  { id: "J06BB", title: "Specific immunoglobulins" },
  { id: "J06BB01", title: "anti-D (rh) immunoglobulin" },
  { id: "J06BB02", title: "tetanus immunoglobulin" },
  { id: "J06BB03", title: "varicella/zoster immunoglobulin" },
  { id: "J06BB04", title: "hepatitis B immunoglobulin" },
  { id: "J06BB05", title: "rabies immunoglobulin" },
  { id: "J06BB06", title: "rubella immunoglobulin" },
  { id: "J06BB07", title: "vaccinia immunoglobulin" },
  { id: "J06BB08", title: "staphylococcus immunoglobulin" },
  { id: "J06BB09", title: "cytomegalovirus immunoglobulin" },
  { id: "J06BB10", title: "diphtheria immunoglobulin" },
  { id: "J06BB11", title: "hepatitis A immunoglobulin" },
  { id: "J06BB12", title: "encephalitis, tick borne immunoglobulin" },
  { id: "J06BB13", title: "pertussis immunoglobulin" },
  { id: "J06BB14", title: "measles immunoglobulin" },
  { id: "J06BB15", title: "mumps immunoglobulin" },
  { id: "J06BB16", title: "palivizumab" },
  { id: "J06BB17", title: "motavizumab" },
  { id: "J06BB30", title: "combinations" },
  { id: "J06BC", title: "Other immunoglobulins" },
  { id: "J06BC01", title: "nebacumab" },
  { id: "J07", title: "VACCINES" },
  { id: "J07A", title: "BACTERIAL VACCINES" },
  { id: "J07AC", title: "Anthrax vaccines" },
  { id: "J07AC01", title: "anthrax antigen" },
  { id: "J07AD", title: "Brucellosis vaccines" },
  { id: "J07AD01", title: "brucella antigen" },
  { id: "J07AE", title: "Cholera vaccines" },
  { id: "J07AE01", title: "cholera, inactivated, whole cell" },
  { id: "J07AE02", title: "cholera, live attenuated" },
  {
    id: "J07AE51",
    title:
      "cholera, combinations with typhoid vaccine, inactivated, whole cell",
  },
  { id: "J07AF", title: "Diphtheria vaccines" },
  { id: "J07AF01", title: "diphtheria toxoid" },
  { id: "J07AG", title: "Hemophilus influenzae B vaccines" },
  {
    id: "J07AG01",
    title: "hemophilus influenzae B, purified antigen conjugated",
  },
  {
    id: "J07AG51",
    title: "hemophilus influenzae B, combinations with toxoids",
  },
  {
    id: "J07AG52",
    title: "hemophilus influenzae B, combinations with pertussis and toxoids",
  },
  {
    id: "J07AG53",
    title:
      "hemophilus influenzae B, combinations with meningococcus C, conjugated",
  },
  { id: "J07AH", title: "Meningococcal vaccines" },
  {
    id: "J07AH01",
    title: "meningococcus A, purified polysaccharides antigen",
  },
  {
    id: "J07AH02",
    title: "other meningococcal monovalent purified polysaccharides antigen",
  },
  {
    id: "J07AH03",
    title: "meningococcus A,C, bivalent purified polysaccharides antigen",
  },
  {
    id: "J07AH04",
    title:
      "meningococcus A,C,Y,W-135, tetravalent purified polysaccharides antigen",
  },
  {
    id: "J07AH05",
    title: "other meningococcal polyvalent purified polysaccharides antigen",
  },
  {
    id: "J07AH06",
    title: "meningococcus B, outer membrane vesicle vaccine",
  },
  {
    id: "J07AH07",
    title: "meningococcus C, purified polysaccharides antigen conjugated",
  },
  {
    id: "J07AH08",
    title:
      "meningococcus A,C,Y,W-135, tetravalent purified polysaccharides antigen conjugated",
  },
  { id: "J07AH09", title: "meningococcus B, multicomponent vaccine" },
  {
    id: "J07AH10",
    title: "meningococcus A, purified polysaccharides antigen conjugated",
  },
  { id: "J07AJ", title: "Pertussis vaccines" },
  { id: "J07AJ01", title: "pertussis, inactivated, whole cell" },
  { id: "J07AJ02", title: "pertussis, purified antigen" },
  {
    id: "J07AJ51",
    title: "pertussis, inactivated, whole cell, combinations with toxoids",
  },
  {
    id: "J07AJ52",
    title: "pertussis, purified antigen, combinations with toxoids",
  },
  { id: "J07AK", title: "Plague vaccines" },
  { id: "J07AK01", title: "plague, inactivated, whole cell" },
  { id: "J07AL", title: "Pneumococcal vaccines" },
  {
    id: "J07AL01",
    title: "pneumococcus, purified polysaccharides antigen",
  },
  {
    id: "J07AL02",
    title: "pneumococcus, purified polysaccharides antigen conjugated",
  },
  {
    id: "J07AL52",
    title:
      "pneumococcus purified polysaccharides antigen and haemophilus influenzae, conjugated",
  },
  { id: "J07AM", title: "Tetanus vaccines" },
  { id: "J07AM01", title: "tetanus toxoid" },
  {
    id: "J07AM51",
    title: "tetanus toxoid, combinations with diphtheria toxoid",
  },
  {
    id: "J07AM52",
    title: "tetanus toxoid, combinations with tetanus immunoglobulin",
  },
  { id: "J07AN", title: "Tuberculosis vaccines" },
  { id: "J07AN01", title: "tuberculosis, live attenuated" },
  { id: "J07AP", title: "Typhoid vaccines" },
  { id: "J07AP01", title: "typhoid, oral, live attenuated" },
  { id: "J07AP02", title: "typhoid, inactivated, whole cell" },
  { id: "J07AP03", title: "typhoid, purified polysaccharide antigen" },
  { id: "J07AP10", title: "typhoid, combinations with paratyphi types" },
  { id: "J07AR", title: "Typhus (exanthematicus) vaccines" },
  {
    id: "J07AR01",
    title: "typhus exanthematicus, inactivated, whole cell",
  },
  { id: "J07AX", title: "Other bacterial vaccines" },
  { id: "J07B", title: "VIRAL VACCINES" },
  { id: "J07BA", title: "Encephalitis vaccines" },
  {
    id: "J07BA01",
    title: "encephalitis, tick borne, inactivated, whole virus",
  },
  {
    id: "J07BA02",
    title: "encephalitis, Japanese, inactivated, whole virus",
  },
  { id: "J07BA03", title: "encephalitis, Japanese, live attenuated" },
  { id: "J07BB", title: "Influenza vaccines" },
  { id: "J07BB01", title: "influenza, inactivated, whole virus" },
  {
    id: "J07BB02",
    title: "influenza, inactivated, split virus or surface antigen",
  },
  { id: "J07BB03", title: "influenza, live attenuated" },
  { id: "J07BC", title: "Hepatitis vaccines" },
  { id: "J07BC01", title: "hepatitis B, purified antigen" },
  { id: "J07BC02", title: "hepatitis A, inactivated, whole virus" },
  { id: "J07BC20", title: "combinations" },
  { id: "J07BD", title: "Measles vaccines" },
  { id: "J07BD01", title: "measles, live attenuated" },
  {
    id: "J07BD51",
    title: "measles, combinations with mumps, live attenuated",
  },
  {
    id: "J07BD52",
    title: "measles, combinations with mumps and rubella, live attenuated",
  },
  {
    id: "J07BD53",
    title: "measles, combinations with rubella, live attenuated",
  },
  {
    id: "J07BD54",
    title:
      "measles, combinations with mumps, rubella and varicella, live attenuated",
  },
  { id: "J07BE", title: "Mumps vaccines" },
  { id: "J07BE01", title: "mumps, live attenuated" },
  { id: "J07BF", title: "Poliomyelitis vaccines" },
  {
    id: "J07BF01",
    title: "poliomyelitis oral, monovalent, live attenuated",
  },
  {
    id: "J07BF02",
    title: "poliomyelitis oral, trivalent, live attenuated",
  },
  {
    id: "J07BF03",
    title: "poliomyelitis, trivalent, inactivated, whole virus",
  },
  { id: "J07BF04", title: "poliomyelitis oral, bivalent, live attenuated" },
  { id: "J07BG", title: "Rabies vaccines" },
  { id: "J07BG01", title: "rabies, inactivated, whole virus" },
  { id: "J07BH", title: "Rota virus diarrhea vaccines" },
  { id: "J07BH01", title: "rota virus, live attenuated" },
  { id: "J07BH02", title: "rota virus, pentavalent, live, reassorted" },
  { id: "J07BJ", title: "Rubella vaccines" },
  { id: "J07BJ01", title: "rubella, live attenuated" },
  {
    id: "J07BJ51",
    title: "rubella, combinations with mumps, live attenuated",
  },
  { id: "J07BK", title: "Varicella zoster vaccines" },
  { id: "J07BK01", title: "varicella, live attenuated" },
  { id: "J07BK02", title: "zoster, live attenuated" },
  { id: "J07BL", title: "Yellow fever vaccines" },
  { id: "J07BL01", title: "yellow fever, live attenuated" },
  { id: "J07BM", title: "Papillomavirus vaccines" },
  { id: "J07BM01", title: "papillomavirus (human types 6, 11, 16, 18)" },
  { id: "J07BM02", title: "papillomavirus (human types 16, 18)" },
  { id: "J07BX", title: "Other viral vaccines" },
  { id: "J07C", title: "BACTERIAL AND VIRAL VACCINES, COMBINED" },
  { id: "J07CA", title: "Bacterial and viral vaccines, combined" },
  { id: "J07CA01", title: "diphtheria-poliomyelitis-tetanus" },
  { id: "J07CA02", title: "diphtheria-pertussis-poliomyelitis-tetanus" },
  { id: "J07CA03", title: "diphtheria-rubella-tetanus" },
  { id: "J07CA04", title: "hemophilus influenzae B and poliomyelitis" },
  { id: "J07CA05", title: "diphtheria-hepatitis B-pertussis-tetanus" },
  {
    id: "J07CA06",
    title: "diphtheria-hemophilus influenzae B-pertussis-poliomyelitis-tetanus",
  },
  { id: "J07CA07", title: "diphtheria-hepatitis B-tetanus" },
  { id: "J07CA08", title: "hemophilus influenzae B and hepatitis B" },
  {
    id: "J07CA09",
    title:
      "diphtheria-hemophilus influenzae B-pertussis-poliomyelitis-tetanus-hepatitis B",
  },
  { id: "J07CA10", title: "typhoid-hepatitis A" },
  {
    id: "J07CA11",
    title: "diphtheria-hemophilus influenzae B-pertussis-tetanus-hepatitis B",
  },
  {
    id: "J07CA12",
    title: "diphtheria-pertussis-poliomyelitis-tetanus-hepatitis B",
  },
  {
    id: "J07CA13",
    title:
      "diphtheria-hemophilus influenzae B-pertussis-tetanus-hepatitis B-meningococcus A + C",
  },
  { id: "J07X", title: "OTHER VACCINES" },
  { id: "L", title: "ANTINEOPLASTIC AND IMMUNOMODULATING AGENTS" },
  { id: "L01", title: "ANTINEOPLASTIC AGENTS" },
  { id: "L01A", title: "ALKYLATING AGENTS" },
  { id: "L01AA", title: "Nitrogen mustard analogues" },
  { id: "L01AA01", title: "cyclophosphamide" },
  { id: "L01AA02", title: "chlorambucil" },
  { id: "L01AA03", title: "melphalan" },
  { id: "L01AA05", title: "chlormethine" },
  { id: "L01AA06", title: "ifosfamide" },
  { id: "L01AA07", title: "trofosfamide" },
  { id: "L01AA08", title: "prednimustine" },
  { id: "L01AA09", title: "bendamustine" },
  { id: "L01AB", title: "Alkyl sulfonates" },
  { id: "L01AB01", title: "busulfan" },
  { id: "L01AB02", title: "treosulfan" },
  { id: "L01AB03", title: "mannosulfan" },
  { id: "L01AC", title: "Ethylene imines" },
  { id: "L01AC01", title: "thiotepa" },
  { id: "L01AC02", title: "triaziquone" },
  { id: "L01AC03", title: "carboquone" },
  { id: "L01AD", title: "Nitrosoureas" },
  { id: "L01AD01", title: "carmustine" },
  { id: "L01AD02", title: "lomustine" },
  { id: "L01AD03", title: "semustine" },
  { id: "L01AD04", title: "streptozocin" },
  { id: "L01AD05", title: "fotemustine" },
  { id: "L01AD06", title: "nimustine" },
  { id: "L01AD07", title: "ranimustine" },
  { id: "L01AG", title: "Epoxides" },
  { id: "L01AG01", title: "etoglucid" },
  { id: "L01AX", title: "Other alkylating agents" },
  { id: "L01AX01", title: "mitobronitol" },
  { id: "L01AX02", title: "pipobroman" },
  { id: "L01AX03", title: "temozolomide" },
  { id: "L01AX04", title: "dacarbazine" },
  { id: "L01B", title: "ANTIMETABOLITES" },
  { id: "L01BA", title: "Folic acid analogues" },
  { id: "L01BA01", title: "methotrexate" },
  { id: "L01BA03", title: "raltitrexed" },
  { id: "L01BA04", title: "pemetrexed" },
  { id: "L01BA05", title: "pralatrexate" },
  { id: "L01BB", title: "Purine analogues" },
  { id: "L01BB02", title: "mercaptopurine" },
  { id: "L01BB03", title: "tioguanine" },
  { id: "L01BB04", title: "cladribine" },
  { id: "L01BB05", title: "fludarabine" },
  { id: "L01BB06", title: "clofarabine" },
  { id: "L01BB07", title: "nelarabine" },
  { id: "L01BC", title: "Pyrimidine analogues" },
  { id: "L01BC01", title: "cytarabine" },
  { id: "L01BC02", title: "fluorouracil" },
  { id: "L01BC03", title: "tegafur" },
  { id: "L01BC04", title: "carmofur" },
  { id: "L01BC05", title: "gemcitabine" },
  { id: "L01BC06", title: "capecitabine" },
  { id: "L01BC07", title: "azacitidine" },
  { id: "L01BC08", title: "decitabine" },
  { id: "L01BC52", title: "fluorouracil, combinations" },
  { id: "L01BC53", title: "tegafur, combinations" },
  { id: "L01C", title: "PLANT ALKALOIDS AND OTHER NATURAL PRODUCTS" },
  { id: "L01CA", title: "Vinca alkaloids and analogues" },
  { id: "L01CA01", title: "vinblastine" },
  { id: "L01CA02", title: "vincristine" },
  { id: "L01CA03", title: "vindesine" },
  { id: "L01CA04", title: "vinorelbine" },
  { id: "L01CA05", title: "vinflunine" },
  { id: "L01CA06", title: "vintafolide" },
  { id: "L01CB", title: "Podophyllotoxin derivatives" },
  { id: "L01CB01", title: "etoposide" },
  { id: "L01CB02", title: "teniposide" },
  { id: "L01CC", title: "Colchicine derivatives" },
  { id: "L01CC01", title: "demecolcine" },
  { id: "L01CD", title: "Taxanes" },
  { id: "L01CD01", title: "paclitaxel" },
  { id: "L01CD02", title: "docetaxel" },
  { id: "L01CD03", title: "paclitaxel poliglumex" },
  { id: "L01CD04", title: "cabazitaxel" },
  { id: "L01CX", title: "Other plant alkaloids and natural products" },
  { id: "L01CX01", title: "trabectedin" },
  { id: "L01D", title: "CYTOTOXIC ANTIBIOTICS AND RELATED SUBSTANCES" },
  { id: "L01DA", title: "Actinomycines" },
  { id: "L01DA01", title: "dactinomycin" },
  { id: "L01DB", title: "Anthracyclines and related substances" },
  { id: "L01DB01", title: "doxorubicin" },
  { id: "L01DB02", title: "daunorubicin" },
  { id: "L01DB03", title: "epirubicin" },
  { id: "L01DB04", title: "aclarubicin" },
  { id: "L01DB05", title: "zorubicin" },
  { id: "L01DB06", title: "idarubicin" },
  { id: "L01DB07", title: "mitoxantrone" },
  { id: "L01DB08", title: "pirarubicin" },
  { id: "L01DB09", title: "valrubicin" },
  { id: "L01DB10", title: "amrubicin" },
  { id: "L01DB11", title: "pixantrone" },
  { id: "L01DC", title: "Other cytotoxic antibiotics" },
  { id: "L01DC01", title: "bleomycin" },
  { id: "L01DC02", title: "plicamycin" },
  { id: "L01DC03", title: "mitomycin" },
  { id: "L01DC04", title: "ixabepilone" },
  { id: "L01X", title: "OTHER ANTINEOPLASTIC AGENTS" },
  { id: "L01XA", title: "Platinum compounds" },
  { id: "L01XA01", title: "cisplatin" },
  { id: "L01XA02", title: "carboplatin" },
  { id: "L01XA03", title: "oxaliplatin" },
  { id: "L01XA04", title: "satraplatin" },
  { id: "L01XA05", title: "polyplatillen" },
  { id: "L01XB", title: "Methylhydrazines" },
  { id: "L01XB01", title: "procarbazine" },
  { id: "L01XC", title: "Monoclonal antibodies" },
  { id: "L01XC01", title: "edrecolomab" },
  { id: "L01XC02", title: "rituximab" },
  { id: "L01XC03", title: "trastuzumab" },
  { id: "L01XC04", title: "alemtuzumab" },
  { id: "L01XC05", title: "gemtuzumab" },
  { id: "L01XC06", title: "cetuximab" },
  { id: "L01XC07", title: "bevacizumab" },
  { id: "L01XC08", title: "panitumumab" },
  { id: "L01XC09", title: "catumaxomab" },
  { id: "L01XC10", title: "ofatumumab" },
  { id: "L01XC11", title: "ipilimumab" },
  { id: "L01XC12", title: "brentuximab vedotin" },
  { id: "L01XC13", title: "pertuzumab" },
  { id: "L01XC14", title: "trastuzumab emtansine" },
  { id: "L01XC15", title: "obinutuzumab" },
  {
    id: "L01XD",
    title: "Sensitizers used in photodynamic/radiation therapy",
  },
  { id: "L01XD01", title: "porfimer sodium" },
  { id: "L01XD03", title: "methyl aminolevulinate" },
  { id: "L01XD04", title: "aminolevulinic acid" },
  { id: "L01XD05", title: "temoporfin" },
  { id: "L01XD06", title: "efaproxiral" },
  { id: "L01XE", title: "Protein kinase inhibitors" },
  { id: "L01XE01", title: "imatinib" },
  { id: "L01XE02", title: "gefitinib" },
  { id: "L01XE03", title: "erlotinib" },
  { id: "L01XE04", title: "sunitinib" },
  { id: "L01XE05", title: "sorafenib" },
  { id: "L01XE06", title: "dasatinib" },
  { id: "L01XE07", title: "lapatinib" },
  { id: "L01XE08", title: "nilotinib" },
  { id: "L01XE09", title: "temsirolimus" },
  { id: "L01XE10", title: "everolimus" },
  { id: "L01XE11", title: "pazopanib" },
  { id: "L01XE12", title: "vandetanib" },
  { id: "L01XE13", title: "afatinib" },
  { id: "L01XE14", title: "bosutinib" },
  { id: "L01XE15", title: "vemurafenib" },
  { id: "L01XE16", title: "crizotinib" },
  { id: "L01XE17", title: "axitinib" },
  { id: "L01XE18", title: "ruxolitinib" },
  { id: "L01XE19", title: "ridaforolimus" },
  { id: "L01XE21", title: "regorafenib" },
  { id: "L01XE22", title: "masitinib" },
  { id: "L01XE23", title: "dabrafenib" },
  { id: "L01XE24", title: "ponatinib" },
  { id: "L01XE25", title: "trametinib" },
  { id: "L01XX", title: "Other antineoplastic agents" },
  { id: "L01XX01", title: "amsacrine" },
  { id: "L01XX02", title: "asparaginase" },
  { id: "L01XX03", title: "altretamine" },
  { id: "L01XX05", title: "hydroxycarbamide" },
  { id: "L01XX07", title: "lonidamine" },
  { id: "L01XX08", title: "pentostatin" },
  { id: "L01XX09", title: "miltefosine" },
  { id: "L01XX10", title: "masoprocol" },
  { id: "L01XX11", title: "estramustine" },
  { id: "L01XX14", title: "tretinoin" },
  { id: "L01XX16", title: "mitoguazone" },
  { id: "L01XX17", title: "topotecan" },
  { id: "L01XX18", title: "tiazofurine" },
  { id: "L01XX19", title: "irinotecan" },
  { id: "L01XX22", title: "alitretinoin" },
  { id: "L01XX23", title: "mitotane" },
  { id: "L01XX24", title: "pegaspargase" },
  { id: "L01XX25", title: "bexarotene" },
  { id: "L01XX27", title: "arsenic trioxide" },
  { id: "L01XX29", title: "denileukin diftitox" },
  { id: "L01XX32", title: "bortezomib" },
  { id: "L01XX33", title: "celecoxib" },
  { id: "L01XX35", title: "anagrelide" },
  { id: "L01XX36", title: "oblimersen" },
  { id: "L01XX37", title: "sitimagene ceradenovec" },
  { id: "L01XX38", title: "vorinostat" },
  { id: "L01XX39", title: "romidepsin" },
  { id: "L01XX40", title: "omacetaxine mepesuccinate" },
  { id: "L01XX41", title: "eribulin" },
  { id: "L01XX42", title: "panobinostat" },
  { id: "L01XX43", title: "vismodegib" },
  { id: "L01XX44", title: "aflibercept" },
  { id: "L01XX45", title: "carfilzomib" },
  { id: "L01XY", title: "Combinations of antineoplastic agents" },
  { id: "L02", title: "ENDOCRINE THERAPY" },
  { id: "L02A", title: "HORMONES AND RELATED AGENTS" },
  { id: "L02AA", title: "Estrogens" },
  { id: "L02AA01", title: "diethylstilbestrol" },
  { id: "L02AA02", title: "polyestradiol phosphate" },
  { id: "L02AA03", title: "ethinylestradiol" },
  { id: "L02AA04", title: "fosfestrol" },
  { id: "L02AB", title: "Progestogens" },
  { id: "L02AB01", title: "megestrol" },
  { id: "L02AB02", title: "medroxyprogesterone" },
  { id: "L02AB03", title: "gestonorone" },
  { id: "L02AE", title: "Gonadotropin releasing hormone analogues" },
  { id: "L02AE01", title: "buserelin" },
  { id: "L02AE02", title: "leuprorelin" },
  { id: "L02AE03", title: "goserelin" },
  { id: "L02AE04", title: "triptorelin" },
  { id: "L02AE05", title: "histrelin" },
  { id: "L02AX", title: "Other hormones" },
  { id: "L02B", title: "HORMONE ANTAGONISTS AND RELATED AGENTS" },
  { id: "L02BA", title: "Anti-estrogens" },
  { id: "L02BA01", title: "tamoxifen" },
  { id: "L02BA02", title: "toremifene" },
  { id: "L02BA03", title: "fulvestrant" },
  { id: "L02BB", title: "Anti-androgens" },
  { id: "L02BB01", title: "flutamide" },
  { id: "L02BB02", title: "nilutamide" },
  { id: "L02BB03", title: "bicalutamide" },
  { id: "L02BG", title: "Aromatase inhibitors" },
  { id: "L02BG01", title: "aminoglutethimide" },
  { id: "L02BG02", title: "formestane" },
  { id: "L02BG03", title: "anastrozole" },
  { id: "L02BG04", title: "letrozole" },
  { id: "L02BG05", title: "vorozole" },
  { id: "L02BG06", title: "exemestane" },
  { id: "L02BX", title: "Other hormone antagonists and related agents" },
  { id: "L02BX01", title: "abarelix" },
  { id: "L02BX02", title: "degarelix" },
  { id: "L02BX03", title: "abiraterone" },
  { id: "L03", title: "IMMUNOSTIMULANTS" },
  { id: "L03A", title: "IMMUNOSTIMULANTS" },
  { id: "L03AA", title: "Colony stimulating factors" },
  { id: "L03AA02", title: "filgrastim" },
  { id: "L03AA03", title: "molgramostim" },
  { id: "L03AA09", title: "sargramostim" },
  { id: "L03AA10", title: "lenograstim" },
  { id: "L03AA12", title: "ancestim" },
  { id: "L03AA13", title: "pegfilgrastim" },
  { id: "L03AA14", title: "lipegfilgrastim" },
  { id: "L03AB", title: "Interferons" },
  { id: "L03AB01", title: "interferon alfa natural" },
  { id: "L03AB02", title: "interferon beta natural" },
  { id: "L03AB03", title: "interferon gamma" },
  { id: "L03AB04", title: "interferon alfa-2a" },
  { id: "L03AB05", title: "interferon alfa-2b" },
  { id: "L03AB06", title: "interferon alfa-n1" },
  { id: "L03AB07", title: "interferon beta-1a" },
  { id: "L03AB08", title: "interferon beta-1b" },
  { id: "L03AB09", title: "interferon alfacon-1" },
  { id: "L03AB10", title: "peginterferon alfa-2b" },
  { id: "L03AB11", title: "peginterferon alfa-2a" },
  { id: "L03AB12", title: "albinterferon alfa-2b" },
  { id: "L03AB60", title: "peginterferon alfa-2b, combinations" },
  { id: "L03AB61", title: "peginterferon alfa-2a, combinations" },
  { id: "L03AC", title: "Interleukins" },
  { id: "L03AC01", title: "aldesleukin" },
  { id: "L03AC02", title: "oprelvekin" },
  { id: "L03AX", title: "Other immunostimulants" },
  { id: "L03AX01", title: "lentinan" },
  { id: "L03AX02", title: "roquinimex" },
  { id: "L03AX03", title: "BCG vaccine" },
  { id: "L03AX04", title: "pegademase" },
  { id: "L03AX05", title: "pidotimod" },
  { id: "L03AX07", title: "poly I:C" },
  { id: "L03AX08", title: "poly ICLC" },
  { id: "L03AX09", title: "thymopentin" },
  { id: "L03AX10", title: "immunocyanin" },
  { id: "L03AX11", title: "tasonermin" },
  { id: "L03AX12", title: "melanoma vaccine" },
  { id: "L03AX13", title: "glatiramer acetate" },
  { id: "L03AX14", title: "histamine dihydrochloride" },
  { id: "L03AX15", title: "mifamurtide" },
  { id: "L03AX16", title: "plerixafor" },
  { id: "L03AX17", title: "sipuleucel-T" },
  { id: "L03AX18", title: "cridanimod" },
  { id: "L04", title: "IMMUNOSUPPRESSANTS" },
  { id: "L04A", title: "IMMUNOSUPPRESSANTS" },
  { id: "L04AA", title: "Selective immunosuppressants" },
  { id: "L04AA02", title: "muromonab-CD3" },
  { id: "L04AA03", title: "antilymphocyte immunoglobulin (horse)" },
  { id: "L04AA04", title: "antithymocyte immunoglobulin (rabbit)" },
  { id: "L04AA06", title: "mycophenolic acid" },
  { id: "L04AA10", title: "sirolimus" },
  { id: "L04AA13", title: "leflunomide" },
  { id: "L04AA15", title: "alefacept" },
  { id: "L04AA18", title: "everolimus" },
  { id: "L04AA19", title: "gusperimus" },
  { id: "L04AA21", title: "efalizumab" },
  { id: "L04AA22", title: "abetimus" },
  { id: "L04AA23", title: "natalizumab" },
  { id: "L04AA24", title: "abatacept" },
  { id: "L04AA25", title: "eculizumab" },
  { id: "L04AA26", title: "belimumab" },
  { id: "L04AA27", title: "fingolimod" },
  { id: "L04AA28", title: "belatacept" },
  { id: "L04AA29", title: "tofacitinib" },
  { id: "L04AA31", title: "teriflunomide" },
  { id: "L04AA32", title: "apremilast" },
  {
    id: "L04AB",
    title: "Tumor necrosis factor alpha (TNF-&alpha;) inhibitors",
  },
  { id: "L04AB01", title: "etanercept" },
  { id: "L04AB02", title: "infliximab" },
  { id: "L04AB03", title: "afelimomab" },
  { id: "L04AB04", title: "adalimumab" },
  { id: "L04AB05", title: "certolizumab pegol" },
  { id: "L04AB06", title: "golimumab" },
  { id: "L04AC", title: "Interleukin inhibitors" },
  { id: "L04AC01", title: "daclizumab" },
  { id: "L04AC02", title: "basiliximab" },
  { id: "L04AC03", title: "anakinra" },
  { id: "L04AC04", title: "rilonacept" },
  { id: "L04AC05", title: "ustekinumab" },
  { id: "L04AC06", title: "mepolizumab" },
  { id: "L04AC07", title: "tocilizumab" },
  { id: "L04AC08", title: "canakinumab" },
  { id: "L04AC09", title: "briakinumab" },
  { id: "L04AC10", title: "secukinumab" },
  { id: "L04AD", title: "Calcineurin inhibitors" },
  { id: "L04AD01", title: "ciclosporin" },
  { id: "L04AD02", title: "tacrolimus" },
  { id: "L04AD03", title: "voclosporin" },
  { id: "L04AX", title: "Other immunosuppressants" },
  { id: "L04AX01", title: "azathioprine" },
  { id: "L04AX02", title: "thalidomide" },
  { id: "L04AX03", title: "methotrexate" },
  { id: "L04AX04", title: "lenalidomide" },
  { id: "L04AX05", title: "pirfenidone" },
  { id: "L04AX06", title: "pomalidomide" },
  { id: "M", title: "MUSCULO-SKELETAL SYSTEM" },
  { id: "M01", title: "ANTIINFLAMMATORY AND ANTIRHEUMATIC PRODUCTS" },
  {
    id: "M01A",
    title: "ANTIINFLAMMATORY AND ANTIRHEUMATIC PRODUCTS, NON-STEROIDS",
  },
  { id: "M01AA", title: "Butylpyrazolidines" },
  { id: "M01AA01", title: "phenylbutazone" },
  { id: "M01AA02", title: "mofebutazone" },
  { id: "M01AA03", title: "oxyphenbutazone" },
  { id: "M01AA05", title: "clofezone" },
  { id: "M01AA06", title: "kebuzone" },
  { id: "M01AB", title: "Acetic acid derivatives and related substances" },
  { id: "M01AB01", title: "indometacin" },
  { id: "M01AB02", title: "sulindac" },
  { id: "M01AB03", title: "tolmetin" },
  { id: "M01AB04", title: "zomepirac" },
  { id: "M01AB05", title: "diclofenac" },
  { id: "M01AB06", title: "alclofenac" },
  { id: "M01AB07", title: "bumadizone" },
  { id: "M01AB08", title: "etodolac" },
  { id: "M01AB09", title: "lonazolac" },
  { id: "M01AB10", title: "fentiazac" },
  { id: "M01AB11", title: "acemetacin" },
  { id: "M01AB12", title: "difenpiramide" },
  { id: "M01AB13", title: "oxametacin" },
  { id: "M01AB14", title: "proglumetacin" },
  { id: "M01AB15", title: "ketorolac" },
  { id: "M01AB16", title: "aceclofenac" },
  { id: "M01AB17", title: "bufexamac" },
  { id: "M01AB51", title: "indometacin, combinations" },
  { id: "M01AB55", title: "diclofenac, combinations" },
  { id: "M01AC", title: "Oxicams" },
  { id: "M01AC01", title: "piroxicam" },
  { id: "M01AC02", title: "tenoxicam" },
  { id: "M01AC04", title: "droxicam" },
  { id: "M01AC05", title: "lornoxicam" },
  { id: "M01AC06", title: "meloxicam" },
  { id: "M01AC56", title: "meloxicam, combinations" },
  { id: "M01AE", title: "Propionic acid derivatives" },
  { id: "M01AE01", title: "ibuprofen" },
  { id: "M01AE02", title: "naproxen" },
  { id: "M01AE03", title: "ketoprofen" },
  { id: "M01AE04", title: "fenoprofen" },
  { id: "M01AE05", title: "fenbufen" },
  { id: "M01AE06", title: "benoxaprofen" },
  { id: "M01AE07", title: "suprofen" },
  { id: "M01AE08", title: "pirprofen" },
  { id: "M01AE09", title: "flurbiprofen" },
  { id: "M01AE10", title: "indoprofen" },
  { id: "M01AE11", title: "tiaprofenic acid" },
  { id: "M01AE12", title: "oxaprozin" },
  { id: "M01AE13", title: "ibuproxam" },
  { id: "M01AE14", title: "dexibuprofen" },
  { id: "M01AE15", title: "flunoxaprofen" },
  { id: "M01AE16", title: "alminoprofen" },
  { id: "M01AE17", title: "dexketoprofen" },
  { id: "M01AE18", title: "naproxcinod" },
  { id: "M01AE51", title: "ibuprofen, combinations" },
  { id: "M01AE52", title: "naproxen and esomeprazole" },
  { id: "M01AE53", title: "ketoprofen, combinations" },
  { id: "M01AE56", title: "naproxen and misoprostol" },
  { id: "M01AG", title: "Fenamates" },
  { id: "M01AG01", title: "mefenamic acid" },
  { id: "M01AG02", title: "tolfenamic acid" },
  { id: "M01AG03", title: "flufenamic acid" },
  { id: "M01AG04", title: "meclofenamic acid" },
  { id: "M01AH", title: "Coxibs" },
  { id: "M01AH01", title: "celecoxib" },
  { id: "M01AH02", title: "rofecoxib" },
  { id: "M01AH03", title: "valdecoxib" },
  { id: "M01AH04", title: "parecoxib" },
  { id: "M01AH05", title: "etoricoxib" },
  { id: "M01AH06", title: "lumiracoxib" },
  {
    id: "M01AX",
    title: "Other antiinflammatory and antirheumatic agents, non-steroids",
  },
  { id: "M01AX01", title: "nabumetone" },
  { id: "M01AX02", title: "niflumic acid" },
  { id: "M01AX04", title: "azapropazone" },
  { id: "M01AX05", title: "glucosamine" },
  { id: "M01AX07", title: "benzydamine" },
  { id: "M01AX12", title: "glucosaminoglycan polysulfate" },
  { id: "M01AX13", title: "proquazone" },
  { id: "M01AX14", title: "orgotein" },
  { id: "M01AX17", title: "nimesulide" },
  { id: "M01AX18", title: "feprazone" },
  { id: "M01AX21", title: "diacerein" },
  { id: "M01AX22", title: "morniflumate" },
  { id: "M01AX23", title: "tenidap" },
  { id: "M01AX24", title: "oxaceprol" },
  { id: "M01AX25", title: "chondroitin sulfate" },
  { id: "M01AX26", title: "avocado and soyabean oil, unsaponifiables" },
  { id: "M01AX68", title: "feprazone, combinations" },
  {
    id: "M01B",
    title: "ANTIINFLAMMATORY/ANTIRHEUMATIC AGENTS IN COMBINATION",
  },
  {
    id: "M01BA",
    title:
      "Antiinflammatory/antirheumatic agents in combination with corticosteroids",
  },
  { id: "M01BA01", title: "phenylbutazone and corticosteroids" },
  { id: "M01BA02", title: "dipyrocetyl and corticosteroids" },
  { id: "M01BA03", title: "acetylsalicylic acid and corticosteroids" },
  {
    id: "M01BX",
    title:
      "Other antiinflammatory/antirheumatic agents in combination with other drugs",
  },
  { id: "M01C", title: "SPECIFIC ANTIRHEUMATIC AGENTS" },
  { id: "M01CA", title: "Quinolines" },
  { id: "M01CA03", title: "oxycinchophen" },
  { id: "M01CB", title: "Gold preparations" },
  { id: "M01CB01", title: "sodium aurothiomalate" },
  { id: "M01CB02", title: "sodium aurotiosulfate" },
  { id: "M01CB03", title: "auranofin" },
  { id: "M01CB04", title: "aurothioglucose" },
  { id: "M01CB05", title: "aurotioprol" },
  { id: "M01CC", title: "Penicillamine and similar agents" },
  { id: "M01CC01", title: "penicillamine" },
  { id: "M01CC02", title: "bucillamine" },
  { id: "M01CX", title: "Other specific antirheumatic agents" },
  { id: "M02", title: "TOPICAL PRODUCTS FOR JOINT AND MUSCULAR PAIN" },
  { id: "M02A", title: "TOPICAL PRODUCTS FOR JOINT AND MUSCULAR PAIN" },
  {
    id: "M02AA",
    title: "Antiinflammatory preparations, non-steroids for topical use",
  },
  { id: "M02AA01", title: "phenylbutazone" },
  { id: "M02AA02", title: "mofebutazone" },
  { id: "M02AA03", title: "clofezone" },
  { id: "M02AA04", title: "oxyphenbutazone" },
  { id: "M02AA05", title: "benzydamine" },
  { id: "M02AA06", title: "etofenamate" },
  { id: "M02AA07", title: "piroxicam" },
  { id: "M02AA08", title: "felbinac" },
  { id: "M02AA09", title: "bufexamac" },
  { id: "M02AA10", title: "ketoprofen" },
  { id: "M02AA11", title: "bendazac" },
  { id: "M02AA12", title: "naproxen" },
  { id: "M02AA13", title: "ibuprofen" },
  { id: "M02AA14", title: "fentiazac" },
  { id: "M02AA15", title: "diclofenac" },
  { id: "M02AA16", title: "feprazone" },
  { id: "M02AA17", title: "niflumic acid" },
  { id: "M02AA18", title: "meclofenamic acid" },
  { id: "M02AA19", title: "flurbiprofen" },
  { id: "M02AA21", title: "tolmetin" },
  { id: "M02AA22", title: "suxibuzone" },
  { id: "M02AA23", title: "indometacin" },
  { id: "M02AA24", title: "nifenazone" },
  { id: "M02AA25", title: "aceclofenac" },
  { id: "M02AA26", title: "nimesulide" },
  { id: "M02AB", title: "Capsaicin and similar agents" },
  { id: "M02AB01", title: "capsaicin" },
  { id: "M02AB02", title: "zucapsaicin" },
  { id: "M02AC", title: "Preparations with salicylic acid derivatives" },
  {
    id: "M02AX",
    title: "Other topical products for joint and muscular pain",
  },
  { id: "M02AX02", title: "tolazoline" },
  { id: "M02AX03", title: "dimethyl sulfoxide" },
  { id: "M02AX05", title: "idrocilamide" },
  { id: "M02AX06", title: "tolperisone" },
  { id: "M02AX10", title: "various" },
  { id: "M03", title: "MUSCLE RELAXANTS" },
  { id: "M03A", title: "MUSCLE RELAXANTS, PERIPHERALLY ACTING AGENTS" },
  { id: "M03AA", title: "Curare alkaloids" },
  { id: "M03AA01", title: "alcuronium" },
  { id: "M03AA02", title: "tubocurarine" },
  { id: "M03AA04", title: "dimethyltubocurarine" },
  { id: "M03AB", title: "Choline derivatives" },
  { id: "M03AB01", title: "suxamethonium" },
  { id: "M03AC", title: "Other quaternary ammonium compounds" },
  { id: "M03AC01", title: "pancuronium" },
  { id: "M03AC02", title: "gallamine" },
  { id: "M03AC03", title: "vecuronium" },
  { id: "M03AC04", title: "atracurium" },
  { id: "M03AC05", title: "hexafluronium" },
  { id: "M03AC06", title: "pipecuronium bromide" },
  { id: "M03AC07", title: "doxacurium chloride" },
  { id: "M03AC08", title: "fazadinium bromide" },
  { id: "M03AC09", title: "rocuronium bromide" },
  { id: "M03AC10", title: "mivacurium chloride" },
  { id: "M03AC11", title: "cisatracurium" },
  {
    id: "M03AX",
    title: "Other muscle relaxants, peripherally acting agents",
  },
  { id: "M03AX01", title: "botulinum toxin" },
  { id: "M03B", title: "MUSCLE RELAXANTS, CENTRALLY ACTING AGENTS" },
  { id: "M03BA", title: "Carbamic acid esters" },
  { id: "M03BA01", title: "phenprobamate" },
  { id: "M03BA02", title: "carisoprodol" },
  { id: "M03BA03", title: "methocarbamol" },
  { id: "M03BA04", title: "styramate" },
  { id: "M03BA05", title: "febarbamate" },
  {
    id: "M03BA51",
    title: "phenprobamate, combinations excl. psycholeptics",
  },
  {
    id: "M03BA52",
    title: "carisoprodol, combinations excl. psycholeptics",
  },
  {
    id: "M03BA53",
    title: "methocarbamol, combinations excl. psycholeptics",
  },
  {
    id: "M03BA71",
    title: "phenprobamate, combinations with psycholeptics",
  },
  { id: "M03BA72", title: "carisoprodol, combinations with psycholeptics" },
  {
    id: "M03BA73",
    title: "methocarbamol, combinations with psycholeptics",
  },
  { id: "M03BB", title: "Oxazol, thiazine, and triazine derivatives" },
  { id: "M03BB02", title: "chlormezanone" },
  { id: "M03BB03", title: "chlorzoxazone" },
  {
    id: "M03BB52",
    title: "chlormezanone, combinations excl. psycholeptics",
  },
  {
    id: "M03BB53",
    title: "chlorzoxazone, combinations excl. psycholeptics",
  },
  {
    id: "M03BB72",
    title: "chlormezanone, combinations with psycholeptics",
  },
  {
    id: "M03BB73",
    title: "chlorzoxazone, combinations with psycholeptics",
  },
  { id: "M03BC", title: "Ethers, chemically close to antihistamines" },
  { id: "M03BC01", title: "orphenadrine (citrate)" },
  { id: "M03BC51", title: "orphenadrine, combinations" },
  { id: "M03BX", title: "Other centrally acting agents" },
  { id: "M03BX01", title: "baclofen" },
  { id: "M03BX02", title: "tizanidine" },
  { id: "M03BX03", title: "pridinol" },
  { id: "M03BX04", title: "tolperisone" },
  { id: "M03BX05", title: "thiocolchicoside" },
  { id: "M03BX06", title: "mephenesin" },
  { id: "M03BX07", title: "tetrazepam" },
  { id: "M03BX08", title: "cyclobenzaprine" },
  { id: "M03BX09", title: "eperisone" },
  { id: "M03BX30", title: "fenyramidol" },
  { id: "M03C", title: "MUSCLE RELAXANTS, DIRECTLY ACTING AGENTS" },
  { id: "M03CA", title: "Dantrolene and derivatives" },
  { id: "M03CA01", title: "dantrolene" },
  { id: "M04", title: "ANTIGOUT PREPARATIONS" },
  { id: "M04A", title: "ANTIGOUT PREPARATIONS" },
  { id: "M04AA", title: "Preparations inhibiting uric acid production" },
  { id: "M04AA01", title: "allopurinol" },
  { id: "M04AA02", title: "tisopurine" },
  { id: "M04AA03", title: "febuxostat" },
  { id: "M04AA51", title: "allopurinol, combinations" },
  { id: "M04AB", title: "Preparations increasing uric acid excretion" },
  { id: "M04AB01", title: "probenecid" },
  { id: "M04AB02", title: "sulfinpyrazone" },
  { id: "M04AB03", title: "benzbromarone" },
  { id: "M04AB04", title: "isobromindione" },
  {
    id: "M04AC",
    title: "Preparations with no effect on uric acid metabolism",
  },
  { id: "M04AC01", title: "colchicine" },
  { id: "M04AC02", title: "cinchophen" },
  { id: "M04AX", title: "Other antigout preparations" },
  { id: "M04AX01", title: "urate oxidase" },
  { id: "M04AX02", title: "pegloticase" },
  { id: "M05", title: "DRUGS FOR TREATMENT OF BONE DISEASES" },
  {
    id: "M05B",
    title: "DRUGS AFFECTING BONE STRUCTURE AND MINERALIZATION",
  },
  { id: "M05BA", title: "Bisphosphonates" },
  { id: "M05BA01", title: "etidronic acid" },
  { id: "M05BA02", title: "clodronic acid" },
  { id: "M05BA03", title: "pamidronic acid" },
  { id: "M05BA04", title: "alendronic acid" },
  { id: "M05BA05", title: "tiludronic acid" },
  { id: "M05BA06", title: "ibandronic acid" },
  { id: "M05BA07", title: "risedronic acid" },
  { id: "M05BA08", title: "zoledronic acid" },
  { id: "M05BB", title: "Bisphosphonates, combinations" },
  { id: "M05BB01", title: "etidronic acid and calcium, sequential" },
  { id: "M05BB02", title: "risedronic acid and calcium, sequential" },
  { id: "M05BB03", title: "alendronic acid and colecalciferol" },
  {
    id: "M05BB04",
    title: "risedronic acid, calcium and colecalciferol, sequential",
  },
  {
    id: "M05BB05",
    title: "alendronic acid, calcium and colecalciferol, sequential",
  },
  { id: "M05BB06", title: "alendronic acid and alfacalcidol, sequential" },
  { id: "M05BC", title: "Bone morphogenetic proteins" },
  { id: "M05BC01", title: "dibotermin alfa" },
  { id: "M05BC02", title: "eptotermin alfa" },
  {
    id: "M05BX",
    title: "Other drugs affecting bone structure and mineralization",
  },
  { id: "M05BX01", title: "ipriflavone" },
  { id: "M05BX02", title: "aluminium chlorohydrate" },
  { id: "M05BX03", title: "strontium ranelate" },
  { id: "M05BX04", title: "denosumab" },
  { id: "M05BX53", title: "strontium ranelate and colecalciferol" },
  {
    id: "M09",
    title: "OTHER DRUGS FOR DISORDERS OF THE MUSCULO-SKELETAL SYSTEM",
  },
  {
    id: "M09A",
    title: "OTHER DRUGS FOR DISORDERS OF THE MUSCULO-SKELETAL SYSTEM",
  },
  { id: "M09AA", title: "Quinine and derivatives" },
  { id: "M09AA01", title: "hydroquinine" },
  { id: "M09AA72", title: "quinine, combinations with psycholeptics" },
  { id: "M09AB", title: "Enzymes" },
  { id: "M09AB01", title: "chymopapain" },
  { id: "M09AB02", title: "collagenase clostridium histolyticum" },
  { id: "M09AB52", title: "trypsin, combinations" },
  {
    id: "M09AX",
    title: "Other drugs for disorders of the musculo-skeletal system",
  },
  { id: "M09AX01", title: "hyaluronic acid" },
  { id: "M09AX02", title: "chondrocytes, autologous" },
  { id: "N", title: "NERVOUS SYSTEM" },
  { id: "N01", title: "ANESTHETICS" },
  { id: "N01A", title: "ANESTHETICS, GENERAL" },
  { id: "N01AA", title: "Ethers" },
  { id: "N01AA01", title: "diethyl ether" },
  { id: "N01AA02", title: "vinyl ether" },
  { id: "N01AB", title: "Halogenated hydrocarbons" },
  { id: "N01AB01", title: "halothane" },
  { id: "N01AB02", title: "chloroform" },
  { id: "N01AB04", title: "enflurane" },
  { id: "N01AB05", title: "trichloroethylene" },
  { id: "N01AB06", title: "isoflurane" },
  { id: "N01AB07", title: "desflurane" },
  { id: "N01AB08", title: "sevoflurane" },
  { id: "N01AF", title: "Barbiturates, plain" },
  { id: "N01AF01", title: "methohexital" },
  { id: "N01AF02", title: "hexobarbital" },
  { id: "N01AF03", title: "thiopental" },
  { id: "N01AG", title: "Barbiturates in combination with other drugs" },
  { id: "N01AG01", title: "narcobarbital" },
  { id: "N01AH", title: "Opioid anesthetics" },
  { id: "N01AH01", title: "fentanyl" },
  { id: "N01AH02", title: "alfentanil" },
  { id: "N01AH03", title: "sufentanil" },
  { id: "N01AH04", title: "phenoperidine" },
  { id: "N01AH05", title: "anileridine" },
  { id: "N01AH06", title: "remifentanil" },
  { id: "N01AH51", title: "fentanyl, combinations" },
  { id: "N01AX", title: "Other general anesthetics" },
  { id: "N01AX03", title: "ketamine" },
  { id: "N01AX04", title: "propanidid" },
  { id: "N01AX05", title: "alfaxalone" },
  { id: "N01AX07", title: "etomidate" },
  { id: "N01AX10", title: "propofol" },
  { id: "N01AX11", title: "sodium oxybate" },
  { id: "N01AX13", title: "nitrous oxide" },
  { id: "N01AX14", title: "esketamine" },
  { id: "N01AX15", title: "xenon" },
  { id: "N01AX63", title: "nitrous oxide, combinations" },
  { id: "N01B", title: "ANESTHETICS, LOCAL" },
  { id: "N01BA", title: "Esters of aminobenzoic acid" },
  { id: "N01BA01", title: "metabutethamine" },
  { id: "N01BA02", title: "procaine" },
  { id: "N01BA03", title: "tetracaine" },
  { id: "N01BA04", title: "chloroprocaine" },
  { id: "N01BA05", title: "benzocaine" },
  { id: "N01BA52", title: "procaine, combinations" },
  { id: "N01BB", title: "Amides" },
  { id: "N01BB01", title: "bupivacaine" },
  { id: "N01BB02", title: "lidocaine" },
  { id: "N01BB03", title: "mepivacaine" },
  { id: "N01BB04", title: "prilocaine" },
  { id: "N01BB05", title: "butanilicaine" },
  { id: "N01BB06", title: "cinchocaine" },
  { id: "N01BB07", title: "etidocaine" },
  { id: "N01BB08", title: "articaine" },
  { id: "N01BB09", title: "ropivacaine" },
  { id: "N01BB10", title: "levobupivacaine" },
  { id: "N01BB20", title: "combinations" },
  { id: "N01BB51", title: "bupivacaine, combinations" },
  { id: "N01BB52", title: "lidocaine, combinations" },
  { id: "N01BB53", title: "mepivacaine, combinations" },
  { id: "N01BB54", title: "prilocaine, combinations" },
  { id: "N01BB57", title: "etidocaine, combinations" },
  { id: "N01BB58", title: "articaine, combinations" },
  { id: "N01BC", title: "Esters of benzoic acid" },
  { id: "N01BC01", title: "cocaine" },
  { id: "N01BX", title: "Other local anesthetics" },
  { id: "N01BX01", title: "ethyl chloride" },
  { id: "N01BX02", title: "dyclonine" },
  { id: "N01BX03", title: "phenol" },
  { id: "N01BX04", title: "capsaicin" },
  { id: "N02", title: "ANALGESICS" },
  { id: "N02A", title: "OPIOIDS" },
  { id: "N02AA", title: "Natural opium alkaloids" },
  { id: "N02AA01", title: "morphine" },
  { id: "N02AA02", title: "opium" },
  { id: "N02AA03", title: "hydromorphone" },
  { id: "N02AA04", title: "nicomorphine" },
  { id: "N02AA05", title: "oxycodone" },
  { id: "N02AA08", title: "dihydrocodeine" },
  { id: "N02AA10", title: "papaveretum" },
  { id: "N02AA51", title: "morphine, combinations" },
  { id: "N02AA55", title: "oxycodone, combinations" },
  { id: "N02AA58", title: "dihydrocodeine, combinations" },
  { id: "N02AA59", title: "codeine, combinations excl. psycholeptics" },
  { id: "N02AA79", title: "codeine, combinations with psycholeptics" },
  { id: "N02AB", title: "Phenylpiperidine derivatives" },
  { id: "N02AB01", title: "ketobemidone" },
  { id: "N02AB02", title: "pethidine" },
  { id: "N02AB03", title: "fentanyl" },
  { id: "N02AB52", title: "pethidine, combinations excl. psycholeptics" },
  { id: "N02AB72", title: "pethidine, combinations with psycholeptics" },
  { id: "N02AC", title: "Diphenylpropylamine derivatives" },
  { id: "N02AC01", title: "dextromoramide" },
  { id: "N02AC03", title: "piritramide" },
  { id: "N02AC04", title: "dextropropoxyphene" },
  { id: "N02AC05", title: "bezitramide" },
  { id: "N02AC52", title: "methadone, combinations excl. psycholeptics" },
  {
    id: "N02AC54",
    title: "dextropropoxyphene, combinations excl. psycholeptics",
  },
  {
    id: "N02AC74",
    title: "dextropropoxyphene, combinations with psycholeptics",
  },
  { id: "N02AD", title: "Benzomorphan derivatives" },
  { id: "N02AD01", title: "pentazocine" },
  { id: "N02AD02", title: "phenazocine" },
  { id: "N02AE", title: "Oripavine derivatives" },
  { id: "N02AE01", title: "buprenorphine" },
  { id: "N02AF", title: "Morphinan derivatives" },
  { id: "N02AF01", title: "butorphanol" },
  { id: "N02AF02", title: "nalbuphine" },
  { id: "N02AG", title: "Opioids in combination with antispasmodics" },
  { id: "N02AG01", title: "morphine and antispasmodics" },
  { id: "N02AG02", title: "ketobemidone and antispasmodics" },
  { id: "N02AG03", title: "pethidine and antispasmodics" },
  { id: "N02AG04", title: "hydromorphone and antispasmodics" },
  { id: "N02AX", title: "Other opioids" },
  { id: "N02AX01", title: "tilidine" },
  { id: "N02AX02", title: "tramadol" },
  { id: "N02AX03", title: "dezocine" },
  { id: "N02AX05", title: "meptazinol" },
  { id: "N02AX06", title: "tapentadol" },
  { id: "N02AX52", title: "tramadol, combinations" },
  { id: "N02B", title: "OTHER ANALGESICS AND ANTIPYRETICS" },
  { id: "N02BA", title: "Salicylic acid and derivatives" },
  { id: "N02BA01", title: "acetylsalicylic acid" },
  { id: "N02BA02", title: "aloxiprin" },
  { id: "N02BA03", title: "choline salicylate" },
  { id: "N02BA04", title: "sodium salicylate" },
  { id: "N02BA05", title: "salicylamide" },
  { id: "N02BA06", title: "salsalate" },
  { id: "N02BA07", title: "ethenzamide" },
  { id: "N02BA08", title: "morpholine salicylate" },
  { id: "N02BA09", title: "dipyrocetyl" },
  { id: "N02BA10", title: "benorilate" },
  { id: "N02BA11", title: "diflunisal" },
  { id: "N02BA12", title: "potassium salicylate" },
  { id: "N02BA14", title: "guacetisal" },
  { id: "N02BA15", title: "carbasalate calcium" },
  { id: "N02BA16", title: "imidazole salicylate" },
  {
    id: "N02BA51",
    title: "acetylsalicylic acid, combinations excl. psycholeptics",
  },
  {
    id: "N02BA55",
    title: "salicylamide, combinations excl. psycholeptics",
  },
  { id: "N02BA57", title: "ethenzamide, combinations excl. psycholeptics" },
  { id: "N02BA59", title: "dipyrocetyl, combinations excl. psycholeptics" },
  {
    id: "N02BA65",
    title: "carbasalate calcium combinations excl. psycholeptics",
  },
  {
    id: "N02BA71",
    title: "acetylsalicylic acid, combinations with psycholeptics",
  },
  { id: "N02BA75", title: "salicylamide, combinations with psycholeptics" },
  { id: "N02BA77", title: "ethenzamide, combinations with psycholeptics" },
  { id: "N02BA79", title: "dipyrocetyl, combinations with psycholeptics" },
  { id: "N02BB", title: "Pyrazolones" },
  { id: "N02BB01", title: "phenazone" },
  { id: "N02BB02", title: "metamizole sodium" },
  { id: "N02BB03", title: "aminophenazone" },
  { id: "N02BB04", title: "propyphenazone" },
  { id: "N02BB05", title: "nifenazone" },
  { id: "N02BB51", title: "phenazone, combinations excl. psycholeptics" },
  {
    id: "N02BB52",
    title: "metamizole sodium, combinations excl. psycholeptics",
  },
  {
    id: "N02BB53",
    title: "aminophenazone, combinations excl. psycholeptics",
  },
  {
    id: "N02BB54",
    title: "propyphenazone, combinations excl. psycholeptics",
  },
  { id: "N02BB71", title: "phenazone, combinations with psycholeptics" },
  {
    id: "N02BB72",
    title: "metamizole sodium, combinations with psycholeptics",
  },
  {
    id: "N02BB73",
    title: "aminophenazone, combinations with psycholeptics",
  },
  {
    id: "N02BB74",
    title: "propyphenazone, combinations with psycholeptics",
  },
  { id: "N02BE", title: "Anilides" },
  { id: "N02BE01", title: "paracetamol" },
  { id: "N02BE03", title: "phenacetin" },
  { id: "N02BE04", title: "bucetin" },
  { id: "N02BE05", title: "propacetamol" },
  { id: "N02BE51", title: "paracetamol, combinations excl. psycholeptics" },
  { id: "N02BE53", title: "phenacetin, combinations excl. psycholeptics" },
  { id: "N02BE54", title: "bucetin, combinations excl. psycholeptics" },
  { id: "N02BE71", title: "paracetamol, combinations with psycholeptics" },
  { id: "N03", title: "ANTIEPILEPTICS" },
  { id: "N03A", title: "ANTIEPILEPTICS" },
  { id: "N03AA", title: "Barbiturates and derivatives" },
  { id: "N03AA01", title: "methylphenobarbital" },
  { id: "N03AA02", title: "phenobarbital" },
  { id: "N03AA03", title: "primidone" },
  { id: "N03AA04", title: "barbexaclone" },
  { id: "N03AA30", title: "metharbital" },
  { id: "N03AB", title: "Hydantoin derivatives" },
  { id: "N03AB01", title: "ethotoin" },
  { id: "N03AB02", title: "phenytoin" },
  { id: "N03AB03", title: "amino(diphenylhydantoin) valeric acid" },
  { id: "N03AB04", title: "mephenytoin" },
  { id: "N03AB05", title: "fosphenytoin" },
  { id: "N03AB52", title: "phenytoin, combinations" },
  { id: "N03AB54", title: "mephenytoin, combinations" },
  { id: "N03AC", title: "Oxazolidine derivatives" },
  { id: "N03AC01", title: "paramethadione" },
  { id: "N03AC02", title: "trimethadione" },
  { id: "N03AC03", title: "ethadione" },
  { id: "N03AD", title: "Succinimide derivatives" },
  { id: "N03AD01", title: "ethosuximide" },
  { id: "N03AD02", title: "phensuximide" },
  { id: "N03AD03", title: "mesuximide" },
  { id: "N03AD51", title: "ethosuximide, combinations" },
  { id: "N03AE", title: "Benzodiazepine derivatives" },
  { id: "N03AE01", title: "clonazepam" },
  { id: "N03AF", title: "Carboxamide derivatives" },
  { id: "N03AF01", title: "carbamazepine" },
  { id: "N03AF02", title: "oxcarbazepine" },
  { id: "N03AF03", title: "rufinamide" },
  { id: "N03AF04", title: "eslicarbazepine" },
  { id: "N03AG", title: "Fatty acid derivatives" },
  { id: "N03AG01", title: "valproic acid" },
  { id: "N03AG02", title: "valpromide" },
  { id: "N03AG03", title: "aminobutyric acid" },
  { id: "N03AG04", title: "vigabatrin" },
  { id: "N03AG05", title: "progabide" },
  { id: "N03AG06", title: "tiagabine" },
  { id: "N03AX", title: "Other antiepileptics" },
  { id: "N03AX03", title: "sultiame" },
  { id: "N03AX07", title: "phenacemide" },
  { id: "N03AX09", title: "lamotrigine" },
  { id: "N03AX10", title: "felbamate" },
  { id: "N03AX11", title: "topiramate" },
  { id: "N03AX12", title: "gabapentin" },
  { id: "N03AX13", title: "pheneturide" },
  { id: "N03AX14", title: "levetiracetam" },
  { id: "N03AX15", title: "zonisamide" },
  { id: "N03AX16", title: "pregabalin" },
  { id: "N03AX17", title: "stiripentol" },
  { id: "N03AX18", title: "lacosamide" },
  { id: "N03AX19", title: "carisbamate" },
  { id: "N03AX21", title: "retigabine" },
  { id: "N03AX22", title: "perampanel" },
  { id: "N03AX30", title: "beclamide" },
  { id: "N04", title: "ANTI-PARKINSON DRUGS" },
  { id: "N04A", title: "ANTICHOLINERGIC AGENTS" },
  { id: "N04AA", title: "Tertiary amines" },
  { id: "N04AA01", title: "trihexyphenidyl" },
  { id: "N04AA02", title: "biperiden" },
  { id: "N04AA03", title: "metixene" },
  { id: "N04AA04", title: "procyclidine" },
  { id: "N04AA05", title: "profenamine" },
  { id: "N04AA08", title: "dexetimide" },
  { id: "N04AA09", title: "phenglutarimide" },
  { id: "N04AA10", title: "mazaticol" },
  { id: "N04AA11", title: "bornaprine" },
  { id: "N04AA12", title: "tropatepine" },
  { id: "N04AB", title: "Ethers chemically close to antihistamines" },
  { id: "N04AB01", title: "etanautine" },
  { id: "N04AB02", title: "orphenadrine (chloride)" },
  { id: "N04AC", title: "Ethers of tropine or tropine derivatives" },
  { id: "N04AC01", title: "benzatropine" },
  { id: "N04AC30", title: "etybenzatropine" },
  { id: "N04B", title: "DOPAMINERGIC AGENTS" },
  { id: "N04BA", title: "Dopa and dopa derivatives" },
  { id: "N04BA01", title: "levodopa" },
  { id: "N04BA02", title: "levodopa and decarboxylase inhibitor" },
  {
    id: "N04BA03",
    title: "levodopa, decarboxylase inhibitor and COMT inhibitor",
  },
  { id: "N04BA04", title: "melevodopa" },
  { id: "N04BA05", title: "melevodopa and decarboxylase inhibitor" },
  { id: "N04BA06", title: "etilevodopa and decarboxylase inhibitor" },
  { id: "N04BB", title: "Adamantane derivatives" },
  { id: "N04BB01", title: "amantadine" },
  { id: "N04BC", title: "Dopamine agonists" },
  { id: "N04BC01", title: "bromocriptine" },
  { id: "N04BC02", title: "pergolide" },
  { id: "N04BC03", title: "dihydroergocryptine mesylate" },
  { id: "N04BC04", title: "ropinirole" },
  { id: "N04BC05", title: "pramipexole" },
  { id: "N04BC06", title: "cabergoline" },
  { id: "N04BC07", title: "apomorphine" },
  { id: "N04BC08", title: "piribedil" },
  { id: "N04BC09", title: "rotigotine" },
  { id: "N04BD", title: "Monoamine oxidase B inhibitors" },
  { id: "N04BD01", title: "selegiline" },
  { id: "N04BD02", title: "rasagiline" },
  { id: "N04BX", title: "Other dopaminergic agents" },
  { id: "N04BX01", title: "tolcapone" },
  { id: "N04BX02", title: "entacapone" },
  { id: "N04BX03", title: "budipine" },
  { id: "N05", title: "PSYCHOLEPTICS" },
  { id: "N05A", title: "ANTIPSYCHOTICS" },
  { id: "N05AA", title: "Phenothiazines with aliphatic side-chain" },
  { id: "N05AA01", title: "chlorpromazine" },
  { id: "N05AA02", title: "levomepromazine" },
  { id: "N05AA03", title: "promazine" },
  { id: "N05AA04", title: "acepromazine" },
  { id: "N05AA05", title: "triflupromazine" },
  { id: "N05AA06", title: "cyamemazine" },
  { id: "N05AA07", title: "chlorproethazine" },
  { id: "N05AB", title: "Phenothiazines with piperazine structure" },
  { id: "N05AB01", title: "dixyrazine" },
  { id: "N05AB02", title: "fluphenazine" },
  { id: "N05AB03", title: "perphenazine" },
  { id: "N05AB04", title: "prochlorperazine" },
  { id: "N05AB05", title: "thiopropazate" },
  { id: "N05AB06", title: "trifluoperazine" },
  { id: "N05AB07", title: "acetophenazine" },
  { id: "N05AB08", title: "thioproperazine" },
  { id: "N05AB09", title: "butaperazine" },
  { id: "N05AB10", title: "perazine" },
  { id: "N05AC", title: "Phenothiazines with piperidine structure" },
  { id: "N05AC01", title: "periciazine" },
  { id: "N05AC02", title: "thioridazine" },
  { id: "N05AC03", title: "mesoridazine" },
  { id: "N05AC04", title: "pipotiazine" },
  { id: "N05AD", title: "Butyrophenone derivatives" },
  { id: "N05AD01", title: "haloperidol" },
  { id: "N05AD02", title: "trifluperidol" },
  { id: "N05AD03", title: "melperone" },
  { id: "N05AD04", title: "moperone" },
  { id: "N05AD05", title: "pipamperone" },
  { id: "N05AD06", title: "bromperidol" },
  { id: "N05AD07", title: "benperidol" },
  { id: "N05AD08", title: "droperidol" },
  { id: "N05AD09", title: "fluanisone" },
  { id: "N05AE", title: "Indole derivatives" },
  { id: "N05AE01", title: "oxypertine" },
  { id: "N05AE02", title: "molindone" },
  { id: "N05AE03", title: "sertindole" },
  { id: "N05AE04", title: "ziprasidone" },
  { id: "N05AE05", title: "lurasidone" },
  { id: "N05AF", title: "Thioxanthene derivatives" },
  { id: "N05AF01", title: "flupentixol" },
  { id: "N05AF02", title: "clopenthixol" },
  { id: "N05AF03", title: "chlorprothixene" },
  { id: "N05AF04", title: "tiotixene" },
  { id: "N05AF05", title: "zuclopenthixol" },
  { id: "N05AG", title: "Diphenylbutylpiperidine derivatives" },
  { id: "N05AG01", title: "fluspirilene" },
  { id: "N05AG02", title: "pimozide" },
  { id: "N05AG03", title: "penfluridol" },
  {
    id: "N05AH",
    title: "Diazepines, oxazepines, thiazepines and oxepines",
  },
  { id: "N05AH01", title: "loxapine" },
  { id: "N05AH02", title: "clozapine" },
  { id: "N05AH03", title: "olanzapine" },
  { id: "N05AH04", title: "quetiapine" },
  { id: "N05AH05", title: "asenapine" },
  { id: "N05AH06", title: "clotiapine" },
  { id: "N05AL", title: "Benzamides" },
  { id: "N05AL01", title: "sulpiride" },
  { id: "N05AL02", title: "sultopride" },
  { id: "N05AL03", title: "tiapride" },
  { id: "N05AL04", title: "remoxipride" },
  { id: "N05AL05", title: "amisulpride" },
  { id: "N05AL06", title: "veralipride" },
  { id: "N05AL07", title: "levosulpiride" },
  { id: "N05AN", title: "Lithium" },
  { id: "N05AN01", title: "lithium" },
  { id: "N05AX", title: "Other antipsychotics" },
  { id: "N05AX07", title: "prothipendyl" },
  { id: "N05AX08", title: "risperidone" },
  { id: "N05AX10", title: "mosapramine" },
  { id: "N05AX11", title: "zotepine" },
  { id: "N05AX12", title: "aripiprazole" },
  { id: "N05AX13", title: "paliperidone" },
  { id: "N05AX14", title: "iloperidone" },
  { id: "N05B", title: "ANXIOLYTICS" },
  { id: "N05BA", title: "Benzodiazepine derivatives" },
  { id: "N05BA01", title: "diazepam" },
  { id: "N05BA02", title: "chlordiazepoxide" },
  { id: "N05BA03", title: "medazepam" },
  { id: "N05BA04", title: "oxazepam" },
  { id: "N05BA05", title: "potassium clorazepate" },
  { id: "N05BA06", title: "lorazepam" },
  { id: "N05BA07", title: "adinazolam" },
  { id: "N05BA08", title: "bromazepam" },
  { id: "N05BA09", title: "clobazam" },
  { id: "N05BA10", title: "ketazolam" },
  { id: "N05BA11", title: "prazepam" },
  { id: "N05BA12", title: "alprazolam" },
  { id: "N05BA13", title: "halazepam" },
  { id: "N05BA14", title: "pinazepam" },
  { id: "N05BA15", title: "camazepam" },
  { id: "N05BA16", title: "nordazepam" },
  { id: "N05BA17", title: "fludiazepam" },
  { id: "N05BA18", title: "ethyl loflazepate" },
  { id: "N05BA19", title: "etizolam" },
  { id: "N05BA21", title: "clotiazepam" },
  { id: "N05BA22", title: "cloxazolam" },
  { id: "N05BA23", title: "tofisopam" },
  { id: "N05BA56", title: "lorazepam, combinations" },
  { id: "N05BB", title: "Diphenylmethane derivatives" },
  { id: "N05BB01", title: "hydroxyzine" },
  { id: "N05BB02", title: "captodiame" },
  { id: "N05BB51", title: "hydroxyzine, combinations" },
  { id: "N05BC", title: "Carbamates" },
  { id: "N05BC01", title: "meprobamate" },
  { id: "N05BC03", title: "emylcamate" },
  { id: "N05BC04", title: "mebutamate" },
  { id: "N05BC51", title: "meprobamate, combinations" },
  { id: "N05BD", title: "Dibenzo-bicyclo-octadiene derivatives" },
  { id: "N05BD01", title: "benzoctamine" },
  { id: "N05BE", title: "Azaspirodecanedione derivatives" },
  { id: "N05BE01", title: "buspirone" },
  { id: "N05BX", title: "Other anxiolytics" },
  { id: "N05BX01", title: "mephenoxalone" },
  { id: "N05BX02", title: "gedocarnil" },
  { id: "N05BX03", title: "etifoxine" },
  { id: "N05C", title: "HYPNOTICS AND SEDATIVES" },
  { id: "N05CA", title: "Barbiturates, plain" },
  { id: "N05CA01", title: "pentobarbital" },
  { id: "N05CA02", title: "amobarbital" },
  { id: "N05CA03", title: "butobarbital" },
  { id: "N05CA04", title: "barbital" },
  { id: "N05CA05", title: "aprobarbital" },
  { id: "N05CA06", title: "secobarbital" },
  { id: "N05CA07", title: "talbutal" },
  { id: "N05CA08", title: "vinylbital" },
  { id: "N05CA09", title: "vinbarbital" },
  { id: "N05CA10", title: "cyclobarbital" },
  { id: "N05CA11", title: "heptabarbital" },
  { id: "N05CA12", title: "reposal" },
  { id: "N05CA15", title: "methohexital" },
  { id: "N05CA16", title: "hexobarbital" },
  { id: "N05CA19", title: "thiopental" },
  { id: "N05CA20", title: "etallobarbital" },
  { id: "N05CA21", title: "allobarbital" },
  { id: "N05CA22", title: "proxibarbal" },
  { id: "N05CB", title: "Barbiturates, combinations" },
  { id: "N05CB01", title: "combinations of barbiturates" },
  { id: "N05CB02", title: "barbiturates in combination with other drugs" },
  { id: "N05CC", title: "Aldehydes and derivatives" },
  { id: "N05CC01", title: "chloral hydrate" },
  { id: "N05CC02", title: "chloralodol" },
  { id: "N05CC03", title: "acetylglycinamide chloral hydrate" },
  { id: "N05CC04", title: "dichloralphenazone" },
  { id: "N05CC05", title: "paraldehyde" },
  { id: "N05CD", title: "Benzodiazepine derivatives" },
  { id: "N05CD01", title: "flurazepam" },
  { id: "N05CD02", title: "nitrazepam" },
  { id: "N05CD03", title: "flunitrazepam" },
  { id: "N05CD04", title: "estazolam" },
  { id: "N05CD05", title: "triazolam" },
  { id: "N05CD06", title: "lormetazepam" },
  { id: "N05CD07", title: "temazepam" },
  { id: "N05CD08", title: "midazolam" },
  { id: "N05CD09", title: "brotizolam" },
  { id: "N05CD10", title: "quazepam" },
  { id: "N05CD11", title: "loprazolam" },
  { id: "N05CD12", title: "doxefazepam" },
  { id: "N05CD13", title: "cinolazepam" },
  { id: "N05CE", title: "Piperidinedione derivatives" },
  { id: "N05CE01", title: "glutethimide" },
  { id: "N05CE02", title: "methyprylon" },
  { id: "N05CE03", title: "pyrithyldione" },
  { id: "N05CF", title: "Benzodiazepine related drugs" },
  { id: "N05CF01", title: "zopiclone" },
  { id: "N05CF02", title: "zolpidem" },
  { id: "N05CF03", title: "zaleplon" },
  { id: "N05CF04", title: "eszopiclone" },
  { id: "N05CH", title: "Melatonin receptor agonists" },
  { id: "N05CH01", title: "melatonin" },
  { id: "N05CH02", title: "ramelteon" },
  { id: "N05CM", title: "Other hypnotics and sedatives" },
  { id: "N05CM01", title: "methaqualone" },
  { id: "N05CM02", title: "clomethiazole" },
  { id: "N05CM03", title: "bromisoval" },
  { id: "N05CM04", title: "carbromal" },
  { id: "N05CM05", title: "scopolamine" },
  { id: "N05CM06", title: "propiomazine" },
  { id: "N05CM07", title: "triclofos" },
  { id: "N05CM08", title: "ethchlorvynol" },
  { id: "N05CM09", title: "Valerianae radix" },
  { id: "N05CM10", title: "hexapropymate" },
  { id: "N05CM11", title: "bromides" },
  { id: "N05CM12", title: "apronal" },
  { id: "N05CM13", title: "valnoctamide" },
  { id: "N05CM15", title: "methylpentynol" },
  { id: "N05CM16", title: "niaprazine" },
  { id: "N05CM18", title: "dexmedetomidine" },
  {
    id: "N05CX",
    title: "Hypnotics and sedatives in combination, excl. barbiturates",
  },
  { id: "N05CX01", title: "meprobamate, combinations" },
  { id: "N05CX02", title: "methaqualone, combinations" },
  { id: "N05CX03", title: "methylpentynol, combinations" },
  { id: "N05CX04", title: "clomethiazole, combinations" },
  { id: "N05CX05", title: "emepronium, combinations" },
  { id: "N05CX06", title: "dipiperonylaminoethanol, combinations" },
  { id: "N06", title: "PSYCHOANALEPTICS" },
  { id: "N06A", title: "ANTIDEPRESSANTS" },
  { id: "N06AA", title: "Non-selective monoamine reuptake inhibitors" },
  { id: "N06AA01", title: "desipramine" },
  { id: "N06AA02", title: "imipramine" },
  { id: "N06AA03", title: "imipramine oxide" },
  { id: "N06AA04", title: "clomipramine" },
  { id: "N06AA05", title: "opipramol" },
  { id: "N06AA06", title: "trimipramine" },
  { id: "N06AA07", title: "lofepramine" },
  { id: "N06AA08", title: "dibenzepin" },
  { id: "N06AA09", title: "amitriptyline" },
  { id: "N06AA10", title: "nortriptyline" },
  { id: "N06AA11", title: "protriptyline" },
  { id: "N06AA12", title: "doxepin" },
  { id: "N06AA13", title: "iprindole" },
  { id: "N06AA14", title: "melitracen" },
  { id: "N06AA15", title: "butriptyline" },
  { id: "N06AA16", title: "dosulepin" },
  { id: "N06AA17", title: "amoxapine" },
  { id: "N06AA18", title: "dimetacrine" },
  { id: "N06AA19", title: "amineptine" },
  { id: "N06AA21", title: "maprotiline" },
  { id: "N06AA23", title: "quinupramine" },
  { id: "N06AB", title: "Selective serotonin reuptake inhibitors" },
  { id: "N06AB02", title: "zimeldine" },
  { id: "N06AB03", title: "fluoxetine" },
  { id: "N06AB04", title: "citalopram" },
  { id: "N06AB05", title: "paroxetine" },
  { id: "N06AB06", title: "sertraline" },
  { id: "N06AB07", title: "alaproclate" },
  { id: "N06AB08", title: "fluvoxamine" },
  { id: "N06AB09", title: "etoperidone" },
  { id: "N06AB10", title: "escitalopram" },
  { id: "N06AF", title: "Monoamine oxidase inhibitors, non-selective" },
  { id: "N06AF01", title: "isocarboxazid" },
  { id: "N06AF02", title: "nialamide" },
  { id: "N06AF03", title: "phenelzine" },
  { id: "N06AF04", title: "tranylcypromine" },
  { id: "N06AF05", title: "iproniazide" },
  { id: "N06AF06", title: "iproclozide" },
  { id: "N06AG", title: "Monoamine oxidase A inhibitors" },
  { id: "N06AG02", title: "moclobemide" },
  { id: "N06AG03", title: "toloxatone" },
  { id: "N06AX", title: "Other antidepressants" },
  { id: "N06AX01", title: "oxitriptan" },
  { id: "N06AX02", title: "tryptophan" },
  { id: "N06AX03", title: "mianserin" },
  { id: "N06AX04", title: "nomifensine" },
  { id: "N06AX05", title: "trazodone" },
  { id: "N06AX06", title: "nefazodone" },
  { id: "N06AX07", title: "minaprine" },
  { id: "N06AX08", title: "bifemelane" },
  { id: "N06AX09", title: "viloxazine" },
  { id: "N06AX10", title: "oxaflozane" },
  { id: "N06AX11", title: "mirtazapine" },
  { id: "N06AX12", title: "bupropion" },
  { id: "N06AX13", title: "medifoxamine" },
  { id: "N06AX14", title: "tianeptine" },
  { id: "N06AX15", title: "pivagabine" },
  { id: "N06AX16", title: "venlafaxine" },
  { id: "N06AX17", title: "milnacipran" },
  { id: "N06AX18", title: "reboxetine" },
  { id: "N06AX19", title: "gepirone" },
  { id: "N06AX21", title: "duloxetine" },
  { id: "N06AX22", title: "agomelatine" },
  { id: "N06AX23", title: "desvenlafaxine" },
  { id: "N06AX24", title: "vilazodone" },
  { id: "N06AX25", title: "Hyperici herba" },
  { id: "N06AX26", title: "vortioxetine" },
  {
    id: "N06B",
    title: "PSYCHOSTIMULANTS, AGENTS USED FOR ADHD AND NOOTROPICS",
  },
  { id: "N06BA", title: "Centrally acting sympathomimetics" },
  { id: "N06BA01", title: "amfetamine" },
  { id: "N06BA02", title: "dexamfetamine" },
  { id: "N06BA03", title: "metamfetamine" },
  { id: "N06BA04", title: "methylphenidate" },
  { id: "N06BA05", title: "pemoline" },
  { id: "N06BA06", title: "fencamfamin" },
  { id: "N06BA07", title: "modafinil" },
  { id: "N06BA08", title: "fenozolone" },
  { id: "N06BA09", title: "atomoxetine" },
  { id: "N06BA10", title: "fenetylline" },
  { id: "N06BA11", title: "dexmethylphenidate" },
  { id: "N06BA12", title: "lisdexamfetamine" },
  { id: "N06BC", title: "Xanthine derivatives" },
  { id: "N06BC01", title: "caffeine" },
  { id: "N06BC02", title: "propentofylline" },
  { id: "N06BX", title: "Other psychostimulants and nootropics" },
  { id: "N06BX01", title: "meclofenoxate" },
  { id: "N06BX02", title: "pyritinol" },
  { id: "N06BX03", title: "piracetam" },
  { id: "N06BX04", title: "deanol" },
  { id: "N06BX05", title: "fipexide" },
  { id: "N06BX06", title: "citicoline" },
  { id: "N06BX07", title: "oxiracetam" },
  { id: "N06BX08", title: "pirisudanol" },
  { id: "N06BX09", title: "linopirdine" },
  { id: "N06BX10", title: "nizofenone" },
  { id: "N06BX11", title: "aniracetam" },
  { id: "N06BX12", title: "acetylcarnitine" },
  { id: "N06BX13", title: "idebenone" },
  { id: "N06BX14", title: "prolintane" },
  { id: "N06BX15", title: "pipradrol" },
  { id: "N06BX16", title: "pramiracetam" },
  { id: "N06BX17", title: "adrafinil" },
  { id: "N06BX18", title: "vinpocetine" },
  { id: "N06BX21", title: "mebicar" },
  { id: "N06BX22", title: "phenibut" },
  {
    id: "N06C",
    title: "PSYCHOLEPTICS AND PSYCHOANALEPTICS IN COMBINATION",
  },
  {
    id: "N06CA",
    title: "Antidepressants in combination with psycholeptics",
  },
  { id: "N06CA01", title: "amitriptyline and psycholeptics" },
  { id: "N06CA02", title: "melitracen and psycholeptics" },
  { id: "N06CA03", title: "fluoxetine and psycholeptics" },
  {
    id: "N06CB",
    title: "Psychostimulants in combination with psycholeptics",
  },
  { id: "N06D", title: "ANTI-DEMENTIA DRUGS" },
  { id: "N06DA", title: "Anticholinesterases" },
  { id: "N06DA01", title: "tacrine" },
  { id: "N06DA02", title: "donepezil" },
  { id: "N06DA03", title: "rivastigmine" },
  { id: "N06DA04", title: "galantamine" },
  { id: "N06DA05", title: "ipidacrine" },
  { id: "N06DA52", title: "donepezil and memantine" },
  { id: "N06DX", title: "Other anti-dementia drugs" },
  { id: "N06DX01", title: "memantine" },
  { id: "N06DX02", title: "Ginkgo folium" },
  { id: "N07", title: "OTHER NERVOUS SYSTEM DRUGS" },
  { id: "N07A", title: "PARASYMPATHOMIMETICS" },
  { id: "N07AA", title: "Anticholinesterases" },
  { id: "N07AA01", title: "neostigmine" },
  { id: "N07AA02", title: "pyridostigmine" },
  { id: "N07AA03", title: "distigmine" },
  { id: "N07AA30", title: "ambenonium" },
  { id: "N07AA51", title: "neostigmine, combinations" },
  { id: "N07AB", title: "Choline esters" },
  { id: "N07AB01", title: "carbachol" },
  { id: "N07AB02", title: "bethanechol" },
  { id: "N07AX", title: "Other parasympathomimetics" },
  { id: "N07AX01", title: "pilocarpine" },
  { id: "N07AX02", title: "choline alfoscerate" },
  { id: "N07AX03", title: "cevimeline" },
  { id: "N07B", title: "DRUGS USED IN ADDICTIVE DISORDERS" },
  { id: "N07BA", title: "Drugs used in nicotine dependence" },
  { id: "N07BA01", title: "nicotine" },
  { id: "N07BA03", title: "varenicline" },
  { id: "N07BB", title: "Drugs used in alcohol dependence" },
  { id: "N07BB01", title: "disulfiram" },
  { id: "N07BB02", title: "calcium carbimide" },
  { id: "N07BB03", title: "acamprosate" },
  { id: "N07BB04", title: "naltrexone" },
  { id: "N07BB05", title: "nalmefene" },
  { id: "N07BC", title: "Drugs used in opioid dependence" },
  { id: "N07BC01", title: "buprenorphine" },
  { id: "N07BC02", title: "methadone" },
  { id: "N07BC03", title: "levacetylmethadol" },
  { id: "N07BC04", title: "lofexidine" },
  { id: "N07BC05", title: "levomethadone" },
  { id: "N07BC06", title: "diamorphine" },
  { id: "N07BC51", title: "buprenorphine, combinations" },
  { id: "N07C", title: "ANTIVERTIGO PREPARATIONS" },
  { id: "N07CA", title: "Antivertigo preparations" },
  { id: "N07CA01", title: "betahistine" },
  { id: "N07CA02", title: "cinnarizine" },
  { id: "N07CA03", title: "flunarizine" },
  { id: "N07CA04", title: "acetylleucine" },
  { id: "N07CA52", title: "cinnarizine, combinations" },
  { id: "N07X", title: "OTHER NERVOUS SYSTEM DRUGS" },
  { id: "N07XA", title: "Gangliosides and ganglioside derivatives" },
  { id: "N07XX", title: "Other nervous system drugs" },
  { id: "N07XX01", title: "tirilazad" },
  { id: "N07XX02", title: "riluzole" },
  { id: "N07XX03", title: "xaliproden" },
  { id: "N07XX04", title: "sodium oxybate" },
  { id: "N07XX05", title: "amifampridine" },
  { id: "N07XX06", title: "tetrabenazine" },
  { id: "N07XX07", title: "fampridine" },
  { id: "N07XX08", title: "tafamidis" },
  { id: "N07XX09", title: "dimethyl fumarate" },
  { id: "N07XX10", title: "laquinimod" },
  { id: "N07XX59", title: "dextromethorphan, combinations" },
  { id: "P", title: "ANTIPARASITIC PRODUCTS, INSECTICIDES AND REPELLENTS" },
  { id: "P01", title: "ANTIPROTOZOALS" },
  {
    id: "P01A",
    title: "AGENTS AGAINST AMOEBIASIS AND OTHER PROTOZOAL DISEASES",
  },
  { id: "P01AA", title: "Hydroxyquinoline derivatives" },
  { id: "P01AA01", title: "broxyquinoline" },
  { id: "P01AA02", title: "clioquinol" },
  { id: "P01AA04", title: "chlorquinaldol" },
  { id: "P01AA05", title: "tilbroquinol" },
  { id: "P01AA52", title: "clioquinol, combinations" },
  { id: "P01AB", title: "Nitroimidazole derivatives" },
  { id: "P01AB01", title: "metronidazole" },
  { id: "P01AB02", title: "tinidazole" },
  { id: "P01AB03", title: "ornidazole" },
  { id: "P01AB04", title: "azanidazole" },
  { id: "P01AB05", title: "propenidazole" },
  { id: "P01AB06", title: "nimorazole" },
  { id: "P01AB07", title: "secnidazole" },
  { id: "P01AC", title: "Dichloroacetamide derivatives" },
  { id: "P01AC01", title: "diloxanide" },
  { id: "P01AC02", title: "clefamide" },
  { id: "P01AC03", title: "etofamide" },
  { id: "P01AC04", title: "teclozan" },
  { id: "P01AR", title: "Arsenic compounds" },
  { id: "P01AR01", title: "arsthinol" },
  { id: "P01AR02", title: "difetarsone" },
  { id: "P01AR03", title: "glycobiarsol" },
  { id: "P01AR53", title: "glycobiarsol, combinations" },
  {
    id: "P01AX",
    title: "Other agents against amoebiasis and other protozoal diseases",
  },
  { id: "P01AX01", title: "chiniofon" },
  { id: "P01AX02", title: "emetine" },
  { id: "P01AX04", title: "phanquinone" },
  { id: "P01AX05", title: "mepacrine" },
  { id: "P01AX06", title: "atovaquone" },
  { id: "P01AX07", title: "trimetrexate" },
  { id: "P01AX08", title: "tenonitrozole" },
  { id: "P01AX09", title: "dihydroemetine" },
  { id: "P01AX10", title: "fumagillin" },
  { id: "P01AX11", title: "nitazoxanide" },
  { id: "P01AX52", title: "emetine, combinations" },
  { id: "P01B", title: "ANTIMALARIALS" },
  { id: "P01BA", title: "Aminoquinolines" },
  { id: "P01BA01", title: "chloroquine" },
  { id: "P01BA02", title: "hydroxychloroquine" },
  { id: "P01BA03", title: "primaquine" },
  { id: "P01BA06", title: "amodiaquine" },
  { id: "P01BB", title: "Biguanides" },
  { id: "P01BB01", title: "proguanil" },
  { id: "P01BB02", title: "cycloguanil embonate" },
  { id: "P01BB51", title: "proguanil, combinations" },
  { id: "P01BC", title: "Methanolquinolines" },
  { id: "P01BC01", title: "quinine" },
  { id: "P01BC02", title: "mefloquine" },
  { id: "P01BD", title: "Diaminopyrimidines" },
  { id: "P01BD01", title: "pyrimethamine" },
  { id: "P01BD51", title: "pyrimethamine, combinations" },
  { id: "P01BE", title: "Artemisinin and derivatives, plain" },
  { id: "P01BE01", title: "artemisinin" },
  { id: "P01BE02", title: "artemether" },
  { id: "P01BE03", title: "artesunate" },
  { id: "P01BE04", title: "artemotil" },
  { id: "P01BE05", title: "artenimol" },
  { id: "P01BF", title: "Artemisinin and derivatives, combinations" },
  { id: "P01BF01", title: "artemether and lumefantrine" },
  { id: "P01BF02", title: "artesunate and mefloquine" },
  { id: "P01BF03", title: "artesunate and amodiaquine" },
  {
    id: "P01BF04",
    title: "artesunate, sulphamethopyrazine and pyrimethamine",
  },
  { id: "P01BF05", title: "artenimol and piperaquine" },
  { id: "P01BF06", title: "artesunate and pyronaridine" },
  { id: "P01BX", title: "Other antimalarials" },
  { id: "P01BX01", title: "halofantrine" },
  { id: "P01C", title: "AGENTS AGAINST LEISHMANIASIS AND TRYPANOSOMIASIS" },
  { id: "P01CA", title: "Nitroimidazole derivatives" },
  { id: "P01CA02", title: "benznidazole" },
  { id: "P01CB", title: "Antimony compounds" },
  { id: "P01CB01", title: "meglumine antimonate" },
  { id: "P01CB02", title: "sodium stibogluconate" },
  { id: "P01CC", title: "Nitrofuran derivatives" },
  { id: "P01CC01", title: "nifurtimox" },
  { id: "P01CC02", title: "nitrofural" },
  { id: "P01CD", title: "Arsenic compounds" },
  { id: "P01CD01", title: "melarsoprol" },
  { id: "P01CD02", title: "acetarsol" },
  {
    id: "P01CX",
    title: "Other agents against leishmaniasis and trypanosomiasis",
  },
  { id: "P01CX01", title: "pentamidine isethionate" },
  { id: "P01CX02", title: "suramin sodium" },
  { id: "P01CX03", title: "eflornithine" },
  { id: "P02", title: "ANTHELMINTICS" },
  { id: "P02B", title: "ANTITREMATODALS" },
  { id: "P02BA", title: "Quinoline derivatives and related substances" },
  { id: "P02BA01", title: "praziquantel" },
  { id: "P02BA02", title: "oxamniquine" },
  { id: "P02BB", title: "Organophosphorous compounds" },
  { id: "P02BB01", title: "metrifonate" },
  { id: "P02BX", title: "Other antitrematodal agents" },
  { id: "P02BX01", title: "bithionol" },
  { id: "P02BX02", title: "niridazole" },
  { id: "P02BX03", title: "stibophen" },
  { id: "P02BX04", title: "triclabendazole" },
  { id: "P02C", title: "ANTINEMATODAL AGENTS" },
  { id: "P02CA", title: "Benzimidazole derivatives" },
  { id: "P02CA01", title: "mebendazole" },
  { id: "P02CA02", title: "tiabendazole" },
  { id: "P02CA03", title: "albendazole" },
  { id: "P02CA04", title: "ciclobendazole" },
  { id: "P02CA05", title: "flubendazole" },
  { id: "P02CA06", title: "fenbendazole" },
  { id: "P02CA51", title: "mebendazole, combinations" },
  { id: "P02CB", title: "Piperazine and derivatives" },
  { id: "P02CB01", title: "piperazine" },
  { id: "P02CB02", title: "diethylcarbamazine" },
  { id: "P02CC", title: "Tetrahydropyrimidine derivatives" },
  { id: "P02CC01", title: "pyrantel" },
  { id: "P02CC02", title: "oxantel" },
  { id: "P02CE", title: "Imidazothiazole derivatives" },
  { id: "P02CE01", title: "levamisole" },
  { id: "P02CF", title: "Avermectines" },
  { id: "P02CF01", title: "ivermectin" },
  { id: "P02CX", title: "Other antinematodals" },
  { id: "P02CX01", title: "pyrvinium" },
  { id: "P02CX02", title: "bephenium" },
  { id: "P02D", title: "ANTICESTODALS" },
  { id: "P02DA", title: "Salicylic acid derivatives" },
  { id: "P02DA01", title: "niclosamide" },
  { id: "P02DX", title: "Other anticestodals" },
  { id: "P02DX01", title: "desaspidin" },
  { id: "P02DX02", title: "dichlorophen" },
  {
    id: "P03",
    title: "ECTOPARASITICIDES, INCL. SCABICIDES, INSECTICIDES AND REPELLENTS",
  },
  { id: "P03A", title: "ECTOPARASITICIDES, INCL. SCABICIDES" },
  { id: "P03AA", title: "Sulfur containing products" },
  { id: "P03AA01", title: "dixanthogen" },
  { id: "P03AA02", title: "potassium polysulfide" },
  { id: "P03AA03", title: "mesulfen" },
  { id: "P03AA04", title: "disulfiram" },
  { id: "P03AA05", title: "thiram" },
  { id: "P03AA54", title: "disulfiram, combinations" },
  { id: "P03AB", title: "Chlorine containing products" },
  { id: "P03AB01", title: "clofenotane" },
  { id: "P03AB02", title: "lindane" },
  { id: "P03AB51", title: "clofenotane, combinations" },
  { id: "P03AC", title: "Pyrethrines, incl. synthetic compounds" },
  { id: "P03AC01", title: "pyrethrum" },
  { id: "P03AC02", title: "bioallethrin" },
  { id: "P03AC03", title: "phenothrin" },
  { id: "P03AC04", title: "permethrin" },
  { id: "P03AC51", title: "pyrethrum, combinations" },
  { id: "P03AC52", title: "bioallethrin, combinations" },
  { id: "P03AC53", title: "phenothrin, combinations" },
  { id: "P03AC54", title: "permethrin, combinations" },
  { id: "P03AX", title: "Other ectoparasiticides, incl. scabicides" },
  { id: "P03AX01", title: "benzyl benzoate" },
  { id: "P03AX02", title: "copper oleinate" },
  { id: "P03AX03", title: "malathion" },
  { id: "P03AX04", title: "quassia" },
  { id: "P03AX05", title: "dimeticone" },
  { id: "P03B", title: "INSECTICIDES AND REPELLENTS" },
  { id: "P03BA", title: "Pyrethrines" },
  { id: "P03BA01", title: "cyfluthrin" },
  { id: "P03BA02", title: "cypermethrin" },
  { id: "P03BA03", title: "decamethrin" },
  { id: "P03BA04", title: "tetramethrin" },
  { id: "P03BX", title: "Other insecticides and repellents" },
  { id: "P03BX01", title: "diethyltoluamide" },
  { id: "P03BX02", title: "dimethylphthalate" },
  { id: "P03BX03", title: "dibutylphthalate" },
  { id: "R", title: "RESPIRATORY SYSTEM" },
  { id: "R01", title: "NASAL PREPARATIONS" },
  {
    id: "R01A",
    title: "DECONGESTANTS AND OTHER NASAL PREPARATIONS FOR TOPICAL USE",
  },
  { id: "R01AA", title: "Sympathomimetics, plain" },
  { id: "R01AA02", title: "cyclopentamine" },
  { id: "R01AA03", title: "ephedrine" },
  { id: "R01AA04", title: "phenylephrine" },
  { id: "R01AA05", title: "oxymetazoline" },
  { id: "R01AA06", title: "tetryzoline" },
  { id: "R01AA07", title: "xylometazoline" },
  { id: "R01AA08", title: "naphazoline" },
  { id: "R01AA09", title: "tramazoline" },
  { id: "R01AA10", title: "metizoline" },
  { id: "R01AA11", title: "tuaminoheptane" },
  { id: "R01AA12", title: "fenoxazoline" },
  { id: "R01AA13", title: "tymazoline" },
  { id: "R01AA14", title: "epinephrine" },
  {
    id: "R01AB",
    title: "Sympathomimetics, combinations excl. corticosteroids",
  },
  { id: "R01AB01", title: "phenylephrine" },
  { id: "R01AB02", title: "naphazoline" },
  { id: "R01AB03", title: "tetryzoline" },
  { id: "R01AB05", title: "ephedrine" },
  { id: "R01AB06", title: "xylometazoline" },
  { id: "R01AB07", title: "oxymetazoline" },
  { id: "R01AB08", title: "tuaminoheptane" },
  { id: "R01AC", title: "Antiallergic agents, excl. corticosteroids" },
  { id: "R01AC01", title: "cromoglicic acid" },
  { id: "R01AC02", title: "levocabastine" },
  { id: "R01AC03", title: "azelastine" },
  { id: "R01AC04", title: "antazoline" },
  { id: "R01AC05", title: "spaglumic acid" },
  { id: "R01AC06", title: "thonzylamine" },
  { id: "R01AC07", title: "nedocromil" },
  { id: "R01AC08", title: "olopatadine" },
  { id: "R01AC51", title: "cromoglicic acid, combinations" },
  { id: "R01AD", title: "Corticosteroids" },
  { id: "R01AD01", title: "beclometasone" },
  { id: "R01AD02", title: "prednisolone" },
  { id: "R01AD03", title: "dexamethasone" },
  { id: "R01AD04", title: "flunisolide" },
  { id: "R01AD05", title: "budesonide" },
  { id: "R01AD06", title: "betamethasone" },
  { id: "R01AD07", title: "tixocortol" },
  { id: "R01AD08", title: "fluticasone" },
  { id: "R01AD09", title: "mometasone" },
  { id: "R01AD11", title: "triamcinolone" },
  { id: "R01AD12", title: "fluticasone furoate" },
  { id: "R01AD13", title: "ciclesonide" },
  { id: "R01AD52", title: "prednisolone, combinations" },
  { id: "R01AD53", title: "dexamethasone, combinations" },
  { id: "R01AD57", title: "tixocortol, combinations" },
  { id: "R01AD58", title: "fluticasone, combinations" },
  { id: "R01AD60", title: "hydrocortisone, combinations" },
  { id: "R01AX", title: "Other nasal preparations" },
  { id: "R01AX01", title: "calcium hexamine thiocyanate" },
  { id: "R01AX02", title: "retinol" },
  { id: "R01AX03", title: "ipratropium bromide" },
  { id: "R01AX05", title: "ritiometan" },
  { id: "R01AX06", title: "mupirocin" },
  { id: "R01AX07", title: "hexamidine" },
  { id: "R01AX08", title: "framycetin" },
  { id: "R01AX09", title: "hyaluronic acid" },
  { id: "R01AX10", title: "various" },
  { id: "R01AX30", title: "combinations" },
  { id: "R01B", title: "NASAL DECONGESTANTS FOR SYSTEMIC USE" },
  { id: "R01BA", title: "Sympathomimetics" },
  { id: "R01BA01", title: "phenylpropanolamine" },
  { id: "R01BA02", title: "pseudoephedrine" },
  { id: "R01BA03", title: "phenylephrine" },
  { id: "R01BA51", title: "phenylpropanolamine, combinations" },
  { id: "R01BA52", title: "pseudoephedrine, combinations" },
  { id: "R01BA53", title: "phenylephrine, combinations" },
  { id: "R02", title: "THROAT PREPARATIONS" },
  { id: "R02A", title: "THROAT PREPARATIONS" },
  { id: "R02AA", title: "Antiseptics" },
  { id: "R02AA01", title: "ambazone" },
  { id: "R02AA02", title: "dequalinium" },
  { id: "R02AA03", title: "dichlorobenzyl alcohol" },
  { id: "R02AA05", title: "chlorhexidine" },
  { id: "R02AA06", title: "cetylpyridinium" },
  { id: "R02AA09", title: "benzethonium" },
  { id: "R02AA10", title: "myristyl-benzalkonium" },
  { id: "R02AA11", title: "chlorquinaldol" },
  { id: "R02AA12", title: "hexylresorcinol" },
  { id: "R02AA13", title: "acriflavinium chloride" },
  { id: "R02AA14", title: "oxyquinoline" },
  { id: "R02AA15", title: "povidone-iodine" },
  { id: "R02AA16", title: "benzalkonium" },
  { id: "R02AA17", title: "cetrimonium" },
  { id: "R02AA18", title: "hexamidine" },
  { id: "R02AA19", title: "phenol" },
  { id: "R02AA20", title: "various" },
  { id: "R02AB", title: "Antibiotics" },
  { id: "R02AB01", title: "neomycin" },
  { id: "R02AB02", title: "tyrothricin" },
  { id: "R02AB03", title: "fusafungine" },
  { id: "R02AB04", title: "bacitracin" },
  { id: "R02AB30", title: "gramicidin" },
  { id: "R02AD", title: "Anesthetics, local" },
  { id: "R02AD01", title: "benzocaine" },
  { id: "R02AD02", title: "lidocaine" },
  { id: "R02AD03", title: "cocaine" },
  { id: "R02AD04", title: "dyclonine" },
  { id: "R02AX", title: "Other throat preparations" },
  { id: "R02AX01", title: "flurbiprofen" },
  { id: "R03", title: "DRUGS FOR OBSTRUCTIVE AIRWAY DISEASES" },
  { id: "R03A", title: "ADRENERGICS, INHALANTS" },
  { id: "R03AA", title: "Alpha- and beta-adrenoreceptor agonists" },
  { id: "R03AA01", title: "epinephrine" },
  { id: "R03AB", title: "Non-selective beta-adrenoreceptor agonists" },
  { id: "R03AB02", title: "isoprenaline" },
  { id: "R03AB03", title: "orciprenaline" },
  { id: "R03AC", title: "Selective beta-2-adrenoreceptor agonists" },
  { id: "R03AC02", title: "salbutamol" },
  { id: "R03AC03", title: "terbutaline" },
  { id: "R03AC04", title: "fenoterol" },
  { id: "R03AC05", title: "rimiterol" },
  { id: "R03AC06", title: "hexoprenaline" },
  { id: "R03AC07", title: "isoetarine" },
  { id: "R03AC08", title: "pirbuterol" },
  { id: "R03AC09", title: "tretoquinol" },
  { id: "R03AC10", title: "carbuterol" },
  { id: "R03AC11", title: "tulobuterol" },
  { id: "R03AC12", title: "salmeterol" },
  { id: "R03AC13", title: "formoterol" },
  { id: "R03AC14", title: "clenbuterol" },
  { id: "R03AC15", title: "reproterol" },
  { id: "R03AC16", title: "procaterol" },
  { id: "R03AC17", title: "bitolterol" },
  { id: "R03AC18", title: "indacaterol" },
  { id: "R03AC19", title: "olodaterol" },
  { id: "R03AH", title: "Combinations of adrenergics" },
  {
    id: "R03AK",
    title:
      "Adrenergics in combination with corticosteroids or other drugs, excl. anticholinergics",
  },
  {
    id: "R03AK01",
    title: "epinephrine and other drugs for obstructive airway diseases",
  },
  {
    id: "R03AK02",
    title: "isoprenaline and other drugs for obstructive airway diseases",
  },
  { id: "R03AK04", title: "salbutamol and sodium cromoglicate" },
  { id: "R03AK05", title: "reproterol and sodium cromoglicate" },
  { id: "R03AK06", title: "salmeterol and fluticasone" },
  { id: "R03AK07", title: "formoterol and budesonide" },
  { id: "R03AK08", title: "formoterol and beclometasone" },
  { id: "R03AK09", title: "formoterol and mometasone" },
  { id: "R03AK10", title: "vilanterol and fluticasone furoate" },
  { id: "R03AK11", title: "formoterol and fluticasone" },
  {
    id: "R03AL",
    title: "Adrenergics in combination with anticholinergics",
  },
  { id: "R03AL01", title: "fenoterol and ipratropium bromide" },
  { id: "R03AL02", title: "salbutamol and ipratropium bromide" },
  { id: "R03AL03", title: "vilanterol and umeclidinium bromide" },
  { id: "R03AL04", title: "indacaterol and glycopyrronium bromide" },
  {
    id: "R03B",
    title: "OTHER DRUGS FOR OBSTRUCTIVE AIRWAY DISEASES, INHALANTS",
  },
  { id: "R03BA", title: "Glucocorticoids" },
  { id: "R03BA01", title: "beclometasone" },
  { id: "R03BA02", title: "budesonide" },
  { id: "R03BA03", title: "flunisolide" },
  { id: "R03BA04", title: "betamethasone" },
  { id: "R03BA05", title: "fluticasone" },
  { id: "R03BA06", title: "triamcinolone" },
  { id: "R03BA07", title: "mometasone" },
  { id: "R03BA08", title: "ciclesonide" },
  { id: "R03BB", title: "Anticholinergics" },
  { id: "R03BB01", title: "ipratropium bromide" },
  { id: "R03BB02", title: "oxitropium bromide" },
  { id: "R03BB03", title: "stramoni preparations" },
  { id: "R03BB04", title: "tiotropium bromide" },
  { id: "R03BB05", title: "aclidinium bromide" },
  { id: "R03BB06", title: "glycopyrronium bromide" },
  { id: "R03BC", title: "Antiallergic agents, excl. corticosteroids" },
  { id: "R03BC01", title: "cromoglicic acid" },
  { id: "R03BC03", title: "nedocromil" },
  {
    id: "R03BX",
    title: "Other drugs for obstructive airway diseases, inhalants",
  },
  { id: "R03BX01", title: "fenspiride" },
  { id: "R03C", title: "ADRENERGICS FOR SYSTEMIC USE" },
  { id: "R03CA", title: "Alpha- and beta-adrenoreceptor agonists" },
  { id: "R03CA02", title: "ephedrine" },
  { id: "R03CB", title: "Non-selective beta-adrenoreceptor agonists" },
  { id: "R03CB01", title: "isoprenaline" },
  { id: "R03CB02", title: "methoxyphenamine" },
  { id: "R03CB03", title: "orciprenaline" },
  { id: "R03CB51", title: "isoprenaline, combinations" },
  { id: "R03CB53", title: "orciprenaline, combinations" },
  { id: "R03CC", title: "Selective beta-2-adrenoreceptor agonists" },
  { id: "R03CC02", title: "salbutamol" },
  { id: "R03CC03", title: "terbutaline" },
  { id: "R03CC04", title: "fenoterol" },
  { id: "R03CC05", title: "hexoprenaline" },
  { id: "R03CC06", title: "isoetarine" },
  { id: "R03CC07", title: "pirbuterol" },
  { id: "R03CC08", title: "procaterol" },
  { id: "R03CC09", title: "tretoquinol" },
  { id: "R03CC10", title: "carbuterol" },
  { id: "R03CC11", title: "tulobuterol" },
  { id: "R03CC12", title: "bambuterol" },
  { id: "R03CC13", title: "clenbuterol" },
  { id: "R03CC14", title: "reproterol" },
  { id: "R03CC53", title: "terbutaline, combinations" },
  {
    id: "R03CK",
    title: "Adrenergics and other drugs for obstructive airway diseases",
  },
  {
    id: "R03D",
    title: "OTHER SYSTEMIC DRUGS FOR OBSTRUCTIVE AIRWAY DISEASES",
  },
  { id: "R03DA", title: "Xanthines" },
  { id: "R03DA01", title: "diprophylline" },
  { id: "R03DA02", title: "choline theophyllinate" },
  { id: "R03DA03", title: "proxyphylline" },
  { id: "R03DA04", title: "theophylline" },
  { id: "R03DA05", title: "aminophylline" },
  { id: "R03DA06", title: "etamiphylline" },
  { id: "R03DA07", title: "theobromine" },
  { id: "R03DA08", title: "bamifylline" },
  { id: "R03DA09", title: "acefylline piperazine" },
  { id: "R03DA10", title: "bufylline" },
  { id: "R03DA11", title: "doxofylline" },
  { id: "R03DA20", title: "combinations of xanthines" },
  { id: "R03DA51", title: "diprophylline, combinations" },
  {
    id: "R03DA54",
    title: "theophylline, combinations excl. psycholeptics",
  },
  { id: "R03DA55", title: "aminophylline, combinations" },
  { id: "R03DA57", title: "theobromine, combinations" },
  { id: "R03DA74", title: "theophylline, combinations with psycholeptics" },
  { id: "R03DB", title: "Xanthines and adrenergics" },
  { id: "R03DB01", title: "diprophylline and adrenergics" },
  { id: "R03DB02", title: "choline theophyllinate and adrenergics" },
  { id: "R03DB03", title: "proxyphylline and adrenergics" },
  { id: "R03DB04", title: "theophylline and adrenergics" },
  { id: "R03DB05", title: "aminophylline and adrenergics" },
  { id: "R03DB06", title: "etamiphylline and adrenergics" },
  { id: "R03DC", title: "Leukotriene receptor antagonists" },
  { id: "R03DC01", title: "zafirlukast" },
  { id: "R03DC02", title: "pranlukast" },
  { id: "R03DC03", title: "montelukast" },
  { id: "R03DC04", title: "ibudilast" },
  {
    id: "R03DX",
    title: "Other systemic drugs for obstructive airway diseases",
  },
  { id: "R03DX01", title: "amlexanox" },
  { id: "R03DX02", title: "eprozinol" },
  { id: "R03DX03", title: "fenspiride" },
  { id: "R03DX05", title: "omalizumab" },
  { id: "R03DX06", title: "seratrodast" },
  { id: "R03DX07", title: "roflumilast" },
  { id: "R05", title: "COUGH AND COLD PREPARATIONS" },
  {
    id: "R05C",
    title: "EXPECTORANTS, EXCL. COMBINATIONS WITH COUGH SUPPRESSANTS",
  },
  { id: "R05CA", title: "Expectorants" },
  { id: "R05CA01", title: "tyloxapol" },
  { id: "R05CA02", title: "potassium iodide" },
  { id: "R05CA03", title: "guaifenesin" },
  { id: "R05CA04", title: "ipecacuanha" },
  { id: "R05CA05", title: "althea root" },
  { id: "R05CA06", title: "senega" },
  { id: "R05CA07", title: "antimony pentasulfide" },
  { id: "R05CA08", title: "creosote" },
  { id: "R05CA09", title: "guaiacolsulfonate" },
  { id: "R05CA10", title: "combinations" },
  { id: "R05CA11", title: "levoverbenone" },
  { id: "R05CA12", title: "Hederae helicis folium" },
  { id: "R05CA13", title: "cineole" },
  { id: "R05CB", title: "Mucolytics" },
  { id: "R05CB01", title: "acetylcysteine" },
  { id: "R05CB02", title: "bromhexine" },
  { id: "R05CB03", title: "carbocisteine" },
  { id: "R05CB04", title: "eprazinone" },
  { id: "R05CB05", title: "mesna" },
  { id: "R05CB06", title: "ambroxol" },
  { id: "R05CB07", title: "sobrerol" },
  { id: "R05CB08", title: "domiodol" },
  { id: "R05CB09", title: "letosteine" },
  { id: "R05CB10", title: "combinations" },
  { id: "R05CB11", title: "stepronin" },
  { id: "R05CB12", title: "tiopronin" },
  { id: "R05CB13", title: "dornase alfa (desoxyribonuclease)" },
  { id: "R05CB14", title: "neltenexine" },
  { id: "R05CB15", title: "erdosteine" },
  { id: "R05CB16", title: "mannitol" },
  {
    id: "R05D",
    title: "COUGH SUPPRESSANTS, EXCL. COMBINATIONS WITH EXPECTORANTS",
  },
  { id: "R05DA", title: "Opium alkaloids and derivatives" },
  { id: "R05DA01", title: "ethylmorphine" },
  { id: "R05DA03", title: "hydrocodone" },
  { id: "R05DA04", title: "codeine" },
  { id: "R05DA05", title: "opium alkaloids with morphine" },
  { id: "R05DA06", title: "normethadone" },
  { id: "R05DA07", title: "noscapine" },
  { id: "R05DA08", title: "pholcodine" },
  { id: "R05DA09", title: "dextromethorphan" },
  { id: "R05DA10", title: "thebacon" },
  { id: "R05DA11", title: "dimemorfan" },
  { id: "R05DA12", title: "acetyldihydrocodeine" },
  { id: "R05DA20", title: "combinations" },
  { id: "R05DB", title: "Other cough suppressants" },
  { id: "R05DB01", title: "benzonatate" },
  { id: "R05DB02", title: "benproperine" },
  { id: "R05DB03", title: "clobutinol" },
  { id: "R05DB04", title: "isoaminile" },
  { id: "R05DB05", title: "pentoxyverine" },
  { id: "R05DB07", title: "oxolamine" },
  { id: "R05DB09", title: "oxeladin" },
  { id: "R05DB10", title: "clofedanol" },
  { id: "R05DB11", title: "pipazetate" },
  { id: "R05DB12", title: "bibenzonium bromide" },
  { id: "R05DB13", title: "butamirate" },
  { id: "R05DB14", title: "fedrilate" },
  { id: "R05DB15", title: "zipeprol" },
  { id: "R05DB16", title: "dibunate" },
  { id: "R05DB17", title: "droxypropine" },
  { id: "R05DB18", title: "prenoxdiazine" },
  { id: "R05DB19", title: "dropropizine" },
  { id: "R05DB20", title: "combinations" },
  { id: "R05DB21", title: "cloperastine" },
  { id: "R05DB22", title: "meprotixol" },
  { id: "R05DB23", title: "piperidione" },
  { id: "R05DB24", title: "tipepidine" },
  { id: "R05DB25", title: "morclofone" },
  { id: "R05DB26", title: "nepinalone" },
  { id: "R05DB27", title: "levodropropizine" },
  { id: "R05DB28", title: "dimethoxanate" },
  {
    id: "R05F",
    title: "COUGH SUPPRESSANTS AND EXPECTORANTS, COMBINATIONS",
  },
  { id: "R05FA", title: "Opium derivatives and expectorants" },
  { id: "R05FA01", title: "opium derivatives and mucolytics" },
  { id: "R05FA02", title: "opium derivatives and expectorants" },
  { id: "R05FB", title: "Other cough suppressants and expectorants" },
  { id: "R05FB01", title: "cough suppressants and mucolytics" },
  { id: "R05FB02", title: "cough suppressants and expectorants" },
  { id: "R05X", title: "OTHER COLD PREPARATIONS" },
  { id: "R06", title: "ANTIHISTAMINES FOR SYSTEMIC USE" },
  { id: "R06A", title: "ANTIHISTAMINES FOR SYSTEMIC USE" },
  { id: "R06AA", title: "Aminoalkyl ethers" },
  { id: "R06AA01", title: "bromazine" },
  { id: "R06AA02", title: "diphenhydramine" },
  { id: "R06AA04", title: "clemastine" },
  { id: "R06AA06", title: "chlorphenoxamine" },
  { id: "R06AA07", title: "diphenylpyraline" },
  { id: "R06AA08", title: "carbinoxamine" },
  { id: "R06AA09", title: "doxylamine" },
  { id: "R06AA52", title: "diphenhydramine, combinations" },
  { id: "R06AA54", title: "clemastine, combinations" },
  { id: "R06AA56", title: "chlorphenoxamine, combinations" },
  { id: "R06AA57", title: "diphenylpyraline, combinations" },
  { id: "R06AA59", title: "doxylamine, combinations" },
  { id: "R06AB", title: "Substituted alkylamines" },
  { id: "R06AB01", title: "brompheniramine" },
  { id: "R06AB02", title: "dexchlorpheniramine" },
  { id: "R06AB03", title: "dimetindene" },
  { id: "R06AB04", title: "chlorphenamine" },
  { id: "R06AB05", title: "pheniramine" },
  { id: "R06AB06", title: "dexbrompheniramine" },
  { id: "R06AB07", title: "talastine" },
  { id: "R06AB51", title: "brompheniramine, combinations" },
  { id: "R06AB52", title: "dexchlorpheniramine, combinations" },
  { id: "R06AB54", title: "chlorphenamine, combinations" },
  { id: "R06AB56", title: "dexbrompheniramine, combinations" },
  { id: "R06AC", title: "Substituted ethylene diamines" },
  { id: "R06AC01", title: "mepyramine" },
  { id: "R06AC02", title: "histapyrrodine" },
  { id: "R06AC03", title: "chloropyramine" },
  { id: "R06AC04", title: "tripelennamine" },
  { id: "R06AC05", title: "methapyrilene" },
  { id: "R06AC06", title: "thonzylamine" },
  { id: "R06AC52", title: "histapyrrodine, combinations" },
  { id: "R06AC53", title: "chloropyramine, combinations" },
  { id: "R06AD", title: "Phenothiazine derivatives" },
  { id: "R06AD01", title: "alimemazine" },
  { id: "R06AD02", title: "promethazine" },
  { id: "R06AD03", title: "thiethylperazine" },
  { id: "R06AD04", title: "methdilazine" },
  { id: "R06AD05", title: "hydroxyethylpromethazine" },
  { id: "R06AD06", title: "thiazinam" },
  { id: "R06AD07", title: "mequitazine" },
  { id: "R06AD08", title: "oxomemazine" },
  { id: "R06AD09", title: "isothipendyl" },
  { id: "R06AD52", title: "promethazine, combinations" },
  { id: "R06AD55", title: "hydroxyethylpromethazine, combinations" },
  { id: "R06AE", title: "Piperazine derivatives" },
  { id: "R06AE01", title: "buclizine" },
  { id: "R06AE03", title: "cyclizine" },
  { id: "R06AE04", title: "chlorcyclizine" },
  { id: "R06AE05", title: "meclozine" },
  { id: "R06AE06", title: "oxatomide" },
  { id: "R06AE07", title: "cetirizine" },
  { id: "R06AE09", title: "levocetirizine" },
  { id: "R06AE51", title: "buclizine, combinations" },
  { id: "R06AE53", title: "cyclizine, combinations" },
  { id: "R06AE55", title: "meclozine, combinations" },
  { id: "R06AK", title: "Combinations of antihistamines" },
  { id: "R06AX", title: "Other antihistamines for systemic use" },
  { id: "R06AX01", title: "bamipine" },
  { id: "R06AX02", title: "cyproheptadine" },
  { id: "R06AX03", title: "thenalidine" },
  { id: "R06AX04", title: "phenindamine" },
  { id: "R06AX05", title: "antazoline" },
  { id: "R06AX07", title: "triprolidine" },
  { id: "R06AX08", title: "pyrrobutamine" },
  { id: "R06AX09", title: "azatadine" },
  { id: "R06AX11", title: "astemizole" },
  { id: "R06AX12", title: "terfenadine" },
  { id: "R06AX13", title: "loratadine" },
  { id: "R06AX15", title: "mebhydrolin" },
  { id: "R06AX16", title: "deptropine" },
  { id: "R06AX17", title: "ketotifen" },
  { id: "R06AX18", title: "acrivastine" },
  { id: "R06AX19", title: "azelastine" },
  { id: "R06AX21", title: "tritoqualine" },
  { id: "R06AX22", title: "ebastine" },
  { id: "R06AX23", title: "pimethixene" },
  { id: "R06AX24", title: "epinastine" },
  { id: "R06AX25", title: "mizolastine" },
  { id: "R06AX26", title: "fexofenadine" },
  { id: "R06AX27", title: "desloratadine" },
  { id: "R06AX28", title: "rupatadine" },
  { id: "R06AX29", title: "bilastine" },
  { id: "R06AX31", title: "quifenadine" },
  { id: "R06AX32", title: "sequifenadine" },
  { id: "R06AX53", title: "thenalidine, combinations" },
  { id: "R06AX58", title: "pyrrobutamine, combinations" },
  { id: "R07", title: "OTHER RESPIRATORY SYSTEM PRODUCTS" },
  { id: "R07A", title: "OTHER RESPIRATORY SYSTEM PRODUCTS" },
  { id: "R07AA", title: "Lung surfactants" },
  { id: "R07AA01", title: "colfosceril palmitate" },
  { id: "R07AA02", title: "natural phospholipids" },
  { id: "R07AA30", title: "combinations" },
  { id: "R07AB", title: "Respiratory stimulants" },
  { id: "R07AB01", title: "doxapram" },
  { id: "R07AB02", title: "nikethamide" },
  { id: "R07AB03", title: "pentetrazol" },
  { id: "R07AB04", title: "etamivan" },
  { id: "R07AB05", title: "bemegride" },
  { id: "R07AB06", title: "prethcamide" },
  { id: "R07AB07", title: "almitrine" },
  { id: "R07AB08", title: "dimefline" },
  { id: "R07AB09", title: "mepixanox" },
  { id: "R07AB52", title: "nikethamide, combinations" },
  { id: "R07AB53", title: "pentetrazol, combinations" },
  { id: "R07AX", title: "Other respiratory system products" },
  { id: "R07AX01", title: "nitric oxide" },
  { id: "R07AX02", title: "ivacaftor" },
  { id: "S", title: "SENSORY ORGANS" },
  { id: "S01", title: "OPHTHALMOLOGICALS" },
  { id: "S01A", title: "ANTIINFECTIVES" },
  { id: "S01AA", title: "Antibiotics" },
  { id: "S01AA01", title: "chloramphenicol" },
  { id: "S01AA02", title: "chlortetracycline" },
  { id: "S01AA03", title: "neomycin" },
  { id: "S01AA04", title: "oxytetracycline" },
  { id: "S01AA05", title: "tyrothricin" },
  { id: "S01AA07", title: "framycetin" },
  { id: "S01AA09", title: "tetracycline" },
  { id: "S01AA10", title: "natamycin" },
  { id: "S01AA11", title: "gentamicin" },
  { id: "S01AA12", title: "tobramycin" },
  { id: "S01AA13", title: "fusidic acid" },
  { id: "S01AA14", title: "benzylpenicillin" },
  { id: "S01AA15", title: "dihydrostreptomycin" },
  { id: "S01AA16", title: "rifamycin" },
  { id: "S01AA17", title: "erythromycin" },
  { id: "S01AA18", title: "polymyxin B" },
  { id: "S01AA19", title: "ampicillin" },
  { id: "S01AA20", title: "antibiotics in combination with other drugs" },
  { id: "S01AA21", title: "amikacin" },
  { id: "S01AA22", title: "micronomicin" },
  { id: "S01AA23", title: "netilmicin" },
  { id: "S01AA24", title: "kanamycin" },
  { id: "S01AA25", title: "azidamfenicol" },
  { id: "S01AA26", title: "azithromycin" },
  { id: "S01AA27", title: "cefuroxime" },
  { id: "S01AA30", title: "combinations of different antibiotics" },
  { id: "S01AB", title: "Sulfonamides" },
  { id: "S01AB01", title: "sulfamethizole" },
  { id: "S01AB02", title: "sulfafurazole" },
  { id: "S01AB03", title: "sulfadicramide" },
  { id: "S01AB04", title: "sulfacetamide" },
  { id: "S01AB05", title: "sulfafenazol" },
  { id: "S01AD", title: "Antivirals" },
  { id: "S01AD01", title: "idoxuridine" },
  { id: "S01AD02", title: "trifluridine" },
  { id: "S01AD03", title: "aciclovir" },
  { id: "S01AD05", title: "interferon" },
  { id: "S01AD06", title: "vidarabine" },
  { id: "S01AD07", title: "famciclovir" },
  { id: "S01AD08", title: "fomivirsen" },
  { id: "S01AD09", title: "ganciclovir" },
  { id: "S01AE", title: "Fluoroquinolones" },
  { id: "S01AE01", title: "ofloxacin" },
  { id: "S01AE02", title: "norfloxacin" },
  { id: "S01AE03", title: "ciprofloxacin" },
  { id: "S01AE04", title: "lomefloxacin" },
  { id: "S01AE05", title: "levofloxacin" },
  { id: "S01AE06", title: "gatifloxacin" },
  { id: "S01AE07", title: "moxifloxacin" },
  { id: "S01AE08", title: "besifloxacin" },
  { id: "S01AX", title: "Other antiinfectives" },
  { id: "S01AX01", title: "mercury compounds" },
  { id: "S01AX02", title: "silver compounds" },
  { id: "S01AX03", title: "zinc compounds" },
  { id: "S01AX04", title: "nitrofural" },
  { id: "S01AX05", title: "bibrocathol" },
  { id: "S01AX06", title: "resorcinol" },
  { id: "S01AX07", title: "sodium borate" },
  { id: "S01AX08", title: "hexamidine" },
  { id: "S01AX09", title: "chlorhexidine" },
  { id: "S01AX10", title: "sodium propionate" },
  { id: "S01AX14", title: "dibrompropamidine" },
  { id: "S01AX15", title: "propamidine" },
  { id: "S01AX16", title: "picloxydine" },
  { id: "S01AX18", title: "povidone-iodine" },
  { id: "S01B", title: "ANTIINFLAMMATORY AGENTS" },
  { id: "S01BA", title: "Corticosteroids, plain" },
  { id: "S01BA01", title: "dexamethasone" },
  { id: "S01BA02", title: "hydrocortisone" },
  { id: "S01BA03", title: "cortisone" },
  { id: "S01BA04", title: "prednisolone" },
  { id: "S01BA05", title: "triamcinolone" },
  { id: "S01BA06", title: "betamethasone" },
  { id: "S01BA07", title: "fluorometholone" },
  { id: "S01BA08", title: "medrysone" },
  { id: "S01BA09", title: "clobetasone" },
  { id: "S01BA10", title: "alclometasone" },
  { id: "S01BA11", title: "desonide" },
  { id: "S01BA12", title: "formocortal" },
  { id: "S01BA13", title: "rimexolone" },
  { id: "S01BA14", title: "loteprednol" },
  { id: "S01BA15", title: "fluocinolone acetonide" },
  { id: "S01BB", title: "Corticosteroids and mydriatics in combination" },
  { id: "S01BB01", title: "hydrocortisone and mydriatics" },
  { id: "S01BB02", title: "prednisolone and mydriatics" },
  { id: "S01BB03", title: "fluorometholone and mydriatics" },
  { id: "S01BB04", title: "betamethasone and mydriatics" },
  { id: "S01BC", title: "Antiinflammatory agents, non-steroids" },
  { id: "S01BC01", title: "indometacin" },
  { id: "S01BC02", title: "oxyphenbutazone" },
  { id: "S01BC03", title: "diclofenac" },
  { id: "S01BC04", title: "flurbiprofen" },
  { id: "S01BC05", title: "ketorolac" },
  { id: "S01BC06", title: "piroxicam" },
  { id: "S01BC07", title: "bendazac" },
  { id: "S01BC08", title: "salicylic acid" },
  { id: "S01BC09", title: "pranoprofen" },
  { id: "S01BC10", title: "nepafenac" },
  { id: "S01BC11", title: "bromfenac" },
  {
    id: "S01C",
    title: "ANTIINFLAMMATORY AGENTS AND ANTIINFECTIVES IN COMBINATION",
  },
  {
    id: "S01CA",
    title: "Corticosteroids and antiinfectives in combination",
  },
  { id: "S01CA01", title: "dexamethasone and antiinfectives" },
  { id: "S01CA02", title: "prednisolone and antiinfectives" },
  { id: "S01CA03", title: "hydrocortisone and antiinfectives" },
  { id: "S01CA04", title: "fluocortolone and antiinfectives" },
  { id: "S01CA05", title: "betamethasone and antiinfectives" },
  { id: "S01CA06", title: "fludrocortisone and antiinfectives" },
  { id: "S01CA07", title: "fluorometholone and antiinfectives" },
  { id: "S01CA08", title: "methylprednisolone and antiinfectives" },
  { id: "S01CA09", title: "chloroprednisone and antiinfectives" },
  { id: "S01CA10", title: "fluocinolone acetonide and antiinfectives" },
  { id: "S01CA11", title: "clobetasone and antiinfectives" },
  {
    id: "S01CB",
    title: "Corticosteroids/antiinfectives/mydriatics in combination",
  },
  { id: "S01CB01", title: "dexamethasone" },
  { id: "S01CB02", title: "prednisolone" },
  { id: "S01CB03", title: "hydrocortisone" },
  { id: "S01CB04", title: "betamethasone" },
  { id: "S01CB05", title: "fluorometholone" },
  {
    id: "S01CC",
    title:
      "Antiinflammatory agents, non-steroids and antiinfectives in combination",
  },
  { id: "S01CC01", title: "diclofenac and antiinfectives" },
  { id: "S01E", title: "ANTIGLAUCOMA PREPARATIONS AND MIOTICS" },
  { id: "S01EA", title: "Sympathomimetics in glaucoma therapy" },
  { id: "S01EA01", title: "epinephrine" },
  { id: "S01EA02", title: "dipivefrine" },
  { id: "S01EA03", title: "apraclonidine" },
  { id: "S01EA04", title: "clonidine" },
  { id: "S01EA05", title: "brimonidine" },
  { id: "S01EA51", title: "epinephrine, combinations" },
  { id: "S01EB", title: "Parasympathomimetics" },
  { id: "S01EB01", title: "pilocarpine" },
  { id: "S01EB02", title: "carbachol" },
  { id: "S01EB03", title: "ecothiopate" },
  { id: "S01EB04", title: "demecarium" },
  { id: "S01EB05", title: "physostigmine" },
  { id: "S01EB06", title: "neostigmine" },
  { id: "S01EB07", title: "fluostigmine" },
  { id: "S01EB08", title: "aceclidine" },
  { id: "S01EB09", title: "acetylcholine" },
  { id: "S01EB10", title: "paraoxon" },
  { id: "S01EB51", title: "pilocarpine, combinations" },
  { id: "S01EB58", title: "aceclidine, combinations" },
  { id: "S01EC", title: "Carbonic anhydrase inhibitors" },
  { id: "S01EC01", title: "acetazolamide" },
  { id: "S01EC02", title: "diclofenamide" },
  { id: "S01EC03", title: "dorzolamide" },
  { id: "S01EC04", title: "brinzolamide" },
  { id: "S01EC05", title: "methazolamide" },
  { id: "S01ED", title: "Beta blocking agents" },
  { id: "S01ED01", title: "timolol" },
  { id: "S01ED02", title: "betaxolol" },
  { id: "S01ED03", title: "levobunolol" },
  { id: "S01ED04", title: "metipranolol" },
  { id: "S01ED05", title: "carteolol" },
  { id: "S01ED06", title: "befunolol" },
  { id: "S01ED51", title: "timolol, combinations" },
  { id: "S01ED52", title: "betaxolol, combinations" },
  { id: "S01ED54", title: "metipranolol, combinations" },
  { id: "S01ED55", title: "carteolol, combinations" },
  { id: "S01EE", title: "Prostaglandin analogues" },
  { id: "S01EE01", title: "latanoprost" },
  { id: "S01EE02", title: "unoprostone" },
  { id: "S01EE03", title: "bimatoprost" },
  { id: "S01EE04", title: "travoprost" },
  { id: "S01EE05", title: "tafluprost" },
  { id: "S01EX", title: "Other antiglaucoma preparations" },
  { id: "S01EX01", title: "guanethidine" },
  { id: "S01EX02", title: "dapiprazole" },
  { id: "S01F", title: "MYDRIATICS AND CYCLOPLEGICS" },
  { id: "S01FA", title: "Anticholinergics" },
  { id: "S01FA01", title: "atropine" },
  { id: "S01FA02", title: "scopolamine" },
  { id: "S01FA03", title: "methylscopolamine" },
  { id: "S01FA04", title: "cyclopentolate" },
  { id: "S01FA05", title: "homatropine" },
  { id: "S01FA06", title: "tropicamide" },
  { id: "S01FA56", title: "tropicamide, combinations" },
  {
    id: "S01FB",
    title: "Sympathomimetics excl. antiglaucoma preparations",
  },
  { id: "S01FB01", title: "phenylephrine" },
  { id: "S01FB02", title: "ephedrine" },
  { id: "S01FB03", title: "ibopamine" },
  { id: "S01G", title: "DECONGESTANTS AND ANTIALLERGICS" },
  { id: "S01GA", title: "Sympathomimetics used as decongestants" },
  { id: "S01GA01", title: "naphazoline" },
  { id: "S01GA02", title: "tetryzoline" },
  { id: "S01GA03", title: "xylometazoline" },
  { id: "S01GA04", title: "oxymetazoline" },
  { id: "S01GA05", title: "phenylephrine" },
  { id: "S01GA06", title: "oxedrine" },
  { id: "S01GA51", title: "naphazoline, combinations" },
  { id: "S01GA52", title: "tetryzoline, combinations" },
  { id: "S01GA53", title: "xylometazoline, combinations" },
  { id: "S01GA55", title: "phenylephrine, combinations" },
  { id: "S01GA56", title: "oxedrine, combinations" },
  { id: "S01GX", title: "Other antiallergics" },
  { id: "S01GX01", title: "cromoglicic acid" },
  { id: "S01GX02", title: "levocabastine" },
  { id: "S01GX03", title: "spaglumic acid" },
  { id: "S01GX04", title: "nedocromil" },
  { id: "S01GX05", title: "lodoxamide" },
  { id: "S01GX06", title: "emedastine" },
  { id: "S01GX07", title: "azelastine" },
  { id: "S01GX08", title: "ketotifen" },
  { id: "S01GX09", title: "olopatadine" },
  { id: "S01GX10", title: "epinastine" },
  { id: "S01GX11", title: "alcaftadine" },
  { id: "S01GX51", title: "cromoglicic acid, combinations" },
  { id: "S01H", title: "LOCAL ANESTHETICS" },
  { id: "S01HA", title: "Local anesthetics" },
  { id: "S01HA01", title: "cocaine" },
  { id: "S01HA02", title: "oxybuprocaine" },
  { id: "S01HA03", title: "tetracaine" },
  { id: "S01HA04", title: "proxymetacaine" },
  { id: "S01HA05", title: "procaine" },
  { id: "S01HA06", title: "cinchocaine" },
  { id: "S01HA07", title: "lidocaine" },
  { id: "S01HA30", title: "combinations" },
  { id: "S01J", title: "DIAGNOSTIC AGENTS" },
  { id: "S01JA", title: "Colouring agents" },
  { id: "S01JA01", title: "fluorescein" },
  { id: "S01JA02", title: "rose bengal sodium" },
  { id: "S01JA51", title: "fluorescein, combinations" },
  { id: "S01JX", title: "Other ophthalmological diagnostic agents" },
  { id: "S01K", title: "SURGICAL AIDS" },
  { id: "S01KA", title: "Viscoelastic substances" },
  { id: "S01KA01", title: "hyaluronic acid" },
  { id: "S01KA02", title: "hypromellose" },
  { id: "S01KA51", title: "hyaluronic acid, combinations" },
  { id: "S01KX", title: "Other surgical aids" },
  { id: "S01KX01", title: "chymotrypsin" },
  { id: "S01L", title: "OCULAR VASCULAR DISORDER AGENTS" },
  { id: "S01LA", title: "Antineovascularisation agents" },
  { id: "S01LA01", title: "verteporfin" },
  { id: "S01LA02", title: "anecortave" },
  { id: "S01LA03", title: "pegaptanib" },
  { id: "S01LA04", title: "ranibizumab" },
  { id: "S01LA05", title: "aflibercept" },
  { id: "S01X", title: "OTHER OPHTHALMOLOGICALS" },
  { id: "S01XA", title: "Other ophthalmologicals" },
  { id: "S01XA01", title: "guaiazulen" },
  { id: "S01XA02", title: "retinol" },
  { id: "S01XA03", title: "sodium chloride, hypertonic" },
  { id: "S01XA04", title: "potassium iodide" },
  { id: "S01XA05", title: "sodium edetate" },
  { id: "S01XA06", title: "ethylmorphine" },
  { id: "S01XA07", title: "alum" },
  { id: "S01XA08", title: "acetylcysteine" },
  { id: "S01XA09", title: "iodoheparinate" },
  { id: "S01XA10", title: "inosine" },
  { id: "S01XA11", title: "nandrolone" },
  { id: "S01XA12", title: "dexpanthenol" },
  { id: "S01XA13", title: "alteplase" },
  { id: "S01XA14", title: "heparin" },
  { id: "S01XA15", title: "ascorbic acid" },
  { id: "S01XA18", title: "ciclosporin" },
  { id: "S01XA19", title: "limbal stems cells, autologous" },
  {
    id: "S01XA20",
    title: "artificial tears and other indifferent preparations",
  },
  { id: "S01XA21", title: "mercaptamine" },
  { id: "S01XA22", title: "ocriplasmin" },
  { id: "S02", title: "OTOLOGICALS" },
  { id: "S02A", title: "ANTIINFECTIVES" },
  { id: "S02AA", title: "Antiinfectives" },
  { id: "S02AA01", title: "chloramphenicol" },
  { id: "S02AA02", title: "nitrofural" },
  { id: "S02AA03", title: "boric acid" },
  { id: "S02AA04", title: "aluminium acetotartrate" },
  { id: "S02AA05", title: "clioquinol" },
  { id: "S02AA06", title: "hydrogen peroxide" },
  { id: "S02AA07", title: "neomycin" },
  { id: "S02AA08", title: "tetracycline" },
  { id: "S02AA09", title: "chlorhexidine" },
  { id: "S02AA10", title: "acetic acid" },
  { id: "S02AA11", title: "polymyxin B" },
  { id: "S02AA12", title: "rifamycin" },
  { id: "S02AA13", title: "miconazole" },
  { id: "S02AA14", title: "gentamicin" },
  { id: "S02AA15", title: "ciprofloxacin" },
  { id: "S02AA16", title: "ofloxacin" },
  { id: "S02AA30", title: "antiinfectives, combinations" },
  { id: "S02B", title: "CORTICOSTEROIDS" },
  { id: "S02BA", title: "Corticosteroids" },
  { id: "S02BA01", title: "hydrocortisone" },
  { id: "S02BA03", title: "prednisolone" },
  { id: "S02BA06", title: "dexamethasone" },
  { id: "S02BA07", title: "betamethasone" },
  { id: "S02BA08", title: "fluocinolone acetonide" },
  {
    id: "S02C",
    title: "CORTICOSTEROIDS AND ANTIINFECTIVES IN COMBINATION",
  },
  {
    id: "S02CA",
    title: "Corticosteroids and antiinfectives in combination",
  },
  { id: "S02CA01", title: "prednisolone and antiinfectives" },
  { id: "S02CA02", title: "flumetasone and antiinfectives" },
  { id: "S02CA03", title: "hydrocortisone and antiinfectives" },
  { id: "S02CA04", title: "triamcinolone and antiinfectives" },
  { id: "S02CA05", title: "fluocinolone acetonide and antiinfectives" },
  { id: "S02CA06", title: "dexamethasone and antiinfectives" },
  { id: "S02CA07", title: "fludrocortisone and antiinfectives" },
  { id: "S02D", title: "OTHER OTOLOGICALS" },
  { id: "S02DA", title: "Analgesics and anesthetics" },
  { id: "S02DA01", title: "lidocaine" },
  { id: "S02DA02", title: "cocaine" },
  { id: "S02DA03", title: "phenazone" },
  { id: "S02DA04", title: "cinchocaine" },
  { id: "S02DA30", title: "combinations" },
  { id: "S02DC", title: "Indifferent preparations" },
  { id: "S03", title: "OPHTHALMOLOGICAL AND OTOLOGICAL PREPARATIONS" },
  { id: "S03A", title: "ANTIINFECTIVES" },
  { id: "S03AA", title: "Antiinfectives" },
  { id: "S03AA01", title: "neomycin" },
  { id: "S03AA02", title: "tetracycline" },
  { id: "S03AA03", title: "polymyxin B" },
  { id: "S03AA04", title: "chlorhexidine" },
  { id: "S03AA05", title: "hexamidine" },
  { id: "S03AA06", title: "gentamicin" },
  { id: "S03AA07", title: "ciprofloxacin" },
  { id: "S03AA08", title: "chloramphenicol" },
  { id: "S03AA30", title: "antiinfectives, combinations" },
  { id: "S03B", title: "CORTICOSTEROIDS" },
  { id: "S03BA", title: "Corticosteroids" },
  { id: "S03BA01", title: "dexamethasone" },
  { id: "S03BA02", title: "prednisolone" },
  { id: "S03BA03", title: "betamethasone" },
  {
    id: "S03C",
    title: "CORTICOSTEROIDS AND ANTIINFECTIVES IN COMBINATION",
  },
  {
    id: "S03CA",
    title: "Corticosteroids and antiinfectives in combination",
  },
  { id: "S03CA01", title: "dexamethasone and antiinfectives" },
  { id: "S03CA02", title: "prednisolone and antiinfectives" },
  { id: "S03CA04", title: "hydrocortisone and antiinfectives" },
  { id: "S03CA05", title: "fludrocortisone and antiinfectives" },
  { id: "S03CA06", title: "betamethasone and antiinfectives" },
  {
    id: "S03D",
    title: "OTHER OPHTHALMOLOGICAL AND OTOLOGICAL PREPARATIONS",
  },
  { id: "V", title: "VARIOUS" },
  { id: "V01", title: "ALLERGENS" },
  { id: "V01A", title: "ALLERGENS" },
  { id: "V01AA", title: "Allergen extracts" },
  { id: "V01AA01", title: "feather" },
  { id: "V01AA02", title: "grass pollen" },
  { id: "V01AA03", title: "house dust mites" },
  { id: "V01AA04", title: "mould fungus and yeast fungus" },
  { id: "V01AA05", title: "tree pollen" },
  { id: "V01AA07", title: "insects" },
  { id: "V01AA08", title: "food" },
  { id: "V01AA09", title: "textiles" },
  { id: "V01AA10", title: "flowers" },
  { id: "V01AA11", title: "animals" },
  { id: "V01AA20", title: "various" },
  { id: "V03", title: "ALL OTHER THERAPEUTIC PRODUCTS" },
  { id: "V03A", title: "ALL OTHER THERAPEUTIC PRODUCTS" },
  { id: "V03AB", title: "Antidotes" },
  { id: "V03AB01", title: "ipecacuanha" },
  { id: "V03AB02", title: "nalorphine" },
  { id: "V03AB03", title: "edetates" },
  { id: "V03AB04", title: "pralidoxime" },
  { id: "V03AB05", title: "prednisolone and promethazine" },
  { id: "V03AB06", title: "thiosulfate" },
  { id: "V03AB08", title: "sodium nitrite" },
  { id: "V03AB09", title: "dimercaprol" },
  { id: "V03AB13", title: "obidoxime" },
  { id: "V03AB14", title: "protamine" },
  { id: "V03AB15", title: "naloxone" },
  { id: "V03AB16", title: "ethanol" },
  { id: "V03AB17", title: "methylthioninium chloride" },
  { id: "V03AB18", title: "potassium permanganate" },
  { id: "V03AB19", title: "physostigmine" },
  { id: "V03AB20", title: "copper sulfate" },
  { id: "V03AB21", title: "potassium iodide" },
  { id: "V03AB22", title: "amyl nitrite" },
  { id: "V03AB23", title: "acetylcysteine" },
  { id: "V03AB24", title: "digitalis antitoxin" },
  { id: "V03AB25", title: "flumazenil" },
  { id: "V03AB26", title: "methionine" },
  { id: "V03AB27", title: "4-dimethylaminophenol" },
  { id: "V03AB29", title: "cholinesterase" },
  { id: "V03AB31", title: "prussian blue" },
  { id: "V03AB32", title: "glutathione" },
  { id: "V03AB33", title: "hydroxocobalamin" },
  { id: "V03AB34", title: "fomepizole" },
  { id: "V03AB35", title: "sugammadex" },
  { id: "V03AB36", title: "phentolamine" },
  { id: "V03AC", title: "Iron chelating agents" },
  { id: "V03AC01", title: "deferoxamine" },
  { id: "V03AC02", title: "deferiprone" },
  { id: "V03AC03", title: "deferasirox" },
  {
    id: "V03AE",
    title: "Drugs for treatment of hyperkalemia and hyperphosphatemia",
  },
  { id: "V03AE01", title: "polystyrene sulfonate" },
  { id: "V03AE02", title: "sevelamer" },
  { id: "V03AE03", title: "lanthanum carbonate" },
  { id: "V03AE04", title: "calcium acetate and magnesium carbonate" },
  { id: "V03AE05", title: "sucroferric oxyhydroxide" },
  { id: "V03AE06", title: "colestilan" },
  { id: "V03AF", title: "Detoxifying agents for antineoplastic treatment" },
  { id: "V03AF01", title: "mesna" },
  { id: "V03AF02", title: "dexrazoxane" },
  { id: "V03AF03", title: "calcium folinate" },
  { id: "V03AF04", title: "calcium levofolinate" },
  { id: "V03AF05", title: "amifostine" },
  { id: "V03AF06", title: "sodium folinate" },
  { id: "V03AF07", title: "rasburicase" },
  { id: "V03AF08", title: "palifermin" },
  { id: "V03AF09", title: "glucarpidase" },
  { id: "V03AF10", title: "sodium levofolinate" },
  { id: "V03AG", title: "Drugs for treatment of hypercalcemia" },
  { id: "V03AG01", title: "sodium cellulose phosphate" },
  { id: "V03AH", title: "Drugs for treatment of hypoglycemia" },
  { id: "V03AH01", title: "diazoxide" },
  { id: "V03AK", title: "Tissue adhesives" },
  { id: "V03AM", title: "Drugs for embolisation" },
  { id: "V03AN", title: "Medical gases" },
  { id: "V03AN01", title: "oxygen" },
  { id: "V03AN02", title: "carbon dioxide" },
  { id: "V03AN03", title: "helium" },
  { id: "V03AN04", title: "nitrogen" },
  { id: "V03AN05", title: "medical air" },
  { id: "V03AX", title: "Other therapeutic products" },
  { id: "V03AX02", title: "nalfurafine" },
  { id: "V03AX03", title: "cobicistat" },
  { id: "V03AZ", title: "Nerve depressants" },
  { id: "V03AZ01", title: "ethanol" },
  { id: "V04", title: "DIAGNOSTIC AGENTS" },
  { id: "V04B", title: "URINE TESTS" },
  { id: "V04C", title: "OTHER DIAGNOSTIC AGENTS" },
  { id: "V04CA", title: "Tests for diabetes" },
  { id: "V04CA01", title: "tolbutamide" },
  { id: "V04CA02", title: "glucose" },
  { id: "V04CB", title: "Tests for fat absorption" },
  { id: "V04CB01", title: "vitamin A concentrates" },
  { id: "V04CC", title: "Tests for bile duct patency" },
  { id: "V04CC01", title: "sorbitol" },
  { id: "V04CC02", title: "magnesium sulfate" },
  { id: "V04CC03", title: "sincalide" },
  { id: "V04CC04", title: "ceruletide" },
  { id: "V04CD", title: "Tests for pituitary function" },
  { id: "V04CD01", title: "metyrapone" },
  { id: "V04CD03", title: "sermorelin" },
  { id: "V04CD04", title: "corticorelin" },
  { id: "V04CD05", title: "somatorelin" },
  { id: "V04CE", title: "Tests for liver functional capacity" },
  { id: "V04CE01", title: "galactose" },
  { id: "V04CE02", title: "sulfobromophthalein" },
  { id: "V04CF", title: "Tuberculosis diagnostics" },
  { id: "V04CF01", title: "tuberculin" },
  { id: "V04CG", title: "Tests for gastric secretion" },
  { id: "V04CG01", title: "cation exchange resins" },
  { id: "V04CG02", title: "betazole" },
  { id: "V04CG03", title: "histamine phosphate" },
  { id: "V04CG04", title: "pentagastrin" },
  { id: "V04CG05", title: "methylthioninium chloride" },
  { id: "V04CG30", title: "caffeine and sodium benzoate" },
  { id: "V04CH", title: "Tests for renal function and ureteral injuries" },
  { id: "V04CH01", title: "inulin and other polyfructosans" },
  { id: "V04CH02", title: "indigo carmine" },
  { id: "V04CH03", title: "phenolsulfonphthalein" },
  { id: "V04CH04", title: "alsactide" },
  { id: "V04CH30", title: "aminohippuric acid" },
  { id: "V04CJ", title: "Tests for thyreoidea function" },
  { id: "V04CJ01", title: "thyrotropin" },
  { id: "V04CJ02", title: "protirelin" },
  { id: "V04CK", title: "Tests for pancreatic function" },
  { id: "V04CK01", title: "secretin" },
  { id: "V04CK02", title: "pancreozymin (cholecystokinin)" },
  { id: "V04CK03", title: "bentiromide" },
  { id: "V04CL", title: "Tests for allergic diseases" },
  { id: "V04CM", title: "Tests for fertility disturbances" },
  { id: "V04CM01", title: "gonadorelin" },
  { id: "V04CX", title: "Other diagnostic agents" },
  { id: "V06", title: "GENERAL NUTRIENTS" },
  { id: "V06A", title: "DIET FORMULATIONS FOR TREATMENT OF OBESITY" },
  { id: "V06AA", title: "Low-energy diets" },
  { id: "V06B", title: "PROTEIN SUPPLEMENTS" },
  { id: "V06C", title: "INFANT FORMULAS" },
  { id: "V06CA", title: "Nutrients without phenylalanine" },
  { id: "V06D", title: "OTHER NUTRIENTS" },
  {
    id: "V06DA",
    title: "Carbohydrates/proteins/minerals/vitamins, combinations",
  },
  {
    id: "V06DB",
    title: "Fat/carbohydrates/proteins/minerals/vitamins, combinations",
  },
  { id: "V06DC", title: "Carbohydrates" },
  { id: "V06DC01", title: "glucose" },
  { id: "V06DC02", title: "fructose" },
  {
    id: "V06DD",
    title: "Amino acids, incl. combinations with polypeptides",
  },
  {
    id: "V06DE",
    title: "Amino acids/carbohydrates/minerals/vitamins, combinations",
  },
  { id: "V06DF", title: "Milk substitutes" },
  { id: "V06DX", title: "Other combinations of nutrients" },
  { id: "V07", title: "ALL OTHER NON-THERAPEUTIC PRODUCTS" },
  { id: "V07A", title: "ALL OTHER NON-THERAPEUTIC PRODUCTS" },
  { id: "V07AA", title: "Plasters" },
  {
    id: "V07AB",
    title: "Solvents and diluting agents, incl. irrigating solutions",
  },
  { id: "V07AC", title: "Blood transfusion, auxiliary products" },
  { id: "V07AD", title: "Blood tests, auxiliary products" },
  { id: "V07AN", title: "Incontinence equipment" },
  { id: "V07AR", title: "Sensitivity tests, discs and tablets" },
  { id: "V07AS", title: "Stomi equipment" },
  { id: "V07AT", title: "Cosmetics" },
  { id: "V07AV", title: "Technical disinfectants" },
  { id: "V07AX", title: "Washing agents etc." },
  { id: "V07AY", title: "Other non-therapeutic auxiliary products" },
  { id: "V07AZ", title: "Chemicals and reagents for analysis" },
  { id: "V08", title: "CONTRAST MEDIA" },
  { id: "V08A", title: "X-RAY CONTRAST MEDIA, IODINATED" },
  {
    id: "V08AA",
    title: "Watersoluble, nephrotropic, high osmolar X-ray contrast media",
  },
  { id: "V08AA01", title: "diatrizoic acid" },
  { id: "V08AA02", title: "metrizoic acid" },
  { id: "V08AA03", title: "iodamide" },
  { id: "V08AA04", title: "iotalamic acid" },
  { id: "V08AA05", title: "ioxitalamic acid" },
  { id: "V08AA06", title: "ioglicic acid" },
  { id: "V08AA07", title: "acetrizoic acid" },
  { id: "V08AA08", title: "iocarmic acid" },
  { id: "V08AA09", title: "methiodal" },
  { id: "V08AA10", title: "diodone" },
  {
    id: "V08AB",
    title: "Watersoluble, nephrotropic, low osmolar X-ray contrast media",
  },
  { id: "V08AB01", title: "metrizamide" },
  { id: "V08AB02", title: "iohexol" },
  { id: "V08AB03", title: "ioxaglic acid" },
  { id: "V08AB04", title: "iopamidol" },
  { id: "V08AB05", title: "iopromide" },
  { id: "V08AB06", title: "iotrolan" },
  { id: "V08AB07", title: "ioversol" },
  { id: "V08AB08", title: "iopentol" },
  { id: "V08AB09", title: "iodixanol" },
  { id: "V08AB10", title: "iomeprol" },
  { id: "V08AB11", title: "iobitridol" },
  { id: "V08AB12", title: "ioxilan" },
  { id: "V08AC", title: "Watersoluble, hepatotropic X-ray contrast media" },
  { id: "V08AC01", title: "iodoxamic acid" },
  { id: "V08AC02", title: "iotroxic acid" },
  { id: "V08AC03", title: "ioglycamic acid" },
  { id: "V08AC04", title: "adipiodone" },
  { id: "V08AC05", title: "iobenzamic acid" },
  { id: "V08AC06", title: "iopanoic acid" },
  { id: "V08AC07", title: "iocetamic acid" },
  { id: "V08AC08", title: "sodium iopodate" },
  { id: "V08AC09", title: "tyropanoic acid" },
  { id: "V08AC10", title: "calcium iopodate" },
  { id: "V08AD", title: "Non-watersoluble X-ray contrast media" },
  { id: "V08AD01", title: "ethyl esters of iodised fatty acids" },
  { id: "V08AD02", title: "iopydol" },
  { id: "V08AD03", title: "propyliodone" },
  { id: "V08AD04", title: "iofendylate" },
  { id: "V08B", title: "X-RAY CONTRAST MEDIA, NON-IODINATED" },
  { id: "V08BA", title: "Barium sulfate containing X-ray contrast media" },
  { id: "V08BA01", title: "barium sulfate with suspending agents" },
  { id: "V08BA02", title: "barium sulfate without suspending agents" },
  { id: "V08C", title: "MAGNETIC RESONANCE IMAGING CONTRAST MEDIA" },
  { id: "V08CA", title: "Paramagnetic contrast media" },
  { id: "V08CA01", title: "gadopentetic acid" },
  { id: "V08CA02", title: "gadoteric acid" },
  { id: "V08CA03", title: "gadodiamide" },
  { id: "V08CA04", title: "gadoteridol" },
  { id: "V08CA05", title: "mangafodipir" },
  { id: "V08CA06", title: "gadoversetamide" },
  { id: "V08CA07", title: "ferric ammonium citrate" },
  { id: "V08CA08", title: "gadobenic acid" },
  { id: "V08CA09", title: "gadobutrol" },
  { id: "V08CA10", title: "gadoxetic acid" },
  { id: "V08CA11", title: "gadofosveset" },
  { id: "V08CB", title: "Superparamagnetic contrast media" },
  { id: "V08CB01", title: "ferumoxsil" },
  { id: "V08CB02", title: "ferristene" },
  { id: "V08CB03", title: "iron oxide, nanoparticles" },
  { id: "V08CX", title: "Other magnetic resonance imaging contrast media" },
  { id: "V08CX01", title: "perflubron" },
  { id: "V08D", title: "ULTRASOUND CONTRAST MEDIA" },
  { id: "V08DA", title: "Ultrasound contrast media" },
  { id: "V08DA01", title: "microspheres of human albumin" },
  { id: "V08DA02", title: "microparticles of galactose" },
  { id: "V08DA03", title: "perflenapent" },
  { id: "V08DA04", title: "microspheres of phospholipids" },
  { id: "V08DA05", title: "sulfur hexafluoride" },
  { id: "V09", title: "DIAGNOSTIC RADIOPHARMACEUTICALS" },
  { id: "V09A", title: "CENTRAL NERVOUS SYSTEM" },
  {
    id: "V09AX",
    title: "Other central nervous system diagnostic radiopharmaceuticals",
  },
  {
    id: "V09AX03",
    title: "iodine (124I) 2beta-carbomethoxy-3beta-(4 iodophenyl)-tropane",
  },
  { id: "V09B", title: "SKELETON" },
  { id: "V09C", title: "RENAL SYSTEM" },
  {
    id: "V09CX",
    title: "Other renal system diagnostic radiopharmaceuticals",
  },
  { id: "V09D", title: "HEPATIC AND RETICULO ENDOTHELIAL SYSTEM" },
  {
    id: "V09DX",
    title:
      "Other hepatic and reticulo endothelial system diagnostic radiopharmaceuticals",
  },
  { id: "V09E", title: "RESPIRATORY SYSTEM" },
  {
    id: "V09EX",
    title: "Other respiratory system diagnostic radiopharmaceuticals",
  },
  { id: "V09F", title: "THYROID" },
  { id: "V09FX", title: "Various thyroid diagnostic radiopharmaceuticals" },
  { id: "V09FX04", title: "sodium iodide (124I)" },
  { id: "V09G", title: "CARDIOVASCULAR SYSTEM" },
  {
    id: "V09GX",
    title: "Other cardiovascular system diagnostic radiopharmaceuticals",
  },
  { id: "V09GX04", title: "rubidium (82Rb) chloride" },
  { id: "V09H", title: "INFLAMMATION AND INFECTION DETECTION" },
  {
    id: "V09HX",
    title:
      "Other diagnostic radiopharmaceuticals for inflammation and infection detection",
  },
  { id: "V09I", title: "TUMOUR DETECTION" },
  {
    id: "V09IX",
    title: "Other diagnostic radiopharmaceuticals for tumour detection",
  },
  { id: "V09X", title: "OTHER DIAGNOSTIC RADIOPHARMACEUTICALS" },
  { id: "V09XX", title: "Various diagnostic radiopharmaceuticals" },
  { id: "V10", title: "THERAPEUTIC RADIOPHARMACEUTICALS" },
  { id: "V10A", title: "ANTIINFLAMMATORY AGENTS" },
  {
    id: "V10AX",
    title: "Other antiinflammatory therapeutic radiopharmaceuticals",
  },
  { id: "V10B", title: "PAIN PALLIATION (BONE SEEKING AGENTS)" },
  { id: "V10BX", title: "Various pain palliation radiopharmaceuticals" },
  { id: "V10X", title: "OTHER THERAPEUTIC RADIOPHARMACEUTICALS" },
  { id: "V10XX", title: "Various therapeutic radiopharmaceuticals" },
  { id: "V10XX03", title: "radium (223Ra) dichloride" },
  { id: "V20", title: "SURGICAL DRESSINGS" },
  { id: "A02BA", title: "H2-receptor antagonists" },
  { id: "A02BA01", title: "cimetidine" },
  { id: "A02BA02", title: "ranitidine" },
  { id: "A02BA03", title: "famotidine" },
  { id: "A02BA04", title: "nizatidine" },
  { id: "A02BA05", title: "niperotidine" },
  { id: "A02BA06", title: "roxatidine" },
  { id: "A02BA07", title: "ranitidine bismuth citrate" },
  { id: "A02BA08", title: "lafutidine" },
  { id: "A02BA51", title: "cimetidine, combinations" },
  { id: "A02BA53", title: "famotidine, combinations" },
  { id: "N02BE73", title: "phenacetin, combinations with psycholeptics" },
  { id: "N02BE74", title: "bucetin, combinations with psycholeptics" },
  { id: "N02BG", title: "Other analgesics and antipyretics" },
  { id: "N02BG02", title: "rimazolium" },
  { id: "N02BG03", title: "glafenine" },
  { id: "N02BG04", title: "floctafenine" },
  { id: "N02BG05", title: "viminol" },
  { id: "N02BG06", title: "nefopam" },
  { id: "N02BG07", title: "flupirtine" },
  { id: "N02BG08", title: "ziconotide" },
  { id: "N02BG09", title: "methoxyflurane" },
  { id: "N02BG10", title: "cannabinoids" },
  { id: "N02C", title: "ANTIMIGRAINE PREPARATIONS" },
  { id: "N02CA", title: "Ergot alkaloids" },
  { id: "N02CA01", title: "dihydroergotamine" },
  { id: "N02CA02", title: "ergotamine" },
  { id: "N02CA04", title: "methysergide" },
  { id: "N02CA07", title: "lisuride" },
  { id: "N02CA51", title: "dihydroergotamine, combinations" },
  { id: "N02CA52", title: "ergotamine, combinations excl. psycholeptics" },
  { id: "N02CA72", title: "ergotamine, combinations with psycholeptics" },
  { id: "N02CB", title: "Corticosteroid derivatives" },
  { id: "N02CB01", title: "flumedroxone" },
  { id: "N02CC", title: "Selective serotonin (5HT1) agonists" },
  { id: "N02CC01", title: "sumatriptan" },
  { id: "N02CC02", title: "naratriptan" },
  { id: "N02CC03", title: "zolmitriptan" },
  { id: "N02CC04", title: "rizatriptan" },
  { id: "N02CC05", title: "almotriptan" },
  { id: "N02CC06", title: "eletriptan" },
  { id: "N02CC07", title: "frovatriptan" },
  { id: "N02CX", title: "Other antimigraine preparations" },
  { id: "N02CX01", title: "pizotifen" },
  { id: "N02CX02", title: "clonidine" },
  { id: "N02CX03", title: "iprazochrome" },
  { id: "N02CX05", title: "dimetotiazine" },
  { id: "N02CX06", title: "oxetorone" },
  { id: "P03BX04", title: "dibutylsuccinate" },
  { id: "P03BX05", title: "dimethylcarbate" },
  { id: "P03BX06", title: "etohexadiol" },
  { id: "V09AA", title: "Technetium (99mTc) compounds" },
  { id: "V09AA01", title: "technetium (99mTc) exametazime" },
  { id: "V09AA02", title: "technetium (99mTc) bicisate" },
  { id: "V09AB", title: "Iodine (123I) compounds" },
  { id: "V09AB01", title: "iodine iofetamine (123I)" },
  { id: "V09AB02", title: "iodine iolopride (123I)" },
  { id: "V09AB03", title: "iodine ioflupane (123I)" },
  { id: "V09AX01", title: "indium (111In) pentetic acid" },
  { id: "V09AX04", title: "flutemetamol (18F)" },
  { id: "V09AX05", title: "florbetapir (18F)" },
  { id: "V09BA", title: "Technetium (99mTc) compounds" },
  { id: "V09BA01", title: "technetium (99mTc) oxidronic acid" },
  { id: "V09BA02", title: "technetium (99mTc) medronic acid" },
  { id: "V09BA03", title: "technetium (99mTc) pyrophosphate" },
  { id: "V09BA04", title: "technetium (99mTc) butedronic acid" },
  { id: "V09CA", title: "Technetium (99mTc) compounds" },
  { id: "V09CA01", title: "technetium (99mTc) pentetic acid" },
  { id: "V09CA02", title: "technetium (99mTc) succimer" },
  { id: "V09CA03", title: "technetium (99mTc) mertiatide" },
  { id: "V09CA04", title: "technetium (99mTc) gluceptate" },
  { id: "V09CA05", title: "technetium (99mTc) gluconate" },
  { id: "V09CA06", title: "technetium (99mTc) ethylenedicysteine" },
  { id: "V09CX01", title: "sodium iodohippurate (123I)" },
  { id: "V09CX02", title: "sodium iodohippurate (131I)" },
  { id: "V09CX03", title: "sodium iothalamate (125I)" },
  { id: "V09CX04", title: "chromium (51Cr) edetate" },
  { id: "V09DA", title: "Technetium (99mTc) compounds" },
  { id: "V09DA01", title: "technetium (99mTc) disofenin" },
  { id: "V09DA02", title: "technetium (99mTc) etifenin" },
  { id: "V09DA03", title: "technetium (99mTc) lidofenin" },
  { id: "V09DA04", title: "technetium (99mTc) mebrofenin" },
  { id: "V09DA05", title: "technetium (99mTc) galtifenin" },
  { id: "V09DB", title: "Technetium (99mTc), particles and colloids" },
  { id: "V09DB01", title: "technetium (99mTc) nanocolloid" },
  { id: "V09DB02", title: "technetium (99mTc) microcolloid" },
  { id: "V09DB03", title: "technetium (99mTc) millimicrospheres" },
  { id: "V09DB04", title: "technetium (99mTc) tin colloid" },
  { id: "V09DB05", title: "technetium (99mTc) sulfur colloid" },
  { id: "V09DB06", title: "technetium (99mTc) rheniumsulfide colloid" },
  { id: "V09DB07", title: "technetium (99mTc) phytate" },
  { id: "V09DX01", title: "selenium (75Se) tauroselcholic acid" },
  { id: "V09EA", title: "Technetium (99mTc), inhalants" },
  { id: "V09EA01", title: "technetium (99mTc) pentetic acid" },
  { id: "V09EA02", title: "technetium (99mTc) technegas" },
  { id: "V09EA03", title: "technetium (99mTc) nanocolloid" },
  { id: "V09EB", title: "Technetium (99mTc), particles for injection" },
  { id: "V09EB01", title: "technetium (99mTc) macrosalb" },
  { id: "V09EB02", title: "technetium (99mTc) microspheres" },
  { id: "V09EX01", title: "krypton (81mKr) gas" },
  { id: "V09EX02", title: "xenon (127Xe) gas" },
  { id: "V09EX03", title: "xenon (133Xe) gas" },
  { id: "V09FX01", title: "technetium (99mTc) pertechnetate" },
  { id: "V09FX02", title: "sodium iodide (123I)" },
  { id: "V09FX03", title: "sodium iodide (131I)" },
  { id: "V09GA", title: "Technetium (99mTc) compounds" },
  { id: "V09GA01", title: "technetium (99mTc) sestamibi" },
  { id: "V09GA02", title: "technetium (99mTc) tetrofosmin" },
  { id: "V09GA03", title: "technetium (99mTc) teboroxime" },
  { id: "V09GA04", title: "technetium (99mTc) human albumin" },
  { id: "V09GA05", title: "technetium (99mTc) furifosmin" },
  {
    id: "V09GA06",
    title: "technetium (99mTc) stannous agent labelled cells",
  },
  { id: "V09GA07", title: "technetium (99mTc) apcitide" },
  { id: "V09GB", title: "Iodine (125I) compounds" },
  { id: "V09GB01", title: "fibrinogen (125I)" },
  { id: "V09GB02", title: "iodine (125I) human albumin" },
  { id: "V09GX01", title: "thallium (201Tl) chloride" },
  { id: "V09GX02", title: "indium (111In) imciromab" },
  { id: "V09GX03", title: "chromium (51Cr) chromate labelled cells" },
  { id: "V09HA", title: "Technetium (99mTc) compounds" },
  { id: "V09HA01", title: "technetium (99mTc) human immunoglobulin" },
  { id: "V09HA02", title: "technetium (99mTc) exametazime labelled cells" },
  { id: "V09HA03", title: "technetium (99mTc) antigranulocyte antibody" },
  { id: "V09HA04", title: "technetium (99mTc) sulesomab" },
  { id: "V09HB", title: "Indium (111In) compounds" },
  { id: "V09HB01", title: "indium (111In) oxinate labelled cells" },
  { id: "V09HB02", title: "indium (111In) tropolonate labelled cells" },
  { id: "V09HX01", title: "gallium (67Ga) citrate" },
  { id: "V09IA", title: "Technetium (99mTc) compounds" },
  {
    id: "V09IA01",
    title: "technetium (99mTc) antiCarcinoEmbryonicAntigen antibody",
  },
  { id: "V09IA02", title: "technetium (99mTc) antimelanoma antibody" },
  { id: "V09IA03", title: "technetium (99mTc) pentavalent succimer" },
  { id: "V09IA04", title: "technetium (99mTc) votumumab" },
  { id: "V09IA05", title: "technetium (99mTc) depreotide" },
  { id: "V09IA06", title: "technetium (99mTc) arcitumomab" },
  { id: "V09IA07", title: "technetium (99mTc) hynic-octreotide" },
  { id: "V09IA08", title: "technetium (99mTc) etarfolatide" },
  { id: "V09IA09", title: "technetium (99mTc) tilmanocept" },
  { id: "V09IB", title: "Indium (111In) compounds" },
  { id: "V09IB01", title: "indium (111In) pentetreotide" },
  { id: "V09IB02", title: "indium (111In) satumomab pendetide" },
  { id: "V09IB03", title: "indium (111In) antiovariumcarcinoma antibody" },
  { id: "V09IB04", title: "indium (111In) capromab pendetide" },
  { id: "V09IX01", title: "iobenguane (123I)" },
  { id: "V09IX02", title: "iobenguane (131I)" },
  { id: "V09IX03", title: "iodine (125I) CC49-monoclonal antibody" },
  { id: "V09IX04", title: "fludeoxyglucose (18F)" },
  { id: "V09IX05", title: "fluorodopa (18F)" },
  { id: "V09IX06", title: "sodium fluoride (18F)" },
  { id: "V09IX07", title: "fluoromethylcholine (18F)" },
  { id: "V09IX08", title: "fluoroethylcholine (18F)" },
  { id: "V09XA", title: "Iodine (131I) compounds" },
  { id: "V09XA01", title: "iodine (131I) norcholesterol" },
  { id: "V09XA02", title: "iodocholesterol (131I)" },
  { id: "V09XA03", title: "iodine (131I) human albumin" },
  { id: "V09XX01", title: "cobalt (57Co) cyanocobalamine" },
  { id: "V09XX02", title: "cobalt (58Co) cyanocobalamine" },
  { id: "V09XX03", title: "selenium (75Se) norcholesterol" },
  { id: "V09XX04", title: "ferric (59Fe) citrate" },
  { id: "V10AA", title: "Yttrium (90Y) compounds" },
  { id: "V10AA01", title: "yttrium (90Y) citrate colloid" },
  { id: "V10AA02", title: "yttrium (90Y) ferrihydroxide colloid" },
  { id: "V10AA03", title: "yttrium (90Y) silicate colloid" },
  { id: "V10AX01", title: "phosphorous (32P) chromicphosphate colloid" },
  { id: "V10AX02", title: "samarium (153Sm) hydroxyapatite colloid" },
  { id: "V10AX03", title: "dysprosium (165Dy) colloid" },
  { id: "V10AX04", title: "erbium (169Er) citrate colloid" },
  { id: "V10AX05", title: "rhenium (186Re) sulfide colloid" },
  { id: "V10AX06", title: "gold (198Au) colloidal" },
  { id: "V10BX01", title: "strontium (89Sr) chloride" },
  { id: "V10BX02", title: "samarium (153Sm) lexidronam" },
  { id: "V10BX03", title: "rhenium (186Re) etidronic acid" },
  { id: "V10XA", title: "Iodine (131I) compounds" },
  { id: "V10XA01", title: "sodium iodide (131I)" },
  { id: "V10XA02", title: "iobenguane (131I)" },
  { id: "V10XA53", title: "tositumomab/iodine (131I) tositumomab" },
  { id: "V10XX01", title: "sodium phosphate (32P)" },
  { id: "V10XX02", title: "ibritumomab tiuxetan [90Y]" },
];

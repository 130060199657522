import styled from "styled-components";

export const NewRfqWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
   padding: 2rem 0.5rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .new-rfq-container {
    width: 100%;
  }
`;

import { AiOutlineAppstore, AiOutlineSetting } from "react-icons/ai";
import { BiBuildingHouse, BiCube, BiGitPullRequest } from "react-icons/bi";

import {
  BsCommand,
  BsFolder2,
  BsReceipt,
  BsDistributeHorizontal,
} from "react-icons/bs";
import { FaWarehouse } from "react-icons/fa";
import { FcInvite } from "react-icons/fc";
import { IoGitPullRequestOutline } from "react-icons/io5";

import {
  MdApproval,
  MdOutlineCancel,
  MdOutlinePayments,
  MdOutlineQuestionAnswer,
} from "react-icons/md";
import { PiWarehouseDuotone, PiWarehouseLight } from "react-icons/pi";
import { states } from "src/common/data";
import { KeyValueObj } from "src/common/types";

export const sidebarItems = [
  {
    name: "Dashboard",
    link: "/dashboard/stats",
    icon: AiOutlineAppstore,
    roles: ["manufacturer", "importer", "distributor", "retailer"],
    type: "item",
  },
  {
    name: "Products",
    link: "/dashboard/products",
    icon: BsCommand,
    roles: ["manufacturer", "importer", "distributor", "retailer"],
    type: "item",
  },
  {
    name: "Deliveries",
    link: "/dashboard/deliveries",
    icon: BsCommand,
    roles: ["manufacturer", "importer", "distributor", "retailer"],
    type: "item",
  },
  {
    name: "Inventories",
    link: "/dashboard/inventories",
    icon: BsFolder2,
    roles: ["manufacturer", "importer", "distributor", "retailer"],
    type: "item",
  },
  {
    name: "Orders",
    link: "/dashboard/requisition",
    icon: IoGitPullRequestOutline,
    roles: ["manufacturer", "importer", "distributor", "retailer"],
    type: "item",
  },
  {
    name: "RFQs",
    link: "/dashboard/rfq",
    icon: BsReceipt,
    roles: ["distributor", "retailer"],
    type: "item",
  },
  {
    name: "My Distributors",
    link: "/dashboard/my-team/manufacturer-distributors",
    icon: BsDistributeHorizontal,
    roles: ["manufacturer"],
    type: "item",
  },
  {
    name: "My Distributors",
    link: "/dashboard/my-team/importer-distributors",
    icon: BsDistributeHorizontal,
    roles: ["importer"],
    type: "item",
  },

  {
    name: "My Distribution",
    link: "/dashboard/my-team/distributor-importer-manufacturers",
    icon: FaWarehouse,
    roles: ["distributor"],
    type: "item",
  },
  {
    name: "My Retailers",
    link: "/dashboard/my-team/distributor-retailers",
    icon: PiWarehouseDuotone,
    roles: ["distributor"],
    type: "item",
  },
  {
    name: "My Distributors",
    link: "/dashboard/my-team/retailer-distributors",
    icon: PiWarehouseLight,
    roles: ["retailer"],
    type: "item",
  },
  {
    name: "My Distributors",
    link: "/dashboard/my-team/lsdp-distributors",
    icon: PiWarehouseLight,
    roles: ["delivery service provider"],
    type: "item",
  },
  {
    name: "My LSDPs",
    link: "/dashboard/my-team/distributor-lsdp",
    icon: PiWarehouseLight,
    roles: ["distributor"],
    type: "item",
  },
  {
    name: "Distribution Requests",
    link: "/dashboard/requests/manufacturer",
    icon: BiGitPullRequest,
    roles: ["manufacturer"],
    type: "item",
  },
  {
    name: "Distribution Requests",
    link: "/dashboard/requests/importer",
    icon: BiGitPullRequest,
    roles: ["importer"],
    type: "item",
  },
  {
    name: "Distribution Requests",
    link: "/dashboard/requests/distributor",
    icon: BiGitPullRequest,
    roles: ["distributor"],
    type: "item",
  },
  {
    name: "Supplier Requests",
    link: "/dashboard/requests/retailer",
    icon: BiGitPullRequest,
    roles: ["retailer"],
    type: "item",
  },
  {
    name: "Distributor Requests",
    link: "/dashboard/requests/lsdp",
    icon: BiGitPullRequest,
    roles: ["delivery service provider"],
    type: "item",
  },
  {
    name: "Invoices",
    link: "/dashboard/invoices",
    icon: MdOutlinePayments,
    roles: ["distributor", "retailer"],
    type: "item",
  },
  {
    type: "line",
  },
  {
    name: "Settings",
    link: "/dashboard/settings",
    icon: AiOutlineSetting,
    roles: ["retailer"],
    type: "item",
  },
  {
    type: "line",
  },
];

export const pcnSideItems = [
  {
    name: "Invite Company",
    link: "/dashboard/pcn-admin/invite-companies",
    icon: FcInvite,
    roles: ["pcn admin"],
    type: "item",
  },
];

export const adminsideItems = [
  {
    name: "Companies",
    icon: BiBuildingHouse,
    options: [
      {
        name: "Pending",
        link: "admin/companies/pending",
        icon: MdOutlineQuestionAnswer,
      },
      {
        name: "Approved",
        link: "admin/companies/approved",
        icon: MdApproval,
      },
      {
        name: "Queried",
        link: "admin/companies/queried",
        icon: MdOutlineQuestionAnswer,
      },

      {
        name: "Declined",
        link: "admin/companies/declined",
        icon: MdOutlineCancel,
      },
    ],
  },
  {
    name: "Products",
    icon: BiCube,
    options: [
      {
        name: "Pending",
        link: "admin/products/pending",
        icon: MdOutlineQuestionAnswer,
      },
      {
        name: "Approved",
        link: "admin/products/approved",
        icon: MdApproval,
      },
      {
        name: "Queried",
        link: "admin/products/queried",
        icon: MdOutlineQuestionAnswer,
      },
      {
        name: "Declined",
        link: "admin/products/declined",
        icon: MdOutlineCancel,
      },
    ],
  },
];

export const getProfileEditForm = (
  authDetails: KeyValueObj,
  address: string
) => [
  {
    inputType: "text",
    prompt: "First Name",
    name: "first_name",
    placeholder: "Enter your first name",
    fullWidth: false,
    initialValue: authDetails.firstName,
    rules: {
      minLength: {
        expectedValue: 2,
        errorMessage: "First name should be atleast 2 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Middle Name",
    name: "middle_name",
    placeholder: "Enter your middle name",
    fullWidth: false,
    initialValue: authDetails.middleName,
    rules: {
      minLength: {
        expectedValue: 2,
        errorMessage: "Middle name should be atleast 2 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Last Name",
    name: "last_name",
    placeholder: "Enter your last name",
    fullWidth: false,
    initialValue: authDetails.lastName,
    rules: {
      minLength: {
        expectedValue: 2,
        errorMessage: "Last name should be atleast 2 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Address",
    name: "address",
    placeholder: "Enter your last name",
    fullWidth: false,
    initialValue: address,
    rules: {},
  },
  {
    inputType: "selectParent",
    prompt: "State",
    name: "state_of_residence",
    placeholder: "Select your state",
    fullWidth: false,
    options: states,
    initialValue: authDetails?.state,
  },
  {
    inputType: "selectChild",
    prompt: "Local Government Area",
    name: "lga_of_residence",
    placeholder: "Select your LGA",
    fullWidth: false,
    options: states,
    parentName: "state_of_residence",
    initialValue: authDetails?.local_govt,
  },
];

export const getDistributorsCompanyForm = (
  companyData: KeyValueObj,
  baseData: KeyValueObj
) => [
  {
    inputType: "text",
    prompt: "Company Name",
    name: "name",
    placeholder: "Enter your Company Name",
    fullWidth: false,
    initialValue: companyData?.name,
    readOnly: true,
  },

  {
    inputType: "text",
    prompt: "TIN number",
    name: "tin",
    placeholder: "Enter your tin number",
    fullWidth: false,
    maxLength: 13,
    initialValue: companyData?.tin,
    readOnly: true,
  },
  {
    inputType: "text",
    prompt: "RC number",
    name: "rc_number",
    placeholder: "Enter your RC number",
    fullWidth: false,
    readOnly: true,
    initialValue: companyData?.rc_number,
  },

  {
    inputType: "text",
    prompt: "Corporate address",
    name: "corporate_address",
    placeholder: "Enter your corporate address",
    fullWidth: false,
    initialValue: companyData?.corporate_address,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Corporate address should be atleast 3 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Landmark",
    name: "landmark",
    placeholder: "Enter a landmark closest to your address",
    fullWidth: false,
    initialValue: companyData?.landmark,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Landmark should be atleast 3 characters",
      },
    },
  },

  {
    inputType: "date",
    prompt: "Date of Establishment",
    name: "date_of_establishment",
    placeholder: "Enter your date of establishment",
    fullWidth: false,
    initialValue: companyData?.date_of_establishment,
    rules: {
      notInTheFuture: {
        expectedValue: true,
        errorMessage: "Your date of establishment cannot be in the future",
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Company Status",
    name: "company_status",
    options_name: "retailer_status",
    placeholder: "Select your company status",
    fullWidth: false,
    initialValue: companyData?.retailer_status,
    options: baseData?.values?.retailer_status?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },
];

export const getRetailerUpdateForm = (
  companyData: KeyValueObj,
  baseData: KeyValueObj
) => [
  {
    inputType: "text",
    prompt: "Company Name",
    name: "name",
    placeholder: "Enter your Company Name",
    fullWidth: false,
    initialValue: companyData?.name,
    readOnly: true,
  },

  {
    inputType: "text",
    prompt: "TIN number",
    name: "tin",
    placeholder: "Enter your tin number",
    fullWidth: false,
    maxLength: 13,
    initialValue: companyData?.tin,
    readOnly: true,
  },
  {
    inputType: "text",
    prompt: "RC number",
    name: "rc_number",
    placeholder: "Enter your RC number",
    fullWidth: false,
    readOnly: true,
    initialValue: companyData?.rc_number,
  },

  {
    inputType: "text",
    prompt: "Corporate address",
    name: "corporate_address",
    placeholder: "Enter your corporate address",
    fullWidth: false,
    initialValue: companyData?.corporate_address,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Corporate address should be atleast 3 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Landmark",
    name: "landmark",
    placeholder: "Enter a landmark closest to your address",
    fullWidth: false,
    initialValue: companyData?.landmark,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Landmark should be atleast 3 characters",
      },
    },
  },

  {
    inputType: "selectInput",
    prompt: "Retailer Type",
    options_name: "retailer_types",
    name: "company_type",
    placeholder: "Select your retailer type",
    initialValue: companyData?.company_type,
    fullWidth: false,
    options: baseData?.values?.retailer_types?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },
  {
    inputType: "selectInput",
    prompt: "Retailer Class",
    options_name: "retailer_classes",
    name: "company_class",
    placeholder: "Select your retailer class",
    fullWidth: false,
    initialValue: companyData?.company_class,
    options: baseData?.values?.retailer_classes?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },
  {
    inputType: "selectInput",
    prompt: "Level",
    options_name: "retailer_levels",
    name: "company_level",
    placeholder: "Select your level",
    initialValue: companyData?.company_level,
    fullWidth: false,
    options: baseData?.values?.retailer_levels?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },
  {
    inputType: "selectInput",
    prompt: "Ownership",
    options_name: "retailer_ownerships",
    name: "company_ownership",
    placeholder: "Select the type of ownership",
    fullWidth: false,
    initialValue: companyData?.company_ownership,
    options: baseData?.values?.retailer_ownerships?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },
  {
    inputType: "selectInput",
    prompt: "Retailer Status",
    name: "company_status",
    options_name: "retailer_status",
    placeholder: "Select your company status",
    fullWidth: false,
    initialValue: companyData?.reatailer_status,
    options: baseData?.values?.retailer_status?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },

  {
    inputType: "date",
    prompt: "Date of establishment",
    name: "date_of_establishment",
    placeholder: "Enter date of establishment",
    fullWidth: false,
    initialValue: companyData?.date_of_establishment,
    rules: {
      notInTheFuture: {
        expectedValue: true,
        errorMessage: "Your date of establishment cannot be in the future",
      },
    },
  },
];

export const getManuImporterUpdateForm = (
  companyData: KeyValueObj,
  baseData: KeyValueObj
) => [
  {
    inputType: "text",
    prompt: "Company Name",
    name: "name",
    placeholder: "Enter your Company Name",
    fullWidth: false,
    initialValue: companyData?.name,
    readOnly: true,
  },

  {
    inputType: "text",
    prompt: "TIN number",
    name: "tin",
    placeholder: "Enter your tin number",
    fullWidth: false,
    maxLength: 13,
    initialValue: companyData?.tin,
    readOnly: true,
  },
  {
    inputType: "text",
    prompt: "RC number",
    name: "rc_number",
    placeholder: "Enter your RC number",
    fullWidth: false,
    readOnly: true,
    initialValue: companyData?.rc_number,
  },

  {
    inputType: "text",
    prompt: "Corporate address",
    name: "corporate_address",
    placeholder: "Enter your corporate address",
    fullWidth: false,
    initialValue: companyData?.corporate_address,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Corporate address should be atleast 3 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Landmark",
    name: "landmark",
    placeholder: "Enter a landmark closest to your address",
    fullWidth: false,
    initialValue: companyData?.landmark,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Landmark should be atleast 3 characters",
      },
    },
  },

  {
    inputType: "date",
    prompt: "Date of Establishment",
    name: "date_of_establishment",
    placeholder: "Enter your date of establishment",
    fullWidth: false,
    initialValue: companyData?.date_of_establishment,
    rules: {
      notInTheFuture: {
        expectedValue: true,
        errorMessage: "Your date of establishment cannot be in the future",
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Company Status",
    name: "company_status",
    options_name: "retailer_status",
    placeholder: "Select your company status",
    fullWidth: false,
    initialValue: companyData?.retailer_status,
    options: baseData?.values?.retailer_status?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },

  {
    inputType: "text",
    prompt: "NAFDAC number",
    name: "nafdac_number",
    placeholder: "Enter your NAFDAC number",
    initialValue: companyData?.nafdac_number,
    fullWidth: false,
    maxLength: 7,
    rules: {
      length: {
        expectedValue: 7,
        errorMessage: "NAFDAC number should be 7 characters",
      },
    },
  },
];

export const getContactUpdateForm = (
  userData: KeyValueObj,
  baseData: KeyValueObj
) => [
  {
    inputType: "text",
    prompt: "Contact Full Name",
    name: "contact_full_name",
    placeholder: "Enter your contact's full name",
    fullWidth: false,

    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Full name should be atleast 3 characters",
      },
    },
  },
  {
    inputType: "selectInput",
    prompt: "Contact Designation",
    name: "contact_designation",
    options_name: "contact_roles",
    placeholder: "Select your contact's designation",
    fullWidth: false,
    options: baseData?.values?.contact_roles?.map((status: string) => ({
      id: status,
      title: status,
    })),
  },
  {
    inputType: "email",
    prompt: "Contact Email",
    name: "contact_email",
    placeholder: "Enter your contact email",
    fullWidth: false,
    rules: {
      isEmail: {
        expectedValue: true,
        errorMessage: "Not a valid email address",
      },
    },
  },

  {
    inputType: "text",
    prompt: "Contact Phone",
    name: "contact_phone",
    placeholder: "Enter your contact's phone number",
    fullWidth: false,
    rules: {
      validNigerianPhoneNumber: {
        expectedValue: true,
        errorMessage: "Invalid Nigerian phone number",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Contact City",
    name: "contact_city",
    placeholder: "Enter your contact's city",
    fullWidth: false,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Contact city should be atleast 3 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Contact Address",
    name: "contact_address",
    placeholder: "Enter your contact's address",
    fullWidth: false,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Contact address should be atleast 3 characters",
      },
    },
  },

  {
    inputType: "selectParent",
    prompt: "State",
    name: "state",
    placeholder: "Select your state",
    fullWidth: false,
    options: states,
  },
  {
    inputType: "selectChild",
    prompt: "Local Government Area",
    name: "local_govt",
    placeholder: "Select your LGA",
    fullWidth: false,
    options: states,
    parentName: "state",
  },
];

export const getCompanyAddressUpdateForm = (companyData: KeyValueObj) => [
  {
    inputType: "text",
    prompt: "Building Number",
    name: "building_number",
    placeholder: "Enter your building number",
    fullWidth: false,
    rules: {
      minLength: {
        expectedValue: 1,
        errorMessage: "Street number should be atleast 1 character",
      },
    },
  },
  {
    inputType: "text",
    prompt: "Street",
    name: "street",
    placeholder: "Enter your street",
    fullWidth: false,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "Street address should be atleast 3 characters",
      },
    },
  },
  {
    inputType: "text",
    prompt: "City",
    name: "city",
    placeholder: "Enter your city",
    fullWidth: false,
    rules: {
      minLength: {
        expectedValue: 3,
        errorMessage: "City should be atleast 3 characters",
      },
    },
  },
  {
    inputType: "selectParent",
    prompt: "State",
    name: "state",
    placeholder: "Select your state",
    fullWidth: false,
    options: states,
  },
  {
    inputType: "selectChild",
    prompt: "Local Government Area",
    name: "local_govt",
    placeholder: "Select your LGA",
    fullWidth: false,
    options: states,
    parentName: "state",
  },
];

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { DataWrapper } from "../../../common/components/dataWrapper";
import Form from "../../../common/components/form/Form";
import { Modal } from "../../../common/components/modal";
import { Preview } from "../../../common/components/preview";
import Table from "../../../common/components/Table";
import { PRODUCT_URL, INVENTORY_URL } from "../../../common/constants";
import {
  ModalFormWrapper,
  ModalPreviewWrapper,
  ModalWrapper,
} from "../../../common/elements";
import { KeyValueObj } from "../../../common/types";
//import { sleep } from "../../../common/utils";
import { useApiCallHandling } from "../../../hooks/useApiCallHandling";
import { useAuthDetails } from "../../../hooks/useAuthDetails";
import useAxiosCall from "../../../hooks/useAxiosCall";
import { Barchart } from "../common/Barchart";
import { barOptions, barUserData } from "../common/Data";
import {
  getBarchartData,
  getCardDetails,
  ICardDetails,
  inventoryForm,
  tableDataShort,
  tableHeaders,
  tableHeadersShort,
  tableKeys,
  tableKeysShort,
} from "./subs/data";
import { AddInventoryWrapper } from "./subs/style";
import { StatCard } from "./subs/StatCard";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import { Button } from "src/common/components/button";
import { formatToNaira } from "src/common/utils/general";

export interface IAddInventoryProps {}

export function AddInventory(props: IAddInventoryProps) {
  //STATES
  // const [searchString, setSearchString] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [stage, setStage] = useState("inventory");
  const [retry, setRetry] = useState(0);
  const [actualInventoryForm, setActualInventoryForm] = useState<any[]>([]);
  const [editForm, setEditForm] = useState<any[]>([]);
  const [inventories, setInventories] = useState<KeyValueObj[]>([]);
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formValues, setFormValues] = useState<KeyValueObj>({});
  const [options, setOptions] = useState<KeyValueObj[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentInventoryId, setCurrentInventoryId] = useState("");
  const [, setShowActions] = useState(false);
  const [, setDisableActionButtons] = useState(false);
  const [, setCurrentInventoryLoadingButton] = useState("");

  //HOOKES
  const [getProducts, productsFetched, productsError, productsLoading] =
    useAxiosCall();
  const [
    getInventories,
    inventoriesFetched,
    inventoriesError,
    inventoriesLoading,
  ] = useAxiosCall();
  const apiCall = useApiCallHandling();
  const [searchParams] = useSearchParams();
  const { companyType } = useAuthDetails();

  //USEEFFECTS

  //FETCHING PAGE LOAD DATA
  useEffect(() => {
    getProducts({
      method: "GET",
      url: PRODUCT_URL + "?status=approved",
    });
    getInventories({
      method: "GET",
      url: INVENTORY_URL,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry]);

  //HANDLING INVENTORY TABLE
  useEffect(() => {
    inventoriesFetched && setInventories(inventoriesFetched);
  }, [inventoriesFetched]);

  useEffect(() => {
    const inventoryTable =
      inventories.length > 0 &&
      inventories
        .reverse()
        .map((inventory: (typeof inventoriesFetched)[0]) => ({
          name: {
            value: inventory?.product?.brand_name,
            type: "string",
          },

          unit: {
            value: inventory?.product?.form,
            type: "string",
          },
          strength: {
            type: "string",
            value: `${inventory?.product?.strength}${
              inventory?.product?.strength_unit === "milligrams" ? "mg" : "g"
            }`,
          },
          storage: {
            value: inventory?.product.storage_requirement,
            type: "string",
          },

          quantity: {
            value: inventory?.quantity_received,
            type: "string",
          },
          minLevel: {
            value: inventory?.reorder_level,
            type: "string",
          },
          supply: {
            value: inventory?.quantity_sold,
            type: "string",
          },
          selling_price: {
            value: formatToNaira(inventory?.selling_price_per_unit),
            type: "string",
          },
          // action: {
          //   type: "button",
          //   buttonType: "dots",
          //   buttonLoading: false,
          //   buttonName: "",
          //   onClick: dotClicked,
          // },
          id: inventory?.id,
        }));

    inventoryTable && setTableData(inventoryTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventories]);

  // HANDLING INVENTORY FORM
  useEffect(() => {
    //create the options
    const options: KeyValueObj[] = [];
    productsFetched?.forEach((product: KeyValueObj) => {
      options.push({
        id: product.id,
        title: `${product.atc_code?.readable_name}(${product.brand_name}) - ${
          product?.strength
        }${product?.strength_unit === "grams" ? "mg" : "g"}`,
      });
    });

    //add to options


    //This check is very neccesary
    // if (options.length > 0) {
    setActualInventoryForm([
      {
        inputType: "selectInput",
        prompt: "Select the product",
        name: "product_id",
        placeholder: "Select a product from the list",
        fullWidth: false,
        returnId: true,
        options: options?.length > 0 ? options : [],
        onEveryChange: onProductSelect,
      },
      ...inventoryForm,
    ]);
    // }

    setOptions(options);

    if (searchParams.get("new") === "true" && !productsLoading) {
      setShowModal(true);
      setModalTitle("Add Inventory");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsFetched, productsError, productsLoading]);

  //FUNCTIONS
  function onAddEventory() {
    setShowModal(true);
    setModalTitle("Add Inventory");
    setStage("inventory");
  }

  const onProductSelect: any = async (
    latestInput: string,
    name: string,
    updateValidity: (name: string, validity: boolean) => void,
    setFormValues: Dispatch<SetStateAction<KeyValueObj>>
  ) => {
    //replace the package_type with loading state
    setActualInventoryForm((prev: any) => {
      const packageInput = {
        inputType: "text",
        prompt: "Package type",
        options_name: "package_type",
        name: "package_type",
        readOnly: true,
        loadingState: true,
        placeholder: "Loading packages",
        fullWidth: false,
      };

      const index = prev.findIndex((inv: any) => inv.name === "package_type");
      prev.splice(index, 1, packageInput);
      return prev.slice();
    });

    //get the product details
    const [product] = productsFetched.filter(
      (product: any) => product.id === latestInput
    );

    let currentReorderLevel = 0;

    //fetch the inventory details
    const atcCode = product?.atc_code?.id;
    const brand = product?.brand_name;

    await apiCall(
      `api/v1/company/inventories/${atcCode}/${brand}`,
      "GET",
      {},
      (data) => {
        if (data.length > 0) {
          currentReorderLevel = data[data.length - 1].reorder_level;
        }
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );

    //await sleep(500);

    setActualInventoryForm((prev: any) => {
      //set the package type with correct options,
      const packageInput = {
        inputType: "selectInput",
        prompt: "Package type",
        options_name: "package_type",
        name: "package_type",
        placeholder: "Select the package type you wish to add",
        fullWidth: false,
        options: [
          { id: 1, title: product.primary_packaging + " (Primary Packaging)" },
          {
            id: 2,
            title: product.secondary_packaging + " (Secondary Packaging)",
          },
          {
            id: 3,
            title: product.tertiary_packaging + " (Tertiary Packaging)",
          },
        ],
      };

      const index = prev.findIndex((inv: any) => inv.name === "package_type");

      prev.splice(index, 1, packageInput);

      //set the reorder level and put the initial value of the reorder level
      //check if it already exist first and replace it
      const reorderIndex = prev.findIndex(
        (inv: KeyValueObj) => inv.name === "reorder_level"
      );

      const reorderInput = {
        inputType: "number",
        prompt: "Enter Reorder level",
        name: "reorder_level",
        placeholder: "Type the quantity that will trigger reorder",
        fullWidth: false,
        initialValue: currentReorderLevel,
        rules: {
          notNegative: {
            expectedValue: true,
            errorMessage: "Value must be non negative",
          },
        },
      };

      if (reorderIndex === -1) {
        prev.push(reorderInput);
      } else {
        prev.splice(reorderIndex, 1, reorderInput);
      }

      return prev.slice();
    });
  };

  function onFormContinue(formValues: KeyValueObj) {
    const formPreviewData: KeyValueObj[] = [];
    Object.keys(formValues).forEach((formKey: any) => {
      formPreviewData.push({
        name: formKey,
        value: formValues[formKey],
      });
    });

    //the drug name returns the id, so we need to replace that with name
    const product = formPreviewData.splice(0, 1)[0];
    const productName = options.find(
      (option) => option.id === product.value
    )?.title;
    formPreviewData.splice(0, 0, { name: "Product", value: productName });

    setFormValues(formValues);
    setPreviewData(formPreviewData);
    setModalTitle("Review your Inventory");
    setModalSubtitle("");
    setStage("inventory-preview");
  }

  function onPreviewBack() {
    setModalTitle("Add Inventory");
    setStage("inventory");
  }

  function buildFormData(formValues: KeyValueObj): FormData {
    const formData = new FormData();
    //add form values
    Object.keys(formValues).forEach((formKey: string) => {
      //remove the additional info on the packaging
      if (formKey === "package_type") {
        const value = formValues[formKey].split(" ")[0];
        formData.append(formKey, value);
      } else {
        formData.append(formKey, formValues[formKey]);
      }
    });
    return formData;
  }

  async function onPreviewSubmit() {
    setSubmitLoading(true);
    const formData = buildFormData(formValues);
    const data = await apiCall(
      INVENTORY_URL,
      "POST",
      formData,
      () => {
        setFormValues({});
        setStage("inventory");
        setShowModal(false);
      },
      () => {},
      () => {
        setSubmitLoading(false);
      }
    );
    data && setInventories([data, ...inventories]);
  }

  function resetEditStates() {
    setCurrentInventoryId("");
    setDisableActionButtons(false);
    setCurrentInventoryLoadingButton("");
  }

  function onActionButtonClicked(buttonName: string, row: KeyValueObj) {
    setCurrentInventoryLoadingButton(buttonName);
    setCurrentInventoryId(row?.id);
    setDisableActionButtons(true);

    switch (buttonName) {
      case "view":
        onViewInventory(row?.id);
        break;
      //reset states
      case "delete":
        apiCall(
          INVENTORY_URL + "/" + currentInventoryId,
          "DELETE",
          {},
          (data: KeyValueObj) => {
            //if succesful, remove the current id from list
            setShowActions(false);
            const filteredInventories = inventories.filter(
              (inv: KeyValueObj) => inv.id !== currentInventoryId
            );
            filteredInventories && setInventories(filteredInventories);
          },
          (error: KeyValueObj) => {
            console.log(error);
          },
          () => {
            resetEditStates();
          }
        );

        break;

      case "edit":
        onEditInventory(row?.id);
        break;

      default:
        break;
    }
  }

  function onEditInventory(id: string) {
    const inventory = inventories.find(
      (inventory: KeyValueObj) => inventory.id === id
    );
    let newInventoyForm: any = [];
    if (
      companyType?.toLowerCase() === "manufacturer" ||
      companyType?.toLowerCase() === "importer"
    ) {
      newInventoyForm = actualInventoryForm.map((formInput: KeyValueObj) => {
        if (formInput.name === "product_id") {
          return {
            ...formInput,
            inputType: "input",
            prompt: "Product",
            readOnly: true,
            initialValue:
              inventory?.product?.brand_name +
              " - " +
              inventory?.product?.strength +
              inventory?.product?.strength_unit,
          };
        } else if (formInput.name === "package_type") {
          return {
            ...formInput,
            initialValue: inventory?.package_type,
            options: [
              {
                id: 1,
                title:
                  inventory?.product.primary_packaging + " (Primary Packaging)",
              },
              {
                id: 2,
                title:
                  inventory?.product.secondary_packaging +
                  " (Secondary Packaging)",
              },
              {
                id: 3,
                title:
                  inventory?.product.tertiary_packaging +
                  " (Tertiary Packaging)",
              },
            ],
          };
        } else {
          return {
            ...formInput,
            initialValue: inventory && inventory[formInput.name],
          };
        }
      });
    } else {
      newInventoyForm = actualInventoryForm.map((formInput: KeyValueObj) => {
        if (formInput.name === "product_id") {
          return {
            ...formInput,
            inputType: "input",
            prompt: "Product",
            readOnly: true,
            initialValue:
              inventory?.product?.brand_name +
              " - " +
              inventory?.product?.strength +
              inventory?.product?.strength_unit,
          };
        } else if (formInput.name === "selling_price_per_unit") {
          return {
            ...formInput,
            initialValue: inventory && inventory[formInput.name],
          };
        } else {
          return {
            ...formInput,
            initialValue: inventory && inventory[formInput.name],
            readOnly: true,
            inputType: "input",
          };
        }
      });
    }

    setShowActions(false);
    setEditForm(newInventoyForm);
    setShowModal(true);
    setStage("inventory-edit");
  }

  function onEditInventoryContinue(formValues: KeyValueObj) {
    const formPreviewData: KeyValueObj[] = [];
    Object.keys(formValues).forEach((formKey: any) => {
      formPreviewData.push({
        name: formKey === "product_id" ? "Product" : formKey,
        value: formValues[formKey],
      });
    });

    setFormValues(formValues);
    setPreviewData(formPreviewData);
    setModalTitle("Review your Edited Inventory");
    setModalSubtitle("");
    setStage("inventory-edit-preview");
  }

  function onEditPreviewSubmit() {
    setSubmitLoading(true);
    const formData = buildFormData(formValues);
    apiCall(
      `${INVENTORY_URL}/${currentInventoryId}`,
      "PUT",
      formData,
      (data: KeyValueObj) => {
        setInventories((prev: KeyValueObj[]) => {
          const index = prev.findIndex(
            (inventory: KeyValueObj) => inventory.id === currentInventoryId
          );
          prev[index] = data;
          return prev.slice();
        });
        setShowModal(false);
        setFormValues({});
      },
      (error: KeyValueObj) => {},
      () => {
        setSubmitLoading(false);
        resetEditStates();
      }
    );
  }

  function onEditPreviewBack() {
    setModalTitle("Edit Inventory");
    setModalSubtitle("");
    setStage("inventory-edit");
  }

  function onCloseModal() {
    setShowModal(false);
    resetEditStates();
  }

  function onViewInventory(id: string) {
    const previewDataInner: KeyValueObj[] = [];
    const inventory = inventories.find(
      (inventoryInner: KeyValueObj) => inventoryInner.id === id
    );

    const product = inventory?.product;

    //preparing product data for preview;
    const productData: KeyValueObj[] = [];
    Object.keys(product).forEach((productItemKey: string) => {
      //we need to go into the images and push them individually
      if (productItemKey === "images") {
        product[productItemKey]?.forEach(
          (imageItem: KeyValueObj, index: number) => {
            productData.push({
              name: "image_" + index,
              value: imageItem?.url,
              type: "imageUrl",
            });
          }
        );
      }
      //we also need to not include the ids in the list
      else if (
        productItemKey !== "id" &&
        productItemKey !== "manufacturer_id" &&
        productItemKey !== "atc_code_id" &&
        productItemKey !== "name"
      ) {
        productData.push({
          name: productItemKey,
          value: product[productItemKey],
        });
      }
    });

    //Preparing inventory data for view;

    const inventoryData: KeyValueObj[] = [];
    inventory &&
      Object.keys(inventory).forEach((invKey: string) => {
        if (
          invKey !== "product" &&
          invKey !== "id" &&
          invKey !== "owner" &&
          invKey !== "supplier"
        ) {
          inventoryData.push({
            name: invKey,
            value: inventory[invKey],
          });
        }
      });

    previewDataInner.push({
      type: "category",
      name: "Product Details",
      value: productData,
    });
    previewDataInner.push({
      type: "category",
      name: "Inventory Details",
      value: inventoryData,
    });

    setPreviewData(previewDataInner);
    setShowModal(true);
    setStage("inventory-view");
    setShowActions(false);
    setModalTitle("Inventory Details");
    resetEditStates();
  }

  return (
    <AddInventoryWrapper>
      <PageTitleNavs
        title="Inventories"
        backLink="#"
        bcrumbs={[{ title: "Inventories", link: "#" }]}
      />
      <Modal
        title={modalTitle}
        subTitle={modalSubtitle}
        isOpen={showModal}
        close={onCloseModal}
      >
        <ModalWrapper
          short={
            stage === "inventory-preview" ||
            stage === "inventory-view" ||
            stage === "inventory-edit-preview"
          }
        >
          {/* Inventory add form */}
          <ModalFormWrapper show={stage === "inventory"}>
            <DataWrapper
              noDataMessage="No product added yet, Products added will show here."
              loading={productsLoading}
              error={productsError}
              onFailAction={() => setRetry((prev) => prev + 1)}
              loadingMessage="Loading products data..."
              failedMessage="Could not fetch products data. Check your network and click the button below to retry"
            >
              <Form
                loadingState={false}
                submitLabel={"Continue"}
                formInputs={actualInventoryForm}
                processInputs={onFormContinue}
                spinnerComponent={"spinner"}
                login={false}
                biControl={false}
                handleBack={undefined}
              />
            </DataWrapper>
          </ModalFormWrapper>

          <ModalPreviewWrapper show={stage === "inventory-preview"}>
            <Preview
              data={previewData}
              biControl
              onBack={onPreviewBack}
              loading={submitLoading}
              onSubmit={onPreviewSubmit}
            />
          </ModalPreviewWrapper>

          {/* Inventory Edit form */}
          <ModalFormWrapper show={stage === "inventory-edit"}>
            <Form
              loadingState={false}
              submitLabel={"Continue"}
              formInputs={editForm}
              processInputs={onEditInventoryContinue}
              spinnerComponent={"spinner"}
              login={false}
              biControl={false}
              handleBack={undefined}
            />
          </ModalFormWrapper>

          <ModalPreviewWrapper show={stage === "inventory-edit-preview"}>
            <Preview
              data={previewData}
              biControl
              onBack={onEditPreviewBack}
              loading={submitLoading}
              onSubmit={onEditPreviewSubmit}
            />
          </ModalPreviewWrapper>

          <ModalPreviewWrapper show={stage === "inventory-view"}>
            <Preview
              data={previewData}
              submitLabel={"Close"}
              onSubmit={() => setShowModal(false)}
            />
          </ModalPreviewWrapper>
        </ModalWrapper>
      </Modal>

      <div className="stats-wrapper">
        <div className="cards-wrapper">
          {getCardDetails({})?.map((card: ICardDetails, idx: number) => (
            <StatCard
              key={idx}
              title={card.title}
              value={card.value}
              imageUrl={card.imageUrl}
              bg={card.bg}
            />
          ))}
        </div>

        <div className="mid-stats">
          <div className="bar">
            <h2 className="sub-title">Sales</h2>
            <Barchart
              chartData={getBarchartData(barUserData)}
              options={barOptions}
            />
          </div>
          <div className="short-table">
            <h2 className="sub-title">Recent Orders</h2>
            <Table
              tableHeaders={tableHeadersShort}
              tableData={tableDataShort}
              actionsData={[]}
              tableKeys={tableKeysShort}
              full={false}
              rowLineColor="light"
            />
          </div>
        </div>
      </div>

      <div className="inventories">
        <div className="controls">
          {(companyType?.toLowerCase() === "manufacturer" ||
            companyType?.toLowerCase() === "importer") && (
            <Button onClick={onAddEventory}>+ Add Inventory</Button>
          )}
        </div>
        <div className="table-wrapper">
          <Table
            tableHeaders={tableHeaders}
            tableData={tableData}
            actionsData={[]}
            tableKeys={tableKeys}
            full
            spaced
            loading={inventoriesLoading}
            errorLoading={inventoriesError?.message?.length > 0}
            onRetry={() => setRetry((prev) => prev + 1)}
            showDropDown
            dropDownOptions={[
              {
                label: "View",
                action: (row: KeyValueObj) =>
                  onActionButtonClicked("view", row),
              },
              {
                label: "Edit",
                action: (row: KeyValueObj) =>
                  onActionButtonClicked("edit", row),
              },
              {
                label: "Delete",
                action: (row: KeyValueObj) =>
                  onActionButtonClicked("delete", row),
              },
            ]}
          />
        </div>
      </div>
    </AddInventoryWrapper>
  );
}

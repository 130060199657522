import styled from "styled-components";
import dispImg from "src/assets/images/error-loading.png";
import { H4 } from "src/common/elements";
import { Button } from "../button";

export const RowWRapper = styled.div`
  width: 100%;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
  }
`;

export interface IEmptyTableProps {
  onRetry: () => void;
  failedMessage?: string;
}

export function ErrorLoadingData({ onRetry, failedMessage }: IEmptyTableProps) {
  return (
    <RowWRapper>
      <div className="wrapper">
        <img src={dispImg} alt="Empty cart" />
        <H4>
          {failedMessage ||
            "Unable to load data, click the button below to try again."}
        </H4>
        <Button onClick={onRetry}>Retry</Button>
      </div>
    </RowWRapper>
  );
}

import styled from "styled-components";
import { ClipSpinner } from "../../../../../common/components/spinner";
import { BsTrash } from "react-icons/bs";

const ExistingImageWrapper = styled.div`
  width: 15rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  overflow: hidden;

  img {
    height: 10rem;
  }

  .delete-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.text.primary};
    :hover {
      color: red;
    }
  }
`;

export interface IExistingImageProps {
  imageUrl: string;
  deleteImage: (imageUrl: string) => void;
  currentImageUrl: string;
  imageId: string;
  canDelete?: boolean;
}

export function ExistingImage({
  imageUrl,
  deleteImage,
  currentImageUrl,
  imageId,
  canDelete,
}: IExistingImageProps) {
  return (
    <ExistingImageWrapper>
      <img src={imageUrl} alt="Product" />
      <>
        {canDelete ? (
          <>
            {currentImageUrl === imageId ? (
              <ClipSpinner />
            ) : (
              <BsTrash
                className="delete-icon"
                onClick={() => deleteImage(imageId)}
              />
            )}
          </>
        ) : null}
      </>
    </ExistingImageWrapper>
  );
}

import React, { Dispatch, SetStateAction, useState } from "react";
// import { FormField, Input, Label, Select } from "../styles";

import { Input, Label, RadioInput } from "../styles";
import { Fileupload } from "../styles";
import { FormControl } from "@mui/material";
import { FaFileAlt } from "react-icons/fa";
import { useApiCallHandling } from "src/hooks/useApiCallHandling";

// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import dayjs, { Dayjs } from "dayjs";
import { DateSelect } from "../styles";

interface Data {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => any;
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
}

function PcnIDVerification({
  onChange,
  selectedOption,
  setSelectedOption,
}: Data) {
  const [, setLoading] = useState(false);

  const [license] = useState("");
  const [issueDate] = useState("");
  const [expiryDate] = useState("");
  const [file] = useState<File | undefined>();

  function onFinally() {
    setLoading(false);
  }

  function onSuccess(data: any) {
    // setData(data);
    // setIsSuccess(true);
    console.log(data);
  }

  function onFail() {}

  const MakeReq = useApiCallHandling();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (license) formData.append("current_pcn_licence_number", license);
    if (issueDate) formData.append("current_pcn_licence_iss_date", issueDate);
    if (expiryDate) formData.append("current_pcn_licence_exp_date", expiryDate);
    if (file) formData.append("pcn_licence_certificate", file);

    // console.log(file);
    MakeReq(
      "/api/v1/companies",
      "post",
      formData,
      onSuccess,
      onFail,
      onFinally
    );
  };

  return (
    <div className="pcn-container">
      <div className="input_container">
        <div className="heading">MEDEX Onboarding</div>
        <div className="sub-heading">
          <div className="stepcounter">Step 4 of 4</div>
          <div className="stepcountTitle">PCN Licence</div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          justifyItems: "start",
        }}
      >
        <FormControl>
          <div style={{ marginTop: "10px" }}>
            Do you have a PCN Licence Number?
          </div>

          <RadioInput>
            <div className="radio">
              <input
                type="radio"
                value="yes"
                checked={selectedOption === "yes"}
                onChange={(event) => setSelectedOption(event.target.value)}
              />
              <label>Yes</label>
            </div>

            <div className="radio">
              <input
                type="radio"
                value="no"
                checked={selectedOption === "no"}
                onChange={(event) => setSelectedOption(event.target.value)}
              />
              <label>No</label>
            </div>
          </RadioInput>

          {selectedOption === "yes" && (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div style={{ marginTop: "30px" }}>
                <Label style={{ fontSize: "0.95rem" }} htmlFor="name">
                  Enter PCN Licence Number
                </Label>
                <Input
                  id="name"
                  name="current_pcn_licence_number"
                  type="text"
                  onChange={onChange}
                  required
                />

                <DateSelect>
                  {/* <StyledDateInput> */}
                  <Label
                    style={{ fontSize: "0.95rem", marginTop: "1rem" }}
                    htmlFor="current_pcn_licence_iss_date"
                  >
                    License Issue Date
                  </Label>
                  <input
                    className="input"
                    type="date"
                    name="current_pcn_licence_iss_date"
                    onChange={onChange}
                  />

                  <Label
                    style={{ fontSize: "0.95rem", marginTop: "1rem" }}
                    htmlFor="name"
                  >
                    PCN Expiry Number
                  </Label>
                  <input
                    className="input"
                    type="date"
                    name="current_pcn_licence_exp_date"
                    onChange={onChange}
                  />
                  {/* </StyledDateInput> */}
                </DateSelect>
                <div
                  className="btnContainer"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Fileupload>
                    <div className="fileupload">
                      <div>
                        <FaFileAlt /> Licence Upload
                      </div>
                      Upload file
                      <input
                        type="file"
                        name="pcn_licence_certificate"
                        onChange={onChange}
                      />
                    </div>
                  </Fileupload>
                </div>
              </div>
            </form>
          )}
        </FormControl>
      </div>
    </div>
  );
}

export default PcnIDVerification;

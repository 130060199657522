import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  min-height: 25rem;
  background-color: ${({ theme }) => theme.bg.secondary};
  padding: 6.7rem 5vw;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 6.6875rem 2rem;
  }

  .top {
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 3rem;

    .segment {
      width: 16rem;

      h3 {
        color: ${({ theme }) => theme.text.prim_500};
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 1.5rem;
        margin-bottom: 1.875rem;
      }

      .icons {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  .lower {
    justify-content: space-between;

    h2 {
      font-weight: 400;
      font-size: 0.85rem;
      line-height: 1.5rem;
      color: ${({ theme }) => theme.text.prim_500};
    }
  }
`;

export const IconWrappr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.bg.primary + "1A"};
  color: ${({ theme }) => theme.text.inverted};
  margin-right: 1rem;

  .icon {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.text.prim_500};
  }
`;

import styled from "styled-components";
import { ReactNode, forwardRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.2rem;
  max-width: 100%;
  overflow: auto;

  .tab-buttons {
    top: ${({ theme }) => theme.navHeight};
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border-bottom: 1px solid ${({ theme }) => theme.bg.secondary}; */
    border-bottom: 1px solid ${({ theme }) => theme.bg.prim_500};
    /* border-radius: 0.5rem; */

    button {
      padding: 0.85rem 1rem;
      outline: none;
      border: none;
      font-size: 0.9rem;
      min-width: 6rem;
      /* background-color: ${({ theme }) => theme.bg.prim_500}; */
      background-color: ${({ theme }) => theme.bg.secondary};
      /* color: ${({ theme }) => theme.bg.secondary}; */
      color: ${({ theme }) => theme.bg.prim_500};
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;
      transition: 0.3s all ease-in-out;

      :hover {
        background-color: ${({ theme }) => theme.bg.prim_300};
        background-color: ${({ theme }) => theme.bg.sec_100};
      }

      :last-child {
        border-radius: 0 0.5rem 0 0;
      }
      :first-child {
        border-radius: 0.5rem 0 0 0;
      }
    }

    button.active {
      color: ${({ theme }) => theme.text.prim_500};
      color: ${({ theme }) => theme.text.secondary};
      background-color: ${({ theme }) => theme.bg.secondary};
      background-color: ${({ theme }) => theme.bg.prim_500};
    }
  }

  .ui {
    width: 100%;
    background-color: ${({ theme }) => theme.bg.prim_500};
  }
`;

interface TabItem {
  title: string;
  component: ReactNode;
}

interface TabProps {
  onTabClick: (title: string) => void;
  currentTab: string;
  tabs: TabItem[];
}

const Tab2 = forwardRef(({ onTabClick, currentTab, tabs }: TabProps) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const currentTabParam = searchParams.get("currentTab") || currentTab;

  const CurrentUI = tabs.find(
    (tab: TabItem) => tab.title === currentTabParam
  )?.component;

  useEffect(() => {
    setSearchParam({ currentTab });
  }, [currentTab, setSearchParam]);

  return (
    <TabContainer>
      <div className="tab-buttons">
        {tabs?.map((tab: TabItem, i: number) => (
          <button
            key={i}
            onClick={() => {
              onTabClick(tab.title);
            }}
            className={tab.title === currentTabParam ? "active" : ""}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="ui">{CurrentUI}</div>
    </TabContainer>
  );
});
export default Tab2;

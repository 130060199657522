import styled from "styled-components";

const highColor = "#7661E2";
const lowColor = "#C52419";
const mediumColor = "#59E6F6";

function getColor(percent: number): string {
  if (percent > 69) return highColor;
  if (percent > 39) return mediumColor;
  return  lowColor;
}

export interface ILineChartProps {}

const ChartWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bg.prim_500};
  box-shadow: ${({ theme }) => theme.shadow.shad_100};
  padding: 3rem 1.2rem 1.5rem;

  .chart-sub {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 150%;
  }

  .lower-sub {
    margin-top: 1rem;
  }

  .lower-sec {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .product-text {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.text.prim_100};
      font-weight: 400;
      width: 6rem;
    }

    .lines {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 90%;
    }

    .line-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

   

    .quantity {
      flex: 1;
      height: 1.5rem;
      display: flex;
      justify-content: space-between;
      gap: .35rem;


      p{
        font-size: .75rem;
        color: ${({theme}) => theme.text.prim_100};
        font-weight: 500;
      }
    }

    .legend{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: .5rem;
      padding-left: 1.5rem;
      max-width: 15%;

     

      .legend-item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: .2rem;
        
      }

      .box{
        width: 1rem;
        height: 1rem;
        border-radius: .1rem;
      }

      .medium {
       background-color: ${mediumColor}
      }

      .low {
        background-color: ${lowColor}
      }

      .high {
        background-color: ${highColor}
      }
    }
  }
`;

interface IStrengthLine {
  percent: number;
}
const StrengthLine = styled.div<IStrengthLine>`
  background-color: green;
  height: 100%;
  width: ${({ percent }) => percent + "%"};
  background-color: ${({ percent }) => getColor(percent)};
`;

interface IInventory {
  name: string;
  quantity: number;
}
const inventories: IInventory[] = [
  {
    name: "Paracetamol",
    quantity: 95,
  },
  {
    name: "Coartem",
    quantity: 55,
  },
  {
    name: "Oraflur",
    quantity: 30,
  },
  {
    name: "Monacylin",
    quantity: 60,
  },
];

const scale = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export function LineChart(props: ILineChartProps) {
  return (
    <ChartWrapper>
      <h3 className="chart-sub">Total Inventory Value: N800,000</h3>
      <h3 className="chart-sub lower-sub">Stock Levels</h3>

      <section className="lower-sec">
        <div className="lines">
          {inventories.map((inventory: IInventory, idx: number) => (
            <div key={idx} className="line-wrapper">
              <h3 className="product-text ">{inventory.name}</h3>
              <div className="quantity">
                <StrengthLine percent={inventory.quantity} />
              </div>
            </div>
          ))}

          <div className="line-wrapper">
            {/* <h3 className="product-text"></h3> */}
            <div className="quantity">
              {scale.map((sc: number, idx: number) => (
                <p key={idx}>{sc}</p>
              ))}
            </div>
          </div>
        </div>
        <div className="legend">
          <div className="legend-item">
            <p className="product-text">Low</p>
            <div className="box low"></div>
          </div>
          <div className="legend-item">
            <p className="product-text">Medium</p>
            <div className="box medium"></div>
          </div>
          <div className="legend-item">
            <p className="product-text">High</p>
            <div className="box high"></div>
          </div>
        </div>
      </section>
    </ChartWrapper>
  );
}

import styled from "styled-components";
import { GenericInputWrapper } from "./input";
import { BiCalendar } from "react-icons/bi";
import { KeyValueObj } from "src/common/types";
import { SetStateAction } from "react";

const DateInpuWrapper = styled(GenericInputWrapper)`
  .date-input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 0rem;
    cursor: pointer;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.prim_100};
    }

    .calendar-icon {
      font-size: 1.3rem;
      color: ${({ theme }) => theme.text.prim_100};
    }
  }
`;

export interface IDateInputProps {
  name: string;
  setFormValues: SetStateAction<any>;
  label: string;
  formValues: KeyValueObj;
  indexLevel: number;
}

export function DateInput({
  name,
  setFormValues,
  label,
  formValues,
  indexLevel,
}: IDateInputProps) {
  return (
    <DateInpuWrapper indexLevel={indexLevel}>
      <label htmlFor={`date_${name}`}>
        <>{formValues[name]}</>
        <div className={`input-label ${formValues[name] ? "move-up" : ""}`}>
          <BiCalendar className="calendar-icon icon" />
          <h3 className="text">{label}</h3>
        </div>
      </label>

      <input
        onChange={(e: any) =>
          setFormValues((prev: any) => ({ ...prev, [name]: e.target.value }))
        }
        className="date-input"
        type="date"
        id={`date_${name}`}
      />
    </DateInpuWrapper>
  );
}

import styled from "styled-components";

interface WrapperProps {
  bg: string;
  loss?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 6.5rem;
  background-color: ${({ theme, bg }) => theme.dashboard[bg]};
  border-radius: 0.25rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    img {
      width: 2rem;
   
    }

    h5 {
      font-size: .9rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.prim_500};
      width: 70%;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 600;
      font-size: 1.4rem;
      color: ${({ theme }) => theme.text.prim_500};
    }

    .values {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.3rem;

      h5 {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme, loss }) => (loss ? "red" : theme.text.muted_200)};
      }

      h6 {
        font-size: 0.7rem;
        font-weight: 500;
        color: ${({ theme }) => theme.text.prim_500};
      }
    }
  }
`;

export interface IStatCardProps {
  bg: string;
  title: string;
  value: string;
  imageUrl: string;
}
export function StatCard({ bg, imageUrl, title, value }: IStatCardProps) {
  return (
    <Wrapper bg={bg} >
      <div className="top">
        <h5>{title}</h5>
        <img src={imageUrl} alt="card i" />
      </div>
      <div className="bottom">
        <h2>{value}</h2>
     
      </div>
    </Wrapper>
  );
}

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, MouseEvent, useState } from "react";
import useAxiosCall from "../../../../hooks/useAxiosCall";
import { COMPANIES } from "../../../../common/constants";
import styled from "styled-components";
import { KeyValueObj } from "../../../../common/types";
import { DataWrapper } from "../../../../common/components/dataWrapper";
import Table from "../../../../common/components/Table";
import { tableKeys, tableHeaders } from "./subs/data";
import { Pagination } from "src/common/components/pagination";
import { PageTitleNavs } from "src/common/components/page-title-navs";

const ComapanyWrapper = styled.div`
  width: 100%;
  padding: 2rem;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem 10rem;
  }
`;

export interface ICompaniesProps {}

export function Companies(props: ICompaniesProps) {
  const [tableData, setTableData] = useState<KeyValueObj[]>([]);
  const [retry, setRetry] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [pageType, setPageType] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const [
    fetchComapanies,
    fetchedCompanies,
    companyError,
    companyLoading,
    companyMeta,
  ] = useAxiosCall();

  useEffect(() => {
    const locationArr = location.pathname.split("/");
    const status = locationArr[locationArr.length - 1];
    setPageType(status);

    fetchComapanies({
      url:
        COMPANIES + "?status=" + status + `&per_page=${perPage}&page=${page}`,
      method: "GET",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, retry, page, perPage]);

  useEffect(() => {
    const companyTableData = fetchedCompanies.map((company: KeyValueObj) => ({
      id: company.id,
      name: {
        type: "string",
        value: company.name,
      },
      tin: {
        type: "string",
        value: company.tin,
      },
      category: {
        type: "string",
        value: company.classification,
      },
      email: {
        type: "string",
        value: company.email,
      },
      action: {
        type: "button",
        buttonType: "light-green",
        buttonName: "View",
        onClick: onViewMore,
      },
    }));
    setTableData(companyTableData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCompanies]);

  function onViewMore(e: MouseEvent<HTMLElement>, id: string) {
    navigate("/dashboard/admin/companies/details/" + id + "/" + pageType);
  }

  return (
    <ComapanyWrapper>
      <PageTitleNavs
        title={pageType + " Companies"}
        backLink="#"
        bcrumbs={[{ title: "Companies", link: "#" }]}
      />
      <DataWrapper
        data={tableData}
        noDataMessage={"There is no company in this category at the moment"}
        loading={companyLoading}
        error={companyError}
        loadingMessage="Loading comapany data..."
        failedMessage="Could not fetch comapanies data. Check your network and click the button below to retry"
        onFailAction={() => {
          setRetry((prev) => prev + 1);
        }}
      >
        <div className="table-wrapper">
          <Table
            tableData={tableData}
            tableHeaders={tableHeaders}
            tableKeys={tableKeys}
            full
            spaced
            rowLineColor="light"
            tableTitle="Companies"
            tableSubtitle={"Total companies: " + companyMeta?.total_count }
          />
        </div>
      </DataWrapper>
      <Pagination
        pageCount={companyMeta?.total_pages}
        onNextPage={({ selected }) => setPage(selected)}
        onPerPageChange={(event: any) => setPerPage(event?.target?.value)}
      />
    </ComapanyWrapper>
  );
}

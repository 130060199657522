import Table from "src/common/components/Table";
import { H3 } from "src/common/elements";
import styled from "styled-components";
import {
  addedProductHeaders,
  addedProductKeys,
  getAddedProductTableBody,
  getLogisticPreviewDetails,
  getOtherDetails,
  getPickupPreviewDetails,
  getRecipentPreviewDetails,
} from "../data";
import { KeyValueObj } from "src/common/types";
import { KeyValue } from "src/common/components/key-value";
import { Button } from "src/common/components/button";

const DeliveryWrapper = styled.div`
  width: 65rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.2rem;
  }

  .double-column-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .separate {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.bg.prim_400};
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .control {
    padding: 2rem;
  }
`;

export interface IDeliveryRequestPreviewProps {
  payload: KeyValueObj;
  recipentCompany: KeyValueObj;
  recipentAddress: KeyValueObj;
  logisticCompany: KeyValueObj;
  senderAddressType: string;
  pickupAddress: KeyValueObj;
  handleSubmitDeliveryRequest: () => void;
  loading: boolean;
  otherDetails: KeyValueObj;
}

export function DeliveryRequestPreview({
  payload,
  recipentAddress,
  recipentCompany,
  logisticCompany,
  senderAddressType,
  pickupAddress,
  handleSubmitDeliveryRequest,
  loading,
  otherDetails,
}: IDeliveryRequestPreviewProps) {
  const { pickup_option, delivery_items_details } = payload;
  return (
    <DeliveryWrapper>
      <H3>Product Details</H3>
      <Table
        tableHeaders={addedProductHeaders}
        tableData={getAddedProductTableBody(delivery_items_details)}
        tableKeys={addedProductKeys}
        rowLineColor="light"
        removeFilters
      />

      <div className="separate"></div>

      <H3>Recipent Company Details</H3>
      <div className="double-column-wrapper">
        {getRecipentPreviewDetails(recipentCompany, recipentAddress)?.map(
          (detail: KeyValueObj, i: number) => (
            <KeyValue key={i} title={detail?.title} value={detail?.value} />
          )
        )}
      </div>

      <div className="separate"></div>

      <H3>Logistic Company Details</H3>
      <div className="double-column-wrapper">
        {getLogisticPreviewDetails(
          logisticCompany,
          delivery_items_details
        )?.map((detail: KeyValueObj, i: number) => (
          <KeyValue key={i} title={detail?.title} value={detail?.value} />
        ))}
      </div>

      <div className="separate"></div>

      <H3>Pick-up Details</H3>
      <div className="double-column-wrapper">
        {getPickupPreviewDetails(
          pickup_option,
          senderAddressType,
          pickupAddress
        )?.map((detail: KeyValueObj, i: number)=> (
          <KeyValue  key={i} title={detail?.title} value={detail?.value} />
        ))}
      </div>

      <div className="separate"></div>

      <H3>Other Details</H3>
      <div className="double-column-wrapper">
        {getOtherDetails(otherDetails)?.map((detail: KeyValueObj, i: number) => (
          <KeyValue key={i} title={detail?.title} value={detail?.value} />
        ))}
      </div>

      <div className="control flex-col">
        <Button loading={loading} onClick={handleSubmitDeliveryRequest}>
          Submit Delivery Request
        </Button>
      </div>
    </DeliveryWrapper>
  );
}

import Form from "../../common/components/form/Form";
import { RegisterWrapper } from "./style";
import googleLogo from "../../assets/images/google.png";
import { formInputs, registerRefferedInput } from "./data";
import { KeyValueObj, User } from "../../common/types";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { ClipSpinner } from "../../common/components/spinner";
import { useNotification } from "../../common/components/Notifications/NotificationProvider";
import { useApiCallHandling } from "../../hooks/useApiCallHandling";
import { COMPANY_TYPES_URL, REGISTER_USER_URL } from "../../common/constants";

import dotsImage from "../../assets/images/dots.png";
import { Modal } from "../../common/components/modal";
import { SuccessModal } from "./success-modal";
import { Button } from "src/common/components/button";
import useQuery from "src/hooks/useQuery";

export interface ILoginProps {}

export function Register(props: ILoginProps) {
  const [loading, setLoading] = useState(false);
  const [, setFetching] = useState(false);
  const [actualForm, setActualForm] = useState<any>(formInputs);
  const [, setRoles] = useState<KeyValueObj[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [paramData, setParamData] = useState<KeyValueObj>({});
  const [, setLoadingRolesFailed] = useState(false);

  const notify = useNotification();
  const apiCall = useApiCallHandling();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const {
    loading: companyTypesLoading,
    data: companyTypes,
    error: companyTypesError,
  } = useQuery(COMPANY_TYPES_URL);

  useEffect(() => {
    setFetching(true);
    setActualForm(formInputs);
    setRoles(companyTypes);
    if (!companyTypesLoading) {
      const companyType = {
        inputType: "selectInput",
        returnId: true,
        prompt: "Company type",
        name: "company_type",
        placeholder: "Select Company Type",
        fullWidth: true,
        options: companyTypes,
      };

      setActualForm((prev: any) => {
        const copy = [...prev];
        copy[0] = companyType;
        return copy;
      });
    }

    if (!companyTypesLoading && companyTypesError) {
      notify({
        type: "ERROR",
        message: "Unable to load roles, refresh the page to try again.",
      });
      setLoadingRolesFailed(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyTypes, companyTypesError, companyTypesLoading]);

  useEffect(() => {
    const paramEmail = params.get("email");
    const paramPhone = params.get("phone_number");
    const paramReferer = params.get("invited_by");
    setParamData({
      paramEmail,
      paramPhone,
      paramReferer,
    });

    if (paramReferer) {
      const newForm = registerRefferedInput.map((formItem: KeyValueObj) => {
        if (formItem.name === "phone" && paramPhone) {
          return {
            ...formItem,
            initialValue: paramPhone,
          };
        } else {
          return formItem;
        }
      });

      setActualForm(newForm);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  async function processFormInputs(values: User) {
    let { email, company_type, phone, password, repeatPassword } = values;
    let referer = "";

    if (paramData.paramReferer) {
      email = paramData.paramEmail;
      referer = paramData.paramReferer;
      // role = fetchedRole.id;
    }

    setLoading(true);
    apiCall(
      REGISTER_USER_URL,
      "POST",
      {
        email,
        password,
        password_confirmation: repeatPassword,
        phone_number: phone,
        company_type_id: company_type,
        invited_by: referer,
      },
      (_data: any) => {
        setShowSuccessModal(true);
      },
      (_err: any) => {},
      () => {
        setLoading(false);
      },
      { dontNotifyOnSucess: true }
    );
  }

  const spinnerComponent = (
    <Button disabled className="submit">
      Loading Roles <ClipSpinner />
    </Button>
  );

  const handleProceedClick = useCallback(() => {
    setShowSuccessModal(false);
    navigate(`/login`);
  }, [navigate]);

  return (
    <>
      <Modal isOpen={showSuccessModal}>
        <SuccessModal onProceedClick={handleProceedClick} />
      </Modal>
      <RegisterWrapper>
        <section className="container">
          <section className="info-sec">
            <div className="text-wrapper">
              <h2>Getting started is easy.</h2>
              <p>
                MedEX is a service that simplifies and automates pharmaceutical
                and medical supplies to hospitals, pharmacies, and other
                healthcare providers as well as process prescriptions and
                dispatch medicines to patients
              </p>
            </div>
            <img className="dots-image" src={dotsImage} alt="" />
          </section>
          <section className="input-sec">
            <div className="top flex-row">
              <div className="welcome-message">
                <h2 className="sign-up">Sign Up</h2>
                <p className="other-route">
                  Already have account?{" "}
                  <span>
                    <Link className="route-link" to="/login">
                      Log in
                    </Link>
                  </span>
                </p>
              </div>
            </div>
            <div className="form">
              <Form
                formInputs={actualForm}
                loadingState={loading}
                submitLabel="Sign Up"
                processInputs={processFormInputs}
                spinnerComponent={spinnerComponent}
                login={false}
                biControl={false}
                handleBack={undefined}
              />
            </div>
            {paramData.paramReferer ? null : (
              <div className="controls">
                <div className="or">
                  <h3>or</h3>
                </div>
                <button>
                  <img src={googleLogo} alt="google logo" />
                  Continue with Google
                </button>
              </div>
            )}
          </section>
        </section>
      </RegisterWrapper>
    </>
  );
}

import styled from "styled-components";

export const DeliveryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .top-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

import styled from "styled-components";
import Form from "../../common/components/form/Form";
import { ClipSpinner } from "../../common/components/spinner";
import { PageContainr } from "../../common/elements";
import { KeyValueObj } from "../../common/types";

import {registerRefferedInput} from './data'

const RefferedWrapper = styled(PageContainr)`
  width: 100%;

  .top {
    width: 100%;
    margin-top: 2rem;

    .logo-wrapper {
      width: 60px;
    }

    .welcome-message {
      margin-left: 1.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .sign-up {
        font-weight: 500;
        font-size: 3rem;
        line-height: 3.875rem;
        letter-spacing: 0.0009rem;
        font-family: "DM Sans";
        color: ${({ theme }) => theme.button.primary};

        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          font-size: 2.2rem;
        }
      }

      .welcome {
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 2.75rem;
        letter-spacing: 0.0009rem;
        @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
          font-size: 2rem;
        }
      }
    }
  }

  .form {
    width: 100%;
    max-width: 76rem;
    margin-bottom: 2.25rem;
    margin-top: 2rem;
  }
`;

export interface IRegisterRefferedProps {}

export function RegisterReffered(props: IRegisterRefferedProps) {

  function processFormInputs(formData:KeyValueObj){
  
  }

  return (
    <RefferedWrapper>
      <div className="top flex-row">
        <div className="welcome-message">
          <h2 className="sign-up">Sign Up</h2>
          <h3 className="welcome">Welcome!</h3>
        </div>
      </div>
      <div className="form">
        <Form
          formInputs={registerRefferedInput}
          loadingState={false}
          submitLabel="Register"
          processInputs={processFormInputs}
          spinnerComponent={<ClipSpinner />}
          login={false}
          biControl={false}
          handleBack={undefined}
        />
      </div>
    </RefferedWrapper>
  );
}

import { SetStateAction, useRef, useState } from "react";
import { KeyValueObj } from "src/common/types";
import styled from "styled-components";

interface IInputProp {
  indexLevel: number;
}

export const GenericInputWrapper = styled.div<IInputProp>`
  position: relative;
  width: 14rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  box-shadow: ${({ theme }) => theme.shadow.shad_100};
  z-index: ${({ indexLevel }) => indexLevel};
`;

const FilterInputWrapper = styled(GenericInputWrapper)`
  input {
    width: 100%;
    position: absolute;
    height: 100%;
    padding: 0.4rem 0.7rem;
    border: none;
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.text.prim_100};

    :focus {
      outline: none;
      box-shadow: ${({ theme }) => theme.shadow.drag_over};
    }
  }
`;

export interface IFilterInputProps {
  name: string;
  setFormValues: SetStateAction<any>;
  label: string;
  formValues: KeyValueObj;
  indexLevel: number;
}

export function FilterInput({
  indexLevel,
  name,
  setFormValues,
  label,
  formValues,
}: IFilterInputProps) {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<any>(null);

  function handleLabelClick() {
    inputRef?.current?.focus();
  }

  function handleInputChange(e: any) {
    setFormValues((prev: any) => ({ ...prev, [name]: e.target.value }));
  }

  return (
    <FilterInputWrapper indexLevel={indexLevel}>
      <input
        ref={inputRef}
        value={formValues?.[name] ?? ""}
        onClick={handleLabelClick}
        onChange={handleInputChange}
        className={`filter-input`}
        type="text"
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
      />
      <h3
        className={`input-label ${
          focused || formValues?.[name] ? "move-up" : ""
        }`}
        onClick={handleLabelClick}
      >
        {label}
      </h3>
    </FilterInputWrapper>
  );
}

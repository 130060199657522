import { useState } from "react";
import { SettingsWrapper } from "./subs/styles";
import { PageTitleNavs } from "src/common/components/page-title-navs";
import Tab2 from "src/common/components/Tab/Tab2";
import { SettingsGeneral } from "./subs/General";
import { ApiKey } from "./subs/ApiKey";
import useQuery from "src/hooks/useQuery";

export interface ISettingsProps {}

export function Settings(props: ISettingsProps) {
  const [currentTab, setCurrentTab] = useState("General");

  const { data: settingsData, loading: settingsLoading } = useQuery(
    "/api/v1/company/settings-api-key"
  );

  const tabs = [
    {
      title: "General",
      component: (
        <SettingsGeneral
          loading={settingsLoading}
          existingNotificationUrl={
            settingsData?.settings?.goods_received_notification_url || ""
          }
        />
      ),
    },
    {
      title: "API Key",
      component: (
        <ApiKey
          loading={settingsLoading}
          existingApiKey={settingsData?.api_key || ""}
        />
      ),
    },
  ];

  return (
    <SettingsWrapper>
      <PageTitleNavs
        title="Settings"
        backLink=""
        bcrumbs={[{ title: "Settings", link: "#" }]}
      />
      <Tab2
        tabs={tabs}
        onTabClick={(title: string) => setCurrentTab(title)}
        currentTab={currentTab}
      />
    </SettingsWrapper>
  );
}

import styled from "styled-components";

interface ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  bg?: string;
  color?: string;
  disabledBg?: string;
  borderColor?: string;
  width?: string;
  small?: boolean;
}

export const Button = styled.button<ButtonProps>`
  min-width: ${({ width }) => (width ? width : "8rem")};
  height: 2.5rem;
  padding: 0 .75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  gap: 0.5rem;
  outline: none;
  border: none;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  color: ${({ color, secondary, theme }) =>
    color ? theme.text[color] : secondary ? theme.bg.secondary : "white"};
  border: ${({ secondary, theme, borderColor }) =>
    borderColor
      ? "1px solid " + theme.border[borderColor]
      : secondary
      ? "none"

      // ? "1px solid " + theme.border.sec_button
      : "none"};
  background-color: ${({ bg, secondary, theme }) =>
    bg ? bg : secondary ? "#cddafa" : theme.bg.secondary};

    @media (max-width: ${({theme}) => theme.screen.mobile}) {
      min-width: ${({width}) => width ?? "6rem"};
    }

  :disabled {
    background-color: ${({ disabledBg, secondary, theme }) =>
      disabledBg
        ? disabledBg
        : secondary
        ? theme.bg.btn_disabled
        : theme.bg.btn_disabled};
    cursor: not-allowed;
    border: ${({ theme, secondary }) =>
      secondary ? "1px solid " + theme.bg.btn_disabled : ""};
    color: ${({ theme, secondary }) =>
      secondary ? theme.bg.prim_500 : theme.text.prim_500};
  }
`;

export const AddmoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.bg.progress};
  font-weight: 500;
  font-size: 1rem;
  line-height: 2.0625rem;
  cursor: pointer;

  :disabled,
  button[disabled] {
    background-color: transparent;
    color: grey;
    cursor: not-allowed;
  }
`;

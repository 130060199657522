import { createGlobalStyle } from "styled-components";
import { themeType } from "../App";

export const GlobalStyle = createGlobalStyle<{ theme: themeType }>`
.flex-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row{
  display: flex;
  align-items: center;
  justify-content: center;
 
}


.btn{
  width: 19.125rem;
  height: 3rem;
  background: ${({ theme }) => theme.bg.secondary};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.text.prim_500};
  font-weight: 400;
  font-size: 1rem;
  line-height: 170%;
  cursor: pointer;

  @media screen and (max-width: ${({ theme }) => theme.screen.smallScreen}) {
    /* font-size: 1rem;
    width: 30vw;
    height: 3.2rem; */
  }
}

button:disabled, button[disabled]{
        cursor: not-allowed;
        background-color: ${({ theme }) => theme.bg.btn_disabled};
        border: none;
      }

/* html{

  @media (max-width: 1440px){
     font-size: 14px;
  }
 
  
  @media (max-width:${({ theme }) => theme.screen.smallScreen}){
    font-size: 11px
  }
} */


* {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  
  ::-webkit-scrollbar{
    width: .5rem;
    height: .5rem;
  }

::-webkit-scrollbar-track-piece {
    background:#eee;
}
::-webkit-scrollbar-thumb {
    background:  #888;
    border-radius: 4px;
    
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

}


/* TEXT */
.info-title{
  color: ${({ theme }) => theme.text.primary};
  font-weight: 600;
  font-size: 1.2rem;
}

.dash-title-lg{
  color: ${({ theme }) => theme.text.primary};
  font-weight: 600;
  font-size: 1.5rem;
  
  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    font-size: 1rem;
  }
}
.dash-title {
  font-weight: 500;
  font-size: .875rem;
  }

.dash-text{
  font-weight: 500;
  font-size: .875rem;
  color: ${({ theme }) => theme.text.prim_100};
  word-break: break-all;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
   font-size: .85rem;
   font-weight: 400;

  }
}

.table-wrapper {
  width: 100%;
    margin-top: 1rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    padding: 2rem;
    border-radius: .25rem;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: .5rem
    }
  }

  .weight-600{
    font-weight: 600;
  }

.upped{
  text-transform: capitalize;
}

.input-error-message{
  font-size: 1rem;
  color: red;
  position: absolute;
  top: 100%;
}

.error-message {
  font-size: .85rem;
  color: red;
  
}

section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.shad_100};
  padding: 1.5rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 1.5rem 0.5rem;
  }
}

.space-between{
  justify-content: space-between;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */

.info-text {

}

//for label that can move up when click on
.input-label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    color: ${({ theme }) => theme.text.prim_200};
    font-size: 0.875rem;
    font-weight: 500rem;
    background-color: ${({ theme }) => theme.bg.prim_500};
    padding-left: 0.7rem;
    transition: all ease-in-out 0.2s;
    top: 0.7rem;
    font-weight: 500;
  }

  .move-up {
    font-size: 0.6rem;
    padding: 0.2rem 0.7rem;
    top: -0.65rem;
    left: 0;

    .text{
      font-size: 0.6rem;
    }

  .icon {
    font-size: .8rem !important;
    }

  }


  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.7)
    }

    50%{
      opacity: 1;
      transform: scale(1.2);
    }

    75%{
      opacity: 1;
      transform: scale(0.9);
    }

    100%{
      transform: scale(1)
    }
  }


`;

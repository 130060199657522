import styled from "styled-components";
import { Button } from "../button";
import { LiaArrowLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import React from "react";

const TitleNavsWrapper = styled.div`
  width: 100%;
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;

  .page-title {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.text.primary};
    text-transform: capitalize;

    @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
      font-size: 1rem;
    }
  }

  .crumb-title {
    cursor: pointer;
    color: ${({ theme }) => theme.text.prim_100};
    font-size: 0.85rem;

    :hover {
      color: ${({ theme }) => theme.text.secondary};
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .crumb-title.last {
    color: ${({ theme }) => theme.text.secondary};
    font-weight: 600;
    text-decoration: underline;
  }

  .crumbs {
    margin-top: 0.5rem;
  }

  .crumb-slash {
    margin-left: 0.5rem;
    margin-right: 0.2rem;
  }
`;
export interface IBCrumbs {
  link: string;
  title: string;
}
export interface IPageTitleNavsProps {
  title: string;
  backLink?: string;
  bcrumbs: IBCrumbs[];
}

export function PageTitleNavs({
  title,
  backLink,
  bcrumbs,
}: IPageTitleNavsProps) {
  const navigate = useNavigate();
  return (
    <TitleNavsWrapper>
      <div className="left">
        <h2 className="page-title">{title}</h2>
        <div className="crumbs">
          {bcrumbs?.map((crumb: IBCrumbs, i: number) => {
            return i === 0 ? (
              <span
                key={i}
                className={`crumb-title ${
                  bcrumbs?.length - 1 === i ? "last" : ""
                }`}
                onClick={() => navigate(crumb.link)}
              >
                {crumb.title}
              </span>
            ) : (
              <React.Fragment key={i}>
                <span className="crumb-slash">/</span>{" "}
                <span
                  className={`crumb-title ${
                    bcrumbs?.length - 1 === i ? "last" : ""
                  }`}
                  onClick={() => navigate(crumb.link)}
                >
                  {crumb.title}
                </span>{" "}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <Button onClick={() => navigate(backLink ?? "")} secondary>
        <LiaArrowLeftSolid /> Back{" "}
      </Button>
    </TitleNavsWrapper>
  );
}

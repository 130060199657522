import {
  AddressDetails,
  CompanyTopView,
  RequestTable,
} from "src/common/components/quotation-views";
import { PrintWrapper, RVRfqWrapper } from "./styles";
import { KeyValueObj } from "src/common/types";
import { Button } from "src/common/components/button";
import { PageSkeletonLoader } from "src/common/components/skeleton/page-skeleton";
import { KeyValueContainer } from "src/common/components/key-value-wrapper";
import { Modal } from "src/common/components/modal";
import { Prompt } from "src/common/components/prompt";
import { FcPrint } from "react-icons/fc";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

export interface IRespondViewRfqProps {
  company: KeyValueObj;
  rfq: KeyValueObj;
  prices?: KeyValueObj;
  subTotal?: number | string;
  logisticsCost: number | string;
  tax: number | string;
  discount: number | string;
  total: number | string;
  rowTotals: KeyValueObj;
  rowCosts?: KeyValueObj;
  rowQuantities?: KeyValueObj;
  rowStockQuantities?: KeyValueObj;
  handleInputChange?: (value: string, id: string, unitPrice: string) => void;
  handleQuantityChange?: (value: string, id: string, unitPrice: string) => void;
  handleOtherInputChange?: (type: string, value: string) => void;
  handleAccountChange?: (type: string, value: string) => void;
  terms: string;
  handleSecondaryButton: (props?: any) => void;
  handleMainButton: (props?: any) => void;
  updateCounterPaymentTerm?: (name: string, value: any) => void;
  secondaryButtonLoading?: boolean;
  mainButtonLoading?: boolean;
  loadingData?: boolean;
  isViewing?: boolean;
  viewer?: string;
  quotation?: KeyValueObj;
  quotationExpiry?: string;
  inspecting?: boolean;
  rfqData?: KeyValueObj[];
  handleRespond?: () => void;
  showRejectConfirmation?: boolean;
  handleDecline?: (options?: any) => void;
  closeDelcineModal?: () => void;
  isInvoice?: boolean;
  invoiceDetails?: KeyValueObj;
  title?: string;
  ordertype?: string;
  quotationStatus?: string;
  retryNotification?: () => void;
}

export function RespondViewRfq({
  company,
  subTotal,
  tax,
  discount,
  logisticsCost,
  total,
  handleInputChange,
  handleOtherInputChange,
  rowTotals,
  handleAccountChange,
  terms,
  rfq,
  handleSecondaryButton,
  handleMainButton,
  updateCounterPaymentTerm,
  secondaryButtonLoading,
  mainButtonLoading,
  loadingData,
  viewer,
  isViewing,
  quotation,
  quotationExpiry,
  inspecting,
  rfqData,
  handleRespond,
  handleDecline,
  showRejectConfirmation,
  closeDelcineModal,
  invoiceDetails,
  isInvoice,
  title,
  rowStockQuantities,
  rowCosts,
  rowQuantities,
  handleQuantityChange,
  ordertype,
  quotationStatus,
  retryNotification,
}: IRespondViewRfqProps) {
  const handlePrint = useReactToPrint({
    content: () => rfqRef?.current,
    documentTitle: "Quotation",
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });

  const rfqRef = useRef<HTMLDivElement>(null);

  if (loadingData) {
    return <PageSkeletonLoader />;
  }
  return (
    <>
      <PrintWrapper>
        <FcPrint
          onClick={handlePrint}
          style={{ fontSize: "1.8rem", cursor: "pointer" }}
        />
      </PrintWrapper>

      <RVRfqWrapper ref={rfqRef}>
        <Modal
          isOpen={showRejectConfirmation as boolean}
          close={() => closeDelcineModal?.()}
        >
          <Prompt
            prompt={
              "Are you sure you want to decline this request for quotation? if Yes, kindly state the reason below"
            }
            onMainAction={(options?: any) => handleDecline?.(options)}
            onSecondaryAction={() => closeDelcineModal?.()}
            withInput
            mainLoading={secondaryButtonLoading}
          />
        </Modal>

        <CompanyTopView entity={inspecting ? rfq?.owner : company} />
        {inspecting ? (
          <div className="request-info">
            <div className="text">
              <h2 className="quote-title">RFQ Details</h2>
            </div>
            <KeyValueContainer loading={loadingData} data={rfqData || []} />
          </div>
        ) : (
          <AddressDetails
            isInvoice={isInvoice}
            invoiceDetails={invoiceDetails}
            rfq={rfq}
            title={title}
            isInspecting={inspecting}
          />
        )}
        <RequestTable
          rfq={rfq}
          discount={discount}
          tax={tax}
          logisticsCost={logisticsCost}
          rowTotals={rowTotals}
          rowCosts={rowCosts}
          rowStockQuantities={rowStockQuantities}
          rowQuantities={rowQuantities}
          subTotal={subTotal}
          total={total}
          handleInputChange={handleInputChange}
          handleOtherInputChange={handleOtherInputChange}
          handleAccountChange={handleAccountChange}
          terms={terms}
          updateCounterPaymentTerm={updateCounterPaymentTerm}
          isViewing={isViewing}
          quotation={quotation}
          inspecting={inspecting}
          quotationExpiry={quotationExpiry}
          handleQuantityChange={handleQuantityChange}
          orderType={ordertype}
        />
        {!isViewing ? (
          inspecting ? (
            <div className="rvrf-controls">
              <Button
                secondary
                loading={secondaryButtonLoading}
                onClick={handleSecondaryButton}
              >
                Decline
              </Button>
              <Button loading={mainButtonLoading} onClick={handleRespond}>
                Respond
              </Button>
            </div>
          ) : (
            <div className="rvrf-controls">
              <Button
                secondary
                loading={secondaryButtonLoading}
                onClick={handleSecondaryButton}
              >
                Decline
              </Button>
              <Button
                loading={mainButtonLoading}
                onClick={() => handleMainButton(rfq?.id)}
              >
                Send Quotation
              </Button>
            </div>
          )
        ) : null}

        {isViewing &&
        viewer === "retailer" &&
        quotationStatus?.toLowerCase() === "draft" ? (
          <div className="rvrf-controls">
            <Button
              secondary
              loading={secondaryButtonLoading}
              disabled={secondaryButtonLoading}
              onClick={() => handleSecondaryButton(rfq)}
            >
              Decline
            </Button>
            <Button
              loading={mainButtonLoading}
              disabled={mainButtonLoading}
              onClick={() => handleMainButton(rfq)}
            >
              Accept Quotation
            </Button>
          </div>
        ) : null}

        {isInvoice ? (
          <div className="rvrf-controls center ">
            <Button onClick={() => handleMainButton(rfq)}>Make Payment</Button>
          </div>
        ) : null}

        {["unavailable", "error"].includes(
          rfq?.external_inventory_callback_status?.toLowerCase()?.trim()
        ) ? (
          <div className="rvrf-controls center ">
            <Button
              loading={rfq.retryingNotification}
              onClick={retryNotification}
            >
              Retry External Inventory Update
            </Button>
          </div>
        ) : null}
      </RVRfqWrapper>
    </>
  );
}

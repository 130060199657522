import styled from "styled-components";

// export const LandingPage = styled.div``;

export const mobile_view = styled.div`
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: green;

  @media (max-width: ${({ theme }) => theme.screen.smallScreen}) {
    height: 90vh;
  }

  //****  new index page sytling...
  .hero-section {
    width: 94%;
    /* height: 90vh; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    background-color: ${({ theme }) => theme.bg.prim_400};

    @media (max-width: 768px) {
      background-color: lightgreen;
      padding: 15px;
      background-color: blue;
    }
  }

  .header-container {
    @media (max-width: 480px) {
      display: none;
    }
  }
`;

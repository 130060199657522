import { ReactElement } from "react";
import { PageSkeletonLoader } from "../skeleton/page-skeleton";
import { EmptyData } from "./empty-data";
import { ErrorLoadingData } from "./error-loading";

export interface IDataWrapperProps {
  loading: boolean;
  error: string;
  loadingMessage?: string;
  failedMessage?: string;
  children?: ReactElement;
  onFailAction?: () => void;
  data?: any;
  noDataMessage?: string;
  customLoader?: ReactElement;
}

export function DataWrapper({
  data,
  noDataMessage,
  loading,
  error,

  failedMessage,
  children,
  onFailAction,
  customLoader,
}: IDataWrapperProps) {
  return (
    <>
      {loading ? customLoader || <PageSkeletonLoader /> : null}
      {error && !loading && (
        <ErrorLoadingData failedMessage={failedMessage} onRetry={() => onFailAction?.()} />
      )}
      {data?.length < 1 && !loading && !error && (
        <EmptyData noDataMessage={noDataMessage || ""} />
      )}
      {!loading && !error && data?.length !== 0 && children}
    </>
  );
}

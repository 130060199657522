import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import notFoundImage from "../../assets/images/404.png";
import { Button } from "../../common/elements";

const NotFoundWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  margin-top: 7rem;
  gap: 4rem;
  padding: 0 3rem;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 0 0.5rem;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 3rem;
  }

  .text-wrapper {
    padding-top: 2rem;
    .oops {
      font-size: 5rem;
      font-weight: 700;
      color: ${({ theme }) => theme.text.primary};

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    .message {
      font-size: 3.5rem;
      font-weight: 400;
      color: ${({ theme }) => theme.text.input};

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 1rem;
      }
    }

    .details {
      margin: 2rem 0;
      font-weight: 400;
      font-size: 1.2rem;

      @media (max-width: ${({ theme }) => theme.screen.mobile}) {
        font-size: 0.875rem;
      }
    }
  }

  .link {
    text-decoration: none;
  }

  .image-404 {
    max-width: 100%;

    .not-found {
      width: 100%;
    }
  }
`;

export interface I_404Props {}

export function NotFound(props: I_404Props) {
  const location = useLocation();
  const isDashboard = location.pathname.split("/")[1] === "dashboard";

  return (
    <NotFoundWrapper>
      <div className="text-wrapper">
        <h2 className="oops">Ooops...</h2>
        <h3 className="message">Page not found</h3>
        <h4 className="details">Check the url you entered and try again</h4>
        {!isDashboard && (
          <Link className="link" to={"/"}>
            <Button>Go back to home</Button>
          </Link>
        )}
        {isDashboard && (
          <Link className="link" to={"/dashboard/stats"}>
            <Button>Go back to dashboard</Button>
          </Link>
        )}
      </div>
      <div className="image-404">
        <img className="not-found" src={notFoundImage} alt="Not found" />
      </div>
    </NotFoundWrapper>
  );
}

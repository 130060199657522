import { SetStateAction, useState } from "react";
import { KeyValueObj } from "src/common/types";
import { GenericInputWrapper } from "./input";
import styled from "styled-components";
import { BsCaretDownFill } from "react-icons/bs";
import { IOption } from "src/common/types/others";

const SelectWrapper = styled(GenericInputWrapper)`
  cursor: pointer;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0.7rem;
    color: ${({ theme }) => theme.text.prim_100};
    cursor: pointer;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
    }

    .caret-icon {
      font-size: 1.2rem;
    }
  }

  .radios {
    box-shadow: ${({ theme }) => theme.shadow.shad_100};
    background-color: ${({ theme }) => theme.bg.prim_500};
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem;
    margin-top: 1rem;
    opacity: 0;
    max-height: 8rem;
    overflow-y: auto;
    animation: radioFadein 0.35s forwards 0.1s;
  }

  input[type="radio"] {
    height: 1.2rem;
    width: 1.2rem;
  }

  .radio-group {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
    }
  }

  @keyframes radioFadein {
    to {
      opacity: 1;
      margin-top: 0.5rem;
    }
  }
`;

export interface IFilterSelectInputProps {
  name: string;
  setFormValues: SetStateAction<any>;
  label: string;
  formValues: KeyValueObj;
  indexLevel: number;
  options: IOption[] | undefined;
}

export function FilterSelectInput({
  formValues,
  setFormValues,
  label,
  name,
  indexLevel,
  options,
}: IFilterSelectInputProps) {
  const [showOptions, setShowOptions] = useState(false);

  function onRadioChange(event: any) {
    const { name, value } = event.target;
    setFormValues((prev: any) => ({ ...prev, [name]: value }));
    setShowOptions(false);
  }

  return (
    <SelectWrapper indexLevel={indexLevel}>
      <label
        className="dash-text"
        onClick={() => setShowOptions((prev: boolean) => !prev)}
      >
        <h3 className=" dash-text upped">{formValues[name]}</h3>
        <div className={`input-label ${formValues[name] ? "move-up" : ""}`}>
          <h3 className="text dash-text upped">{label}</h3>
          <BsCaretDownFill className="caret-icon icon" />
        </div>
      </label>

      {showOptions ? (
        <div onChange={onRadioChange} className="radios">
          {options?.map((option: IOption, i: number) => (
            <div className="radio-group" key={i}>
              <input
                checked={formValues[name] === option.value}
                type="radio"
                name={name}
                value={option.value}
                id={`${option.value}_id`}
                onChange={() => {}}
              />
              <label className="dash-text upped" htmlFor={`${option.value}_id`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
      ) : null}
    </SelectWrapper>
  );
}

import { KeyValueObj } from "src/common/types";

export function getRequestTableHeaders() {
  return [
    "Medex Code",
    "Company Name",
    "RC Number",
    "Company Status",
    "Company Type",
    "Status",
  ];
}

export function getRequestTableData(
  data: KeyValueObj[],
  onView: (e: any, id: string) => void,
  type: string
) {
  let tableData = data?.map((datum: KeyValueObj) => {
    let entity: any;
    if (type === "inbound") {
      entity = datum[datum?.sent_by?.toLowerCase()];
      if (datum?.sent_by === "DeliveryServiceProvider") {
        entity = datum?.delivery_service_provider;
      }
    } else {
      entity = datum[datum?.received_by?.toLowerCase()];
      if (datum?.sent_by === "DeliveryServiceProvider") {
        entity = datum?.delivery_service_provider;
      }
    }

    return {
      id: entity?.id,

      medexCode: {
        type: "string",
        value: entity?.medex_code,
      },
      companyName: {
        type: "string",
        value: (
          <div style={{ minWidth: "10rem", maxWidth: "10rem" }}>
            {entity?.name}
          </div>
        ),
      },
      rcNumber: {
        type: "string",
        value: entity?.rc_number,
      },
      companyStatus: {
        type: "string",
        value: entity?.company_status,
      },
      companyType: {
        type: "string",
        value: datum?.sent_by,
      },
      status: {
        type: "status",
        value: datum?.status,
      },
    };
  });

  return tableData;
}

export function getRequestTableKeys() {
  return [
    "medexCode",
    "companyName",
    "rcNumber",
    "companyStatus",
    "companyType",
    "status",
  ];
}

import styled from "styled-components";

export const ReqWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  gap: 2rem;

  @media (max-width: ${({theme}) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .top-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    position: sticky;
    top: 1rem;
  }

  .table-wrapper {
    width: 100%;
  }

  
`;

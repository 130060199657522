import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthProvider";
// import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import NotificationProvider from "./common/components/Notifications/NotificationProvider";
import { DashboardProvider } from "./context/DashboardProvider";
import { GeneralProvider } from "./context/GeneralProvider";

// if (process.env.NODE_ENV === "production") {
//   disableReactDevTools();
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <DashboardProvider>
        <GeneralProvider>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </GeneralProvider>
      </DashboardProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "styled-components";
import { Skeleton } from "@mui/material";

interface ISkeleton {
  width?: string;
  height?: string;
  noPadding?: boolean;
}

const SkeletonWrapper = styled.div<ISkeleton>`
  padding: ${({ noPadding }) => (noPadding ? "0" : "0.25rem")};
  width: ${({ width }) => width || "100%"};

  .skeleton-style {
    border-radius: 0.15rem;
    height: ${({ height }) => height || "1.7rem"};
    width: 100%;
    background-color: ${({ theme }) => theme.bg.prim_400};
  }
`;

export interface ISkeletonProps {
  width?: string;
  height?: string;
  noPadding?: boolean;
}

export function LoadingSkeleton({ width, height, noPadding }: ISkeletonProps) {
  return (
    <SkeletonWrapper width={width} height={height} noPadding={noPadding}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        className="skeleton-style"
      />
    </SkeletonWrapper>
  );
}

import validator from "validator";
import {
  isBefore,
  isContainLower,
  isContainSpecialChar,
  isContainUpper,
  isInTheFuture,
  isOnlyDigits,
  beforeToday,
} from "./isContain";

export function validateData(value, rules, formValues) {
  let validInput = true;
  let errorMessages = [];

  if (!rules) {
    validInput = true;

    //Come back later
    return { isValid: validInput, errorMessages };
  }

  Object.keys(rules).forEach((ruleKey) => {
    switch (ruleKey) {
      case "maxLength":
        if (value.length > rules[ruleKey].expectedValue) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }

        break;

      case "minLength":
        if (value.length < rules[ruleKey].expectedValue) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "length":
        if (value.length !== rules[ruleKey].expectedValue) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;
      case "contains":
        if (!validator.contains(value, rules[ruleKey].expectedValue)) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }

        break;

      case "isEmail":
        if (!(validator.isEmail(value) === rules[ruleKey].expectedValue)) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }

        break;

      case "compareWithExisting":
        if (!(formValues[rules[ruleKey].expectedValue] === value)) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "validNigerianPhoneNumber":
        if (
          true
          // validatePhoneNumberSync(value).isValid ===
          // rules[ruleKey].expectedValue
        ) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "containSpecialChar":
        if (!isContainSpecialChar(value)) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;
      case "containUppercase":
        if (!isContainUpper(value)) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;
      case "containLowercase":
        if (!isContainLower(value)) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;
      case "onlyDigit":
        if (!isOnlyDigits(value)) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "notInTheFuture":
        if (isInTheFuture(value) === rules[ruleKey].expectedValue) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "notBefore":
        if (!isBefore(value, formValues[rules[ruleKey].expectedValue])) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "imageType":
        if (value && value["type"].split("/")[0] !== "image") {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "maxSize":
        if (value && value.size > rules[ruleKey].expectedValue) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "aboveZero":
        const isGreater = value > 0;
        if (isGreater !== rules[ruleKey].expectedValue) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      case "fromToday":
        if (beforeToday(value) === rules[ruleKey].expectedValue) {
          validInput = false;
          errorMessages.push(rules[ruleKey].errorMessage);
        }
        break;

      default:
        break;
    }
  });

  return { isValid: validInput, errorMessages };
}

import { useState, FormEvent, ChangeEvent, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { AddmoreButton, H3, ModalWrapper } from "src/common/elements";
import { Button } from "src/common/components/button";
import { FilesObject, KeyValueObj, UploadDoc } from "src/common/types";
import { isContainSpecialChar, isNumber } from "src/common/utils";
import {
  FileInput,
  FileWrapper,
} from "src/pages/complete-registration/subs/FileInput";
import { FileGeneralWrapper } from "src/pages/complete-registration/subs/styles";
import styled from "styled-components";
import { IoIosEye } from "react-icons/io";
import { Modal } from "src/common/components/modal";
import { FileViewer } from "src/common/components/FileViewer";

const FileUpdateWrapper = styled.div`
  width: 45rem;
  background-color: ${({ theme }) => theme.bg.prim_500};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .update-controls {
    justify-content: center;
    padding: 2rem 0;
  }

  .uploaded-files-wrapper {
    width: 100%;
    padding: 2rem;

    .uploaded-files {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: 1.2rem;
    }
  }

  .uploaded-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .file-title {
    font-size: 0.9rem;
    font-weight: 500;
    color: ${({ theme }) => theme.text.prim_100};
    text-transform: capitalize;
  }
`;

export interface IFileUpdateProps {
  requiredFiles: any;
  companyType: string;
  handleUpdateFiles: (files: FilesObject) => void;
  loading: boolean;
  companyData: KeyValueObj;
}
export function FileUpdate({
  requiredFiles,
  companyType,
  handleUpdateFiles,
  loading,
  companyData,
}: IFileUpdateProps) {
  const [selectedFiles, setSelectedFiles] = useState<FilesObject>({});
  const [moreInput, setMoreInput] = useState(false);
  const [chosenName, setChosenName] = useState("");
  const [chosenTitle, setChosenTitle] = useState("");
  const [chosenTitleError, setChosenTitleError] = useState<string[]>([]);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [docs, setDocs] = useState<UploadDoc[]>([]);
  const [currentFileUrl, setCurrentFileUrl] = useState("");
  const [currentFileTitle, setCurrentFileTitle] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [currentFileType, setCurrentFileType] = useState("");

  useEffect(() => {
    const role_prepped = companyType
      ?.toLowerCase()
      ?.trim()
      ?.split(" ")
      ?.join("_");
    prepareDocs(requiredFiles?.[role_prepped]);
  }, [requiredFiles, companyType]);

  function prepareDocs(docs: string[]) {
    const preparedDocs: UploadDoc[] = [];
    docs?.forEach((doc: string) => {
      preparedDocs.push({
        title: doc.split("_").join(" "),
        name: doc,
      });
    });
    setDocs(preparedDocs);
  }

  function handleFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setMoreInput(true);
    setShowCustomForm(false);
  }

  function handleCustomInput(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    // Aim is to check for special characaters excluding spaces.
    // I will therefore check the space trimmed version of the text,
    const withoutSpace = value.split(" ").join("");
    const withoutSpaceAndUnderscore = withoutSpace.split("_").join("");
    const containSpecialChar = isContainSpecialChar(withoutSpaceAndUnderscore);
    const isFirstNumber = isNumber(value[0]);
    setChosenTitleError([]);

    if (containSpecialChar) {
      setChosenTitleError([
        "File name should not contain special characters except space",
      ]);
    }

    if (isFirstNumber) {
      setChosenTitleError((prev: string[]) => [
        ...prev,
        "File name must not start with a number",
      ]);
    }

    if (containSpecialChar || isFirstNumber) return;
    let tempName = value.split(" ").join("_");
    setChosenName(tempName);
    setChosenTitle(value);
  }

  function removeCustomInput() {
    setShowCustomForm(false);
    setMoreInput(false);
    setSelectedFiles((prev: KeyValueObj) => {
      const prevCopy = { ...prev };
      delete prevCopy[chosenName];
      return prevCopy;
    });
  }

  function handleShowFile(fileName: string, fileTitle: string) {
    setCurrentFileUrl(companyData?.[fileName]?.url || "");
    setCurrentFileTitle(fileTitle);
    const type = companyData?.[fileName]?.content_type?.split("/")?.[1];
    setCurrentFileType(type);
    setShowFile(true);
  }

  return (
    <FileUpdateWrapper>
      <Modal
        title={currentFileTitle}
        isOpen={showFile}
        close={() => setShowFile(false)}
      >
        <ModalWrapper>
          <FileViewer fileType={currentFileType} fileUrl={currentFileUrl} />
        </ModalWrapper>
      </Modal>
      <div className="uploaded-files-wrapper">
        <H3 style={{ fontSize: "1.2rem" }}>Uploaded files</H3>
        <div className="uploaded-files">
          <div className="uploaded-file">
            <h3 className="file-title">Company Logo</h3>
            <Button
              onClick={() => handleShowFile("company_logo", "Company Logo")}
              secondary
            >
              View <IoIosEye style={{ fontSize: "1.5rem" }} />
            </Button>
          </div>
          {docs.map((doc: KeyValueObj) => (
            <div className="uploaded-file">
              <h3 className="file-title">{doc.title}</h3>
              <Button
                secondary
                onClick={() => handleShowFile(doc.name, doc.title)}
              >
                View <IoIosEye style={{ fontSize: "1.5rem" }} />
              </Button>
            </div>
          ))}
        </div>
      </div>

      <FileGeneralWrapper>
        <div className="desc">
          <h3>
            Please upload relevant documents as listed. Ensure that the
            documents are legible.
          </h3>
          <h4>Click on the SPECIFIC tab to continue relevant uploads</h4>
        </div>
        <div className="files-component-wrapper">
          <FileInput
            setFiles={setSelectedFiles}
            name={"company_logo"}
            title={"Company Logo"}
            files={selectedFiles}
            imageOnly
          />
          {docs.map((doc: UploadDoc, i: number) => (
            <FileInput
              key={i}
              setFiles={setSelectedFiles}
              name={doc?.name}
              title={doc?.title}
              files={selectedFiles}
              isRequired
            />
          ))}

          {moreInput && (
            <FileInput
              setFiles={setSelectedFiles}
              name={chosenName}
              title={chosenTitle}
              cancelable={true}
              files={selectedFiles}
              onClose={removeCustomInput}
            />
          )}
          {showCustomForm && (
            // the styling for this is in styles.ts
            <FileWrapper>
              <div className="close-icon-wrapper">
                <AiOutlineClose
                  className="close-icon"
                  onClick={removeCustomInput}
                />
              </div>

              <form className="more-form flex-row" onSubmit={handleFormSubmit}>
                <div className="input-control-wrapper">
                  <div className="input-wrapper flex-col">
                    <label>Enter the file name</label>
                    <input
                      onChange={handleCustomInput}
                      autoFocus
                      type="text"
                      required
                    />
                  </div>
                  <button
                    onClick={() => setMoreInput(true)}
                    disabled={!chosenTitle || chosenTitleError.length > 0}
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
                {chosenTitleError.length > 0 && (
                  <div className="error-wrapper">
                    {chosenTitleError.map((err: string, i: number) => {
                      return <p key={i}>{err}</p>;
                    })}
                  </div>
                )}
              </form>
            </FileWrapper>
          )}
        </div>

        <div className="input-add-more">
          <h4>Accepted format: pdf, image</h4>
          <AddmoreButton
            disabled={showCustomForm || moreInput}
            onClick={() => setShowCustomForm(true)}
          >
            + Add more
          </AddmoreButton>
        </div>
        <div className="update-controls flex-row">
          <Button
            loading={loading}
            onClick={() => handleUpdateFiles(selectedFiles)}
          >
            Update Files
          </Button>
        </div>
      </FileGeneralWrapper>
    </FileUpdateWrapper>
  );
}

import styled from "styled-components";

export const AddInventoryWrapper = styled.div`
  width: 100%;
  padding: 2rem;

  @media screen and (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  .stats-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;

    .cards-wrapper {
      width: 100%;
      display: grid;
      gap: 1rem;

      @media (min-width: ${({ theme }) => theme.screen.mobile}) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: ${({ theme }) => theme.screen.tablet}) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: ${({ theme }) => theme.screen.smallScreen}) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .sub-title {
    font-size: 1rem;
    color: ${({ theme }) => theme.text.prim_100};
    font-weight: 500;
  }

  .mid-stats {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 0.3rem;
    justify-content: space-between;
    align-self: flex-start;

    .short-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;
      align-self: flex-start;
      padding: 0.5rem;
      background-color: ${({ theme }) => theme.bg.prim_500};

      @media (min-width: ${({ theme }) => theme.screen.tablet}) {
        width: 52%;
      }
    }

    .bar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      border-radius: 0.25rem;
      gap: 0.5rem;
      align-self: stretch;

      @media (min-width: ${({ theme }) => theme.screen.tablet}) {
        width: 45%;
      }
    }
  }

  .inventories {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* box-shadow: ${({ theme }) => theme.shadow.shad_100}; */
    border-radius: 0.25rem;
    width: 100%;
    margin: auto;
    margin-top: 3rem;

    .controls {
      display: flex;
      align-items: center;
      gap: 2rem;
      width: 100%;
      justify-content: flex-end;
      .search {
        flex-grow: 1;
        position: relative;
        height: 3rem;

        input {
          width: 100%;
          height: 100%;
          border: 1px solid ${({ theme }) => theme.border.input};
          border-radius: 0.3125rem;
          outline: none;
          padding: 1rem;
        }
        label {
          position: absolute;
          top: 25%;
          left: 1rem;
          display: flex;
          gap: 0.75rem;
          justify-items: center;
          align-items: center;
          color: ${({ theme }) => theme.text.muted_200};

          .search-icon {
            font-size: 1.2rem;
          }
          h3 {
            font-weight: 400;
          }
        }
      }
    }

    .info-text {
      color: ${({ theme }) => theme.bg.secondary};
      font-weight: 400;
      border: 1px solid ${({ theme }) => theme.bg.secondary};
      padding: 2.5rem;
      border-radius: 0.25rem;
      background-color: ${({ theme }) => theme.bg.secondary}20;

      margin: 2rem;
    }
  }
`;

import { createContext, useContext, useReducer } from "react";
import { v4 } from "uuid";
import Notification from "./Notification";
import "./Notification.css";
import ReactDOM from "react-dom";

const NotificationContext = createContext();
const notificationRoot = document.getElementById("notification-root");

const NotificationProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "ADD_NOTIFICATION":
        return [...state, { ...action.payload }];
      case "REMOVE_NOTIFICATION":
        return state.filter((el) => el.id !== action.id);
      default:
        return state;
    }
  }, []);

  return ReactDOM.createPortal(
    <NotificationContext.Provider value={dispatch}>
      {state.length > 0 && (
        <div className={"notification-wrapper"}>
          {state.map((note) => {
            return <Notification dispatch={dispatch} key={note.id} {...note} />;
          })}
        </div>
      )}
      {props.children}
    </NotificationContext.Provider>,
    notificationRoot
  );
};

export const useNotification = () => {
  const dispatch = useContext(NotificationContext);

  return (props) => {
    dispatch({
      type: "ADD_NOTIFICATION",
      payload: {
        id: v4(),
        ...props,
      },
    });
  };
};

export default NotificationProvider;

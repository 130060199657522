import { useNotification } from "../common/components/Notifications/NotificationProvider";
import { KeyValueObj } from "../common/types";
import { useAxiosAuth } from "./useAxiosAuth";
import useLogout from "./useLogout";

export function useApiCallHandling() {
  const notify = useNotification();
  const axiosAuth = useAxiosAuth();
  const logout = useLogout();

  return async (
    url: string,
    method: string,
    formData: any,
    onSuccess: (data?: any, metadata?: any) => void,
    onFail: (error?: any) => void,
    onFinally: () => void,
    options?: KeyValueObj
  ) => {
    const dontNotifyOnSucess = options?.dontNotifyOnSucess;
    const dontNotifyonFail = options?.dontNotifyonFail;

    try {
      const response = await axiosAuth[method.toLowerCase()](url, formData);

      if (response.data.success) {
        const { message, data, meta } = response.data;

        if (!dontNotifyOnSucess) {
          if (typeof message === "string" && message?.length > 0) {
            notify({
              type: "SUCCESS",
              message: message,
            });
          } else if (Array.isArray(message)) {
            message?.map((mess: string) =>
              notify({
                type: "SUCCESS",
                message: mess,
              })
            );
          } else {
            notify({
              type: "SUCCESS",
              message: "Operation Successful",
            });
          }
        }

        onSuccess(data, meta);
        return data;
      } else {
        const message = response?.data?.message;
        if (message) {
          if (typeof message === "string" && message.length > 0) {
            notify({
              type: "ERROR",
              message: message,
            });
          } else if (typeof message === "object") {
            message?.map((mess: string) =>
              notify({
                type: "ERROR",
                message: mess,
              })
            );
          } else {
            notify({
              type: "ERROR",
              message: "Something went wrong, please try again.",
            });
          }
        } else {
          notify({
            type: "ERROR",
            message: "Something went wrong, please try again.",
          });
        }
      }
    } catch (err: any) {
      if (err?.response?.request?.status === 401) {
        return logout();
      }
      if (!dontNotifyonFail) {
        const message = err?.response?.data?.message;
        if (message) {
          if (typeof message === "string" && message.length > 0) {
            notify({
              type: "ERROR",
              message: message,
            });
          } else if (typeof message === "object") {
            message?.map((mess: string) =>
              notify({
                type: "ERROR",
                message: mess,
              })
            );
          } else {
            notify({
              type: "ERROR",
              message: "Something went wrong, please try again.",
            });
          }
        } else {
          notify({
            type: "ERROR",
            message: "Something went wrong, please try again.",
          });
        }
      }

      onFail(err);
    } finally {
      onFinally();
    }
  };
}

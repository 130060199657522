import { ButtonHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import { Button as Button2 } from "../../elements";
import { ClipSpinner } from "../spinner";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  bg?: string;
  color?: string;
  disabledBg?: string;
  borderColor?: string;
  children?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  width?: string;
  small?: boolean;
}

const StyledButton = styled(Button2)``;

export function Button(props: IButtonProps) {
  const { loading, children } = props;
  return (
    <StyledButton {...props}>
      <>
        {children}
        {loading ? <ClipSpinner color="white" /> : null}
      </>
    </StyledButton>
  );
}

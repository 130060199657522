import styled from "styled-components";

export const Backdrop = styled.div`
  width: 100vw;
  height: 100%;
  background-color: rgba(49, 63, 64, 0.68);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 45;
`
import styled from "styled-components";
import dispImg from "src/assets/images/empty-cart.png";
import { H4 } from "src/common/elements";

export const RowWRapper = styled.div`
  width: 100%;
  .wrapper {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.border.light};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 0.5rem;
  }
`;

export interface IEmptyTableProps {
  noDataMessage: string;
}

export function EmptyData({noDataMessage}: IEmptyTableProps) {
  return (
    <RowWRapper>
      <div className="wrapper">
        <img src={dispImg} alt="Empty cart" />
        <H4>{noDataMessage || "No data to show"} </H4>
      </div>
    </RowWRapper>
  );
}

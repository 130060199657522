import styled from "styled-components";

export const NewDeliveryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.screen.mobile}) {
    padding: 2rem 0.5rem;
  }

  input[type="checkbox"] {
    transform: scale(1.2); /* Adjust scale as needed */
  }

  .main-wrapper {
    width: 100%;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: ${({ theme }) => theme.bg.prim_500};

    @media (max-width: ${({ theme }) => theme.screen.mobile}) {
      padding: 2rem 0.2rem;
    }

    .no-address {
      font-size: 0.9rem;
      color: ${({ theme }) => theme.text.mild_danger};
    }

    .logistic-details {
      margin-top: -1.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .checkbox-wrapper {
      display: flex;
      gap: 0.55rem;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.3rem;
    }

    .separate {
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.bg.prim_400};
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    .pickup-wrapper,
    .double-column-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }

    .radio-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      cursor: pointer;
    }

    .logistics-type {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    label {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.text.prim_300};
      cursor: pointer;
    }

    .control {
      padding: 2rem;
    }

    .error {
      font-size: 0.85rem;
      font-weight: 400;
      color: ${({ theme }) => theme.text.danger};
      padding: 0;
      margin: 0;
    }
  }
`;

import styled from "styled-components";
import { H3, H4 } from "../../elements";
import { useNavigate } from "react-router-dom";

const ProductWrapper = styled.div`
  width: 100%;
  height: 19rem;
  box-shadow: ${({ theme }) => theme.shadow.shad_100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.4rem;
  animation: productIn 0.5s forwards 0.2s;
  opacity: 0;
  transform: scale(0.95);
  padding-bottom: 1rem;

  /* Mobile first approach for the card width */
  @media screen and (min-width: 512px) {
    max-width: 18rem;
  }
  /*
  @media screen and (min-width: 1100px) {
    width: 32%;
  }

  @media screen and (min-width: 1340px) {
    width: 23.875%;
  } */

  @keyframes productIn {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .image-wrapper {
    width: 100%;
    height: 9rem;
    overflow: hidden;
    background-color: #b1cdf8;
  }
  img {
    width: 100%;
  }

  .texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.5rem 0.7rem;
  }

  button {
    width: 5rem;
    height: 2.2rem;
    cursor: pointer;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 0.25rem;
    border: 1px solid ${({ theme }) => theme.bg.secondary};

    .icon {
      color: ${({ theme }) => theme.text.inverted};
      font-size: 1.5rem;
    }
  }
`;

export interface IProductProps {
  imageUrl: string;
  brand: string;
  name: string;
  size: string;
  id: string;
  strength: number;
  strengthUnit: string;
  form: string;
  baseUrl: string;
  onView?: (id: string) => void;
  onApprove?: boolean;
}

const NewH4 = styled(H4)`
  text-transform: capitalize;
  font-size: 0.85rem;
`;

export function Product({
  imageUrl,
  brand,
  name,
  baseUrl,
  id,
  strength,
  strengthUnit,
  onView,
  onApprove,
  form,
}: IProductProps) {
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    if (onApprove) {
      navigate(baseUrl + "/" + id);
    } else if (onView) {
      onView(id);
    }
  };

  return (
    <ProductWrapper>
      <div className="image-wrapper">
        <img src={imageUrl} alt={brand} />
      </div>

      <div className="texts">
        <H3>{brand + "(" + form + ")"}</H3>
        <NewH4>{name}</NewH4>
        <H4>
          {strength}
          {strengthUnit === "milligrams" ? "mg" : "g"}
        </H4>
      </div>

      <button onClick={() => handleClick(id)}>View</button>
    </ProductWrapper>
  );
}
